import React from 'react'
import { Autorize } from './Popup.elements'
import { UserLogButtonPopup } from '../Headers/Headers.elements'
import { useAuth0 } from '@auth0/auth0-react'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import svenskaSpelLogo from '../../assets/svenskaspel-logomark.svg'
import svsBlueSymbol from '../../assets/svs-blue-symbol.svg';

const Autorized = ({isModalOpen,setIsModalOpen,closeBtn}) => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0()
  return (
    <div>
      <Autorize
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
        position='center'
      >
        {closeBtn && <button className='modalClose' onClick={() => setIsModalOpen(false)}>
          <CloseIcon alt='close' />
        </button>}
        <div className='svenska'>
          <img src={svsBlueSymbol} alt='Svenska Spel Symbol'/>
        </div>
        <div className='icon'>
          <img src={svenskaSpelLogo} alt='svenskaSpelLogo'/>
        </div>
        <h3 className='title' style={{fontSize:22}}>
          Du är inte inloggad
        </h3>
        <p>För att spara kuponger och statistik, vänligen logga in</p>
        <p className='account'>Har du inget konto?
          <span className='underline' onClick={() => {
            loginWithRedirect()
          }}>Skapa ett kostnadsfritt konto här!</span>
        </p>
        <div className='btn-group'>
          <UserLogButtonPopup
            black
            width
            onClick={() => {
              setIsModalOpen(false)
            }}
          >
            AVBRYT
          </UserLogButtonPopup>
          <UserLogButtonPopup
            width
            onClick={() => {
              loginWithRedirect()
            }}
          >
            Logga in
          </UserLogButtonPopup>
        </div>

      </Autorize>
    </div>
  )
}

export default Autorized
import styled, { css } from 'styled-components'

export const InputNumberElem = styled.input`
    background: rgb(38, 50, 56);
    color: rgb(255, 255, 255);
    text-align: center;
    height: 2rem;
    width: 6rem;
    padding: 0.4rem;
    border-radius: 0.375rem;
    border: none;
    width: 4.5rem;
    ${props=> props.size === 'small' && css `
      width: 4.5rem;
  
    `}
    &:disabled {
      cursor: not-allowed;
    }
`
import styled from 'styled-components'
import { ToolsPanel } from '../../../../styles/globalStyle'

import {
  colors,
  font,
  breakPoints,
  spacers,
  borderRadius
} from '../../../../styles/constants'

export const ResultSummaryContainer = styled(ToolsPanel)``

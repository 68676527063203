import styled from 'styled-components'
import {
  breakPoints,
  colors,
  font,
  spacers,
  borderRadius,
  headerHeight
} from '../../styles/constants'
import { Header } from '../Headers/Headers.elements'

export const AboutHeader = styled(Header)`
  border-bottom: ${spacers.small} solid ${colors.darkGrey2};
  background: #263238;
  max-width: 1180px;
  margin: 0 auto;
  .reducering-wordmark{
  display: none;
  }
  .reducering-logo{
  height: auto;
  }
  grid-template-columns:
    minmax(${headerHeight.large}, 1fr)
    minmax(auto, calc(${breakPoints.large} - ${spacers.xsmall} * 2))
    minmax(${headerHeight.large}, 1fr);

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    position: relative;
  }

  @media screen and (min-width: ${breakPoints.medium}) {
    grid-template-columns:
      minmax(6.9rem, 1fr)
      minmax(auto, calc(${breakPoints.large} + 0.2rem - ${spacers.xsmall} * 2))
      minmax(6.9rem, 1fr);
  }

  + * {
    @media screen and (min-width: ${breakPoints.medium}) {
      margin-top: 6rem;
    }

    @media screen and (min-width: ${breakPoints.large}) {
      margin-top: 3.4rem;
    }
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    border-bottom-width: ${spacers.small};
  }

  @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
    height: ${headerHeight.small};
  }

  @media screen and (min-width: ${breakPoints.large}) {
    height: ${headerHeight.large};
  }

  .headerItem {
    &:nth-child(1) {
      grid-column: 1 / 1;
      display: flex;
      padding-bottom: ${spacers.xsmall};
    }

    &:nth-child(2) {
      display: grid;
      row-gap: ${spacers.small};
      grid-template-columns: auto auto auto min-content;

      @media screen and (min-width: ${breakPoints.medium}) {
        grid-template-columns: auto min-content auto auto min-content;
      }

      @media screen and (min-width: ${breakPoints.large}) {
        grid-template-columns: auto auto auto auto min-content;
        height: calc(${headerHeight.small} + ${spacers.small});
      }

      @media screen and (max-width: ${breakPoints.mediumMax}) {
        > *:nth-child(1) {
          grid-column: 1 / 4 span;
          grid-row-start: 1;
        }

        > *:nth-child(3) {
          grid-column: 3 / span 2;
          grid-row-start: 2;
        }

        > *:nth-child(4) {
          grid-column: 1 / span 2;
          grid-row-start: 2;
        }

        > *:nth-child(5) {
          grid-column: 1 / 5;
          grid-row-start: 3;

          > button {
            width: 100%;
          }
        }
      }

      > *:nth-child(3),
      > *:nth-child(4) {
        @media screen and (max-width: ${breakPoints.smallMax}) {
          height: 4rem;
        }
      }
    }

    &:nth-child(3) {
      grid-column: 3 / 3;

      @media screen and (max-width: calc(${breakPoints.xlargeMax} + 250px)) and (min-width: ${breakPoints.medium}) {
        //height: 4rem;
      }
    }
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    grid-template-columns: auto min-content;

    .headerItem:nth-child(1) {
      grid-column: initial;
      flex-direction: row;
      display: flex;
      align-items: center;
      height: ${headerHeight.small};
    }

    .headerItem:nth-child(2) {
      grid-column: 1 / span 2;
      grid-row-start: 1;
      padding-left: ${spacers.small};
      padding-right: ${spacers.small};
    }

    .headerItem:nth-child(3) {
      grid-row-start: 1;
      grid-column-start: 2;
    }

    .headerItem:nth-child(1),
    .headerItem:nth-child(3) {
      padding: 1rem;
      height: 6rem;
      display: none;
    }
    .headerItem:nth-child(3){
      display: block;
      padding: 0.1rem 0.5rem 0 0;
      height: 5rem;
    }
  }
`

export const AboutHeaderContent = styled.div`
  background-color: ${colors.darkGrey2};
  grid-row-start: 2;
  grid-column: 1 / span 5;

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    grid-row-start: 3;
    grid-column: 1 / span 4;
  }

  @media screen and (min-width: ${breakPoints.medium}) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 6rem;
    border-bottom: ${spacers.small} solid ${colors.darkGrey2};
  }

  > * {
    @media screen and (min-width: ${breakPoints.medium}) {
      width: calc(50% - ${spacers.small} / 2);
    }

    &:nth-child(2) {
      padding: 1rem;

      @media screen and (max-width: ${breakPoints.mediumMax}) {
        display: flex;
      }

      @media screen and (max-width: ${breakPoints.smallMax}) {
        padding: 0.75rem;
      }
    }

    &:nth-child(1) {
      @media screen and (max-width: ${breakPoints.smallMax}) {
        //height: 4rem;
        flex-wrap: wrap;
        overflow: hidden;
      }
    }
  }
`

export const AboutHeaderRounds = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  a {
    color: ${colors.white};
    @media screen and (max-width: 488px){
     min-height: 40px;
     max-height: 45px;
    }
  }

  &:before {
    content: '';
    padding-bottom: 5.5rem;
    width: 0;
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    margin-bottom: 0;
  }

  &.single-game a {
    flex-direction: row;
    font-size: ${font.size.medium};

    span {
      margin-left: ${spacers.small};
    }
  }

  > * {
    flex: auto;
    background-color: inherit;
    background-color: ${({ gameTypeColor }) => gameTypeColor};
    border-radius: ${borderRadius.small};
    padding: ${spacers.large};
    font-size: ${font.size.xsmall};
    font-weight: ${font.weight.semiBold};
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1;

    span {
      font-size: ${font.size.medium};
    }

    @media screen and (max-width: ${breakPoints.largeMax}) {
      font-size: ${font.size.xxsmall};
    }

    @media screen and (max-width: ${breakPoints.smallMax}) {
      padding: ${spacers.xsmall};
      display: flex;
      overflow: hidden;
      text-align: center;

      span {
        font-size: ${font.size.small};
      }
    }

    + * {
      margin-left: ${spacers.small};
    }
  }
`

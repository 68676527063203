import styled from 'styled-components'
import { breakPoints, spacers, colors, font } from '../../../../styles/constants'
import { ToolsPanel } from '../../../../styles/globalStyle'

export const PointReductionRow = styled.div`
  display: flex;
  flex-direction: row;

  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : null )};
  user-select: ${({ isDisabled }) => (isDisabled ? 'none' : null )};

  * {
    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : null )};
  }

  button {
    color: ${({ isDisabled }) => (isDisabled ? colors.toolGrey : null )};
    background-color: ${({ isDisabled }) => (isDisabled ? colors.darkGrey : null )};
    box-shadow: ${({ isDisabled }) => (isDisabled ? 'none' : null )};
  }
`

export const PointReductionContainer = styled(ToolsPanel)`
  flex-direction: row;

  @media screen and (min-width: ${breakPoints.medium}) {
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    flex-direction: row;
    align-items: flex-end;

    button {
      margin-top: 0;
      margin-right: auto;
    }

    p {
      flex-basis: 100%;
      order: -1;
      margin-top: -${spacers.medium};
    }
  }
`

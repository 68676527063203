import styled,{css} from 'styled-components'
import {
  InnerContainerLarge,
  CircleBtn } from '../../../styles/globalStyle'
import {
  borderRadius,
  breakPoints,
  colors,
  font,
  spacers
} from '../../../styles/constants'

export const ReductionContainer = styled.div`
  position: fixed;
  z-index: 10;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 0 2rem 1rem rgba(0 0 0 / 25%);

  background: linear-gradient(
    -45deg,
    rgba(55, 71, 79, 0.9) 0%,
    rgba(55, 71, 79, 0.9) 45%,
    rgba(84, 110, 122, 0.9) 55%
  );
`

export const ReductionRow = styled(InnerContainerLarge)`
  flex-wrap: nowrap;
  justify-content: flex-end;
  color: ${colors.lightGrey};
  padding-left: 0;
  padding-right: 0;

  @media screen and (min-width: ${breakPoints.medium}) {
    padding-left: ${spacers.xsmall};
    padding-right: ${spacers.xsmall};
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    padding-left: ${spacers.xlarge};
  }

  @media screen and (max-width: 500px) {
    padding-left: ${spacers.large};
  }

  > * + * {
    margin-left: ${spacers.xlarge};

    @media screen and (max-width: ${breakPoints.smallMax}) and (max-width: ${breakPoints.mediumMax}) {
      margin-left: 1rem;
    }

    @media screen and (min-width: ${breakPoints.small}) and (max-width: ${breakPoints.mediumMax}) {
      margin-left: ${spacers.xxlarge};
    }

    @media screen and (min-width: ${breakPoints.xxlarge}) {
      margin-left: ${spacers.xxlarge};
    }
  }
`

export const ReductionColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-end;
  padding-top: ${spacers.medium};
  padding-bottom: ${spacers.medium};

  @media screen and (min-width: ${breakPoints.xlarge}) {
    padding-top: ${spacers.large};
    padding-bottom: ${spacers.large};
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    padding-top: ${spacers.xlarge};
    padding-bottom: ${spacers.xlarge};
  }

  @media screen and (max-width: ${breakPoints.smallMax}) {
    padding-top: ${spacers.large};
    padding-bottom: ${spacers.large};
  }

  @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
    //flex-direction: row;
    //align-content: center;

    > * + * {
      margin-left: ${spacers.medium};
    }
  }

  > p {
    white-space: nowrap;
    font-size: ${font.size.medium};

    &:not(.reduction-title) {
      width: auto;
    }

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      font-size: ${font.size.small};
      line-height: 1.6;
    }

    @media screen and (min-width: ${breakPoints.large}) {
      text-align: right;
    }
  }

  .reduction-info {
    display: flex;

    > * + * {
      margin-left: ${spacers.large};

      @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
        margin-left: ${spacers.medium};
      }

      @media screen and (max-width: ${breakPoints.mediumMax}) {
        margin-left: 0;
      }
    }

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      flex-direction: column;
    }
  }

  span strong {
    color: ${colors.white};
  }
`

export const SpelaTillsammansButton = styled(CircleBtn)`
  background-color: ${colors.titleGrey};
  margin-right: 0.5rem;
  box-shadow: rgb(0 0 0 / 35%) 0px 3px 13px -2px;
  font-weight: ${font.weight.bold};

  &.isActive {
    background-color: ${colors.vividCyan};
  }
`

export const AfterReduction = styled(ReductionColumn)`
  color: ${colors.vividCyan};

  & > .reduction-title {
    font-weight: ${font.weight.bold};
  }
`

export const ReducedPercentage = styled(ReductionColumn)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${breakPoints.xlarge}) {
    > * + * {
      margin-left: ${spacers.large};
    }
  }

  @media screen and (min-width: ${breakPoints.medium}) {
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
  }

  img {
    @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
      width: 17px;
      height: 10px;
    }

    @media screen and (min-width: ${breakPoints.medium}) {
      transform: rotate(-90deg);
    }

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      margin-bottom: ${spacers.large};
    }
  }

  span {
    font-size: 2rem;
    font-weight: ${font.weight.bold};
    line-height: 1;

    @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
      font-size: ${font.size.medium};
    }
  }

  .reducedAmount {
    display: flex;
    flex-direction: column;
    margin-left: ${spacers.small};

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      margin-left: 0;
      align-items: center;
    }
  }

  .reducedNumeral {
    font-size: ${font.size.xxsmall};
    color: ${colors.vividCyan};
    white-space: nowrap;

    @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
      font-size: 0.8rem;
    }
  }
`

export const ReductionBtn = styled.button`
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border-radius: ${borderRadius.xsmall};
  border: none;
  padding: 0.7rem 1rem;
  text-transform: uppercase;
  font-size: ${font.size.medium};
  font-weight: ${font.weight.semiBold};
  color: ${colors.darkGrey2};
  background-color: ${colors.vividCyan};
  width: calc(100% * 1 / 8);
  white-space: nowrap;
  margin-left: 3px;
  @media screen and (max-width: ${breakPoints.xlargeMax}) {
    //width: calc(100% * 1 / 10);
  }

  @media screen and (max-width: ${breakPoints.largeMax}) {
    width: calc(100% * 1 / 8);
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    border-radius: 0;
    width: calc(100% * 1 / 6);
  }

  @media screen and (max-width: ${breakPoints.smallMax}) {
    width: auto;
    padding: ${spacers.large};
    padding-left: ${spacers.xlarge};
  }

  @media screen and (max-width: 500px) {
    font-size: ${font.size.xsmall};
    padding-left: ${spacers.large};
  }
  @media screen and (max-width: 374px) {
    font-size: 1rem;
    max-width: 60px;
    justify-content: center;
    white-space: normal;
    padding-left: ${spacers.large};
  }
  ${props=> props.liveTo && css`
    background-color: #25b1e6;
    margin-left: 15px;

  @media screen and (max-width: 374px) {
    font-size: 1rem;
    max-width: 60px;
    justify-content: center;
    white-space: normal;
    padding-left: ${spacers.large};
  }
`}
`

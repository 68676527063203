import React from 'react'
import '../index.css'

import GameDetail from '../GameDetail/GameDetail'
import SelectBet from '../SelectBet/SelectBet'
import { SelectableGameItems, GameItemsWholeGuardRow } from './GameItem.elements'

import { ReactComponent as ArrowDown } from '../../../../assets/chevron-down.svg'

export default function GameItem({
  eventData,
  viewState: { home, draw, away },
  mode,
  couponFunctions,
  index,
  isProbability,
  trendData,
  setRowActive,
  isReduced,
  productId,
  eventForecast
}) {
  const { odds, distribution, sportEventId } = eventData

  let paybackRate = 0
  const prob = {}

  let asFloatArray = [odds?.home, odds?.draw, odds?.away].map(str => {
    return parseFloat(str?.replace(',', '.')) || 0
  })

  for (let o of asFloatArray) {
    paybackRate += 1 / o
  }

  prob.home = asFloatArray[0]
    ? `${((1 / (asFloatArray[0] * paybackRate)) * 100).toFixed(0)}%`
    : `0%`
  prob.draw = asFloatArray[1]
    ? `${((1 / (asFloatArray[1] * paybackRate)) * 100).toFixed(0)}%`
    : `0%`
  prob.away = asFloatArray[2]
    ? `${((1 / (asFloatArray[2] * paybackRate)) * 100).toFixed(0)}%`
    : `0%`

  const betsArr = [
    {
      viewState: home,
      trendData: trendData?.home,
      odds: !isProbability ? odds?.home : prob.home,
      distribution: distribution?.home,
      betChoice: '1'
    },
    {
      viewState: draw,
      trendData: trendData?.draw,
      odds: !isProbability ? odds?.draw : prob.draw,
      distribution: distribution?.draw,
      betChoice: 'X'
    },
    {
      viewState: away,
      trendData: trendData?.away,
      odds: !isProbability ? odds?.away : prob.away,
      distribution: distribution?.away,
      betChoice: '2'
    }
  ]

  if (betsArr.some(el => 
    el.trendData && !(0 < el.trendData && el.trendData < 1))
  ) {
    betsArr.forEach((el, i) => {
      /// MIXING TYPES, integers and strings; NOT GOOD
      el.trendData = "n/a";
    })
  }

  const createCouponFunctions = (index, sign) => {
    return {
      toggle: couponFunctions.toggleSelected(index, sign),
      setValue: couponFunctions.setValue(index, sign),
      setColor: couponFunctions.setColor(index, sign),
      openEdit: couponFunctions.openEdit(index, sign),
      closeEdit: couponFunctions.closeEdit(index, sign)
    }
  }
  // There is no good reason to loop here, there will never be more options that 1, X, 2
  return (
    <SelectableGameItems>
      <GameDetail eventForecast={eventForecast} eventData={eventData} betsArr={betsArr} productId={productId}/>

      <SelectBet
        isReduced={isReduced}
        betObj={betsArr[0]}
        key={`${sportEventId}-${0}`}
        couponFunctions={createCouponFunctions(index, 'home')}
        mode={mode}
      />

      <SelectBet
        isReduced={isReduced}
        betObj={betsArr[1]}
        key={`${sportEventId}-${1}`}
        couponFunctions={createCouponFunctions(index, 'draw')}
        mode={mode}
      />

      <SelectBet
        isReduced={isReduced}
        betObj={betsArr[2]}
        key={`${sportEventId}-${2}`}
        couponFunctions={createCouponFunctions(index, 'away')}
        mode={mode}
      />

      <GameItemsWholeGuardRow>
        <div onClick={() => setRowActive(index)}>
          <ArrowDown />
        </div>
      </GameItemsWholeGuardRow>
    </SelectableGameItems>
  )
}

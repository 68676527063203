import styled from 'styled-components'
import {
  colors
} from '../../../../styles/constants'
import { ToolsPanel } from '../../../../styles/globalStyle'

export const RowStakeContainer = styled(ToolsPanel)`
  ${({ isActive }) => (isActive ? `opacity: 1;` : `opacity: 0.3;`)}
`

export const Select = styled.select`
  align-self: flex-end;
  background: ${colors.darkGrey2};
  color: ${colors.white};
  border: none;
  padding: 0.3rem 0.7rem;
  border-radius: 0.3rem;
  ${({ isActive }) => (isActive ? `cursor: pointer;` : `cursor: not-allowed;`)}
`

export const Option = styled.option`
  background: ${colors.darkGrey2};
  color: ${colors.white};
  display: flex;
`

import styled, { css } from 'styled-components'
import {
  breakPoints,
  colors,
  font,
  spacers,
  borderRadius,
  headerHeight
} from '../../../styles/constants'
import Search from '../../../assets/search.png'

export const WinTableWrapper = styled.div`
  width: 100%;
  padding: 5rem 0;
  .tabs{
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 1rem;
    overflow: hidden;
    grid-gap: 4rem;
    margin-bottom: 1.2rem;
    &__tab{
        padding: 0.5rem 0rem;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 1.8rem;
        color: ${colors.white};
        font-weight: 500;
        cursor: pointer;
        border-radius: 1rem;
        position: relative;
        &:before {
          content : "";
          position: absolute;
          left    : 50%;
          transform: translateX(-50%);
          bottom  : 0;
          height  : 1px;
          width   : 0;  /* or 100px */
          transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
        }
        &:hover{
          &:before {
            width: 90%;
            border-bottom:2px solid grey;
          }
        }
        &--active{
         &:before{
            width: 90%;
            border-bottom:2px solid #36c5af;
         }
         &:hover{
          &:before{
           border-bottom:2px solid #36c5af;
          }
         }
        }
    }
  }
  .btn-go{
      border-radius: 0.5rem;
      background-color: #36c5af;
      box-shadow: 0px 3px 6px #0000004d;
      padding: 1rem 3rem;
      cursor: pointer;
      line-height: 1.6rem;
      font-size: 1.4rem;
      font-weight: 500;
      text-transform: uppercase;
      border: none;
      color: #FFFFFF;
  }
  h2 {
    margin-bottom: 4rem ;
    font-size: 2.8rem;
    text-align: center;
    @media screen and (max-width: 768px){
      font-size: 1.8rem;
      margin-bottom: 3rem;
    }
  }
  .params{
  
    
  }
  .without-data {
    text-align: center;
    margin: 3rem 0;
    font-size: 1.8rem;
  }
  .table {
    width: 100%;
    overflow: hidden;
    padding: 2.3rem 2rem 1.6rem 2rem;
    background: transparent linear-gradient(122deg, #5A6E79 0%, #37474F 100%);
    box-shadow: 0px 3px 15px #0000000A;
    border-radius: 1rem;
    &-icon{
      width: 1.5rem;
      fill: white;
      transition: transform ease-in 200ms;
      &--open{
        transform: rotate(180deg);
      }
    }
    &-flex{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 1rem;
      padding-left: 0.5rem;
      &--padding{
        padding-left: 3rem;
      }
    }
    &-container {
         ::-webkit-scrollbar {
            height: 3px;
         }
        ::-webkit-scrollbar-track {
          background:linear-gradient(to top, #5a6e79 0, #5a6e79 33%, #a4a4a4 33%, #a4a4a4 66%, #5a6e79 66%, #5a6e79 100%);
          border-radius: 1rem;
        }
        ::-webkit-scrollbar-thumb {
          background: ${colors?.anchor};
          border-radius: 1rem;
          
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      @media screen and (max-width: 768px){
        overflow: auto;
         
      }
    }
    &-title {
      display: flex;
      justify-content: center;
      align-items: center;
      &-medium {
        text-decoration: underline;
        cursor: pointer;
      }
      .arrows {
        margin-left: 0.7rem;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        span {
          height: 1rem;
          display: flex;
          align-items: center;
          &:hover {
            transform: scale(1.5);
          }
        }
        .up {
          transform: rotateZ(270deg);
        }
        .down {
          transform: rotateZ(90deg);
        }
        img {
          width: 0.7rem;
          height: 1.5rem;
        }
      }
    }
    &-head {
      display: grid;
      grid-template-columns:2fr 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 4rem;
      div {
        text-align: center;
        font-size: 1.8rem;
        font-weight: ${font.weight.bold};
      }
      @media screen and (max-width: 768px){
          grid-template-columns: repeat(6, minmax(8.5rem, 1fr) );
          grid-gap: 4rem;
          div {
            font-size: 1.4rem;
            justify-content: left;
          }
      }
      &--min{
        grid-template-columns:1fr 1fr 1fr 1fr;
        grid-template-columns: repeat(4, minmax(14rem, 1fr) );
      }
    }
    &-body {
      margin-top:2.2rem;
      @media screen and (max-width: 768px){
        min-height: 7rem;
        width: auto;
      }
      &__item {
        padding: 1rem 0;
        display: grid;
        text-align: center;
        align-items: center;
        font-size: 1.8rem;
        grid-gap: 4rem;
        grid-template-columns:2fr 1fr 1fr 1fr 1fr 1fr;
        //border-bottom: 1px solid #c8cdcd;
        box-shadow: 0 2px 1px -2px #c8cdcd;
        &:hover{
          background-color: #3b4b56;
          cursor: pointer;
        }
        @media screen and (max-width: 768px){
          grid-template-columns: repeat(6, minmax(8.5rem, 1fr) );
          grid-gap: 4rem;
          font-size: 1.4rem;
          width: fit-content;
          div {
            text-align: left;
          }
      }
        div {
          word-break: break-word;
        }
        &:last-child{
          box-shadow: none;
        }
        &--min{
          grid-template-columns:1fr 1fr 1fr 1fr;
          @media screen and (max-width: 768px){
            grid-template-columns: repeat(4, minmax(14rem, 1fr) );
          }
        }
        &--color {
          background-color: #748c9c;
          font-size: 1.4rem;
        }
      }
    }
  }
  .head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media screen and (max-width: 768px){
      flex-direction: column-reverse;
      align-items: flex-start;
    }
    .type {
      button {
        width: 3.2rem;
        height: 3.2rem;
        margin-right: 1rem;
        border: none;
        cursor: pointer;
        background: transparent;
        img {
          max-width: 100%;
          opacity: 0.5;
        }
        .active {
          opacity: 1!important;
        }
        &:hover {
          img{
            opacity: 1;
          }
          @media screen and (max-width: 468px){
              img {
              opacity: 0.5;
              }
            }
        }
      }
    }
    .right{
      display: flex;
      grid-gap: 1rem;
      align-items: center;
      @media screen and (max-width: 768px){
        margin-bottom: 2rem;
      }
    }
    .params{
      cursor: pointer;
      max-width:26.2rem;
      width: 100%;
      input {
        background: #263238;
        border-radius: 0.5rem;
        border: none;
        width: 100%;
        padding: 1.1rem 3rem 1.1rem 1.9rem;
        color:${colors.white};
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
    .search {
      position: relative;
      max-width:26.2rem;
      width: 100%;
      
      &:after{
        content:"";
        background: url(${Search});
        background-size: cover;
        width: 14px;
        height: 14px;
        z-index: 1;
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
      }
      input {
        background: #263238;
        border-radius: 0.5rem;
        border: none;
        width: 100%;
        padding: 1.1rem 3rem 1.1rem 1.9rem;
        color:${colors.white};
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }
  }
  .pagination {
    margin-top:2rem;
    padding-top: 1.5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info-win{
      font-size: 12px;
      text-align: center;
    }
    &:before{
      content: '';
     position: absolute;
     width: 140%;
     height:0.1rem ;
     background: ${colors.white};
     opacity: 0.5;
     top: 0;
     right: -2rem;
    }
    @media screen and (max-width: 768px){
      flex-direction: column-reverse;
      grid-gap: 1rem;
      &:before {
        display: none;
      }
      padding-top: 0;
    }
    ul {
       display:flex;
       align-items: center;
       justify-content: end;
       li { 
        display:flex;
        border:none;
        font-size:1.6rem;
        cursor:pointer;
        background-color: transparent;
        color:${colors.white};
        align-items: center;
        justify-content: center;
        min-width:2.2rem;
        padding: 0 0.5rem;
        height:2.2rem;
        margin-right:0.5rem;
        border-radius:0.2rem;
        a {
           width:100%;
           text-align: center;
        }
       }
       .active {
          background-color:${colors.anchor};
       }
   
    }
  }
  .table-name{
    font-size: 1.8rem;
  }
  ${props => props.minPadding && css `
    padding: 3rem 0;
`}
`
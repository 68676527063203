import styled from 'styled-components'

export const BombenDividentContainer = styled.div`
  .total-win{
    display: flex;
    align-items: center;
    justify-content: space-between; 
    font-size: 1.4rem;
    font-weight: 300;
    color: rgb(207, 216, 220);
    margin-bottom: 0.3rem;
    &:last-child{
    margin-bottom: 0;
    }
    &__flex{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 2rem;
    }
   
  }
  .winn{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.8rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
`
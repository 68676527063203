import React, { useState, useEffect } from "react";
import { socket, SocketContext } from "./context/socket";
import { ActiveGamesContext } from "./context/activeGamesContext";
import { LiveResultContext } from "./context/liveResultContext";
import { PhotoContext } from "./context/photoContext";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { colors, font } from "./styles/constants";
import AdblockPopup from "./components/Popup/AdblockPopup";
import SDKInitializer from "./components/Popup/SDKInitializer";

import ProtectedRoute from "./auth/auth";

import { useAuth0 } from "@auth0/auth0-react";

import Auth0ProviderWithHistory from "./auth/authWithHistory";

import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import HomePage from "./pages/HomePage";
import GuidePage from "./pages/GuidePage";
import AboutPage from "./pages/AboutPage";
import LiveResultPage from "./pages/LiveResultPage";
import CouponLoader from "./pages/CouponLoader";
import GameLoader from "./pages/GameLoader";
import LiveLoader from "./pages/LiveLoader";

import MyPage from "./pages/MyPage";

import GamePageAbout from "./pages/GamePageAbout";
import RulesConditionsPage from "./pages/RulesConditionsPage/RulesConditionsPage";
import GdprPage from "./pages/GdprPage/GdprPage";

import "./App.css";
import { ReductionToolTip } from "./styles/reductionToolTip";
import {
  GlobalStyle,
  Main,
  CookieConsentContainer,
} from "./styles/globalStyle";

import { HelmetProvider, Helmet } from "react-helmet-async";
import ContestPage from "./pages/ContestPage";
import AdminPage from "./pages/AdminPage/AdminPage";
import CreateBlog from "./pages/Blog/CreateBlog";
import Blog from "./pages/Blog/BlogSingle/Blog";
import { AccountContext } from "./context/accountContext";
import BlogPage from "./pages/Blog/BlogPage/BlogPage";
import UserNamePopup from "./components/Popup/UserNamePopup";
import AuthorPage from "./pages/AuthorPage/AuthorPage";
import Bomben from "./pages/Bomben/Bomben";
import EmptyPage from "./pages/EmptyPage/EmptyPage";
import BombenLive from "./pages/Bomben/BombenLive";
import * as Sentry from "@sentry/react";
import CreateNews from "./pages/News/CreateNews";

function App() {
  return (
    <HelmetProvider>
      <Router>
        {process.env.REACT_APP_NO_BACKEND.toUpperCase() === "FALSE" && (
          <CookieConsent
            location="bottom"
            buttonText="Stäng"
            cookieName="GeneralConsent"
            style={{
              background: colors.toolsGradient,
              alignItems: "center",
              textAlign: "center",
              // borderBottom: "2px black solid",
              // fontSize: font.size.medium,
              // fontWeight: font.weight.heavy
            }}
            buttonStyle={{
              backgroundColor: colors.toolGreyActive,
              color: "white",
              fontSize: font.size.medium,
              padding: "15px",
            }}
            // debug={true}
            // onAccept={() => {}}  <----- This should attach all the tracking scripts n stuff.
          >
            <CookieConsentContainer>
              <p>
                <b>
                  {" "}
                  <i> Reducering.se </i>{" "}
                </b>{" "}
                använder cookies för att kunna ge en så bra upplevelse av
                webbplatsen som möjligt. <br />
                Genom att använda vår webbplats godkänner du användningen av
                cookies,{" "}
                <Link className="consent-link" to="/gdpr">
                  {" "}
                  läs mer om cookies här
                </Link>
                .
              </p>
            </CookieConsentContainer>
          </CookieConsent>
        )}
        {process.env.REACT_APP_NO_BACKEND.toUpperCase() === "FALSE" ? (
          <Auth0ProviderWithHistory>
            <AppWithAuth />
          </Auth0ProviderWithHistory>
        ) : (
          <AppEmpty />
        )}
      </Router>
    </HelmetProvider>
  );
}

function AppWithAuth() {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const [userPhoto, setUserPhoto] = useState(null);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [drawNumbers, setDrawNumbers] = useState({});
  const [liveResultData, setLiveResultData] = useState({});
  const [admin, setAdmin] = useState(false);
  const [openModal, setIsOpenModal] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const now = new Date();
    const stored = localStorage.getItem("betaBanner");
    if (!stored || parseInt(stored) < now.getTime()) {
      setShowBanner(true);
    }
  }, []);

  const handleCloseBanner = () => {
    const now = new Date();
    const inFuture = new Date(now);
    inFuture.setHours(now.getHours() + 2);
    localStorage.setItem("betaBanner", inFuture.getTime().toString());

    setShowBanner(false);
  };
  useEffect(() => {
    if (process.env.REACT_APP_NO_BACKEND.toUpperCase() === "TRUE") {
      let drawdata = {
        stryktipset: { draws: ["0000"] },
        europatipset: { draws: ["0000"] },
        powerplay: { draws: ["0000"] },
        topptipset: { draws: ["0000"] },
      };
      setDrawNumbers(drawdata);
    } else {
      socket.on("connect", () => {
        console.log("Socket running? ", socket.connected);
      });
      socket.on("fireWinEvent", (data) => {
        console.log("fireWinEvent ", data);
      });
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/gameInfo`)
        .then((res) => res.json())
        .then((data) => {
          console.log("infoInfo:", data);
          setDrawNumbers(data);
        });
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/ongoinggameInfo`)
        .then((res) => res.json())
        .then((data) => {
          console.log("ongoingData:", data);
          setLiveResultData(data);
        });
    }

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);
  useEffect(async () => {
    if (!isLoading && isAuthenticated) {
      let token = isAuthenticated ? await getAccessTokenSilently() : {};
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/account`,
        {
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        setTimeout(async () => {
          const response = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/account`,
            {
              method: "GET",
              headers: headers,
            }
          );
          const account = await response.json();
          if (!account?.username) setIsOpenModal(true);
          setAdmin(account);
        }, 10000);
      } else {
        const account = await response.json();
        if (!account?.username) setIsOpenModal(true);
        setAdmin(account);
      }
    }
  }, [isAuthenticated]);
  useEffect(() => {
    const fetchUserPhoto = async () => {
      let token = isAuthenticated ? await getAccessTokenSilently() : {};
      let headers;
      if (isAuthenticated) {
        headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      } else {
        headers = {};
      }
      let username = admin?.username;
      let photo = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/users/${username}/photo`,
        {
          headers: headers,
        }
      );
      if (photo.ok) {
        let img = await photo.blob();
        let createImgBlog = URL.createObjectURL(img);
        setUserPhoto(createImgBlog);
      }
    };
    if (admin?.username) {
      fetchUserPhoto();
    }
  }, [admin]);
  return (
    <>
      {/* <Router>
      <Auth0ProviderWithHistory> */}
      <SDKInitializer />
      {/* <AdblockPopup /> */}
      <Helmet>
        <title>Sveriges bästa poolspels-verktyg | Reducering.se</title>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.reducering.se" />
        <meta property="og:title" content="Sveriges bästa poolspels-verktyg" />
        <meta
          property="og:description"
          content="Reducering.se är det givna verktyget för dig som vill spela reducerat och smartare på Svenska Spels poolspelsprodukter. Bli en bättre spelare - redan idag!"
        />
        <meta property="og:image" content="./assets/og_image.jpg" />
      </Helmet>
      <GlobalStyle />
      <ReductionToolTip />
      <Main>
        <ActiveGamesContext.Provider value={drawNumbers}>
          <LiveResultContext.Provider value={liveResultData}>
            <AccountContext.Provider value={admin}>
              <PhotoContext.Provider value={userPhoto}>
                <UserNamePopup
                  isModalOpen={openModal}
                  setIsModalOpen={setIsOpenModal}
                  setUser={setAdmin}
                />
                <Switch>
                  <Route exact path="/">
                    {showBanner && (
                      <div
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          backgroundColor: "#00427A",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "12px",
                          position: "relative",
                        }}
                      >
                        <a
                          style={{
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                          href="https://beta.reducering.se"
                          target="_blank"
                        >
                          Reducering 2.0 är live - klicka{" "}
                          <span
                            style={{
                              color: "#30B29E",
                            }}
                          >
                            här
                          </span>{" "}
                          och testa direkt!
                        </a>
                        <button
                          onClick={handleCloseBanner}
                          style={{
                            position: "absolute",
                            right: "15px",
                            backgroundColor: "transparent",
                            border: "none",
                            color: "white",
                            cursor: "pointer",
                          }}
                        >
                          <svg
                            fill="#FFFFFF"
                            height="15px"
                            width="15px"
                            version="1.1"
                            id="Capa_1"
                            viewBox="0 0 490 490"
                          >
                            <polygon
                              points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 
	489.292,457.678 277.331,245.004 489.292,32.337 "
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                    <HomePage />
                  </Route>
                  {/* <Route path={['/reducera/:id', '/reducera']}>
                    <CouponLoader />
                  </Route> */}
                  <Route path={["/c/:id"]}>
                    <CouponLoader active={drawNumbers} live={liveResultData} />
                  </Route>
                  <Route
                    path={[
                      "/liveresults/bomben/:drawNumber/:id",
                      "/liveresults/bomben/:drawNumber/",
                    ]}
                  >
                    <BombenLive />
                  </Route>
                  <Route
                    path={[
                      "/reducera/bomben/:drawNumber/:id",
                      "/reducera/bomben/:drawNumber/",
                    ]}
                  >
                    <Bomben />
                  </Route>
                  <Route
                    path={[
                      "/reducera/:gameType/:drawNumber/:id",
                      "/reducera/:gameType/:drawNumber/",
                    ]}
                  >
                    <GameLoader />
                  </Route>
                  <Route path="/hur-funkar-det">
                    <GuidePage />
                  </Route>
                  <Route path="/om-oss">
                    <AboutPage />
                  </Route>
                  <Route path="/regler-villkor">
                    <RulesConditionsPage />
                  </Route>
                  <Route path="/gdpr">
                    <GdprPage />
                  </Route>
                  <Route
                    path={[
                      "/liveresults/:gameType/:drawNumber/:id",
                      "/liveresults/:gameType/:drawNumber/",
                    ]}
                  >
                    <LiveLoader />
                  </Route>
                  <Route path="/tavling">
                    <ContestPage />
                  </Route>
                  <Route path="/blogs">
                    <BlogPage />
                  </Route>
                  <ProtectedRoute component={AdminPage} path="/admin" />
                  <ProtectedRoute component={AuthorPage} path="/author" />
                  <ProtectedRoute component={CreateBlog} path="/create-blog" />
                  <ProtectedRoute component={CreateNews} path="/create-news" />

                  <Route path={["/blog/:gameType/:id"]}>
                    <Blog />
                  </Route>
                  <Route path={["/profile/:username"]}>
                    <MyPage setUserPhoto={setUserPhoto} />
                  </Route>
                  <Route path={["/:gameType"]}>
                    <GamePageAbout />
                  </Route>
                </Switch>
              </PhotoContext.Provider>
            </AccountContext.Provider>
          </LiveResultContext.Provider>
        </ActiveGamesContext.Provider>
      </Main>
      {/* </Auth0ProviderWithHistory>
      </Router> */}
    </>
  );
}

function AppEmpty() {
  return (
    <Switch>
      <Route path="*">
        <EmptyPage />
      </Route>
    </Switch>
  );
}

//export default App
export default Sentry.withProfiler(App);

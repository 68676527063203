import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH_DOMAIN; // 'dev-uzdd6cno.eu.auth0.com';
  const clientId = process.env.REACT_APP_AUTH_CLIENTID;  // 'cD79DO8MIBaS7yDB9eQxkTmIorhEwfwM';
  const audience = process.env.REACT_APP_AUTH_AUDIENCE
  const history = useHistory()

  const onRedirectCallback = appState => {
    //console.log("pusing history", appState)
    history.push(appState?.returnTo || window.location.pathname)
    // history.push(window.location.pathname);
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={`${window.location.origin}`}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory

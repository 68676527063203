import styled from 'styled-components'
import {
  ToolsPanel
} from '../../../../styles/globalStyle'

export const GuardContainer = styled(ToolsPanel)`
> p {
  margin-top: 0;
  display: flex;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
}
`

import styled from 'styled-components'
import { breakPoints, colors, font } from '../../../../styles/constants'
import { CircleBtn } from '../../../../styles/globalStyle'

export const ColorGrid = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  ${props => props.bg};
`

export const SelectButton = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: ${colors.white};
`

export const Circle = styled(CircleBtn).attrs({ as: 'span' })`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isSelected, points }) =>
    isSelected && points ? colors.white : colors.darkGrey};
  font-size: ${font.size.medium};
  font-weight: ${font.weight.bold};

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    font-size: 1.2rem;
    width: 2.8rem;
    height: 2.8rem;
  }
`

export const PointPercentage = styled.div`
  position: absolute;
  font-size: ${font.size.xxsmall};
  font-weight: ${font.weight.semiBold};
  z-index: 0;
  top: 0;
  color: ${({ isSelected }) => (isSelected ? colors.darkGrey : colors.grey)};
  display: flex;
  align-items: flex-end;
  padding-bottom: 2px;
  height: calc(50% - 1.7rem);

  @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
    font-weight: ${font.weight.bold};
    height: calc(50% - 1.2rem);
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    font-size: ${font.size.medium};
    align-items: center;
  }
`

export const PointInput = styled.input`
  position: absolute;
  z-index: 0;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  background-color: ${colors.overlayBlack};
  border: 0.3rem solid ${colors.vividCyan};
  font-size: inherit;
  font-weight: inherit;
  text-align: center;
  color: ${colors.white};
  pointer-events: auto;
  //text-indent: 1.2rem;

  &[type='number'] {
    // -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      opacity: 1;
      margin-right: -0.3rem;
    }
  }

  transition: all 0.3s;
  transform: scale(0);
  transform-origin: center;

  &:focus,
  &:active {
    outline: none;
    transform: scale(1);
  }
  &::placeholder {
    color: white;
  }

  &:focus {
    ::placeholder {
      opacity: 0;
    }
  }
`

import styled, { css } from 'styled-components'
import {
  breakPoints,
  borderRadius,
  colors,
  font,
  spacers
} from '../../../styles/constants'

export const UserRowsContainer = styled.div`
  color: ${colors.lightGrey};
  border-radius: ${borderRadius.xsmall};
  overflow: hidden;
  .scroll{
    overflow: auto;
  }
`

export const UserRowsTool = styled.div`
  font-size: ${font.size.xsmall};

  @media screen and (min-width: ${breakPoints.xlarge}) {
    font-size: ${font.size.small};
  }

  .userRowEventInfo {
    display: flex;
    flex-direction: row;
    position: sticky;
    left: 0;
    flex-wrap: wrap;
    flex: 1 0 auto;
    width: 6.4rem;
  }

  .userRowEventOutcomeLabel {
    height: 3rem;
    width: 100%;
    background: ${colors.darkGrey};
    border-top: ${spacers.xxsmall} solid ${colors.darkGrey2};
    border-right: ${spacers.xxsmall} solid ${colors.darkGrey2};
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-shadow: inset 0 0 0 2px ${colors.vividCyan};

    padding: ${spacers.medium} ${spacers.large};
    text-transform: uppercase;
    font-size: 8px;
    white-space: nowrap;

    @keyframes ex {
      0% {
        opacity: 0;
      }
      25% {
        background: ${colors.svenskaSpelRed};
      }
      75% {
        opacity: 1;
      }
      100% {
        background: ${colors.darkGrey};
      }
    }
    animation: ${({ isNewBestRow }) => (isNewBestRow ? 'ex 2s;' : null)};

    transition: ${({ isNewBestRow }) =>
      isNewBestRow
        ? 'box-shadow 1500ms cubic-bezier(.02,1.02,.81,1)'
        : 'background 150ms ease-in-out'};

    box-shadow: ${({ isNewBestRow }) =>
      isNewBestRow ? '0 0 0 2rem rgba(255, 0, 0, 0)' : '0 0 0  rgba(255, 0, 0, 0.8)'};

    span:nth-child(2) {
      font-size: ${font.size.large};
      color: ${colors.white};
    }
  }

  .userRowEventNumber,
  .userRowEventOutcome {
    display: flex;
    flex-direction: column;
    background-color: initial;
    padding: 0;
    border-right: ${spacers.xxsmall} solid ${colors.darkGrey2};
    flex: 0 1 auto;
    box-sizing: content-box;
    width: calc(50% - 2px);

    font-weight: ${font.weight.bold};

    > * {
      height: 2.4rem;
      background-color: ${colors.darkGrey};
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (min-width: ${breakPoints.xlarge}) {
        height: 3.4rem;
      }

      + * {
        margin-top: ${spacers.xxsmall};
      }

      font-size: inherit;
      line-height: inherit;
    }
  }

  .userRowEventOutcome > * {
    background-color: ${colors.svenskaSpelRed};
    color: ${colors.white};
  }
`

export const UserRowsHeader = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: ${spacers.xxsmall};

  .item-center {
    border-top-left-radius: ${borderRadius.xsmall};
    flex: 1 0 auto;
    font-weight: ${font.weight.semiBold};

    > p {
      max-width: 12rem;
      color: ${colors.white};
      margin-bottom: 0;
    }
  }

  > * {
    background-color: ${colors.darkGrey};
    display: flex;
    align-items: center;
    padding: ${spacers.medium} ${spacers.large};

    + * {
      margin-left: ${spacers.xxsmall};
    }
  }

  button.userRowsToggle {
    border-top-right-radius: ${borderRadius.xsmall};
    border: none;
    height: inherit;
    color: inherit;
    cursor: pointer;
    color: ${colors.white};
    box-shadow: inset 0 0 0 2px ${colors.vividCyan};

    width: 6.4rem;
    justify-content: center;
    font-weight: ${font.weight.semiBold};
    background-color: ${colors.grey};
    font-size: ${font.size.xxsmall};
  }
  ${props => props.hide && css`
    @media screen and (max-width:1361px){
      display: none;
    }
  `}
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: auto;
  scrollbar-color: dark;
`

export const UserRowsList = styled.ul`
  width: 100%;
  height: initial;
  display: flex;

  background-color: ${colors.panelDarkGrey};
  box-shadow: inset 0 -4.2rem 0 0 #303e46;

  li {
    ${({ isModalOpen }) =>
      isModalOpen
        ? `flex: 0 1 2.125rem; min-width: 2.339rem;`
        : `flex: 0 0 calc((100% - 18px) * 1/10);`}

    @media screen and (max-width: ${breakPoints.largeMax}) {
      ${({ isModalOpen }) => (isModalOpen ? `flex: 0 0 2rem;` : null)}
    }

    @media screen and (max-width: 510px) {
      ${({ isModalOpen }) => (isModalOpen ? `flex: 0 0 2.125rem;` : null)}
    }
  }
`

export const InnerColList = styled.li`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: initial;
  padding: 0;

  + * {
    margin-left: ${spacers.xxsmall};
  }

  > * {
    height: 2.4rem;
    background-color: ${colors.darkGrey};
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: ${font.weight.semiBold};

    @media screen and (min-width: ${breakPoints.xlarge}) {
      height: 3.4rem;
    }

    + * {
      margin-top: ${spacers.xxsmall};
    }
  }
`

export const InnerUserSign = styled.span`
  ${({ correctBet }) =>
    correctBet
      ? `background-color: ${colors.vividCyan};`
      : `background-color: ${colors.darkGrey};`}
  // width: 1.5rem;
  height: 2.4rem;
  color: ${colors.darkGrey2};
  box-shadow: 1px 0 0 1px ${colors.darkGrey2};

  font-size: inherit;
  line-height: inherit;

  @media screen and (min-width: ${breakPoints.xlarge}) {
    height: 3.4rem;
  }
`

export const CorrectRowsList = styled(InnerColList)`
  flex-direction: row;
`

export const CorrectRows = styled.div`
  // width: 1.5rem;
  height: 4rem;
  color: ${colors.lightGrey};
  box-shadow: 1px 0 0 1px ${colors.darkGrey2};
`

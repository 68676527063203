import React, { useEffect, useState, useContext } from "react";

import { ReductionPopup } from "./Popup.elements";
import "reactjs-popup/dist/index.css";

import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { ReactComponent as SvenskaSpelLogo } from "../../assets/svenskaspel-logomark.svg";
import { ReactComponent as SaveHandin } from "../../assets/save-coupon.svg";
import { ReactComponent as SendHandin } from "../../assets/send.svg";

import { GameContext } from "../../context/gameContext";

import { startAffiliate, removeAffiliate } from "../../utils/helperFunctions";

import { useAuth0 } from "@auth0/auth0-react";
import { IsLocked } from "../Game/CouponSettings/CouponSettings.elements";
import reduceringSm from "../../assets/reducering-sm.svg";
import reduceringEm from "../../assets/reducering-em-big.svg";
import Tooltip from "rc-tooltip";
import CustomChekbox from "../CustomCheckbox/CustomChekbox";
import { ToolsSmallHeading } from "../../styles/globalStyle";
import { Link } from "react-router-dom";
import { ReactComponent as Cup } from "../../assets/cup.svg";
import { ContestContext } from "../../context/contestContext";

export default function HandinModal({
  open,
  setIsModalOpen,
  handinRows,
  isReduced,
  cloneCount,
  betRowAmount,
  currentId,
  saveCoupon,
  setStatus,
  currentNickname,
  copied,
  topptippsify,
  isLocked,
  isErrorCoupon,
}) {
  // This file assumes that the rows that go in can always he handed in.

  const { isLoading, isAuthenticated, user } = useAuth0();

  const [rows, setRows] = useState(handinRows);
  const [urls, setUrls] = useState([]);
  const { gameType, drawNumber } = useContext(GameContext);
  const { isSM } = useContext(ContestContext);

  const getUrl = (data) => {
    return fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/${topptippsify(
        gameType
      )}/${drawNumber}/handin`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
  };

  const onOpen = () => {
    removeAffiliate();

    if (!isReduced) {
      return;
    }

    let finalrows;
    if (gameType === "stryktipset" || gameType === "europatipset") {
      if (handinRows.type === "single" && handinRows.rows.length > 10000) {
        finalrows = Array(Math.floor(handinRows.rows.length / 10000 + 1))
          .fill()
          .map((el) => []);
        handinRows.rows.forEach((row, i) => {
          finalrows[Math.floor(i / 10000)].push(row);
        });
      } else {
        finalrows = [[...handinRows.rows]];
      }
    } else {
      if (handinRows.type === "single" && handinRows.rows.length > 1000) {
        finalrows = Array(Math.floor(handinRows.rows.length / 1000 + 1))
          .fill()
          .map((el) => []);
        handinRows.rows.forEach((row, i) => {
          finalrows[Math.floor(i / 1000)].push(row);
        });
      } else {
        finalrows = [[...handinRows.rows]];
      }
    }

    let promises = finalrows.map((rows) => {
      const data = {
        betRowAmount: betRowAmount,
        system: handinRows.type,
        items: rows.map((row) => {
          return row.join();
        }),
      };
      return getUrl(data);
    });

    for (let i = 0; i < cloneCount; i++) {
      const clones = finalrows.map((rows) => {
        const data = {
          betRowAmount: betRowAmount,
          system: handinRows.type,
          items: rows.map((row) => {
            return row.join();
          }),
        };
        return getUrl(data);
      });
      promises = promises.concat(clones);
    }

    Promise.all(promises)
      .then((values) => {
        return Promise.all(values.map((r) => r.json()));
      })
      .then((data) => {
        let nUrls = data.map((el) => el.response.url);
        setUrls(nUrls);

        // Affiliate links
        startAffiliate();
      })
      .catch((e) => {
        let nUrls = e.map((el) => el.message);
        setUrls(nUrls);
      });

    saveCoupon({
      nickname: currentId ? currentNickname : "",
      saveAsCopy: !currentId,
      savestatus: "final",
    });
    setStatus("final");
  };

  const [sendToEmail, setSendToEmail] = useState("");

  return (
    <ReductionPopup
      open={open}
      onOpen={onOpen}
      onClose={() => {
        setIsModalOpen(false);
        setUrls([]);
        removeAffiliate();
      }}
      position="center"
      copied={copied}
    >
      <>
        <button className="modalClose" onClick={() => setIsModalOpen(false)}>
          <CloseIcon alt="close" />
        </button>

        {!isReduced ? (
          <div> Reducera ditt system! </div>
        ) : (
          <>
            {isAuthenticated && user ? (
              <p>Inloggad som: {user.email || user.name}</p>
            ) : (
              <p style={{ fontSize: "1.5em" }}>EJ INLOGGAD</p>
            )}
            {cloneCount ? (
              <p className="modalAlert">
                {" "}
                Din kupong är klonad {cloneCount} gång(er).{" "}
              </p>
            ) : null}

            <label>Kopiera kupong-ID </label>
            <div className="handinSave" style={{ display: "flex" }}>
              <button className="icon" onClick={saveCoupon}>
                <SaveHandin alt="Save" />
              </button>
              <div>
                {currentId}
                <span className="copied" aria-hidden="true">
                  Kupongen är kopierad till "​urklipp"
                </span>
              </div>
            </div>

            <label>Dela kupong-ID via e-post </label>
            <div className="handinEmail" style={{ display: "flex" }}>
              <a
                className="icon sendMailBtn"
                href={`mailto:${sendToEmail}?subject=Kupong ID&body=Kupong ID: %0A ${currentId}`}
              >
                <SendHandin alt="Send e-mail" />
              </a>
              <div>
                <input
                  id="mailinput"
                  type="email"
                  placeholder="Skriv din e-postadress här"
                  onChange={(ev) => setSendToEmail(ev.target.value)}
                />
              </div>
            </div>

            {urls.length > 1
              ? urls.map((url, i) => {
                  return (
                    <p key={i}>
                      <a
                        className="handinCta"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          Lämna in hos <br /> Svenska Spel Sport & Casino
                        </span>
                        <span>
                          Kupong <br /> del {i + 1}
                        </span>

                        <SvenskaSpelLogo alt="Svenska Spel" />
                      </a>
                    </p>
                  );
                })
              : urls.map((url, i) => {
                  return (
                    <p key={i}>
                      <a
                        className="handinCta"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>
                          Lämna in hos <br /> Svenska Spel Sport & Casino
                        </span>
                        <SvenskaSpelLogo alt="Svenska Spel" />
                      </a>
                    </p>
                  );
                })}
            {isSM
              ? gameType === "europatipset" &&
                isAuthenticated &&
                (!isLocked ? ( // Changed to enable contest
                  <IsLocked small={true}>
                    <div className="turnament-img">
                      <img alt="reduceringEm" src={reduceringEm} />
                    </div>
                    <div className="checkbox-container"></div>
                    <h4 style={{ fontSize: "12px" }}>
                      För att delta måste du aktivera kupongen. Stäng denna ruta
                      och anmäl dig till Reducering-EM.
                    </h4>
                    <div className="turnamet-notes">
                      <ol>
                        <li>1. Gör ett system på max 500 rader</li>
                        <li>2. Checka boxen "Delta i Reducering-EM"</li>
                        <li>3. Klicka på "Lämna in"-knappen</li>
                        <li>4. Vinnaren får en resa värd 10 000 kr!</li>
                      </ol>
                      <Cup className="svg" />
                    </div>
                  </IsLocked>
                ) : isErrorCoupon ? (
                  <IsLocked small={true}>
                    <div className="turnament-img">
                      <img alt="reduceringEm" src={reduceringEm} />
                    </div>
                    <div className="checkbox-container"></div>
                    <h4>Denna kupong deltar i Reducering-EM!</h4>
                  </IsLocked>
                ) : (
                  <IsLocked small={true}>
                    <div className="turnament-img">
                      <img alt="reduceringEm" src={reduceringEm} />
                    </div>
                    <div className="checkbox-container"></div>
                    <h4>Grattis! Du deltar nu i Reducering-EM!</h4>
                  </IsLocked>
                ))
              : null}
            {isSM
              ? gameType === "europatipset" && // Changed to enable contest
                !isAuthenticated && (
                  <IsLocked small={true}>
                    <div className="turnament-img">
                      <img alt="reduceringEm" src={reduceringEm} />
                    </div>
                    <div className="checkbox-container"></div>
                    <h4>För att delta - Skapa ett konto</h4>
                  </IsLocked>
                )
              : null}
            <p>
              Genom att klicka på{" "}
              <strong>LÄMNA IN HOS SVENSKA SPEL SPORT & CASINO</strong> skickas
              du vidare till Svenska Spel Sport & Casino för inlämning.
            </p>

            <p>
              * Går inte ditt spel att lämna in?
              <br />
              <strong>
                <a
                  href="https://spela.svenskaspel.se/villkor-och-integritet/spelregler"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Läs mer om Svenska Spel Sport & Casinos regler här.
                </a>
              </strong>
            </p>
          </>
        )}
      </>
    </ReductionPopup>
  );
}

import React,{useEffect, useState} from 'react'
import BlogCard from '../../../components/Blog/BlogCard'
import { InnerContainer } from '../../../styles/globalStyle'
import SwiperCore, { Navigation } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import { useAuth0 } from '@auth0/auth0-react'
import {BlogMain} from './BlogPage.elements'
import HomeHeader from '../../../components/Headers/HomeHeader'
import Footer from '../../../components/Footer/Footer'
import { ReactComponent as LoadingLogo } from '../../../assets/reducering-logomark.svg'

const BlogPage = () => {
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  SwiperCore.use([Navigation])
  const [powerplay,setPowerplay] = useState([]);
  const [europatipset,setEuropatipset] = useState([]);
  const [stryktipset,setStryktipset] = useState([]);
  const [topptipset,setTopptipset] = useState([]);
  const [loader,setLoader] = useState(true)
  //Fetch blog
  const fetchBlog = async (type) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers={}
    }

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/blog?gameType=${type}`,{
      headers: headers
    }).then(res =>{

      return res.json()
    }).then(data=>{
      if(type =='powerplay') setPowerplay(data?.blogs)
      else if(type =='europatipset') setEuropatipset(data?.blogs)
      else if(type =='stryktipset') setStryktipset(data?.blogs)
      else if(type =='topptipset') setTopptipset(data?.blogs)
      //setPowerPlay(data?.blogs)
    })
  }
  //
  useEffect(  ()=>{
    if(!isLoading) {
      fetchBlog('powerplay')
      fetchBlog('europatipset')
      fetchBlog('stryktipset')
      fetchBlog('topptipset')
      setLoader(false)
    }
  },[isLoading])
  return (
    <>{
      loader ? (
        <div className='loader'>
          <div className='loader-animation' />
          <LoadingLogo />

          <p>
            Loading ...
          </p>
        </div>
      ) : (
     <>
    <HomeHeader/>
    <InnerContainer>
      <BlogMain>
        <div className='item'>
          <h3>Stryktipset</h3>
          {
            stryktipset.length > 0 ?
              (<div className='wrapper-slider'>
            <Swiper slidesPerView={1} freeMode={true} spaceBetween={35} navigation={true} breakpoints={{
              "568": {
                "slidesPerView":2 ,
                "spaceBetween": 20
              },
              "768": {
                "slidesPerView": 2,
                "spaceBetween": 20
              },
              "1124": {
                "slidesPerView": 3,
                "spaceBetween": 35
              },
              "1324": {
                "slidesPerView": 4,
                "spaceBetween": 33
              }
            }} className="mySwiper">
                { stryktipset.map(item => <SwiperSlide> <BlogCard blog={item} /> </SwiperSlide> ) }
            </Swiper>
          </div>)
            :
              <p className='no-data'>Inga tillgängliga blogginlägg</p>
          }
        </div>
        <div className='item'>
          <h3>Europatipset</h3>
          {
            europatipset.length > 0 ?
            (<div className='wrapper-slider'>
            <Swiper slidesPerView={1} freeMode={true} spaceBetween={35} navigation={true} breakpoints={{
              "568": {
                "slidesPerView":2 ,
                "spaceBetween": 20
              },
              "768": {
                "slidesPerView": 2,
                "spaceBetween": 20
              },
              "1124": {
                "slidesPerView": 3,
                "spaceBetween": 33
              },
              "1324": {
                "slidesPerView": 4,
                "spaceBetween": 33
              }
            }} className="mySwiper">
                { europatipset.map(item => <SwiperSlide> <BlogCard blog={item} /> </SwiperSlide> ) }
            </Swiper>
          </div>)
              :
              <p className='no-data'>Inga tillgängliga blogginlägg</p>
          }
        </div>
        <div className='item'>
          <h3>Topptipset</h3>
          {
            topptipset.length > 0 ?
              (<div className='wrapper-slider'>
            <Swiper slidesPerView={1} freeMode={true} spaceBetween={35} navigation={true} breakpoints={{
              "568": {
                "slidesPerView":2 ,
                "spaceBetween": 20
              },
              "768": {
                "slidesPerView": 2,
                "spaceBetween": 20
              },
              "1124": {
                "slidesPerView": 3,
                "spaceBetween": 35
              },
              "1324": {
                "slidesPerView": 4,
                "spaceBetween": 33
              }
            }} className="mySwiper">
              { topptipset.map((item,index) => <SwiperSlide data-hash={`slide${index}`}>
                <BlogCard blog={item} /> </SwiperSlide> ) }
            </Swiper>
          </div>)
            :
              <p className='no-data'>Inga tillgängliga blogginlägg</p>
          }
        </div>
        <div className='item'>
          <h3>Powerplay</h3>
          {
            powerplay.length > 0 ?
              (<div className='wrapper-slider'>
            <Swiper slidesPerView={1} freeMode={true} spaceBetween={35} navigation={true} breakpoints={{
              "568": {
                "slidesPerView":2 ,
                "spaceBetween": 20
              },
              "768": {
                "slidesPerView": 2,
                "spaceBetween": 20
              },
              "1124": {
                "slidesPerView": 3,
                "spaceBetween": 35
              },
              "1324": {
                "slidesPerView": 4,
                "spaceBetween": 33
              }
            }} className="mySwiper">
                { powerplay.map(item => <SwiperSlide> <BlogCard blog={item} /> </SwiperSlide> ) }
            </Swiper>
          </div>)
            :
              <p className='no-data'>Inga tillgängliga blogginlägg</p>
          }
        </div>
      </BlogMain>
    </InnerContainer>
    <InnerContainer>
     <Footer />
    </InnerContainer>
       </>
      )}
    </>
  )
}

export default BlogPage
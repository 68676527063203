import styled, { css } from 'styled-components'
import {
  breakPoints,
  colors,
  font,
  spacers,
  borderRadius,
  headerHeight
} from '../../styles/constants'
import { Header, Profile } from '../Headers/Headers.elements'
import {UserLogButton} from '../Headers/Headers.elements'

export const ProfileGame = styled(Profile)`
  margin-left: 4.5rem;
 .author-name{
  width: auto;
  @media screen and (max-width: 550px){
  display: none;
  }
 }
 @media screen and (max-width: 968px){
  margin-right: 2rem;
 }
 @media screen and (max-width: 369px){
 margin-right: 1rem;
 margin-left: 1rem;
 }
`
export const UserLogButtonGame = styled(UserLogButton)`
margin-left: 4rem;
max-width: 8rem;
font-size: 1.2rem;
padding: 1rem 0.5rem;
  @media screen and (max-width: 968px){
    margin-right: 2rem;
 }
`


export const GameTypeHeader = styled(Header)`
  position: fixed;
  left: 0;
  background: transparent;
  border-bottom: ${spacers.medium} solid ${colors.darkGrey2};
  grid-template-columns:
    minmax(${headerHeight.large}, 1fr)
    minmax(auto, calc(${breakPoints.xlarge} - ${spacers.xsmall} * 2))
    minmax(${headerHeight.large}, 1fr);
  grid-template-columns: ${props => props.grid};
  
  @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
    grid-template-columns:
      minmax(${headerHeight.large}, 1fr)
      minmax(auto, calc(${breakPoints.medium} - ${spacers.xsmall} * 2))
      minmax(${headerHeight.large}, 1fr);
  }

  @media screen and (min-width: ${breakPoints.large}) {
    height: ${headerHeight.large};
    + * {
      margin-top: calc(${headerHeight.large} + 5px);
    }
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    border-bottom-width: ${spacers.small};
    position: sticky;
    width: 100vw;
    margin-left: -${spacers.medium};
    margin-right: -${spacers.medium};
    margin: ${props => props.deleteMargin ? '0' : 'inherit'};
  }

  @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
    height: ${headerHeight.small};
    + * {
      margin-top: calc(${headerHeight.small} + 5px);
    }
  }

  .headerItem {
    &:nth-child(1) {
      grid-column: 1 / 1;
      display: flex;
      padding-bottom: ${spacers.xsmall};
    }

    &:nth-child(2) {
      display: grid;
      row-gap: ${spacers.small};
    }

    &:nth-child(3) {
      grid-column: 3 / 3;
    }
  }

  .gameViewToggleContainer {
    width: fit-content;

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      position: absolute;
      top: 2.5rem;
      right: 7rem;
    }
  }

  .gameViewToggle {
    border: none;
    background: ${colors.grey};
    color: ${colors.white};
    font-weight: ${font.weight.semiBold};
    font-size: ${font.size.small};
    padding: ${spacers.medium} ${spacers.large};
    box-shadow: 0 0 0 2px ${colors.vividCyan};
    border-radius: ${borderRadius.xsmall};
    white-space: nowrap;
    margin-left: 3rem;
    margin-right: 3rem;

    transition: all 250ms cubic-bezier(0.02, 1.02, 0.8, 1);

    @media screen and (max-width: ${breakPoints.largeMax}) {
      margin-left: 1.25rem;
      margin-right: 1.25rem;
    }

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      font-size: ${font.size.xsmall};
      padding: ${spacers.xsmall} ${spacers.medium};
      margin-left: auto;
      margin-right: auto;
    }

    &:hover {
      background: ${colors.toolGreyActive};
      box-shadow: 0 0 0 3px ${colors.vividCyan};
    }
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    grid-template-columns: auto min-content;
    position: relative;
  
    .headerItem:nth-child(1) {
      grid-column: initial;
      flex-direction: row;
      display: flex;
      align-items: center;
      height: ${headerHeight.small};
      display: none;
    }

    .headerItem:nth-child(2) {
      white-space: no-wrap;
      grid-column: 1 / span 2;
      grid-row-start: 1;
      padding-left: ${spacers.small};
      padding-right: ${spacers.small};
      grid-template-areas: 
            "head ."
            "jack profile";
    }
    
    .headerItem:nth-child(3) {
      grid-row-start: 1;
      grid-column-start: 2;
    }

    .headerItem:nth-child(1),
    .headerItem:nth-child(3) {
      @media screen and (max-width: ${breakPoints.mediumMax}) {
        padding: 0.1rem 1rem 1rem 1rem;
        height: 5rem;
      }
    }
  }

  .reducering-logo {
    @media screen and (max-width: 2800px) and (min-width: ${breakPoints.medium}) {
      justify-content: flex-end;

      > a {
        width: 100%;
        max-width: ${headerHeight.large};
        height: 100%;

        .reducering-logomark {
          width: inherit;
          max-width: inherit;
          height: inherit;
          padding: ${spacers.large} ${spacers.small} ${spacers.large} ${spacers.large};
        }
      }

      .reducering-wordmark {
        display: none;
      }
    }

    @media screen and (max-width: ${breakPoints.largeMax}) and (min-width: ${breakPoints.medium}) {
      > a .reducering-logomark {
        padding: ${spacers.large} ${spacers.small} ${spacers.large} ${spacers.large} !important;
      }
    }

    @media screen and (max-width: ${breakPoints.xxlargeMax}) {
      margin-left: 0;
    }
  }

  @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
    .navbar {
      height: ${headerHeight.small};
    }
  }

  @media screen and (max-width: ${breakPoints.largeMax}) {
    .navbar {
      height: 4rem;
      width: initial;

      img {
        height: 4rem;
        width: 4rem;
      }
    }
  }

  @media screen and (max-width: calc(${breakPoints.xxlarge} + 48px)) and (min-width: ${breakPoints.medium}) {
    .navbar {
      width: 6.75rem;
      margin-left: 0;
      margin-right: auto;
    }
  }
  ${props=>props.gridTemplate && css`
    grid-template-columns: ${props.gridTemplate};
    background: #273238;
  `}
`

export const GameTypeHeaderContent = styled.div`
  // grid-template-columns: max-content auto auto auto min-content;
  grid-template-columns: max-content min-content 0 auto min-content;
  align-items: center;

  white-space: nowrap;

  @media screen and (max-width: ${breakPoints.xlargeMax}) {
     grid-template-columns: max-content min-content 0 auto min-content;
  // grid-template-columns: max-content auto ${props => props.jackpot ? 'auto' : '0' } auto min-content;

    > *:nth-child(3) {
      flex-wrap: nowrap;
    }
  }

  @media screen and (min-width: ${breakPoints.medium}) {
    background-color: ${props => props.gameTypeColor};
    background-image: linear-gradient(
      to right,
      ${props => props.gameTypeColor} 0%,
      ${props => props.gameTypeColor} 60%,
      ${colors.darkGrey2} 90%,
      ${colors.darkGrey2} 100%
    );
    height: inherit;
  }

  // Week
  > *:nth-child(2) {
    @media screen and (min-width: ${breakPoints.xlarge}) {
      text-align: center;
    }
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    grid-template-columns: auto auto auto min-content;

    // Logo
    > *:nth-child(1) {
      height: ${headerHeight.small};
      background-color: ${props => props.gameTypeColor};
      grid-column: 1 / 4 span;
      grid-row-start: 1;
    }

    &.live > *:nth-child(1) {
      grid-column: 1 / 4 span;
    }

    > *:nth-child(3) {
      // grid-column: ${props => props.jackpot ? '3 / 5' : '3 / 5'};
      grid-column: 3 / 5;
      grid-row-start: 2;
      border-top-right-radius: ${borderRadius.xsmall};
      border-bottom-right-radius: ${borderRadius.xsmall};
      background-color: ${props => props.gameTypeColor};
      height: 5rem;
      // margin:auto;
    }

    > *:nth-child(4) {
      // grid-column: ${props => props.jackpot ? '1 / 5' : '1 / 5'};
      // grid-column: 1 / 3;
      grid-column: 1 / 5;
      grid-row-start: 2;
      border-top-left-radius: ${borderRadius.xsmall};
      border-bottom-left-radius: ${borderRadius.xsmall};
      @media screen and (max-width: 468px){
        border-top-right-radius: ${borderRadius.xsmall};
        border-bottom-right-radius: ${borderRadius.xsmall};
      }
      background-color: ${props => props.gameTypeColor};
      height: 5rem;
    }

    // Button
    > *:nth-child(5) {
      grid-column: 4 / 4;
      grid-row-start: 2;
    }
  }
`

export const GameTypeLogo = styled.div`
  display: flex;
  height: inherit;

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    //margin-left: -${spacers.medium};
    border-top-right-radius: ${borderRadius.xsmall};
    border-bottom-right-radius: ${borderRadius.xsmall};
  }

  & > .svenskaspel-logomark {
    background: ${colors.svenskaSpelRed};
    margin-right: ${spacers.xlarge};

    @media screen and (max-width: ${breakPoints.xlargeMax}) {
      margin-right: ${spacers.large};
    }

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      height: 70px;
    }
  }

  & > .gametype-wordmark {
    margin-right: 2em;
    align-self: center;

    &.powerplay {
      @media screen and (max-width: 430px) {
        height: 3rem;
      }
    }
  }

  p {
    font-size: ${font.size.large};
    align-self: flex-end;
    padding-top: ${spacers.medium};
    padding-bottom: 2rem;
  }

  img {
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`
export const GameTypeTurnament = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  padding: 0.5rem 0.8rem;
  background: linear-gradient(90deg,#40C7A8 0%,#30B29E 100%);
  border-radius: 0.375rem;
  margin-right: 2rem;
  p{
    font-size: 1.4rem;
    text-align: left;
  }
  h5{
    font-size: 1.4rem;
    text-align: left;
    font-weight: 700;
  }
  svg{
    width: 3.2rem;
    height: 3.2rem;
    fill: #FFFFFF;
  }
  ${props => props.isHideBig && css `
    height: 4rem;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
     @media screen and (max-width: 1400px){
        display: block;
    }
  `}
  ${props => props.isShowBig && css `
    @media screen and (max-width: 1400px){
    display: none;
    }
  `}
 `

export const GameTypeInfo = styled.div`
  text-align: right;
  padding: ${spacers.medium};
  justify-content: center;
  display: flex;
  flex-direction: column;

  & > p {
    font-size: 1.1rem;

    @media screen and (min-width: ${breakPoints.xlarge}) {
      font-size: ${font.size.medium};
    }
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    padding: ${spacers.large};
    text-align: left;
  }
  
`

export const GameTypeInfoWrapper = styled.div`
  justify-content: space-between;
  display: flex;

  .extra-pengar {
    p {
      margin-top: 0;
      margin-left: 0;
      text-indent: 0;
      align-self: flex-start;
    }

    > img {
      @media screen and (max-width: ${breakPoints.xlargeMax}) {
        width: 73px;
        height: 35px;
      }

      @media screen and (max-width: ${breakPoints.smallMax}) {
        margin-top: -1px;
        width: 65px;
        height: 27px;
      }
    }
  }


  .isLoggedInImg {
   width: 5em;
  } 

  div:first-child {
    text-align:left;

    p:first-child {
      font-weight: ${props => props.isAuthenticated ? 'normal' : 'bolder' }
    }

    p:last-child {
      font-weight: ${props => props.isAuthenticated ? 'bolder' : 'normal' }
    }



  }

  div:last-child {
    text-align:right;
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    
    div:first-child {
      text-align:right;
      justify-content: center;
      margin-right: 150px;
    }

    div:last-child {
      text-align:left;
    }

    justify-content: space-between;
    flex-direction: row-reverse;
    text-align: left;
  }
  @media screen and (max-width: 468px) {
   div:first-child {
      text-align:right;
      justify-content: flex-start!important;
      margin-right: 50px;
      img {
        width: 60px;
      }
      .small{
        width:70px;
      }
      p {
      font-size: 1rem;
      }
    }
  }
`

export const GameAlarm = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;

  & > button {
    border: none;
    background-color: ${colors.svenskaSpelRed};
    font-size: ${font.size.small};
    color: ${colors.white};
    padding: ${spacers.medium};
    border-radius: ${borderRadius.small};
    display: flex;
    align-items: center;
    text-align: right;
    margin-left: auto;
    cursor: pointer;
    white-space: nowrap;

    @media screen and (min-width: ${breakPoints.medium}) {
      font-size: ${font.size.xsmall};
      margin-left: ${spacers.xlarge};
    }

    @media screen and (max-width: ${breakPoints.smallMax}) {
      font-size: ${font.size.xsmall};
    }

    @media screen and (min-width: ${breakPoints.xlarge}) {
      margin-left: 4rem;
    }
  }

  img {
    margin-right: ${spacers.small};

    @media screen and (max-width: ${breakPoints.smallMax}) {
      width: 18px;
      height: 19px;
    }
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    margin-left: ${spacers.medium};
    height: ${headerHeight.small};

    & > button {
      height: 100%;
    }
  }
`

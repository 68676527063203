export const colors = {
  //Text
  white: '#ffffff',
  white2: '#efffff',
  white3: '#eceff1',
  lighterGrey: '#EBEFF2',
  lightGrey: '#cfd8dc',
  headerGrey: '#596E79',
  panelDarkGrey: '#36474F',
  grey: '#667C89',
  darkGrey: '#455a64',
  darkGrey2: '#263238',
  black: '#000',
  toolGrey: '#506974',
  toolGreyActive: '#58737e',
  anchor: '#36c5af',
  titleGrey: '#8a9da8',
  gold:'#FFD700',
  //Reduce colors
  reduceRed: '#ff6e40',
  reduceYellow: '#ffd600',
  reduceBlue: '#00b8d4',
  reduceGreen: '#00c853',
  reducedTurquoise: '#64FFDA',
  vividCyan: '#1de9b6',
  //Gamebet colors
  gamebetGreen: '#037D32',
  // Game types + Svenska spel
  stryktipset: '#00427a',
  europatipset: '#008733',
  topptipset: '#f29400',
  powerplay: '#a00814',
  bomben: '#920B19',
  svenskaSpelRed: '#ff0000',
  svenskaSpelRedActive: '#ff3d3d',
  // Overlays
  overlayBlack: 'rgba(0, 0, 0, 0.9)',
  overlayToolTip: 'rgba(0, 0, 0, 0.7)',
  // Backgrounds (Gradients)
  toolsGradient:
    'linear-gradient(-45deg, rgba(55, 71, 79, 0.9) 0%, rgba(55, 71, 79, 0.9) 35%, rgba(84, 110, 122, 0.9) 65% )'
}

export const transition = {
  menu: 'all 250ms cubic-bezier(.02,1.02,.8,1)'
}

export const font = {
  weight: {
    thin: 100,
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600
  },

  size: {
    xxsmall: '0.9rem',
    xsmall: '1rem',
    small: '1.2rem',
    medium: '1.4rem',
    large: '1.8rem',
    xlarge: '2.2rem',
    xxlarge: '2.8rem'
  }
}

export const breakPoints = {
  small: '768px',
  smallMax: '767px',

  medium: '960px',
  mediumMax: '959px',

  large: '1024px',
  largeMax: '1023px', // large: '1280px', largeMax: '1279px',

  xlarge: '1366px',
  xlargeMax: '1365px',

  xxlarge: '1920px',
  xxlargeMax: '1919px',

  customSmall: '720px',
  custom: '2350px'
}

export const borderRadius = {
  xsmall: '0.375rem',
  small: '0.5rem',
  medium: '1rem'
}

export const spacers = {
  xxsmall: '0.2rem',
  xsmall: '0.3rem',
  small: '0.4rem',
  medium: '0.5rem',
  large: '0.8rem',
  xlarge: '1.5rem',
  xxlarge: '2.5rem',

  panelMedium: '2.2rem',
  panelLarge: '4rem'
}

export const headerHeight = {
  small: '40px',
  medium: '50px',
  large: '70px'
}

import styled, { createGlobalStyle, css } from "styled-components";
import {
  colors,
  font,
  breakPoints,
  spacers,
  headerHeight,
  borderRadius,
} from "./constants";
import Check from "../assets/checked.svg";
export const GlobalStyle = createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      font-family: 'Roboto', sans-serif;
      box-sizing: border-box;
      text-decoration: none;
      list-style: none;
  }


  .hasScrollBar::-webkit-scrollbar {
    height: 11px;
    width: 11px;
  }

  .hasScrollBar {
    scrollbar-width: auto;
    scrollbar-color: #93989B #1b2023;
  }

  .hasScrollBar::-webkit-scrollbar-track {
    background: #1b2023;
  }

  .hasScrollBar::-webkit-scrollbar-thumb {
    background-color: rgba(102, 124, 137, 0.8);
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.6);
  }

  .hasScrollBar:hover::-webkit-scrollbar-thumb {
    background-color: #93989B;
  }

  html {
    /* this defines 1rem */
    font-size: 62.5%; /* 1rem = 10px 10/16=0.62.5 ~ 62.5% */
    background-color: ${colors.darkGrey2};
  }

  .hide-for-medium {
    @media screen and (min-width: ${breakPoints.medium}) {
      display: none !important;
    }
  }

  .show-for-medium {
    @media screen and (max-width: ${breakPoints.mediumMax}) {
      display: none !important;
    }
  }

  .hide-for-small {
    @media screen and (min-width: ${breakPoints.small}) {
      display: none !important;
    }
  }

  .show-for-small {
    @media screen and (max-width: ${breakPoints.smallMax}) {
      display: none !important;
    }
  }

  .adsbox {
    width: 1px;
    height: 1px;
    position: absolute;
    left: -10000px;
  }

  .btn-global{
    border-radius: 0.5rem;
    background-color: #36c5af;
    box-shadow: 0px 3px 6px #0000004d;
    padding: 1rem 3rem;
    cursor: pointer;
    line-height: 1.6rem;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    color: #FFFFFF;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1.25rem;
  }
`;

// Wrappers
export const InnerContainerLarge = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - ${headerHeight.large} * 2);
  max-width: ${breakPoints.xlarge};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${spacers.xsmall};
  padding-right: ${spacers.xsmall};

  @media screen and (max-width: ${breakPoints.xlargeMax}) {
    max-width: ${breakPoints.medium};
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    max-width: ${breakPoints.customSmall};
    width: 100%;
    padding-left: ${spacers.small};
    padding-right: ${spacers.small};
  }
  ${(props) =>
    props.maxsLength &&
    css`
      max-width: 1135px;
    `}
  ${(props) =>
    props.maxHeight &&
    css`
      height: 100vh;
    `}
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - ${headerHeight.large} * 2);
  max-width: ${breakPoints.large};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${spacers.xsmall};
  padding-right: ${spacers.xsmall};
  .h2__title {
    font-size: 2.8rem;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
    &--paddingTop {
      padding-top: 4.8rem;
    }
  }
  @media screen and (max-width: ${breakPoints.mediumMax}) {
    width: 100%;
    padding-left: ${spacers.small};
    padding-right: ${spacers.small};
    overflow: hidden;
  }
  .section-title {
    text-align: center;
    font-size: 2.8rem;
    margin: 2rem auto 0 auto;
  }
`;

// Columns
export const OneThird = styled.div`
  width: calc(100% / 3);
  @media screen and (max-width: ${breakPoints.xlargeMax}) {
    width: calc(100% / 2);
  }
  @media screen and (max-width: ${breakPoints.mediumMax}) {
    width: 100%;
  }
`;

export const TwoThirds = styled.div`
  width: calc(100% * 2 / 3);

  @media screen and (max-width: ${breakPoints.xlargeMax}) {
    width: calc(100% / 2);
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    width: 100%;
  }
`;

export const OneHalf = styled.div`
  width: calc(100% / 2);

  @media screen and (max-width: ${breakPoints.smallMax}) {
    width: 100%;
  }

  @media screen and (min-width: ${breakPoints.small}) {
    article {
      width: calc((100% - (${spacers.small} / 2)));
    }

    + * {
      article {
        margin-left: calc(${spacers.small} / 2);
      }
    }
  }
`;

// Elements
export const HeroContainer = styled.div`
  width: 100%;
  display: flex;

  img {
    max-width: 100%;
    height: auto;
    align-self: center;
  }
`;

export const Main = styled.main`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: white;
`;

export const GamesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  font-size: 1.3rem;
  font-weight: ${font.weight.light};
  margin-top: 1.4rem;
  grid-gap: 1.4rem;
  > * {
    width: 32.4%;
    max-height: 18rem;
    position: relative;
    border-radius: ${borderRadius.small};
    @media screen and (max-width: 1150px) {
      width: 49%;
      justify-content: space-between;
    }

    strong {
      font-weight: ${font.weight.semiBold};
    }

    + * {
      margin-left: 0;
    }

    @media screen and (max-width: 799px) {
      width: calc((100% - 1.4rem) / 2);

      &:nth-child(2n + 1) {
        margin-left: 0;
      }

      &:nth-child(n + 3) {
        margin-top: ${spacers.small};
      }
    }
  }
`;

export const PageContent = styled(InnerContainer)`
  display: flex;

  > * {
    display: flex;
    flex-wrap: wrap;
    margin-top: ${spacers.small};
  }
`;

export const ArticlePanel = styled.article`
  width: 100%;
  padding: ${spacers.panelLarge};
  border-radius: ${borderRadius.small};
  display: block;

  > * {
    max-width: 62rem;
  }

  background: linear-gradient(
    -45deg,
    ${colors.panelDarkGrey} 0%,
    ${colors.panelDarkGrey} 15%,
    ${colors.headerGrey} 85%
  );

  // Typography
  h3,
  h4,
  p {
    margin-bottom: ${spacers.xlarge};
  }

  h3,
  h4 {
    &:not(:first-child) {
      margin-top: ${spacers.xxlarge};
    }

    margin-bottom: 1.25rem;
  }

  h3 {
    font-size: 2.3rem;
    font-weight: ${font.weight.bold};
  }

  h4 {
    font-size: 1.8rem;
    font-weight: ${font.weight.bold};
  }

  p,
  ol,
  ul {
    color: ${colors.lightGrey};
    font-size: ${font.size.small};

    &.preamble {
      font-size: 1.5rem;
      font-weight: ${font.weight.semiBold};
    }

    strong,
    em {
      color: ${colors.white};
    }
  }

  a {
    color: ${colors.anchor};
    text-decoration: underline;
  }

  ol > li {
    list-style-type: decimal;
  }

  ul > li {
    list-style-type: disc;
  }

  ol,
  ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 1.5rem;

    li {
      margin-bottom: inherit;

      > ul {
        > li {
          list-style: none;
          &:before {
            content: "–";
            position: absolute;
            margin-left: -1.25em;
          }
        }
      }
    }
  }
`;

export const ContestBody = styled(ArticlePanel)`
  ul.dashed > li::marker {
    content: "-  ";
  }
  ${(props) =>
    props.flex &&
    css`
      display: flex;
      padding: 4rem 2rem;
      grid-gap: 3rem;
      ul.dashed > li {
        position: relative;
      }
      ul.dashed > li::marker {
        display: none;
        content: none;
      }
      ul.dashed > li::after {
        content: "";
        width: 0.6rem;
        position: absolute;
        height: 0.6rem;
        left: -1.5rem;
        top: 50%;
        border-radius: 50%;
        background-color: #36c5af;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      h3 {
        font-size: 2.7rem;
        text-transform: uppercase;
      }
      p {
        font-size: 16px;
        color: #ffffff;
      }
      .right {
        max-width: 480px;
        width: 100%;
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;
        padding-right: 2rem;
        border-right: 2px solid #6e7c82;
        @media screen and (max-width: 1155px) {
          border-right: 0;
        }
      }
      .left {
        max-width: 460px;
        width: 100%;
      }
      @media screen and (max-width: 1155px) {
        .right {
          max-width: 100%;
        }
        .left {
          max-width: 100%;
        }
      }
    `}
`;

export const ArticlePanelWithLink = styled(ArticlePanel)`
  border-bottom-right-radius: 10rem;
  position: relative;

  .contestImage {
    width: 65%;
    position: relative;
    margin: auto;
    margin-top: 6em;
    margin-bottom: 2em;
    display: block;
    transform: rotate(-9deg);
  }

  .readMore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    line-height: 0.75;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    right: 0;
    color: ${colors.lightGrey};
    text-decoration: none;
    border-radius: 50%;
    background-color: ${colors.grey};
    box-shadow: rgb(0 0 0 / 35%) 0px 3px 13px -2px;

    span {
      width: min-content;
      height: min-content;
      margin-bottom: -0.25rem;
      text-align: left;
      font-size: 1.75rem;
      font-weight: ${font.weight.semiBold};

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ToolsPanel = styled.div`
  width: 100%;
  padding: ${spacers.xlarge};
  border-radius: ${borderRadius.xsmall};

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  color: ${colors.white};

  background: linear-gradient(
    -45deg,
    ${colors.panelDarkGrey} 0%,
    ${colors.panelDarkGrey} 35%,
    ${colors.headerGrey} 65%
  );
`;

export const ToolsPanelSmall = styled(ToolsPanel)`
  background: linear-gradient(
    -45deg,
    ${colors.panelDarkGrey} 15%,
    ${colors.headerGrey} 75%
  );
`;

export const ToolsHeading = styled.p`
  font-size: ${font.size.small};
  font-weight: ${font.weight.semiBold};
  margin-right: auto;
  margin-top: -${spacers.small};
  margin-bottom: ${spacers.large};
  display: inline-flex;
  align-items: center;

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    font-size: ${font.size.medium};
  }

  @media screen and (min-width: ${breakPoints.xlarge}) {
    font-size: ${font.size.medium};
  }

  .light {
    font-weight: ${font.weight.light};
  }
`;

export const ToolsSmallHeading = styled.p`
  font-size: 1.4rem;
  font-weight: ${font.weight.semiBold};
  display: inline-flex;
  align-items: center;
`;

export const ToolTip = styled.button`
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
`;

export const ToolsText = styled(ToolsHeading)`
  width: 100%;
  font-weight: ${font.weight.normal};
`;

export const GameItemElement = styled.div`
  display: grid;
  grid-template-columns: auto repeat(3, 5rem) 1.3rem;
  gap: 0.2rem;

  @media screen and (max-width: ${breakPoints.smallMax}) {
    grid-template-columns: auto repeat(3, 4.8rem) 1.3rem;
  }

  @media screen and (min-width: ${breakPoints.xlarge}) {
    grid-template-columns: auto repeat(3, 7rem) 1.3rem;
  }
`;

export const ResultItemElement = styled.div`
  display: grid;
  grid-template-columns: auto repeat(3, 5rem);
  gap: 0.2rem;

  @media screen and (max-width: ${breakPoints.smallMax}) {
    grid-template-columns: auto repeat(3, 4.8rem);
  }

  @media screen and (min-width: ${breakPoints.xlarge}) {
    grid-template-columns: auto repeat(3, 7rem);
  }
`;

export const CircleBtn = styled.button`
  border-radius: 50%;
  background-color: ${colors.grey};
  color: ${colors.darkGrey};
  width: 3.4rem;
  height: 3.4rem;
  border: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  & > span {
    font-size: ${font.size.medium};
    font-weight: ${font.weight.bold};

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      font-size: ${font.size.large};
    }
  }

  @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const ActiveGamesContainer = styled.div`
  background-color: ${({ gameTypeColor }) => gameTypeColor};
  border-radius: ${borderRadius.small};

  &.hasModal {
    cursor: pointer;
    pointer-events: auto;
  }

  .svsBlueSymbolContainer {
    padding: 0;
    bottom: auto;
    left: auto;
  }
  .reduceringSm {
    width: 11rem;
    margin: -1.2rem;
    transform: rotate(1deg);
    @media screen and (max-width: 400px) {
      width: 10rem;
    }
  }

  .svsBlueSymbol {
    background-color: transparent;
    width: 7.5rem;
    border-radius: 0 0.5rem 0 0;
    // height: 5rem;
  }

  &:before {
    content: "";
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(1 / 1 * 100%);
  }

  + * {
    //margin-left: ${spacers.small};
  }

  > * {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    padding: ${spacers.panelMedium};

    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    a {
      display: block;
      color: ${colors.white};
    }

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      padding-left: ${spacers.xlarge};
      padding-right: ${spacers.xlarge};
      padding-bottom: 2rem;
    }
  }

  .activeGameHeader {
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .svenskaSpelLogo {
      background-color: ${colors.svenskaSpelRed};
      width: 4rem;
      height: 4rem;
    }

    .activeGamesLength {
      border: 4px solid ${colors.white};
      color: ${colors.white};
      background-color: transparent;
      border-radius: 50%;
      width: 3.125rem;
      height: 3.125rem;
      font-size: ${font.size.large};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: inherit;
      margin-right: 2rem;
      margin-top: auto;
    }
  }

  .jackpot,
  .extra-pengar {
    width: 10rem;
    position: absolute;
    left: 80px;
    top: 15px;
  }
  .jackpot {
    top: 25px;
  }

  p {
    margin-bottom: 0.25rem;
    color: ${colors.white};
  }

  .itemLogo {
    height: 4.2rem;
    width: auto;
    margin-bottom: -1rem;
    align-self: flex-start;

    &.powerPlay {
      height: 2.8rem;
      margin-top: 1.125rem;
      margin-bottom: -0.75rem;
    }

    @media screen and (min-width: 800px) and (max-width: 1110px) {
      height: 3.5rem;

      &.powerPlay {
        margin-top: 1rem;
        height: 2.3rem;
      }
    }

    @media screen and (max-width: ${breakPoints.smallMax}) {
      height: 2.8rem;

      &.powerPlay {
        margin-top: 0.75rem;
        height: 1.86rem;
      }
    }
  }

  @media screen and (max-width: 1110px) {
    // This never gets applied!
    .svenskaSpelLogo {
      width: 3rem;
      height: 3rem;
    }

    .jackpot,
    .extra-pengar {
      width: 25%;
    }

    p {
      font-size: ${font.size.xsmall};
    }
  }
  @media screen and (max-width: 760px) {
    .extra-pengar {
      top: 25px;
    }
    .jackpot {
      top: 30px;
    }
  }
  @media screen and (max-width: 550px) {
    .extra-pengar {
      left: 60px;
    }
    .jackpot {
      left: 60px;
    }
  }

  @media screen and (max-width: 400px) {
    // This never gets applied!
    .jackpot,
    .extra-pengar {
      width: 20%;
    }

    .extra-pengar {
    }

    .svsBlueSymbol {
      width: 6.5rem;
    }
  }
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const SimpleActiveGamesContainer = styled(ActiveGamesContainer)`
  background-color: #1c3d4f;
`;

export const CookieConsentContainer = styled.div`
  // background: ${colors.toolsGradient};
  color: white;

  p {
    font-size: ${font.size.medium};
  }
  .consent-link:hover {
    cursor: pointer;
    pointer-events: auto;
    text-decoration: underline;
  }

  .consent-link:visited {
    color: ${colors.lightGrey};
  }

  .consent-link:link {
    color: ${colors.reducedTurquoise};
  }
`;
export const TeamWrapper = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.type ? "0" : "20px")};
  margin-bottom: 20px;
  border-radius: ${borderRadius.small};
  position: relative;
  background: linear-gradient(
    -45deg,
    ${colors.panelDarkGrey} 0%,
    ${colors.panelDarkGrey} 15%,
    ${colors.headerGrey} 85%
  );
  .without {
    text-align: center;
    font-size: 1.8rem;
  }
  .svsBlueSymbolContainer {
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .team-main {
    padding: 2rem 2rem 3rem 1rem;
  }
  .svsBlueSymbol {
    background-color: transparent;
    width: 9rem;
    border-radius: 0 ${borderRadius.small} 0 0;
    // height: 5rem;
  }
  .svenskaSpelLogo {
    background-color: transperent;
    width: 3rem;
    height: 2.8rem;
    position: relative;
    top: -1.8rem;
  }
  .img {
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
    object-position: center;
    display: block;
  }
  button {
    outline: none;
    border: none;
    background: transparent;
    margin-left: 1rem;
  }
  .team-header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding-top: 1.5rem;
    margin-bottom: 2.5rem;
    @media screen and (max-width: 1025px) {
      flex-direction: column;
      align-items: start;
      .team-btn {
        flex-direction: row-reverse;
        margin-top: 3rem;
        width: 100%;
        justify-content: space-between;
        button {
          margin-right: 1rem;
          margin-left: 0;
        }
      }
      .btn-modal {
        margin-right: 0;
        font-size: 1.2rem;
        padding: 0.7rem 2rem;
      }
    }
  }
  .team-btn {
    display: flex;
    align-items: center;
    @media screen and (max-width: 340px) {
      font-size: 1rem !important;
    }
  }
  .btn-modal {
    border-radius: ${borderRadius.small};
    background-color: ${colors.anchor};
    box-shadow: 0px 3px 6px #0000004d;
    padding: 1rem 4rem;
    cursor: pointer;
    line-height: 2.2rem;
    font-size: ${font.size.large};
    font-weight: ${font.weight.semiBold};
    text-transform: uppercase;
    margin-right: ${(props) => (props.type ? "0" : "4rem")};
    @media screen and (max-width: 350px) {
      font-size: 1rem !important;
      display: flex;
      padding: 0.3rem 0.5rem !important;
    }
  }
  .get-more {
    color: ${colors.anchor};
    text-decoration: underline;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    font-size: ${font.size.large};
    &--color {
      color: ${colors.grey};
    }
  }
  .get-more-wrapper {
    display: flex;
    align-item: center;
    justify-content: center;
  }
  p {
    font-size: ${font.size.large};
    font-weight: ${font.weight.normal};
    line-height: 2.5rem;
  }
  h3 {
    font-size: ${font.size.xlarge};
    line-height: 3rem;
    font-weight: ${font.weight.bold};
  }
  .img-opacity {
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    @media screen and (max-width: 468px) {
      &:hover {
        opacity: 0.5;
      }
    }
  }
  .img-active {
    opacity: 1;
    @media screen and (max-width: 468px) {
      &:hover {
        opacity: 1;
      }
    }
  }

  @media (max-width: 468px) {
    .img {
      width: 2.9rem;
      height: 2.9rem;
    }
    .smaller-btn {
      max-width: 110px;
    }
  }
`;

export const TeamContainer = styled.div`
  display: grid;
  width: 100%;
  margin: 20px 0;
  grid-template-columns: ${(props) =>
    props.type ? "1fr 1fr 1fr" : "1fr 1fr 1fr 1fr"};
  grid-gap: 1.6rem 0.6rem;
  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
  @media screen and (max-width: 468px) {
    grid-template-columns: 1fr;
    justify-content: center;
  }
  .team-card {
    cursor: pointer;
    width: 100%;
    background: ${colors.darkGrey2};
    border-radius: ${borderRadius.medium};
    padding: 1.1rem 1.8rem;
    max-width: 241px;
    display: flex;
    margin: 0 auto;
    min-height: 121px;
    max-height: 122px;
    box-shadow: 0px 3px 12px #0000000a;
    justify-content: space-between;
    @media screen and (max-width: 468px) {
      max-width: 336px;
    }
    @media screen and (max-width: 350px) {
      max-width: 290px;
    }
    &__info {
      width: 60%;
      position: relative;
    }
    &__title {
      word-break: break-word;
      cursor: pointer;
      margin-top: 1.2rem;
      overflow: hidden;
      font-size: 1.4rem;
      color: ${colors.white};
      font-weight: ${font.weight.bold};
      text-transform: uppercase;
      text-overflow: ellipsis;
      line-height: 1.4;
      -webkit-line-clamp: 3;
      min-height: 56px;
      max-height: 56.1px;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      &:hover {
        background: ${colors.darkGrey2};
        position: absolute;
        width: 100%;
        overflow-wrap: anywhere;
        max-width: 155px;
        -webkit-line-clamp: initial;
      }
    }
    .team-card__title:hover + .team-time {
      margin-top: 72px;
    }
    .username {
      font-size: 1.2rem;
      line-height: initial;
      color: ${colors.anchor};
    }
    &--active {
      border: 1px solid ${colors.anchor};
      position: relative;
      &:after {
        //font-family: "Font Awesome 5 Free";
        content: " ";
        background-image: url(${Check});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 24px;
        height: 24px;
        background-color: ${colors.anchor};
        border-radius: 50%;
        top: -1.4rem;
        right: -1rem;
      }
    }
  }
  span {
    display: inline-block;
    font-size: ${font.size.medium};
    margin-top: 0.4rem;
    color: ${colors?.white};
  }
  .price {
    display: inline-block;
    font-size: 1.6rem;
    margin-bottom: 1.2rem;
    margin-top: 0;
    width: 100%;
    text-align: end;
    color: ${colors.anchor};
  }
  .img {
    width: 3rem;
    height: 3rem;
    margin-left: auto;
  }
  .delete {
    margin-top: 2.5rem;
    font-size: 1.4rem;
    position: relative;
    color: #cfd8dc;
    &:before {
      position: absolute;
      content: "";
      width: 1.1rem;
      height: 1.3rem;
      left: -1.7rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .img-delete {
    width: 11px;
    height: 13px;
    margin-right: 0.6rem;
  }
  .remove {
    cursor: pointer;
  }
`;
export const AdminContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: calc(100% - ${headerHeight.large} * 2);
  max-width: ${breakPoints.large};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${spacers.xsmall};
  padding-right: ${spacers.xsmall};

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    width: 100%;
    padding-left: ${spacers.small};
    padding-right: ${spacers.small};
  }
  h3 {
    font-size: 4rem;
    font-weight: ${font.weight.bold};
    margin-top: 5.4rem;
    margin-bottom: 2.1rem;
    @media screen and (max-width: 968px) {
      text-align: center;
    }
    @media screen and (max-width: 468px) {
      font-size: 3rem;
      margin-top: 3rem;
    }
  }

  .item {
    margin-bottom: 3rem;
    width: 100%;
    h4 {
      font-size: 2.8rem;
      line-height: 3.7rem;
      margin-bottom: 1.9rem;
      @media screen and (max-width: 968px) {
        padding-left: 0.8rem;
      }
      @media screen and (max-width: 468px) {
        font-size: 2.4rem;
      }
    }
  }
`;
export const TableContent = styled.div`
  background: linear-gradient(
    -45deg,
    ${colors.panelDarkGrey} 0%,
    ${colors.panelDarkGrey} 15%,
    ${colors.headerGrey} 85%
  );
  border-radius: 0.5rem;
  width: 100%;
  padding: 2.2rem 2.9rem;
  @media screen and (max-width: 968px) {
    padding: 1.6rem 1rem;
    overflow: auto;
  }
  h5 {
    font-size: 2rem;
    margin-bottom: 1.6rem;
    display: flex;
    align-items: center;
    @media screen and (max-width: 968px) {
      font-size: 1.6rem;
    }
    span {
      margin-left: 2rem;
    }
  }
  .header {
    display: grid;
    grid-template-columns: 4fr 2fr 2fr;
    margin-bottom: 2rem;
    grid-gap: 1.2rem;
    &--size {
      grid-template-columns: 2fr 1fr 3fr;
      @media screen and (max-width: 968px) {
        grid-template-columns: 0.7fr 0.7fr 2fr !important;
      }
    }
    @media screen and (max-width: 968px) {
      grid-template-columns: 3fr 2fr 2.5fr;
    }
    p {
      font-size: 1.8rem;
      font-weight: ${font.weight.bold};
      line-height: 2.4rem;
      @media screen and (max-width: 968px) {
        font-size: 1.4rem;
      }
    }
  }
  .item {
    display: grid;
    grid-template-columns: 4fr 2fr 1.6fr;
    border-bottom: 0.1rem solid #667c89;
    padding: 2rem 0;
    margin: 0;
    grid-gap: 1.2rem;
    align-items: center;
    overflow-wrap: anywhere;
    @media screen and (max-width: 968px) {
      padding: 1rem 0;
      grid-template-columns: 3fr 2fr 2.5fr;
      p {
        font-size: 1.2rem !important;
      }
    }

    p {
      font-size: 1.6rem;
      //line-height:2.1rem;
      width: 100%;
      max-width: 435px;
    }
    .date {
      width: 225px;
      @media screen and (max-width: 968px) {
        width: auto;
      }
    }
    &--size {
      grid-template-columns: 2fr 1fr 3fr;
      @media screen and (max-width: 968px) {
        grid-template-columns: 1fr 1fr 3fr !important;
      }
      p {
        max-width: 312px;
      }
      .date {
        max-width: 156px;
        width: 100%;
      }
      .controls {
        .notera {
          max-width: 350px;
          width: 100%;
        }
      }
    }
    .controls {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      p {
        width: 118px;
        @media screen and (max-width: 968px) {
          width: auto;
          margin-right: 0.5rem;
        }
      }
      &-group {
        display: flex;
        width: 100%;
        justify-content: end;
        button {
          outline: none;
          cursor: pointer;
          background: transparent;
          border: none;
          width: 20px;
          height: 20px;

          &:first-child {
            margin-right: 1.5rem;
          }
          &:last-child {
            margin-left: 0.5rem;
          }
          @media screen and (max-width: 968px) {
            &:first-child {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
    input {
      background: transparent;
      border: 1px solid ${colors.white};
      //height:2rem;
      padding: 1rem 1rem;
      width: 100%;
      cursor: pointer;
      color: ${colors.white};
      font-size: 1.6rem;
    }
    .category-img {
      width: 3.2rem;
      height: 3.2rem;
      margin-left: 0.5rem;
    }
  }
  .pagination {
    margin-top: 2rem;
    ul {
      display: flex;
      align-items: center;
      justify-content: end;
      li {
        display: flex;
        border: none;
        font-size: 1.6rem;
        cursor: pointer;
        background-color: transparent;
        color: ${colors.white};
        align-items: center;
        justify-content: center;
        width: 2.2rem;
        height: 2.2rem;
        margin-right: 0.5rem;
        border-radius: 0.2rem;
        a {
          width: 100%;
          text-align: center;
        }
      }
      .active {
        background-color: ${colors.anchor};
      }
    }
  }
  .header--four {
    grid-template-columns: 3fr 1fr 2fr 2fr;
    @media screen and (max-width: 390px) {
      grid-template-columns: 0.8fr 0.5fr 0.5fr 1.1fr;
    }
    @media screen and (min-width: 390px) and (max-width: 460px) {
      grid-template-columns: 1fr 0.6fr 0.5fr 1.1fr;
    }
    @media screen and (max-width: 968px) and (min-width: 469px) {
      grid-template-columns: 2.5fr 1.5fr 1.5fr 1.5fr;
    }
  }
  .item--four {
    grid-template-columns: 3fr 1fr 2fr 1.6fr;

    @media screen and (max-width: 468px) {
      grid-template-columns: 2.5fr 1.5fr 1.5fr 1.5fr;
    }
    @media screen and (max-width: 968px) and (min-width: 469px) {
      grid-template-columns: 2.5fr 1.5fr 1.5fr 1.5fr;
    }
  }
`;
export const CustomDropdown = styled.div`
  position: relative;
  width: 100%;
  .open {
    border-radius: 1rem 1rem 0 0 !important;
  }
  .error {
    border-color: red !important;
  }

  .error-mes {
    color: red;
    position: absolute;
  }
  .input-arrow {
    position: absolute;
    top: 50%;
    right: 3rem;
    height: 2rem;
    width: 2rem;
    transform: translateY(-50%);
    display: flex;
    img {
      width: 16px;
      height: 15px;
      object-fit: contain;
    }
  }
  .active {
    position: absolute;
    width: 100%;
    background-color: ${colors.white};
    min-height: 4rem;
    max-height: 15rem;
    z-index: 1;
    overflow: auto;
    padding-left: 1rem;
    padding-top: 0.5rem;
    border-radius: 0 0 1rem 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    .no-data {
      text-align: center;
      font-size: 1.8rem;
      color: ${colors.black};
    }
    div {
      cursor: pointer;
      padding: 1rem 0;
      color: ${colors.black};
      font-size: ${font.size.medium};
      border-bottom: 1px solid ${colors.grey};
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .dark {
    border-top: 1px solid #667c89;
    background: #263238;
    max-height: 16rem;
    div {
      color: #ffffff;
    }
  }
  ${(props) =>
    props.type &&
    css`
      cursor: pointer;
      .open {
        cursor: pointer;
      }
      .input-arrow {
        right: 2rem;
        display: flex;
        align-items: center;
      }
    `}
`;
export const AdminLottery = styled.div`
  background: linear-gradient(
    -45deg,
    ${colors.panelDarkGrey} 0%,
    ${colors.panelDarkGrey} 15%,
    ${colors.headerGrey} 85%
  );
  border-radius: 0.5rem;
  width: 100%;
  padding: 2.2rem 2.9rem;
  margin-bottom: 2rem;
  h5 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.6rem;
  }
  .lottery-head {
    display: grid;
    grid-template-columns: 2fr 2fr 0.7fr 0.5fr;
    grid-gap: 1.5rem;
    margin-bottom: 1rem;
    .lottery-title {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 2.4rem;
    }
  }
  .lottery {
    display: grid;
    grid-template-columns: 2fr 2fr 0.7fr 0.5fr;
    grid-gap: 1.5rem;
    p {
      font-size: 1.8rem;
      height: 40px;
      line-height: 20px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      -ms-box-orient: vertical;
      box-orient: vertical;
      -webkit-line-clamp: 2;
      -moz-line-clamp: 2;
      -ms-line-clamp: 2;
      line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    label {
      align-items: center;
      cursor: pointer;
      font-size: 1.4rem;
    }
  }
  .lottery-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      width: 1.8rem;
      height: auto;
    }
    span {
      cursor: pointer;
      &:first-child {
        margin-right: 2rem;
      }
    }
  }
`;
export const TavlingSection = styled.div`
  background-color: #30b29e;
  padding: 2rem;
  width: 100%;
  border-radius: 0.375rem;
  display: flex;
  justify-content: flex-start;
  grid-gap: 4.5rem;
  align-items: flex-start;
  margin-bottom: 2.6rem;
  .tavling-info {
    display: flex;
    flex-direction: column;
    grid-gap: 1.2rem;
    h3 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1.4rem;
    }
    .btn {
      border-radius: 0.5rem;
      background-color: #273238;
      box-shadow: 0px 3px 6px #0000004d;
      padding: 1rem 3rem;
      cursor: pointer;
      line-height: 1.6rem;
      font-size: 1.4rem;
      font-weight: 500;
      text-transform: uppercase;
      border: none;
      color: #ffffff;
      width: 16rem;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .svenska {
    width: 7rem;
  }
`;

import styled from 'styled-components'
import { ToolsPanelSmall } from '../../../../styles/globalStyle'
import { colors, font, breakPoints, spacers, borderRadius } from '../../../../styles/constants'

export const DividedContainer = styled.div`
  > p {
    hyphens: auto;
    margin-top: initial;
    margin-bottom: 1.25rem;

    @media screen and (max-width: ${breakPoints.large}) {
      font-size: 1.25rem;
    }

    .popup-content & {
      font-size: ${font.size.xsmall};
      text-transform: uppercase;
      margin-bottom: 1rem;
    }
  }
`

export const DividedList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: ${font.size.medium};
  font-weight: ${font.weight.light};
  color: ${colors.lightGrey};
`

export const SignWrapper = styled.div``

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;

  .popup-content & {
    p {
      margin-bottom: 0;
    }
  }

  &.listitemsign > p {
    font-weight: ${font.weight.semiBold};
    font-size: ${font.size.small};
    color: ${colors.white};
    text-align: left;
  }

  + .listitemsign {
    margin-top: ${spacers.medium};
  }

  > * {
    flex: 1 0 calc(100% * 1 / 3);

    &:first-child {
      flex: 3rem;
    }

    &:last-child {
      text-align: right;
    }

    .popup-content & {
      font-size: ${font.size.xsmall};
      line-height: 1.2;
    }
  }
`

import React, { useState, useRef, useEffect } from 'react';
import CardElement from './CardElement';
import { TeamContainer, TeamWrapper } from '../../styles/globalStyle';
import svsBlueSymbol from '../../assets/svs-blue-symbol.svg';
import svsPOwerplay from '../../assets/svs_product-powerplay.svg';
import svsBomben from '../../assets/Bomben-icon.svg';
import svsEuro from '../../assets/svs_product_europatipset.svg';
import svsStryk from '../../assets/svs_product_stryktipset.svg';
import svsTop from '../../assets/svs_product_topptipset.svg';
import svenskaSpelLogo from '../../assets/svenskaspel-logomark.svg';
import Loader from '../Loader/Loader';
import { useAuth0 } from '@auth0/auth0-react';

const Card = (props) => {
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [team, setTeam] = useState(props);
  const prevState = useRef(null);
  useEffect(() => {
    if (props.team) {
      setTeam(props.team);
      prevState.current = props.team;
    }
  }, [props]);
  useEffect(() => {
    if (props.resetSort) {
      const allImage = document.querySelectorAll('.img-opacity');
      allImage?.forEach((img) => {
        img?.classList?.remove('img-active');
      });
    }
  }, [props.resetSort]);

  const handleOpen = (e) => {
    if (isAuthenticated) {
      const callback = props.setIsModal;
      callback(true);
    } else {
      props.setIsModalLogin(true);
    }
  };

  const handleType = (e, sort) => {
    const allImage = document.querySelectorAll('.img-opacity');

    if (sort) {
      let copy = prevState.current;
      if (e.target.classList.contains('img-active')) {
        setTeam(copy);
        props.setTypeSort(null);
        e.target.classList.remove('img-active');
      } else {
        props.setTypeSort(sort);
        //let newDate = copy.filter( element => element.gameType === sort)
        const currentImage = e.target.classList.add('img-active');
        //setTeam(newDate)
        allImage.forEach((image) => {
          if (image.alt === e.target.alt) {
            return;
          } else {
            image.classList.remove('img-active');
          }
        });
      }
    }
    return;
  };
  const handleMore = async (e) => {
    await props.setIsLoader(true);

    await props.uploadMore();
  };

  return (
    <TeamWrapper type={props.withoutSort}>
      <div className="svsBlueSymbolContainer">
        <img
          className="svsBlueSymbol"
          src={svsBlueSymbol}
          alt="Svenska Spel Symbol"
        />
      </div>
      <div className="team-main">
        <div className="team-header">
          <div>
            <img
              className="svenskaSpelLogo"
              src={svenskaSpelLogo}
              alt="Svenska Spel"
            />
            <h3>Spela Tillsammans</h3>
            <p>Tillsammans-spel med liverättning</p>
          </div>
          <div className="team-btn">
            <a className="btn-modal smaller-btn" onClick={handleOpen}>
              Skapa andelslag
            </a>
            {!props.withoutSort && (
              <div>
                <button
                  name="str"
                  onClick={(e) => handleType(e, 'stryktipset')}
                >
                  <img src={svsStryk} alt="str" className="img img-opacity" />
                </button>
                <button
                  name="euro"
                  onClick={(e) => handleType(e, 'europatipset')}
                >
                  <img src={svsEuro} alt="euro" className="img img-opacity" />
                </button>
                <button name="bom" onClick={(e) => handleType(e, 'bomben')}>
                  <img src={svsBomben} alt="bom" className="img img-opacity" />
                </button>
                <button name="top" onClick={(e) => handleType(e, 'topptipset')}>
                  <img src={svsTop} alt="top" className="img img-opacity" />
                </button>
                <button name="pow" onClick={(e) => handleType(e, 'powerplay')}>
                  <img
                    src={svsPOwerplay}
                    alt="pow"
                    className="img img-opacity"
                  />
                </button>
              </div>
            )}
          </div>
        </div>
        <TeamContainer type={props.withoutSort}>
          {team?.length > 0
            ? team?.map((element) => (
                <CardElement item={element} handlDelete={props.handlDelete} />
              ))
            : null}
          {/*<CardElement delete={props.handlDelete} img={EUR} my={true} text="asdasdasd dssdfs sdjfbshdfb sdjfbshda sdfsdf  wqeqw" />
          <CardElement  img={STR} text="asdasdasd dssdfs sdjfbshdfb sdjfbshda sdfsdf sdfewrw wqeqw" />
          <CardElement img={OL}  text="asdasdasd dssdfs sdjfbshdfb  sdfewrw wqeqw"/>
          <CardElement img={TOP} text=" sdjfbshda sdfsdf sdqw"/>
          <CardElement img={EUR} text="asdasdasd dssdfs sdjfbshdfb sdfsdf sdfewrw wqeqw"/>*/}
        </TeamContainer>
        {props.isLoader && <Loader />}
        {props?.team?.length > 0 ? (
          <div className="get-more-wrapper">
            <button
              disabled={!props.IsCanLoad}
              onClick={handleMore}
              className={
                !props.IsCanLoad ? 'get-more get-more--color' : 'get-more'
              }
            >
              VISA FLER ANDELSLAG
            </button>
          </div>
        ) : (
          <p className="without">Inga tillgängliga Andelslag</p>
        )}
      </div>
    </TeamWrapper>
  );
};

export default Card;

import React,{useState} from 'react'
import {LabelCheckbox} from './CustomChekbox.elements'


const CustomChekbox = ({checked,title,onChange,imageId,disabled = false,styles}) => {

  return (
    <LabelCheckbox styles={styles} disabled={disabled} >
      <input
        checked={checked}
        type="checkbox"
        onChange={
         disabled ? ()=> {} : (e)=>onChange(e,imageId)
        }
      />
      <span
        className={`checkbox ${checked ? "checkbox--active" : ""}`}
        // This element is purely decorative so
        // we hide it for screen readers
        aria-hidden="true"
      />
      {title}
    </LabelCheckbox>
  )
}

export default CustomChekbox
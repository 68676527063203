import React from 'react'
import { Option, Select } from '../../Game/CouponSettings/RowStake/RowStake.elements'
import { BombenBigContainer } from './BombenBig.styles'
import InputNumber from '../InputNumber/InputNumber'

const BoombenBig = ({area,onChange,value,option}) => {
  return (
    <BombenBigContainer area={area}>
      <p>Välj radinsats</p>
      <InputNumber
        option={option}
        onChange={onChange}
        value={value}
        size='small'
        //disable={!isActive}
      />
    </BombenBigContainer>
  )
}

export default BoombenBig
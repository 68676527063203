import styled from 'styled-components'
import {
  breakPoints,
  colors,
  font,
  spacers,
  borderRadius,
  headerHeight
} from '../../styles/constants'

export const CustomToggleComponents = styled.div`
margin-bottom: 4rem;
.rToolTip-icon{
  width: 24px;
  height: 24px;
  font-size: 1.5rem;
}
.toogle-label{
  margin-right: 1rem;
  font-size: 1.4rem;
  min-width: 2rem;
}
.info{
  margin-left: 0.8rem;
}
.container {
  text-align: center;
  height: max-content;
  display: flex;
  align-items: center;
  
}
.toggle-switch {
  position: relative;
  width: 50px;
  display: inline-block;
  text-align: left;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 26px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "";
  padding-left: 10px;
  background-color: ${colors.anchor};
  color: #fff;
}
.inner:after {
  content: "";
  padding-right: 10px;
  background-color: #2B373E;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 20px;
  margin: 3px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}

`
import React, { useState, useEffect, useContext } from 'react'
import { Redirect, useLocation, useParams} from 'react-router-dom'
import GamePage from './GamePage'

import { LiveResultContext } from '../context/liveResultContext'
import { ActiveGamesContext } from '../context/activeGamesContext'

import { SocketContext, socket } from './../context/socket'
import { GameContext } from './../context/gameContext'
import { useAuth0 } from "@auth0/auth0-react";
import Autorized from '../components/Popup/Autorized'

export default function GameLoader() {
  const { gameType, drawNumber, id } = useParams()
  const history = useLocation()
  const [redirectHome, setRedirectHome] = useState(false)
  const [loadedState, setLoadedState] = useState(null)
  //State for preload state from blog page
  const [preloadState, setPreloadState] = useState(null)
  const [historicLoad, setHistoricLoad] = useState(null)
  const [actionType,setActionType] = useState(null)
  const [isModalLogin, setIsModalLogin] = useState(false)

  const updateLoadedState = () =>{
    if(loadedState.initialViewState === null){
      setLoadedState(prev=>({...prev,initialViewState:{isLocked:false}}))
      return
    }
    let loaded  = loadedState.initialViewState;
    let updateObj = {...loaded,isLocked:false};
    setLoadedState(prev=>({...prev,initialViewState:updateObj}))
  }
  const changeLocked = (value) =>{
    if(loadedState.initialViewState === null){
      setLoadedState(prev=>({...prev,initialViewState:{isLocked:value}}))
      return
    }
    let loaded  = loadedState.initialViewState;
    let updateObj = {...loaded,isLocked:value};
    setLoadedState(prev=>({...prev,initialViewState:updateObj}))
  }
  const changeLockedNull = (value) =>{
    if(loadedState.initialViewState === null){
      setLoadedState(prev=>({...prev,initialViewState:{isLocked:value}}))
    }
  }


  let activeGames = useContext(ActiveGamesContext)
  let liveGames = useContext(LiveResultContext)

  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()

  const state = {
    gameType: gameType,
    drawNumber: drawNumber,
    id: null,
    initialViewState: null,
    initialNickname: "",
    initialStatus: "draft"
  }
  // State with preload date when redirect from blog page
  useEffect(()=>{
    if(history?.state?.viewState?.length >0){
      setPreloadState(history.state.viewState)
      setActionType(history?.state?.event)
    }
    return ()=> {
      setActionType(null)
      setPreloadState(null)
    }
  },[ history])
  //Check if user not autorized
  useEffect(()=>{

    if(!isAuthenticated && !isLoading){
      setIsModalLogin(true)
    }
  },[isAuthenticated,isLoading])

  useEffect(async () => {
    // console.log("isAuthenticated in GameLoader", `${isAuthenticated}`)
    if (Object.keys(activeGames).length === 0 || Object.keys(liveGames).length === 0) {
      return;
    }

    if (!id) {
      setLoadedState(state)
      setHistoricLoad(false);
      return;
    }

    // if (!isLoading) {
        // let token = isAuthenticated ? await getAccessTokenSilently() : null;
        // auth fetch
        // console.log("token", token)
        // console.log("fetching", token ? `Bearer ${token}` : null);

        //const headers = isAuthenticated ? { 'Authorization': `Bearer ${token}` } : {};

        await fetch(`${process.env.REACT_APP_API_ENDPOINT}/coupon/${id}`, {
          // headers: headers
        })
        // TODO add bearer token here
        .then(response => {
          if (response.status === 404) {
            throw new Error('Not found')
          }
          if (response.status === 403) {
            throw new Error('Not Allowed')
          }
          return response.json()
        })
        .then(data => {
          // check if the loaded coupon matches the game and dn of directed coupon
          if (!(data.data.gameType === gameType && data.data.drawNumber === drawNumber)) {
            setRedirectHome(true)
            return;
          } 

          const isActive = activeGames[gameType].draws.some((el) => {
            return el.drawNumber === parseInt(drawNumber);
          })

          const isLive = liveGames[gameType].draws.some((el) => {
            return el.drawNumber === parseInt(drawNumber);
          })

          if (isActive || isLive) {
            setHistoricLoad(false);
          } else {
            setHistoricLoad(true);
          }

          setLoadedState({
            gameType: data.data.gameType,
            drawNumber: data.data.drawNumber,
            id: data.id,
            initialViewState: data.data,
            initialNickname: data.nickname,
            initialStatus: data.data.status
          })
          
        })
        .catch(error => {
          console.error(error)
          setRedirectHome(true)
        })

    // }

  }, [gameType, drawNumber, activeGames, liveGames]) // , isAuthenticated, isLoading])

  return loadedState?.gameType === gameType && loadedState?.drawNumber === drawNumber && historicLoad !== null ? (
    <SocketContext.Provider value={socket}>
      <GameContext.Provider value={loadedState}>
        {
          <>
            <Autorized
              isModalOpen={isModalLogin}
              setIsModalOpen={setIsModalLogin}
            />
            <GamePage
              key={`${loadedState.gameType}/${loadedState.drawNumber}`}
              initialViewState={loadedState.initialViewState}
              initialId={loadedState.id}
              initialNickname={loadedState.initialNickname}
              initialStatus={loadedState.initialStatus}
              historicLoad={historicLoad}
              preloadState={preloadState}
              actionType = {actionType}
              updateLoadedState={updateLoadedState}
              changeLocked={changeLocked}
              changeLockedNull={changeLockedNull}
            />
          </>
        }
      </GameContext.Provider>
    </SocketContext.Provider>
  ) : redirectHome ? (
    <Redirect to='/' />
  ) : null // (!isLoading && !isAuthenticated ? <div>Forbidden!</div> : null)
}

import React, { useRef, useState,useEffect } from 'react'
import {CustomDropdown} from '../../styles/globalStyle'
import { useOnClickOutside } from '../../utils/hooks/useOnClickOutside';
import arrow from '../../assets/arrow.png'

const Dropdown = ({list,error,onChange,objType,classFor,...props}) => {
  const ref = useRef(null)
  const lastState = useRef(null)
  useOnClickOutside(ref, () => {
    setIsOpen(false)
  })
  const [isOpen, setIsOpen] = useState(false);
  return (
    <CustomDropdown
      onClick={()=>setIsOpen(prev =>!prev)}
      ref={ref}
      type
    >
      <input
        className={`${isOpen ? "open" : ""}`}
        type='text'
        autoComplete='off'
        {...props}
      ></input>
      { <span className='input-arrow'><img style={{transform:isOpen ? 'rotate(270deg)' : 'rotate(90deg)'}} src={arrow}/></span>}
      {isOpen && <div className={`active ${classFor || ''}`} >
        {(list?.length > 0) ? list?.map( (li) =>
          <div onClick={(e)=>onChange(e,li)}>
            {li.type}
            </div>
        ) : <p className='no-data'>No Data</p>}
      </div>}
    </CustomDropdown>
  )
}

export default Dropdown
import React, { useEffect, useState } from 'react'
import Tooltip from 'rc-tooltip'
import { Bar, HorizontalBar } from '@reactchartjs/react-chart.js'
import { ToolsHeading, ToolsPanel } from '../../../../styles/globalStyle'
import { Inner, ChartContainer } from './TotaloddsReduction.elements'
import { colors } from '../../../../styles/constants'
import RangeSlider from '../../../Slider/Slider'

export default function TotaloddsReduction({ totaloddsData, extraInfo, setOddsRange }) {
  const [rangeSpan, setRangeSpan] = useState([0, 8])

  //console.log("e", extraInfo)

  const marksArr = []
  if (totaloddsData.length) {
    marksArr.push([0, '0', 0])
  }
  const marksArr2 = totaloddsData.map((el, i) => {
    let value = el?.[0]?.[1]
    let out
    if (value > 1000000) {
      out = `${(value / 1000000).toFixed(0)}M`
    } else if (value > 1000) {
      out = `${(value / 1000).toFixed(0)}K`
    } else {
      out = `${(Math.round(value / 100) * 100).toFixed(0)}`
    }

    return [i + 1, out, value]
  })
  const newMarksArr = marksArr.concat(marksArr2)
  const onChangeUpdate = ev => {
    setRangeSpan(ev)
  }

  const updateOddsRange = ev => {
    if (newMarksArr.length) {
      setOddsRange([newMarksArr[ev[0]][2], newMarksArr[ev[1]][2]])
    }
  }

  const data = {
    labels: totaloddsData
      //.filter(el => el[0][0] !== el[0][1])
        .map(el => {
          if (!el || !el[0]) {
            console.error("Invalid element or structure:", el);
            return null; // or some default value
          }
          let startValue = el[0][0];
          let endValue = el[0][1];

        let startString
        let endString

        if (startValue >= 1000000) {
          startString = `${(startValue / 1000000).toFixed(0)}M`
        } else if (startValue > 1000) {
          startString = `${(startValue / 1000).toFixed(0)}K`
        } else {
          startString = `${(Math.round(startValue / 100) * 100).toFixed(0)}`
        }

        if (endValue === Infinity) {
          return `${(startString)}+`;
        } else if (endValue >= 1000000) {
          endString = `${(endValue / 1000000).toFixed(0)}M`
        } else if (endValue > 1000) {
          endString = `${(endValue / 1000).toFixed(0)}K`
        } else {
          endString = `${(Math.round(endValue / 100) * 100).toFixed(0)}`
        }


          return startString + ' - ' + endString
      }),
    datasets: [
      {
        data: totaloddsData.map(el => el[1]),
        backgroundColor: totaloddsData.map((val, i) =>
          i >= rangeSpan[0] && i < rangeSpan[1] ? colors.vividCyan : colors.grey
        ),
        barThickness: 5,
        categoryPercentage: 1,
        borderSkipped: false
      }
    ]
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: false
    },
    legend: {
      display: false
    },
    scales: {
      yAxes: [{
        ticks: {
          fontColor: colors.white,
          max: !totaloddsData.length ? 100 :  undefined,
          min: 0,
          stepSize: 10,
          padding: 10,

          callback: (val) => {
            return `${val}`
          }
        },
        gridLines: {
          display: true,
          drawTicks: false,
          drawBorder: false,
          lineWidth: 2,
          offsetGridLines: false,
          color: colors.grey,
          zeroLineWidth: 2,
          zeroLineColor: colors.grey,
        }
      }],
      xAxes: [{
        ticks: {
          stepSize: 1,
          autoSkip: false,
          fontColor: colors.white
        },
        gridLines: {
          display: false
        }
      }]
    }
  }



  useEffect(() => {
    if (totaloddsData.length) {
      setOddsRange([newMarksArr[0][2], newMarksArr[8][2]])
    }
    setRangeSpan([0, 8])
  }, [totaloddsData])

  return (
    <>
      <ToolsPanel>
        <Tooltip
          prefixCls='rToolTip'
          placement='bottom'
          trigger={['hover']}
          animation='anim'
          overlay={
            <>
              <h3>Totaloddsprognos</h3>

              <p>
                Hur ser totaloddset ut för raderna i systemet? Få en snabb överblick på vad totaloddset är för alla rader i systemet - matchoddsen hämtas från Svenska Spel och multipliceras per automatik med varandra enligt hur raderna ser ut. Min- och maxoddset syns till höger och staplarna visar hur pass defensivt/offensivt upplagt systemet är.
              </p>

              <p>
                Totaloddsprognosen aktiveras när man byggt ett system och klickar på <strong>REDUCERA</strong>-knappen. Med hjälp av poäng- färg- och 1X2-reducering kan man sedan “vikta om” systemets rader beroende på smak och tycke.
              </p>
            </>
          }
        >
          <ToolsHeading style={{ marginBottom: '1.5rem' }}>
            Totaloddsprognos <span className='rToolTip-icon'>i</span>
          </ToolsHeading>
        </Tooltip>

        <p style={{ width: '100%', textIndent: '1.1rem' }}>%</p>

        <Inner>
          <ChartContainer>
            <Bar data={data} options={options} />
          </ChartContainer>
          <div style={{display: "flex", alignItems: "center", margin: "0 auto"}}>
            Min: <br /> {parseInt(extraInfo.minOdds.toFixed(0)).toLocaleString()} <br /> <br />
            Max: <br /> {parseInt(extraInfo.maxOdds.toFixed(0)).toLocaleString()} <br /> <br />
            {/* Avg: <br /> {extraInfo.avgOdds.toFixed(0)} <br /> <br /> */}
          </div>
          {/* {<RangeSlider
            vertical
            allowCross={false}
            pushable={true}
            defaultValue={[0, 8]}
            min={0}
            max={8}
            step={1}
            value={[rangeSpan[0], rangeSpan[1]]}
            marksArr={newMarksArr}
            onChange={ev => onChangeUpdate(ev)}
            onAfterChange={ev => {
              updateOddsRange(ev)
            }}
          />} */}
        </Inner>
      </ToolsPanel>
    </>
  )
}

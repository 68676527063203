import styled, { css } from 'styled-components'
import check from '../../assets/big_Cheked.png'
import white from '../../assets/checked.svg'
export const LabelCheckbox = styled.label`
  display: flex;
  input[type="checkbox"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .checkbox {
    display: inline-block;
    height: 20px;
    width: 20px;
    background: transparent;
    border: 2px #ddd solid;
    margin-right: 4px;
    &--active{
      background: url(${check});
      background-repeat: no-repeat;
      background-size: 1.4rem;
      background-position: center;
    }
  }
   ${props=>props.styles === 'greens' && css`
      font-size: 1.4rem;
      align-items: center;
      grid-gap: 0.8rem;
      cursor: pointer;
      
      .checkbox{
        border-radius: 0.2rem;
        border: none;
        background-color: #263238;
        &--active{
          background: url(${white});
          background-repeat: no-repeat;
          background-size: 1.4rem;
          background-position: center;
          background-color: #008733;    
        }
      }
    `}
   ${props=>props.styles === 'grays' && css`
      font-size: 1.4rem;
      align-items: center;
      grid-gap: 0.8rem;
      cursor: pointer;
      
      .checkbox{
        border-radius: 0.2rem;
        border: none;
        background-color: #263238;
        &--active{
          background: url(${white});
          background-repeat: no-repeat;
          background-size: 1.4rem;
          background-position: center;
          background-color: #455A64;    
        }
      }
    `}
   ${props=>props.disabled && css`
    cursor: not-allowed;
     opacity: 0.7;
  `}
`

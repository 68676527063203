import styled from 'styled-components'
import {
  colors,
  font
} from '../../../../styles/constants'
import { ToolsPanel, CircleBtn } from '../../../../styles/globalStyle'

export const TrendsContainer = styled(ToolsPanel)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  ${({ isActive }) => (isActive ? `opacity: 1;` : `opacity: 0.3;`)}
`

export const SliderContainer = styled.div`
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  margin: 2rem 3rem 0 3rem;

  .rc-slider-disabled {
    background-color: transparent;
  }
`

export const Button = styled(CircleBtn)`
  box-shadow: rgb(0 0 0 / 35%) 0px 3px 13px -2px;
  ${({ isActive }) => (isActive ? `cursor: pointer;` : `cursor: not-allowed;`)}

  svg.loading {
    width: 27px;
    height: 27px;
    animation: loading 450ms infinite linear;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  &.resetTrend {
    margin-left: auto;
    margin-right: 1rem;
    transition: all 250ms cubic-bezier(.02,1.02,.8,1);

    svg {
      fill: ${colors.white};
      width: 2rem;
      height: 2rem;
    }

    &:disabled {
      background: transparent;
      pointer-events: none;
      border: 1px solid ${colors.grey};

      svg {
        fill: ${colors.grey};
      }
    }
  }
`

export const railStyle = { background: colors.darkGrey2, width: '100%' }

export const trackStyle = {
  background: colors.vividCyan
}

export const handleStyle = {
  height: '1.7rem',
  width: '6rem',
  borderRadius: '0.3rem',
  background: colors.grey,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
  border: 'none',
  clipPath:
    'polygon(0 0, 70% 0%, 100% 40%, 100% 60%, 70% 100%, 0 100%, 0% 70%, 0% 30%)',
  top: '0.35rem',
  textAlign: 'center',
  fontSize: `${font.size.small}`,
  fontWeight: `${font.weight.semiBold}`,
  paddingRight: '12px',
  paddingTop: '1px'
}

export const secondHandleStyle = {
  height: '1.7rem',
  width: '6rem',
  borderRadius: '0.3rem',
  background: colors.grey,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
  border: 'none',
  clipPath:
    'polygon(30% 0%, 70% 0%, 100% 0, 99% 100%, 70% 100%, 30% 100%, 0 60%, 0 40%)',
  top: '0.35rem',
  textAlign: 'center',
  fontSize: `${font.size.small}`,
  fontWeight: `${font.weight.semiBold}`,
  paddingLeft: '12px',
  paddingTop: '1px'
}

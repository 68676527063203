import { ReductionPopup } from './Popup.elements'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'

export default function ErrorPopup({open, setErrorPopup, errorPopupText}) {
  return (
    <ReductionPopup
      open={open}
      onClose={
          () => setErrorPopup(false)
        }
      errorPopupText
    >
      <button className='modalClose' onClick={() => setErrorPopup(false)}>
        <CloseIcon alt='close' />
      </button>

      <div>
        {errorPopupText}
      </div>
    </ReductionPopup>
  )
}
import React, { useEffect, useState, useContext } from "react";
import {
  SelectedCouponArea,
  GameListArea,
  GameWidgetArea,
} from "./SelectedCoupon.elements";

import { useAuth0 } from "@auth0/auth0-react";
import { Prompt } from "react-router-dom";

import GameTypeHeader from "../GameTypeHeader/GameTypeHeader";
import GameList from "./GameList/GamesList";
import ReductionTools from "./ReductionTools/ReductionTools";
import CouponSettings from "./CouponSettings/CouponSettings";
import ReducedRow from "./ReducedRow/ReducedRow";
import GameReducer from "../../utils/math";

import { GameContext } from "../../context/gameContext";

import { startAffiliate, removeAffiliate } from "../../utils/helperFunctions";
import { ContestContext } from "../../context/contestContext";

export default function SelectedCoupon({
  data,
  initialViewState,
  initialId,
  initialNickname,
  initialStatus,
  preloadState,
  actionType,
  isLocked,
  setIsLocked,
  isMine,
  setIsMine,
  updateLoadedState,
  changeLocked,
  changeLockedNull,
}) {
  const [totalRader, setTotalRader] = useState(0);
  const [reducedRowsCount, setReducedRowsCount] = useState(0);
  const [checkBox, setCheckBox] = useState(false);
  const [isErrorCoupon, setIsErrorCoupon] = useState(false);

  const events = data.events;
  const { gameType, drawNumber, productId } = useContext(GameContext);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const { isSM } = useContext(ContestContext);

  // Creates an 2D array with the event.length amount of inner arrays.
  const eventArr = new Array(events.length);

  const [status, setStatus] = useState(initialStatus || "draft");

  const adjustReductionTools = (viewState) => {
    // Recalculate total rows
    setTotalRader(
      viewState.reduce((accum, curr) => {
        const total =
          curr.home.isSelected + curr.draw.isSelected + curr.away.isSelected;
        return accum * total;
      }, 1)
    );

    // Recalculate values
    for (let color of ["yellow", "blue", "red", "green"]) {
      let total = viewState.reduce((acc, row) => {
        let hasColor =
          (row.home.isSelected && row.home.colors.includes(color)) ||
          (row.draw.isSelected && row.draw.colors.includes(color)) ||
          (row.away.isSelected && row.away.colors.includes(color));
        return acc + hasColor;
      }, 0);
      colorReduceFunctions.setTotal(color, total);
      adjustMinMax(color, total);
    }

    // Recalculate total points
    let totalPoints = viewState.reduce((acc, curr) => {
      return (
        acc +
        Math.max(
          curr.home.isSelected ? curr.home.points : 0,
          curr.draw.isSelected ? curr.draw.points : 0,
          curr.away.isSelected ? curr.away.points : 0
        )
      );
    }, 0);

    setPointReduction((prevState) => {
      let newState = { ...prevState };
      newState.maxLimit = totalPoints;
      return newState;
    });
  };

  // State of the point reduction
  const pointReductionState = {
    isSelected: false,
    max: null,
    min: null,
    maxLimit: 0,
    minLimit: 0,
  };
  const [pointReduction, setPointReduction] = useState(
    initialViewState ? initialViewState.pointReduction : pointReductionState
  );

  const [totaloddsData, setTotaloddsData] = useState({
    data: [],
    extraInfo: {
      minOdds: 0,
      maxOdds: 0,
      avgOdds: 0,
    },
  });

  // State of the color reduction
  const colorReductionState = {
    isSelected: false,
    currentColor: null,
    red: {
      max: 0,
      min: 0,
      total: 0,
      autoIncrement: true,
    },
    yellow: {
      max: 0,
      min: 0,
      total: 0,
      autoIncrement: true,
    },
    green: {
      max: 0,
      min: 0,
      total: 0,
      autoIncrement: true,
    },
    blue: {
      max: 0,
      min: 0,
      total: 0,
      autoIncrement: true,
    },
  };
  const [colorReduction, setColorReduction] = useState(
    initialViewState ? initialViewState.colorReduction : colorReductionState
  );

  // State of the sign reduction
  const signReductionState = {
    home: {
      max: events.length,
      min: 0,
    },
    draw: {
      max: events.length,
      min: 0,
    },
    away: {
      max: events.length,
      min: 0,
    },
    eventLength: events.length,
  };
  const [signReduction, setSignReduction] = useState(
    initialViewState ? initialViewState.signReduction : signReductionState
  );

  const signReduceFunctions = {
    setMin(sign, value) {
      setIsReduced(false);
      setSignReduction((prevState) => {
        let newState = { ...prevState };
        newState[sign].min = value;
        setShouldClickButton(true);
        return newState;
      });
    },
    setMax(sign, value) {
      setIsReduced(false);
      setSignReduction((prevState) => {
        let newState = { ...prevState };
        newState[sign].max = value;
        setShouldClickButton(true);
        return newState;
      });
    },
  };

  // Functions for color reduction
  const colorReduceFunctions = {
    startColorMode: (color) => {
      // setColorReduction({currentColor: color})
      setColorReduction((prevState) => {
        let newState = { ...prevState };
        newState.isSelected = true;
        newState.currentColor = color;
        return newState;
      });
      pointReduceFunctions.endPointMode();
      setMode({ mode: "colorMode", params: color });
    },
    stopColorMode: () => {
      setColorReduction((prevState) => {
        let newState = { ...prevState };
        newState.isSelected = false;
        newState.currentColor = null;
        return newState;
      });
      setMode({ mode: "default", params: null });
    },
    setMin(color, value) {
      setIsReduced(false);
      setColorReduction((prevState) => {
        let newState = { ...prevState };
        newState[color].min = value;
        setShouldClickButton(true);
        return newState;
      });
    },
    setMax(color, value) {
      setIsReduced(false);
      setColorReduction((prevState) => {
        let newState = { ...prevState };
        newState[color].max = value;
        setShouldClickButton(true);
        return newState;
      });
    },
    setTotal(color, value) {
      setColorReduction((prevState) => {
        let newState = { ...prevState };
        newState[color].total = value;
        return newState;
      });
    },
    setAutoIncrement(color, value) {
      setColorReduction((prevState) => {
        let newState = { ...prevState };
        newState[color].autoIncrement = value;
        return newState;
      });
    },
  };

  // The state view, the one representing the coupon
  const viewStates = eventArr.fill(0).map((val) => {
    return {
      home: {
        isSelected: false,
        isEditable: false,
        points: 0,
        colors: [],
        signDistribution: "-",
      },
      draw: {
        isSelected: false,
        isEditable: false,
        points: 0,
        colors: [],
        signDistribution: "-",
      },
      away: {
        isSelected: false,
        isEditable: false,
        points: 0,
        colors: [],
        signDistribution: "-",
      },
    };
  });

  // useEffect to state for preloadState from blog page
  useEffect(() => {
    if (preloadState) {
      setViewState(preloadState);
      setTotalRader(
        preloadState.reduce((accum, curr) => {
          const total =
            curr.home.isSelected + curr.draw.isSelected + curr.away.isSelected;
          return accum * total;
        }, 1)
      );
    }
  }, [preloadState]);

  const setSignDistribution = function (distribution) {
    let len = distribution.length;

    let newState = viewState.map((val, i) => {
      val.home.signDistribution = len ? distribution[i].home : 0;
      val.draw.signDistribution = len ? distribution[i].draw : 0;
      val.away.signDistribution = len ? distribution[i].away : 0;
      return val;
    });

    setViewState(newState);
  };

  const [viewState, setViewState] = useState(
    initialViewState?.viewState || viewStates
  );
  const [currentId, setCurrentId] = useState(initialId);
  const [currentNickname, setCurrentNickname] = useState(initialNickname);

  useEffect(() => {
    if (initialViewState?.viewState) {
      setTotalRader(
        initialViewState.viewState.reduce((accum, curr) => {
          const total =
            curr.home.isSelected + curr.draw.isSelected + curr.away.isSelected;
          return accum * total;
        }, 1)
      );
    }
  }, []);

  const adjustMinMax = (color, total) => {
    if (total === 0) {
      colorReduceFunctions.setAutoIncrement(color, true);
    }
    if (total <= colorReduction[color].max) {
      colorReduceFunctions.setMax(color, total);
    }
    if (total <= colorReduction[color].min) {
      colorReduceFunctions.setMin(color, total);
    }

    if (colorReduction[color].autoIncrement) {
      colorReduceFunctions.setMax(color, total);
    }
  };

  const [shouldClickButton, setShouldClickButton] = useState(false);

  useEffect(() => {
    /*
    if (shouldClickButton && totalRader) {
      const reduceraButton = document.getElementById("reduceraButton");
      if (reduceraButton) {
        reduceraButton.click();
      }
      setShouldClickButton(false);
    }
    */
  }, [shouldClickButton, totalRader]);

  const couponFunctions = {
    toggleSelected: (idx, game) => {
      return () => {
        let newState = [...viewState];
        newState[idx][game].isSelected = !newState[idx][game].isSelected;
        adjustReductionTools(newState);
        setViewState(newState);
        setIsReduced(false);
        setShouldClickButton(true);
      };
    },
    openEdit: (idx, game) => {
      return () => {
        let newState = [...viewState];
        newState[idx][game].isEditable = true;
        if (!newState[idx][game].isSelected) {
          newState[idx][game].isSelected = true;
          adjustReductionTools(newState);
        }

        setViewState(newState);
      };
    },
    closeEdit: (idx, game) => {
      return () => {
        let newState = [...viewState];
        newState[idx][game].isEditable = false;
        setViewState(newState);
      };
    },

    // In principle, we could recalculate here too, might we need it on EVERY KEY ENTRY? Probably not...
    setValue: (idx, game) => {
      return (val) => {
        let newState = [...viewState];
        newState[idx][game].points = val || 0;
        let totalPoints = newState.reduce((acc, curr) => {
          return (
            acc +
            Math.max(
              curr.home.isSelected ? curr.home.points : 0,
              curr.draw.isSelected ? curr.draw.points : 0,
              curr.away.isSelected ? curr.away.points : 0
            )
          );
        }, 0);
        setPointReduction((prevState) => {
          let newState = { ...prevState };
          newState.maxLimit = totalPoints;
          return newState;
        });
        setViewState(newState);
        setIsReduced(false);
        setShouldClickButton(true);
      };
    },
    setColor: (idx, game) => {
      return (color) => {
        let newState = [...viewState];
        let index = newState[idx][game].colors.indexOf(color);
        if (index > -1) {
          newState[idx][game].colors.splice(index, 1);
        } else {
          newState[idx][game].colors.push(color);
        }

        let preferredOrder = ["yellow", "blue", "red", "green"];

        newState[idx][game].colors.sort((a, b) => {
          if (preferredOrder.indexOf(a) > preferredOrder.indexOf(b)) {
            return 1;
          }
          if (preferredOrder.indexOf(a) < preferredOrder.indexOf(b)) {
            return -1;
          }
          return 0;
        });

        adjustReductionTools(newState);

        setViewState(newState);
        setIsReduced(false);
        setShouldClickButton(true);
      };
    },
  };

  // Function relevant to the point reduction
  const pointReduceFunctions = {
    startPointMode: () => {
      setPointReduction((prevState) => {
        let newState = { ...prevState };
        newState.isSelected = true;
        return newState;
      });
      colorReduceFunctions.stopColorMode();
      setMode({ mode: "pointMode", params: null });
    },
    endPointMode: () => {
      let newState = [...viewState];
      newState.forEach((val) => {
        for (const [k, v] of Object.entries(val)) {
          if (v.isSelected) {
            val[k].isEditable = false;
          }
        }
      });
      setViewState(newState);
      setPointReduction((prevState) => {
        let newState = { ...prevState };
        newState.isSelected = false;
        return newState;
      });
      setMode({ mode: "default", params: null });
    },
    setMin: (value) => {
      setIsReduced(false);
      setPointReduction((prevState) => {
        let newState = { ...prevState };
        newState.min = value;
        setShouldClickButton(true);
        return newState;
      });
    },
    setMax: (value) => {
      setIsReduced(false);
      setPointReduction((prevState) => {
        let newState = { ...prevState };
        newState.max = value;
        setShouldClickButton(true);
        return newState;
      });
    },
  };
  const oddsArr = events.map((e) => {
    return {
      // Odds might not exist at all!
      home: parseFloat(e.odds?.home.replace(",", ".")) || 2.0,
      draw: parseFloat(e.odds?.draw.replace(",", ".")) || 2.0,
      away: parseFloat(e.odds?.away.replace(",", ".")) || 2.0,
    };
  });

  const distArray = events.map((e) => {
    return {
      // Odds might not exist at all!
      home: parseFloat(e.distribution?.home.replace(",", ".")) || 0,
      draw: parseFloat(e.distribution?.draw.replace(",", ".")) || 0,
      away: parseFloat(e.distribution?.away.replace(",", ".")) || 0,
    };
  });

  const handleReduce = (mRows, mOdds, distribution) =>
    GameReducer(mRows, mOdds, distribution);

  // UGLY, but the idea is sane
  const [mode, setMode] = useState({
    mode: initialViewState?.pointReduction?.isSelected
      ? "pointMode"
      : initialViewState?.colorReduction?.isSelected
      ? "colorMode"
      : "default",
    params: initialViewState?.colorReduction?.isSelected
      ? initialViewState.colorReduction.currentColor
      : null,
  });

  const [handinRows, setHandinRows] = useState({
    type: "",
    rows: [],
  });
  const [isReduced, setIsReduced] = useState(false);
  const [isProbability, setIsProbability] = useState(false);

  const [dividendForecast, setDividendForecast] = useState([]);

  const [trendData, setTrendData] = useState({});

  const setColumnActive = (sign) => {
    let newState = [...viewState];
    let shouldToggle = newState.reduce((acc, game) => {
      return acc && game[sign].isSelected;
    }, true);
    if (!shouldToggle) {
      newState.forEach((game) => {
        game[sign].isSelected = true;
      });
    } else {
      newState.forEach((game) => {
        game[sign].isSelected = !game[sign].isSelected;
      });
    }
    adjustReductionTools(newState);
    setIsReduced(false);
    setViewState(newState);
  };

  const setRowActive = (idx) => {
    let newState = [...viewState];
    let shouldToggle = Object.entries(newState[idx]).reduce((acc, game) => {
      return acc && game[1].isSelected;
    }, true);
    if (!shouldToggle) {
      Object.entries(newState[idx]).forEach((game) => {
        game[1].isSelected = true;
      });
    } else {
      Object.entries(newState[idx]).forEach((game) => {
        game[1].isSelected = !game[1].isSelected;
      });
    }
    adjustReductionTools(newState);
    setIsReduced(false);
    setViewState(newState);
  };

  const [oddsRange, setOddsRange] = useState([0, Number.MAX_VALUE]);

  const resetCoupon = (shouldCLearFrame) => {
    if (shouldCLearFrame) {
      setTotalRader(0);
      setViewState(
        viewState.map((el) => {
          return {
            home: {
              ...el.home,
              points: 0,
              colors: [],
              isSelected: false,
              isEditable: false,
            },
            draw: {
              ...el.draw,
              points: 0,
              colors: [],
              isSelected: false,
              isEditable: false,
            },
            away: {
              ...el.away,
              points: 0,
              colors: [],
              isSelected: false,
              isEditable: false,
            },
          };
        })
      );
    } else {
      setViewState(
        viewState.map((el) => {
          return {
            home: { ...el.home, points: 0, colors: [] },
            draw: { ...el.draw, points: 0, colors: [] },
            away: { ...el.away, points: 0, colors: [] },
          };
        })
      );
    }

    setMode({
      mode: "default",
      params: null,
    });
    setColorReduction(colorReductionState);
    setSignReduction(signReductionState);

    setPointReduction(pointReductionState);
    setIsReduced(false);
    setHandinRows([]);
    setDividendForecast([]);
    setTotaloddsData({
      data: [],
      extraInfo: {
        minOdds: 0,
        maxOdds: 0,
        avgOdds: 0,
      },
    });

    // Could be removed since we are not using odds reduction
    setOddsRange([0, Number.MAX_VALUE]);
  };

  const [betRowAmount, setBetRowAmount] = useState(
    initialViewState?.betRowAmount || 1
  );
  const [isSpelaTillsammans, setIsSpelaTillsammans] = useState(false);

  const [reducedStates, setReducedStates] = useState({
    pointReduction: null,
    signReduction: null,
    colorReduction: null,
  });

  let closeTime = data.closeTime;

  const saveCoupon = async ({ nickname, saveAsCopy, savestatus }) => {
    const data = {
      id: saveAsCopy ? null : currentId,
      nickname: nickname,
      data: {
        // status: saveAsCopy ? "draft" : (savestatus || status) || "draft",  // one of "draft" | "final
        status: savestatus || status || "draft", // one of "draft" | "final"
        saveTime: Date.now(),
        closeTime: closeTime,
        gameType: gameType,
        drawNumber: drawNumber,
        fetchDataUrlName: topptippsify(gameType),
        betRowAmount: betRowAmount,
        viewState: viewState,
        pointReduction: reducedStates.pointReduction,
        colorReduction: reducedStates.colorReduction,
        signReduction: reducedStates.signReduction,
      },
    };
    if (gameType === "europatipset" && data.data.status === "final") {
      // if we have game type and final state  - we send new field
      // checkBox
      data.data.isLocked = isSM
        ? checkBox || false
        : initialViewState?.isLocked || false;
    }
    let lockedToSend = data.data.isLocked;
    const isNew = !!currentId;
    // send token silently
    let headers = {
      "Content-Type": "application/json",
    };
    if (isAuthenticated) {
      let token = await getAccessTokenSilently();
      headers["Authorization"] = `Bearer ${token}`;
    }

    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/coupon/`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 403) {
          setIsErrorCoupon(true);
          throw new Error("Not allowed");
        }
        if (response.status === 404) {
          setIsErrorCoupon(true);
          throw new Error("Not found");
        }
        if (response.status === 400) {
          setIsErrorCoupon(true);

          if (isSM) {
            setErrorPopup(true);
            setErrorPopupText(
              <>
                <h3>
                  Spelstopp kan ha passerat, eller så försöker du delta med
                  någon annans kupong.
                </h3>
              </>
            );
          }

          throw new Error("You have a coupon");
        }
        return response.json();
      })
      .then((data) => {
        setIsErrorCoupon(false);
        let url = window.location.toString();
        url = url.endsWith("/") ? url.slice(0, -1) : url;

        if (!url.endsWith(currentId)) {
          window.history.replaceState("", "", url + `/${data.id}`);
        } else if (data.id === currentId) {
          // noop
        } else {
          let newUrl = url.substring(0, url.indexOf(`${currentId}`));
          newUrl += data.id;
          window.history.replaceState({}, "", new URL(newUrl));
        }

        if (isSM) {
          if (saveAsCopy && gameType === "stryktipset" && isNew) {
            // Changed to enable contest
            // if we create new coupon without id
            updateLoadedState();
            setCheckBox(false);
            setIsMine(true);
          } else if (saveAsCopy && gameType === "stryktipset" && !isNew) {
            // when we clone coupon
            changeLockedNull(lockedToSend);
            setIsMine(true);
          } else if (saveAsCopy && !isNew) {
            setIsMine(true);
          }

          if (!saveAsCopy && gameType === "stryktipset") {
            // Changed to enable contest
            changeLocked(lockedToSend);
            setIsMine(true);
          }
        }

        setCurrentId(data.id);
        setCurrentNickname(nickname);
        navigator.clipboard
          .writeText(
            `${process.env.REACT_APP_SITE_URL}/reducera/${gameType}/${drawNumber}/${data.id}`
          )
          .then(() => console.log("Copying to clipboard was successful!"))
          .catch((err) => console.log("Could not copy text:", err));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const [errorPopup, setErrorPopup] = useState(false);
  const [errorPopupText, setErrorPopupText] = useState("");

  let extraMoney =
    data.fund &&
    parseFloat(data.fund.extraMoney) > 0 &&
    data.fund.extraMoney.replace(/\B(?=(\d{3})+(?!\d))/g, " ").slice(0, -3) +
      " kr";

  useEffect(() => {
    let listener = (event) => {
      const e = event || window.event;
      // Cancel the event
      console.log(e);
      e.preventDefault();
      if (e) {
        e.returnValue = ""; // Legacy method for cross browser support
      }
      return ""; // Legacy method for cross browser support
    };

    if (!currentId) {
      window.addEventListener("beforeunload", listener);
    }
    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  }, [currentId]);

  useEffect(() => {
    startAffiliate();

    return () => removeAffiliate();
  }, []);

  const topptippsify = (gamename) => {
    let out = gamename;
    if (gamename === "topptipset") {
      if (data.productName === "Topptipset Extra") {
        out = out + "europa";
      }
      if (data.productName === "Topptipset Stryk") {
        out = out + "stryk";
      }
    }
    return out;
  };

  // The components
  return (
    <>
      <Prompt
        message={(location, action) => {
          if (action === "POP") {
            console.log("Backing up...");
          }
          return currentId
            ? true
            : "Du är påväg att lämna sidan, glöm inte spara din kupong!";
          // return location.pathname.startsWith("/dasdsa")
          //   ? true
          //   :
        }}
      />
      <GameTypeHeader
        gameType={gameType}
        gameTurnover={data.turnover}
        gameCloseTime={data.closeTime}
        jackpot={data.jackpotItems?.[0]?.amount}
        extraMoney={extraMoney}
        currentId={currentId}
        errorPopup={errorPopup}
        setErrorPopup={setErrorPopup}
        errorPopupText={errorPopupText}
        setErrorPopupText={setErrorPopupText}
        isReduced={isReduced}
        drawNumber={drawNumber}
        isTurnament={isSM ? initialViewState?.isLocked : false}
      />
      <SelectedCouponArea>
        <GameListArea>
          <GameList
            events={events}
            viewState={viewState}
            couponFunctions={couponFunctions}
            mode={mode}
            isProbability={isProbability}
            setIsProbability={setIsProbability}
            trendData={trendData}
            setColumnActive={setColumnActive}
            setRowActive={setRowActive}
            isReduced={isReduced}
            currentCouponName={currentNickname}
            productId={data.productId}
            eventForecast={data?.forecast?.events}
          />
        </GameListArea>
        <GameWidgetArea>
          <ReductionTools
            pointReductionState={{
              pointReduction,
              pointReduceFunctions,
            }}
            colorReductionState={{
              colorReduction,
              colorReduceFunctions,
            }}
            signReductionState={{
              signReduction,
              signReduceFunctions,
            }}
            totaloddsData={totaloddsData}
            trendsData={{
              trendData,
              setTrendData,
              closingTime: data?.closeTime,
            }}
            setOddsRange={setOddsRange}
            currentId={currentId}
            topptippsify={topptippsify}
          />
          <CouponSettings
            actionType={actionType}
            resetCoupon={resetCoupon}
            topptippsify={topptippsify}
            handinRows={handinRows}
            forecastData={dividendForecast}
            viewState={viewState}
            isReduced={isReduced}
            events={events}
            id={{ currentId, setCurrentId }}
            bet={{ betRowAmount, setBetRowAmount }}
            saveCoupon={saveCoupon}
            setStatus={setStatus}
            status={status}
            setErrorPopup={setErrorPopup}
            setErrorPopupText={setErrorPopupText}
            currentNickname={currentNickname}
            totalRader={totalRader}
            reducedRowsCount={reducedRowsCount}
            isLocked={isLocked}
            setIsLocked={setIsLocked}
            closeTime={data?.closeTime}
            checkBox={checkBox}
            setCheckBox={setCheckBox}
            SpTsms={isSpelaTillsammans}
            isMine={isMine}
            isErrorCoupon={isErrorCoupon}
          />
        </GameWidgetArea>
      </SelectedCouponArea>
      <ReducedRow
        actionType={actionType}
        id={currentId}
        totalRader={totalRader}
        handleReduce={handleReduce}
        oddsArr={oddsArr}
        distArray={distArray}
        viewState={viewState}
        pointReduction={pointReduction}
        signReduction={signReduction}
        colorReduction={colorReduction}
        setTotaloddsData={setTotaloddsData}
        setHandinRows={setHandinRows}
        setIsReduced={setIsReduced}
        isReduced={isReduced}
        setDividendForecast={setDividendForecast}
        oddsRange={oddsRange}
        setSignDistribution={setSignDistribution}
        handinRows={handinRows}
        betRowAmount={betRowAmount}
        SpTsms={{ isSpelaTillsammans, setIsSpelaTillsammans }}
        setReducedStates={setReducedStates}
        setErrorPopup={setErrorPopup}
        setErrorPopupText={setErrorPopupText}
        reducedRowsCount={reducedRowsCount}
        setReducedRowsCount={setReducedRowsCount}
      />
    </>
  );
}

import React from 'react'

import { ToolsHeading } from '../../../../styles/globalStyle'
import { ReductionInner, Sign, Label, Select } from '../ResultWidgets.elements'
import { SignReductionContainer } from './SignReduction.elements'

export default function SignReduction({ signState }) {
  return (
    <SignReductionContainer isDisabled={true}>
      <ToolsHeading>1X2-reducering</ToolsHeading>

      <ReductionInner>
        <Sign>
          <Label>1</Label>
          <div className='break' />
          <Select>
            <option placeholder='Min' readOnly value={signState?.home?.min || 0}>
              {signState?.home.min || 0}
            </option>
          </Select>

          <Select>
            <option placeholder='Max' readOnly value={signState?.home.max || 0}>
              {signState?.home.max || 0}
            </option>
          </Select>
        </Sign>

        <Sign>
          <Label>X</Label>
          <div className='break' />
          <Select>
            <option placeholder='Min' readOnly value={signState?.draw.min || 0}>
              {signState?.draw.min || 0}
            </option>
          </Select>

          <Select>
            <option placeholder='Max' readOnly value={signState?.draw.max || 0}>
              {signState?.draw.max || 0}
            </option>
          </Select>
        </Sign>

        <Sign>
          <Label>2</Label>
          <div className='break' />
          <Select>
            <option placeholder='Min' readOnly value={signState?.away.min || 0}>
              {signState?.away.min || 0}
            </option>
          </Select>

          <Select>
            <option placeholder='Max' readOnly value={signState?.away.max || 0}>
              {signState?.away.max || 0}
            </option>
          </Select>
        </Sign>
      </ReductionInner>
    </SignReductionContainer>
  )
}

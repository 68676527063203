import React, { useEffect, useState } from 'react'

import RecentEvent from './RecentEvent'

import {
  RecentEventsTool,
  RecentEventsHeader,
  RecentEventsHeading,
  RecentEventsItem,
  MuteBtn
} from './RecentEvents.elements'

import { Howl } from 'howler'
import Notification from '../../../../assets/audio/Notification.wav'

import { ReactComponent as VolumeOnIcon } from '../../../../assets/volume-on.svg'
import { ReactComponent as VolumeOffIcon } from '../../../../assets/volume-off.svg'

export default function RecentEvents({ latestFive, events ,area }) {
  const [recentGoalsArr, setRecentGoalsArr] = useState([])
  const [notificationMute, setNotificationMute] = useState(true)

  const fetchLatestGoals = games => {
    const currentOutcomeScore = games?.map((el) => {
      return {
        outcomeScore: el.outcomeScore || el.outcome,
        description: el.description,
        scoringTeam: el.scoringTeam,
        participants: events[el.eventNumber - 1].participants
      }
    })
    
    setRecentGoalsArr(currentOutcomeScore)
  }

  const notificationSound = new Howl({
    src: [Notification],
    volume: 0.1,
    mute: notificationMute
  })

  const playNotificationSound = () => {
    notificationSound.play()
  }

  const handleNotificationMute = () => {
    setNotificationMute(!notificationMute)
  }

  useEffect(() => {
    fetchLatestGoals(latestFive)
  }, [latestFive])

  return (
    <RecentEventsTool area={area}>
      <RecentEventsHeader>
        <RecentEventsHeading>Fem senaste händelserna</RecentEventsHeading>
        <MuteBtn onClick={handleNotificationMute}>
          {notificationMute ? <VolumeOffIcon /> : <VolumeOnIcon />}
        </MuteBtn>
      </RecentEventsHeader>
      {latestFive.length === 0 ? (
        <RecentEventsItem>Inga händelser</RecentEventsItem>
      ) : (
        recentGoalsArr
          ?.map((el, idx) => (
            <RecentEvent
              key={`recent-event-${idx}`}
              recentDetails={el}
              playNotificationSound={playNotificationSound}
            />
          ))
          .reverse()
          .slice(0, 5)
      )}
    </RecentEventsTool>
  )
}

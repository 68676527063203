import React,{useRef, useEffect, useState} from 'react';
import {AsideContainer} from './Blog.elements';
import svsBlueSymbol from '../../assets/svs-blue-symbol.svg';
import svsPow from '../../assets/svs_product-powerplay.svg';
import svsEuro from '../../assets/svs_product_europatipset.svg';
import svsStryk from '../../assets/svs_product_stryktipset.svg';
import svsTop from '../../assets/svs_product_topptipset.svg';
import moment from 'moment';
import { gameTurnoverWithSpace, getActiveDates } from '../../utils/helperFunctions'
import {useHistory} from 'react-router-dom'
import svgSven from '../../assets/svenskaspel-logomark.svg'

const BlogAside = ({
  blog,
  handleChange,
  totalRader,
  canCheck,
  setIsModalOpen
  }) => {
  const history = useHistory();
  const blogTable = useRef()
  const getImage = [
    {gameType:'europatipset',img:svsEuro},
    {gameType:'powerplay',img:svsPow},
    {gameType:'stryktipset',img:svsStryk},
    {gameType:'topptipset',img:svsTop},
    ];

  const currentGameIcon = (gameCurrentType) =>{
      let findIcon = getImage.find(game => game.gameType === gameCurrentType);
      return findIcon?.img
  };

  const { day, time } = getActiveDates(blog?.draw?.closeTime);

  const redirectToGame = (type) =>{
    const createObject = blog.events.map(game =>{
      let games = {
        home:{colors:[],isEditable:false,isSelected:game.forecast[0],points:0,signDistribution:"-"},
        draw:{colors:[],isEditable:false,isSelected:game.forecast[1],points:0,signDistribution:"-"},
        away:{colors:[],isEditable:false,isSelected:game.forecast[2],points:0,signDistribution:"-"},
      }
      return games
    })
    let typeAction = null ;
    if(type == 'admin') {
      if(totalRader <= 0) {
        setIsModalOpen(true)
        return
      }
      typeAction = 'submit'
    }
    history.push({
      pathname:`/reducera/${blog.gameType}/${blog.draw.drawNumber}`,
      state:{gameType:blog.gameType,
        viewState:createObject,
        event:typeAction
      }
    })
  }
  return (
    <AsideContainer  ref ={blogTable}>
      <div className='aside-absolute'>
        <img src={svsBlueSymbol} alt='aside-img'/>
      </div>
      {/*<h2>{blog?.gameType} {moment(blog?.date).format('YYYY-MM-DD')}</h2>*/}
      <div className='head'>
        <div className='head-img'>
          <img src={currentGameIcon(blog?.gameType)} alt=""/>
        </div>
        <div className='column column--ml'>
          <span>Spelstoppp: </span>
          <span className='mt'>Omsättning:</span>
        </div>
        <div className='column'>
          <span>{day} {time} </span>
          <span className='mt'>{gameTurnoverWithSpace(blog?.draw?.turnover)} kr</span>
        </div>
      </div>
      <div className='table-header'>
        <span className='pl'>
          <span style={{marginRight:'1rem'}}>#</span>
          Match</span>
        <span>Start</span>
        <span>Folket (%)</span>
        <span>Rader</span>
      </div>
      <div className='table-body'>
        {blog?.events && blog.events.map(
          (event,index) => (
            <div key={event.id} className='table-item'>
              <div className='table-name'>
                <span className='number'>{event.id}</span>
                <div className='table-dist'>
                  <span>{event.event.participants[0].name} -</span>
                  <span>{event.event.participants[1].name}</span>
                </div>

              </div>
              {<span>{moment(event.event.sportEventStart).format('HH:mm')}</span>}
              <span>
                <span
                  className={`${event.forecast[0] ? 'selected-precent' : ''}`}>
                  {event.event.distribution.home}-
                </span>
                <span
                  className={`${event.forecast[1] ? 'selected-precent' : ''} `}>
                  {event.event.distribution.draw}-
                </span>
                <span className={`${event.forecast[2] ? 'selected-precent' : ''}`}>
                  {event.event.distribution.away}
                </span>
              </span>
              <div className='checked'>
                <span onClick={(e)=>handleChange(e,event.id,0)}
                  className={`${event.forecast[0] ? 'select-active' : ''}
                    ${blog.result ? (blog?.result[index]?.outcome == '1' ? 'win-precent' : '') : ''}`}>1</span>
                <span onClick={(e)=>handleChange(e,event.id,1)}
                  className={`${event.forecast[1] ? 'select-active' : ''}
                  ${ blog.result ? (blog?.result[index]?.outcome == 'X' ? 'win-precent' : '') : ''}`}>X</span>
                <span onClick={(e)=>handleChange(e,event.id,2)}
                  className={`${event.forecast[2] ? 'select-active' : ''}
                  ${blog.result ?(blog?.result[index]?.outcome == '2' ? 'win-precent' : '') : ''}`}>2</span>
              </div>
            </div>
          )
        )}
      </div>
      <div className='footer'>
        <span>{totalRader} rader</span>
        <span>{totalRader} kr</span>
      </div>
      {blog?.distribution?.length && <div className='finish'>
        <h3 className='finish__title'>Utdelning Svenska folket</h3>
        {blog?.distribution?.map(distribution =>(
          <div className='finish-item'>
            <div>
              <span className='right-margin'>{distribution.name}</span>
              <span>{parseInt(distribution.winners)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} st</span>
            </div>
            <span>{parseInt(distribution.amount || distribution.odds)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} kr</span>
          </div>
        ))}
      </div>}
      <div className='controls'>
        <button className={`${!canCheck ? 'disable' : ''}`} onClick={ canCheck ? redirectToGame : ()=>{}}>SKAPA REDUCERAT</button>
        <button onClick={ canCheck ? ()=> redirectToGame('admin') : ()=>{}} className={`${!canCheck ? 'disable':''} red`}><img  src={svgSven}/> LÄMNA IN</button>
      </div>
    </AsideContainer>
  )
}

export default BlogAside
import React from 'react'
import { Link } from 'react-router-dom'

import logoMark from '../../assets/reducering-logomark.svg'
import wordMark from '../../assets/reducering-wordmark.svg'

export default function Logo() {
  return (
    <>
      <div className='reducering-logo'>
        <Link to='/' alt='Reducering.se | Start'>
          <img className='reducering-logomark' src={logoMark} alt='Reducering Logomark' />
          <img className='reducering-wordmark' src={wordMark} alt='Reducering Wordmark' />
        </Link>
      </div>

      <p className='hide-for-medium'>

      </p>
    </>
  )
}

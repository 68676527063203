import React from 'react'
import { ErrorRedirect } from './Popup.elements'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'

const BlogSinglePopup = ({isModalOpen,setIsModalOpen}) => {
  return (
    <div>
      <ErrorRedirect
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
        position='center'
      >
        <button className='modalClose' onClick={() => setIsModalOpen(false)}>
          <CloseIcon alt='close' />
        </button>
        <h3>Din kupong är ofullständig!</h3>
      </ErrorRedirect>
    </div>
  )
}

export default BlogSinglePopup
import React, { useState } from 'react'
import Tooltip from 'rc-tooltip'
import { ToolsHeading } from '../../../../styles/globalStyle'
import { ReductionInner } from '../ReductionTools.elements'
import { Select, Sign } from '../ReductionTools.elements'
import {
  ColorReductionContainer,
  Inner,
  SelectColorReduction,
  LabelColorReductionMinMax,
  ReduceRed,
  ReduceYellow,
  ReduceBlue,
  ReduceGreen
} from './ColorReduction.elements'

export default function ColorReduction({
  colorReductionState: { colorReduceFunctions, colorReduction }
}) {
  const handleIsColorActive = color => {
    colorReduceFunctions.startColorMode(color)

    if (colorReduction.currentColor === color) {
      colorReduceFunctions.stopColorMode()
    } else {
      colorReduceFunctions.startColorMode(color)
    }
  }

  let [firstClick, setFirstClick] = useState({
    red: true,
    green: true,
    blue: true,
    yellow: true
  })

  return (
    <>
      <ColorReductionContainer>
        <Tooltip
          prefixCls='rToolTip'
          placement='bottom'
          trigger={['hover']}
          animation='anim'
          overlay={
            <>
              <h3>Färgreducering</h3>

              <p>
                Klicka på den färg du vill jobba med - det spelar ingen roll vilken du
                väljer - och fördela färgen på valda tecken i “ramen”. Ställ sedan in
                villkor genom att välja ett Min/Max-värde och klicka på <strong>REDUCERA</strong> för att
                reducera bort de rader som EJ uppfyller villkoret - d.v.s. de rader där
                totala färgsumman på valda tecken ligger under Min- och över Max-värdet. 
              </p>

              <p>
                Det går att använda flera färger på samma tecken och färgreducering
                fungerar utmärkt att kombinera tillsammans med poäng- och 1X2-reducering.
              </p>
            </>
          }
        >
          <ToolsHeading>
            Färgreducering <span className='rToolTip-icon'>i</span>
          </ToolsHeading>
        </Tooltip>

        <ReductionInner>
          <Inner>
            <ReduceYellow
              onClick={() => {
                if (firstClick.yellow) {
                  setFirstClick({ ...firstClick, yellow: false })
                  handleIsColorActive('yellow')
                }
                handleIsColorActive('yellow')
              }}
              isActive={colorReduction.currentColor}
            />
            <SelectColorReduction
              value={colorReduction.yellow.min}
              onChange={ev => {
                let val = parseInt(ev.target.value) || 0
                colorReduceFunctions.setMin('yellow', val || 0)
              }}
            >
              {Array.from(Array(colorReduction.yellow.total + 1).keys()).map(
                (val, idx) => (
                  <option key={`yellow-min-${idx}`} value={val}>
                    {val}
                  </option>
                )
              )}
            </SelectColorReduction>
            <SelectColorReduction
              value={colorReduction.yellow.max}
              onChange={ev => {
                let val = parseInt(ev.target.value) || 0
                colorReduceFunctions.setMax('yellow', val)
                colorReduceFunctions.setAutoIncrement('yellow', false)
              }}
            >
              {Array.from(Array(colorReduction.yellow.total + 1).keys()).slice(1).map(
                (val, idx) => (
                  <option key={`yellow-max-${idx}`} value={val}>
                    {val}
                  </option>
                )
              )}
            </SelectColorReduction>
          </Inner>

          <LabelColorReductionMinMax>
            <span>Min</span>
            <span>Max</span>
          </LabelColorReductionMinMax>

          <Inner>
            <ReduceBlue
              onClick={() => {
                if (firstClick.blue) {
                  setFirstClick({ ...firstClick, blue: false })
                  handleIsColorActive('blue')
                }
                handleIsColorActive('blue')
              }}
              isActive={colorReduction.currentColor}
            />
            <SelectColorReduction
              value={colorReduction.blue.min}
              onChange={ev => {
                let val = parseInt(ev.target.value) || 0
                colorReduceFunctions.setMin('blue', val || 0)
              }}
            >
              {Array.from(Array(colorReduction.blue.total + 1).keys()).map((val, idx) => (
                <option key={`blue-min-${idx}`} value={val}>
                  {val}
                </option>
              ))}
            </SelectColorReduction>
            <SelectColorReduction
              value={colorReduction.blue.max}
              onChange={ev => {
                let val = parseInt(ev.target.value) || 0
                colorReduceFunctions.setMax('blue', val)
                colorReduceFunctions.setAutoIncrement('blue', false)
              }}
            >
              {Array.from(Array(colorReduction.blue.total + 1).keys()).slice(1).map((val, idx) => (
                <option key={`blue-max-${idx}`} value={val}>
                  {val}
                </option>
              ))}
            </SelectColorReduction>
          </Inner>

          <LabelColorReductionMinMax>
            <span>Min</span>
            <span>Max</span>
          </LabelColorReductionMinMax>

          <Inner>
            <ReduceRed
              onClick={() => {
                if (firstClick.red) {
                  setFirstClick({ ...firstClick, red: false })
                  handleIsColorActive('red')
                }
                handleIsColorActive('red')
              }}
              isActive={colorReduction.currentColor}
            />
            <SelectColorReduction
              value={colorReduction.red.min}
              onChange={ev => {
                let val = parseInt(ev.target.value) || 0
                colorReduceFunctions.setMin('red', val || 0)
              }}
            >
              {Array.from(Array(colorReduction.red.total + 1).keys()).map((val, idx) => (
                <option key={`red-min-${idx}`} value={val}>
                  {val}
                </option>
              ))}
            </SelectColorReduction>
            <SelectColorReduction
              value={colorReduction.red.max}
              onChange={ev => {
                let val = parseInt(ev.target.value) || 0
                colorReduceFunctions.setMax('red', val)
                colorReduceFunctions.setAutoIncrement('red', false)
              }}
            >
              {Array.from(Array(colorReduction.red.total + 1).keys()).slice(1).map((val, idx) => (
                <option key={`red-max-${idx}`} value={val}>
                  {val}
                </option>
              ))}
            </SelectColorReduction>
          </Inner>

          <LabelColorReductionMinMax>
            <span>Min</span>
            <span>Max</span>
          </LabelColorReductionMinMax>

          <Inner>
            <ReduceGreen
              onClick={() => {
                if (firstClick.green) {
                  setFirstClick({ ...firstClick, green: false })
                  handleIsColorActive('green')
                }
                handleIsColorActive('green')
              }}
              isActive={colorReduction.currentColor}
            />
            <SelectColorReduction
              value={colorReduction.green.min}
              onChange={ev => {
                let val = parseInt(ev.target.value) || 0
                colorReduceFunctions.setMin('green', val || 0)
              }}
            >
              {Array.from(Array(colorReduction.green.total + 1).keys()).map(
                (val, idx) => (
                  <option key={`green-min-${idx}`} value={val}>
                    {val}
                  </option>
                )
              )}
            </SelectColorReduction>
            <SelectColorReduction
              value={colorReduction.green.max}
              onChange={ev => {
                let val = parseInt(ev.target.value) || 0
                colorReduceFunctions.setMax('green', val)
                colorReduceFunctions.setAutoIncrement('green', false)
              }}
            >
              {Array.from(Array(colorReduction.green.total + 1).keys()).slice(1).map(
                (val, idx) => (
                  <option key={`green-max-${idx}`} value={val}>
                    {val}
                  </option>
                )
              )}
            </SelectColorReduction>
          </Inner>
        </ReductionInner>
      </ColorReductionContainer>
    </>
  )
}

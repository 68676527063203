

import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ReactComponent as LoadingLogo } from '../assets/reducering-logomark.svg'

const ProtectedRoute = ({ component, ...args }) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <div className='loader'>
          <div className='loader-animation' />
          <LoadingLogo />

          <p>
            Verifying login...
          </p>
        </div>,
    })}
    {...args}
  />
);

export default ProtectedRoute;
import styled, { css } from 'styled-components'
import { ToolsPanel } from '../../../styles/globalStyle'
import {
  colors,
  font,
  breakPoints,
  spacers,
  borderRadius
} from '../../../styles/constants'

export const CouponContainer = styled.div`
  width: 100%;
  font-size: ${font.size.small};
`

export const IsLocked = styled.div`
  padding: 2rem 1.2rem;
  background: linear-gradient(90deg, #40C7A8 0%, #30B29E 100%);
  border-radius: 0.375rem;
  margin-top: 1rem;
  position: relative;
  h4{
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 1rem;
  }
  p{
  font-size: 1.2rem;
  }
  .turnamet-notes{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    align-items: flex-end;
  }
  .svg{
    width: 6rem;
    height: 6rem;
  }
  .turnament-img{
    position: absolute;
    top: -0.4rem;
    right: 0;
    display: flex;
    img{
     width: 10.6rem;
     margin-top: -2rem;
     margin-right: -2rem;
     transform: rotate(-2deg);
    }
  }
  .checkbox-container{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .checkbox{
      width: 1.6rem;
      height: 1.6rem;
      background-size: 1rem;
    }
  }
  label{
    cursor: pointer;
  }
  .link{
    color: #36474F;
    text-decoration: underline;
  }
  .info-disabled{
    font-size: 1.4rem;
    font-weight: 500;
  }
  ${props=>props.isDisabled && css`
     label{
      cursor: not-allowed;
      opacity: 0.2;
      .checkbox{
       //background:#b0acac;
      }
    }
  `}
  ${props=>props.small && css`
     margin-bottom: 1rem;
     h4{
      margin-bottom: 0;
      margin-top: 0!important;
     }
     .turnamet-notes{
      margin-top: 0;
     }
     .turnament-img{
        img{
          width: 7.5rem;
          margin-top: -1.3rem;
          margin-right: -1.3rem;
        }
     }
     p{
      color: #FFFFFF;
      font-weight: 500;
      font-size: 1rem;
     }
  `}
  small
`

export const CouponGrid = styled.div`
  display: grid;
  gap: ${spacers.xsmall};
  width: calc(100% - ${spacers.xsmall} * 2);
  grid-template-rows:
    min-content
    max-content
    max-content
    max-content;
  grid-template-columns: 60% 20% 20%;
  grid-template-areas:
    'summery forecast forecast'
    'summery input input'
    'summery clone save'
    'summery delete handin';

  @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
    gap: ${spacers.xxsmall};
    width: calc(100% - ${spacers.xsmall});
  }

  @media screen and (max-width: ${breakPoints.smallMax}) {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: repeat(3, calc(100% / 3));

    grid-template-areas:
      'summery summery summery'
      'forecast forecast forecast'
      'input goto clone '
      'delete save handin';
  }

  @media screen and (min-width: ${breakPoints.medium}) {
    grid-template-areas:
      'summery summery summery'
      'forecast safeguard safeguard'
      'forecast input input'
      'forecast clone save'
      'forecast delete handin';
  }
`

export const SignSummery = styled(ToolsPanel)`
  grid-area: summery;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .signsHeading {
    width: 100%;
    justify-content: space-between;

    .headerSigns {
      display: flex;
      justify-content: space-between;
      width: 10rem;
      margin-right: -0.5rem;

      span {
        flex-basis: 2.8rem;
      }
    }
  }

  .eventContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .eventRow {
    display: flex;
    flex-direction: row;
    margin-top: 0.596rem;
    margin-right: -0.5rem;

    + div {
      padding-top: 0.596rem;
      border-top: 1px solid ${colors.grey};
    }

    > * + * {
      margin-left: ${spacers.large};
    }

    > * {
      &:not(.eventDescription) {
        flex-basis: 2.8rem;
        font-weight: ${font.weight.semiBold};
        text-align: left;
      }
    }

    .eventNumber {
      flex-basis: 2rem;
    }
  }

  .eventDescription {
    flex: 1;
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    .eventRow:last-of-type {
      padding-bottom: ${spacers.medium};
      border-bottom: 1px solid ${colors.grey};
    }

    .safeguard {
      margin: auto auto 0 0;
      min-width: 19rem;
      > div {
        padding: 2.5rem;
        padding-bottom: 1.5rem;
        background: none;
      }
    }
  }
`

export const EventSign = styled.div`
  position: relative;
  ${({ correctBet }) =>
    correctBet &&
    `
    &::before {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: 0.2rem solid ${colors.svenskaSpelRed};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  `}

  text-align: center;
`

export const DividedForecast = styled(ToolsPanel)`
  grid-area: forecast;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  ${props => props.area && css`
    grid-area: chart;
    height: auto;
  `}
  
`

export const DividendContainer = styled.div`
  margin-top: ${spacers.large};
  width: 100%;
  min-height: 22rem;
`

export const Safeguard = styled.div`
  grid-area: safeguard;
  display: flex;
`

export const RowInput = styled.div`
  grid-area: input;
  display: flex;
`

// Coupon CTAs
const CouponCta = styled.div`
  display: flex;
  border-radius: ${borderRadius.xsmall};
  overflow: hidden;

  &:before {
    content: '';
    padding-bottom: 100%;
    width: 0;
  }

  > * {
    width: 100%;
    padding: ${spacers.xlarge};

    @media screen and (min-width: ${breakPoints.xlarge}) {
      padding: 1rem;
    }

    @media screen  and (max-width: 1140px) and (min-width: ${breakPoints.medium}) {
      padding: 1rem;
    }
  }

  img {
    width: 2rem;
    height: 2rem;
    margin-bottom: ${spacers.medium};
    filter: invert(99%) sepia(1%) saturate(5473%) hue-rotate(170deg) brightness(95%)
      contrast(80%);
    // inactive - filter: initial

    @media screen and (max-width: ${breakPoints.xlargeMax}) {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    border: none;
    cursor: pointer;
    font-weight: ${font.weight.bold};
    background-color: ${colors.toolGrey};
    color: ${colors.lightGrey};

    @media screen and (min-width: ${breakPoints.xlarge}) {
      font-size: 12px;
    }

    @media screen  and (max-width: 1140px) and (min-width: ${breakPoints.medium}) {
      font-size: 12px;
    }

    @media screen and (max-width: 1440px) and (min-width: ${breakPoints.xlarge}) {
      span {
        hyphens: auto;
      }
    }

    &:active {
      background-color: ${colors.toolGreyActive};
    }
  }
`

export const CloneCoupon = styled(CouponCta)`
  position: relative;
  grid-area: clone;
`
export const GoToLive = styled(CouponCta)`
  display: none;
  grid-area:goto;
  @media screen and (min-width: 320px) and (max-width: 767px){
    align-items: flex-end;
    padding: 15px;
    display: flex;
    font-size: 13.3px;
    background-color: #25b1e6;
    color:${colors.white};
    font-weight: ${font.weight.bold};
  }
`
export const SaveCoupon = styled(CouponCta)`
  grid-area: save;
`

export const DeleteCoupon = styled(CouponCta)`
  grid-area: delete;
  border: 0;
`

export const HandInCoupon = styled(CouponCta)`
  grid-area: handin;
  position: relative;

  img {
    align-self: flex-start;
    width: 3rem;
    height: 3rem;
    margin-top: -${spacers.large};
    margin-bottom: ${spacers.large};
    filter: initial;
  }

  // Not quite needed
  img.svs-hat {
    position: absolute;
    width: 3rem;
    height: 3rem;
    
  }

  button {
    background-color: ${colors.svenskaSpelRed};
    color: ${colors.white};

    &:active {
      background-color: ${colors.svenskaSpelRedActive};
    }
  }
`

import React from 'react'
import { ToolsHeading } from '../../../../styles/globalStyle'
import {
  ColorReductionContainer,
  Inner,
  InputColorReduction,
  LabelColorReductionMinMax,
  ReduceRed,
  ReduceYellow,
  ReduceBlue,
  ReduceGreen
} from './ColorReduction.elements'
import { ReductionInner, Select } from '../ResultWidgets.elements'
import { SelectColorReduction } from '../../../Game/ReductionTools/ColorReduction/ColorReduction.elements'

export default function ColorReduction({ colorState }) {
  return (
    <>
      <ColorReductionContainer isDisabled={true}>
        <ToolsHeading>Färgreducering</ToolsHeading>

        <ReductionInner>
          <Inner>
            <ReduceYellow />
            <SelectColorReduction>
              <option placeholder='Min' readOnly value={colorState?.yellow.min || 0}>
                {colorState?.yellow.min || 0}
              </option>
            </SelectColorReduction>
            <SelectColorReduction>
              <option placeholder='Max' readOnly value={colorState?.yellow.max || 0}>
                {colorState?.yellow.max || 0}
              </option>
            </SelectColorReduction>
          </Inner>

          <LabelColorReductionMinMax>
            <span>Min</span>
            <span>Max</span>
          </LabelColorReductionMinMax>

          <Inner>
            <ReduceBlue />
            <SelectColorReduction>
              <option placeholder='Min' readOnly value={colorState?.blue.min || 0}>
                {colorState?.blue.min || 0}
              </option>
            </SelectColorReduction>
            <SelectColorReduction>
              <option placeholder='Max' readOnly value={colorState?.blue.max || 0}>
                {colorState?.blue.max || 0}
              </option>
            </SelectColorReduction>
          </Inner>

          <LabelColorReductionMinMax>
            <span>Min</span>
            <span>Max</span>
          </LabelColorReductionMinMax>

          <Inner>
            <ReduceRed />
            <SelectColorReduction>
              <option placeholder='Min' readOnly value={colorState?.red.min || 0}>
                {colorState?.red.min || 0}
              </option>
            </SelectColorReduction>
            <SelectColorReduction>
              <option placeholder='Max' readOnly value={colorState?.red.max || 0}>
                {colorState?.red.max || 0}
              </option>
            </SelectColorReduction>
          </Inner>

          <LabelColorReductionMinMax>
            <span>Min</span>
            <span>Max</span>
          </LabelColorReductionMinMax>

          <Inner>
            <ReduceGreen />
            <SelectColorReduction>
              <option placeholder='Min' readOnly value={colorState?.green.min || 0}>
                {colorState?.green.min || 0}
              </option>
            </SelectColorReduction>
            <SelectColorReduction>
              <option placeholder='Max' readOnly value={colorState?.green.max || 0}>
                {colorState?.green.max || 0}
              </option>
            </SelectColorReduction>
          </Inner>
        </ReductionInner>
      </ColorReductionContainer>
    </>
  )
}

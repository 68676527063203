import React, { useEffect, useState, useContext } from 'react'
import { ToolsHeading } from '../../../../styles/globalStyle'
import { RowStakeContainer, Select, Option } from './RowStake.elements'
import { GameContext } from '../../../../context/gameContext'

export default function RowStake({ setBetRowAmount, betRowAmount }) {
  const { gameType, drawNumber } = useContext(GameContext)
  const [isActive, setIsActive] = useState(false)
  const handleSelect = e => {
    //console.log('handleSelect value', Number(e.target.value))
    setBetRowAmount(Number(e.target.value))
  }

  useEffect(() => {
    switch (gameType) {
      case 'stryktipset':
        //console.log('gameType: ', gameType)
        setIsActive(false)
        break
      case 'europatipset':
        //console.log('gameType: ', gameType)
        setIsActive(false)
        break
      case 'topptipset':
        //console.log('gameType: ', gameType)
        setIsActive(true)
        break
      case 'powerplay':
        //console.log('gameType: ', gameType)
        setIsActive(true)
        break
      default:
        console.log('No gametype available..')
    }
  }, [gameType])

  return (
    <RowStakeContainer isActive={isActive}>
      <ToolsHeading>Välj radinsats</ToolsHeading>
      <Select onChange={handleSelect} isActive={isActive} disabled={!isActive} defaultValue={`${betRowAmount}`}>
        <Option value='1'>1 kr</Option>
        <Option value='2'>2 kr</Option>
        {(gameType === 'topptipset' || gameType === 'powerplay') &&
          <Option value='3'>3 kr</Option>}
        <Option value='5'>5 kr</Option>
        <Option value='10'>10 kr</Option>
      </Select>
    </RowStakeContainer>
  )
}

import React, { useState, useEffect, useContext } from 'react'
import { SocketContext } from '../../context/socket'
import { GameContext } from '../../context/gameContext'
import SelectedCoupon from '../../components/Game/SelectedCoupon'
import Footer from '../../components/Footer/Footer'
import { InnerContainerLarge } from '../../styles/globalStyle'
import './GamePage.css'
import fake from '../../utils/fake.js'
import { ReactComponent as LoadingLogo } from '../../assets/reducering-logomark.svg'
import { useAuth0 } from '@auth0/auth0-react'
import {ContestContext} from "../../context/contestContext";

export default function GamePage({ initialViewState, initialId, initialNickname, initialStatus, historicLoad, preloadState,actionType,updateLoadedState,changeLocked,changeLockedNull }) {
  const socket = useContext(SocketContext)
  const gameInfo = useContext(GameContext)
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [gameType, drawNumber] = [gameInfo.gameType, gameInfo.drawNumber]
  const [isLocked, setIsLocked] = useState(false)
  const [data, setData] = useState(null)
  const [infoLive,setInfoLive] = useState(null)
  const [isMine, setIsMine] = useState(false)
  const { isSM } = useContext(ContestContext);
  useEffect(() => {
    if (process.env.REACT_APP_NO_BACKEND.toUpperCase() === 'TRUE') {
      setData(fake.europatipset)
    } else if (!historicLoad) {
      // As soon as component is mounted, do:
      // console.log("emitting JOIN_DATA_CHANNEL")
      socket.emit('JOIN_DATA_CHANNEL', { game: gameType, draw: drawNumber })
      socket.on('data', data => {
        setData(data)
      })
      socket.on('statistic', data => {
        setInfoLive(data)
      })
    } else {
      console.log("emitting REQUEST_SPECIFIC", initialViewState.fetchDataUrlName, drawNumber)
      socket.emit('REQUEST_SPECIFIC', { game: (initialViewState.fetchDataUrlName || gameType), draw: drawNumber })
      socket.on('data', data => {
        setData(data)
      })
    }

    return () => {
      // Before component is destroyed
      // Unbind all event handlers used in this component
      //console.log(`stop data from channel: ${gameType}/${drawNumber}`)
      socket.emit('LEAVE_DATA_CHANNEL', { game: gameType, draw: drawNumber })
      socket.off('data')
      socket.off('statistic')
    }
  }, [socket, gameType, drawNumber])
  // if we have game type stryktipset and id - needto check is locked coupon
  useEffect(async()=>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers= {}
    }
    if(isSM && gameType === 'europatipset' && drawNumber && isAuthenticated){ // Changed to enable contest
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/coupons/${drawNumber}/locked`,{
        headers:headers
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Not found')
          }
          return response.json()
        })
        .then(data => {
          setIsLocked(!!data)
        })
        .catch(error => {
         debugger
        })
  }else{}
    if(isAuthenticated && initialId && drawNumber){
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/usercoupons`, {
        headers: headers
      }).then(response => {
        if (!response.ok) {
          throw new Error('Not found')
        }
        return response.json()
      })
        .then(data => {
          let coupons = data?.data?.usercoupons?.find(coupon => coupon.id === initialId);
          if(coupons.id){
            setIsMine(true)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }else{}
    },[drawNumber, gameType, getAccessTokenSilently, initialId, initialViewState, isAuthenticated, isSM])
  return (
    <div className='gamePageContainer'>
      {data ? (
        <>
          <InnerContainerLarge>
            <SelectedCoupon
              data={data}
              actionType={actionType}
              initialViewState={initialViewState}
              initialId={initialId}
              initialNickname={initialNickname}
              initialStatus={initialStatus}
              preloadState={preloadState}
              isLocked={isLocked}
              setIsLocked={setIsLocked}
              isMine={isMine}
              setIsMine={setIsMine}
              updateLoadedState={updateLoadedState}
              changeLocked={changeLocked}
              changeLockedNull={changeLockedNull}
            />
          </InnerContainerLarge>
          <InnerContainerLarge>
            <Footer />
          </InnerContainerLarge>
        </>
      ) : (
        <div className='loader'>
          <div className='loader-animation' />
          <LoadingLogo />

          <p>Laddar sidan</p>
        </div>
      )}
    </div>
  )
}

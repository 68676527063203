import {  LotteryCreatePopup } from './Popup.elements'
import React, {useContext,useState,useRef} from 'react'
import 'reactjs-popup/dist/index.css';
import WinSection from '../HomePage/WinSection/WinSection'


const LotteryPopup = ({
  isModalOpen,
  setIsModalOpen,
  formLottery,
  setFormLottery,
  setPhotoLoterry,
  photoLoterry,
  copyLottery,
  errorLottery,
                        setErrorLottery,
  handleUpdateLottery}) => {

  const handleRemove = ()=>{
    setFormLottery(prev =>({...copyLottery}))
    setErrorLottery(null)
    setPhotoLoterry(null)
    setIsModalOpen(false)
  }

  return (
    <LotteryCreatePopup
        open={isModalOpen}
        onClose={() => {
          setErrorLottery(null)
          setIsModalOpen(false)
        }}
        position='center'
        closeOnDocumentClick={false}
      >
      <WinSection
        handleUpdateLottery={handleUpdateLottery}
        lottery={formLottery}
        setLottery={setFormLottery}
        isEditing={true}
        photoLoterry={photoLoterry}
        setPhotoLoterry={setPhotoLoterry}
        handleRemove={handleRemove}
        errorLottery={errorLottery}
      />
    </LotteryCreatePopup>
  )
}

export default LotteryPopup
import React, { useEffect, useState } from 'react'
import { ReactComponent as LoadGameIcon } from '../../../assets/load-tool.svg'
import { LoaderCouponContainer } from './LoaderCoupon.styles'

const LoaderCoupon = ({
 area,
 uploadCoupon,
 id,
}) => {
  const [value, setValue] = useState("")
  useEffect(()=>{
    setValue(id)
  },[id])
  return (
    <LoaderCouponContainer area={area}>
      <span className='info'>
        {id ? 'KUPONG' : 'LADDA'}
        <br/>
        {id ? 'LADDAD' : 'KUPONG' }
      </span>
      <input
        className='input'
        value={value}
        type='text'
        placeholder={ id ? `${id}` :'Kopiera in länken till ditt spel här..'}
        onChange={(e) => (
          setValue(e.target.value)
        )}
        maxLength={255}
      />
      <button
        className='button'
        onClick={() => {
          uploadCoupon(value)
        }}
      >
        <LoadGameIcon />
      </button>
    </LoaderCouponContainer>
  )
}

export default LoaderCoupon
import React, { useState, useEffect, useContext } from "react";
import {
  AdminContent,
  InnerContainer,
  AdminLottery,
} from "../../styles/globalStyle";
import Footer from "../../components/Footer/Footer";
import HomeHeader from "../../components/Headers/HomeHeader";
import Table from "../../components/Table/Table";
import svsPOwerplay from "../../assets/svs_product-powerplay.svg";
import svsEuro from "../../assets/svs_product_europatipset.svg";
import svsStryk from "../../assets/svs_product_stryktipset.svg";
import svsTop from "../../assets/svs_product_topptipset.svg";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DeleteItemPopup from "../../components/Popup/DeleteItemPopup";
import CreateItemPopup from "../../components/Popup/CreateItemPopup";
import { useAuth0 } from "@auth0/auth0-react";
import { AccountContext } from "../../context/accountContext";
import CustomChekbox from "../../components/CustomCheckbox/CustomChekbox";
import Pen from "../../assets/pen.png";
import Send from "../../assets/send.svg";
import LotteryPopup from "../../components/Popup/LotteryPopup";

const gameType = [
  {
    gameType: "europatipset",
    img: svsEuro,
  },
  {
    gameType: "powerplay",
    img: svsPOwerplay,
  },
  {
    gameType: "stryktipset",
    img: svsStryk,
  },
  {
    gameType: "topptipset",
    img: svsTop,
  },
];

const teamHead = {
  first: "Titel",
  second: "Email",
  last: "Datum",
};

const usersHead = {
  first: "Email",
  second: "Namn",
  last: "Notera",
};

const header = {
  first: "Resultat STRYKTIPSET 2021-11-20",
  second: "info@gmail.com",
  last: "12/12/2021",
};

const row = [
  {
    first: "Resultat STRYKTIPSET 2021-11-20",
    second: "info@gmail.com",
    last: { info: "dsfsdf" },
  },
  {
    first: "Resultat STRYKTIPSET 2021-11-20",
    second: "info@gmail.com",
    last: { info: "dsfsdf" },
  },
];

const header1 = {
  first: "Titel",
  second: "Datum",
  last: "Kategori",
  name: "User",
};
const header2 = {
  first: "Titel",
  second: "User",
  last: "Datum",
};

const row1 = [
  {
    first: "Resultat STRYKTIPSET 2021-11-20",
    second: "12/12/2021",
    last: {
      img: svsEuro,
    },
  },
  {
    first: "Resultat STRYKTIPSET 2021-11-20",
    second: "12/12/2021",
    last: {
      img: svsEuro,
    },
  },
];

const SelectedSubtitle = {
  text: "SELECTED EMAILS",
  add: true,
};
const BannedSubtitle = {
  text: "BLACK LIST",
  add: true,
};
const AuthorSubtitle = {
  text: "AUTHOR LIST",
  add: true,
};

const CreateBlogSubtitle = {
  text: "CREATE BLOG",
  add: true,
};

const CreateNewsSubtitle = {
  text: "CREATE NEWS",
  add: true,
};

const AdminPage = () => {
  const user = useContext(AccountContext);
  const history = useHistory();
  //state for fetch and check admin role
  const [fetchAllPage, setFetchAllPage] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (user.admin === false) {
        history.push("/");
      } else {
        setFetchAllPage(true);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [user]);
  const findType = (game) => {
    let find = gameType.find((games) => games.gameType === game);
    return find.img;
  };
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Modal for user add
  const [isCreateItemModal, setIsCreateItemModal] = useState(false);
  const [createItem, setCreateItem] = useState({
    email: "",
    username: "",
    note: "",
  });
  // Props to delete popup
  const [typeDelete, setTypeDelete] = useState(null);
  //  Props to create popup
  const [createRole, setCreateRole] = useState(null);

  const formatDate = (date) => {
    let currentDate = moment(date).format("DD/MM/YYYY");
    return currentDate;
  };

  // Page count for tables
  //State for teams
  const [pageCount, setPageCount] = useState(1);
  const [AllPage, setAllPage] = useState(null);
  const [team, setTeam] = useState([]);
  const [isLoaderTeam, setIsLoaderTeam] = useState(false);

  // State for selected
  const [pageCountSelected, setPageCountSelected] = useState(1);
  const [AllPageSelected, setAllPageSelected] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isLoaderSelected, setIsLoaderSelected] = useState(false);

  // State for banList
  const [pageCountBanned, setPageCountBanned] = useState(1);
  const [AllPageBanned, setAllPageBanned] = useState(null);
  const [bannedUsers, setBannedUsers] = useState([]);
  const [isLoaderBanned, setIsLoaderBanned] = useState(false);
  //State for blog
  const [pageCountBlog, setPageCountBlog] = useState(1);
  const [AllPageBlog, setAllPageBlog] = useState(null);
  const [blog, setBlog] = useState([]);
  const [isLoaderBlog, setIsLoaderBlog] = useState(false);
  //State for news
  const [pageCountNews, setPageCountNews] = useState(1);
  const [AllPageNews, setAllPageNews] = useState(null);
  const [news, setNews] = useState([]);
  const [isLoaderNews, setIsLoaderNews] = useState(false);

  //State for author
  const [pageCountAuthor, setPageCountAuthor] = useState(1);
  const [AllPageAuthor, setAllPageAuthor] = useState(null);
  const [authorUsers, setAuthorUsers] = useState([]);
  const [isLoaderAuthor, setIsLoaderAuthor] = useState(false);
  //State for lottery
  const [lotteryPopup, setLotteryPopup] = useState(false);
  const [lotteryLoad, setLotteryLoad] = useState(true);
  const [photoLoterry, setPhotoLoterry] = useState(null);
  const [errorLottery, setErrorLottery] = useState(null);
  const [formLottery, setFormLottery] = useState({
    title: "",
    text: "",
    hidden: true,
    photo: null,
  });
  const [copyLottery, setCopyLottery] = useState({
    title: "",
    text: "",
    hidden: true,
    photo: null,
  });
  // Get Blog
  const fetchBlog = async () => {
    setIsLoaderBlog(true);
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    let reset = await setBlog([]);
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/blog?page=${pageCountBlog}&perPage=6`,
      {
        headers: headers,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTimeout(() => {
          setIsLoaderBlog(false);
        }, 500);
        if (data.blogs.length > 0) {
          let blogs = data.blogs.map((item) => ({
            first: item.title,
            name: item.realAuthor,
            second: formatDate(item.date),
            last: {
              img: findType(item.gameType),
            },
            infoItem: { ...item, link: `/blog/${item.gameType}/${item._id}` },
          }));
          setBlog(blogs);
          let count = data.total;
          let totalPage = count / 6;
          setAllPageBlog(Math.ceil(totalPage));
        } else {
          setBlog([]);
        }
        //setBlog(data?.blogs)
      });
  };

  const fetchNews = async () => {
    setIsLoaderNews(true);
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    let reset = await setNews([]);
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/news?page=${pageCountNews}&perPage=6`,
      {
        headers: headers,
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTimeout(() => {
          setIsLoaderNews(false);
        }, 500);
        if (data.news.length > 0) {
          let news = data.news.map((item) => ({
            first: item.title,
            second: item.realAuthor,
            last: { info: formatDate(item.date) },
            infoItem: { ...item, link: `/nyheter/${item._id}` },
          }));
          setNews(news);
          let count = data.total;
          let totalPage = count / 6;
          setAllPageNews(Math.ceil(totalPage));
        } else {
          setNews([]);
        }
      });
  };

  // Get Team
  const fetchTeam = async () => {
    if (isLoading) setIsLoaderTeam(true);
    if (!isLoading) {
      setIsLoaderTeam(true);
      let token = isAuthenticated ? await getAccessTokenSilently() : {};
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/teams?page=${pageCount}&perPage=6`,
        {
          headers: headers,
        }
      )
        .then((res) => {
          return res.json();
          //return res.json()
        })
        .then((data) => {
          setTimeout(() => {
            setIsLoaderTeam(false);
          }, 500);
          if (data.teams.length > 0) {
            let teams = data.teams.map((item) => ({
              first: item.title,
              second: item.email,
              last: { info: formatDate(item.date) },
              infoItem: { ...item },
            }));
            setTeam(teams);
            let count = data.total;
            let totalPage = count / 6;
            console.log(Math.ceil(totalPage));
            setAllPage(Math.ceil(totalPage));
          } else {
            setTeam([]);
          }
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    }
  };

  // Get selected
  const fetchSelected = async () => {
    if (isLoading) setIsLoaderSelected(true);
    if (!isLoading) {
      setIsLoaderSelected(true);
      let token = isAuthenticated ? await getAccessTokenSilently() : {};
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/users?page=${pageCountSelected}&perPage=6&search=priviledged&searchValue=true`,
        {
          headers: headers,
        }
      )
        .then((res) => {
          return res.json();
          //return res.json()
        })
        .then((data) => {
          setTimeout(() => {
            setIsLoaderSelected(false);
          }, 500);
          if (data.users.length > 0) {
            let selected = data.users.map((item) => ({
              first: item.email,
              second: item.username || "-",
              last: { info: item.note },
              infoItem: { ...item },
            }));
            setSelectedUsers(selected);
            let count = data.total;
            let totalPage = count / 6;
            setAllPageSelected(Math.ceil(totalPage));
          } else {
            setSelectedUsers([]);
          }
        })
        .catch((err) => {
          debugger;
          console.log(err);
        });
    }
  };

  // Get banned
  const fetchBanned = async () => {
    if (isLoading) setIsLoaderBanned(true);
    if (!isLoading) {
      setIsLoaderBanned(true);
      let token = isAuthenticated ? await getAccessTokenSilently() : {};
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/users?page=${pageCountBanned}&perPage=6&search=banned&searchValue=true`,
        {
          headers: headers,
        }
      )
        .then((res) => {
          return res.json();
          //return res.json()
        })
        .then((data) => {
          setTimeout(() => {
            setIsLoaderBanned(false);
          }, 500);
          if (data.users.length > 0) {
            let banned = data.users.map((item) => ({
              first: item.email,
              second: item.username || "-",
              last: { info: item.note },
              infoItem: { ...item },
            }));
            setBannedUsers(banned);
            let count = data.total;
            let totalPage = count / 6;
            setAllPageBanned(Math.ceil(totalPage));
          } else {
            setBannedUsers([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  //Get Author
  const fetchAuthor = async () => {
    if (isLoading) setIsLoaderAuthor(true);
    if (!isLoading) {
      setIsLoaderAuthor(true);
      let token = isAuthenticated ? await getAccessTokenSilently() : {};
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/users?page=${pageCountBanned}&perPage=6&search=author&searchValue=true`,
        {
          headers: headers,
        }
      )
        .then((res) => {
          return res.json();
          //return res.json()
        })
        .then((data) => {
          setTimeout(() => {
            setIsLoaderAuthor(false);
          }, 500);
          if (data.users.length > 0) {
            let author = data.users.map((item) => ({
              first: item.email,
              second: item.username || "-",
              last: { info: item.note },
              infoItem: { ...item },
            }));
            setAuthorUsers(author);
            let count = data.total;
            let totalPage = count / 6;
            setAllPageAuthor(Math.ceil(totalPage));
          } else {
            setAuthorUsers([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Delete news
  const handleDeleteNews = async (id) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/news/${id}`,
      {
        method: "DELETE",
        headers: headers,
      }
    );
    if (response.ok) {
      setIsModalOpen(false);
      fetchNews();
    }
    return response.json();
  };

  // Delete Team
  const handleDeleteTeam = (e, id, type) => {
    console.log("Delete", id);
    setIsModalOpen(true);
    if (type === "team") {
      setTypeDelete({ handle: handlDeleteTeam, id: id });
    } else if (type === "banned") {
      setTypeDelete({ handle: handleBanned, id: id });
    } else if (type === "priviledged") {
      setTypeDelete({ handle: handleSelected, id: id });
    } else if (type === "blog") {
      setTypeDelete({
        handle: handlDeleteBlog,
        id: id,
      });
    } else if (type === "news") {
      setTypeDelete({
        handle: handleDeleteNews,
        id: id,
      });
    } else if (type === "author") {
      setTypeDelete({
        handle: handleDeleteAuthor,
        id: id,
      });
    }
  };

  // Create User Role
  const handleCreate = (e, type) => {
    setIsCreateItemModal(true);
    if (type === "banned") {
      setCreateRole({
        handle: handleCreateBanned,
        type: type,
      });
    } else if (type === "priviledged") {
      setCreateRole({
        handle: handleCreateSelected,
        type: type,
      });
    } else if (type === "author") {
      setCreateRole({
        handle: handleCreateAuthor,
        type: type,
      });
    }
  };
  // handle to create a user role
  const handleCreateBanned = async (id, date) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    let data = {
      banned: true,
      priviledged: false,
      ...date,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/user/${id}`,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      setIsCreateItemModal(false);
      fetchSelected();
      fetchBanned();
      setCreateItem({
        email: "",
        username: "",
        note: "",
      });
    }
    return response.json();
  };
  const handleCreateSelected = async (id, date) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    let data = {
      priviledged: true,
      banned: false,
      ...date,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/user/${id}`,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      setIsCreateItemModal(false);
      fetchSelected();
      fetchBanned();
      setCreateItem({
        email: "",
        username: "",
        note: "",
      });
    }
    return response.json();
  };
  const handleCreateAuthor = async (id, date) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    let data = {
      author: true,
      ...date,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/user/${id}`,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      setIsCreateItemModal(false);
      fetchSelected();
      fetchBanned();
      fetchAuthor();
      setCreateItem({
        email: "",
        username: "",
        note: "",
      });
    }
    return response.json();
  };
  // fecth loterry
  const fetchLottery = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers;
    //setWinLoader(true)
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {};
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/lottery`, {
      headers: headers,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else return null;
      })
      .then((data) => {
        if (data == null) return;
        if (data?.lottery) {
          setFormLottery((prev) => ({
            ...prev,
            title: data?.lottery?.title,
            text: data?.lottery?.text,
            hidden: data?.lottery?.hidden,
          }));
          setCopyLottery((prev) => ({
            ...prev,
            title: data?.lottery?.title,
            text: data?.lottery?.text,
            hidden: data?.lottery?.hidden,
          }));
        }
        setTimeout(() => {
          // setWinLoader(false)
        }, 500);
      });
  };
  const fetchLotteryPhoto = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers;
    //setWinLoader(true)
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {};
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/lotteryPhoto`, {
      headers: headers,
    })
      .then((res) => {
        if (res.ok) return res.blob();
        else return null;
      })
      .then((data) => {
        if (data == null) return;
        let url = URL.createObjectURL(data);
        setFormLottery((prev) => ({ ...prev, photo: url }));
        setCopyLottery((prev) => ({ ...prev, photo: url }));
        setTimeout(() => {
          // setWinLoader(false)
        }, 500);
      });
  };
  useEffect(async () => {
    if (fetchAllPage) {
      fetchTeam();
      fetchSelected();
      fetchBanned();
      fetchBlog();
      fetchNews();
      fetchAuthor();
      fetchLottery();
      fetchLotteryPhoto();
    }
  }, [isAuthenticated, fetchAllPage]);

  // Use effect for change pages pagination
  useEffect(async () => {
    if (fetchAllPage) {
      await AllPage;
      fetchTeam();
    }
  }, [pageCount, fetchAllPage]);

  useEffect(async () => {
    if (fetchAllPage) {
      await AllPageBlog;
      fetchBlog();
    }
  }, [pageCountBlog, fetchAllPage]);

  useEffect(async () => {
    if (fetchAllPage) {
      await AllPageNews;
      fetchNews();
    }
  }, [pageCountNews, fetchAllPage]);

  useEffect(async () => {
    if (fetchAllPage) {
      await AllPageBlog;
      fetchAuthor();
    }
  }, [pageCountAuthor, fetchAllPage]);

  useEffect(async () => {
    if (fetchAllPage) {
      await AllPageSelected;
      fetchSelected();
    }
  }, [pageCountSelected, fetchAllPage]);

  useEffect(async () => {
    if (fetchAllPage) {
      await AllPageBanned;
      fetchBanned();
    }
  }, [pageCountBanned, fetchAllPage]);
  /* useEffect(async ()=>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/teams?page=0&perPage=10`,{
      headers: headers
    }).then(res =>{
      return res.json()
      //return res.json()
    }).then(data=>{
      if(data.teams.length > 0){
        let teams = data.teams.map(item =>
          ({
            first:item.title,
            second:item.email,
            last: { info:formatDate(item.date) },
            infoItem:{...item}
          })
        )
        setTeam(teams)
      }
    })
      .catch(err =>{
        debugger
        console.log(err)
      })
  },[pageCount])*/

  // handler to delete items
  const handlDeleteTeam = async (id) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/teams/${id}`,
      {
        method: "DELETE",
        headers: headers,
      }
    );
    if (response.ok) {
      setIsModalOpen(false);
      fetchTeam();
    }
    console.log("Delete", response);
    return response.json();
  };
  const handleBanned = async (id) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    let data = {
      banned: false,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/user/${id}`,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      setIsModalOpen(false);
      fetchBanned();
    }
    console.log("Delete", response);
    return response.json();
  };
  const handleSelected = async (id) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    let data = {
      priviledged: false,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/user/${id}`,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      setIsModalOpen(false);
      fetchSelected();
    }
    return response.json();
  };
  const handleDeleteAuthor = async (id) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    let data = {
      author: false,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/user/${id}`,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      setIsModalOpen(false);
      fetchAuthor();
    }
    return response.json();
  };
  const handlDeleteBlog = async (id) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/blog/${id}`,
      {
        method: "DELETE",
        headers: headers,
      }
    );
    if (response.ok) {
      setIsModalOpen(false);
      fetchBlog();
    }
    return response.json();
  };
  // handle to update inside table
  const handleUpdate = async (update) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    let { id, note } = update;
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/user/${id}`,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify({ note: note }),
      }
    );
    if (response.ok) {
      setIsModalOpen(false);
      fetchSelected();
      fetchBanned();
      fetchAuthor();
    }
    return response.json();
  };
  // update lottery
  const handleUpdateLottery = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const pathObject = {
      title: formLottery.title,
      text: formLottery.text,
      hidden: formLottery.hidden,
    };
    let uploadImg;
    if (photoLoterry) {
      uploadImg = await postLotteryImg();
      if (uploadImg?.ok) {
      } else {
        setFormLottery((prev) => ({ ...prev, photo: copyLottery.photo }));
        setErrorLottery(uploadImg.statusText);
      }
    } else {
      uploadImg = { ok: true };
    }
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/lottery`,
      {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(pathObject),
      }
    );
    let result;
    if (response.ok && uploadImg?.ok) {
      result = await response.json();
      setLotteryPopup(false);
      setErrorLottery(null);
      setFormLottery((prev) => ({
        ...prev,
        title: result?.lottery?.title,
        text: result?.lottery?.text,
      }));
      setCopyLottery((prev) => ({
        ...prev,
        title: result?.lottery?.title,
        text: result?.lottery?.text,
      }));
    } else {
      if (uploadImg.ok) setErrorLottery(response?.statusText);
    }
    return result;
  };
  // upload image
  const postLotteryImg = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      //'Content-Type': 'application/json',
      //'Content-Type': "multipart/form-data",
      //'Content-Type':'application/x-www-form-urlencoded',
      Authorization: `Bearer ${token}`,
    };
    const formData = new FormData();
    formData.append("lotteryPhoto", photoLoterry);
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/lotteryPhoto`,
      {
        method: "POST",
        headers: headers,
        body: formData,
      }
    );
    return response;
    if (response.ok) {
      /*result = await response.json()
      setLotteryPopup(false)
      setFormLottery(prev =>({...prev,
        title:result?.lottery?.title,
        text:result?.lottery?.text,
      }))*/
    }
  };
  // handle to redirect
  const handleRedirect = () => {
    history.push("/create-blog");
  };
  const handleRedirectNews = () => {
    history.push("/create-news");
  };
  return (
    <div>
      {lotteryPopup && (
        <LotteryPopup
          isModalOpen={lotteryPopup}
          setIsModalOpen={setLotteryPopup}
          formLottery={formLottery}
          setFormLottery={setFormLottery}
          handleUpdateLottery={handleUpdateLottery}
          photoLoterry={photoLoterry}
          setPhotoLoterry={setPhotoLoterry}
          copyLottery={copyLottery}
          errorLottery={errorLottery}
          setErrorLottery={setErrorLottery}
        />
      )}
      {isModalOpen && (
        <DeleteItemPopup
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          typeDelete={typeDelete}
        />
      )}
      {isCreateItemModal && (
        <CreateItemPopup
          isModalOpen={isCreateItemModal}
          setIsModalOpen={setIsCreateItemModal}
          createItem={createItem}
          setCreateItem={setCreateItem}
          handleCreate={createRole}
        />
      )}
      <HomeHeader />
      <AdminContent>
        <h3>ADMINPANEL</h3>
        <div className="item">
          <h4>BLOG</h4>
          <Table
            header={header1}
            row={blog}
            modFor={true}
            title={CreateBlogSubtitle}
            handleCreate={handleRedirect}
            deleteItem={handleDeleteTeam}
            pageCount={pageCountBlog}
            setPageCount={setPageCountBlog}
            AllPage={AllPageBlog}
            typeAccout="blog"
            typeIcon="redirect"
            isLoading={isLoaderBlog}
          ></Table>
        </div>
        <div className="item">
          <h4>NYHETER</h4>
          <Table
            header={header2}
            row={news}
            modFor={true}
            title={CreateNewsSubtitle}
            handleCreate={handleRedirectNews}
            deleteItem={handleDeleteTeam}
            pageCount={pageCountNews}
            setPageCount={setPageCountNews}
            AllPage={AllPageNews}
            typeAccout="news"
            typeIcon="redirect"
            isLoading={isLoaderNews}
          ></Table>
        </div>
        <div className="item">
          <h4>ANDELSLAG</h4>
          <Table
            header={teamHead}
            row={team}
            typeIcon="redirect"
            deleteItem={handleDeleteTeam}
            pageCount={pageCount}
            setPageCount={setPageCount}
            AllPage={AllPage}
            typeAccout="team"
            isLoading={isLoaderTeam}
          ></Table>
        </div>
        <div className="item">
          <Table
            header={usersHead}
            row={selectedUsers}
            size={true}
            title={SelectedSubtitle}
            typeIcon="pen"
            isEdit={true}
            handleCreate={handleCreate}
            deleteItem={handleDeleteTeam}
            pageCount={pageCountSelected}
            setPageCount={setPageCountSelected}
            AllPage={AllPageSelected}
            typeAccout="priviledged"
            handleUpdate={handleUpdate}
            isLoading={isLoaderSelected}
          ></Table>
        </div>
        <div className="item">
          <Table
            header={usersHead}
            row={bannedUsers}
            handleCreate={handleCreate}
            size={true}
            title={BannedSubtitle}
            isEdit={true}
            typeIcon="pen"
            deleteItem={handleDeleteTeam}
            pageCount={pageCountBanned}
            setPageCount={setPageCountBanned}
            AllPage={AllPageBanned}
            typeAccout="banned"
            handleUpdate={handleUpdate}
            isLoading={isLoaderBanned}
          ></Table>
        </div>
        <div className="item">
          <Table
            header={usersHead}
            row={authorUsers}
            handleCreate={handleCreate}
            size={true}
            title={AuthorSubtitle}
            isEdit={true}
            typeIcon="pen"
            deleteItem={handleDeleteTeam}
            pageCount={pageCountAuthor}
            setPageCount={setPageCountAuthor}
            AllPage={AllPageAuthor}
            typeAccout="author"
            handleUpdate={handleUpdate}
            isLoading={isLoaderAuthor}
          ></Table>
        </div>
        <AdminLottery>
          <h5>Lottery</h5>
          <div className="lottery-head">
            <p className="lottery-title">Title</p>
            <p className="lottery-title">Text</p>
            <p className="lottery-title">Hidden</p>
          </div>
          <div className="lottery">
            <p>{formLottery.title}</p>
            <p>{formLottery.text}</p>
            <CustomChekbox
              disabled={true}
              title=""
              checked={formLottery.hidden}
              onChange={() =>
                setFormLottery((prev) => ({ ...prev, hidden: !prev.hidden }))
              }
            />
            <div className="lottery-controls">
              <span>
                <img onClick={() => setLotteryPopup(true)} src={Pen} />
              </span>
            </div>
          </div>
        </AdminLottery>
      </AdminContent>
      <InnerContainer>
        <Footer />
      </InnerContainer>
    </div>
  );
};

export default AdminPage;

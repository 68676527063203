import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  handlePopup,
  importGameTypeLogos,
  gameTurnoverWithSpace,
  getActiveDate,
  ActiveGamesRouter,
} from "../../utils/helperFunctions";
import { ActiveGamesContainer } from "../../styles/globalStyle";
import Rull from "../../assets/rullpott-reducering-wordmark.png";

import svenskaSpelLogo from "../../assets/svenskaspel-logomark.svg";
import svsBlueSymbol from "../../assets/svs-blue-symbol.svg";
import reduceraSm from "../../assets/reducering-sm-blue.svg";
import reduceraEm from "../../assets/reducering-em.svg";
import { ContestContext } from "../../context/contestContext";

export default function ActiveGamesBox({
  data,
  gameTypeAssets: { gameType, logo, color, amountOfGames },
  popupAssets: { setModalData, setIsModalOpen, isModalOpen },
}) {
  const images = importGameTypeLogos(
    require.context("../../assets/", false, /.*\.svg$/),
  );
  const { isSM } = useContext(ContestContext);
  const now = new Date();
  const closestFutureDraw = data?.draws
    .filter((draw) => new Date(draw.closeTime) > now)
    .sort((a, b) => new Date(a.closeTime) - new Date(b.closeTime))[0];
  const turnover = closestFutureDraw
    ? gameTurnoverWithSpace(closestFutureDraw.turnover)
    : "N/A";
  const activeDate = closestFutureDraw
    ? getActiveDate({ draws: [closestFutureDraw] }, 0, true)
    : "N/A";

  const renderFirstMatchingImage = () => {
    const firstMatchingDraw = data?.draws.find(
      (draw) =>
        draw.jackpotItems ||
        (draw.fund && parseFloat(draw.fund.extraMoney) > 0) ||
        (draw.fund && parseFloat(draw.fund.rolloverIn) > 0),
    );

    if (!firstMatchingDraw) return null;

    const isJackpot = firstMatchingDraw.jackpotItems;
    const isExtraMoney =
      firstMatchingDraw.fund &&
      parseFloat(firstMatchingDraw.fund.extraMoney) > 0;
    const isRolloverIn =
      firstMatchingDraw.fund &&
      parseFloat(firstMatchingDraw.fund.rolloverIn) > 0;

    return (
      <>
        {isJackpot && (
          <img
            src={images["jackpot-reducering-wordmark.svg"].default}
            alt="Jackpot"
            className="jackpot"
          />
        )}
        {isExtraMoney && (
          <img
            src={images["extrapengar-reducering-wordmark.svg"].default}
            alt="Extra pengar"
            className="extra-pengar"
          />
        )}
        {isRolloverIn && (
          <img src={Rull} alt="rullpott" className="extra-pengar" />
        )}

        {data.draws[0].jackpotItems && isJackpot && (
          <p>
            <strong>Jackpot {data.draws[0].jackpotItems[0].amount}</strong>
          </p>
        )}
        {data.draws[0].fund &&
          parseFloat(data.draws[0].fund.extraMoney) > 0 &&
          isExtraMoney && (
            <p>
              <strong>
                Extrapengar{" "}
                {data.draws[0].fund.extraMoney
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  .slice(0, -3)}{" "}
                kr
              </strong>
            </p>
          )}
        {data.draws[0].fund &&
          parseFloat(data.draws[0].fund.rolloverIn) > 0 &&
          isRolloverIn && (
            <p>
              <strong>
                Rullpott{" "}
                {data.draws[0].fund.rolloverIn
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  .slice(0, -3)}{" "}
                kr
              </strong>
            </p>
          )}
      </>
    );
  };

  return (
    <ActiveGamesContainer
      gameTypeColor={color}
      // disabled={gameType === 'bomben'}
      className={
        amountOfGames > 1 ? "hasModal" : !amountOfGames ? "disabled" : "active"
      }
      onClick={
        amountOfGames > 1
          ? () =>
              handlePopup(
                gameType,
                data,
                setModalData,
                setIsModalOpen,
                isModalOpen,
              )
          : null
      }
    >
      <div className="svsBlueSymbolContainer">
        <img
          className="svsBlueSymbol"
          src={svsBlueSymbol}
          alt="Svenska Spel Symbol"
        />
      </div>
      {/* {gameType === 'europatipset' && isSM && (
              <div className="svsBlueSymbolContainer">
                  <img className='reduceringSm' src={reduceraEm} alt='reduceraSm' />
              </div>
          )} */}
      <ActiveGamesRouter
        condition={amountOfGames > 1}
        wrapper={(children) => (
          <Link
            to={
              !amountOfGames
                ? `/${gameType}/`
                : `/reducera/${gameType}/${data?.draws[0]?.drawNumber}`
            }
          >
            {children}
          </Link>
        )}
      >
        <div className="activeGameHeader">
          <img
            className="svenskaSpelLogo"
            src={svenskaSpelLogo}
            alt="Svenska Spel"
          />

          {amountOfGames <= 1 ? null : (
            <button className="activeGamesLength">{amountOfGames}</button>
          )}
        </div>
        <div>
          {renderFirstMatchingImage()}

          {!amountOfGames ? null : (
            <>
              <p>{"Omsättning " + turnover + " kr"}</p>
              <p>Spelstopp {activeDate}</p>
            </>
          )}
        </div>
        <img
          className={`itemLogo ${gameType === "powerplay" && "powerPlay"}`}
          src={logo}
          alt={gameType}
        />
      </ActiveGamesRouter>
    </ActiveGamesContainer>
  );
}

import styled, { css } from 'styled-components'
import { breakPoints, spacers, colors, borderRadius, font } from '../../styles/constants'

export const FooterContent = styled.div`
  margin-top: ${spacers.small};
  border-radius: ${borderRadius.small};
  font-size: ${font.size.small};
  background-color: ${colors.panelDarkGrey};
  padding: 4rem;
  padding-bottom: 0;
  width: 100%;
  ${props=>props.marginBottom && css`
    margin-bottom: 4.8rem;
    @media screen and (max-width: 960px){
      margin-bottom: 8.7rem;
    }
     @media screen and (max-width: 765px){
      margin-bottom: 7.3rem;
    }
  `}
  @media screen and (max-width: ${breakPoints.small}) {
    padding: 2.5rem;
    padding-bottom: 0;
  }
  .gameLink {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
  }
  .footer-nav-games{
  display: flex;
  grid-gap: 1.2rem;
    .disabled{
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
  p {
    margin-bottom: 1.6rem;
    font-size: 1.4rem;
    &:last-child{
    margin-bottom: 1rem;
    }
  }

  a {
    text-decoration: underline;
    color: ${colors.white};
    font-size: 1.6rem;
    @media screen and (max-width: 468px){
      font-size: 1.4rem;
    }
  }

  .reducering-logo {
    align-items: flex-end;
    display: flex;
    height: 3.6rem;
    margin-bottom: 2.3rem;

    > a {
      height: inherit;
      display: flex;
      align-items: center;
    
      .reducering-logomark {
        height: inherit;
        margin-right: 1.5rem;
      }
    }

    .reducering-wordmark {
      width: 207px;
      height: 36px;
      margin-bottom: -${spacers.medium};
    }
  }

  .footer-content {
    a {
      color: ${colors.white};
      font-size: 1.6rem;
      text-decoration: none;
    }

    > *:nth-child(2) {
      > *:first-child {
        margin-top: 1.6rem;
      }
    }

    @media screen and (max-width: ${breakPoints.smallMax}) {
      margin-bottom: 3rem;
    }
  }
  .social{
    //max-width: 48rem;
    width: 100%;
  }
  .footer-content,
  .footer-links {
    @media screen and (min-width: 880px) {
      display: flex;
      justify-content: space-between;

      > * {
        //width: 50%;
        max-width: 48rem;

        + * {
          margin-left: 0;
        }
      }
    }
  }
  .footer-content{
    grid-gap: 1rem;
  }
  .footer-links {
    a {
      font-weight: ${font.weight.semiBold};
    }

    > *:nth-child(2) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .some {
        display: flex;
        flex-direction: row;
        margin-bottom: 3rem;
        align-items: center;
        a {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            fill: ${colors.white};
          }

          &.twitter {
            background-color: transparent;
          }

          &.facebook {
            background-color: ${colors.white};
            border-radius: 0.2rem;
            width: 2.1rem;
            height: 2.4rem;
            svg {
              fill: #36474F;
              width:2rem;
              position: relative;
              top: 2px;
            }
          }

          + * {
            margin-left: 2.4rem;
          }
        }
      }

      .svenskaSpel {
        width: 8.3rem;
        height: 8.3rem;
        background-color: ${colors.svenskaSpelRed};
      }
    }

    ul {
      li {
        margin-bottom: 1.3rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 4rem;
    @media screen and (max-width: 468px){
      margin-bottom: 3.3rem;
    }
    > * + * {
      margin-left: ${spacers.xlarge};
    }

    .footer-nav-games {
      a, div {
        color: ${colors.anchor};
        text-decoration: underline;
        font-weight: ${font.weight.semiBold};
      }
    }

    .footer-nav-title {
      font-size: ${font.size.medium};
      font-weight: ${font.weight.semiBold};
      color: ${colors.titleGrey};
    }
  }

  .gamePageContainer &,
  .livePageContainer & {
    margin-bottom: 4.8rem;

    @media screen and (max-width: ${breakPoints.xlargeMax}) {
      margin-bottom: 3rem;
    }

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      margin-bottom: 8.5rem;
    }

    @media screen and (max-width: ${breakPoints.smallMax}) {
      margin-bottom: 7rem;
    }
  }
  .mail {
    position:relative;
    bottom: -2.5rem;
    
    a {
      display: flex;
      align-items: center; 
      max-width:20rem ;
      img {
      margin-right: 1rem;
      }
    }
    @media screen and (max-width: 968px){
      position:static;
    } 
  }
`

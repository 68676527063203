import React, { useState, useEffect, useContext } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { LiveResultContext } from "../context/liveResultContext"
import { ActiveGamesContext } from "../context/activeGamesContext"

import { SocketContext, socket } from '../context/socket'
import { GameContext } from '../context/gameContext'
import LiveResultPage from './LiveResultPage'

export default function LiveLoader() {
  const {gameType, drawNumber, id} =  useParams()

  const [redirectHome, setRedirectHome] = useState(false)
  const [loadedState, setLoadedState] = useState(null)
  
  let activeGames = useContext(ActiveGamesContext)
  let liveGames = useContext(LiveResultContext)

  const state = {
    gameType: gameType,
    drawNumber: drawNumber,
    id: null,
    initialViewState: null
  }
  const [historicLoad, setHistoricLoad] = useState(null)

  useEffect( async () => {

    if (Object.keys(activeGames).length === 0 || Object.keys(liveGames).length === 0) {
      return;
    }

    if (!id) {
      setLoadedState(state)
      setHistoricLoad(false);
      return;
    }

    const isActive = activeGames[gameType].draws.some((el) => {
      return el.drawNumber === parseInt(drawNumber);
    })

    const isLive = liveGames[gameType].draws.some((el) => {
      return el.drawNumber === parseInt(drawNumber);
    })

    if (isActive || isLive) {
      setHistoricLoad(false);
    } else {
      setHistoricLoad(true);
    }

      fetch(`${process.env.REACT_APP_API_ENDPOINT}/coupon/${id}`, {
      })
      .then(response => {
        if (response.status === 404) {
          throw new Error('Not found')
        }
        if (response.status === 403) {
          throw new Error('Not Allowed')
        }
        return response.json()
      })
      .then(data => {

        if (!(data.data.gameType === gameType && data.data.drawNumber === drawNumber)) {
          setRedirectHome(true)
        } else {
          setLoadedState({
            gameType: data.data.gameType,
            drawNumber: data.data.drawNumber,
            id: data.id,
            initialViewState: data.data
          })
        }
      })
      .catch(error => {
        console.error(error)
        setRedirectHome(true)
      })

  }, [gameType, drawNumber, activeGames, liveGames])

  return loadedState?.gameType === gameType && loadedState?.drawNumber === drawNumber && historicLoad !== null ? 
    <SocketContext.Provider value={socket}>
      <GameContext.Provider value={loadedState}>
      {
        <LiveResultPage 
          key={`${loadedState.gameType}/${loadedState.drawNumber}`}
          loadedData={loadedState.initialViewState}
          id={loadedState.id}
          historicLoad={historicLoad}
        />
      }
      </GameContext.Provider>
    </SocketContext.Provider> : 
    redirectHome ? 
    <Redirect to="/" /> : null

}
import styled from 'styled-components'
import {
  breakPoints,
  colors,
  font,
  spacers,
  borderRadius,
  headerHeight
} from '../../styles/constants'

export const ContainerInput = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  
  .wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2.5rem;
    position: relative;
    .icon {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: ${colors.white};
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 1.2rem;
      top: 1.2rem;
      img {
        object-fit: contain;
        object-position: center;
      }
    }
    label {
      font-weight: ${font.weight.bold};
      font-size: 1.6rem;
      margin-bottom: 0.7rem;
    }
    input {
      width: 100%;
      padding: 1.4rem 1.2rem;
      background: #4E616A;
      border: 1px solid #6C808A;
      border-radius: 1rem;
      color: ${colors.white};
      font-size: 1.4rem;
      &::placeholder{
        color: #8F9FA8;
      }
      &:focus{
        outline: none;
        border-color: ${colors.white};
      }
    }
    
  }
  .icon {
    input{
    padding-left: 4rem;
    }
    
  }
  .area {
    textarea{
      min-height:9rem;
      width: 100%;
      padding: 1.2rem;
      background: #4E616A;
      border: 1px solid #6C808A;
      border-radius: 1rem;
      color: ${colors.white};
      font-size: 1.4rem;
      resize: none;
      &::placeholder{
        color: #8F9FA8;
      }
      &:focus{
        outline: none;
        border-color: ${colors.white};
      }
      ::-webkit-scrollbar {
            height: 1px;
            width: 4px;
         }
        ::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 2rem;
        }
        ::-webkit-scrollbar-thumb {
          background: ${colors?.anchor};
          border-radius: 1rem;
          
        }
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
    }
  }
 .errors {
  input{
    //border-color: #fe0000;
   border: none;
   box-shadow: 0 0 0 1px #fe0000;
  }
  textarea{
   border: none;
   box-shadow: 0 0 0 1px #fe0000;
  }
 }
.counter{
  margin-top: 0.4rem;
}
.error {
      position:absolute;
      left: 0;
      bottom: -1.5rem;
      color: #fe0000;
      font-size: 1.2rem;
    }
  .disable {
    opacity: 0.7;
   input{
    cursor: not-allowed;
   }
  }
`
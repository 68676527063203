import styled from 'styled-components'
import {
  colors,
  spacers,
  borderRadius,
  font,
  breakPoints
} from '../../../../styles/constants'
import { ToolsPanel } from '../../../../styles/globalStyle'
import { LabelMinMax, Input } from '../ResultWidgets.elements'

export const ColorReductionContainer = styled(ToolsPanel)`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : null )};
  user-select: ${({ isDisabled }) => (isDisabled ? 'none' : null )};

  * {
    pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : null )};
  }

  > div {
    flex-direction: row;
    padding: ${spacers.large};
  }
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const LabelColorReductionMinMax = styled(LabelMinMax)`
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: center;
  align-self: flex-end;

  > * {
    height: 2rem;
    line-height: 2rem;

    + * {
      margin-top: ${spacers.large};
    }
  }
`

export const ColorBox = styled.button`
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  border: none;
  border-radius: ${borderRadius.small};
  margin-bottom: ${spacers.xxlarge};
  transform: rotate(0deg);
  overflow: hidden;
  cursor: pointer;
`

export const ReduceRed = styled(ColorBox)`
  background-color: ${colors.reduceRed};
`

export const ReduceYellow = styled(ColorBox)`
  background-color: ${colors.reduceYellow};
`

export const ReduceBlue = styled(ColorBox)`
  background-color: ${colors.reduceBlue};
`

export const ReduceGreen = styled(ColorBox)`
  background-color: ${colors.reduceGreen};
`

export const InputColorReduction = styled(Input)`
  width: 5.5rem;
  max-width: 5rem;
  padding-left: 0;
  margin-bottom: ${spacers.large};

  &:last-child {
    margin-bottom: 0;
  }

  + input {
    margin-left: 0;
  }
`

export const Label = styled.label`
  font-size: ${font.size.xlarge};
  font-weight: ${font.weight.semiBold};
  display: flex;
  flex-basis: 100%;
  max-width: 12rem;

  margin-left: auto;
  margin-right: auto;

  justify-content: space-evenly;
  align-items: flex-end;
  color: ${colors.lightGrey};
  text-align: center;
  line-height: 0.8;
  margin-bottom: ${spacers.small};

  @media screen and (min-width: ${breakPoints.xlarge}) {
    font-size: 2.8rem;
  }

  + .break {
    flex-basis: 100%;
    height: 0;
  }

  &:before,
  &:after {
    font-size: ${font.size.small};
    font-weight: ${font.weight.light};
    line-height: 1;
  }

  &:before {
    content: 'Min';
  }

  &:after {
    content: 'Max';
  }
`

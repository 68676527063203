import React, { useEffect, useState, useContext } from 'react';

import {
  GameListArea,
  GameWidgetArea,
  SelectedCouponArea,
} from './LiveResult.elements';

import GameTypeHeader from '../GameTypeHeader/GameTypeHeader';
import ResultsList from './ResultsList/ResultsList';

import ResultWidgets from './ResultWidgets/ResultWidgets';
import ShowRowsPopup from '../Popup/ShowRowsPopup';
import ShowRows from './ShowRows/ShowRows';
import UserRows from './UserRows/UserRows';

import GameReducer from '../../utils/math';
import { GameContext } from '../../context/gameContext';
import { startAffiliate, removeAffiliate } from '../../utils/helperFunctions';
import {ContestContext} from "../../context/contestContext";

// helpers
const calculateUserRows = (evData, userRows, notStartedX, gameType) => {
  const { forecast, events } = evData;
  let out = Array.from({ length: events.length + 1 })
    .fill(0)
    .map((ev) => {
      return {
        winners: 0,
        dividend: 0,
      };
    });

  userRows.forEach((row) => {
    let numOfCorrects = row.reduce((acc, sign, i) => {
      return (
        acc +
        (sign === 'X' &&
        events[i].sportEventStatus === 'Inte startat' &&
        !forecast?.events[i].cancelled
          ? notStartedX
          : sign === (forecast?.events[i].outcome || ''))
      );
    }, 0);
    out[numOfCorrects].winners++;
  });

  out.forEach((game, i) => {
    let numcorr = forecast?.distribution.filter(
      (val) => val.name == `${i} rätt`,
    );

    let key = gameType === 'powerplay' ? 'odds' : 'amount';
    //console.log("nummcorr", numcorr)
    if (numcorr?.length) {
      game.dividend = numcorr[0].sign
        ? parseInt(numcorr[1][key])
        : parseInt(numcorr[0][key]);
    } else {
      game.dividend = 0;
    }

    if (!forecast) {
      game.dividend = '-';
    }
  });

  return out;
};

export default function LiveResult({ loadedData, data, id, infoLive }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isSM } = useContext(ContestContext);

  const [loadedCouponData, setLoadedData] = useState(loadedData || null);
  const [loadedId, setLoadedId] = useState(id || null);

  const [userRows, setUserRows] = useState([]);
  const [reducedRowCount, setReducedRowCount] = useState(0);
  const [unreducedRowCount, setUnreducedRowCount] = useState(0);
  const { gameType, drawNumber } = useContext(GameContext);
  const [dividendForecast, setDividendForecast] = useState([]);

  const [toggleUnplayed, setToggleUnplayed] = useState(true);

  useEffect(() => {
    if (loadedCouponData) {
      const pr = loadedCouponData.pointReduction;
      const sr = loadedCouponData.signReduction;
      const cr = loadedCouponData.colorReduction;

      const oddsArr = data.events.map((e) => {
        return {
          // Odds might not exist at all!
          home: parseFloat(e.odds?.home.replace(',', '.')) || 2.0,
          draw: parseFloat(e.odds?.draw.replace(',', '.')) || 2.0,
          away: parseFloat(e.odds?.away.replace(',', '.')) || 2.0,
        };
      });

      const distArray = data.events.map((e) => {
        return {
          // Odds might not exist at all!
          home: parseFloat(e.distribution?.home.replace(',', '.')) || 0,
          draw: parseFloat(e.distribution?.draw.replace(',', '.')) || 0,
          away: parseFloat(e.distribution?.away.replace(',', '.')) || 0,
        };
      });

      let reducerObj = GameReducer(
        loadedCouponData.viewState,
        oddsArr,
        distArray,
      );

      reducerObj.pointReduce(pr.min, pr.max);
      reducerObj.signReduce('1', sr.home.min, sr.home.max);
      reducerObj.signReduce('X', sr.draw.min, sr.draw.max);
      reducerObj.signReduce('2', sr.away.min, sr.away.max);

      let usedColors = [];
      if (cr.red.total) {
        usedColors.push('red');
      }
      if (cr.blue.total) {
        usedColors.push('blue');
      }
      if (cr.yellow.total) {
        usedColors.push('yellow');
      }
      if (cr.green.total) {
        usedColors.push('green');
      }

      for (let color of usedColors) {
        reducerObj.colorReduce(color, cr[color].min, cr[color].max);
      }

      setUserRows(reducerObj.rows);

      setReducedRowCount(reducerObj.rowCount);
      setUnreducedRowCount(reducerObj.unreducedRowsCount);
      setDividendForecast(
        calculateUserRows(data, reducerObj.rows, true, gameType),
      );
    }
  }, []);

  useEffect(() => {
    userRows.length && updateForecast(toggleUnplayed);
  }, [data]);

  const updateForecast = (notStartedX) => {
    const dividendForecast = calculateUserRows(
      data,
      userRows,
      notStartedX,
      gameType,
    );
    setDividendForecast(dividendForecast);
  };

  let extraMoney =
    data.fund &&
    parseFloat(data.fund.extraMoney) > 0 &&
    data.fund.extraMoney.replace(/\B(?=(\d{3})+(?!\d))/g, ' ').slice(0, -3) +
      ' kr';

  useEffect(() => {
    startAffiliate();
    return () => removeAffiliate();
  }, []);

  return (
    <>
      <GameTypeHeader
        gameType={gameType}
        gameTurnover={data.turnover}
        gameCloseTime={data.closeTime}
        jackpot={data.jackpotItems?.[0]?.amount}
        extraMoney={extraMoney}
        drawNumber={drawNumber}
        loadedId={loadedId}
        isTurnament={isSM ? loadedData?.isLocked : false}
      />
      <ShowRowsPopup
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        eventData={data}
        dividendForecast={dividendForecast}
        userRows={userRows}
        updateForecast={updateForecast}
        toggleUnplayed={toggleUnplayed}
        setToggleUnplayed={setToggleUnplayed}
        betRowAmount={loadedCouponData?.betRowAmount || 1}
      />
      <SelectedCouponArea>
        <GameListArea>
          <ResultsList
            infoLive={infoLive}
            eventData={data}
            loadedCouponData={loadedCouponData?.viewState}
          />
        </GameListArea>
        <GameWidgetArea>
          <ResultWidgets
            // interesting
            eventData={data}
            loadedId={loadedId}
            dividendForecast={dividendForecast}
            loadedCouponData={loadedCouponData}
            userRows={userRows}
            betRowAmount={loadedCouponData?.betRowAmount || 1}
          />
          <UserRows
            betRowAmount={loadedCouponData?.betRowAmount || 1}
            key={`UserRows-LR`}
            eventData={data}
            userRows={userRows}
            dividendForecast={dividendForecast}
            updateForecast={updateForecast}
            toggleUnplayed={toggleUnplayed}
            setToggleUnplayed={setToggleUnplayed}
          />
        </GameWidgetArea>
      </SelectedCouponArea>
      <ShowRows
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        reducedRowCount={reducedRowCount}
        unreducedRowCount={unreducedRowCount}
        loadedId={loadedId}
        betRowAmount={loadedCouponData?.betRowAmount}
      />
    </>
  );
}

import React from 'react'
import {BlogMainContainer,IconBlog} from './Blog.elements'
import { Link } from 'react-router-dom'
import moment from 'moment'
import svsPOwerplay from '../../assets/Powerplay-blog.png';
import svsStryk from '../../assets/Stryktipset-blog.png';
import svsTop from '../../assets/Topptipset-blog.png';
import svsEuro from '../../assets/Europtipset.png'
import {useHistory} from 'react-router-dom'

const BlogMain = (props) => {
const game = [{
  game:'powerplay',
  img:svsPOwerplay,
  color:'#a00814'
  },
  {
    game:'topptipset',
    img:svsTop,
    color:'#f29400'
  },
  {
    game:'stryktipset',
    img:svsStryk,
    color:'#043266'
  },
  {
    game: 'europatipset',
    img:svsEuro,
    color:'#008733'
  }]
const whatGame = (gameType) => {
    const picture = game.find(game => game.game === gameType)
  return picture
}
const history = useHistory()
  return (
    <BlogMainContainer>
      <h2>ANALYSER</h2>
      <div className='content'>
        {
          props?.Blog?.length > 0 && (
            props.Blog.map(blog =>(
              <div className='blog'>
                <div className='blog-main'>
                  <img style={{backgroundColor:whatGame(blog.gameType).color}}
                       src={whatGame(blog.gameType).img}/>
                  <div className='info'>
                    <h3>{blog.title}</h3>
                    <Link className='link' to={{
                      pathname: `blog/${blog.gameType}/${blog._id}`,
                    }}>LÄS MER
                    <span className='icon'></span>
                    </Link>
                  </div>
                </div>
                <div className='date'>
                  {moment(blog.date).format('DD.MM.YYYY')}
                </div>
              </div>
            ))
          )
        }
      </div>
      {props?.Blog?.length > 0 ? <div className="get-more-wrapper">
        <button /*disabled={!props.isCanLoadBlog} onClick={ props.uploadMoreBlog}*/
          onClick={()=>history.push('/blogs')}
          className={!props.isCanLoadBlog ? "get-more get-more--color" : "get-more"}>
          VISA ALLA INLÄGG
        </button>
      </div>
        :
      <p className='without'>Inga tillgängliga blogginlägg</p>}
    </BlogMainContainer>
  )
}

export default BlogMain
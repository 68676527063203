import React, { useContext , useRef, useEffect} from 'react'
import svenskaSpelLogo from '../../../../assets/svenskaspel-logomark.svg'
import {GameContext} from '../../../../context/gameContext'
import { useAuth0 } from '@auth0/auth0-react'
import moment from 'moment'

export default function HandInCouponBtn({
    handinRows,
    isReduced,
    setIsModalOpen,
    setErrorPopup,
    setErrorPopupText,
    totalRader,
    reducedRowsCount,
    actionType,
    SpTsms,
    disabledBtn,
    isMine,
    id,
  }) {
  const { gameType, drawNumber } = useContext(GameContext)
  const {isAuthenticated} = useAuth0();
  const button = useRef(null)
  useEffect(()=>{
    if(actionType && button.current){
      setTimeout(()=>{
        button.current?.click()
      },1600)
    }
  },[])
  return (
    <button
      ref={button}
      onClick={() => {

        if (!handinRows.rows?.length && !SpTsms) {
          setErrorPopup(true)
          setErrorPopupText(
            <h3>Din kupong är tom!</h3>
          )
          return;
        }
        if (!handinRows.rows?.length
          && SpTsms
          && ((gameType === 'europatipset') || (gameType ==='stryktipset')))
        {
          setErrorPopup(true)
          setErrorPopupText(
            <h3>
              Ditt "Spela Tillsammans"-system kostar antingen mer än 20 000 kr
              eller uppfyller EJ Svenska Spels tekniska krav för reducerade
              andelsspel, och går därför inte lämna in. Ändra om ditt system och försök igen!
            </h3>
          )
          return;
        }
        if (!handinRows.rows?.length
          && SpTsms
          && ((gameType === 'topptipset') || (gameType ==='powerplay')))
        {
          setErrorPopup(true)
          setErrorPopupText(
            <h3>
              Ditt "Spela Tillsammans"-system uppfyller EJ Svenska Spels tekniska
              krav för reducerade andelsspel och går därför inte lämna in.
              Ändra om ditt system och försök igen!
            </h3>
          )
          return;
        }

        if (reducedRowsCount > 20000) {
          setErrorPopup(true)
          setErrorPopupText(
            <h3>Kupongen får inte innehålla mer är 20 000 rader</h3>
          )
          return;
        }

        if (!isReduced) {
          setErrorPopup(true)
          setErrorPopupText(
            <h3>Tryck på REDUCERA för att generera dina rader.</h3>
          )
          return;
        }
        if(disabledBtn){
          setErrorPopup(true)
          setErrorPopupText(
            <h3>
              Denna kupong är låst eftersom du deltar i Reducering-EM.
              Bocka ur tävlingen - eller radera kupongen från din profil -
              om du skapa en ny kupong för SM.
            </h3>
          )
          return;
        }
       /* if(!isMine && id && isAuthenticated){
          setErrorPopup(true)
          setErrorPopupText(
            <h3>
              Du kan ej delta med någon annans kupong. Klicka här för att delta med samma kupong!
            </h3>
          )
          return;
        }*/
       /* if(moment(new Date) > moment(closeTime)){
          setErrorPopup(true)
          setErrorPopupText(
            <h3>
              Tiden för att byta kupong är förbi!
            </h3>
          )
          return;
        }*/
        setIsModalOpen(true)
      }}
    >
    <img src={svenskaSpelLogo} />
      Lämna in
    </button>
  )
}

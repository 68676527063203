import React from 'react'
import { CustomToggleComponents } from './CustomToggle.elements'
import { ToolsHeading } from '../../styles/globalStyle'
import Tooltip from 'rc-tooltip'

const CustomToggle = ({
   checked,
   onChange,
   label
}) => {

  return (
    <CustomToggleComponents>
      <div className="container">
        <span className='toogle-label'>{label}</span>
        <div className="toggle-switch">
          <input type="checkbox" className="checkbox"
                 checked={checked}
                 onChange={onChange}
                 name={label} id={label} />
          <label className="label" htmlFor={label}>
            <span className="inner" />
            <span className="switch" />
          </label>
        </div>
        <div className='info'>
          <Tooltip
            prefixCls='rToolTip'
            placement='bottom'
            trigger={['hover']}
            animation='anim'
            overlay={
              <>
                <h3>Offentligt konto</h3>
                <p>
                  Om detta är "På" så kommer alla dina kuponger och statistik att vara synligt för andra användare.
                  Om du väljer "Av" kommer allt att förbli dolt för andra användare
                </p>
              </>
            }
          >
            <ToolsHeading>
              <span className='rToolTip-icon'>i</span>
            </ToolsHeading>
          </Tooltip>
        </div>
      </div>
    </CustomToggleComponents>
  );
}

export default CustomToggle
import React, { useState, useEffect } from "react";
import { TableContent } from "../../styles/globalStyle";
import DeleteItem from "../../assets/delete.png";
import Redirect from "../../assets/link-to.png";
import Pen from "../../assets/pen.png";
import ReactPaginate from "react-paginate";
import Close from "../../assets/WhiteClose.svg";
import Send from "../../assets/send.svg";
import Loader from "../Loader/Loader";
import { Link } from "react-router-dom";

const Table = ({
  header,
  row,
  title,
  size,
  deleteItem,
  isEdit,
  handleCreate,
  typeIcon,
  pageCount,
  setPageCount,
  AllPage,
  typeAccout,
  handleUpdate,
  isLoading,
  modFor,
}) => {
  //State for pagination
  const [page, setPage] = useState(0);

  // Icon
  const Icon = [
    { typeIcon: "redirect", img: Redirect },
    { typeIcon: "pen", img: Pen },
  ];
  const getIcon = () => {
    let find = Icon.find((icon) => icon.typeIcon === typeIcon);
    return find.img;
  };
  // Pagination
  const handlePageClick = (event) => {
    setPageCount(event.selected + 1);
    setPage(event.selected);
  };
  const [inputValue, setInputValue] = useState(null);
  // Create visible for change
  const [item, setItem] = useState(null);
  useEffect(() => {
    setItem(
      row.map((items, id) => ({
        ...items,
        visible: false,
        id: id,
        edited: false,
      }))
    );
  }, [row]);
  // state for changes
  const [changesItem, setChangesItem] = useState({});
  const handleEdit = (e, id) => {
    setInputValue(null);
    setChangesItem({});
    let update = item.map((items) => {
      if (items.id === id) {
        items.edited = true;
        items.visible = true;
        return items;
      } else {
        items.visible = false;
        items.edited = false;
        return items;
      }
    });
    setItem(update);
  };
  const handleChange = (e, id) => {
    // Need to add _id
    let currentId = id.infoItem._id;
    let updateObj = {};
    let name = e.target.name;
    let value = e.target.value;

    updateObj.id = currentId;
    updateObj.note = value;
    setChangesItem(updateObj);
    setInputValue(value);
  };

  //Update
  const resetValue = () => {
    let update = item.map((items) => {
      items.visible = false;
      items.edited = false;
      return items;
    });
    setInputValue(null);
    setChangesItem({});
    setItem(update);
  };
  return (
    <TableContent>
      {title?.text && (
        <h5
          style={{ cursor: "pointer" }}
          onClick={title?.add ? (e) => handleCreate(e, typeAccout) : null}
        >
          {title?.text}
          {title?.add && <span>+</span>}
        </h5>
      )}
      <div
        className={`header ${size ? "header--size" : ""} ${
          modFor ? "header--four" : ""
        }`}
      >
        <p>{header.first}</p>
        {modFor ? <p>{header.name}</p> : null}
        <p>{header.second}</p>
        <p>{header.last}</p>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {item?.map((item, index) => (
            <div
              key={index}
              className={`item ${size ? "item--size" : ""} ${
                modFor ? "item--four" : ""
              }`}
            >
              <p>{item.first}</p>
              {modFor ? <p>{item.name}</p> : null}
              <p className="date">
                {item.second}
                {typeAccout === "team"
                  ? ` / ${item.infoItem?.userInfo[0]?.username}`
                  : ""}
              </p>
              <div className="controls">
                <p className="notera">
                  {item.visible ? (
                    <input
                      name="last"
                      maxLength={255}
                      onChange={(e) => {
                        handleChange(e, item);
                      }}
                      defaultValue={item.last.info}
                      value={inputValue}
                    ></input>
                  ) : (
                    item.last.info
                  )}
                  {item.last?.img && (
                    <img className="category-img" src={item.last?.img} />
                  )}
                </p>
                <p className="controls-group">
                  {typeAccout === "blog" && (
                    <button style={{ marginRight: 5 }}>
                      {
                        <Link
                          to={{
                            pathname: "/create-blog",
                            state: { id: item.infoItem._id },
                          }}
                        >
                          <img
                            style={{ width: 16, height: 16 }}
                            src={Icon[1].img}
                          />{" "}
                        </Link>
                      }
                    </button>
                  )}
                  {typeAccout === "news" && (
                    <button style={{ marginRight: 5 }}>
                      {
                        <Link
                          to={{
                            pathname: "/create-news",
                            state: { id: item.infoItem._id },
                          }}
                        >
                          <img
                            style={{ width: 16, height: 16 }}
                            src={Icon[1].img}
                          />{" "}
                        </Link>
                      }
                    </button>
                  )}
                  {item.edited ? (
                    <button onClick={() => handleUpdate(changesItem)}>
                      <img style={{ width: 16, height: 16 }} src={Send} />
                    </button>
                  ) : (
                    <button
                      onClick={isEdit ? (e) => handleEdit(e, item.id) : null}
                    >
                      {typeIcon === "redirect" ? (
                        <a target="_blank" href={item.infoItem.link}>
                          <img
                            style={{ width: 16, height: 16 }}
                            src={getIcon()}
                          />{" "}
                        </a>
                      ) : (
                        <img
                          style={{ width: 16, height: 16 }}
                          src={getIcon()}
                        />
                      )}
                    </button>
                  )}
                  {item.edited ? (
                    <button onClick={resetValue}>
                      <img style={{ width: 18, height: 18 }} src={Close} />
                    </button>
                  ) : (
                    <button
                      onClick={(e) =>
                        deleteItem(e, item.infoItem._id, typeAccout)
                      }
                    >
                      <img style={{ width: 14, height: 16 }} src={DeleteItem} />
                    </button>
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
      {isLoading ? null : (
        <div className="pagination">
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={handlePageClick}
            marginPagesDisplayed={2}
            activeClassName="active"
            pageRangeDisplayed={5}
            pageCount={AllPage}
            previousLabel="< "
            forcePage={page}
            disableInitialCallback={true}
          />
          {/*  <div>
          <button className='arrows'>{"<"}</button>
          <button className='active'>1 </button>
          <button>2 </button>
          <button>3 </button>
          <button className='arrows'>{">"}</button>
        </div>*/}
        </div>
      )}
    </TableContent>
  );
};

export default Table;

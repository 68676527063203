import React from 'react'
import { Link } from 'react-router-dom'
import { CircleBtn } from '../../styles/globalStyle'
import { HowItWorksPanel } from './HowItWorksPanel.elements'
import { UserLogButton } from '../Headers/Headers.elements'
import {useHistory} from 'react-router-dom'

export default function Footer() {
  const history = useHistory();
  return (
    <>
      <HowItWorksPanel>
        <h3>Hur funkar det?</h3>
        <div className='content'>
          <p>
            Kom igång med <em>Reducering.se</em> och spela smartare! Är du nyfiken, men
            känner dig osäker på hur det fungerar? Lugn, här följer en grundlig
            steg-för-steg-guide. Klicka på "Läs mer" och ta del av råden, från steg 1 hela
            vägen till nr. 12, för att få en så bra start som möjligt. Lycka till!
          </p>

          <p>
            <em>Reducering.se</em> är framtaget av ett team erfarna poolspelare, som mer än
            gärna delar med sig av sitt kunnande. Håll utkik efter en instruktionsvideo, som
            kommer att spelas in och laddas upp på den här sektionen.
          </p>
          <UserLogButton onClick={()=>{
            history.push('/hur-funkar-det')
          }} width={true}>LÄS MER</UserLogButton>
        </div>
       {/* <Link to='/hur-funkar-det' className='readMore'>
          <span>Läs mer</span>
        </Link>*/}
      </HowItWorksPanel>
    </>
  )
}

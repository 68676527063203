import React from 'react'
import { BombenDividentContainer } from './style'

const BombenDividend = ({
    type = '',
    info,
    win
 }) => {
  return (
    <BombenDividentContainer>
      {type === 'win'
        ?
        (<div>
          <p className='winn'>
            <span>{win?.max} rätt</span>
            <span>{win?.st} kr</span>
          </p>
          {info?.length > 0
            ?
            info?.map((item,index)=>{
              return (
                <div className='total-win' key={`total${index}`}>
                  <p className='total-win__flex'>
                    <span>Alla rätt</span>
                    <span>
                      <span style={{fontWeight:'500',color:'#fff'}}>{item?.st} st</span>
                    </span>
                  </p>
                  <p>{parseInt(item?.kr)} kr</p>
                </div>
              )
            })
            :
            null
          }
        </div>)
        :
        (<div>
          {info?.length > 0
            ?
              info?.map((item,index)=>{
                return (
                  <div className='total-win' key={`total${index}`}>
                    <p className='total-win__flex'>
                      <span>Alla rätt</span>
                      <span>{item?.st} st</span>
                    </p>
                    <p>{parseInt(item?.kr)} kr</p>
                  </div>
                )
              })
            :
              <p style={{fontSize:'1.1rem',textAlign:'center'}}>Kupong ej startad</p>
          }
        </div>)
      }
    </BombenDividentContainer>
  )
}

export default BombenDividend
import React, { useContext, useState, useEffect } from "react";
import windowDimensions from "../../utils/hooks/useWindowDimensions.js";
import { ActiveGamesContext } from "../../context/activeGamesContext";
import { useParams, useLocation, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { colors } from "../../styles/constants";
import Video from "../../components/Video/Video";
import {
  InnerContainer,
  HeroContainer,
  PageContent,
  ArticlePanel,
} from "../../styles/globalStyle";
import { GameAboutSidebar, GameAboutContent } from "./GamePageAbout.elements";
import GameHeaderAbout from "../../components/AboutGameHeader/AboutGameHeader";
import ActiveGamesBoxes from "../../components/ActiveGamesBoxes/ActiveGamesBoxes";
import Footer from "../../components/Footer/Footer";
import HowItWorksPanel from "../../components/HowItWorksPanel/HowItWorksPanel";
import stryktipsetLogo from "../../assets/stryktipset-wordmark.svg";
import europatipsetLogo from "../../assets/europatipset-wordmark.svg";
import topptipsetLogo from "../../assets/topptipset-wordmark.svg";
import powerplayLogo from "../../assets/powerplay-wordmark.svg";

import VideoHeroMp4 from "../../assets/video/Hero-Fotboll.mp4";
import VideoHeroPPMp4 from "../../assets/video/Hero-PP.mp4";
import VideoHeroWebm from "../../assets/video/Hero-Fotboll.webm";
import VideoHeroPPWebm from "../../assets/video/Hero-PP.webm";
import VideoHeroImage from "../../assets/video/Hero-fallback-fotboll.jpg";
import VideoHeroPPImage from "../../assets/video/Hero-fallback-PP.jpg";

import StryktipsetContent from "./GamePageAboutContent/StryktipsetContent";
import StryktipsetNavigation from "./GamePageAboutContent/StryktipsetNavigation";
import EuropatipsetContent from "./GamePageAboutContent/EuropatipsetContent";
import EuropatipsetNavigation from "./GamePageAboutContent/EuropatipsetNavigation";
import TopptipsetContent from "./GamePageAboutContent/TopptipsetContent";
import TopptipsetNavigation from "./GamePageAboutContent/TopptipsetNavigation";
import PowerplayContent from "./GamePageAboutContent/PowerplayContent";
import PowerplayNavigation from "./GamePageAboutContent/PowerplayNavigation";

import { GamePageAboutNavContainer } from "./GamePageAboutContent/GamePageAboutContent.elements";

export default function GamePageAbout() {
  const { winHeight, winWidth } = windowDimensions();
  const { gameType } = useParams();
  const url = useLocation();
  const [aboutGameHeaderState, setAboutGameHeaderState] = useState(0);
  const [videoID, setVideoID] = useState(null);

  const activeGames = useContext(ActiveGamesContext);

  const gameTypeContent = {
    stryktipset: {
      logo: stryktipsetLogo,
      color: colors.stryktipset,
      content: <StryktipsetContent />,
      navigation: <StryktipsetNavigation />,
    },
    europatipset: {
      logo: europatipsetLogo,
      color: colors.europatipset,
      content: <EuropatipsetContent />,
      navigation: <EuropatipsetNavigation />,
    },
    topptipset: {
      logo: topptipsetLogo,
      color: colors.topptipset,
      content: <TopptipsetContent />,
      navigation: <TopptipsetNavigation />,
    },
    powerplay: {
      logo: powerplayLogo,
      color: colors.powerplay,
      content: <PowerplayContent />,
      navigation: <PowerplayNavigation />,
    },
  };

  useEffect(() => {
    const aboutGameHeader =
      document.getElementById("aboutGameHeader")?.clientHeight;
    setAboutGameHeaderState(aboutGameHeader + 3);
  }, [winHeight, winWidth]);

  useEffect(() => {
    if (gameType === "powerplay") {
      setVideoID(2);
    } else {
      setVideoID(1);
    }
  }, [gameType]);

  return !["stryktipset", "europatipset", "topptipset", "powerplay"].includes(
    gameType,
  ) ? (
    <Redirect to="/" />
  ) : (
    <>
      <Helmet>
        <meta
          property="og:url"
          content={`https://www.reducering.se${url.pathname}`}
        />
      </Helmet>
      <GameHeaderAbout
        gameType={gameType}
        gameTurnover={activeGames?.[gameType]?.draws[0]?.turnover}
        gameCloseTime={activeGames?.[gameType]?.draws[0]?.closeTime}
        jackpot={activeGames?.[gameType]?.draws[0]?.jackpotItems?.[0]?.amount}
        activeGames={activeGames?.[gameType]?.draws}
      />

      <InnerContainer>
        <div id="aboutGameContent"></div>
        <HeroContainer>
          <Video
            muted
            autoPlay // Autoplay in Chrome requires video to be muted.
            loop
            playsInline
            mp4={gameType === "powerplay" ? VideoHeroPPMp4 : VideoHeroMp4}
            webm={gameType === "powerplay" ? VideoHeroPPWebm : VideoHeroWebm}
            img={gameType === "powerplay" ? VideoHeroPPImage : VideoHeroImage}
            id={videoID}
            alt={`Sorry, your browser doesn't support embedded videos.`}
          />
        </HeroContainer>
      </InnerContainer>

      <PageContent>
        <GameAboutContent>
          <GamePageAboutNavContainer
            className="hide-for-small"
            style={{ top: aboutGameHeaderState }}
          >
            {gameTypeContent[gameType]?.navigation || null}
          </GamePageAboutNavContainer>

          {gameTypeContent[gameType]?.content || (
            <ArticlePanel>
              <h3>Lorem ipsum</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ac
                neque augue. Nullam at arcu eget erat euismod pulvinar. Maecenas
                euismod enim eu nunc blandit, in tristique augue pellentesque.
              </p>
            </ArticlePanel>
          )}

          <HowItWorksPanel />
        </GameAboutContent>

        <GameAboutSidebar>
          <GamePageAboutNavContainer className="show-for-small">
            {gameTypeContent[gameType]?.navigation || null}
          </GamePageAboutNavContainer>
        </GameAboutSidebar>
      </PageContent>

      <InnerContainer>
        <ActiveGamesBoxes />
      </InnerContainer>

      <InnerContainer>
        <Footer />
      </InnerContainer>
    </>
  );
}

import React, { useContext, useState } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import {
  importGameTypeLogos,
  gameTurnoverWithSpace,
  getActiveDates,
} from "../../utils/helperFunctions";
import ErrorPopup from "../Popup/ErrorPopup";
import Navbar from "../Navbar/Navbar";
import AlarmClock from "../AlarmClock/AlarmClock";
import Logo from "../Headers/Logo";
import Rull from "../../assets/rullpott-reducering-wordmark.png";
import {
  GameTypeHeader,
  GameTypeLogo,
  GameTypeHeaderContent,
  GameTypeInfo,
  GameTypeInfoWrapper,
  ProfileGame,
  UserLogButtonGame,
  GameTypeTurnament,
} from "./GameTypeHeader.elements";
import { colors } from "../../styles/constants";
import {
  LogoContainer,
  GameTypeJackpot,
  Profile,
  UserLogButton,
} from "../Headers/Headers.elements";
import SvenskaSpelLogo from "../../assets/svenskaspel-logomark-hat.svg";

import { useAuth0 } from "@auth0/auth0-react";
import { AccountContext } from "../../context/accountContext";
import userLogo from "../../assets/simple-profile.svg";
import { PhotoContext } from "../../context/photoContext";
import { ReactComponent as Cup } from "../../assets/cup.svg";

export default function GameHeader({
  gameType,
  gameTurnover,
  gameCloseTime,
  jackpot,
  extraMoney,
  currentId,
  errorPopup,
  setErrorPopup,
  errorPopupText,
  setErrorPopupText,
  isReduced,
  drawNumber,
  loadedId,
  rolloverIn,
  size,
  gridTemplate,
  isTurnament,
}) {
  const { pathname } = useLocation();
  let isReducera = pathname.includes("/reducera/");
  let isLiveResult = pathname.includes("/liveresults/");
  const { day, time } = getActiveDates(gameCloseTime);
  const account = useContext(AccountContext);
  // Import all images in folder
  const images = importGameTypeLogos(
    require.context("../../assets/", false, /.*\.svg$/)
  );
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { isAuthenticated, user, logout, loginWithRedirect } = useAuth0();
  const userPhoto = useContext(PhotoContext);

  return (
    <GameTypeHeader
      deleteMargin={size}
      grid={
        size
          ? "minmax(70px,1fr) minmax(auto,calc(1366px - 17.3rem * 2)) minmax(70px,1fr)"
          : null
      }
      gridTemplate={gridTemplate}
    >
      <LogoContainer className="headerItem">
        <Logo />
      </LogoContainer>

      <GameTypeHeaderContent
        className={"headerItem" + (isLiveResult ? " live" : "")}
        gameTypeColor={colors[gameType]}
        jackpot={!!jackpot}
      >
        <GameTypeLogo classList="">
          {/* <img
            className='svenskaspel-logomark'
            src={images['svenskaspel-logomark.svg'].default}
            alt='Svenska Spel'
          /> */}
          <img
            className="svenskaspel-logomark"
            src={SvenskaSpelLogo}
            alt="Reducering Wordmark"
          />
          <img
            className={"gametype-wordmark " + gameType}
            src={images[gameType + "-wordmark.svg"].default}
            alt={gameType.charAt(0).toUpperCase() + gameType.slice(1)}
          />
          {gameType === "stryktipset" && isTurnament && (
            <GameTypeTurnament isHideBig={true}>
              <Cup />
            </GameTypeTurnament>
          )}
        </GameTypeLogo>

        <p className="gameViewToggleContainer">
          <ErrorPopup
            open={errorPopup}
            setErrorPopup={setErrorPopup}
            errorPopupText={errorPopupText}
          />
          {/*!isLiveResult ? (
            <Link
              className='gameViewToggle'
              to={`/liveresults/${gameType}/${drawNumber}/${currentId || ''}`}
              onClick={e => {
                //if (!isReduced) {
                //  e.preventDefault()
                //  setErrorPopup(true)
                //  setErrorPopupText(<h3>Tryck på REDUCERA innan du går vidare till liverättningen.</h3>)
                //  return
                //}

                if (!currentId) {
                  e.preventDefault()
                  setErrorPopup(true)
                  // Not the best practice to set state to jsx
                  setErrorPopupText(
                    <>
                      <h3>Glöm inte att spara din kupong!</h3>
                      <Link to={`/liveresults/${gameType}/${drawNumber}/`}>
                        Fortsätt ändå
                      </Link>
                    </>
                  )
                }
              }}
            >
              Gå till liverättning
            </Link>
          ) : (
            <Link
              className='gameViewToggle'
              to={`/reducera/${gameType}/${drawNumber}/${loadedId || ''}`}
            >
              Gå till reducering
            </Link>
          )*/}
        </p>

        {/* <GameTypeJackpot className={extraMoney && 'extra-pengar'}>
          {jackpot && (
            <img src={images['jackpot-reducering-wordmark.svg'].default} alt='Jackpot' />
          )}
          {jackpot && (
            <p style={{whiteSpace: 'nowrap'}}>
              <strong>{jackpot}</strong>
            </p>
          )}

          {extraMoney && (
            <img
              src={images['extrapengar-reducering-wordmark.svg'].default}
              alt='Extra pengar'
              width='73'
              height='35'
            />
          )}
          {extraMoney && (
            <p style={{whiteSpace: 'nowrap'}}>
              <strong>{extraMoney}</strong>
            </p>
          )}
        </GameTypeJackpot> */}

        {/* // To account for style */}
        <div></div>

        <GameTypeInfoWrapper isAuthenticated={isAuthenticated}>
          <GameTypeJackpot
            className={extraMoney && "extra-pengar"}
            style={{
              flexWrap: "nowrap",
              justifyContent: "center",
              width: "75%",
            }}
          >
            {jackpot && (
              <img
                src={images["jackpot-reducering-wordmark.svg"].default}
                alt="Jackpot"
              />
            )}
            {jackpot && (
              <p style={{ whiteSpace: "nowrap" }}>
                <strong>{jackpot}</strong>
              </p>
            )}

            {extraMoney && (
              <img
                src={images["extrapengar-reducering-wordmark.svg"].default}
                alt="Extra pengar"
                width="73"
                height="35"
              />
            )}
            {extraMoney && (
              <p style={{ whiteSpace: "nowrap" }}>
                <strong>{extraMoney}</strong>
              </p>
            )}
            {rolloverIn && (
              <img
                src={Rull}
                alt="Extra pengar"
                width="70"
                className="small"
                height="20"
              />
            )}
            {rolloverIn && (
              <p style={{ whiteSpace: "nowrap" }}>
                <strong>
                  {rolloverIn?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                </strong>
              </p>
            )}
          </GameTypeJackpot>

          {gameType === "europatipset" && isTurnament && (
            <GameTypeTurnament isShowBig={true}>
              <Cup />
              <div className="hide-medium">
                <h5>Denna kupong deltar i Reducering-EM!</h5>
              </div>
            </GameTypeTurnament>
          )}
          <GameTypeInfo>
            <p>
              Omsättning{" "}
              <strong>{gameTurnoverWithSpace(gameTurnover)} kr</strong>
            </p>
            <p>
              Spelstopp <strong>{`${day || ""} ${time || ""}`}</strong>
            </p>
          </GameTypeInfo>
        </GameTypeInfoWrapper>
        {account.email || account.username ? (
          <ProfileGame>
            <div className="author-name">{account?.username}</div>
            <div onClick={() => setOpen(!open)} className="author-img">
              <img src={userPhoto || userLogo} alt="userIcon" />
            </div>
            {open && (
              <ul className="author-nav">
                <li>{account?.username || "-"}</li>
                <li
                  onClick={() => history.push(`/profile/${account?.username}`)}
                  className=""
                >
                  MINA SPEL
                </li>
                <li
                  onClick={() =>
                    history.push(`/profile/${account?.username}`, {
                      isOpenProfile: true,
                    })
                  }
                  className=""
                >
                  INSTÄLLNINGAR
                </li>
                <li
                  onClick={() => {
                    logout({
                      returnTo: window.location.origin,
                    });
                  }}
                  className=""
                >
                  LOGGA UT
                </li>
              </ul>
            )}
          </ProfileGame>
        ) : (
          <UserLogButtonGame
            onClick={() => {
              loginWithRedirect();
            }}
          >
            Logga in
          </UserLogButtonGame>
        )}
        {/*isReducera ? <AlarmClock gameCloseTime={gameCloseTime} /> : null*/}
      </GameTypeHeaderContent>

      <Navbar className="navbar headerItem" />
    </GameTypeHeader>
  );
}

import React from 'react'
import {ContainerInput} from './input.element'


const CustomInput = ({id,label,error,onChange,className,img,counter,value,inputType,zero,...props}) => {

  return (
    <ContainerInput>
      <div className={`${className} wrapper ${error?.message ? 'errors': ''}`}>
        {label && <label htmlFor={id}>{label}</label>}
        {img && <div className='icon'><img src={img}/></div>}
        {inputType == 'input' ? <input
          onChange={onChange}
          id = {id}
          value={value}
          {...props}
        /> : <textarea
          onChange={onChange}
          id = {id}
          value={value}
          {...props}
        />}
        {counter && <span className='counter'>{value?.length}/{counter}</span>}
      </div>
      {error && <span style={{bottom:`${zero ? '0.5rem':'-1.5rem'}`}} className='error'>{error?.message}</span>}
    </ContainerInput>
  )
}

export default CustomInput
import React, { useEffect, useState } from 'react'
import { BombenDistributionContainer } from './BombenDistribution.styles'

const BombenDisributionHead = ({type}) => {
  let getScore = (score) =>{
    let toArr = score?.split('-')
    let getFirst = score[0]
    return getFirst || ''
  }
  return (
    <BombenDistributionContainer
      live
      head
    >
      <div className='score'>
        <span className='total-live'>{getScore(type?.score)}</span>
      </div>
    </BombenDistributionContainer>
  )
}

export default BombenDisributionHead
import styled from 'styled-components'
import { breakPoints, spacers, colors,font } from '../../styles/constants'

export const CreateContainer = styled.div`
  width:100%;
  padding-top:5.4rem;
  padding-bottom:10rem;
 
  @media screen and (max-width: 468px){
      padding: 3rem 0;
    }
  input {
    border: 1px solid #FFFFFF;
    width: 100%;
    max-height:50px;
    border-radius: 1rem;
    padding: 1.6rem 1rem 1.6rem 2rem;
    outline: none;
    background: transparent;
    font-size: 1.8rem;
    color: #ffffff;
    position: relative;
  }
  .counter {
    position:absolute;
    right: 1rem;
    bottom: 0.5rem;
    font-size: 1rem;
  }
  textarea {
    resize: none;
    border: 1px solid #FFFFFF;
    width: 100%;
    height:95px;
    width:100%;
    border-radius: 1rem;
    padding: 1.6rem 1rem 1.6rem 2rem;
    outline: none;
    background: transparent;
    font-size: 1.8rem;
    color: #ffffff;
  }
  .button {
    font-size:1.8rem;
    display:flex;
    cursor:pointer;
    font-weight:${font.weight.bold};
    align-items: center;
    justify-content: center;
    min-width:148px;
    box-shadow: 0px 3px 6px #0000004D;
    border-radius:0.5rem;
    border:none;
    color:${colors.white};
    background:${colors.panelDarkGrey};
    min-height:42px;
    @media screen and (max-width: 468px){
      font-size: 1.4rem;
      min-width: 14rem;
    }
  }
  .checked-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .checked-item {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-right: 2rem;
    img {
      width: 150px;
      height: 100px;
      object-fit: contain;
    }
  }
  label {
    font-size:2rem;
    margin-bottom:1.4rem;
  }
  .save {
      min-width:216px;
      background:${colors.anchor};
      @media screen and (max-width: 468px){
        min-width: 18rem;
      }
    }
  .row {
    display:flex;
    flex-direction:column;
    margin-top:2.8rem;
    position: relative;
    &--size {
      max-width:54.8rem;
    }
    &--default {
      flex-direction: row;
     @media screen and (max-width: 468px){
      justify-content: center;
    }
      .save {
        margin-right:2rem;
        @media screen and (max-width: 468px){
          margin-right: 1rem;
          min-width: 18rem;
       }
      }
    }
    &--space {
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .row-div {
        width:49%;
        display:flex;
        flex-direction:column;
        font-size: 2rem;
        .react-datepicker {
          font-size: 0.85em;
        }
        .react-datepicker__header {
          padding-top: 0.8em;
        }
        .react-datepicker__month {
          margin: 0.4em 0.5em;
        }
        .react-datepicker__day-name, .react-datepicker__day {
          width: 1.7em;
          line-height: 1.5em;
          margin: 0.166em;
        }
        .react-datepicker__current-month {
          font-size: 1em;
        }
        .react-datepicker__navigation {
          top: 1em;
          line-height: 1.7em;
        }
        .react-datepicker__navigation--previous {
          border-right-color: #ccc;
          left: 1em;
        }
        .react-datepicker__navigation--next {
          border-left-color: #ccc;
          right: 1em;
        }
      }
    }
  }
  h2 {
    font-size:4rem;
    font-weight:${font.weight.bold};
    @media screen and (max-width: 468px){
      font-size: 3rem;
      margin-bottom: 1rem;
    }
  }
  .head {
    display:flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 468px){
      justify-content: space-between;
    }
  }
  .info-error-input {
    font-size: 1.2rem;
    color: #ff0000;
    position: absolute;
    bottom: -1.5rem;
  }
  .input-error {
    border-color:#ff0000!important;
  }
  .category {
    margin-bottom:6rem;
    .item {
      display:flex;
      margin-top:3rem;
      width:100%;
      &:first-child {
        margin-top:2rem;
      }
      &-title {
        display: flex;
        padding-top:2.1rem;
        justify-content: space-between;
        @media screen and (max-width: 468px){
            flex-wrap: wrap;
            margin-top: 1rem;
            padding-top:0;
            
        }
      }
      &-number {
        margin-right:1.5rem;
        min-width: 3.8rem;
        @media screen and (max-width: 468px){
            margin-right:0.5rem;
            min-width: 3.4rem;
            text-align: center;
            span {
              font-size: 2.5rem!important;
            }
        }
        span {
          font-size:3.4rem;
          font-weight:${font.weight.semiBold};
        }
      }
      &-main {
        font-size:2rem;
        width:100%;
        .title {
          font-size:2.2rem;
          @media screen and (max-width: 468px){
            margin-bottom: 1rem;
           }
        }
        .bold {
          font-weight:${font.weight.semiBold};
        }
        .checked {
          display:flex;
          align-items: center;
          position: relative;
          top:-2.1rem;
          user-select: none;
           @media screen and (max-width: 468px){
              position: static;
          }
          div {
            display: flex;
            align-items: center;
            &:first-child {
              margin-left:3rem;
            }
            @media screen and (max-width: 468px){
            &:first-child {
              margin-left:1rem;
            }
            }
          }
          .type {
            opacity:0.5;
          }
          .percent {
             width:6.2rem;
             height:3.4rem;  
             display: flex;
             cursor:pointer;
             border-radius:0.5rem;
             background-color:${colors.darkGrey};
             align-items: center;
             font-weight:${font.weight.normal};
             justify-content: center; 
             margin:0 1rem;
             @media screen and (max-width: 468px){
              width: 4.2rem;
              height: 3rem;
              font-size: 1.4rem;
            }
          }
          .active {
              background-color:${colors.anchor};
             }
        }
      }
    }
  }
  
  .nav-text{
    width:100%;
    cursor:pointer;
    padding:1rem 2rem;
    margin:0.2rem 0;
    font-size:1.4rem;
    text-transform:uppercase;
    font-weight:${font.weight.bold};
    span {
      font-size:1.4rem;
      text-transform: initial;
      font-weight:${font.weight.normal};
    }
  }
  
  .visible {
      justify-content: start!important; 
      span {
        text-transform: uppercase!important;
        font-weight:${font.weight.semiBold};
      }
    }
  .img-logos{
    height: 3rem;
    width: auto;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    }
  .stryktipset {
        background-color: ${colors.stryktipset};
        display:flex;
        align-items: center;
        justify-content: space-between;
        + *:not(.stryktipset) {
          margin-top:none ;
        }
      }

      .europatipset {
        background-color: ${colors.europatipset};
        display:flex;
        align-items: center;
        justify-content: space-between;
        + *:not(.europatipset) {
          margin-top:none ;
        }
      }

      .topptipset {
        background-color: ${colors.topptipset};
        display:flex;
        align-items: center;
        justify-content: space-between;
        + *:not(.topptipset) {
          margin-top:none ;
        }
      }

      .powerplay {
        display:flex;
        align-items: center;
        justify-content: space-between;
        background-color: ${colors.powerplay};
        .img-logos{
          height:2rem;
        }
        + *:not(.powerplay) {
         margin-top:none ;
        }
      }
      .custom-selected{
        height:4.2rem;
        
        border-radius:0.5rem;
        display:flex;
        padding:1rem 2rem;
        align-items: center;
        font-size:1.4rem;
        font-weight:${font.weight.bold};
        .open {
          position: absolute!important;
          width: 2rem;
          height: 2rem;
          right: 0;
          display: flex;
          align-items: center;
          .open-img {
            width: 9px;
            height: 14px;
            object-fit: contain;
          }
        }
        span {
          margin-left:2.5rem;
          @media screen and (max-width: 468px){
            margin-left: 0.5rem;
            font-size: 1.2rem;
          }
        }
      }
      .custom-list {
        max-height:450px;
        overflow:auto;
        position:absolute;
        width:100%;
        z-index:1;
        top:4.1rem;
        background:${colors.grey};
        border-radius:0.5rem;
      }
      .custom-item {
        display:flex;
        align-items: center;
        
      }
      .custom-span {
          margin-right:1rem;
      }
      .img-logo-pow {
        height:2rem;
      }
      .background-str {
        background-color: ${colors.stryktipset};
      }
      .background-euro {
        background-color: ${colors.europatipset};
      }
      .background-pow {
        background-color: ${colors.powerplay};
      }
      .background-top { 
         background-color: ${colors.topptipset};
       }
      .info-error {
        font-size:1.2rem;
        color:${colors.svenskaSpelRed};
        position: absolute;
        bottom:-1.5rem;
      } 
      .custom-dropdow {
        position: relative;
      }
      .custom-selected-default {
        border:1px solid #ffffff;
        span {
        margin-left:0!important;
        }
     
      .span-default {
        margin-left:0!important;
      }
      .open {
          position: absolute!important;
          width: 2rem;
          height: 2rem;
          right: 0;
          display: flex;
          align-items: center;
          .open-img {
            width: 9px;
            height: 14px;
            object-fit: contain;
          }
      }
  
`

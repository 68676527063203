import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { formatSavedCouponDate } from '../../utils/helperFunctions'
import ActiveGamesBoxes from '../ActiveGamesBoxes/ActiveGamesBoxes'
import ShareCouponPopup from '../Popup/ShareCouponPopup'
import { colors, font } from '../../styles/constants'
import DeleteGamePopup from '../Popup/DeleteGamePopup.js';
import Profile from '../../assets/simple-profile.svg'
import Flag from '../../assets/flag.svg'
import TeamSmall from './TeamSmall'
import {
  UserContainer,
  Content,
  ActiveCoupons,
  Heading,
  CouponsList,
  CouponInfo,
  CouponItem,
  ItemButton,
  LatestClosedCoupons,
  Aside,
  WelcomePanel,
  TeamPanel,
  UserAbout,
  HeadingWith
} from './User.elements'

import { ReactComponent as DeleteIcon } from '../../assets/trash.svg'
import { ReactComponent as ShareIcon } from '../../assets/paper-plane.svg'
import { ReactComponent as ExpandIcon } from '../../assets/bold-close.svg'
import {ReactComponent as InfoIcon} from '../../assets/public-relation.svg'
import {ReactComponent as CloseInfoIcon} from '../../assets/Profile-mute.svg'
import { ReactComponent as SettingsIcon } from '../../assets/settings.svg'
import {ReactComponent as SvenskaLogo} from '../../assets/svenskaspel-logomark.svg'
import {ReactComponent as StatusCoupon} from '../../assets/status.svg'
import {ReactComponent as ModalIcon} from '../../assets/edit.svg'
import {ReactComponent as ReloadIcon} from '../../assets/reload.svg'
import BlogSmall from '../Blog/BlogSmall'
import { ToolsHeading } from '../../styles/globalStyle'
import Tooltip from 'rc-tooltip'
import facebook from '../../assets/facebook.svg'
import instagram from '../../assets/instagram.svg'
import linkedin from '../../assets/linkedin.svg'
import twitter from '../../assets/twitter.svg'
import Loader from '../Loader/Loader'
import NotFoundUser from '../NotFoundUser/NotFoundUser'

export default function User({
 profile,
 userData,
 userGames,
 handleDelete,
 isAuthenticated,
 team,
 blog,
 account,
 setIsModalOpen,
 hideTeam,
 teamLoad,
 blogLoad,
 infoLoad,
 couponLoad,
 userNotFound,
 setUserNotFound,
 publics
})
  {
  const [showAccordion, setShowAccordion] = useState(false)
  const [showTenLast, setShowTenLast] = useState(false)
  const [shareModal, setShareModal] = useState(false)
  const [couponData, setCouponData] = useState({})

  const [isVerifyPopupOpen, setIsVerifyPopupOpen] = useState(false);
  const [deleteCouponId, setDeleteCouponId] = useState("");
  const [deleteCouponNickname, setDeleteCouponNickname] = useState("");

  //State for team show
  const [showAccordionTeam,setShowAccordionTeam] = useState(getItem('team'))
  const [showAccordionBlog,setShowAccordionBlog] = useState(getItem('blog'))
  //console.log('userData', userData)
  //console.log('userGames', userGames)
  function getItem(type){
    if(type === 'team'){
      let b = window.localStorage.getItem('teamShow')
      if(b === null) return false
      let c = JSON.parse(b.toLowerCase());
      return c
    } else {
      let b = window.localStorage.getItem('blogShow')
      if(b === null) return false
      let c = JSON.parse(b.toLowerCase());
      return c
    }

  }
  useEffect(() => {
    const inActiveCoupons = userGames?.filter(el => el?.ongoingStatus === 'inactive')
    if (inActiveCoupons?.length === 0) {
      setShowTenLast(false)
    } else {
      setShowTenLast(true)
    }
  }, [userGames])

  function getImage (icon){
    let objImg = {facebook,twitter,instagram,linkedin};
    return objImg[icon?.type]
  }

  const localStorage = (type)=>{
    let storage = window.localStorage
      if(type === 'team'){
        if(storage.getItem('teamShow')){
          storage.removeItem('teamShow')
          storage.setItem('teamShow',!showAccordionTeam)
        } else {
          storage.setItem('teamShow',!showAccordionTeam)
        }
        setShowAccordionTeam(prev=>!prev)
      }else if(type === 'blog') {
        if(storage.getItem('blogShow')){
          storage.removeItem('blogShow')
          storage.setItem('blogShow',!showAccordionBlog)
        } else {
          storage.setItem('blogShow',!showAccordionBlog)
        }
        setShowAccordionBlog(prev=>!prev)
      } else {

      }
  }

  return (
    <UserContainer>
      <DeleteGamePopup
          open={isVerifyPopupOpen}
          toggleOpen={setIsVerifyPopupOpen}
          OK={ _ => handleDelete( _ , isAuthenticated)}
          deleteCouponId={deleteCouponId}
          nickname={deleteCouponNickname}
      />

      <ShareCouponPopup
        shareModal={shareModal}
        setShareModal={setShareModal}
        couponData={couponData}
      />
      <Content>
        {userNotFound ? (
          <NotFoundUser  setUserNotFound={setUserNotFound}/>
        ) : (<UserAbout>
          <div className='head'>
            <div className='avatar'>
              <img src={profile.avatar || Profile}/>
            </div>
            <div className='social'>
              <h4 className='username name'>{profile?.name || profile?.username}</h4>
              <div className='icon'>
                {
                  profile?.social?.map((icon) =>{
                    if(!icon?.link) return null
                    return <a className='link' target="_blank" rel="noopener noreferrer" href={icon.link}  >
                      <img src={getImage(icon)} />
                    </a>
                  })
                }

              </div>
            </div>
             <div className='edit'>
               {profile?.me && <div className='edit-btn' onClick={()=>setIsModalOpen(true)} >
                <ModalIcon/>
                <span>
                  Redigera profil
                </span>
              </div>}
              <div className='edit-info'>
                <Tooltip
                  prefixCls='rToolTip'
                  placement='bottom'
                  trigger={['hover']}
                  animation='anim'
                  overlay={
                    <>
                      <h3>{profile?.account ? 'Publik profil' : 'Privat profil'}</h3>
                    </>
                  }
                >
                  <ToolsHeading>
                    {profile?.account &&<InfoIcon/>}
                    {!profile?.account &&<CloseInfoIcon/>}
                  </ToolsHeading>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className='body'>
            {!infoLoad ?
              (<>
                {profile?.about && <h5>Presentation </h5>}
                <p>{profile?.about}</p>
              </>)
              :
              <Loader/>}
          </div>
        </UserAbout>)}

        {!userNotFound && publics && <ActiveCoupons border>
          <HeadingWith>
            <ReloadIcon className='reload'/>
            <StatusCoupon className='status'/>
            Aktiva kuponger: {profile?.username}</HeadingWith>
          {couponLoad && <Loader/>}
          <CouponsList>
            {(!couponLoad && userGames?.length === 0) && (
              <CouponItem>
                <CouponInfo style={{ height: 60, textAlign: 'center', background:'transparent' }}>
                  <h3 style={{ color: colors.white,display:'flex',alignItems:'center',
                    fontWeight: font.weight.semiBold, fontSize:"1.6rem" }}>
                    Inga aktiva kuponger
                  </h3>
                </CouponInfo>
              </CouponItem>
            )}

            {userGames
              ?.filter(el => el.ongoingStatus !== 'inactive')
              ?.map((el, i) => {
                return (
                  <CouponItem adaptive height dash btn key={`couponItemKey-${i}`} isWinner ={el?.data?.isLocked} >
                    {(() => {


                      const shouldLinkTo = el.data.status === "draft" ? "reducera" : "liveresults";

                      switch (el.ongoingStatus) {
                        case 'upcoming':
                          return (
                            <>
                              <CouponInfo gameType={el.data.gameType}>
                                <Link
                                  to={`/${shouldLinkTo}/${el.data.gameType}/${el.data.drawNumber}/${el.id}`}
                                  className='couponItemInfo'
                                >
                                  <div className='couponContent'>
                                    <div className='coupon-info'>
                                      <div className='coupon-img'>
                                        <div className='svenska'>
                                          <SvenskaLogo/>
                                        </div>
                                        <div className='game'>{el.data.gameType}{' '}</div>
                                        {el?.data?.isLocked && <span className='coupon-winner'>Deltar i SM</span>}
                                      </div>
                                      <p className='coupon-text'>
                                        <h3 className='couponHeading'>{el.nickname}</h3>
                                        {formatSavedCouponDate(el.closeTime)}
                                      </p>
                                    </div>
                                  </div>

                                  <p className='couponStatus coupon-status'>
                                    <p className='info'>
                                      {el.data.status === "draft" ? "Utkast" : "Inlämnad"}
                                    </p>
                                    <p className='icon'>
                                      <StatusCoupon/>
                                    </p>
                                  </p>
                                </Link>
                              </CouponInfo>

                              <div className='buttons'>
                                <ItemButton
                                  onClick={() =>  {
                                    setDeleteCouponId(el.id);
                                    setDeleteCouponNickname(el.nickname)
                                    setIsVerifyPopupOpen(true);
                                  }}
                                  disabled ={!profile?.me}
                                >
                                  <DeleteIcon alt='Delete icon' />
                                </ItemButton>
                                <ItemButton
                                  onClick={() => {
                                    setCouponData({
                                      id: el.id,
                                      url: `${process.env.REACT_APP_SITE_URL}/reducera/${el.data.gameType}/${el.data.drawNumber}/${el.id}`
                                    })
                                    setShareModal(true)
                                  }}
                                  disabled ={!profile?.me}
                                >
                                  <ShareIcon alt='Share icon' />
                                </ItemButton>
                              </div>
                            </>
                          )
                        case 'ongoing':
                          return (
                            <>
                              <CouponInfo
                                live
                                gameType={el.data.gameType}
                              >
                                <Link
                                  to={`/${shouldLinkTo}/${el.data.gameType}/${el.data.drawNumber}/${el.id}`}
                                  className='couponItemInfo'
                                >
                                  <div className='couponContent'>
                                    <div className='coupon-info'>
                                      <div className='coupon-img'>
                                        <div className='svenska'>
                                          <SvenskaLogo/>
                                        </div>
                                        <div className='game'>{el.data.gameType}{' '}</div>
                                        {el?.data?.isLocked && <span className='coupon-winner'>Deltar i SM</span>}
                                      </div>
                                      <p className='coupon-text'>
                                        <h3 className='couponHeading'>{el.nickname}</h3>
                                        {formatSavedCouponDate(el.closeTime)}
                                      </p>
                                    </div>
                                  </div>

                                  <p className='couponStatus coupon-status'>
                                    <p className='info'>
                                      {el.data.status === "draft" ? "Utkast" : "Inlämnad"}
                                    </p>
                                    <p className='icon live'>
                                      <StatusCoupon fill ='#fe0000'/>
                                    </p>
                                  </p>
                                </Link>
                              </CouponInfo>
                              <div className='buttons'>
                                <ItemButton
                                  onClick={() => {
                                    setDeleteCouponId(el.id);
                                    setDeleteCouponNickname(el.nickname);
                                    setIsVerifyPopupOpen(true);
                                  }}
                                  disabled ={!profile?.me}
                                >
                                  <DeleteIcon alt='Delete icon' />
                                </ItemButton>
                                <ItemButton
                                  onClick={() => {
                                    setCouponData({
                                      id: el.id,
                                      url: `${process.env.REACT_APP_SITE_URL}/liveresults/${el.data.gameType}/${el.data.drawNumber}/${el.id}`
                                    })
                                    setShareModal(true)
                                  }}
                                  disabled ={!profile?.me}
                                >
                                  <ShareIcon alt='Share icon' />
                                </ItemButton>
                              </div>
                            </>
                          )
                      }
                    })()}
                  </CouponItem>
                )
              })
              .reverse()
            }
          </CouponsList>
        </ActiveCoupons>}
        {/* change showTenLast to !showTenLast for test */}
        {!userNotFound && showTenLast && (
          <LatestClosedCoupons showAccordion={showAccordion}>
            <Heading outher onClick={() => setShowAccordion(!showAccordion)}>
              <span className='title'>
                <img src={Flag} alt='flag'/>
                Tio senaste avslutade kupongerna
              </span>
              <span className='expandIcon'>
                <ExpandIcon alt='Delete icon' />
              </span>
            </Heading>

            <CouponsList showAccordion={showAccordion}>
              {/* change 'inactive' to 'upcoming' for test data*/}
              {userGames
                ?.filter(el => el.ongoingStatus === 'inactive')
                ?.map((el, i) => {
                  const shouldLinkTo = el.data.status === "draft" ? "reducera" : "liveresults";
                  return(
                  <CouponItem
                    key={`couponInactiveItemKey-${i}`}
                    showAccordion={showAccordion}
                  >
                    <Link
                      to={`/${shouldLinkTo}/${el.data.gameType}/${el.data.drawNumber}/${el.id}`}
                      className='tenLatestItem couponItemInfo'
                    >
                      <div className='couponContent'>
                        <h3 className='couponHeading'>{el.nickname}</h3>
                        <p>
                          {el.data.gameType}{' '}
                          {formatSavedCouponDate(el.closeTime)}
                        </p>
                      </div>
                      {el?.data?.isLocked && <p className='coupon-winner'>Deltar i SM</p>}
                      <p className='couponStatus'>
                        {el.data.status === "draft" ? "Utkast" : "Inlämnad"}
                      </p>
                    </Link>
                    <div className='buttons'>
                      <ItemButton
                        onClick={() => {
                          setDeleteCouponId(el.id);
                          setDeleteCouponNickname(el.nickname);
                          setIsVerifyPopupOpen(true);
                        }}
                        disabled ={!profile?.me}
                      >
                        <DeleteIcon />
                      </ItemButton>
                    </div>
                  </CouponItem>
                )})
                // .slice(0, 10)}
                .slice(-10, userGames.length).reverse()}
            </CouponsList>
          </LatestClosedCoupons>
        )}
      </Content>
      <Aside>
        <WelcomePanel>
          <p className='title'>
            <strong>
              Välkommen till <em>Reducering.se</em> och Sveriges bästa reduceringsverktyg
              för Svenska Spels poolspelsprodukter!
            </strong>
          </p>

          <p>
            I inloggat läge visas alla aktiva spel, som är sparade. Den röda ramen runt
            indikerar att spelet är live och ett klick slussar direkt in i
            liverättningsvyn. Ingen röd ram = spelet är ej live och man kommer till
            reduceringsvyn.
          </p>

          <p>
            Med soptunnan raderas ej önskvärda kuponger och med flyg-symbolen delar man
            sin kupong. De senaste spelen finns att hitta längst ned i dragspelsvyn.
          </p>

          <p>Lycka till med ditt spelande!</p>

          {/* <Link to='/mypage' className='settings'>
            <SettingsIcon alt='Settings' />
          </Link> */}
        </WelcomePanel>
        {hideTeam && <TeamPanel>
          <LatestClosedCoupons showAccordion={showAccordionTeam}>
            <Heading back ={true} onClick={() => localStorage('team')}>
              ANDELSLAG
              <span className='expandIcon'>
                <ExpandIcon alt='Delete icon' />
              </span>
            </Heading>
            <CouponsList rad showAccordion={showAccordionTeam}>
              {/* change 'inactive' to 'upcoming' for test data*/}
              {showAccordionTeam && (
                <div className='team-wrapper'>
                  {teamLoad && <Loader/>}
                  {!teamLoad && (team?.length  > 0 ? team.map(item =>(
                    <TeamSmall team = {item }/>
                  )) : <p>Inga tillgängliga Andelslag</p>)}
                </div>
              )}
            </CouponsList>
          </LatestClosedCoupons>
        </TeamPanel>}
        <TeamPanel>
          <LatestClosedCoupons showAccordion={showAccordionBlog}>
            <Heading back ={true} text={true} onClick={()=>localStorage('blog')}>
              Senaste inlägg
              <span className='expandIcon'>
                <ExpandIcon alt='Delete icon' />
              </span>
            </Heading>
            <CouponsList rad showAccordion={showAccordionBlog}>
              {/* change 'inactive' to 'upcoming' for test data*/}
              {showAccordionBlog && (
                <div className='blog-wrapper'>
                  {blogLoad && <Loader/>}
                  {!blogLoad && (blog?.length > 0 ? blog.map(item =>(
                    <BlogSmall blog = {item}/>
                  )) : <p>Inga tillgängliga blogginlägg</p>)}
                </div>
              )}
            </CouponsList>
          </LatestClosedCoupons>
        </TeamPanel>
      </Aside>
      <ActiveGamesBoxes />
    </UserContainer>
  )
}

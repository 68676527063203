import React, { useEffect, useState } from 'react'
import { BombenDistributionContainer } from './BombenDistribution.styles'

let colorPalet = [{
  title:'red',
  color:'#ff6e40'
},{
  title:'yellow',
  color:'#ffd600'
},{
  title:'blue',
  color:'#00b8d4'
},{
  title:'green',
  color:'#00c853'
},]

const BombenDistributionLive = ({type,kind,currentType,eventArrId,eventId,winner}) => {
  const [bgColor, setBgColor] = useState(false);
  const getColor = (color) =>{
    return colorPalet.find(palet => palet.title === color).color
  }
  useEffect(()=>{
    let getCurr = isActive()
    let flags = getCurr?.flags?.map(item=>{
      if(item === 'yellow') return {item,pos:1}
      else if(item === 'blue') return {item,pos:2}
      else if(item === 'red') return {item,pos:3}
      else return {item,pos:4}
    })?.sort((a,b)=>a?.pos - b?.pos)?.map(col => `${col?.item}`);
    if(getCurr && flags?.length > 0){
      let length = getCurr?.flags?.length;
      switch (length){
        case 1:
          setBgColor(getColor(flags[0]))
          break;
        case 2:
          setBgColor(`linear-gradient(90deg, 
          ${getColor(flags[0])} 0%, ${getColor(flags[0])} 50%,  
          ${getColor(flags[1])} 50%,  ${getColor(flags[1])} 100%);`)
          break;
        case 3:
          setBgColor(`linear-gradient(90deg, 
          ${getColor(flags[0])} 0%, ${getColor(flags[0])} 33.3333%, 
          ${getColor(flags[1])} 33.3333%, ${getColor(flags[1])} 66.6667%,
          ${getColor(flags[2])} 66.6667%,${getColor(flags[2])} 100%);`)
          break;
        case 4:
          setBgColor(`linear-gradient(90deg, 
            ${getColor(flags[0])} 0%, ${getColor(flags[0])} 25%, 
            ${getColor(flags[1])} 25%, ${getColor(flags[1])} 50%,
            ${getColor(flags[2])} 50%, ${getColor(flags[2])} 75%, 
            ${getColor(flags[3])} 75%, ${getColor(flags[3])} 100%)`)
          break;
      }
    } else{
      setBgColor(false)
    }
  },[currentType])
  let getScore = (score) =>{
    let toArr = score?.split('-')
    let getFirst = score[0]
    return getFirst || ''
  }

  let findCoast = (score) =>{
    let toArr = score?.split('-')
    let getFirst = score[0]
    let find = currentType?.find(type => type?.score == getFirst?.toLowerCase())
    return find?.coast || 'P'
  }

  let isActive = () =>{
    let currScore = getScore(type?.score);
    let find = currentType?.find(type => type?.score == currScore?.toLowerCase());
    if(find){
      return find
    }else {
      return false
    }
  }
  let isWin = () =>{
    if(!winner) return
    let currScore = getScore(type?.score);
    let splitWin = winner?.split('-')
    let whatTeam = kind === 'home' ? splitWin[0] : splitWin[1]
    return currScore == whatTeam ? true : false
  }
  return (
    <BombenDistributionContainer
      live
      win={isWin()}
      isActive={isActive()}
      bgColor={bgColor}
    >
      <div className='info'>
          <span className='total-live'>{findCoast(type?.score)}</span>
      </div>
    </BombenDistributionContainer>
  )
}

export default BombenDistributionLive
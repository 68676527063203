import React, { useState, useContext, useEffect,useRef } from 'react'

import Tooltip from 'rc-tooltip'

import {
  ReductionContainer,
  ReductionRow,
  ReductionColumn,
  SpelaTillsammansButton,
  ReducedPercentage,
  AfterReduction,
  ReductionBtn
} from './ReducedRow.elements'
import {useHistory, Link} from 'react-router-dom'
import { ReactComponent as GroupIcon } from '../../../assets/group.svg'
import arrowDown from '../../../assets/chevron-down.svg'
import { GameContext } from '../../../context/gameContext'

export default function ReducedRow({
  totalRader,
  handleReduce,
  oddsArr,
  distArray,
  viewState,
  pointReduction,
  signReduction,
  colorReduction,
  setTotaloddsData,
  setHandinRows,
  setIsReduced,
  isReduced,
  setDividendForecast,
  setSignDistribution,
  id,
  betRowAmount,
  SpTsms,
  setReducedStates,
  setErrorPopup,
  setErrorPopupText,
  reducedRowsCount,
  setReducedRowsCount,
  actionType
}) {
  // const [reducedRowsCount, setReducedRowsCount] = useState(0)
  const [spelaTillsammansIndicator, setSpelaTillsammansIndicator] = useState(false)
  const { gameType,drawNumber } = useContext(GameContext)
  const button = useRef(null)
  const history = useHistory();
  /**
   * This function does all calculations on the frame,
   * by calling math object fuctions.
   *
   * Unfortunately, it also does some calculations w.r.t. Spela tillsammans,
   * like breaking up large math rows.
   *
   * PLEASE REWRITE THIS MASSIVE FUNCTION
   * Parts of it goes into the math module
   */

  const allDemCalculations = () => {
    let reducerObj = handleReduce(viewState, oddsArr, distArray)

    const finalPmin = pointReduction.min || pointReduction.minLimit
    const finalPmax = pointReduction.max || pointReduction.maxLimit

    reducerObj.pointReduce(pointReduction.min || pointReduction.minLimit, pointReduction.max || pointReduction.maxLimit)

    console.log(
      'reducing with min:',
      pointReduction.min || pointReduction.minLimit,
      'max: ',
      pointReduction.max || pointReduction.maxLimit
    )

    reducerObj.signReduce('1', signReduction.home.min, signReduction.home.max)
    reducerObj.signReduce('X', signReduction.draw.min, signReduction.draw.max)
    reducerObj.signReduce('2', signReduction.away.min, signReduction.away.max)

    let usedColors = []
    if (colorReduction.red.total) {
      usedColors.push('red')
    }
    if (colorReduction.blue.total) {
      usedColors.push('blue')
    }
    if (colorReduction.yellow.total) {
      usedColors.push('yellow')
    }
    if (colorReduction.green.total) {
      usedColors.push('green')
    }

    for (let color of usedColors) {
      reducerObj.colorReduce(
        color,
        colorReduction[color].min || 0,
        colorReduction[color].max || colorReduction[color].total
      )
    }

    // reducerObj.oddsReduce(
    //   oddsRange[0],
    //   oddsRange[1]
    // )

    let finalpRed = { ...pointReduction, min: finalPmin, max: finalPmax }
    //console.log("would save", finalpRed)

    let dist = reducerObj.generateSignDistribution()
    setSignDistribution(dist)
    setReducedStates({
      pointReduction: finalpRed,
      signReduction: signReduction,
      colorReduction: colorReduction
    })
    setIsReduced(true)
    setDividendForecast(reducerObj.generateForecast(gameType))
    setReducedRowsCount(reducerObj.rowCount)
    setTotaloddsData(reducerObj.generateHistogramPreset(gameType))
    //console.log('reducerObj.rows', reducerObj.rows)
    // This needs prettifying
    if (gameType === 'stryktipset' || gameType === 'europatipset') {
      if (reducerObj.rowCount < 2200) {
        if (SpTsms.isSpelaTillsammans) {
          setSpelaTillsammansIndicator(true)
        }
        setHandinRows({ type: 'single', rows: reducerObj.rows })
      } else {
        let mathrows = reducerObj.toMathRows()

        if (
          mathrows.some(row => {
            return row.every(sign => sign.length === 1)
          })
        ) {
          if (SpTsms.isSpelaTillsammans) {
            setSpelaTillsammansIndicator(false)
            setHandinRows({ type: '', rows: [] })
          } else {
            setHandinRows({ type: 'single', rows: reducerObj.rows })
          }
        } else {
          let newRows = [...mathrows]

          while (
            newRows.some(row => {
              return (
                row.reduce((acc, sign) => {
                  return acc * sign.length
                }, 1) > 9216
              )
            })
          ) {
            newRows = newRows.reduce((arr, row) => {
              let rowValue = row.reduce((acc, sign) => {
                return acc * sign.length
              }, 1)

              if (rowValue > 9216) {
                let row1 = [...row]
                let row2 = [...row]
                for (let i = 0; i < row.length; i++) {
                  let element = row[i]
                  if (element.length !== 1) {
                    row1[i] = row1[i].slice(0, 1)
                    row2[i] = row2[i].slice(1, element.length)
                    break
                  }
                }
                arr.push(row1)
                arr.push(row2)
              } else {
                arr.push(row)
              }
              return arr
            }, [])
          }
          setHandinRows({ type: 'math', rows: newRows })
          if (newRows.length >= 950) {
            setSpelaTillsammansIndicator(false)
          } else {
            setSpelaTillsammansIndicator(true)
          }
        }
      }
    } else {
      if (reducerObj.rowCount < 1000) {
        if (SpTsms.isSpelaTillsammans) {
          setSpelaTillsammansIndicator(true)
        }
        setHandinRows({ type: 'single', rows: reducerObj.rows })
      } else {
        let mathrows = reducerObj.toMathRows()

        if (
          mathrows.some(row => {
            return row.every(sign => sign.length === 1)
          })
        ) {
          if (SpTsms.isSpelaTillsammans) {
            setSpelaTillsammansIndicator(false)
            setHandinRows({ type: '', rows: [] })
          } else {
            setHandinRows({ type: 'single', rows: reducerObj.rows })
          }
        } else {
          let newRows = [...mathrows]

          while (
            newRows.some(row => {
              return (
                row.reduce((acc, sign) => {
                  return acc * sign.length
                }, 1) > 1000
              )
            })
          ) {
            newRows = newRows.reduce((arr, row) => {
              let rowValue = row.reduce((acc, sign) => {
                return acc * sign.length
              }, 1)

              if (rowValue > 1000) {
                let row1 = [...row]
                let row2 = [...row]
                for (let i = 0; i < row.length; i++) {
                  let element = row[i]
                  if (element.length !== 1) {
                    row1[i] = row1[i].slice(0, 1)
                    row2[i] = row2[i].slice(1, element.length)
                    break
                  }
                }
                arr.push(row1)
                arr.push(row2)
              } else {
                arr.push(row)
              }
              return arr
            }, [])
          }
          setHandinRows({ type: 'math', rows: newRows })
          if (newRows.length >= 950) {
            setSpelaTillsammansIndicator(false)
          } else {
            setSpelaTillsammansIndicator(true)
          }
        }
      }
    }
  }

  useEffect(() => {
    if (id) {
      // console.log("its AUTOREDUCING!")
      allDemCalculations()
    }
  }, [])
  useEffect(()=>{
    if(actionType == 'submit'){
      setTimeout(()=>{
        button.current?.click()
      },1000)
    }
  },[])

  return (
    <ReductionContainer>
      <ReductionRow>
        <div
          style={{
            display: 'flex',
            marginLeft: '0',
            marginRight: 'auto',
            alignItems: 'center'
          }}
        >
          <SpelaTillsammansButton
            className={SpTsms.isSpelaTillsammans ? 'isActive' : null}
            onClick={() => {
              SpTsms.setIsSpelaTillsammans(!SpTsms.isSpelaTillsammans)
              setIsReduced(false)
            }}
          >
            <GroupIcon />
          </SpelaTillsammansButton>

          <Tooltip
            prefixCls='rToolTip'
            placement='top'
            trigger={['hover']}
            animation='anim'
            overlay={
              <>
                <h3>Spela Tillsammans</h3>

                <p>
                  Dags för andelssystem på Spela Tillsammans?
                  <em>Reducering.se</em> underlättar för spelläggare som ska spela för mer än 2200 rader. Sätt ihop
                  systemet och klicka på <strong>"Spela Tillsammans"</strong>-knappen för att se om det går att lämna in
                  på Spela Tillsammans (p.g.a. tekniska begränsningar hos Svenska Spel är vissa reducerade system ej
                  möjliga att lämna in som andelssystem på Spela Tillsammans).
                </p>

                <p>
                  <strong>Grön pil upp</strong> = Yes! Systemet går att lämna in på Spela Tillsammans.
                </p>

                <p>
                  <strong>Röd pil ned</strong> = No! Systemet går EJ att lämna in på Spela Tillsammans. Ändra om
                  systemet lite och testa igen!
                </p>
              </>
            }
          >
            <span style={{ marginLeft: 0, marginRight: 5 }} className='rToolTip-icon'>
              i
            </span>
          </Tooltip>

          <div>
            {SpTsms.isSpelaTillsammans ?
              ((spelaTillsammansIndicator && isReduced) ? (
                <span style={{ color: '#00b708' }}>▲</span>
              ) : (
                <span style={{ color: '#FF0000' }}>▼</span>
              )): <span style={{ color: '#FFFFFF' }}>-</span>}
          </div>
        </div>

        <ReductionColumn>
          <p className='reduction-title'>Oreducerat</p>

          <p className='reduction-info'>
            <span>
              <strong>{totalRader}</strong> rader
            </span>

            <span>
              <strong>{totalRader}</strong> kr
            </span>
          </p>
        </ReductionColumn>

        <ReducedPercentage>
          <img src={arrowDown} />

          <div className='reducedAmount'>
            <span className='reducedPercentage'>
              {!isReduced ? '?' : (100 - (reducedRowsCount / totalRader) * 100).toFixed(0)}%
            </span>

            <span className='reducedNumeral'>{!isReduced ? null : reducedRowsCount - totalRader + ' rader'}</span>
          </div>
        </ReducedPercentage>

        <AfterReduction>
          <p className='reduction-title'>Reducerat</p>

          <p className='reduction-info'>
            <span>
              <strong>{!isReduced ? '?' : reducedRowsCount}</strong> rader
            </span>

            <span>
              <strong>{!isReduced ? '?' : `${betRowAmount * reducedRowsCount}`}</strong> kr
            </span>
          </p>
        </AfterReduction>
        <ReductionBtn
          hideMed
          onClick={(e) => {
            e.preventDefault()
            if(id){
              history.push(`/liveresults/${gameType}/${drawNumber}${id ? `/${id}` : ''}`)
            }else{

              setErrorPopup(true)
              // Not the best practice to set state to jsx
              setErrorPopupText(
                <>
                  <h3>Glöm inte att spara din kupong!</h3>
                  <Link to={`/liveresults/${gameType}/${drawNumber}/`}>
                    Fortsätt ändå
                  </Link>
                </>
              )
            }
          }}
        >
           liverättning
        </ReductionBtn>
        <ReductionBtn
          ref={button}
          id="reduceraButton"
          onClick={() => {
            // If there are no total rows, we dont run the reducer
            // The reducer will otherwise calculate rows in highlighted games.
            if (!totalRader) {
              setErrorPopup(true)
              setErrorPopupText(<h3>Din kupong är ofullständig!</h3>)
              return
            }
            allDemCalculations()
          }}
        >
          Reducera
        </ReductionBtn>
      </ReductionRow>
    </ReductionContainer>
  )
}

import styled from 'styled-components'
import {
  breakPoints,
  colors,
  font,
  spacers,
  borderRadius,
  headerHeight
} from '../../styles/constants'
import ArrowRight from '../../assets/right-arrow.png'

export const TableContainer = styled.div`
  max-width:556px;
  width:100%;
  @media screen and (max-width: 1200px){
    max-width: 460px;
    margin: 0 auto;
  }
  .element {
    padding:1.8rem 1.8rem 2rem 1.8rem;
    margin-top:0.2rem;
    border-radius:1rem;
    font-size:1.6rem;
    background: transparent linear-gradient(108deg, #5A6E79 0%, #37474F 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #0000000A;
    @media screen and (max-width: 468px){
     p {
        font-size: 1.4rem;
      }
    }
  }
  .result-game {
    font-weight: ${font.weight.semiBold};
  }
  .select {
    display:flex;
    align-items: center;
    margin-bottom:1rem;
    @media screen and (max-width: 468px){
      flex-direction: column;
      align-items: start;
    }
    p {
      font-weight:${font.weight.bold};
      margin-right:1.8rem;
      @media screen and (max-width: 468px){
      font-size: 1.6rem;
    }
    }
    &-active {
      color:${colors.anchor};
      .precent {
        background-color:${colors.anchor}!important;
        color:${colors.white};
      }
    }
    .win-precent {
    .precent {
       box-shadow: rgb(255 0 0) 0px 0px 0px 1px inset, rgb(255 255 255) 0px 0px 0px 4px inset;
    }
    }
  }
  
  .rating {
    user-select:none;
    display:flex;
    align-items: center;
    @media screen and (max-width: 468px){
      margin-top:1.4rem ;
    }
    div {
      display:flex;
      align-items: center;
      span {
        opacity:0.5;
        font-size:1.6rem;
      }
      .precent {
        width:46px;
        opacity:1;
        cursor:pointer;
        font-size:1.2rem;
        height:24px;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius:0.5rem;
        background-color:#263238;
        margin:0 1rem;
      }
    }
  }
  h2 {
    font-size:2.2rem;
    font-weight:${font.weight.bold};
    text-transform: uppercase;
    margin-bottom:1rem;
    @media screen and (max-width: 468px){
      font-size: 1.8rem;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const AsideContainer = styled.div`
  max-width:460px;
  width:100%;
  padding:1rem 2.7rem 2.2rem 2rem;
  background: transparent linear-gradient(152deg, #596E79 0%, #36474F 100%) 0% 0% no-repeat padding-box;
  border-radius:0.5rem;
  position: sticky;
  top:75px;
  @media screen and (max-width: 468px){
    padding:1rem 1rem 2.2rem 1rem;
  }
  @media screen and (max-height: 782px){
    top: 0;
  }
  @media screen and (min-height: 782px) and (min-width:1200px) and (max-width:1400px){
    top: 0;
  }
  .aside-absolute {
      position: absolute;
      width:10rem;
      top:0;
      right:0;
      @media screen and (max-width: 468px){
        display: none;
      }
      img {
        width:100%;
        height:auto;
        object-fit: cover;
        border-radius:0 0.5rem 0 0;
      }
  }
  h2 {
    font-size:2.4rem;
    font-weight:${font.weight.bold};
    margin-bottom:1rem;
    text-transform:uppercase;
  }
  .head {
    display:flex;
    align-items: center;
    font-size:1.6rem;
    @media screen and (max-width: 468px){
      font-size: 1.3rem;
    }
    .column {
      display: flex;
      flex-direction: column;
      margin-left:2.5rem;
      &--ml {
        margin-left: 1.5rem;
      }
      .mt {
        margin-top:0.5rem;
      }
    }
    &-img {
      img {
        width:49px;
        height:49px;
        object-fit:cover;
      }
    }
  }
 .table-header {
    margin-top:1.3rem;
    font-size:1.4rem;
    font-weight:${font.weight.semiBold};
    display: grid;
    grid-gap:1rem;
    grid-template-columns: 2fr 1fr 1fr 1.5fr;
    @media screen and (max-width: 468px){
      font-size: 1.2rem;
      grid-template-columns: 2.1fr 0.9fr 1.2fr 1.5fr;
    }
    .pl {
      padding-left:1.4rem;
    }
 }
 .table-body {
    margin-top:0.8rem;
    font-size:1.2rem;
    font-weight:${font.weight.bold};
    @media screen and (max-width: 468px){
      font-size: 1rem;
      grid-template-columns: 2fr 0.9fr 1.2fr 1.5fr;
    }
    .table-item {
      display: grid;
      margin-top:0.2rem;
      align-items: center;
      width:100%;
      grid-gap:1rem;
      grid-template-columns: 2fr 1fr 1fr 1.5fr;
      background: transparent linear-gradient(96deg, #596E79 0%, #36474F 100%) 0% 0% no-repeat padding-box;
      border-radius: 1rem;
      padding:0.6rem 1rem;
      
      .table-dist {
        display:flex;
        flex-direction: column;
      }
      .selected-precent {
        color:${colors.anchor};
      }
      .table-name {
        display: flex;
        align-items: center;
        .number {
          opacity:0.5;
          width:16px;
          display:flex;
          flex-shrink: 0;
          font-size:1.4rem;
          margin-right:0.6rem;
        }
      }
    }
    .checked {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        width:26px;
        cursor:pointer;
        border-radius:0.5rem;
        height:24px;
        background: #263238;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 468px){
          width:22px;;
          height:20px;
      }
      }
      .select-active {
        background-color:${colors.anchor};
      }
      .win-precent {
         box-shadow: rgb(255 0 0) 0px 0px 0px 1px inset, rgb(255 255 255) 0px 0px 0px 4px inset;
      }
    }

 }
 .footer {
   margin-top:1.3rem;
   margin-bottom:1.5rem;
   font-size:1.4rem;
   font-weight:${font.weight.bold};
   display:flex;
   align-items: center;
   span {
    width:50%;
    text-align: center;
   }
 }
 .finish {
  display: flex;
  flex-direction: column;
  grid-gap:0.2rem;
  margin-bottom: 2rem;
  &__title {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.4rem;
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.1rem 0;
    font-size: 1.6rem;
    opacity: 0.5;
  }
  .right-margin {
    margin-right: 1.5rem;
  }
 }
 .disable{
  opacity: 0.5;
  cursor: not-allowed!important;
 }
 .controls {
    display:flex;
    align-items: center;
    justify-content: space-between;
     @media screen and (max-width: 468px){
      justify-content: space-evenly;
     }
    button {
      min-width:200px;
      border:none;
      cursor: pointer;
      border-radius:0.5rem;
      max-width:201px;
      font-size:1.8rem;
      font-weight:${font.weight.semiBold};
      height:42px;
      align-items: center;
      justify-content: center;
      display:flex;
      color:${colors.white};
      background-color:${colors.anchor};
      @media screen and (max-width: 468px){
        min-width: 11.1rem;
        font-size: 1.2rem;
        padding: 0 2rem;
        height: 3rem;
      }
    }
    .red {
      background-color:${colors.svenskaSpelRed};
      display: flex;
      align-items: center;
      img {
        width: 1.8rem;
        margin-bottom: 0.4rem;
        margin-right: 0.8rem;
      }
      @media screen and (max-width: 468px){
        img{
          margin-right: 0.4rem;
          width: 11px;
          height: 11px;
        }
      }
    }
 }
`

export const BlogMainContainer = styled.div`
  width:100%;
  padding-top:4.8rem;
  padding-bottom:4.8rem;
  .without {
    text-align: center;
    font-size: 1.8rem;
  }
  h2 {
    margin-bottom:2rem;
    text-align: center;
    font-size:2.8rem;
  }
  .content {
    width:100%;
  }
  .blog {
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    padding-right:3rem;
    background: transparent linear-gradient(96deg, #5A6E79 0%, #37474F 100%) ;
    box-shadow: 0px 3px 15px #0000000A;
    border-radius:1rem;
    margin-bottom:1.6rem;
    @media screen and (max-width: 468px){
        padding-right:1.6rem;
      }
  }
  .blog-main {
     display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: 1rem;
    position: relative;
    min-height:10.8rem;
   
  }
  img {
    width:172px;
    height:108px;
    border-radius:1rem;
    object-fit: cover;
    @media screen and (max-width: 468px){
        width:121px ;
      }
  }
  .date {
    font-size:1.6rem;
     @media screen and (max-width: 768px){
        align-self: flex-start;
        font-size: 1.2rem;
        margin-top: 0.85rem;
      }
  }
  .info {
    margin-left:5.9rem;
    @media screen and (max-width: 468px){
        margin-left:3rem;
      }
    h3 {
      font-size:2.4rem;
      margin-bottom:1.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 60rem;
      padding-right:5rem;
      @media screen and (max-width: 768px){
        max-width: 20rem;
        width: 100%;
        font-size: 1.6rem;
        padding-right:0;
      }
      @media screen and (max-width: 468px){
        max-width: 12rem;
        width: 100%;
        font-size: 1.6rem;
      }
      
    }
    .link {
      font-size:1.8rem;
      font-weight:${font.weight.bold};
      color:${colors.anchor};
      display:flex;
      align-items: center;
       @media screen and (max-width: 768px){
        font-size: 1.4rem;
       // margin-top: 0.85rem;
      }
      .icon {
        background-image:url(${ArrowRight}) ;
        background-repeat: no-repeat;
        background-size: 16px 18px;
        background-position: center;
        width:2rem;
        height:2rem;
        margin-left:1rem;
        display: inline-flex;
      }
    }
  }
  .get-more-wrapper {
    text-align: center;
    margin-top:2.8rem;
    button {
      color: #36c5af;
      text-decoration: underline;
      border: none;
      outline: none;
      cursor: pointer;
      background: transparent;
      font-size: 1.8rem;
      }
      .get-more--color {
        color: #667C89;
      }
  }
`
export const BlogItem = styled.div`
  max-width: 22.6rem;
  min-width: 21rem;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0px 3px 15px #0000000A;
  border-radius: 1rem;
  .img {
    max-width: 100%;
  }
  .head{
    width: 100%;
    min-height: 16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position:relative;
     border-radius:  1rem 1rem 0 0;
    .link {
      width: 100%;
      height: 100%;
      display: flex;
      position: absolute;
    }
    p {
    font-size: 1.4rem;
    position: relative;
    z-index: 2;
      &:last-child {
        margin-bottom: 1rem;
      }
    }
  }
  .body {
    padding:1.6rem 1.6rem 2.3rem 1.6rem;
    background: transparent linear-gradient(145deg, #5A6E79 0%, #37474F 100%) 0% 0%;
    border-radius: 0 0 1rem 1rem;
    h4 {
      font-size: 2.4rem;
      font-weight: ${font.weight.bold};
      //margin-bottom: 1rem;
      text-transform: uppercase;
      width: 100%;
      margin: 0 auto 1rem auto;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    p {
      font-size: 1.6rem;
      margin-bottom: 2rem;
      text-align: left;
    }
    .link-go {
      font-size: 1.8rem;
      font-weight: ${font.weight.bold};
      color:${colors.anchor};
      display:flex;
      align-items: center;
      @media screen and (max-width: 768px){
        font-size: 1.4rem;
      }
      .icon {
        background-image:url(${ArrowRight}) ;
        background-repeat: no-repeat;
        background-size: 16px 18px;
        background-position: center;
        width: 2rem;
        height: 2rem;
        margin-left: 1rem;
        display: inline-flex;

      }
    }
  }

`

export const BlogElement = styled.div`
  //max-width: 33.8rem;
  width: 100%;
  background: linear-gradient(91.04deg, #263238 27.48%, rgba(38, 50, 56, 0) 101.72%);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  &:hover{
    cursor:pointer;
  }
  img {
    object-position: center;
    object-fit: cover;
    width: 140px;
    height: 8rem;
    margin-right: 0.5rem;
    border-radius: 0.5rem;
  }
  h3 {
    font-size: 1.6rem;
    font-weight: ${font.weight.semiBold};
    min-height: 37px;
    max-height: 38px;
    text-overflow: ellipsis;
    word-break: break-word;
    max-width: 16.2rem;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    margin-bottom: 1rem;
  }
  span {
    color: #AFAFAF;
    font-size: 1.2rem;
  }
`

import React from 'react'

import { ToolsHeading } from '../../../../styles/globalStyle'
import { ActivateBtn, LabelMinMax, Input } from '../ResultWidgets.elements'
import { PointReductionRow, PointReductionContainer } from './PointReduction.elements'
export default function PointReduction({ pointState }) {
  return (
    <PointReductionRow isDisabled={true}>
      <PointReductionContainer>
        <ActivateBtn>
          <span>P</span>
        </ActivateBtn>

        <ToolsHeading>Poängreducering</ToolsHeading>

        <LabelMinMax>Min</LabelMinMax>
        <Input placeholder='Min' readOnly value={pointState?.min || 0} />

        <LabelMinMax>Max</LabelMinMax>
        <Input placeholder='Max' readOnly value={pointState?.max || 0} />
      </PointReductionContainer>
    </PointReductionRow>
  )
}

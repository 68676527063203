import React, { useEffect, useState } from 'react'

import { GameArticlePanel, TeamsWrapper, TooltipGame } from './GamePageAboutContent.elements'

import { Helmet } from 'react-helmet-async'
import { useAuth0 } from '@auth0/auth0-react'
import { startAffiliate } from '../../../utils/helperFunctions'
import moment from 'moment'
import 'moment/locale/sv'
import ActiveCreatePopup from '../../../components/Popup/ActiveCreatePopup'
import Autorized from '../../../components/Popup/Autorized'
import Card from '../../../components/Card/Card'
import { Bar, BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Loader from '../../../components/Loader/Loader'

export default function StryktipsetContent() {
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [isLoader,setIsLoader] = useState(false);
  const [uploadPage,setUploadPage] = useState(1);
  const [team, setTeam] = useState(null);
  const [isCanLoad, setIsCanLoad] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [isModalLogin, setIsModalLogin] = useState(false);
  const [turnover, setTurnover] = useState([]);
  const [loaderTurn, setLoaderTurn] = useState(false);
  const [jackpot, setJackpot] = useState([]);
  const [loaderJack, setLoaderJack] = useState(false);
  const [jakpotItem, setJakpotItem] = useState(null)
  const fetchTeam = async (action,pages) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    setIsLoader(true)
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers= {}
    }
    let page
    if(pages){
      page = pages;
      setUploadPage(pages)
    }else{
      page = uploadPage
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/teams?page=${page}&perPage=6&gameType=topptipset`, {
      headers: headers
    }).then(res => {
      return res.json()
    }).then(data => {
      setTimeout(()=>{
        setIsLoader(false)
      },500)
      if(action === 'push'){
        let lenghtNewTeam = data?.teams?.length;
        if(lenghtNewTeam <= 5) setIsCanLoad(false)
        setTeam(prev => ([...prev,...data?.teams]))
      } else {
        setTeam(data?.teams)
      }

      startAffiliate()
    })
  }
  const fetchStrycGrap = async (action) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    setIsLoader(true)
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers= {}
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/charts/turnover?gameType=topptipset&page=1&perPage=40`, {
      headers: headers
    }).then(res => {
      return res.json()
    }).then(data => {
      let items = data?.turnovers?.map((turn,index) => {
        let toNumber = parseInt(turn?.value)
        moment.locale('sv')
        let formDate = moment(turn?.closeTime).format('DD MMM, YYYY')
        return {formDate,pv:toNumber}
      })
      setTurnover(items.reverse())
    }).finally(()=>{
      setLoaderTurn(true)
    })
  }
  const fetchStrycJackpot = async (action) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    setIsLoader(true)
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers= {}
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/charts/jackpot?gameType=topptipset&page=1&perPage=40`, {
      headers: headers
    }).then(res => {
      return res.json()
    }).then(data => {
      let jackPot = {
        '0-250':{
          total:0,
          jackPot:[]
        },
        '250-500':{
          total:0,
          jackPot:[]
        },
        '500-1000':{
          total:0,
          jackPot:[]
        },
        '1000-1500':{
          total:0,
          jackPot:[]
        },
        '1500-2500':{
          total:0,
          jackPot:[]
        },
        '2500-5000':{
          total:0,
          jackPot:[]
        },
        '5000-10k':{
          total:0,
          jackPot:[]
        },
        '10k-20k':{
          total:0,
          jackPot:[]
        },
        '20k-50k':{
          total:0,
          jackPot:[]
        },
        '50k+':{
          total:0,
          jackPot:[]
        },
      };
      setJakpotItem(data?.jackpots?.length)
      let items = data?.jackpots?.map(jack =>{
        let amount = parseInt(jack?.forecast?.distribution[0]?.amount);
        if(amount < 250){
          jackPot['0-250']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        }else if ( amount < 500){
          jackPot['250-500']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if(amount < 1000) {
          jackPot['500-1000']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if(amount < 1500){
          jackPot['1000-1500']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if (amount < 2500){
          jackPot['1500-2500']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if (amount < 5000){
          jackPot['2500-5000']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if (amount < 10_000){
          jackPot['5000-10k']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if (amount < 20_000){
          jackPot['10k-20k']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if (amount < 50_000){
          jackPot['20k-50k']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else {
          jackPot['50k+']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        }
      })

      let cleanObj = Object.entries(jackPot).map(item =>{
        let leng = item[1].jackPot.length
        //item[1].total = leng;
        item[1].title = item[0]
        if(!leng){
          item[1].total = 0.05
        } else{
          item[1].total = leng
        }
      })
      let jack = []
      let finalObj = Object.keys(jackPot).map(key =>jack.push(jackPot[key]))

      setJackpot(jack)
    }).finally(()=>{
      setLoaderJack(true)
    })
  }
  // Delete item
  const handlDelete = async (e,id) =>{
    e.preventDefault()
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/teams/${id}`,{
      method:'DELETE',
      headers:headers,
    })
    if(response.ok){
      setIsLoader(true)
      await fetchTeam(null,1)
    }
    return response.json();
  }
  // Create team item
  const handleSubmit = async (data={}) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    await setIsLoader(true)
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/teams`,{
      method:'POST',
      headers:headers,
      body:JSON.stringify(data)
    })

    let errorMessage = await response.json()
    return {response,error:errorMessage};

  }
  useEffect(()=>{
    if(!isLoading){
      fetchTeam()
      fetchStrycGrap()
      fetchStrycJackpot()
    }
  },[isLoading])
  const uploadMore = async () =>{
    let pages = uploadPage + 1
    fetchTeam('push',pages)
  }
  const updateTeam = async() =>{
    fetchTeam(null,1)
  }
  const handleFormat = (form,ele) =>{
    if(form>0){
      let isMilion = form >= 1_00_000_0
      let item = form
      if(isMilion){
        let milion = form/1_00_000_0
        //debugger
        milion?.toFixed(1);
        item = milion.toString() + 'm'
      } else {
        let milion = form/1_00_0
        //debugger
        milion?.toFixed(1);
        item = milion.toString() + 'k'
      }
      return item
    }  else {
      return form
    }
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if(!payload) return <div></div>
    return (
      <TooltipGame>
        <p className="desc">Antal 8-rättsutdelningar inom intervallet:
          {payload[0]?.payload?.total > 0.9 ? payload[0]?.payload?.total : '0' }</p>
      </TooltipGame>
    );
  }
  const CustomTooltipTur = ({ active, payload, label }) => {
    if(!payload) return <div></div>
    return (
      <TooltipGame size>
        <p className='title'>{payload[0]?.payload?.formDate}</p>
        <p className="desc green">{payload[0]?.payload?.pv?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} kr</p>
      </TooltipGame>
    );
  }
  return (
    <>
      <Helmet>
        <title>Lär dig reducera Topptipset | Reducering.se</title>
        <meta property='og:title' content='Sveriges bästa poolspels-verktyg' />
        <meta
          property='og:description'
          content='Med Reducering.se får du ett komplett verktyg som hjälper dig att reducera Topptipset. Välj bland poäng-, färg- eller 1X2-reduceringar. Läs mer om Topptipset här!'
        />
      </Helmet>
      <ActiveCreatePopup
        isModalOpen={isModal}
        setIsModalOpen={setIsModal}
        Submit={handleSubmit}
        setIsLoader={setIsLoader}
        updateTeam={updateTeam}
      />
      <Autorized
        isModalOpen={isModalLogin}
        setIsModalOpen={setIsModalLogin}
      />
      <TeamsWrapper>
        <Card
          withoutSort ={true}
          isLoader={isLoader}
          setIsLoader = {setIsLoader}
          team={team}
          IsCanLoad={isCanLoad}
          uploadMore={uploadMore}
          handlDelete={handlDelete}
          setIsModal={()=>setIsModal(true)}
          setIsModalLogin={setIsModalLogin}
        />
      </TeamsWrapper>
      <GameArticlePanel id='#vad-ar-topptipset'>
        <h3>Vad är Topptipset?</h3>
        <div className='article-container'>
        <p>
          <em>Topptipset</em> är en mindre och mer lättspelad variant av{' '}
          <em>Stryktipset</em> och <em>Europatipset</em> som introducerades år 2007, då
          endast på dagar då de större, ovan nämnda, spelen avgjordes. Numera är det en
          omgång varje dag, såvida det finns tillräckligt med matcher av att bygga
          kupongen kring. Kupongen består av åtta matcher jämfört med de 13 man tampas med
          på <em>Stryktipset</em> och <em>Europatipset</em> - den tydligaste skillnaden.
        </p>

        <p>
          Det är enbart fotbollsmatcher på kupongen och radpriset ligger på en krona. En
          annan skillnad mot <em>Stryktipset</em> och <em>Europatipset</em> är möjligheten
          att spela med högre radinsats - 1, 2, 3, 5 och 10 kronor - som snabbval.
        </p>

        <p>
          <em>Topptipset</em> är en av Svenska Spels produkter som ökar mest i
          popularitet, vilket förstås mäts i omsättning. Omsättningsrekordet på en omgång
          ligger på 5 222 856 kr (26:e december år 2015).
        </p>
          <p>
            Omsättningen på Topptipset beskriver alltså svenska folkets totala insatser per omgång.
            Bilden visar de {turnover?.length}/40 senaste omgångarna. Typen av matcher på kupongerna brukar påverka
            omsättningen liksom när det är EXTRAPENGAR på Topptipset .
          </p>
          {loaderTurn ? (<ResponsiveContainer width='100%' height = {160} >
            <LineChart
              data={turnover}
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5
              }}

            >
              <CartesianGrid  vertical={false}  />
              <YAxis interval={0} width={40} tickFormatter={handleFormat} tickMargin={8} stroke="#FFFFFF"/>
              <Tooltip content={<CustomTooltipTur/>}/>
              <XAxis hide dataKey="name" />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#40C7A8"
                strokeWidth={2}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>) : (
            <Loader/>
          )}
        </div>
        <div className='article-container'>
        <h4>72.5% tillbaka till kunderna</h4>

        <p>
          Återbetalningen är 72.5% på <em>Topptipset</em>. Det innebär alltså att Svenska
          Spel tar 27.5% av den totala omsättningen varje omgång.
        </p>
        </div>
      </GameArticlePanel>

      <GameArticlePanel id='#topptipset-sa-fungerar-det'>
        <h3>Topptipset - så fungerar det</h3>
        <div className='article-container'>
        <p>
          <em>Topptipset</em> går ut på att tippa rätt utfall - hemmaseger (1), oavgjort
          (X) eller bortaseger (2) - i åtta utvalda matcher som bildar en kupong och vinst
          utfaller om man har alla åtta rätt.
        </p>

        <p>
          De dagar då <em>Stryktipset</em> eller <em>Europatipset</em> avgörs samma dag,
          så består Topptipset av de första åtta matcherna på de kupongerna. Kupongerna
          hittas antingen i fysisk form hos Svenska Spels ombud eller online på Svenska
          Spels hemsida, där sistnämnda står för klart största andelen av omsättningen.
        </p>
        </div>
        <div className='article-container'>
        <h4>Så fungerar en enkelrad</h4>

        <p>
          En rad kostar alltså en krona, d.v.s. om man tippar endast ett utfall i samtliga
          13 matcher, så bildas en enda möjlig kombination - en s.k. enkelrad - som
          således endast kostar en krona att lämna in. Minsta tillåtna insats på{' '}
          <em>Topptipset</em> är två betalade rader.
        </p>
        </div>
        <div className='article-container'>
        <h4>Så fungerar hel- och halvgardering</h4>

        <p>
          För att öka chansen att vinna på <em>Topptipset</em>, så kan man använda sig
          utav både s.k. halv- och helgarderingar. En helgardering är, precis som det
          låter, en kombination av samtliga utfall - 1X2 - och tripplar radpriset varje
          gång det används. Halvgarderingar är kombinationer av två utfall i samma match -
          1X, X2 eller 12 - som alla dubblerar radpriset varje gång det används.
        </p>
        </div>
        <div className='article-container'>
        <h4>Att kombinera hel- och halvgarderingar</h4>

        <p>
          Att använda hel- och halvgarderingar ökar förstås chansen till fler antal rätt,
          samtidigt som det kostar mer för kunden att lämna in. Exempel: Om man väljer ett
          singelutfall i fyra av matcherna, halvgarderingar i tre och helgarderingar i en
          så bildar det (1x1x1x1x2x2x2x3) 48 möjliga kombinationer (rader). Det skulle
          följaktligen kosta 48 kronor att lämna in och benämns som ett systemspel, som
          för oss vidare till det segmenten.
        </p>
        </div>
        <div className='article-container'>
        <h4>Så fungerar systemspel</h4>

        <p>
          Exemplet på 48 rader som beskrivs ovan är ett vanligt M-system där M:et står för
          “Matematiskt”, den typen av system som används på Topptipset. I en halvgardering
          (1X som exempel) så fördelas då per automatik hälften (50%) av raderna på ena
          utfallet (1) och andra hälften (50%) på det andra utfallet (X). Likväl som
          raderna per automatik fördelas med en tredjedel (33,33%) på 1, X och 2 i en
          helgardering.
        </p>

        <p>
          Svenska Spel tillhandahåller ej färdiga R- och U-system för <em>Topptipset</em>.
        </p>
        </div>
        <div className='article-container'>
        <h4>Skapa egna system med Reducering.se</h4>

        <p>
          Det smartaste sättet att spela <em>Topptipset</em> på är med hjälp av
          reduceringsverktyget här inne på <em>Reducering.se</em>.{' '}
          <strong>Helt gratis!</strong> Med olika typer av enkla och smarta reduceringar
          kan man då själv bestämma hur många rader som ska reduceras bort från en
          grundrad av singeltecken, halv- och helgarderingar.
        </p>

        <p>
          På så sätt optimerar man systemet och betalar endast för det antalet rader man
          vill - och inget lämnas åt slumpen. Något som i längden kan vara skillnaden på
          vinst och förlust eftersom <em>Topptipset</em> är svårt att vinna på i ett
          långsiktigt perspektiv med tanke på den tidigare nämnda återbetalningsprocenten.
        </p>
        <p>
           Efter att man lämnat in spelet, så börjar det roliga - att följa resultaten i de
           åtta matcherna och se om det blir vinst. På <em>Topptipset</em> utfaller som
           sagt endast vinst för alla åtta rätt. Skulle det mot förmodan inte vara någon
           med alla åtta rätt, så går potten vidare till en jackpot.
        </p>
        </div>
      </GameArticlePanel>

      <GameArticlePanel id='#topptipset-resultat-och-jackpot'>
        <h3>Topptipset - resultat och jackpot</h3>
        <div className='article-container'>
          <p>
            Att det kan vara varierande utdelning på Topptipset är allmänt känt, men hur ser
            fördelningen ut? På bilden ser vi antal omgångar där utdelningen på 8 rätt hamnat i de
            olika intervallen, allt från låg utdelning till riktiga högvinster. Diagrammet visar de {jakpotItem}/40 senaste
            omgångarna av Topptipset.
          </p>
          {loaderJack ?(<ResponsiveContainer width='100%' height = {170} >
            <BarChart
              data={jackpot}
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5
              }}

            >
              <CartesianGrid  vertical={false}  />
              <YAxis width={20}  tickMargin={8} stroke="#FFFFFF"/>
              <Tooltip content={<CustomTooltip />} cursor={{ fill:'#596E79' }}  />
              <XAxis  stroke="#FFFFFF" dataKey="title" />
              <Bar
                type="monotone"
                dataKey="total"
                fill ="#40C7A8"
                stroke="#40C7A8"
                strokeWidth={2}
              />
            </BarChart >
          </ResponsiveContainer>) :(
            <Loader/>
          )
          }
        </div>
        <div className='article-container'>
        <h4>Liverättning</h4>

        <p>
          Det finns olika sätt att följa spelen. Antingen loggar man in på sitt Svenska
          Spel konto, klickar fram spelet under “Mina Spel” och följer målen, resultaten
          och utdelningen där. Ett annat sätt vi varmt rekommenderar är att följa kupongen
          inne på <em>Reducering.se</em> och den enimenta liverättningen där.
        </p>

        <p>
          Där ser man hur målen “plingar” in, hur många rätt man har och hur hög
          utdelningen är, allt snabbt uppdaterat. I den vyn ser man också eventuellt
          matcher som lottas och även resultatet, så snart det är fastställt.
        </p>
        </div>
        <div className='article-container'>
        <h4>Lottade matcher</h4>

        <p>
          Just lottade matcher är något som berör extra och kräver sin förklaring. Om ett
          matchresultat av olika anledningar inte kan fastställas på normalt sätt inom
          omgångens slutdatum (24 timmar efter sista matchen), så fastställs resultatet
          genom lottdragning.
        </p>

        <p>
          På <em>Topptipset</em> använder sig Svenska Spel av en slumptalsgenerator som
          slumpar fram ett nummer mellan 1 och 16. Slumptalsgeneratorn är certifierad
          enligt Spelinspektionens föreskrifter av ett internationellt ackrediterat
          testlaboratorium. Som utgångspunkt finns det två nummer som representerar
          samtliga utfall (1, X och 2) som fördelas 2-2-2 och därefter läggs det till
          nummer beroende på hur Svenska Spels startodds såg ut för aktuell match.
        </p>

        <p>
          Om oddsen t.ex. är 2.60-3.50-2.60 blir det 4-2-4 i fördelning vilket innebär att
          just den matchen lottas med nummer 1-6 som etta, 7-10 som kryss och 11-16 som
          tvåa, eftersom den totala fördelningen då blir 6-4-6.
        </p>

        <p>
          Om <em>Topptipset</em> tillhör en Stryktipsomgång (match 1-8) så får matcherna
          samma lottningsutfall som där och motsvarande för en kupong som tillhör en
          Europatipsomgång (match 1-8).
        </p>

        <p>
          Notera att fr.o.m. april 2020, som följd av Coronakrisen, finns det en regel att
          en omgång ställs in och alla insatser betalas tillbaka om minst tre matcher
          lottas. Svenska Spel har också infört att resultatet i lottade matcher
          presenteras ca. 45 minuter efter kupongens start.
        </p>
        </div>
      </GameArticlePanel>

      <GameArticlePanel id='#topptipset-tips-fran-coachen'>
        <h3>Topptipset - “tips från coachen”</h3>
        <div className='article-container'>
        <p>
          Att lyckas få alla rätt på <em>Topptipset</em> är förstås betydligt enklare än
          att få 13 rätt på <em>Stryktipset</em> och <em>Europatipset</em>. Spelformen har
          inte alls lika hög varians, men samtidigt är utdelningen oftast betydligt lägre.
          Nedan några viktiga punkter - “tips från coachen” - för att lyckas bättre i
          längden.
        </p>
        </div>
        <div className='article-container'>
        <h4>Strategi/Spelplan</h4>

        <p>
          Lägg upp en strategi, en plan, på spelandet. För hur mycket? Hur ofta? Spela
          ensam eller tillsammans med andra? Offensivt? Defensivt? En plan är bättre än
          ingen plan och glöm inte att inkludera BRM (Bank Roll Management).
        </p>
        </div>
        <div className='article-container'>
        <h4>Ha tålamod</h4>

        <p>
          Det gäller att hålla huvudet kallt - både i bra och dåliga perioder. Det är
          viktigt att hålla sig till planen - vindarna vänder alltid och det kan gå långt
          mellan de större vinsterna.
        </p>
        </div>
        <div className='article-container'>
        <h4>Spela reducerat</h4>

        <p>
          Spela smart och effektivt, d.v.s. betala inte för mycket för osannolika eller
          rader utan värde. Försök vikta insatserna på raderna så mycket som möjligt. Med
          hjälp av <em>Reducering.se:s</em> reduceringsverktyg kan man enkelt forma
          systemet precis som man vill. En i längden stor fördel jämfört med t.ex. vanliga
          matematiska garderingar som alltid är viktat 50-50% på en halv- och
          33.3-33.3-33.3% på en helgardering.
        </p>
        </div>
        <div className='article-container'>
        <h4>Spela med förhöjd radinsats</h4>

        <p>
          Det är som sagt enkelt att spela <em>Topptipset</em> med högre radinsats än en
          krona och det är något man ska försöka göra om budgeten tillåter. Jämfört med
          Stryktipset och Europatipset kommer vinsterna oftare och då gäller det att
          försöka maximera när träffen kommer.
        </p>

        <p>
          <em>Topptipset</em> kan med fördel spelas lite djärvare och med förhöjd
          radinsats multipliceras den eventuella vinsten med den valda radinsatsen. Notera
          att man med <em>Reducering.se:s</em> reduceringsverktyg enkelt kan klona
          kupongen och på så sätt spela med ännu högre radinsats än de max 10 kr som
          gäller per kupong, något som till exempel kan vara den rätta taktiken på en
          kupong med många starka favoriter och förväntad låg utdelning.
        </p>
        </div>
        <div className='article-container'>
        <h4>Det är kul att spela Tillsammans</h4>

        <p>
          Ett enkelt sätt att kunna spela större system är att spela tillsammans -
          antingen privat med vänner eller i arrangerade andelsspel. Vinstchansen ökar
          rejält och även om den egna delen av vinstkakan blir mindre, så är det
          troligtvis ett smartare sätt att spela på långsiktigt - om man inte själv har
          kunskapen eller tiden. Inne på <em>Spelarna.se</em> hittas andelsspel av både
          Oskar Block och Daniel “Striker” Domeij - två erkänt duktiga poolspelare.
        </p>
        </div>
        <div className='article-container'>
        <h4>Tänk på streckvärdet</h4>

        <p>
          Utdelningen baseras som sagt på utfallet i samtliga åtta matcher och hur många
          rätta rader det finns. Streckfördelningen i varje match är därför en viktig
          parameter - den visar hur stor del av omsättningen som ligger på 1, X samt 2 i
          varje match. Streckfördelningen bland svenska folket och lagens egentliga
          vinstchans är dock två vitt skilda saker, så därför är det viktigt att jämföra
          dessa värden för att hitta eventuella “streckvärden”.
        </p>

        <p>
          Exempel: Tottenham-Arsenal har 60-22-18% i streckfördelning samtidigt som
          oddsmarknaden visar 2.20-3.45-3.45 på samma match, d.v.s. ca 44-28-28 omräknat
          till sannolikheterna i procentform. Det innebär alltså att ettan på Tottenham är
          väldigt “överstreckad” om man utgår från att oddsen är korrekta - vilket de i
          längden troligtvis är jämfört med svenska folkets samlade åsikt. Det innebär
          också att X och framförallt 2 har ett fint streckvärde och kan således vara värt
          fler rader jämfört med 1.
        </p>

        <p>
          Tänk dock på att ju fler som använder sig av samma taktik, desto större
          sannolikhet att fler hittar samma rader, vilket gör att utdelningen blir lägre.
          Det gäller därför att kunna särskilja sig från olika typer av “spelartyper”,
          t.ex. småspelaren som ofta bara tippar vad de tror om matcherna och
          storspelaren, som ofta spelar betydligt mera strategiskt med streckvärdet i
          fokus. En mix är förmodligen det optimala i ett långt perspektiv.
        </p>
        </div>
        <div className='article-container'>
        <h4>Läs på och häng med</h4>

        <p>
          Det skadar givetvis inte att läsa på, plugga, inför matcherna.{' '}
          <em>“Den som vet mest vinner mest”</em> är en gammal Oddset-slogan som Svenska
          Spel använt sig av och säger en del om sanningen. Det finns en uppsjö av sidor
          att hitta information på och experter att ta hjälp av. Om inte annat kan det
          vara bra att veta vilka lag som kanske får extra mycket uppmärksamhet tack vare
          media - samla på så mycket information som möjligt. Kanske kan det vara läge att
          gå mot “massan”? Försök även se matcher och följ med i hur oddsen ligger för
          olika lag - det kan också ge fördelar.
        </p>
        </div>
      </GameArticlePanel>

      <GameArticlePanel id='#topptipset-vinstutdelning'>
        <h3>Topptipset - vinstutdelning</h3>
        <div className='article-container'>
        <p>
          Återbetalningen på <em>Topptipset</em> är alltså 72.5% av den totala
          omsättningen och vinst utfaller endast på alla åtta rätt. Av 72.5% avsätts 2.5%
          till den s.k. rullpottsfonden, som används för att “boosta” framtida omgångar.
        </p>

        <p>
          Den hittills högsta utdelningen för åtta rätt på <em>Topptipset</em> sträcker
          sig tillbaka till den 12:e december år 2009 då utdelningen nådde hela 327 756
          kronor.
        </p>
        </div>
      </GameArticlePanel>
    </>
  )
}

import styled, { css } from 'styled-components'

export const BombenContainer = styled.div`
  background: #273238;
  .bomben-wrapper{
    display: grid;
    width: 100%;
    grid-template-areas:
      'game game load load'
      'game game trend trend'
      'game game color color'
      'game game select select'
      'game game reselect reselect'
      'game game win win'
      'game game chart sett'
      ;
    grid-template-columns: 450px 450px 262px 186px;
    grid-template-rows: 64px 115px 174px 50px 56px 156px auto;
    grid-gap: 0.4rem;
    align-items: flex-start;
     @media screen and (max-width:1500px){
     grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media screen and (max-width:1370px){
      grid-template-rows: 64px 108px 174px 50px 56px 156px auto;
    }
     @media screen and (max-width:1090px){
     grid-template-columns: 1fr 1fr 1fr;
     grid-template-rows: auto;
     grid-template-areas:
      'game game game'
      'load load load'
      'trend trend trend'
      'color color color'
      'select select select'
      'reselect reselect reselect'
      'win win win'
      'chart sett sett'
      ;
    }
    @media screen and (max-width:760px){
     grid-template-areas:
      'game game game'
      'load load load'
      'trend trend trend'
      'color color color'
      'select select select'
      'reselect reselect reselect'
      'win win win'
      'chart chart chart'
      'sett sett sett'
      ;
    }
  }
  .trend{
  width: 100%;
  grid-area: trend;
  }
  .bomben-games{
    grid-area: game;
    display: grid;
    grid-template-columns: 45rem 45rem;
    grid-gap: 0.4rem;
    grid-template-rows: max-content;
    align-items: flex-start;
    @media screen and (max-width:1500px){
     grid-template-columns: 1fr;
    }
  }
  .bomben-settings{
    grid-area: sett;
    display: grid;
    grid-template-areas:
      'game game'
      'clone spara'
      'delet lama'
      ;
    grid-template-columns: 92px 92px;
    grid-template-rows: 115px 85px 85px;
    grid-gap: 0.4rem;
    grid-auto-flow: row;
    align-items: stretch;
     @media screen and (max-width:1500px){
     grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width:1090px){
      grid-template-rows: 115px 86px 86px;
    }
    @media screen and (max-width:768px){
       grid-template-areas:
      'game live'
      'clone spara'
      'delet lama'
      ;
      
    }
  }
  .bomben-live{
    display: grid;
    width: 100%;
    grid-template-areas:
      'game load chart'
      'game info chart'
      'game color chart'
      'game select chart'
      'game total chart'
      'game win chart'
      ;
    grid-template-columns: 466px 443px 443px;
    ${props => props.size > 0 ? 
      `grid-template-rows:64px ${28 + ((props.size > 5 ? 5 : props.size) * 45) + 'px'} 174px 50px 55px auto` : 
    'grid-template-rows: 64px 74px 174px 50px 55px auto'};
    align-items: flex-start;
    grid-gap: 0.4rem;
     @media screen and (max-width:1520px){
       grid-template-columns: auto auto 380px;
     }
    @media screen and (max-width:1367px){
    grid-template-areas:
      'game load'
      'game info'
      'game color'
      'game total'
      'game select'
      'game win'
      'game chart'
      ;
      grid-template-columns: 1fr 1fr;
      ${props => props.size > 0 ?
        `grid-template-rows:64px ${28 + ((props.size > 5 ? 5 : props.size) * 45) + 'px'} 174px 55px 50px 156px auto` :
        'grid-template-rows: 64px 74px 174px 55px 50px 156px auto'};
    }
      @media screen and (max-width:969px){
        grid-template-areas:
          'game'
          'load'
          'chart'
          'total'
          'select'
          'win'
          'color'
          'info'
          ;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto auto auto;
    }
  }
  }
  .bomben-live_game{
    grid-area: game;
    display: flex;
    flex-direction: column;
    grid-gap: 0.4rem;
  }
  .live-matrix{
    grid-area: chart;
  }
`
import { useEffect, useState } from "react";
import stryktipsetLogo from "../assets/europatipset-wordmark.svg";

export const ContestPopup = () => {
  const [upcomingContestDraw, setUpcomingContestDraw] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getUpcomingDraw = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/wins/contest-draws`,
        );
        const data = await res.json();

        if (data.length) {
          const upcomingDraw = data.find(
            (draw) => new Date(draw.date) > new Date(),
          );

          if (!upcomingDraw?.gameType) return;
          const res = await fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/gameInfo`,
          );
          const gameInfo = await res.json();

          const draw = gameInfo[upcomingDraw.gameType]?.draws.find(
            (draw) => draw.drawNumber === parseInt(upcomingDraw.id),
          );

          if (draw) {
            setUpcomingContestDraw({
              ...draw,
              gameType: upcomingDraw.gameType,
            });
          }
        }
      } catch (err) {
        return [];
      }
    };

    const dontShowContestCountdown =
      localStorage.getItem("dontShowContestPopup") || "";
    const now = new Date().getTime();
    if (dontShowContestCountdown && now < parseInt(dontShowContestCountdown)) {
      return;
    }

    localStorage.removeItem("dontShowContestPopup");

    if (!localStorage.getItem("dontShowContestPopup")) {
      getUpcomingDraw();
    }
  }, []);

  useEffect(() => {
    if (upcomingContestDraw?.gameType) {
      setIsOpen(true);
    }
  }, [upcomingContestDraw]);

  const handleClose = () => {
    const now = new Date();
    const inFuture = new Date(now);
    inFuture.setMinutes(now.getMinutes() + 60);
    localStorage.setItem("dontShowContestPopup", inFuture.getTime().toString());

    setIsOpen(false);
  };

  return isOpen ? (
    <div
      style={{
        position: "fixed",
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
      }}
      onClick={handleClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          display: "flex",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "1px solid black",
          backgroundColor: "#18252F",
          flexDirection: "column",
          justifyItems: "center",
          padding: "20px",
          borderRadius: "5px",
          width: "90%",
          maxWidth: "400px",
        }}
      >
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            backgroundColor: "transparent",
            border: "none",
            color: "white",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          X
        </button>
        <p
          style={{
            fontSize: "20px",
            fontStyle: "bold",
            textAlign: "center",
          }}
        >
          Reducering-EM på
        </p>
        <img
          src={stryktipsetLogo}
          alt="stryktipsetLogo"
          style={{
            height: "80px",
            objectFit: "contain",
            margin: "0 10px",
          }}
        />
        <p
          style={{
            fontSize: "14px",
            textAlign: "center",
            margin: "5px",
          }}
        >
          Vinn resa värd 10 000 kr!
        </p>
        <a
          href={`/reducera/${upcomingContestDraw.gameType}/${upcomingContestDraw.drawNumber}`}
          style={{
            textAlign: "center",
            backgroundColor: "#038733",
            borderRadius: "5px",
            marginTop: "25px",
            fontSize: "20px",
            margin: "10px 10px auto",
            color: "white",
            padding: "10px",
          }}
        >
          Spela nu!
        </a>
      </div>
    </div>
  ) : null;
};

import styled, { keyframes} from 'styled-components'
import { breakPoints, spacers, colors, font, borderRadius } from '../../styles/constants';

const fadeIn = keyframes`
  from{
    opacity: 0;
  } to{
    opacity: 1;
  }
`;

export const LiveDate = styled.div`
  width: 100%;
  padding: 0.7rem;
  background: transparent linear-gradient(106deg, #596E79 0%, #36474F 100%) 0% 0% no-repeat;
  animation: ${fadeIn} 1s ease-out;
  .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    margin-bottom: 0.6rem;
    color: ${colors.white};
  }
  .progress{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 50%;
    grid-gap: 0.2rem;
    margin-bottom: 0.6rem;
  }
  .first{
    transform: rotate(180deg);
  }
`
export const ProggresBar = styled.div`
  width: 100%;
  progress{
    width: 100%;
    height: 14px;
    border-radius: 2px;
  }
 progress::-webkit-progress-bar{
   background-color: #28343A;
   appearance: none;
  }
  progress::-webkit-progress-value {
  background-color:${props=> props.colors};
  border-radius: 2px;
  }
`

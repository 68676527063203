import styled, { css } from 'styled-components'
import { breakPoints, borderRadius, colors, font, spacers } from '../../../styles/constants'
import { CircleBtn } from '../../../styles/globalStyle'

export const ToolsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakPoints.xlarge}) {
    position: sticky;
    top: 74px;
    align-self: flex-start;
  }

  > * + * {
    margin-top: ${spacers.xxsmall};

    @media screen and (min-width: ${breakPoints.xlarge}) {
      margin-top: ${spacers.xsmall};
    }

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      margin-top: ${spacers.small};
    }
  }
`

export const ActivateBtn = styled(CircleBtn)`
  background: ${props => (props.isActive ? colors.vividCyan : colors.grey)};
  margin-top: -${spacers.small};
  margin-bottom: -${spacers.small};
  margin-right: ${spacers.xlarge};
  color: ${props => (props.isActive ? colors.dark : colors.lightGrey)};
  box-shadow: rgb(0 0 0 / 35%) 0px 3px 13px -2px;
`

export const Input = styled.input`
  background: ${colors.darkGrey2};
  color: ${colors.white};
  text-align: center;
  height: 2rem;
  width: 6rem;
  padding: ${spacers.small};
  border-radius: ${borderRadius.xsmall};
  border: none;

  + input {
    margin-left: ${spacers.small};
  }

  @media screen and (min-width: ${breakPoints.medium}) {
    width: calc(100% / 2 - ${spacers.small} / 2);
    max-width: 4.5rem;
  }
`

export const ReductionInner = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    flex-direction: column;
    align-items: flex-end;

    > * + * {
      margin: ${spacers.xlarge} 0 0 0;
    }
  }
  ${props=>props.noColumn && css`
     @media screen and (max-width: ${breakPoints.mediumMax}) {
        flex-direction: row;
        > * + * {
          margin: ${spacers.xlarge} 0 0 0;
        }
      }
  `}
`

export const LabelMinMax = styled.label`
  font-size: ${font.size.small};
  font-weight: ${font.weight.light};
  line-height: 1.5;
  color: ${colors.lightGrey};
  margin-right: ${spacers.medium};
  margin-left: 1rem;
`

export const Label = styled.label`
  font-size: ${font.size.xlarge};
  font-weight: ${font.weight.semiBold};
  display: flex;
  flex-basis: 100%;
  max-width: 12rem;

  margin-left: auto;
  margin-right: auto;

  justify-content: space-evenly;
  align-items: flex-end;
  color: ${colors.lightGrey};
  text-align: center;
  line-height: 0.8;
  margin-bottom: ${spacers.small};

  @media screen and (min-width: ${breakPoints.xlarge}) {
    font-size: 2.8rem;
  }

  + .break {
    flex-basis: 100%;
    height: 0;
  }

  &:before, &:after {
    font-size: ${font.size.small};
    font-weight: ${font.weight.light};
    line-height: 1;
  }

  &:before {
    content: 'Min';
  }

  &:after {
    content: 'Max';
  }
`

export const Sign = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;

  flex-wrap: wrap;
  position: relative;

  width: calc(100% * 1/3 - ${spacers.xlarge});

  &::before {
    content: '';
    border-left: 2px solid ${colors.grey};
    height: 100%;
    left: -1.25rem;
    position: absolute;
  }

  &:first-child::before {
    display: none;
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    width: calc(100% * 1/3 - ${spacers.medium});

    &::before {
      left: -0.5rem;
    }
  }
`

export const Select = styled.select`
  background: ${colors.darkGrey2};
  color: ${colors.white};
  height: 2rem;
  text-indent: 0.25rem;
  border-radius: ${borderRadius.xsmall};
  border: none;
  color: ${colors.white};
  width: 5rem;

  @media screen and (min-width: ${breakPoints.xlarge}) and (max-width: ${breakPoints.xxlargeMax}) {
    max-width: calc(50% - 0.4rem);
  }

  + select {
    margin-left: ${spacers.small};
  }
`

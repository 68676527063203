/**
 * Very efficient cartesian product, shamelessly stolen from Stack Exchange.
 */
function cartesianProduct(a) {
  // a = array of array
  var i,
    j,
    l,
    m,
    a1,
    out = []
  if (!a || a.length == 0) return a
  a1 = a.splice(0, 1)[0] // the first array of a
  a = cartesianProduct(a)
  for (i = 0, l = a1.length; i < l; i++) {
    if (a && a.length) {
      for (j = 0, m = a.length; j < m; j++) {
        out.push([a1[i]].concat(a[j]))
      }
    } else {
      out.push([a1[i]])
    }
  }
  return out
}
/**
 * Rounds UP to the nearest whole multiple of xs order of magnitude
 */
function RoundNearest(x) {
  return Math.floor(x / 10 ** (numDigits(x) - 1) + 1) * 10 ** (numDigits(x) - 1)
}
/**
 *  Calculates the number of digits of x
 **/
function numDigits(x) {
  return (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1
}
/**
 *  Generates a geometric progression from start to end, with num number of steps.
 */
function geometricProgressionNum(start, end, num) {
  // const step = (end / start) ** (1 / num)
  const step = (end / start) ** (1 / num)
  const arr =  Array.from({
    length: num + 1
  }).map((_, i) => Math.ceil(start * step ** i))
  return arr;
}
/**
 * Returns percentage (as integer) of values in list, whose values are between lim_1 and lim_2
 */
function countElements(list, lim_1, lim_2, tot_count) {
  let c = 0
  for (let x of list) {
    if (lim_1 <= x && x <= lim_2) {
      c += 1
    }
  }
  return Number(Math.ceil((c / tot_count) * 100))
}
// Makes a row of rows out of the input system
function init(system, odds, distribution) {
  let map = {
    home: '1',
    draw: 'X',
    away: '2'
  }
  let out = []
  for (const [i, game] of system.entries()) {
    let gamerow = []
    for (let sign in game) {
      if (game[sign].isSelected) {
        gamerow.push({
          sign: map[sign],
          points: game[sign].points,
          colors: game[sign].colors,
          odds: odds[i][sign] ? odds[i][sign] : 0.5,
          distribution: distribution[i][sign] ? distribution[i][sign] : 0.5
        })
      }
    }
    out.push(gamerow)
  }
  // There are no rows in the system if a gamerow is empty
  for (let gamerow of out) {
    if (gamerow.length == 0) {
      return []
    }
  }
  out = cartesianProduct(out)
  return out
}
/**
 * Game reducer object.
 * Contains an internal representation of the
 *
 * Expects data to be an array with this shape:
 *  [
 *      {home: { points: 1, color: []}, draw: { points: 1, color: []}, away: { points: 1, color: []} },
 *      {home: { points: 1, color: []}, draw: { points: 1, color: []}, away: { points: 1, color: []} },
 *      {home: { points: 1, color: []}, draw: { points: 1, color: []}, away: { points: 1, color: []} },
 *      {home: { points: 1, color: []}, draw: { points: 1, color: []}, away: { points: 1, color: []} },
 *      {home: { points: 1, color: []}, draw: { points: 1, color: []}, away: { points: 1, color: []} },
 *      ...
 *  ]
 *
 *  and odds of this shape
 *  [
 *     {home: 1.34, draw: 1.34, away: 1.34 },
 *     {home: 1.34, draw: 1.34, away: 1.34 },
 *     {home: 1.34, draw: 1.34, away: 1.34 },
 *     {home: 1.34, draw: 1.34, away: 1.34 },
 *  ]
 *
 *  Expects the length of data and object to be equal.
 *
 * @param {Object} data -- Representation of a coupon
 * @param {Object} odds -- Odds for the games in the coupon
 */
function GameReducer(data, odds, distribution) {
  if (odds.length !== data.length) {
    throw new Error(
      "GameReducer error: Number of odds and number of games don't match"
    )
  }
  let _internalRows = init(data, odds, distribution)
  let uRows = _internalRows.length

  return {
    _unreducedRowsCount: uRows,
    _rows: _internalRows,
    _rowCount: _internalRows.length,
    pointReduce: function (min, max) {
      this._rows = this._rows.filter(row => {
        let points = row.reduce((acc, el) => {
          return acc + el.points
        }, 0)
        return min <= points && points <= max
      })
      this._rowCount = this._rows.length
      return this
    },
    signReduce: function (sign, min, max) {
      this._rows = this._rows.filter(row => {
        let signCount = row.reduce((acc, el) => {
          return acc + (el.sign === sign ? 1 : 0)
        }, 0)
        return min <= signCount && signCount <= max
      })
      this._rowCount = this._rows.length
      return this
    },
    colorReduce: function (color, min, max) {
      this._rows = this._rows.filter(row => {
        let colorCount = row.reduce((acc, el) => {
          return acc + (el.colors.includes(color) ? 1 : 0)
        }, 0)
        return min <= colorCount && colorCount <= max
      })
      this._rowCount = this._rows.length
      return this
    },
    oddsReduce: function (min, max) {
      this._rows = this._rows.filter(row => {
        let rowOdds = row.reduce((acc, el) => {
          return acc * el.odds
        }, 1)
        return min <= rowOdds && rowOdds <= max
      })
      this._rowCount = this._rows.length
      return this
    },
    generateHistogram: function (steps) {
      const oddsArray = this._rows.reduce((array, row) => {
        array.push(
          row.reduce((acc, el) => {
            return acc * el.odds
          }, 1)
        )
        return array
      }, [])
      const minMax = array => {
        let min = Number.MAX_VALUE
        let max = Number.MIN_VALUE
        let total = 0
        array.forEach(value => {
          if (value < min) {
            min = value
          }
          if (value > max) {
            max = value
          }
          total = total + value;
        })
        let avg = total/array.length
        return [min, max, avg]
      }
      const [min, max, avg] = minMax(oddsArray)
      let b = geometricProgressionNum(min, max, steps)
      const zip = (a, e) => a.map((k, j) => [k, e[j]])
      let count_elements = [];
      let interval = [[0, b[1]]];
      let initialCount = countElements(
          oddsArray,
          0,
          b[1],
          oddsArray.length
      );
      count_elements.push(initialCount);
      console.log(`Interval 0 to ${b[1]}: ${initialCount} elements`);

      for (let i = 1; i < b.length - 1; i++) {
        let count = countElements(
            oddsArray,
            b[i],
            b[i + 1],
            oddsArray.length
        );
        count_elements.push(count);
        interval.push([b[i], b[i + 1]]);
        console.log(`Interval ${b[i]} to ${b[i+1]}: ${count} elements`);
      }

      return {
        data: zip(interval, count_elements),
        extraInfo: {
          maxOdds: max,
          minOdds: min,
          avgOdds: avg
        }
      }
    },
    generatePredefinedRanges: function (gameType) {
      if (gameType === 'topptipset' || gameType === 'powerplay' || gameType === 'bomben') {
        return [
          [0, 500], [500, 1000], [1000, 2000], [2000, 5000],
          [5000, 10000], [10000, 50000], [50000, 100000], [100000, Infinity]
        ];
      } else {
        return [
          [0, 5000], [5000, 15000], [15000, 50000], [50000, 100000],
          [100000, 250000], [250000, 500000], [500000, 1000000], [1000000, Infinity]
        ];
      }
    },
    generateHistogramPreset: function (gameType) {
      const oddsArray = this._rows.reduce((array, row) => {
        array.push(
            row.reduce((acc, el) => {
              return acc * el.odds
            }, 1)
        )
        return array
      }, [])

      const predefinedRanges = this.generatePredefinedRanges(gameType);

      let count_elements = predefinedRanges.map(range => {
        return countElements(
            oddsArray,
            range[0],
            range[1],
            oddsArray.length
        );
      });

      const minMax = array => {
        let min = Number.MAX_VALUE
        let max = Number.MIN_VALUE
        let total = 0
        array.forEach(value => {
          if (value < min) {
            min = value
          }
          if (value > max) {
            max = value
          }
          total = total + value;
        })
        let avg = total / array.length
        return [min, max, avg]
      }

      const [min, max, avg] = minMax(oddsArray)

      // Log the count for each interval
      predefinedRanges.forEach((range, index) => {
        console.log(`Interval ${range[0]} to ${range[1]}: ${count_elements[index]} elements`);
      });

      return {
        data: predefinedRanges.map((range, index) => [range, count_elements[index]]),
        extraInfo: {
          maxOdds: max,
          minOdds: min,
          avgOdds: avg
        }
      }
    },
    toMathRows: function () {
      let out = []
      let temparray = this.rows
      if (!this.rowCount) {
        return []
      }
      let counts = temparray[0].length
      for (let c = counts - 1; c >= 0; c--) {
        let hashmap = {}
        let j = temparray.length
        out = []
        for (let i = 0; i < j; i++) {
          let arrCopy = [...temparray[i]]
          arrCopy.splice(c, 1)
          let asString = JSON.stringify(arrCopy)
          if (!hashmap[asString]) {
            hashmap[asString] = []
            hashmap[asString].push(i)
          } else {
            hashmap[asString].push(i)
          }
        }
        for (let key in hashmap) {
          let arr = [...temparray[hashmap[key][0]]]
          arr[c] = ''
          for (let idx of hashmap[key]) {
            arr[c] += temparray[idx][c]
          }
          out.push(arr)
        }
        temparray = out
      }
      return temparray
    },
    toMathRowsOld: function () {
      let out = []
      let temparray = this._rows
      let compareAllBut = (n, arr1, arr2) => {
        if (!arr1 || !arr2) {
          return false
        }
        return arr1.reduce((acc, val, idx) => {
          if (idx === n) {
            return acc && true
          }
          return arr1[idx].sign === arr2[idx].sign && acc
        }, true)
      }
      if (!this._rowCount) {
        return []
      }
      let counts = this._rows[0].length
      for (let c = counts - 1; c >= 0; c--) {
        let current
        let j = temparray.length
        out = []
        for (let i = 0; i < j; i++) {
          if (!current) {
            current = temparray[i].map(a => ({ ...a }))
          } else {
            let same = compareAllBut(c, current, temparray[i])
            if (!same) {
              out.push(current)
              current = temparray[i].map(a => ({ ...a }))
            } else {
              current[c].sign += temparray[i][c].sign
            }
          }
          if (i === j - 1) {
            out.push(current)
          }
        }
        temparray = out
      }
      return temparray.map(row => {
        return row.reduce((acc, val) => {
          acc.push(val.sign)
          return acc
        }, [])
      })
    },
    generateSignDistribution: function () {
      if (!this._rowCount) {
        return [];
      }

      let out = Array(this._rows[0].length).fill().map(el => {return {home: 0, draw: 0, away:0}})

      this._rows.forEach((row) => {
        row.forEach((el, i) => {
          switch (el.sign) {
            case "1":
              out[i].home++
              break;
            case "X":
              out[i].draw++
              break;
            case "2":
              out[i].away++
              break;
          }
        })
      })
      
      out.forEach(game => {

        let gameTotal = game.home + game.draw + game.away

        game.home = (game.home/gameTotal);
        game.draw = (game.draw/gameTotal);
        game.away = (game.away/gameTotal);
      })
      return out;

    },
    generateForecast: function (gameType) {
      let part;

      // Using generatePredefinedRanges to set limits
      let limits = this.generatePredefinedRanges(gameType);

      switch (gameType) {
        case "stryktipset":
          part = 0.65 * 0.40;
          break;
        case "topptipset":
          part = 0.70;
          break;
        case "europatipset":
          part = 0.39 * 0.65;
          break;
        case "powerplay":
          part = 0.70;
          break;
      }

      // Generating labels using the new logic
      let labels = limits.map(range => {
        let [startValue, endValue] = range;
        let startString, endString;

        if (startValue >= 1000000) {
          startString = `${(startValue / 1000000).toFixed(0)}M`;
        } else if (startValue >= 1000) {
          startString = `${(startValue / 1000).toFixed(0)}K`;
        } else {
          startString = `${(Math.round(startValue / 100) * 100).toFixed(0)}`;
        }

        if (endValue === Infinity) {
          return `${startString}+`;
        } else if (endValue >= 1000000) {
          endString = `${(endValue / 1000000).toFixed(0)}M`;
        } else if (endValue >= 1000) {
          endString = `${(endValue / 1000).toFixed(0)}K`;
        } else {
          endString = `${(Math.round(endValue / 100) * 100).toFixed(0)}`;
        }

        return startString + ' - ' + endString;
      });

      // Initialize the output array with labels and limits
      let out = labels.map((label, i) => ({
        min: limits[i][0],
        max: limits[i][1],
        count: 0,
        percent: 0,
        label: label
      }));

      // Calculate counts and percentages
      this._rows.forEach(row => {
        let payout = row.reduce((acc, val) => acc * (val.distribution / 100), 1);
        for (let obj of out) {
          if (obj.min < part / payout && part / payout <= obj.max) {
            obj.count++;
          }
        }
      });

      // Set the percentage property
      for (let obj of out) {
        obj.percent = (obj.count / this._rowCount) * 100;
      }

      return out;
    },
    get rows() {
      return this._rows.map(row => {
        return row.reduce((acc, val) => {
          acc.push(val.sign)
          return acc
        }, [])
      })
    },
    get rowCount() {
      return this._rowCount
    },
    get unreducedRowsCount() {
      return this._unreducedRowsCount
    }
  }
}
export default GameReducer

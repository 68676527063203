import React from 'react'
import Tooltip from 'rc-tooltip'
import _ from 'lodash'

import { ToolsHeading } from '../../../../styles/globalStyle'
import { ReductionInner, Sign, Input, Label, Select } from '../ReductionTools.elements'
import { SignReductionContainer } from './SignReduction.elements'

export default function SignReduction({ signReductionState }) {
  //console.log('signReductionState', signReductionState.signReduction.eventLength)

  return (
    <SignReductionContainer>
      <Tooltip
        prefixCls='rToolTip'
        placement='bottom'
        trigger={['hover']}
        animation='anim'
        overlay={
          <>
            <h3>1X2-reducering</h3>
            
            <p>
              Reducera på antal 1, X och 2 på kupongen. Klicka på “drop down”-menyn under Min resp. Max för varje tecken (1X2) och välj antal. Raderna som ej uppfyller villkoret reduceras bort när <strong>REDUCERA</strong>-knappen klickas. Ex: Om minst 2 och max 6 ettor ställs in, så reduceras samtliga rader där antalet ettor ligger utanför det spannet bort.
            </p>
          </>
        }
      >
        <ToolsHeading>
          1X2-reducering <span className='rToolTip-icon'>i</span>
        </ToolsHeading>
      </Tooltip>

      <ReductionInner>
        <Sign>
          <Label>1</Label>
          <div className='break' />
          <Select
            onChange={ev =>
              signReductionState.signReduceFunctions.setMin(
                'home',
                parseInt(ev.target.value)
              )
            }
            value={signReductionState.signReduction.home.min}
          >
            {_.range(0, signReductionState.signReduction.eventLength + 1).map(value => (
              <option key={`homeMin${value}`} value={value}>
                {value}
              </option>
            ))}
          </Select>

          <Select
            onChange={ev =>
              signReductionState.signReduceFunctions.setMax(
                'home',
                parseInt(ev.target.value)
              )
            }
            value={signReductionState.signReduction.home.max}
          >
            {_.range(1, signReductionState.signReduction.eventLength + 1).map(value => (
              <option key={`homeMax${value}`} value={value}>
                {value}
              </option>
            ))}
          </Select>
        </Sign>

        <Sign>
          <Label>X</Label>
          <div className='break' />
          <Select
            onChange={ev =>
              signReductionState.signReduceFunctions.setMin(
                'draw',
                parseInt(ev.target.value)
              )
            }
            value={signReductionState.signReduction.draw.min}
          >
            {_.range(0, signReductionState.signReduction.eventLength + 1).map(value => (
              <option key={`drawMin${value}`} value={value}>
                {value}
              </option>
            ))}
          </Select>

          <Select
            onChange={ev =>
              signReductionState.signReduceFunctions.setMax(
                'draw',
                parseInt(ev.target.value)
              )
            }
            value={signReductionState.signReduction.draw.max}
          >
            {_.range(1, signReductionState.signReduction.eventLength + 1).map(value => (
              <option key={`drawMax${value}`} value={value}>
                {value}
              </option>
            ))}
          </Select>
        </Sign>

        <Sign>
          <Label>2</Label>
          <div className='break' />
          <Select
            onChange={ev =>
              signReductionState.signReduceFunctions.setMin(
                'away',
                parseInt(ev.target.value)
              )
            }
            value={signReductionState.signReduction.away.min}
          >
            {_.range(0, signReductionState.signReduction.eventLength + 1).map(value => (
              <option key={`awayMin${value}`} value={value}>
                {value}
              </option>
            ))}
          </Select>

          <Select
            onChange={ev =>
              signReductionState.signReduceFunctions.setMax(
                'away',
                parseInt(ev.target.value)
              )
            }
            value={signReductionState.signReduction.away.max}
          >
            {_.range(1, signReductionState.signReduction.eventLength + 1).map(value => (
              <option key={`awayMax${value}`} value={value}>
                {value}
              </option>
            ))}
          </Select>
        </Sign>
      </ReductionInner>
    </SignReductionContainer>
  )
}

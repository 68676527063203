import React, { useContext } from 'react'
import { DividedContainer, DividedList, SignWrapper, ListItem } from './Dividend.elements'
import { ToolsHeading } from '../../../../styles/globalStyle'
import { gameTurnoverWithSpace } from '../../../../utils/helperFunctions'
import { GameContext } from '../../../../context/gameContext'


import {
  DividendLastGameContainer,
  DividendSignWrapper,
  DividendSignBox,
  DividendAmountWrapper,
  DividendLastGameHeading,
  DividendLastGameData,
  SignBG
} from '../DividendLastGame/DividendLastGame.elements'

const wrapper = (arr, gameType) => {
  return (
    <DividendSignWrapper>
      <DividendSignBox>
        <SignBG>{arr[0].sign}</SignBG>
      </DividendSignBox>
      <DividendAmountWrapper>
        {arr.map((el, i) => (
          <DividendLastGameData
            key={`dividendLastGameID-${i}`}
            // hasWinners={el.winners}
          >
            <p>{el.name}</p>
            <p>{el.winners} st</p>
            <p>{gameTurnoverWithSpace(gameType === 'powerplay' ? el.odds : el.amount)} kr</p>
          </DividendLastGameData>
        ))}
      </DividendAmountWrapper>
    </DividendSignWrapper>
  )
}

export default function Dividend({ distribution, large }) {
  const gameInfo = useContext(GameContext)
  const [gameType, drawNumber] = [gameInfo.gameType, gameInfo.drawNumber]

  let a1
  let a2
  let a3
  if (distribution?.[0].sign) {
    a1 = distribution?.filter(el => el.sign === '1')
    a2 = distribution?.filter(el => el.sign === 'X')
    a3 = distribution?.filter(el => el.sign === '2')
  }

  const inThrees = (el1, el2, el3) => {
    return (
      <>
        {wrapper(el1, gameType)}
        {wrapper(el2, gameType)}
        {wrapper(el3, gameType)}
      </>
    )
  }

  return (
    <DividedContainer>
      {/* <ToolsHeading>Aktuell utdelning</ToolsHeading> */}
      <DividedList>
        {!distribution ? (
          <p style={{ fontSize: '1.1rem', textAlign: 'center' }}>
            <br />
            Kupong ej startad
          </p>
        ) : null}

        {
          !distribution?.[0].sign
            ? distribution?.map((divide, idx) => (
                <ListItem key={`dividedlistitem-${idx}`}>
                  <p>{divide.name}</p>
                  <p>{divide.winners} st</p>
                  <p>{gameTurnoverWithSpace(gameType === 'powerplay' ? divide.odds : divide.amount)} kr</p>
                </ListItem>
              ))
            : inThrees(a1, a2, a3)

          // distribution?.map((divide, idx) => (
          //     <React.Fragment key={`dividedmultilistitem-${idx}`}>
          //       {!(idx % (large ? 4 : 1)) && <SignWrapper>Tecken {divide.sign}:</SignWrapper>}
          //       <ListItem>
          //         <p>{divide.name}</p>
          //         <p>{divide.winners} st</p>
          //         <p>{gameTurnoverWithSpace(divide.amount)} kr</p>
          //       </ListItem>
          //     </React.Fragment>
          //   ))
        }
      </DividedList>
    </DividedContainer>
  )
}

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import HomeHeader from '../../components/Headers/HomeHeader'
import ActiveGamesBoxes from '../../components/ActiveGamesBoxes/ActiveGamesBoxes'
import Footer from '../../components/Footer/Footer'
import { InnerContainer, PageContent, ArticlePanel } from '../../styles/globalStyle'

export default function GuidePage() {
  const url = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Hur funkar ett reduceringsverktyg? | Reducering.se</title>
        <meta property='og:title' content='Sveriges bästa poolspels-verktyg' />
        <meta property='og:url' content={`https://www.reducering.se${url.pathname}`} />
        <meta
          property='og:description'
          content='Här hittar du info om hur våra reduceringar på Reducering.se funkar och vad du bör tänka på. Bli en bättre spelare med Reducering.se. Läs mer här!'
        />
      </Helmet>
      <HomeHeader />
      <PageContent>
        <ArticlePanel>
          <h3>Hur funkar det?</h3>
          <ol>
            <li>
              Börja med att markera en “grundram” i turkos färg med 1, X och 2 på alla
              matcher - 13 st om Stryk- och Europatipset samt 8 st om Topptipset och
              Powerplay. Nere till höger syns hur många rader ramen (systemet) innehåller
              oreducerat. Dags att reducera!
            </li>

            <li>
              Genom att klicka på “actionknappen”{' '}
              <strong>
                <em>Reducera </em>
              </strong>
              nere till höger syns nu två olika prognoser - totalodds och utdelning.{' '}
              <em>Totaloddsprognosen</em> visar det lägsta resp. högsta oddset för alla
              rader inom systemet och <em>utdelningsprognosen</em> visar den uppskattade
              utdelningen för samtliga rader, baserat på omsättningen och
              streckfördelningen vid den tidpunkten. Dessa prognoser ger en god överblick
              på om systemetgrundens potential och uppbyggnad.
            </li>

            <li>
              Det finns tre olika sätt att reducera på - färg-, 1X2- och poängreducering.{' '}
              <em>1X2-reducering</em> är den simplaste varianten, där ställer man in min
              resp. max antal 1, X och 2 och reducerar bort de raderna som ligger utanför
              valt intervall.
              <br />
              <em>Färgreducering </em>är den vanligaste varianten och det finns fyra olika
              färggrupper att reducera med - blå, gul, röd och grön. Klicka på önskad färg
              för att aktivera och markera de tecken som ska ingå i samma färggrupp. Ställ
              in min resp. max antal för färgen och reducera sedan bort de tecken som
              ligger utanför valda villkor. Det går att använda flera olika färger på ett
              och samma tecken och det går utmärkt att kombinera färgreducering med andra
              reduceringarna.
              <br />
              <em>Poängreducering </em>är den mest avancerade varianten, som dock är
              mycket smart och effektiv om den används på rätt sätt. Klicka på “P” för att
              aktivera och fördela sedan poäng (1-99) på de tecken som ska ingå i
              reduceringsgruppen. Ställ in min resp. max poäng och reducera bort de tecken
              som ligger utanför valda villkor. Det går utmärkt att använda
              poängreducering på samma tecken som är färgreducerat och i kombination med
              1X2-reducering.
            </li>

            <li>
              Nere till höger syns hur många rader som reducerats bort och hur många rader
              som det reducerade systemet innehåller. Notera också teckenfördelningen till
              höger, som i procent visar hur stor del av dina valda tecken som raderna
              fördelar sig på. Denna teckenfördelning syns också i decimalform inne i
              teckenrutorna, allt för att ge en så tydlig överblick som möjligt.
            </li>

            <li>
              Det finns olika typer av hjälpmedel för att underlätta systembyggandet
              förutom de redan nämnda totalodds- och utdelningsprognoserna.
              <ul>
                <li>
                  Varje match är klickbar och länkar rakt in på Svenska Spel och deras
                  information om aktuell match
                </li>

                <li>
                  Inne i “matchrutan” vid varje match hittas svenska folkets
                  streckfördelning i procent och Svenska Spels matchodds - som även går
                  att visa som procent genom att trycka på “Visa odds som %”-knappen
                </li>

                <li>
                  <em>Trender</em> finns tillgängligt från spelstoppsdagen för varje spel
                  och visar streckfördelningen bland svenska folket under valt
                  tidsintervall när det aktiveras. Trendprocenten och en indikator syns då
                  till höger om den totala streckfördelningen inne i “matchrutan” - grönt
                  innebär en högre streckprocent under vald period jämfört med totalt,
                  rött innebär en lägre streckprocent.
                </li>
              </ul>
            </li>

            <li>
              För spelläggare på <em>Svenska Spel Tillsammans</em> finns en smart
              indikator som visar om systemet uppfyller de tekniska villkoren och därmed
              går att lämna in över 2200 rader. Klicka på "Spela Tillsammans"-knappen nere
              till vänster - om grön pil syns är systemet ok att lämna in, och om röd pil
              ned så krävs en ändring i systemet innan ett nytt försök kan göras.
            </li>

            <li>
              Det går att spela med förhöjd radinsats på Topptipset och Powerplay med
              samma summor som man kan välja på inne på Svenska Spel.
            </li>

            <li>
              För spel med mer än 10 kr i radinsats, så finns det en “klona”-knapp som gör
              att systemet dubbleras. Det går att klona systemet upp till 99 gånger.
            </li>

            <li>
              Om behov finns att spara ett påbörjat system till senare, eller kanske om
              man vill dela med en vän, så är det bara att klicka på “spara”-knappen för
              att generera en unik kod som sedan kan laddas upp. Kom ihåg att spara på
              nytt och generera en ny kod om ändringar görs.
            </li>

            <li>
              Om behov finns att radera gjorda reduceringar, men behålla grundramen, så
              finns det även en sådan knapp - klicka då på “Nollställ reducering” nere
              till höger bredvid “Lämna in”-knappen.
            </li>

            <li>
              Sista steget - att lämna in systemet - hittas längst ned till höger, den
              röda “Lämna in”-knappen som slussar användaren vidare direkt in på Svenska
              Spel och lämnar in systemet enligt gjorda reduceringar.
            </li>

            <li>
              Efter inlämning börjar det roliga - jakten på 13 rätt! Det går förstås att
              följa spelet inne på Svenska Spel, men för maximal upplevelse rekommenderas
              liverättningen inne på <em>Reducering.se</em>. Här “plingar” målen in snabbt
              - med eller utan ljud - och det är enkelt och kul att följa utvecklingen för
              antal rätt och utdelning.
            </li>

            <li>
              Om du inte vill använda vår egna liverättning när du rättar ditt system så
              rekommenderar vi Flashscore för statistik, tabeller
              och <a href="https://www.flashscore.se/">fotboll livescore</a>.
            </li>
          </ol>
        </ArticlePanel>
      </PageContent>

      <InnerContainer>
        <ActiveGamesBoxes />
      </InnerContainer>

      <InnerContainer>
        <Footer />
      </InnerContainer>
    </>
  )
}

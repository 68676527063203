import styled from 'styled-components'
import { ResultItemElement } from '../../../../styles/globalStyle'
import {
  breakPoints,
  spacers,
  colors,
  font,
  borderRadius
} from '../../../../styles/constants'

export const SelectableResultItems = styled(ResultItemElement)`
  min-height: 5rem;

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    min-height: 6rem;
  }
`

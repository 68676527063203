import React, { useRef, useEffect, useState, useContext } from 'react'
import {WinSectionContainer} from './WinSection.elements'
import WinImg from '../../../assets/WinSection.png'
import {ReactComponent as WinnerLogo} from '../../../assets/Winner.svg'
import CustomChekbox from '../../CustomCheckbox/CustomChekbox'
import {ReactComponent as Upload} from '../../../assets/save-coupon.svg'
import Loader from '../../Loader/Loader'
import {useHistory} from 'react-router-dom'
import { ActiveGamesContext } from '../../../context/activeGamesContext'

const WinSection = ({
  isEditing,
  lottery,
  setLottery,
  handleUpdateLottery,
  setPhotoLoterry,
  loader,
  errorLottery,
  handleRemove,
  photoLoterry}) => {
  const [isError,setIsError] = useState(null)
  const fileInput = useRef(null)
  const mainText = useRef(null)
  const history = useHistory();
  const MAX_SIZE = 5_242_880;
  const { stryktipset } =
    useContext(ActiveGamesContext);
  const handleClick = () =>{
    fileInput.current.click();
    //setPhotoLoterry()
  }
  const redirectToGame = () =>{
    const [getFirstGame] = stryktipset?.draws;
    const {drawNumber} = getFirstGame || {drawNumber : false};
    if(drawNumber){
      history.push(`/reducera/stryktipset/${drawNumber}`)
    }
  }
  const nodeText = ()=>{
    if(mainText.current) mainText.current.innerHTML = lottery?.text
  }

  useEffect(()=>{
    if(!isEditing && !lottery?.hidden && mainText) {
      nodeText()
      //mainText.current?.innerHTML = lottery?.text
    } else return
  },[lottery,mainText.current,isEditing])

  const handleUploadImg = (e)=>{
    debugger
    let allowedExtension = ['jpeg', 'jpg','png','svg'];
    const image = e.target.files[0];
    const getSizeImg = image?.size;
    const getTypeImg = image?.type?.split('/').pop();
    if(getSizeImg > MAX_SIZE ) {
      return
    }
    if(!allowedExtension.includes(getTypeImg)){
      return
    }
    let imgToBlog = URL.createObjectURL(image)
    setLottery(prev=>({...prev,photo:imgToBlog}))
    setPhotoLoterry(image)
  }

  const handleChange = (e) =>{
    const name = e?.target?.name;
    const value = e?.target?.value;
    setLottery(prev => ({
      ...prev,
      [name]:value
    }))
  }
  if(lottery?.hidden && !isEditing) return null
  return (
    <WinSectionContainer isEditing={isEditing}>
      <div className='wrapper'>
         <div className='info'>
           <>
             {/*<WinnerLogo className ='svg'/>*/}
            {!isEditing ? <h3>{lottery?.title}</h3> :
              <>
              <textarea
                name='title'
                placeholder='Beskrivning'
                onChange={handleChange}
                maxLength={80}
                value={lottery.title}
                className='loterry-title'/>
                <span className='counter'>{lottery.title?.length} /80</span>
                </>
            }
            {!isEditing ?
              (<>
                <div ref={mainText} className='main'>
                </div>
              </>)
              :
              <>
                <textarea
                  name='text'
                  placeholder='Beskrivning'
                  onChange={handleChange}
                  maxLength={1000}
                  value={lottery.text}
                />
                <span className='counter'>{lottery.text?.length} /1000</span>
              </>
            }
            {isEditing && <CustomChekbox
              title='Hidden'
              checked={lottery.hidden}
              onChange={(e)=>setLottery(prev => ({...prev,hidden:!prev.hidden}))}
            />}
            {!isEditing ?
              (<div className='btn-winn'>
                <button onClick={()=>history.push('/tavling')}>LÄS MER</button>
                {/*<button className='delta' onClick={redirectToGame}>delta i sm</button>*/}
              </div>):
              (<div className='btn-group'>
                <button onClick={handleUpdateLottery} className='submit'>Save</button>
                <button onClick={handleRemove} className='cancel'>Cancel</button>
              </div>)}
             {!isError && <p>{isError}</p>}
             {errorLottery && <p className='lottery-error'>
               {errorLottery}
             </p>}
         </>
        </div>
         <div className='img'>
           {isEditing &&
           <input
             accept="image/*"
             ref={fileInput}
             //value={photoLoterry}
             onChange={handleUploadImg}
             style={{display:'none'}}
             type='file' />}
           {isEditing && <div onClick={handleClick} className='overlay-img'>
             <Upload className='upload'/>
           </div>}
           {loader?.img ? <Loader/>: <img src={lottery?.photo }/>}
        </div>
      </div>
    </WinSectionContainer>
  )
}

export default WinSection
import React, {useContext,useState,useRef} from 'react'
import { TeamCreate,TeamCreateWrapper  } from './Popup.elements'
import 'reactjs-popup/dist/index.css';
import {DeletePopup , DeleteContent} from './Popup.elements'


export default function DeleteItemPopup({  isModalOpen, setIsModalOpen,typeDelete }) {


  return (
    <DeletePopup
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false)
      }}
      position='center'
    >
      <DeleteContent>
        <h2>Are you sure you want to delete ?</h2>
        <div>
          <button onClick={()=>typeDelete?.handle(typeDelete?.id)} className='active'>Yes</button>
          <button onClick={() => {
            setIsModalOpen(false)
          }} >No</button>
        </div>
      </DeleteContent>
    </DeletePopup>
  )
}

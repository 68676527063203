import styled from 'styled-components'
import { ToolsPanel } from '../../../../styles/globalStyle'
import {
  colors,
  font,
  breakPoints,
  spacers,
  borderRadius
} from '../../../../styles/constants'

export const DividendForecastContainer = styled.div`
  .toolsHeading {
    @media screen and (max-width: ${breakPoints.large}) {
      font-size: 1.25rem;
    }

    &-forecast {
      margin-right: 0;
      font-size: ${font.size.large};
      line-height: 1.4;
    }
  }

  .popup-content & {
    > * {
      max-width: 30rem;
      margin-left: 0;
      margin-right: auto;
    }

    .toolsHeading {
      font-size: ${font.size.xsmall};
      text-transform: uppercase;

      color: ${colors.white};
      margin-top: 0;

      &-forecast {
        font-size: ${font.size.small};
        color: ${colors.white};
      }
    }

    .dividendList {
      font-size: ${font.size.xsmall};
      line-height: 1.2;

      p {
        margin-bottom: 0;
      }
    }
  }
`

export const DividendList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: ${font.size.medium};
  font-weight: ${font.weight.light};
  color: ${colors.lightGrey};
`

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;

  * {
    flex: 1 0 calc(100% * 1 / 3);

    &:first-child {
      flex: 3rem;
    }

    &:last-child {
      text-align: right;
    }
  }

  span {
    color: ${colors.white};
    font-weight: ${font.weight.semiBold};
  }
`

import React from 'react'
import { removeAffiliate } from '../../utils/helperFunctions'
import { ReductionPopup } from './Popup.elements'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'

const BombenModal = ({open,setOnOpen,variant,setVariant,children,setMessage}) => {
  return (
    <ReductionPopup
      open={open}
      onClose={() => {
        setOnOpen(false)
        setVariant('')
        setMessage('')
      }}
      position='center'
    >
      <>
        <button className='modalClose' onClick={() => setOnOpen(false)}>
          <CloseIcon alt='close' />
        </button>
        {children}
      </>
    </ReductionPopup>
  )
}

export default BombenModal
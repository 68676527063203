export default function Video(props) {
  return (
    <video
      muted={props.muted}
      width='100%'
      height='100%'
      autoPlay={props.autoPlay}
      loop={props.loop}
      playsInline={props.playsInline}
      poster={props.img}
      key={props.id}
    >
      {props.webm && <source src={props.webm} type='video/webm' />}

      {props.mp4 && <source src={props.mp4} type='video/mp4' />}

      {props.alt}
    </video>
  )
}

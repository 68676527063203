import styled from 'styled-components'
import { colors, font } from '../../../../styles/constants'
import { ToolsPanel, CircleBtn } from '../../../../styles/globalStyle'

export const LoadGameContainer = styled(ToolsPanel)`
  flex-direction: row;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`

export const LoadGameInput = styled.input`
  width: 65%;
  background: ${colors.darkGrey2};
  color: ${colors.white};
  font-weight: ${font.weight.bold};

  border: none;
  padding: 0.7rem;
  border-radius: 0.3rem;
  margin-right: auto;
`

export const LoadGameButton = styled(CircleBtn)`
  box-shadow: rgb(0 0 0 / 35%) 0px 3px 13px -2px;

  svg {
    ${({ isLoadingCoupon }) => (isLoadingCoupon && `
      animation: loading 450ms infinite linear;
    `)}
  }
`

import React, { useContext } from 'react'
import {
  ReductionContainer,
  ReductionRow,
  ReductionColumn,
  SpelaTillsammansButton,
  ReducedPercentage,
  AfterReduction,
  ReductionBtn
} from './ShowRows.elements'
import {useHistory} from 'react-router-dom'

import arrowDown from '../../../assets/chevron-down.svg'
import { GameContext } from '../../../context/gameContext'

export default function ShowRows({ 
    isModalOpen, 
    setIsModalOpen,
    reducedRowCount,
    unreducedRowCount,
    loadedId,
    betRowAmount
  }) {
  const history = useHistory();
  const { gameType, drawNumber } = useContext(GameContext)
  return (
    <ReductionContainer>
      <ReductionRow>
        <ReductionColumn>
          <p className='reduction-title'>Oreducerat</p>

          <p className='reduction-info'>
            <span>
              <strong>{unreducedRowCount}</strong> rader
            </span>

            <span>
              <strong>{unreducedRowCount}</strong> kr
            </span>
          </p>
        </ReductionColumn>

        <ReducedPercentage>
          <img src={arrowDown} />

          <div className='reducedAmount'>
            <span className='reducedPercentage'>
              {(100 - (reducedRowCount / unreducedRowCount) * 100).toFixed(0)} %
            </span>

            <span className='reducedNumeral'>
              {reducedRowCount - unreducedRowCount} rader
            </span>
          </div>
        </ReducedPercentage>

        <AfterReduction>
          <p className='reduction-title'>Reducerat</p>

          <p className='reduction-info'>
            <span>
              <strong>{reducedRowCount}</strong> rader
            </span>

            <span>
              <strong>{betRowAmount ?reducedRowCount * betRowAmount : reducedRowCount}</strong> kr
            </span>
          </p>
        </AfterReduction>
        <ReductionBtn liveTo onClick={() => history.push(`/reducera/${gameType}/${drawNumber}${loadedId ? `/${loadedId}` : ""}`)}>
          reducering
        </ReductionBtn>
        <ReductionBtn onClick={() => setIsModalOpen(!isModalOpen)}>
          {isModalOpen ? 'Visa System' : 'Visa Rader'}
        </ReductionBtn>
      </ReductionRow>
    </ReductionContainer>
  )
}

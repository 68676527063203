import React from 'react'

import logoMark from '../../assets/reducering-logomark.svg'
import wordMark from '../../assets/reducering-wordmark.svg'

export default function Logo() {
  return (
    <>
      <div className='reducering-logo'>
        <a href='/' alt='Reducering.se | Start'>
          <img className='reducering-logomark' src={logoMark} alt='Reducering Logomark' />
          <img className='reducering-wordmark' src={wordMark} alt='Reducering Wordmark' />
        </a>
      </div>
    </>
  )
}

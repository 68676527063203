import React, { useState, useEffect, useContext } from 'react'
import { GameResultContainer } from './ResultDetail.elements'
import { GameContext } from '../../../../context/gameContext'
import arrow from '../../../../assets/arrow.png'

export default function ResultDetail({ fcEvent, event , infoForLive , open , setOpen, matchTime }) {
  const { eventNumber, description, isFinished, cancelled, outcome, outcomeScore } =
    fcEvent
  const { gameType } = useContext(GameContext)
  const { sportEventStart, sportEventStatus, participants, distribution } = event
  const [eventTeam , setEventTeam] = useState(null)
  const [eventStart, setEventStart] = useState('')
  const [sportEventStatusAbbrev, setSportEventStatusAbbrev] = useState(null)
  const [updateOutcomeScore, setUpdateOutcomeScore] = useState(outcomeScore)
  const [isScoreUpdated, setIsScoreUpdated] = useState(false)

  useEffect(()=>{
    if(participants?.length){
      let sortParticipants = participants?.sort((home,away)=>{
        if(home?.type !== 'home'){
          return 1
        }else {
          return -1
        }
      });
      setEventTeam(sortParticipants)
    }
  },[participants])

  const handleStatus = () =>{
    if(matchTime === '90+'){
      return ''
    }else if(!matchTime){
      return ''
    } else {
      return `${matchTime}'`
    }
  }
  useEffect(() => {
    const parsedDate = Date.parse(sportEventStart)
    const date = new Date(parsedDate)
    //const eventTime = sportEventStart.slice(11, 16)
    const eventDate = date.getDate()
    const eventMonth = date.getMonth() + 1
    const eventStartDate = `${eventDate}/${eventMonth}`
    const eventStartTime = sportEventStart.slice(11, 16)
    setEventStart(`${eventStartDate} ${eventStartTime}`)
    switch (sportEventStatus) {
      case 'Första halvlek':
        setSportEventStatusAbbrev('')
        break
      case 'Andra halvlek':
        setSportEventStatusAbbrev('')
        break
      case 'Halvtid':
        setSportEventStatusAbbrev('HT')
        break
      case 'Avslutad':
        setSportEventStatusAbbrev('FT')
        break
      case 'Slut efter förlängning':
        setSportEventStatusAbbrev('FT')
        break
      case 'Övertid':
        setSportEventStatusAbbrev('FT')
        break
      case 'Slut efter straffläggning':
        setSportEventStatusAbbrev('FT')
        break
      case 'Slut' :
        setSportEventStatusAbbrev('FT')
        break
      case 'Fulltid' :
        setSportEventStatusAbbrev('FT')
        break
      case 'Inte startat':
        setSportEventStatusAbbrev('')
        break
      default:
        setSportEventStatusAbbrev('')
    }
  }, [sportEventStart, sportEventStatus])

  useEffect(() => {
    setUpdateOutcomeScore(prevState => {
      if (outcomeScore !== prevState) {
        setIsScoreUpdated(true)
        setTimeout(() => setIsScoreUpdated(false), [4000])
        //prevState = outcomeScore
      }
    })
  }, [outcomeScore])

  return (
    <>
      <GameResultContainer
        isFinished={isFinished}
        isScored={isScoreUpdated}
        sportEventStatus={sportEventStatus}
        onClick={()=>setOpen(prev=>!prev)}
        cursor
      >
        {infoForLive &&
        <div className='info'>
          <img className={`nav-img ${open ? 'active' : ''}`} src={arrow} alt='arrow'/>
        </div>}
        <div className='gameStatus'>
          <p className='gameEventNumber'>{eventNumber}</p>

          <p className='gameStatusAbbrev font-size'>{sportEventStatusAbbrev || handleStatus()}</p>
        </div>

        <div className='gameInfo'>
          <p className='gameInfoDate'>
            {eventStart}
            {isFinished && cancelled ? (
              <span className='gameInfoLott show-for-medium position-medium'>Lottad
                {/*<span className='red'>
                 {` ${distribution?.home}-${distribution?.draw}-${distribution?.away} `}
               </span>*/}
              </span>
            ) : null}
          </p>
          <p>
            <a
              href={'https://spela.svenskaspel.se/resultat/' + gameType}
              target='_blank'
              className='gameInfoTeams'
              rel='noopener noreferrer'
            >
              {/* {description} */}
              {eventTeam?.length ? eventTeam[0].name : participants[0].name}
                {' - '}
              {eventTeam?.length ? eventTeam[1].name : participants[1].name}
            </a>
            {isFinished && cancelled ? (
              <span className='gameInfoLott hide-for-medium'>Lottad
                {/*<span className='red'>
                 {` ${distribution?.home}-${distribution?.draw}-${distribution?.away} `}
               </span>*/}
              </span>
            ) : null}
          </p>
        </div>

        <p className='outcomeScore'>{outcomeScore}</p>
        <p className='gameInfoResult'>{outcome}</p>
      </GameResultContainer>
    </>
  )
}

import React, { useState, useContext, useEffect } from 'react'
import { GameContext } from '../../../../context/gameContext'
import { gameTurnoverWithSpace } from '../../../../utils/helperFunctions'
import {
  DividendForecastContainer,
  DividendList,
  ListItem
} from './DividendForecast.elements'
import { ToolsHeading } from '../../../../styles/globalStyle'

export default function DividendForecast({ dividendForecast, betRowAmount }) {
  const { gameType } = useContext(GameContext)
  const [rowsAmount, setRowsAmount] = useState(null)

  //console.log("dividendForecast", dividendForecast);

  const dividendForecaseArr = dividendForecast?.map((el, i) => {
    return {
      correctBet: i,
      winners: el.winners,
      dividend: el.dividend
    }
  })

  //console.log('dividendForecast', dividendForecaseArr)

  const totalsum = dividendForecaseArr?.reduce((acc, el) => {
    return acc + el.winners * el.dividend * betRowAmount
  }, 0)

  const dividendForecastStyle = {
    display: 'flex',
    width: '100%'
  }

  useEffect(() => {
    if (gameType === 'topptipset') {
      setRowsAmount(8)
    } else if (gameType === 'powerplay') {
      setRowsAmount(8)
    } else {
      setRowsAmount(10)
    }
  }, [gameType])

  return (
    <DividendForecastContainer>
      <div style={dividendForecastStyle}>
        {/* <ToolsHeading className='toolsHeading'>Utdelningsprognos system</ToolsHeading> */}

          <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', margin: 'auto'}}>
            <ToolsHeading className='toolsHeading-forecast'>
            {dividendForecaseArr?.reduceRight((acc, el) => (acc || (el.winners ? el.correctBet : acc)), 0)} {"rätt"}
            </ToolsHeading>
           <ToolsHeading className='toolsHeading-forecast'>
            {gameTurnoverWithSpace(totalsum.toFixed(2)) || '0'} kr
          </ToolsHeading>
          </div>
      </div>

      <DividendList className='dividendList'>
        {dividendForecaseArr
          ?.map((dividendObj, idx) => (
            <ListItem key={`dividedforecastlistitem-${idx}`}>
              <p>{dividendObj.correctBet} rätt</p>
              <p>
                {dividendObj.winners === 0 ? (
                  dividendObj.winners
                ) : (
                  <span>{dividendObj.winners}</span>
                )}{' '}
                st
              </p>
              <p>
                {gameTurnoverWithSpace(
                  (dividendObj.winners * dividendObj.dividend * betRowAmount).toFixed(2)
                ) || '0'}{' '}
                kr
              </p>
            </ListItem>
          ))
          .slice(rowsAmount, 14)
          .reverse()}
      </DividendList>
    </DividendForecastContainer>
  )
}

import React, { useContext, useEffect, useState, useRef } from 'react'
import { InnerContainerLarge, ToolsHeading, ToolsSmallHeading } from '../../styles/globalStyle'
import { ReactComponent as LoadingLogo } from '../../assets/reducering-logomark.svg'
import { BombenContainer } from './Bomben.styles'
import Footer from '../../components/Footer/Footer'
import LoaderCoupon from '../../components/Bomben/LoaderCoupon/LoaderCoupon'
import BombenSelect from '../../components/Bomben/BombenSelect/BombenSelect'
import BombenColors from '../../components/Bomben/BombenColors/BombenColors'
import { socket } from '../../context/socket'
import { useParams } from "react-router-dom";
import { ActiveGamesContext } from '../../context/activeGamesContext'
import BombenReduceraRow from '../../components/Bomben/BombenRow/BombenReduceraRow'
import { useAuth0 } from '@auth0/auth0-react'
import { LiveResultContext } from '../../context/liveResultContext'
import BombenLiveGame from '../../components/Bomben/BombenGame/BombenLiveGame'
import RecentEvents from '../../components/LiveResult/ResultWidgets/RecentEvents/RecentEvents'
import GameTypeHeader from '../../components/GameTypeHeader/GameTypeHeader'
import { useHistory } from 'react-router'
import BombenLiveRows from '../../components/Bomben/BombenLiveRows/BombenLiveRows'
import BombenLivePopup from '../../components/Popup/BombenLivePopup'
import _ from 'lodash'
import WinTeam from '../../components/WinTeam/WinTeam'


const BombenLive = () => {
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  let activeGames = useContext(ActiveGamesContext);
  let liveGames = useContext(LiveResultContext)
  const [data, setData] = useState(null);
  const [isFirst, setIsFirst] = useState(true)
  const [saved, setSaved] = useState(null)
  const [history, setHistory] = useState(false)
  const [svenskaMatrix, setSvenskaMatrix] = useState(null)
  const [table,setTable] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [myWin,setMyWin] = useState({max:0,st:0});
  const [totalWin,setTotalWin] = useState([])
  const [win,setWin] = useState([])
  const [gameLive,setGameLive] = useState(false)
  const hist = useHistory()
  const [isZero, setIsZero] = useState(true)
  const [couponInfo, setCouponInfo] = useState({
    cloneCount:0,
    betRowAmount:1,
    currentId:'',
    currentNickname:'',
    saveStatus:undefined,
    isSave:false,
    totalMatches:false,
    totalMatchesLength:false,
    totalRader:0,
  })
  //Winner team state
  const [winnerTotal,setWinnerTotal] = useState({
    min:0,
    max:0,
    values:[],
    possibleValues:0,
    isTouch:{
      min:false,
      max:false
    },
    upload:false,
  })
  //Settings for colors
  const [colorMinMax, setColorMinMax] = useState(
    {
      yellow: {title:'yellow',yellowMin:0,yellowMax:0},
      blue:{ title:'blue',blueMin:0,blueMax:0},
      green:{title:'green',greenMin:0,greenMax:0},
      red:{title:'red',redMin:0,redMax:0},
    }
  )
  const { drawNumber, id } = useParams();
  const gameType = 'bomben'
  // If not live another socket connect
  useEffect(()=>{
    if(!id){
      setHistory(false)
    }
  },[id])
  useEffect(() => {
    const isActive = activeGames[gameType]?.draws?.some((el) => {
      return el.drawNumber === parseInt(drawNumber);
    })
    const isLive = liveGames[gameType]?.draws?.some((el) => {
      return el.drawNumber === parseInt(drawNumber);
    })
    let historyCal = history;
    if(isLive && !gameLive){
      setGameLive(true)
    }else if(!isLive && gameLive){
      setGameLive(false)
    }else{}
    if (isActive || isLive) {
      setHistory(false);
      historyCal = false
    } else {
      setHistory(true);
      historyCal = true
    }
    if (process.env.REACT_APP_NO_BACKEND.toUpperCase() === 'TRUE') {
    } else if (!historyCal) {
      // As soon as component is mounted, do:
      // console.log("emitting JOIN_DATA_CHANNEL")
      socket.emit('JOIN_DATA_CHANNEL', { game: gameType, draw: drawNumber })
      socket.on('data', data => {
        setData(data)
        console.log(data)
      })
    } else {
      socket.emit('REQUEST_SPECIFIC', { game: gameType, draw: drawNumber })
      socket.on('data', data => {
        setData(data)
        console.log(data)
      })
    }
    return () => {
      // Before component is destroyed
      // Unbind all event handlers used in this component
      //console.log(`stop data from channel: ${gameType}/${drawNumber}`)
      socket.emit('LEAVE_DATA_CHANNEL', { game: gameType, draw: drawNumber })
      socket.off('data')
      socket.off('statistic')
    }
  }, [socket, gameType, drawNumber,activeGames,liveGames])

  useEffect(()=>{
    if(saved && saved!== null){
      getResultTable()
    }
  },[saved])

  useEffect(()=>{
    getResultTable()
  },[isZero])
  //update winner team state
  useEffect(()=>{
    if(data?.events?.length > 0 && !winnerTotal.upload){
      //Initial state undefined( its mean no selected users)
      let stateWinner = data?.events?.map(item=>undefined)
      setWinnerTotal(prev=>({...prev,values:stateWinner}))
    }else if(data?.events?.length > 0 && winnerTotal.upload){
      // when we upload coupon
    }
  },[data?.events,winnerTotal.upload]);

  const getResultTable = async () =>{
    if(!id) return
    let token = isAuthenticated ? await getAccessTokenSilently() : null;
    let headers
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else{
      headers = {
        'Content-Type': 'application/json'
      }
    }
    let result = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/bomben-win/${drawNumber}`,
      {
        method:'POST',
        headers:headers,
        body:JSON.stringify({...saved?.data?.couponRawData,isZeroWin:!!isZero})
      }
    )
    if(result.ok){
      let response = await result.json();
      setTable(response?.matches)
    }else{
      // need for error handle
      let response = await result.json();
    }
  }
  const getWinner = async () =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : null;
    let headers
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else{
      headers = {
        'Content-Type': 'application/json'
      }
    }
    let result = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/bomben/${drawNumber}`,
      {
        method:'GET',
        headers:headers,
      }
    )
    if(result.ok){
      let response = await result.json();
      let totalCount = data?.events?.length * 2;
      if(response?.winResult?.length > 0){
        let winCoupon = response?.winResult?.map((coupon,index) =>{
          return {
            ratt:totalCount,
            st:coupon?.winners,
            kr:coupon?.winValue
          }
        })
        setWin(winCoupon)
      }else{
        setWin([])
      }

    }else{
      // need for error handle
      let response = await result.json();

    }
  }
  useEffect(async ()=>{
    if (!id) {
      return;
    } else{
      await fetch(`${process.env.REACT_APP_API_ENDPOINT}/coupon/${id}`, {
        // headers: headers
      })
        .then(response => {
          if (response.status === 404) {
            throw new Error('Not found')
          }
          if (response.status === 403) {
            throw new Error('Not Allowed')
          }
          return response.json()
        })
        .then(data => {
          // check if the loaded coupon matches the game and dn of directed coupon
          if (!(data.data.gameType === gameType && data.data.drawNumber === drawNumber)) {
            //setRedirectHome(true)
            return;
          }
          setSaved(data)
          let total = 0
          if(data?.data?.couponRawData?.matches?.length > 0){
            let totalRader = data?.data?.couponRawData?.matches?.map(event =>{
              return event?.firstTeam?.length * event?.secondTeam?.length
            })
             total = totalRader.reduce((acc, current) => current * acc,1);
          }
          setCouponInfo(prev=>({...prev,
            betRowAmount:data?.data?.betRowAmount,
            totalRader: total
          }))
          updateWinnerTeam(data)
          setColors(data?.data?.couponRawData?.options?.flags)
          getResult(data?.data?.couponRawData)
        })
        .catch(error => {
          console.error(error)
          //setRedirectHome(true)
        })
    }
  },[id])

  //table - /api/bomben-win/draw-number .matches
  //win - api/bomben/:drawNumber .winResult.map(c => {ratt: totalCount, st: c.winners, kr: c.minValue})
  useEffect(()=>{
    if(table?.length > 0 && win?.length > 0){
      //Filter 0 ratt and total not 0 ratt
      let reduceArr = table?.filter(tab=>tab?.ratt > 0)?.reduce((acc,i)=>{
        acc[`${i.ratt}`] = (acc[`${i.ratt}`] || 0) + 1;
        return acc
      },{});
      let toArrayKeys = Object.keys(reduceArr);
      let filterMaxRattWin = table?.filter(item => item?.ratt == toArrayKeys[toArrayKeys?.length - 1]);
      let totalSum = {}
      let totalSelectRatt = win?.map(winItem=>{
        let findInsideSelect = toArrayKeys?.find(objKey =>objKey == (winItem?.ratt / 2));
          if(findInsideSelect){
            totalSum.max = findInsideSelect
          }else{
            let maxRatt = []
            let calculateRatt = filterMaxRattWin?.map(elem =>{
              let scores = elem?.scores
              let chankArr = _.chunk(scores, 2)
              // 3 game = 6 ratt (2 team in 1 game) / win ratt when H1 and B1 is win
              let rattGames = 0
              let calcRatt = chankArr?.forEach(rat=>{
                if((rat[0]?.isWin == true) && (rat[1]?.isWin) == true){
                  rattGames += 1
                }
              })
              maxRatt.push(rattGames)
            })
            let sortMax = maxRatt.sort((a,b)=>a-b)?.reverse()
            totalSum.max = sortMax[0]
          }
        totalSum.st = (totalSum?.st || 0) + reduceArr[findInsideSelect] * parseInt(winItem?.kr)
        return {
            ratt:winItem?.ratt,
            st:findInsideSelect ? reduceArr[findInsideSelect] : 0,
            kr:findInsideSelect ? reduceArr[findInsideSelect] * parseInt(winItem?.kr ): 0
          }
        });
      setMyWin({max:totalSum?.max || 0,st:totalSum?.st || 0})
      setTotalWin(totalSelectRatt)
    }else if(table?.length > 0 && !win?.length > 0){
      setMyWin({max:table[0]?.ratt,st:0})
    }
  },[table,win])
  // If we here some data from socket or etc
  useEffect(()=>{
    if(data !== null && isFirst){
      setIsFirst(false)
      getWinner()
    }
  },[data])

  useEffect(()=>{
    let interval
    if(gameLive){
      interval = setInterval(()=>{
        getWinner()
        getResultTable()
      },2*60*1000)
    }
    return () =>{
      clearInterval(interval)
    }
  },[gameLive])

  const uploadCoupon = async(id) =>{
    if(!id) return
    else{
      await fetch(`${process.env.REACT_APP_API_ENDPOINT}/coupon/${id}`, {
        // headers: headers
      })
        .then(response => {
          if (response.status === 404) {
            throw new Error('Not found')
          }
          if (response.status === 403) {
            throw new Error('Not Allowed')
          }
          return response.json()
        })
        .then(data => {
          // check if the loaded coupon matches the game and dn of directed coupon
          let {drawNumber,gameType} = data.data;
          window.location.href = `${window.location.origin}/liveresults/${gameType}/${drawNumber}/${data.id}`;
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const getResult = async (data) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : null;
    let headers
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else{
      headers = {
        'Content-Type': 'application/json'
      }
    }
    let result = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/bomben`,
      {
        method:'POST',
        headers:headers,
        body:JSON.stringify(data)
      }
    )
    if(result.ok){
      let response = await result.json();
      if(response?.totalMatchesLength > 0 ){
        let totalMatches = response.totalMatches
        setSvenskaMatrix(totalMatches)
        setCouponInfo(prev =>({...prev,
          totalMatches:response.totalMatches,
          totalMatchesLength:response.totalMatchesLength}))
      } else{
        let totalMatches = response.totalMatches
        setSvenskaMatrix(totalMatches)
        setCouponInfo(prev =>({...prev,
          totalMatches:false,
          totalMatchesLength:response.totalMatchesLength}))
      }
    }else{
      // need for error handle
      let response = await result.json();
      console.log(response)
    }
  }
  const setColors = (color) =>{
    let colorUpdate = {
      yellow: {title:'yellow',yellowMin:0,yellowMax:0},
      blue:{ title:'blue',blueMin:0,blueMax:0},
      green:{title:'green',greenMin:0,greenMax:0},
      red:{title:'red',redMin:0,redMax:0},
    }
    color?.forEach(col =>{
      let find = colorUpdate[col.color]
      find[`${col.color}Max`] = col?.max
      find[`${col.color}Min`] = col?.min
      find[`${col.color}Leng`] = col?.max
    })
    setColorMinMax(colorUpdate)
  }
  const updateWinnerTeam = (data) =>{
    let getData = data?.data?.couponRawData?.options?.winnersTotal;
    if(!getData) return
    let getMaxPossibleValue = getData?.values?.filter(win => typeof win === 'string');
    setWinnerTotal(prev=>({...prev,
      isTouch: {
        min:true,
        max:true,
      },
      upload: true,
      values: getData?.values,
      max:getData?.max,
      min:getData?.min,
      possibleValues: getMaxPossibleValue.length}))
  }
  const tooltipPoängreducering = <p>Klicka på “P” för att aktivera Poängreducering och fördela poäng (1-99) på valda tecken i
    “ramen”. Ställ sedan in villkor genom att välja ett Min/Max-värde och klicka på <strong>REDUCERA</strong>
    &nbsp;för att reducera bort de rader som EJ uppfyller villkoret - d.v.s. de rader där totala
    poängsumman på valda tecken antingen ligger under Min- över Max-värdet.</p>
  const tooltipTotalt = <>
    <h3>Totalt antal mål på kupongen</h3>
    <p>Reducera på totalt antal mål i hela omgången (samtliga matcher) genom att välja ett Min-
      och ett Max-värde. Klicka sedan på <strong>REDUCERA</strong>-knappen för att reducera bort de rader som
      EJ uppfyller de valda villkoren.</p>
  </>;
  return (
    <>
      {data ?
      (<>
          <GameTypeHeader
            gameType={gameType}
            gameTurnover={data?.turnover}
            gameCloseTime={data?.closeTime}
            jackpot={data?.jackpotItems?.[0]?.amount}
            extraMoney={parseInt(data?.fund?.extraMoney) > 0 ? parseInt(data?.fund?.extraMoney) + ' kr' : null}
            drawNumber={drawNumber}
            loadedId={id}
            gridTemplate='minmax(70px,1fr) minmax(auto,calc(1366px - 0.3rem * 2)) minmax(70px,1fr)'
        />
        <BombenLivePopup
          open={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        >
        <BombenLiveRows
          table={table?.slice(0, 50)}
          reducera={couponInfo?.totalMatches}
          data={data}
          win={win}
          totalWin={totalWin}
          betRowAmount={couponInfo?.betRowAmount || 1}
          key={`UserRows-LR`}
          toggleUnplayed={isZero}
          setToggleUnplayed={setIsZero}
          isModalOpen={isModalOpen}
          myWin={myWin}
        />
        </BombenLivePopup>
        <BombenContainer live size={data?.latestFive?.length}>
        <InnerContainerLarge>
        <div className='bomben-live'>
        <div className='bomben-live_game'>
        {
          data?.events?.map((event, index) => {
              return (<BombenLiveGame
                eventState={saved?.data?.couponRawData?.matches}
                event={event}
                eventId={index}
                key={index}
                DataForecast={data?.forecast}
                forecast={data?.forecast?.events[index]}
              />)
            }
          )
        }
        </div>
        <LoaderCoupon
          id={id}
          uploadCoupon={uploadCoupon}
          area={true}
        />
        <div className='live-matrix'>
        <BombenLiveRows
          table={table?.slice(0, 10)}
          reducera={couponInfo?.totalMatches}
          data={data}
          betRowAmount={couponInfo?.betRowAmount || 1}
          key={`UserRows-LR`}
          toggleUnplayed={isZero}
          setToggleUnplayed={setIsZero}
          isModalOpen={false}
          win={win}
          totalWin={totalWin}
          myWin={myWin}
        />
        </div>
        <BombenColors
          changeColor={() => {
          }}
          colorMinMax={colorMinMax}
          setColorMinMax={() => {
          }}
          isActive={false}
          removeStatus={setSvenskaMatrix}
          area = 'color'
        />
        <WinTeam
          data={winnerTotal?.values}
          area='win'
          isActive={false}
          possible={winnerTotal.possibleValues}
          min={winnerTotal.min}
          max={winnerTotal.max}
          selectTeam = {()=>{}}
          selectMinMax={()=>{}}
          />
        <BombenSelect
          onChange={() => {
          }}
          valueMin={saved?.data?.couponRawData?.options?.total?.min || ''}
          valueMax={saved?.data?.couponRawData?.options?.total?.max || ''}
          optionMin={['total', 'min', '100']}
          optionMax={['total', 'max', '100']}
          title='Totalt antal mål'
          isActive={false}
          area='first'
          tooltipHtml={tooltipTotalt}
        />
        <BombenSelect
          onChange={() => {
          }}
          valueMin={saved?.data?.couponRawData?.options?.totalCoast?.min || ''}
          valueMax={saved?.data?.couponRawData?.options?.totalCoast?.max || ''}
          optionMin={['totalCoast', 'min']}
          optionMax={['totalCoast', 'max']}
          title='Poängreducering'
          area='total'
          isActive={false}
          isButton={true}
          tooltipHtml={tooltipPoängreducering}
        />
        <RecentEvents
          latestFive={data?.latestFive}
          events={data?.events}
          area='info'
        />
        </div>
        <BombenReduceraRow
          reduced={() => {
          setIsModalOpen(true)
          }}
          isReduced={svenskaMatrix?.length === 0 || svenskaMatrix?.length > 0 || svenskaMatrix !== null}
          reducedRowsCount={couponInfo.totalMatchesLength}
          betRowAmount={couponInfo.betRowAmount}
          totalRader={couponInfo.totalRader || 0}
          type='live'
        />
        <Footer marginBottom={true}/>
        </InnerContainerLarge>
        </BombenContainer>
          </>
        ) : (
          <>
            <div className='loader'>
              <div className='loader-animation' />
              <LoadingLogo />

              <p>Laddar sidan</p>
            </div>
          </>
        )}
    </>
  )
}

export default BombenLive

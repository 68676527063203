import styled, { css } from 'styled-components'
import {} from '../../styles/globalStyle'
import { colors, font } from '../../styles/constants'

export const SliderContainer = styled.div`
  width: 6rem;
  padding-top: 0.6rem;
  padding-bottom: 3.6rem;

  .sliderTooltip {
    width: 4rem;
    text-align: right;
    font-size: ${font.size.small};
    font-weight: ${font.weight.semiBold};
    pointer-events: none;
    //touch-action: pan-y;
  }
`
export const BombenSliderContainer = styled(SliderContainer)`
  width: 100%;
  padding-bottom: 0.6rem;
  ${props => props.isModalOpen && css`
    width: 32rem;
  `}
  ${props => !props.isModalOpen && css`
    @media screen and (max-width: 1520px){
      max-width: 350px;
      width: 100%;
    }
    @media screen and (max-width: 407px){
      max-width: 275px;
      width: 100%;
    }
  `}
   @media screen and (max-width: 407px){
      max-width: 275px;
      width: 100%;
    }
`


export const handleStyle = {
  paddingRight:"5px",
  paddingTop:"2px",
  textAlign: "right",
  fontSize: `${font.size.small}`,
  fontWeight: `${font.weight.semiBold}`,
  height: '1.7rem',
  width: '6rem',
  borderRadius: '0.3rem',
  background: colors.grey,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
  border: 'none',
  clipPath: 'polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)',
  left: '1.25rem'
}

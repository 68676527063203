import React, { useEffect, useState } from 'react'

import { GameArticlePanel, TeamsWrapper, TooltipGame } from './GamePageAboutContent.elements'

import { Helmet } from 'react-helmet-async'
import { startAffiliate } from '../../../utils/helperFunctions'
import moment from 'moment'
import 'moment/locale/sv'
import { useAuth0 } from '@auth0/auth0-react'
import ActiveCreatePopup from '../../../components/Popup/ActiveCreatePopup'
import Autorized from '../../../components/Popup/Autorized'
import Card from '../../../components/Card/Card'
import { Bar, BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Loader from '../../../components/Loader/Loader'

export default function StryktipsetContent() {
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [isLoader,setIsLoader] = useState(false);
  const [uploadPage,setUploadPage] = useState(1);
  const [team, setTeam] = useState(null);
  const [isCanLoad, setIsCanLoad] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [isModalLogin, setIsModalLogin] = useState(false);
  const [turnover, setTurnover] = useState([]);
  const [loaderTurn, setLoaderTurn] = useState(false);
  const [jackpot, setJackpot] = useState([]);
  const [loaderJack, setLoaderJack] = useState(false);
  const [jakpotItem, setJakpotItem] = useState(null)
  const fetchTeam = async (action,pages) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    setIsLoader(true)
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers= {}
    }
    let page
    if(pages){
      page = pages;
      setUploadPage(pages)
    }else{
      page = uploadPage
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/teams?page=${page}&perPage=6&gameType=europatipset`, {
      headers: headers
    }).then(res => {
      return res.json()
    }).then(data => {
      setTimeout(()=>{
        setIsLoader(false)
      },500)
      if(action === 'push'){
        let lenghtNewTeam = data?.teams?.length;
        if(lenghtNewTeam <= 5) setIsCanLoad(false)
        setTeam(prev => ([...prev,...data?.teams]))
      } else {
        setTeam(data?.teams)
      }

      startAffiliate()
    })
  }
  const fetchStrycGrap = async (action) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    setIsLoader(true)
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers= {}
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/charts/turnover?gameType=europatipset&page=1&perPage=40`, {
      headers: headers
    }).then(res => {
      return res.json()
    }).then(data => {
      let items = data?.turnovers?.map((turn,index) => {
        let toNumber = parseInt(turn?.value)
        moment.locale('sv')
        let formDate = moment(turn?.closeTime).format('DD MMM, YYYY')
        return {formDate,pv:toNumber}
      })
      setTurnover(items.reverse())
    }).finally(()=>{
      setLoaderTurn(true)
    })
  }
  const fetchStrycJackpot = async (action) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    setIsLoader(true)
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers= {}
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/charts/jackpot?gameType=europatipset&page=1&perPage=40`, {
      headers: headers
    }).then(res => {
      return res.json()
    }).then(data => {
      let jackPot = {
        '0k-10k':{
          total:0,
          jackPot:[]
        },
        '10k-20k':{
          total:0,
          jackPot:[]
        },
        '20k-50k':{
          total:0,
          jackPot:[]
        },
        '50k-100k':{
          total:0,
          jackPot:[]
        },
        '100k-250k':{
          total:0,
          jackPot:[]
        },
        '250k-500k':{
          total:0,
          jackPot:[]
        },
        '500k-1m':{
          total:0,
          jackPot:[]
        },
        '1m-2m':{
          total:0,
          jackPot:[]
        },
        '2m-10m':{
          total:0,
          jackPot:[]
        },
        '10m+':{
          total:0,
          jackPot:[]
        },
      };
      setJakpotItem(data?.jackpots?.length)
      let items = data?.jackpots?.map(jack =>{
        let amount = parseInt(jack?.forecast?.distribution[0]?.amount);
        if(amount < 10_000){
          jackPot['0k-10k']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        }else if ( amount < 20_000){
          jackPot['10k-20k']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if(amount < 50_000) {
          jackPot['20k-50k']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if(amount < 100_000){
          jackPot['50k-100k']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if (amount < 250_000){
          jackPot['100k-250k']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if (amount < 500_000){
          jackPot['250k-500k']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if (amount < 1_000_000){
          jackPot['500k-1m']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if (amount < 2_000_000){
          jackPot['1m-2m']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else if (amount < 10_000_000){
          jackPot['2m-10m']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        } else {
          jackPot['10m+']?.jackPot.push(jack?.forecast?.distribution[0])
          return 'item'
        }
      })

      let cleanObj = Object.entries(jackPot).map(item =>{
        let leng = item[1].jackPot.length
        //item[1].total = leng;
        item[1].title = item[0]
        if(!leng){
          item[1].total = 0.05
        } else{
          item[1].total = leng
        }
      })
      let jack = []
      let finalObj = Object.keys(jackPot).map(key =>jack.push(jackPot[key]))

      setJackpot(jack)
    }).finally(()=>{
      setLoaderJack(true)
    })
  }
  // Delete item
  const handlDelete = async (e,id) =>{
    e.preventDefault()
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/teams/${id}`,{
      method:'DELETE',
      headers:headers,
    })
    if(response.ok){
      setIsLoader(true)
      await fetchTeam(null,1)
    }
    return response.json();
  }
  // Create team item
  const handleSubmit = async (data={}) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    await setIsLoader(true)
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/teams`,{
      method:'POST',
      headers:headers,
      body:JSON.stringify(data)
    })

    let errorMessage = await response.json()
    return {response,error:errorMessage};

  }
  useEffect(()=>{
    if(!isLoading){
      fetchTeam()
      fetchStrycGrap()
      fetchStrycJackpot()
    }
  },[isLoading])
  const uploadMore = async () =>{
    let pages = uploadPage + 1
    fetchTeam('push',pages)
  }
  const updateTeam = async() =>{
    fetchTeam(null,1)
  }
  const handleFormat = (form,ele) =>{
    if(form>0){
      let isMilion = form >= 1_00_000_0
      let item = form
      if(isMilion){
        let milion = form/1_00_000_0
        //debugger
        milion?.toFixed(1);
        item = milion.toString() + 'm'
      }
      return item
    }  else {
      return form
    }
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if(!payload) return <div></div>
    return (
      <TooltipGame>
        <p className="desc">Antal 13-rättsutdelningar inom intervallet:
          {payload[0]?.payload?.total > 0.9 ? payload[0]?.payload?.total : '0' }</p>
      </TooltipGame>
    );
  }
  const CustomTooltipTur = ({ active, payload, label }) => {
    if(!payload) return <div></div>
    return (
      <TooltipGame size>
        <p className='title'>{payload[0]?.payload?.formDate}</p>
        <p className="desc green">{payload[0]?.payload?.pv?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} kr</p>
      </TooltipGame>
    );
  }
  return (
    <>
      <Helmet>
        <title>Lär dig reducera Europatipset | Reducering.se</title>
        <meta property='og:title' content='Sveriges bästa poolspels-verktyg' />
        <meta
          property='og:description'
          content='Med Reducering.se får du ett komplett verktyg som hjälper dig att reducera Europatipset. Välj bland poäng-, färg- eller 1X2-reduceringar. Läs mer om Europatipset här!'
        />
      </Helmet>
      <ActiveCreatePopup
        isModalOpen={isModal}
        setIsModalOpen={setIsModal}
        Submit={handleSubmit}
        setIsLoader={setIsLoader}
        updateTeam={updateTeam}
      />
      <Autorized
        isModalOpen={isModalLogin}
        setIsModalOpen={setIsModalLogin}
      />
      <TeamsWrapper>
        <Card
          withoutSort ={true}
          isLoader={isLoader}
          setIsLoader = {setIsLoader}
          team={team}
          IsCanLoad={isCanLoad}
          uploadMore={uploadMore}
          handlDelete={handlDelete}
          setIsModal={()=>setIsModal(true)}
          setIsModalLogin={setIsModalLogin}
        />
      </TeamsWrapper>
      <GameArticlePanel id='#vad-ar-europatipset'>
        <h3>Vad är Europatipset?</h3>
        <div className='article-container'>
          <p>
            <em>Europatipset</em> är ett s.k. poolspel på fotboll där många spelare deltar
            och bidrar till vinstpotten med sina insatser. Det är uppbyggd och fungerar på
            samma sätt som klassiska <em>Stryktipset</em> - radpriset är en krona och man
            kan vinna mycket pengar. Spelformen lanserades år 1993, då under namnet{' '}
            <em>Italienska Stryktipset</em> med enbart matcher från det italienska
            seriesystemet och som avgjordes på söndagar.
          </p>
          <p>
            År 2004 bytte spelet namn till <em>Europatipset</em> och precis som namnet
            förkunnar, så är det Europa i fokus med olika matcher från de bästa
            fotbollsligorna - Italienska Serie A, spanska La Liga, tyska Bundesliga och
            franska Ligue 1 - som utgör kupongen. Beroende på säsong så tippar man även på
            engelska matcher och Allsvenskan ibland, samt andra europeiska ligor och olika
            former av landslagsfotboll.
          </p>

          <p>
            Sedan år 2005 går det att spela på <em>Europatipset</em> två gånger i veckan -
            dels en helgomgång på söndagar samt en veckoomgång, oftast på onsdagar.
            Omsättningen per omgång når inte riktigt <em>Stryktipsets</em> nivåer, generellt
            brukar den ligga på ca. 40-50% av <em>Stryktipsets</em> omsättning på helgen och
            ytterligare lite lägre på veckoomgången. Omsättningsrekordet är 20 652 866
            kronor och sattes den 26:e december år 2019.
          </p>
          <h4>Europatipsets utveckling</h4>
          <p>
            Omsättningen på Europatipset beskriver alltså svenska folkets totala insatser per omgång.
            Bilden visar de {turnover?.length}/40 senaste omgångarna. Typen av matcher på kupongerna brukar påverka
            omsättningen liksom när det är JACKPOT på Europatipset .
          </p>
          {loaderTurn ? (<ResponsiveContainer width='100%' height = {160} >
            <LineChart
              data={turnover}
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5
              }}

            >
              <CartesianGrid  vertical={false}  />
              <YAxis interval={0} width={35} tickFormatter={handleFormat} tickMargin={8} stroke="#FFFFFF"/>
              <Tooltip content={<CustomTooltipTur/>}/>
              <XAxis hide dataKey="name" />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#40C7A8"
                strokeWidth={2}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>) : (
            <Loader/>
          )}

        </div>
        <div className='article-container'>
          <h4><span className='size'>65% </span> tillbaka till kunderna</h4>

          <p>
            Sedan år 2010 är återbetalningen 65% på <em>Europatipset</em>. Det innebär
            alltså att Svenska Spel tar 35% av den totala omsättningen varje omgång. Innan
            år 2010 låg återbetalningen på 45% och förhoppningsvis kan Svenska Spel höja
            återbetalningen ytterligare framöver och på så sätt göra produkten ännu mera
            attraktiv.
          </p>
        </div>
      </GameArticlePanel>

      <GameArticlePanel id='#europatipset-sa-fungerar-det'>
        <h3>Europatipset - så fungerar det</h3>
        <div className='article-container'>
        <p>
          <em>Europatipset</em> går ut på att tippa rätt utfall - hemmaseger (1), oavgjort
          (X) eller bortaseger (2) - i 13 utvalda fotbollsmatcher som tillsammans bildar
          en kupong där alla 13 rätt ger högsta vinst. Kupongerna hittas i fysisk form hos
          Svenska Spels ombud eller online på Svenska Spels hemsida, där sistnämnda står
          för den klart största delen av totala omsättningen.
        </p>

        <h4>Så fungerar en enkelrad</h4>

        <p>
          En rad kostar alltså en krona, d.v.s. om man tippar endast ett utfall i samtliga
          13 matcher, så bildas en enda möjlig kombination - en s.k. enkelrad - som
          således endast kostar en krona att lämna in. Minsta tillåtna insats på{' '}
          <em>Europatipset</em> är två betalade rader.
        </p>
        </div>
        <div className='article-container'>
          <h4>Så fungerar hel- och halvgardering</h4>

          <p>
            För att öka chansen att vinna på <em>Europatipset</em>, så kan man använda sig
            utav både s.k. halv- och helgarderingar. En helgardering är, precis som det
            låter, en kombination av samtliga utfall - 1X2 - och tripplar radpriset varje
            gång det används. Halvgarderingar är kombinationer av två utfall i samma match -
            1X, X2 eller 12 - som alla dubblerar radpriset varje gång det används.
          </p>
        </div>
        <div className='article-container'>
        <h4>Att kombinera hel- och halvgarderingar</h4>

        <p>
          Att använda hel- och halvgarderingar ökar förstås chansen till fler antal rätt,
          samtidigt som det kostar mer för kunden att lämna in. Exempel: Om man väljer ett
          singelutfall i åtta av matcherna, halvgarderingar i tre och helgarderingar i två
          så bildar det (1x1x1x1x1x1x1x1x2x2x2x3x3) 72 möjliga kombinationer (rader). Det
          skulle följaktligen kosta 72 kronor att lämna in och benämns som ett systemspel,
          som för oss vidare till det segmenten.
        </p>
        </div>
        <div className='article-container'>
        <h4>Så fungerar systemspel</h4>

        <p>
          Det finns olika typer av systemspel för <em>Europatipset</em> - M-, R- och
          U-system. Exemplet ovan på 72 rader är ett vanligt M-system där M:et står för
          “Matematisk”. Den typen av systemspel fylls i direkt i kupongen och är det
          vanligaste sättet att spela <em>Europatipset</em> på. I en halvgardering (1X som
          exempel) så fördelas då per automatik hälften (50%) av raderna på ena utfallet
          (1) och andra hälften (50%) på det andra utfallet (X). Likväl som raderna per
          automatik fördelas med en tredjedel (33,33%) på 1, X och 2 i en helgardering.
        </p>

        <p>
          Svenska Spel tillhandahåller också 49 färdiga R- och U-system i olika storlekar
          där R:et står för “Reducering” och U:et för “Utgång”.
        </p>
        </div>
        <div className='article-container'>
        <h4>R-system</h4>

        <p>
          I ett R-system minskas slumpvis det antal rader som ett motsvarande matematiskt
          system skulle innehålla. Chansen att vinna blir naturligtvis lägre, men insatsen
          minskar också avsevärt. Systemen har antingen 10-, 11- eller 12-rätts-garanti
          beroende på hur många rader som reduceras bort och rättas med hjälp av en
          nyckeltabell. Något som kan ses som tidskrävande och ett av skälen till att
          R-system inte spelas lika frekvent som andra typer av systemspel.
        </p>
        </div>
        <div className='article-container'>
        <h4>U-system</h4>

        <p>
          I ett U-system kan man till viss del påverka teckenfördelningen på nyckelraderna
          genom att markera utgångstecken på garderingarna, men inte heller U-system står
          numera för någon stor andel av den totala omsättningen. Även om man på ett sätt
          spelar smartare än ett R-system, så lämnas mycket över till slumpen då man inte
          kan bestämma exakt hur raderna ska se ut.
        </p>
        </div>
        <div className='article-container'>
        <h4>Skapa egna system med Reducering.se</h4>

        <p>
          Det smartaste sättet att spela <em>Europatipset</em> på är med hjälp av
          reduceringsverktyget här inne på <em>Reducering.se</em>.{' '}
          <strong>Helt gratis!</strong> Med olika typer av enkla och smarta reduceringar
          kan man då själv bestämma hur många rader som ska reduceras bort från en
          grundrad av singeltecken, halv- och helgarderingar.
        </p>

        <p>
          På så sätt optimerar man systemet och betalar endast för det antalet rader man
          vill - och inget lämnas åt slumpen. Något som i längden kan vara skillnaden på
          vinst och förlust eftersom <em>Europatipset</em> är svårt att vinna på i ett
          långsiktigt perspektiv med tanke på den tidigare nämnda återbetalningsprocenten.
        </p>
        </div>
      </GameArticlePanel>

      <GameArticlePanel id='#europatipset-resultat'>
        <h3>Europatipset - resultat</h3>
        <div className='article-container'>
        <p>
          Efter att man lämnat in spelet, så börjar det roliga - att följa resultaten i de
          13 matcherna och se om det blir vinst. Högsta vinsten utfaller givetvis på alla
          13 rätt, men det kan även bli vinst på 12, 11 och 10 rätt - förutsatt att inte
          finns för många rätta rader.
        </p>

        <p>
          Det blir alltid utdelning på 13 rätt - förutsatt att någon har alla rätt.
          Utdelning på 12 rätt utfaller nästan alltid, men det händer att den blir noll då
          många favoriter vinner. 11 rätt ger oftast vinst, dock ganska blygsamma summor,
          samtidigt som det ganska ofta blir noll kronor på 10:orna.
        </p>
        </div>
        <div className='article-container'>
        <h4>Liverättning</h4>

        <p>
          Det finns olika sätt att följa spelen. Antingen loggar man in på sitt Svenska
          Spel konto, klickar fram spelet under “Mina Spel” och följer målen, resultaten
          och utdelningen där. Ett annat sätt vi varmt rekommenderar är att följa kupongen
          inne på <em>Reducering.se</em> och den enimenta liverättningen där.
        </p>

        <p>
          Där ser man hur målen “plingar” in, hur många rätt man har och hur hög
          utdelningen är, allt snabbt uppdaterat. I den vyn ser man också eventuellt
          matcher som lottas och även resultatet, så snart det är fastställt.
        </p>
        </div>
        <div className='article-container'>
        <h4>Lottade matcher</h4>

        <p>
          Just lottade matcher är något som berör extra och kräver sin förklaring. Om ett
          matchresultat av olika anledningar inte kan fastställas på normalt sätt inom
          omgångens slutdatum (24 timmar efter sista matchen), så fastställs resultatet
          genom lottdragning.
        </p>

        <p>
          På <em>Europatipset</em> använder sig Svenska Spel av en slumptalsgenerator som
          slumpar fram ett nummer mellan 1 och 16. Slumptalsgeneratorn är certifierad
          enligt Spelinspektionens föreskrifter av ett internationellt ackrediterat
          testlaboratorium. Som utgångspunkt finns det två nummer som representerar
          samtliga utfall (1, X och 2) som fördelas 2-2-2 och därefter läggs det till
          nummer beroende på hur Svenska Spels startodds såg ut för aktuell match.
        </p>

        <p>
          Om oddsen t.ex. är 2.60-3.50-2.60 blir det 4-2-4 i fördelning vilket innebär att
          just den matchen lottas med nummer 1-6 som etta, 7-10 som kryss och 11-16 som
          tvåa, eftersom den totala fördelningen då blir 6-4-6.
        </p>

        <p>
          Notera att fr.o.m. april 2020, som följd av Coronakrisen, finns en regel att
          omgången ställs in och alla insatser betalas tillbaka om minst sju matcher
          lottas. Svenska Spel har också infört att resultatet i lottade matcher
          presenteras ca. 45 minuter efter kupongens start.
        </p>
          <p>
            Får ingen 13 rätt eller om det inte blir någon utdelning på 12, 11 eller 10 rätt
            så går den potten vidare till nästa omgång i form av en “jackpot”, som adderas
            till den uppskattade 13-rättspotten. Gränsen för utdelningen ligger på minst 15
            kronor, 14 och nedåt räknas alltså som “ingen utdelning” och noll kr. Om ingen
            får 13 rätt går just den potten vidare till nästa omgång. Samma sak om ingen
            heller skulle få 12 rätt osv., men det är något som inträffar väldigt sällan.
          </p>
        </div>
        <div className='article-container'>
        <h4>Jackpot</h4>
          <p>
            Att det kan vara varierande utdelning på Europatipset är allmänt känt, men hur ser
            fördelningen ut? På bilden ser vi antal omgångar där utdelningen på 13 rätt hamnat i de
            olika intervallen, allt från låg utdelning till riktiga högvinster. Diagrammet visar de {jakpotItem}/40 senaste
            omgångarna av Europatipset.
          </p>
          {loaderJack ?(<ResponsiveContainer width='100%' height = {170} >
            <BarChart
              data={jackpot}
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5
              }}

            >
              <CartesianGrid  vertical={false}  />
              <YAxis width={20}  tickMargin={8} stroke="#FFFFFF"/>
              <Tooltip content={<CustomTooltip />} cursor={{ fill:'#596E79' }}  />
              <XAxis  stroke="#FFFFFF" dataKey="title" />
              <Bar
                type="monotone"
                dataKey="total"
                fill ="#40C7A8"
                stroke="#40C7A8"
                strokeWidth={2}
              />
            </BarChart >
          </ResponsiveContainer>) :(
            <Loader/>
          )
          }
        </div>
      </GameArticlePanel>

      <GameArticlePanel id='#europatipset-tips-fran-coachen'>
        <h3>Europatipset - “tips från coachen”</h3>
        <div className='article-container'>
        <p className='preamble'>
          Att lyckas på <em>Europatipset</em> kräver en hel del. Spelformen har mycket hög
          varians vilket enkelt uttryckt betyder att det kan gå väldigt långt tid mellan
          de betydande vinsterna - även för de som anses vara experter. Nedan några
          viktiga punkter - “tips från coachen” - för att lyckas bättre.
        </p>

        <h4>Strategi/Spelplan</h4>

        <p>
          Lägg upp en strategi, en plan, på spelandet. För hur mycket? Hur ofta? Spela
          ensam eller tillsammans med andra? Offensivt? Defensivt? En plan är bättre än
          ingen plan och glöm inte att inkludera BRM (Bank Roll Management).
        </p>
        </div>
        <div className='article-container'>
        <h4>Ha tålamod</h4>

        <p>
          Det gäller att hålla huvudet kallt - både i bra och dåliga perioder. Det är
          viktigt att hålla sig till planen - vindarna vänder alltid och det kan gå långt
          mellan vinsterna.
        </p>
        </div>
        <div className='article-container'>
        <h4>Spela reducerat</h4>

        <p>
          Spela smart och effektivt, d.v.s. betala inte för mycket för osannolika eller
          rader utan värde. Försök vikta insatserna på raderna så mycket som möjligt. Med
          hjälp av <em>Reducering.se:s</em> reduceringsverktyg kan man enkelt forma
          systemet precis som man vill. En i längden stor fördel jämfört med t.ex. vanliga
          matematiska garderingar som alltid är viktat 50-50% på en halv- och
          33.3-33.3-33.3% på en helgardering.
        </p>
        </div>
        <div className='article-container'>
        <h4>Snåla inte med tecken</h4>

        <p>
          Det är svårt att få 13 rätt och ju fler rader som spelas desto mer minskas
          “slumpfaktorn”. Snåla därför inte med tecken, men givetvis bara om budgeten
          tillåter. Beroende på vem man frågar kommer svaret att variera, men för att
          minska slumpfaktorn bör systemet vara på minst ett par tusen rader. Givetvis
          vinner många även på mindre system, ibland på riktigt små system för under 100
          kronor.
        </p>
        </div>
        <div className='article-container'>
        <h4>Det är kul att spela Tillsammans</h4>

        <p>
          Ett enkelt sätt att kunna spela större system är att spela tillsammans -
          antingen privat med vänner eller i arrangerade andelsspel. Vinstchansen ökar
          rejält och även om den egna delen av vinstkakan blir mindre, så är det
          troligtvis ett smartare sätt att spela på långsiktigt - om man inte själv har
          kunskapen eller tiden. Inne på <em>Spelarna.se</em> hittas andelsspel av både
          Oskar Block och Daniel “Striker” Domeij - två erkänt duktiga poolspelare.
        </p>
        </div>
        <div className='article-container'>
        <h4>Tänk på streckvärdet</h4>

        <p>
          Utdelningen baseras som sagt på utfallet i samtliga 13 matcher och hur många
          rätta rader det finns. Streckfördelningen i varje match är därför en viktig
          parameter - den visar hur stor del av omsättningen som ligger på 1, X samt 2 i
          varje match. Streckfördelningen bland svenska folket och lagens egentliga
          vinstchans är dock två vitt skilda saker, så därför är det viktigt att jämföra
          dessa värden för att hitta eventuella “streckvärden”.
        </p>

        <p>
          Exempel: Tottenham-Arsenal har 60-22-18% i streckfördelning samtidigt som
          oddsmarknaden visar 2.20-3.45-3.45 på samma match, d.v.s. ca 44-28-28 omräknat
          till sannolikheterna i procentform. Det innebär alltså att ettan på Tottenham är
          väldigt “överstreckad” om man utgår från att oddsen är korrekta - vilket de i
          längden troligtvis är jämfört med svenska folkets samlade åsikt. Det innebär
          också att X och framförallt 2 har ett fint streckvärde och kan således vara värt
          fler rader jämfört med 1.
        </p>

        <p>
          Tänk dock på att ju fler som använder sig av samma taktik, desto större
          sannolikhet att fler hittar samma rader, vilket gör att utdelningen blir lägre.
          Det gäller därför att kunna särskilja sig från olika typer av “spelartyper”,
          t.ex. småspelaren som ofta bara tippar vad de tror om matcherna och
          storspelaren, som ofta spelar betydligt mera strategiskt med streckvärdet i
          fokus. En mix är förmodligen det optimala i ett långt perspektiv.
        </p>
        </div>
        <div className='article-container'>
        <h4>Läs på och häng med</h4>

        <p>
          Det skadar givetvis inte att läsa på, plugga, inför matcherna.{' '}
          <em>“Den som vet mest vinner mest”</em> är en gammal Oddset-slogan som Svenska
          Spel använt sig av och säger en del om sanningen. Det finns en uppsjö av sidor
          att hitta information på och experter att ta hjälp av. Om inte annat kan det
          vara bra att veta vilka lag som kanske får extra mycket uppmärksamhet tack vare
          media - samla på så mycket information som möjligt. Kanske kan det vara läge att
          gå mot “massan”? Försök även se matcher och följ med i hur oddsen ligger för
          olika lag - det kan också ge fördelar.
        </p>
        </div>
      </GameArticlePanel>

      <GameArticlePanel id='#europatipset-vinstutdelning'>
        <h3>Europatipset - vinstutdelning</h3>
        <div className='article-container'>
        <p>
          Återbetalningen på <em>Europatipset</em> är alltså 65% av den totala
          omsättningen och vinst utfaller på 13 rätt ned till 10 rätt, beroende på hur de
          rätta raderna är fördelade.
        </p>

        <p>
          Av de 65%:en utfaller 39% till 13 rätt, 22% till 12 rätt, 12% till 11 rätt och
          25% till 10 rätt. Den uppmärksamme ser att de summorna bara uppgår till 98%, det
          beror på att 2% går till en s.k. rullpottsfond som används till att “boosta”
          potter vid några olika omgångar per år.
        </p>

        <p>
          Den hittills högsta vinsten på <em>Europatipset</em> är från 1:a november år
          2020 och uppgick till totalt 5 707 925 kronor där utdelningen på 13 rätt var 4
          717 156 kr.
        </p>
        </div>
      </GameArticlePanel>
    </>
  )
}

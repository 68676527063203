import styled from 'styled-components'
import { GameItemElement } from '../../../../styles/globalStyle'
import {
  breakPoints,
  spacers,
  colors,
  font,
  borderRadius
} from '../../../../styles/constants'

export const SelectableGameItems = styled(GameItemElement)`
  min-height: 5rem;

  @media screen and (min-width: ${breakPoints.xlarge}) {
    min-height: 7rem;
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    min-height: 7.5rem;
  }

  @media screen and (max-width: ${breakPoints.smallMax}) {
    min-height: 6rem;
  }
`

export const GameItemsWholeGuardRow = styled(GameItemElement)`
  border-top-right-radius: ${borderRadius.xsmall};
  border-bottom-right-radius: ${borderRadius.xsmall};
  overflow: hidden;
  width: 1.3rem;

  > * {
    background-color: ${colors.grey};
    align-items: center;
    display: flex;

    padding-left: ${spacers.xxsmall};
    padding-right: ${spacers.xxsmall};

    color: ${colors.lightGrey};
    font-weight: ${font.weight.bold};
    font-size: ${font.size.small};
    justify-content: center;
    cursor: pointer;

    > svg {
      width: 1rem;
      height: 1rem;
      transform: rotate(90deg);
      path {
        fill: #fff;
      }
    }
  }
`

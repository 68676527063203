import React from 'react'
import {TeamContainerSmall} from './User.elements'
import moment from 'moment'
import svsPOwerplay from '../../assets/Powerplay-blog.png'
import svsTop from '../../assets/Topptipset-blog.png'
import svsStryk from '../../assets/Stryktipset-blog.png'
import svsEuro from '../../assets/Europtipset.png'
import svenskaSpelLogo from '../../assets/svenskaspel-logomark.svg'
const game = [{
  game:'powerplay',
  img:svsPOwerplay,
  color:'#a00814'
},
  {
    game:'topptipset',
    img:svsTop,
    color:'#f29400'
  },
  {
    game:'stryktipset',
    img:svsStryk,
    color:'#043266'
  },
  {
    game: 'europatipset',
    img:svsEuro,
    color:'#008733'
  }]


const TeamSmall = ({team}) => {
  const whatGame = (gameType) => {
    const picture = game.find(game => game.game === gameType)
    return picture
  }
  const historyPush = ()=>{
    window.open(team.link, '_blank')?.focus();
  }
  return (
    <TeamContainerSmall>
        <div style={{background:`${whatGame(team?.gameType)?.color}`}} className='head'>
          <img className='svenska' src={svenskaSpelLogo}/>
          <span className='game-type'>{team?.gameType}</span>
        </div>
        <div className='body'>
          <div>
            <a href={team.link} className='title'>{team?.title}</a>
            <p className='time'>{moment(team?.date).format('MM/DD (HH:mm)')}</p>
          </div>
          <div>
            <span className='price'>{team?.price} kr</span>
          </div>
        </div>
    </TeamContainerSmall>
  )
}

export default TeamSmall
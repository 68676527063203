import React from 'react'
import HomeHeader from '../../components/Headers/HomeHeader'
import ActiveGamesBoxes from '../../components/ActiveGamesBoxes/ActiveGamesBoxes'
import HowItWorksPanel from '../../components/HowItWorksPanel/HowItWorksPanel'
import Footer from '../../components/Footer/Footer'
import {
  InnerContainer,
  OneHalf,
  PageContent,
  ArticlePanel
} from '../../styles/globalStyle'

export default function GdprPage() {
  return (
    <>
      <HomeHeader />
      <PageContent>
        <OneHalf>
          <ArticlePanel>
            <h3>Cookiepolicy för Reducering.se</h3>

            <p className='preamble'>
              Detta är cookie policyn för Reducering.se, tillgänglig från
              http://reducering.se/
            </p>

            <h4>Vad är kakor</h4>

            <p>
              Som vanligt med nästan alla professionella webbplatser använder denna
              webbplats cookies, som är små filer som laddas ner till din dator, för att
              förbättra din upplevelse. Denna sida beskriver vilken information de samlar
              in, hur vi använder den och varför vi ibland behöver lagra dessa cookies. Vi
              kommer också att dela med dig av hur du kan förhindra att dessa cookies
              lagras men detta kan nedgradera eller "bryta" vissa delar av webbplatsens
              funktionalitet.
            </p>

            <h4>Hur vi använder kakor</h4>

            <p>
              Vi använder cookies av olika skäl som beskrivs nedan. Tyvärr finns det i de
              flesta fall inga branschstandardalternativ för att inaktivera kakor utan att
              helt inaktivera funktionaliteten och funktionerna de lägger till på denna
              webbplats. Det rekommenderas att du lämnar alla cookies om du inte är säker
              på om du behöver dem eller inte om de används för att tillhandahålla en
              tjänst som du använder.
            </p>

            <h4>Inaktivera kakor</h4>

            <p>
              Du kan förhindra inställning av cookies genom att justera inställningarna i
              din webbläsare (se webbläsarens hjälp för hur du gör detta). Var medveten om
              att inaktivera cookies kommer att påverka funktionaliteten hos denna och
              många andra webbplatser som du besöker. Inaktivera cookies kommer vanligtvis
              att leda till att vissa funktioner och funktioner på denna webbplats
              inaktiveras. Därför rekommenderas att du inte inaktiverar cookies. Denna
              cookiepolicy skapades med hjälp av Cookies Policy Generator från
              CookiePolicyGenerator.com.
            </p>

            <h4>De kakor vi sätter</h4>

            <p>
              <strong>Kontorelaterade cookies</strong>
              <br />
              Om du skapar ett konto hos oss kommer vi att använda cookies för hantering
              av registreringsprocessen och allmän administration. Dessa cookies raderas
              vanligtvis när du loggar ut, men i vissa fall kan de finnas kvar efteråt för
              att komma ihåg dina webbplatsinställningar när du loggar ut.
            </p>

            <p>
              <strong>Inloggningsrelaterade cookies</strong>
              <br />
              Vi använder cookies när du är inloggad så att vi kommer ihåg detta faktum.
              Detta förhindrar att du måste logga in varje gång du besöker en ny sida.
              Dessa cookies tas vanligtvis bort eller rensas när du loggar ut för att
              säkerställa att du bara kan komma åt begränsade funktioner och områden när
              du är inloggad.
            </p>

            <p>
              <strong>E-postrelaterade cookies</strong>
              <br />
              Denna webbplats erbjuder nyhetsbrev eller e-posttjänster och cookies kan
              användas för att komma ihåg om du redan är registrerad och om du vill visa
              vissa meddelanden som bara kan vara giltiga för användare som inte
              prenumererar på.
            </p>

            <p>
              <strong>Tredjepartscookies</strong>
              <br />I vissa speciella fall använder vi också cookies från betrodda tredje
              parter. Följande avsnitt beskriver vilka tredjepartscookies du kan stöta på
              via den här webbplatsen.
            </p>

            <p>
              Den här webbplatsen använder Google Analytics, som är en av de mest utbredda
              och pålitliga analyslösningarna på webben för att hjälpa oss att förstå hur
              du använder webbplatsen och hur vi kan förbättra din upplevelse. Dessa
              cookies kan spåra saker som hur länge du spenderar på webbplatsen och de
              sidor du besöker så att vi kan fortsätta att producera engagerande innehåll.
            </p>

            <h4>
              Mer information om Google Analytics-cookies finns på den officiella Google
              Analytics-sidan.
            </h4>

            <p>
              Flera partners annonserar för våra räkning och spårningskakor för affiliates
              gör det enkelt för oss att se om våra kunder har kommit till webbplatsen via
              en av våra partnersidor så att vi kan kreditera dem på lämpligt sätt och i
              förekommande fall tillåta våra partner att erbjuda någon bonus ge dig för
              att göra ett köp.
            </p>

            <h4>Mer information</h4>

            <p>
              Förhoppningsvis har det klargjort saker för dig och som tidigare nämnts om
              det finns något som du inte är säker på om du behöver eller inte är det
              vanligtvis säkrare att lämna cookies aktiverade om det interagerar med en av
              funktionerna du använder på vår webbplats.
            </p>

            <p>För mer allmän information om kakor, läs "Vad är kakor".</p>

            <p>
              Men om du fortfarande letar efter mer information kan du kontakta oss via en
              av våra föredragna kontaktmetoder:
            </p>

            <p>
              E-post: <a href='mailto:kontakt@reducering.se'>kontakt@reducering.se</a>
            </p>

            <br />
            <hr />

            <h4>Sekretesspolicy för Reducering.se</h4>

            <p>
              På Reducering.se, tillgängligt från http://reducering.se/, är en av våra
              viktigaste prioriteringar våra besökares integritet. Detta sekretesspolicy
              innehåller information som samlas in och registreras av Reducering.se och
              hur vi använder den.
            </p>

            <p>
              Om du har ytterligare frågor eller behöver mer information om vår
              integritetspolicy, tveka inte att kontakta oss.
            </p>

            <h4>General Data Protection Regulation (GDPR)</h4>

            <p className='preamble'>Vi är dataansvarig för din information.</p>

            <p>
              Reducering.se rättsliga grund för att samla in och använda den personliga
              information som beskrivs i denna sekretesspolicy beror på den personliga
              informationen vi samlar in och det specifika sammanhang där vi samlar in
              informationen:
            </p>

            <p>
              Reducering.se måste utföra ett kontrakt med dig
              <br />
              Du har gett Reducering.se tillstånd att göra det
              <br />
              Att behandla din personliga information ligger i Reducering.se legitima
              intressen Reducering.se måste följa lagen
              <br />
              Reducering.se behåller endast din personliga information så länge som är
              nödvändigt för de ändamål som anges i denna sekretesspolicy. Vi kommer att
              behålla och använda din information i den utsträckning som är nödvändig för
              att följa våra juridiska skyldigheter, lösa tvister och genomdriva vår
              policy.
            </p>

            <p>
              Om du är bosatt i Europeiska ekonomiska samarbetsområdet (EES) har du vissa
              dataskyddsrättigheter. Om du vill få information om vilken personlig
              information vi har om dig och om du vill att den ska tas bort från våra
              system, vänligen kontakta oss.
            </p>

            <p>
              Under vissa omständigheter har du följande dataskyddsrättigheter:
              <br />
              Rätten att få tillgång till, uppdatera eller ta bort den information vi har
              om dig. Rätten till rättelse.
              <br />
              Rätten att invända.
              <br />
              Rätten till begränsning.
              <br />
              Rätten till dataportabilitet
              <br />
              Rätten att återkalla samtycke
              <br />
              Loggfiler
              <br />
              Reducering.se följer en standardprocedur för användning av loggfiler. Dessa
              filer loggar besökare när de besöker webbplatser. Alla värdföretag gör detta
              och en del av värdtjänstens analys. Informationen som samlas in av loggfiler
              inkluderar IP-adresser (Internet Protocol), webbläsartyp, Internetleverantör
              (ISP), datum- och tidsstämpel, hänvisnings- / utgångssidor och eventuellt
              antalet klick. Dessa är inte länkade till någon information som är
              personligt identifierbar. Syftet med informationen är att analysera trender,
              administrera webbplatsen, spåra användarnas rörelse på webbplatsen och samla
              demografisk information.
            </p>

            <h4>Kakor och webbfyrar</h4>

            <p>
              Liksom alla andra webbplatser använder Reducering.se 'cookies'. Dessa
              cookies används för att lagra information inklusive besökares preferenser
              och sidorna på webbplatsen som besökaren besökte eller besökte.
              Informationen används för att optimera användarnas upplevelse genom att
              anpassa vårt webbinnehåll baserat på besökarnas webbläsartyp och / eller
              annan information.
            </p>

            <p>För mer allmän information om kakor, läs "Vad är kakor".</p>

            <h4>Sekretesspolicy</h4>

            <p>
              Du kan läsa den här listan för att hitta sekretesspolicyn för var och en av
              annonseringspartnerna på Reducering.se.
            </p>

            <p>
              Tredjeparts annonsservrar eller annonsnätverk använder tekniker som cookies,
              JavaScript eller Web Beacons som används i deras respektive annonser och
              länkar som visas på Reducering.se, som skickas direkt till användarens
              webbläsare. De får automatiskt din IP-adress när detta inträffar. Dessa
              tekniker används för att mäta effektiviteten i deras reklamkampanjer och /
              eller för att anpassa det reklaminnehåll som du ser på webbplatser som du
              besöker.
            </p>

            <p>
              Observera att Reducering.se inte har tillgång till eller kontroll över dessa
              cookies som används av tredjepartsannonsörer.
            </p>

            <h4>Sekretesspolicy från tredje part</h4>

            <p>
              Reducering.se's integritetspolicy gäller inte andra annonsörer eller
              webbplatser. Därför råder vi dig att konsultera respektive sekretesspolicy
              för dessa tredjeparts annonsservrar för mer detaljerad information. Det kan
              innehålla deras praxis och instruktioner om hur man väljer bort vissa
              alternativ.
            </p>

            <p>
              Du kan välja att inaktivera cookies via dina individuella
              webbläsaralternativ. Om du vill veta mer detaljerad information om
              cookiehantering med specifika webbläsare kan du hitta den på webbläsarnas
              respektive webbplatser.
            </p>

            <h4>Barninformation</h4>

            <p>
              En annan del av vår prioritering är att lägga till skydd för barn när de
              använder internet. Vi uppmuntrar föräldrar och vårdnadshavare att observera,
              delta i och / eller övervaka och vägleda deras online-aktivitet.
            </p>

            <p>
              Reducering.se samlar inte medvetet någon personlig identifierbar information
              från barn under 13 år. Om du tror att ditt barn tillhandahöll denna typ av
              information på vår webbplats, uppmuntrar vi dig att kontakta oss omedelbart
              och vi kommer att göra vårt bästa för att omedelbart ta bort sådan
              information från våra register.
            </p>

            <h4>Endast integritetspolicy online</h4>

            <p>
              Vår sekretesspolicy gäller endast våra onlineaktiviteter och är giltig för
              besökare på vår webbplats med avseende på den information som de delade och
              / eller samlar in på Reducering.se. Denna policy är inte tillämplig på en
            </p>
          </ArticlePanel>
        </OneHalf>

        <OneHalf>
          <HowItWorksPanel />
        </OneHalf>
      </PageContent>

      <InnerContainer>
        <ActiveGamesBoxes />
      </InnerContainer>

      <InnerContainer>
        <Footer />
      </InnerContainer>
    </>
  )
}

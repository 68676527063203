import styled from 'styled-components'
import {
  borderRadius,
  breakPoints,
  colors,
  font,
  spacers
} from '../../../../styles/constants'
import { CircleBtn } from '../../../../styles/globalStyle'

export const ColorGrid = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-end;
  ${props => props.bg};
  padding: ${spacers.medium} ${spacers.large};
  ${({ isOutcome }) =>
    isOutcome &&
    `box-shadow:
    inset 0 0 0 1px ${colors.svenskaSpelRed},
    inset 0 0 0 4px ${colors.white};
  border-radius: ${borderRadius.small};
  overflow: hidden;`};

  @media screen and (max-width: ${breakPoints.xlargeMax}) {
    padding: ${spacers.xsmall} ${spacers.small};
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    align-items: center;
  }
`

export const SelectButton = styled.button`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  border: none;
  background-color: transparent;
  color: ${colors.white};

  @media screen and (max-width: ${breakPoints.xlargeMax}) {
    align-items: center;
    height: 100%;
  }

  > * {
    margin-bottom: 0.1rem;
    &:not(span) {
      font-size: ${font.size.xsmall};
      font-weight: ${font.weight.bold};
      color: ${({ isSelected }) => (isSelected ? colors.darkGrey : colors.white)};
    }
  }

  span {
    width: 2.6rem;
    height: 2.6rem;
    font-size: ${font.size.small};

    @media screen and (max-width: ${breakPoints.xlargeMax}) {
      width: 2.2rem;
      height: 2.2rem;
      margin: 0.25rem;
    }
  }
`

export const SelectButtonInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: ${breakPoints.xlargeMax}) {
    flex-direction: column;
    height: 100%;
  }

  @media screen and (max-width: ${breakPoints.smallMax}) {
    flex-direction: column;
    height: initial;
  }
`

export const Circle = styled(CircleBtn).attrs({ as: 'span' })`
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${font.size.medium};
  font-weight: ${font.weight.bold};
  color: ${({ isSelected, points }) =>
    isSelected && points ? colors.white : colors.red};

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    font-size: ${font.size.large};
  }

  @media screen and (max-width: ${breakPoints.smallMax}) {
    margin-bottom: 0.5rem !important;
  }
`

export const SignDistribution = styled.div``

export const Distribution = styled.div``

export const Odds = styled.div``

export const DistributionOdds = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  text-align: right;

  @media screen and (max-width: ${breakPoints.xlargeMax}) {
    height: initial;
  }
`

export const PointInput = styled.input`
  position: absolute;
  z-index: 0;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
  background-color: ${colors.overlayBlack};
  border: 0.3rem solid ${colors.vividCyan};
  font-size: inherit;
  font-weight: inherit;
  text-align: center;
  color: ${colors.white};
  pointer-events: auto;

  &[type='number'] {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      opacity: 1;
      margin-right: -0.3rem;
    }
  }

  transition: all 0.3s;
  transform: scale(0);
  transform-origin: center;

  &:focus,
  &:active {
    outline: none;
    transform: scale(1);
  }
  &::placeholder {
    color: white;
  }
`

import React, { useState, useEffect, useContext } from 'react';
import { SocketContext } from '../../context/socket';
import { GameContext } from '../../context/gameContext';

import { LiveResultContainer } from './LiveResultPage.elements';
import { InnerContainerLarge } from '../../styles/globalStyle';
import LiveResult from '../../components/LiveResult/LiveResult';
import Footer from '../../components/Footer/Footer';

import { ReactComponent as LoadingLogo } from '../../assets/reducering-logomark.svg';

export default function LiveResultPage({ loadedData, id, historicLoad }) {
  const socket = useContext(SocketContext);
  const gameInfo = useContext(GameContext);
  const [gameType, drawNumber] = [gameInfo.gameType, gameInfo.drawNumber];

  const [lrData, setLrData] = useState(null);
  const [infoLive, setInfoLive] = useState(null);

  useEffect(() => {
    if (!historicLoad) {
      console.log('emitting JOIN_DATA_CHANNEL from live');
      socket.on('data', (data) => {
        console.log('setting LR data', data.forecast);
        setLrData(data);
      });
      socket.emit('JOIN_DATA_CHANNEL', { game: gameType, draw: drawNumber });

      fetch(`${process.env.REACT_APP_API_ENDPOINT}/statistics?drawNumber=${drawNumber}&gameType=${gameType}`)
          .then((res) => {
            if (res.ok) return res.json();
          })
          .then((data) => {
            if (data) setInfoLive(data.events);
          });
      socket.on('statistic', (data) => {
        console.log('Statistic socket live', data);
        setInfoLive((prev) => ({ ...prev, ...data }));
      });
    } else {
      console.log('emitting REQUEST_SPECIFIC from live');
      socket.emit('REQUEST_SPECIFIC', {
        game: loadedData.fetchDataUrlName || gameType,
        draw: drawNumber,
      });
      socket.on('data', (data) => {
        setLrData(data);
      });
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/statistics?drawNumber=${drawNumber}&gameType=${gameType}`)
          .then((res) => {
            if (res.ok) return res.json();
          })
          .then((data) => {
            if (data) setInfoLive(data.events);
          });
    }

    return () => {
      socket.emit('LEAVE_DATA_CHANNEL', { game: gameType, draw: drawNumber });
      socket.off('data');
      socket.off('statistic');
    };
  }, [socket, gameType, drawNumber]);

  return (
    <LiveResultContainer className="livePageContainer">
      {lrData ? (
        <>
          <InnerContainerLarge>
            <LiveResult
              loadedData={loadedData}
              id={id}
              data={lrData}
              infoLive={infoLive}
            />

          </InnerContainerLarge>

          <InnerContainerLarge>
            <Footer />
          </InnerContainerLarge>
        </>
      ) : (
        <div className="loader">
          <div className="loader-animation" />
          <LoadingLogo />

          <p>Laddar sidan</p>
        </div>
      )}
    </LiveResultContainer>
  );
}

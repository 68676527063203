import styled from 'styled-components'
import { breakPoints, colors, font, spacers } from '../../../../styles/constants'
import { ToolsPanel, CircleBtn } from '../../../../styles/globalStyle'

export const LoadGameContainer = styled(ToolsPanel)`
  flex-direction: row;
  flex-wrap: nowrap;

  > * {
    flex: 1 1 auto;

    + * {
      margin-left: ${spacers.large};
    }

    &:first-child {
      flex: 0 0 auto;

      @media screen and (max-width: ${breakPoints.smallMax}) {
        flex: 1 1 auto;
        width: min-content;
      }
    }

    &:last-child {
      flex: 0 0 auto;

      svg {
        ${({ isLoadingCoupon }) => (isLoadingCoupon && `
          animation: loading 450ms infinite linear;
        `)}
      }
    }

    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`

export const LoadGameInput = styled.input`
  width: 65%;
  background: ${colors.darkGrey2};
  color: ${colors.white};
  font-weight: ${font.weight.bold};

  border: none;
  padding: 0.7rem;
  border-radius: 0.3rem;
  margin-right: auto;
`

export const LoadGameButton = styled(CircleBtn)`
  box-shadow: rgb(0 0 0 / 35%) 0px 3px 13px -2px;
`

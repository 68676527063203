import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../index.css';
import {
  GameDetailContainer,
  GameInfoContainer,
  GameOddsContainer,
  GameInfoLottad,
} from './GameDetail.elements';

import GameDetailOdds from './GameDetailOdds';

import { useParams } from 'react-router-dom';

export default function GameDetail({
  eventData,
  betsArr,
  productId,
  eventForecast,
}) {
  const {
    eventNumber,
    description,
    participants,
    sportEventStart,
    distribution,
    eventTypeDescription,
  } = eventData;
  const [eventStart, setEventStart] = useState('');
  const [eventTeam, setEventTeam] = useState(null);
  const { gameType, drawNumber } = useParams();

  useEffect(() => {
    const parsedDate = Date.parse(sportEventStart);
    const date = new Date(parsedDate);
    const eventTime = sportEventStart.slice(11, 16);
    const eventDate = date.getDate();
    const eventMonth = date.getMonth() + 1;
    const eventStartString = `${eventDate}/${eventMonth} ${eventTime}`;

    setEventStart(eventStartString);
  }, [sportEventStart]);

  useEffect(() => {
    const formatString = (str) => {
      return str.includes('-') ? str.replace(/-/g, ' - ') : str;
    };

    if (description) {
      setEventTeam(formatString(description));
    } else if (participants?.length) {
      let sortedParticipants = participants.sort((home, away) => {
        return home?.type !== 'home' ? 1 : -1;
      });
      let formattedTeam = sortedParticipants.map(p => `${p.name}`).join(" - ");
      setEventTeam(formatString(formattedTeam));
    }
  }, [participants, description]);

  return (
    <GameDetailContainer>
      <GameInfoContainer>
        <p className="gameInfoEventNumber">{eventNumber}</p>
        <a
          href={`https://spela.svenskaspel.se/${gameType}/?product=${productId}&draw=${drawNumber}&event=${eventNumber}`}
          target="_blank"
          className="gameInfoDescription"
          rel="noopener noreferrer"
        >
          {/* {description} */}
          {eventTeam}
        </a>
        <p className="gameInfoStartDate">{eventStart}</p>
      </GameInfoContainer>
      {eventForecast &&
        eventForecast?.cancelled &&
        eventForecast?.isFinished &&
        !eventTypeDescription && (
          <GameInfoLottad>
            Lottad
            <b>{` ${distribution?.home}-${distribution?.draw}-${distribution?.away} `}</b>
          </GameInfoLottad>
        )}
      {eventTypeDescription && (
        <GameInfoLottad>{eventTypeDescription}</GameInfoLottad>
      )}
      <GameOddsContainer>
        {betsArr.map((bet, idx) => (
          <GameDetailOdds bet={bet} key={`gameDetailOdds-${idx}`} />
        ))}
      </GameOddsContainer>
    </GameDetailContainer>
  );
}

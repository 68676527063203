export const ActiveGamesRouter = ({ condition, wrapper, children }) =>
  condition ? <div>{children}</div> : wrapper(children);

export const handlePopup = (
  gameType,
  draws,
  setModalData,
  setIsModalOpen,
  isModalOpen,
) => {
  draws.gameType = gameType;
  setModalData(draws);
  setIsModalOpen(!isModalOpen);
};

export function importGameTypeLogos(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

export const gameTurnoverWithSpace = (gameTurnover) =>
  gameTurnover
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .slice(0, -3);

export const getActiveDate = (gameType, idx, includeDay) => {
  const daysArr = [
    'Söndag',
    'Måndag',
    'Tisdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lördag',
  ];
  const parsedDate = Date.parse(gameType?.draws[idx].closeTime);
  const eventDay = new Date(parsedDate).getDay();
  const closeDate =
    gameType?.draws?.[idx]['closeTime'].slice(8, 10) +
    '/' +
    gameType?.draws?.[idx]['closeTime'].slice(5, 7);
  const closingDay = daysArr[eventDay];
  const closeTime = gameType?.draws?.[idx]['closeTime'].slice(11, 16);
  // { date: closeDate, day: closingDay, time: closeTime }
  return includeDay
    ? `${closeDate} ${closingDay} (${closeTime})`
    : `${closeDate} (${closeTime})`;
};

export const getShortActiveDate = (closeTime) => {
  return `${closeTime?.slice(8, 10)}/${closeTime?.slice(
    5,
    7,
  )} ${closeTime.slice(11, 16)}`;
};

export const getActiveDates = (closingTime) => {
  const daysArr = [
    'Söndag',
    'Måndag',
    'Tisdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lördag',
  ];
  const parsedDate = Date.parse(closingTime);
  const eventDay = new Date(parsedDate).getDay();
  const closeDate = closingTime?.slice(8, 10) + '/' + closingTime?.slice(5, 7);
  const closeDay = daysArr[eventDay];
  const closeTime = closingTime?.slice(11, 16);

  return { date: closeDate, day: closeDay, time: closeTime };
};

export const formatSavedCouponDate = (closingTime) => {
  const closeDate = closingTime.slice(8, 10) + '/' + closingTime.slice(5, 7);
  const closeTime = closingTime.slice(11, 16);

  return `SPELSTOPP ${closeDate} (${closeTime})`;
};

export const sortDates = (a, b) => {
  a = new Date(a.closeTime).valueOf();
  b = new Date(b.closeTime).valueOf();
  if (a < b) {
    return -1;
  }
  return 0;
};

export const startAffiliate = () => {
  if (process.env.NODE_ENV === 'development') {
    // Affiliate links ruin displaying in local env for some reason
    return;
  }

  window.tdlcAsyncInit = function () {
    window.TDLinkConverter.init({});
  };
  var tdlc_1d43f5s_a = new Date();
  tdlc_1d43f5s_a.setMinutes(0);
  tdlc_1d43f5s_a.setSeconds(0);
  var tdlc_1d43f5s_seconds = parseInt(tdlc_1d43f5s_a.getTime() / 1000);
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src =
      // 'https://clk.tradedoubler.com/lc?a(3195708)rand(' + tdlc_1d43f5s_seconds + ')';
      'https://clk.tradedoubler.com/lc?a(3232063)rand(' +
      tdlc_1d43f5s_seconds +
      ')';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'tdlc-jssdk');
  //console.log('startAffiliate')
};

export const removeAffiliate = () => {
  let tdlc = document.getElementById('tdlc-jssdk');
  if (tdlc) {
    tdlc.remove();
    //console.log('removeAffiliate')
  }
};

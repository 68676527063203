import styled, { css } from 'styled-components'
import { breakPoints, spacers, colors, font, borderRadius } from '../../styles/constants'
import { PageContent, OneHalf } from '../../styles/globalStyle'
import { HowItWorksPanel } from '../HowItWorksPanel/HowItWorksPanel.elements'

export const UserContainer = styled(PageContent)``

export const Content = styled(OneHalf)`
  flex: 1 0 auto;
  flex-direction: column;
  flex-wrap: initial;
`

export const Aside = styled(OneHalf)`
  
  @media screen and (min-width: ${breakPoints.small}) {
    flex: 0 1 290px;
    margin-left: ${spacers.xxsmall};
    align-content: flex-start;
  }
  @media screen and (min-width: 1020px) {
    flex: 0 1 380px;
  }
`

export const WelcomePanel = styled(HowItWorksPanel)`
  padding: ${spacers.panelMedium};
  padding-bottom: 8.5rem;
  // padding-right: 7rem;
  border-bottom-right-radius: ${borderRadius.small};
  background: linear-gradient(118.44deg, #4F616B 8.02%, rgba(79, 97, 107, 0) 117.5%);;
   .title {
   max-width: 28rem;
   width: 100%;
   font-size: 1.3rem;
   }
  .settings {
    svg {
      transform: rotate(-60deg);
      width: 3.4rem;
      height: 3.4rem;
      fill: ${colors.lightGrey};
      transition: transform 450ms cubic-bezier(0.02, 1.02, 0.8, 1);
    }

    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    position: absolute;
    bottom: 0;
    right: 0;

    border-radius: 50%;
    background-color: ${colors.grey};
    box-shadow: rgb(0 0 0 / 35%) 0px 3px 13px -2px;

    &:hover {
      svg {
        transform: rotate(0deg);
        fill: ${colors.white};
      }
    }
  }
`

export const ActiveCoupons = styled.div`
  width: 100%;
  background: #3E4D55;
  padding: 15px;
  border-radius: ${props => props.border? '5px' : '0' };
  @media screen and (max-width: 468px){
   padding: 10px 5px;
  }
`

export const Heading = styled.h3`
  background: ${ props => props.back ? '#4E5F69' :colors.toolsGradient} !important;
  font-size: ${props => props.back ? '1.8rem' :font.size.medium};
  font-weight: ${props => props.back ? 900 : font.weight.normal};
  padding: ${ props => props.back ? '1.5rem' : spacers.large};
  border-top-left-radius: ${borderRadius.small};
  border-top-right-radius: ${borderRadius.small};
  
  .title{
  font-size: 1.5rem;
  font-weight: ${font.weight.bold};
  display: flex;
  align-items: center;
  img {
  margin-right: 1rem;
  }
  }
  ${props => props.outher &&  css`
    background: #4E5F69!important;
  `}
  ${props => props.text &&  css`
    text-transform: uppercase;
  `}
`
export const HeadingWith = styled(Heading)`
  background: transparent!important;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-weight: ${font.weight.bold};
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  .reload{
    width: 16px;
    height: 16px;
  }
  .status{
    width: 20px;
    height: 20px;
    *{
      fill: #FFFFFF;
    }
  }
`

export const CouponInfo = styled.div`
  background: ${({ gameType }) => `${colors[`${gameType}`]}`} !important;
  box-shadow: ${({ isLive }) => `inset 0 0 0 4px ${colors[`${isLive}`]}, 0 0 8px 0 ${colors[`${isLive}`]}`};
`

export const CouponsList = styled.ul`
  display: flex;
  flex-direction: column;
  ${props=>props.rad && css`
  border-radius: 0 0 0.5rem 0.5rem;
`}
`

export const CouponItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.white};
  align-items: stretch;
  margin-top: ${spacers.medium};
  
  .coupon-info{
    display: flex;
    align-items: center;
  }
  .coupon-winner{
    font-size: 1.2rem;
    font-weight: 500;
    color: ${colors.gold};
  }
  .coupon-img{
    flex-shrink: 0;
    width: 85px;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-right: 1rem;
  }
  .game{
    font-size: 1rem;
    text-transform:uppercase;
    font-weight: 900;
    margin-top: 1rem;
  }
  .svenska{
    width: 20px;
    height: 20px;
    background-color: #FF0000;
  }
  .coupon-text{
    //font-size: 1.6rem!important;
    max-width: 185px;
    line-height: 2rem;
  }
  .coupon-status{
    display: flex;
    height: 100%;
    padding: 1rem 0;
    align-items: center;
  }
  .info{
    align-self: flex-end;
    position: relative;
    height: 100%;
    display: inline-flex;
    align-items: flex-end;
    &:after{
      content: "";
      position: absolute;
      width: 1px;
      height: 8rem;
      top: 50%;
      right: -2rem;
      transform: translateY(-50%);
      border-right:${props=>props.isWinner ? `1px solid ${colors.gold}` : '1px dashed #4C5E67'};
    }
  }
  .icon{
    margin-left: 6rem;
    margin-right: 3rem;
    width: 4rem;
    height: 100%;
    position:relative;
    display: flex;
    align-items: center;
    
    
    &:before{
      content: '';
      position: absolute;
      border-radius: 40%;
      border-top:${props=>props.isWinner ? `1px solid ${colors.gold}` : '1px dashed #4C5E67'} ;
      width: 2.5rem;
      height: 2rem;
      background: #3E4D55;
      left: -5.2rem;
      bottom: -2.2rem;
    }
    &:after{
    content: '';
    position: absolute;
    border-radius: 40%;
    //border : 1px dashed #4C5E67;
    border-bottom:${props=>props.isWinner ? `1px solid ${colors.gold}` : '1px dashed #4C5E67'} ;
    width: 2.5rem;
    height: 2rem;
    background: #3E4D55;
    left: -5.2rem;
    top: -2.5rem;
    }
  }
  .live {
    svg {
    *{
    fill: #fe0000;
    }
    }
  }
  a {
    color: ${colors.white};
    padding: 0 ${spacers.large};
  }

  > div:not(.buttons), .tenLatestItem {
    flex: 1;
    display: flex;
    justify-content: center;
    border:${props=>props.dash ? "1px dashed #4C5E67;" : 'none'} 
    border:${props=>props.isWinner ? `2px solid ${colors.gold};` : 'none'} 
    border-radius: 0.5rem;
    background: ${colors.toolsGradient};

    .couponHeading {
      font-size: ${font.size.medium};
      font-weight: ${font.weight.normal};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 1.4;
    
    }

    p {
      text-transform: capitalize;
      font-size: 1.2rem;
      font-weight: ${font.weight.bold};
    }
  }

  .couponItemInfo {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;

    .couponContent {
      margin-right: ${spacers.medium};
    }
  }

  > div.buttons {
    display: flex;

    button {
      cursor: pointer;
      margin-left: ${spacers.medium};
      width: 5rem;
      height:${props=> props.height ? '8rem' : '5rem'} ;
      background-color:${props=> props.btn ? "#263238" : ''} ;
    }
  }
  ${props=>props.adaptive && css`
  @media screen and (max-width: 1160px){
  .couponHeading {
  max-width: 70px;
  }
  }
  @media screen and (max-width: 1060px){
  .coupon-text{
    font-size: 1.2rem!important;  
  }
  .info{
    width: 51px;
  }
  .icon{
  margin-left: 4rem;
  margin-right: 2rem;
  &:before{
    left: -3.2rem;
  }
  &:after{
    left: -3.2rem;
  }
  }
  }
  @media screen and (max-width: 468px){
  .coupon-img{
    //display: none;
    width: 70px;
    height: 65px;
    .game{
    font-size: 0.9rem;
  }
  }
  .hide-mobile{
  display: none;
  }
  .couponHeading{
    font-size: 1.2rem!important;
    max-width: 60px;
  
  }
  .coupon-text{
    font-size: 1rem!important;  
    position: absolute;
    max-width: 120px;
    left: 95px;
  }
  .info{
   font-size: 1rem!important; 
   &:after{
   right: 0;
   }
  }
  .icon{
  margin-left: 2rem;
  margin-right: 1rem;
  
  }
  > div.buttons{
  button{
    width: 4rem;
  }
  }
  }
  @media screen and (max-width: 380px){
  .couponHeading{
   
  }
  }
`}
`

export const ItemButton = styled.button`
  display: flex;
  border: none;
  padding: ${spacers.medium};
  background-color: ${colors.toolGrey};
  width: 5rem;
  height: 5rem;
  align-items: center;
  justify-content: center;

  svg {
    width: 2.5rem;
    height: 2.5rem;
    fill: ${colors.lighterGrey};
  }

  &:hover svg {
    fill: ${colors.white};
  }
  &:disabled{
    cursor: not-allowed!important;
    svg {
    *{
      fill: #3E4D55;
    }
    }
  }
`

export const LatestClosedCoupons = styled.div`
  margin-top: ${spacers.small};
  width: 100%;

  > h3 {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    background: ${colors.toolGrey};
  }

  .expandIcon {
    display: flex;
    margin: -0.5rem -0.25rem -0.5rem 0;
    align-items: center;
    transform: ${({ showAccordion }) =>
      showAccordion ? 'rotate(180deg)' : 'rotate(-45deg)'};
    transition: transform 300ms cubic-bezier(0.02, 1.02, 0.8, 1);

    svg {
      fill: ${colors.lightGrey};
      width: 2rem;
      height: 2rem;
    }
  }

  ul {
    height: ${({ showAccordion }) => (showAccordion ? 'initial' : '0')};
    overflow: hidden;
  }

  li {
    opacity: ${({ showAccordion }) => (showAccordion ? '1' : '0')};
    transition: opacity 150ms cubic-bezier(0.02, 1.02, 0.8, 1);
  }

  button {
    width: 4rem;
    height: 4rem;
  }
`
export const TeamPanel = styled.div`
  width: 100%;
  @media screen and (min-width: 768px){
    margin-left: 0.2rem;
  }
  .blog-wrapper {
    padding: 0 2rem 2rem 2rem;
    margin: 0 auto;
    width: 100%;
    background: #4E5F69;
  }
  .team-wrapper {
    padding: 0 2rem 2rem 2rem;
    margin: 0 auto;
    width: 100%;
    background: #4E5F69;
  }
`

export const TeamContainerSmall = styled.div`
  border-radius: 0.5rem;
  background: #2F3D44;
  margin-bottom: 0.5rem;
 
  .svenska {
    width: 20px;
    height: 20px;
    background: ${colors.svenskaSpelRed};
    border-radius: 0.3rem;
  }
  .game-type{
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1.4rem;
  }
  .head {
    width: 100%;
    height: 30px;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .body {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0 1.5rem 1.5rem 1.5rem;
    margin-top: 0.7rem;
  }
  .price{
    color: ${colors.anchor};
    font-size: 1.6rem;
    
  }
  .title{
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: ${font.weight.semiBold};
    max-width: 25rem;
    margin-bottom: 0.5rem;
    max-height: 35px;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    color: ${colors.white};
  }
  .time {
    font-size: 1.2rem;
    color: #AFAFAF;
  }
`

export const UserAbout = styled.div`
background: linear-gradient(118.44deg, #4F616B 8.02%, rgba(79, 97, 107, 0) 117.5%);
padding: 2rem 1.5rem 3rem 1.5rem;
border-radius: 0.5rem;
margin-bottom: 1rem;

.avatar{
  min-width: 6rem;
  max-width: 6.1rem;
  height: 6rem;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }
}
.edit {
  position: absolute;
  right: 0;
  top: 0;
}
.edit-info{
  margin-top: 0.9rem;
  p {
    display: block;
    text-align: end;
  }
}
.edit-btn{
  display: flex;
  align-items: flex-end;
  text-decoration: underline;
  font-size: 1.4rem;
  cursor: pointer;
  span {
   margin-left: 0.8rem;
  }
}
.head {
  position: relative;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  grid-gap: 2rem;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1rem;
  margin-top: 0.5rem;
}
.link{
  width: 20px;
  height: 20px;
  background-color: ${colors.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.username {
  font-size: 1.4rem;
}
.name{
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  word-break: break-word;
}
.social{
  width: calc(100% - 220px);
}
  h5{
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1.3rem;
    color: #cfd8dc;
    word-break: break-word;
  }
`

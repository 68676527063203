import React, { useEffect, useState, useRef } from 'react'
import { BombenDistributionContainer } from './BombenDistribution.styles'
import { PointInput } from '../../Game/GameList/SelectBet/SelectBet.elements'
import { useOnClickOutside } from '../../../utils/hooks/useOnClickOutside'

let colorPalet = [{
  title:'red',
  color:'#ff6e40'
  },{
  title:'yellow',
  color:'#ffd600'
  },{
  title:'blue',
  color:'#00b8d4'
  },{
  title:'green',
  color:'#00c853'
  },]

const BombenDistribution = ({
  type,
  kind,
  currentType,
  changeStatus,
  eventArrId,
  eventId,
  winner,
  isCoast,
  changeCoast,
  trendData
  }) => {
  const [bgColor, setBgColor] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const ref = useRef(null)
  useOnClickOutside(ref,()=>{
    setIsActive(false)
  })
  const getColor = (color) =>{
    return colorPalet.find(palet => palet.title === color).color
  }
  useEffect(()=>{
    // sort flags to priority
    let flags = currentType?.flags?.map(item=>{
      if(item === 'yellow') return {item,pos:1}
      else if(item === 'blue') return {item,pos:2}
      else if(item === 'red') return {item,pos:3}
      else return {item,pos:4}
    })?.sort((a,b)=>a?.pos - b?.pos)?.map(col => `${col?.item}`);
    if(currentType?.isActive && currentType?.flags && currentType.flags?.length){
      let length = currentType.flags?.length;
      switch (length){
        case 1:
          setBgColor(getColor(flags[0]))
          break;
        case 2:
          setBgColor(`linear-gradient(90deg, 
          ${getColor(flags[0])} 0%, ${getColor(flags[0])} 50%,  
          ${getColor(flags[1])} 50%,  ${getColor(flags[1])} 100%);`)
          break;
        case 3:
          setBgColor(`linear-gradient(90deg, 
          ${getColor(flags[0])} 0%, ${getColor(flags[0])} 33.3333%, 
          ${getColor(flags[1])} 33.3333%, ${getColor(flags[1])} 66.6667%,
          ${getColor(flags[2])} 66.6667%,${getColor(flags[2])} 100%);`)
          break;
        case 4:
          setBgColor(`linear-gradient(90deg, 
            ${getColor(flags[0])} 0%, ${getColor(flags[0])} 25%, 
            ${getColor(flags[1])} 25%, ${getColor(flags[1])} 50%,
            ${getColor(flags[2])} 50%, ${getColor(flags[2])} 75%, 
            ${getColor(flags[3])} 75%, ${getColor(flags[3])} 100%)`)
          break;
      }
    } else{
      setBgColor(false)
    }
  },[currentType])
  let getScore = (score) =>{
    let toArr = score?.split('-')
    let getFirst = score[0]
    return getFirst || ''
  }
  let isWin = () =>{
    if(!winner) return
    let currScore = getScore(type?.score);
    let splitWin = winner?.split('-')
    let whatTeam = kind === 'home' ? splitWin[0] : splitWin[1]
    return currScore == whatTeam ? true : false
  }
  const getTrends = () =>{
    if(trendData && trendData > 0){
      let trend = Math.round(trendData * 100);
      let currWeight = type[kind];
      let more = <span className='small' style={{ color: '#FF0000',fontSize:'10px',paddingLeft:'4px' }}>{trend}%▼</span>
      let less =  <span className='small' style={{ color: '#00b708',fontSize:'10px',paddingLeft:'4px' }}>{trend}%▲</span>
      return trend >= currWeight ? less : more
    }else if (trendData === 0){
      let less =  <span className='small' style={{fontSize:'10px',paddingLeft:'4px' }}>--</span>
      return less
    }else if (trendData < 0){
      let less =  <span className='small' style={{fontSize:'10px',paddingLeft:'4px' }}>n/a</span>
      return less
    }
    else {
      return
    }
  }
  return (
    <BombenDistributionContainer
      isActive={currentType?.isActive}
      bgColor={bgColor}
      win={isWin()}
      onClick={(e)=>changeStatus(e,eventArrId,eventId,kind)}>
      <div className='info'>
        <div className='info-type'>
          <span className='total'>{getScore(type?.score)}</span>
          <div className='info-rader'>
            <span className='info-padding'>{type[kind] + '%'}</span>
            {getTrends()}
          </div>
        </div>
      </div>
      <div
        onClick={(e)=>{
          if(!isCoast) {
            return
          }else if (isCoast && currentType?.isActive){
            e.stopPropagation()
            setIsActive(true)
          } else{
            setIsActive(true)
          }

        }}
        className='circle'>
        {isCoast && currentType?.isActive && isActive ?
          <PointInput
            autoFocus
            ref={ref}
            type='text'
            min={0}
            max={100}
            maxLength={3}
            placeholder={parseInt(currentType.coast)}
            value={parseInt(currentType.coast) || ''}
            onChange={e => changeCoast(e,eventArrId,eventId,kind)}
            onInput={ev => {
              if (typeof ev.target.prevValue === 'undefined') {
                ev.target.prevValue = 0
              }
              const test = value =>
                /^((?!(0)))\d*$/.test(value) && (value === '' || parseInt(value) <= 100)
              if (!test(ev.target.value)) {
                ev.target.value = ev.target.prevValue
              } else {
                ev.target.prevValue = ev.target.value
              }
            }}
            /*onKeyPress={ev => {
              if (ev.key === 'Enter') {
                couponFunctions.closeEdit()
              }
            }}*/
          />
          : <span className='circle__inside'>{
            Boolean(+currentType?.coast) ? <span style={{color:'#fff'}}>{currentType?.coast}</span> : 'P'
          }</span>}
      </div>
    </BombenDistributionContainer>
  )
}

export default BombenDistribution
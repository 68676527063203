import styled from 'styled-components'
import Popup from 'reactjs-popup'

import {
  breakPoints,
  spacers,
  colors,
  borderRadius,
  font,
  headerHeight
} from '../../styles/constants'

export const ReductionPopup = styled(Popup)`
  &-overlay {
    background: rgba(18, 21, 22, 0.8);
    animation: overlayAnim 0.15s cubic-bezier(0.02, 1.02, 0.81, 1);
  }

  &-content {
    max-height: 100%;
    overflow: auto;
    color: ${colors.white};
    background-color: rgb(38 50 56 / 95%);
    border: 2px solid ${colors.vividCyan};
    border-radius: ${borderRadius.medium};
    padding: ${spacers.xlarge};
    box-shadow: 0px 5px 20px -5px ${colors.black};

    width: auto;
    min-width: 35rem;

    animation: contentAnim 0.3s cubic-bezier(0.02, 1.02, 0.81, 1);

    h2,
    h3,
    h4 {
      color: ${colors.white};
      font-weight: ${font.weight.semiBold};
    }

    p {
      color: ${colors.lightGrey};

      + *:not(p) {
        margin-top: ${spacers.xlarge};
      }
    }

    h2,
    h3,
    h4,
    p {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: ${colors.vividCyan};
    }

    .modalAlert {
      color: ${colors.vividCyan};
      font-weight: ${font.weight.bold};
    }

    > label {
      color: ${colors.lightGrey};
      display: block;
    }

    button {
      cursor: pointer;

      &.modalClose {
        width: fit-content;
        margin-left: auto;
        margin-top: -1rem;
        margin-right: -1rem;
        margin-bottom: -0.5rem;
        display: block;
        outline: none;

        border: none;
        background: none;

        svg {
          fill: ${colors.grey};
          transition: fill 100ms ease-in-out;
        }

        &:hover svg {
          fill: ${colors.lightGrey};
        }
      }

      &:not(.modalClose):not(.icon):not(.userRowsToggle):not(.swiper-next):not(.swiper-prev) {
        border: none;
        border-radius: ${borderRadius.xsmall};
        background-color: ${colors.toolGrey};
        padding: ${spacers.medium} ${spacers.xlarge};
        height: 3rem;
        min-width: 4rem;
        font-size: ${font.size.small};
        font-weight: ${font.weight.semiBold};
        color: ${colors.white};

        transition: background-color 100ms ease-in-out;

        &:hover {
          background-color: ${colors.toolGreyActive};
        }

        + button {
          margin-left: ${spacers.large};
        }
      }
    }

    .handinCta {
      padding: ${spacers.medium} ${spacers.large};
      background-color: ${colors.svenskaSpelRed};
      margin-top: ${spacers.xlarge};
      margin-bottom: ${spacers.xlarge};
      color: ${colors.white};
      border-radius: ${borderRadius.xsmall};

      font-weight: ${font.weight.semiBold};
      text-transform: uppercase;

      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &:last-child.of-type {
        margin-bottom: ${spacers.xxlarge};
      }

      span {
        text-align: right;

        &:first-child {
          margin-right: auto;
          margin-left: 0;
          text-align: left;
        }
      }

      svg {
        width: 2.2rem;
        height: 2.2rem;

        margin-top: -0.25rem;
        margin-right: -0.25rem;
        margin-left: ${spacers.medium};
        margin-bottom: 0.25rem;
      }
    }

    .input-group {
      display: block;
      position: relative;
      padding-left: 2.6rem;
      margin-top: 1rem;
      margin-bottom: 1.6rem;
      cursor: pointer;
      font-size: ${font.size.small};
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      label {
        cursor: pointer;
      }

      .checkmark {
        position: absolute;
        top: -1px;
        left: 0;
        height: 1.6rem;
        width: 1.6rem;
        border: 2px solid ${colors.lightGrey};
      }

      input[type='checkbox'] {
        display: none;

        &:checked {
          ~ .checkmark {
            &:after {
              position: relative;
              content: '';
              display: block;
              top: 0;
              left: 0.3rem;
              width: 0.3rem;
              height: 0.8rem;
              border: solid ${colors.anchor};
              border-width: 0 3px 3px 0;
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    .handinSave {
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .handinSave,
    .handinEmail {
      font-size: ${font.size.small};
      font-weight: ${font.weight.semiBold};
      color: ${colors.white};
      margin-top: ${spacers.small};

      > * {
        background-color: ${colors.toolGrey};
        padding: ${spacers.medium};
        height: 3rem;
        border-radius: ${borderRadius.xsmall};

        &:not(button):not(.sendMailBtn) {
          flex: 1 1 auto;
          margin-left: ${spacers.small};
          position: relative;
        }
      }

      button {
        width: 3rem;
        transition: background-color 100ms ease-in-out;
        border: none;
        position: relative;
        &:hover {
          background-color: ${colors.toolGreyActive};
        }
      }

      input {
        background: ${colors.darkGrey2};
        color: ${colors.white};
        text-align: center;
        height: 2rem;
        width: 100%;
        padding: ${spacers.small};
        border-radius: ${borderRadius.xsmall};
        border: none;
        text-align: left;
        font-weight: ${font.weight.semiBold};
      }

      svg {
        width: 2rem;
        height: 2rem;
        fill: ${colors.lightGrey};
      }

      .copied {
        width: 11rem;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        ${({ copied }) =>
          copied
            ? `
            opacity: 1;
            transition: opacity 150ms ease-in;
          `
            : `
            opacity: 0;
            transition: opacity 300ms 1400ms ease-out;
          `}
        color: ${colors.white};
        background-color: ${colors.toolGrey};
        padding: ${spacers.xsmall};
        border-radius: ${borderRadius.xsmall};
      }

      margin-bottom: ${spacers.xlarge};
    }
  }

  @keyframes contentAnim {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
    }
    1% {
      transform: scale(0.96) translateY(5px);
      opacity: 0;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }

  @keyframes overlayAnim {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const PopupGamesList = styled.ul`
  font-size: ${font.size.small};
  text-transform: uppercase;
  color: ${colors.white};

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${colors.grey};
    margin-bottom: ${spacers.xxsmall};
    min-height: 4rem;
    text-transform: uppercase;

    &:first-child {
      margin-top: ${spacers.large};
    }

    > a {
      font-weight: ${font.weight.semiBold};
      color: ${colors.white};
      display: block;
      display: flex;
      align-items: center;
      padding: ${spacers.large} ${spacers.xlarge};

      span {
        margin-left: ${spacers.xlarge};
        text-transform: capitalize;
      }
    }

    &:last-child {
      margin-bottom: ${spacers.small};
    }

    background-color: ${({ gameType }) => `${gameType}`};
  }
`

export const UserRowsPopup = styled(ReductionPopup)`
  &-overlay {
    background: rgba(18, 21, 22, 0.1);
    animation: overlayAnim 0.15s cubic-bezier(0.02, 1.02, 0.81, 1);
  }

  &-content {
    max-width: calc(${breakPoints.xlargeMax});
    width: auto;
    min-width: 42rem;

    @media screen and (max-width: calc(${breakPoints.xlargeMax} + 140px)) {
      max-width: calc(100% - ${headerHeight.large} * 2 - ${spacers.small} * 2);
    }

    @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
      margin: auto auto 3.4rem !important;
    }

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      max-width: calc(100% - ${spacers.small} * 2);
    }

    @media screen and (max-width: ${breakPoints.smallMax}) {
      max-width: 100%;
    }

    @media screen and (max-width: 510px) {
      min-width: 100%;
    }

    > div {
      &:first-of-type {
        margin-top: 1em;
      }

      + div {
        margin-top: ${spacers.small};
      }
    }
  }
`
export const TeamCreate = styled(ReductionPopup)`
  &-content{
    border:none;
    max-width:58rem;
    padding:1.2rem 1.6rem 2.1rem 1.6rem;
    border-radius:0.5rem;
    background: linear-gradient(
    -45deg,
    ${colors.panelDarkGrey} 0%,
    ${colors.panelDarkGrey} 15%,
    ${colors.headerGrey} 85%
  );
  }
 
`
export const Autorize = styled(TeamCreate)`
  &-content {
    text-align: center;
    padding: 1.2rem 1.5rem 3rem 1.5rem;
    .svenska{
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
      img {
        background-color: transparent;
        width: 9rem;
        border-radius: 0 0.5rem 0 0;
      }
    }
    .account{
       font-size: 1.6rem;
      margin: 1rem 0 3rem 0;
    }
    .underline{
      text-decoration: underline;
      margin-left: 0.4rem;
      cursor: pointer;
    }
    .icon{
      margin-bottom: 2rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width: 3rem;
        height: 3rem;
      }
    }
    .title {
      margin-bottom: 0.5rem;
      text-align: left;
      font-weight: ${font.weight.bold};
      text-transform: uppercase;
    }
    p{
      font-size: 1.8rem;
      text-align: left;
      color: #FFFFFF;
    }
    .modalClose {
      margin-right: 0!important;
    }
    .btn-group{
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    
   @media screen and (max-width: 768px){
      grid-gap: 2rem;
  }
  }
   
  }
`
export const UserProfile = styled(ReductionPopup)`
  &-content {
    text-align: center;
    padding:2rem 6rem ;
    max-width: 58rem;
    width: 100%;
    border: none;
    background: linear-gradient(118.44deg, #4F616B 8.02%, rgba(79, 97, 107, 0) 117.5%), linear-gradient(135.32deg, #596E79 0%, #36474F 99.45%);
    ::-webkit-scrollbar {
     height: 1px;
     width: 6px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2rem;
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors?.anchor};
      border-radius: 1rem;       
    }
   .cancel{
      background: #2B373E!important;
    }
    .save {
      background:${colors.anchor}!important;
    }
    .media{
      font-weight: 600;
      font-size: 1.6rem;
      margin-bottom: 0.7rem;
      text-align: left;
    }
    .error{
      font-size: 1.4rem;
      color: #fe0000;
    }
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: center;
      button{
        text-transform: uppercase;
        font-size: 1.6rem!important;
        font-weight: 600!important;
        width: 15.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2.2rem 0rem!important;
    }
    }
    .social{
      &-item{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 1rem;
        @media screen and (max-width: 468px){
          grid-template-columns: 1fr;
        }
      }
    }
    .profile-avatar{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media screen and (max-width: 468px){
        margin-bottom: 2rem;
      }
    }
    .avatar{
      width: 70px;
      height: 65px;
      overflow: hidden;
      border-radius: 50%;
      margin-bottom: 1.9rem;
      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }
    }
    .file-upload{
      display: none;
    }
    .upload-btn {
     font-size: 1.6rem!important;
     font-weight: 600!important;
     width: 15.8rem;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 2.2rem 0rem!important;
     position: relative;
     &:after{
      content: 'Max storlek 5mb';
      position: absolute;
      visibility: hidden;
      bottom: -2.2rem;
      font-size: 1.4rem;
      border-radius: 5rem;
      padding: 0.2rem;
      background: rgba(43, 55, 62, 0.2);;
      z-index: 1;
      width: 155%;
     }
     &:hover {
      &:after{
        visibility: visible;
      }
     }
      }
   .modalClose{
    position: absolute;
    right: 2rem;
    top: 2rem;
    svg{
      width: 25px;
      height: 25px;
    }
   }
   .profile-title{
      font-size: 2.4rem;
     font-weight: ${font.weight.bold};
   }
  }
  
  
`

export const ErrorRedirect = styled(ReductionPopup)`
  &-content{
    
    padding:1.2rem 1.6rem 2.1rem 1.6rem;
    border-radius:1rem;
 
  }
 
`

export const DeletePopup = styled(ReductionPopup)`
  &-content {
    max-width:58rem;
    padding:4rem 2rem;;
  }
`

export const LotteryCreatePopup = styled(ReductionPopup)`
  &-content {
    max-width:104.8rem;
    padding:0rem 1rem 1rem;
  }
  
  .wrapper {
    padding: 0;
  }
 
`
export const DeleteContent = styled.div`

    h2 {
      font-size:4rem;
      text-align: center;
    }
   div {
      display:flex;
      align-items: center;
      justify-content: center;
      margin-top:3rem;
      button {
        width:15rem;
        min-height:4rem;
        font-size:1.6rem!important;
      }
      .active {
        background-color:${colors.anchor}!important;
      }
   }
`
export const CreateContent = styled.div`
  h4 {
    font-size:2.2rem;
    margin-bottom:2rem;
  }
  .row {
    display:flex;
    align-items: center;
    margin-bottom:2rem;
    flex-direction:column;
    align-items: flex-start;
    label {
      display: block;
      font-size: 1.8rem;
      margin-bottom: 1.4rem;
      line-height: 2.4rem;
    }
    input {
      border: 1px solid #FFFFFF;
      width: 100%;
      border-radius: 1rem;
      padding: 1.6rem 1rem 1.6rem 2rem;
      outline: none;
      background: transparent;
      font-size: 1.8rem;
      color: #ffffff;
      position: relative;
    }
  }
   .btn-group {
      display:flex;
      align-items: center;
      justify-content: center;
      margin-top:3rem;
      button {
        box-shadow: 0px 3px 6px #0000004D;
        font-size: 1.8rem!important;
        padding: 0.9rem 4rem!important;
        height: 4.2rem!important;
        background-color: #37474F!important;
        width:15rem;
        font-size:1.8rem!important;
        
      }
      .active {
        background-color:${colors.anchor}!important;
        margin-right: 1.2rem;
        
      }
   }
   
`

export const TeamCreateWrapper = styled.div`
  width:100%;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  }
  .open {
    position:absolute;
    //width: 14px;
    font-size:18px;
    //height: 14px;
    right:2rem;
    img {
       width: 9px;
       height: 14px;
    }
  }
/* Firefox */
  input[type=number] {
  -moz-appearance: textfield;
  }
  .svsBlueSymbolContainer {
    position:absolute;
    top: 0;
    right: 0;
  }
  .svsBlueSymbol {
    width:9.2rem;
    height:7.7rem;
    object-fit: cover;
  }
  .input{
    border: 1px solid #FFFFFF;
    width:100%;
    border-radius:1rem;
    padding:1.4rem 1rem 1.4rem 2rem;
    outline:none;
    background: transparent;
    font-size:1.8rem;
    color:#ffffff;
    position: relative;
    &::placeholder {
      color:white;
      opacity:0.5;
    }
    &-error {
      border: 1px solid ${colors.svenskaSpelRed};
    }
  }
  .d-flex{
    display:flex;
    justify-content:space-between;
    align-items: center;
    & div {
      width:47.4%;
    }
  }
  label {
    display:block;
    font-size:1.8rem;
    margin-bottom:1.4rem;
    line-height:2.4rem;
  }
  .link{
    width:100%;
    margin-top:1.8rem;
    margin-bottom:3rem;
  }
  .custom-error {
        bottom: -4.5rem;
    }
  .btn-group{
    display: flex;
    justify-content: center;
    align-items: center; 
    
    .btn,button{
      box-shadow: 0px 3px 6px #0000004D;
      font-size:1.8rem!important;
      padding:0.9rem 4rem!important;
      height:4.2rem!important;
      background-color:#37474F!important;
      @media screen and (max-width:600px){
        font-size:1.4rem!important;
      }
    }
    .btn-submit {
      margin-right:1.2rem;
      background-color:${colors.anchor}!important;
    }
  }
  img{
    width:3rem;
    height:2.7rem;
    object-fit: contain;
  }
  h4 {
    font-size:2.2rem;
    font-weight:${font.weight.bold};
    line-height:3rem;
    margin-bottom:0.2rem;
    margin-top:1.8rem;
  }
  p {
    font-size: 1.8rem;
    color:${colors.white};
    line-height:2.4rem;
    margin-bottom:2rem!important;
  }
  .img-logos{
    height: 3rem;
    width: auto;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    }
  .nav-text{
    width:100%;
    cursor:pointer;
    padding:1rem 2rem;
    margin:0.2rem 0;
    font-size:1.4rem;
    text-transform:uppercase;
    font-weight:${font.weight.bold};
  }
  .stryktipset {
        background-color: ${colors.stryktipset};

        + *:not(.stryktipset) {
          margin-top: ${spacers.small};
        }
      }

      .europatipset {
        background-color: ${colors.europatipset};

        + *:not(.europatipset) {
          margin-top: ${spacers.small};
        }
      }

      .topptipset {
        background-color: ${colors.topptipset};

        + *:not(.topptipset) {
          margin-top: ${spacers.small};
        }
      }

      .powerplay {
        background-color: ${colors.powerplay};
        .img-logos{
          height:2rem;
        }
        + *:not(.powerplay) {
          margin-top: ${spacers.small};
        }
      }
      .error {
          border-color:red !important;
        }
      .custom-selected{
        height:4.2rem;
        border-radius:0.5rem;
        display:flex;
        margin-bottom:2rem;
        padding:1rem 2rem;
        align-items: center;
        font-size:1.4rem;
        font-weight:${font.weight.bold};
        
        span {
          margin-left:2.5rem;
          @media screen and (max-width:468px) {
            margin-left:0.75rem;
            font-size:1.2rem;
            margin-right:0.1rem;
          }
          
        }
      }
      .custom-list {
        max-height:245px;
        overflow:auto;
        position:absolute;
        width:100%;
        z-index:1;
        top:4.2rem;
        background:${colors.grey};
        border-radius:0.5rem;
        ::-webkit-scrollbar {
         height: 1px;
         width: 6px;
         }
        ::-webkit-scrollbar-track {
          background: transparent;
          border-radius: 2rem;
        }
        ::-webkit-scrollbar-thumb {
          background: ${colors?.anchor};
          border-radius: 1rem;       
        }
      }
      .custom-item {
        display:flex;
        align-items: center;
        
      }
      .custom-span {
          margin-right:1rem;
      }
      .img-logo-pow {
        height:2rem;
      }
      .background-str {
        background-color: ${colors.stryktipset};
      }
      .background-euro {
        background-color: ${colors.europatipset};
      }
      .background-pow {
        background-color: ${colors.powerplay};
      }
      .background-top { 
         background-color: ${colors.topptipset};
       }
      .info-error {
        font-size:1.2rem;
        color:${colors.svenskaSpelRed};
        position: absolute;
      } 
      .custom-dropdow {
        position: relative;
      }
      .custom-selected-default {
        border:1px solid #ffffff;
        span {
        margin-left:0!important;
        }
     
      .span-default {
        margin-left:0!important;
      }
      
`
export const UserNameContainer = styled(ReductionPopup)`
  &-content{
    border:none;
    padding:1.2rem 1.6rem 2.1rem 1.6rem;
    border-radius:0.5rem;
    background: linear-gradient(
    -45deg,
    ${colors.panelDarkGrey} 0%,
    ${colors.panelDarkGrey} 15%,
    ${colors.headerGrey} 85%
  );
  input {
    border: 1px solid #FFFFFF;
    width: 100%;
    border-radius: 1rem;
    padding: 1rem ;
    outline: none;
    background: transparent;
    font-size: 1.8rem;
    color: #ffffff;
    position: relative;
    &::placeholder {
      color: ${colors.white};
      opacity: 0.5;
    }
  }
  .error-input {
    border: 1px solid #fe0000;
  }
  label {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  .submit {
    background-color: ${colors.anchor}!important;
    display: block;
    width: 100%;
    max-width: 22rem;
    margin: 2rem auto 0 auto;
    font-size: 1.8rem!important;
    height: 4rem !important;
  }
  h1{
    margin-bottom: 2rem;
    text-align: center;
  }
  .error {
    font-size: 1.6rem;
    color: #fe0000;
    margin-top: 1rem;
  }
  }
  
`

export const BombenLivePopupWrapp = styled(ReductionPopup)`
  &-content {
    max-width: 1365px;
    min-width: 42rem;
    width: auto;
    @media screen and (max-width: 440px){
      max-width: 35rem;
      min-width: auto;
    }
  }
`
import styled from 'styled-components'
import {
  breakPoints,
  spacers,
  colors,
  font,
  borderRadius
} from '../../../styles/constants'
import { ResultItemElement } from '../../../styles/globalStyle'

export const ResultItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  > * + * {
    margin: ${spacers.xxsmall} 0 0 0;
  }

  @media screen and (max-width: ${breakPoints.smallMax}) {
    margin-left: -${spacers.small};
    margin-right: -${spacers.small};
  }
`

export const ResultItemsHeader = styled(ResultItemElement)`
  overflow: hidden;
  height: 1.8rem;

  position: sticky;
  z-index: 9;
  top: 7.5rem;
  background-color: ${colors.darkGrey2};
  box-shadow: 0 2px 0 ${colors.darkGrey2};

  @media screen and (max-width: ${breakPoints.xlargeMax}) {
    top: 4.5rem;
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    top: 0;
  }

  > * {
    background-color: ${colors.grey};
    align-items: center;
    display: flex;

    &:last-child {
      border-top-right-radius: ${borderRadius.xsmall};
    }

    &:first-child {
      padding-left: ${spacers.large};
      padding-right: ${spacers.large};
      border-top-left-radius: ${borderRadius.xsmall};

      span:first-child {
        + span {
          padding-left: ${spacers.xlarge};
          padding-right: ${spacers.large};
        }
      }

      button {
        margin-left: auto;
        margin-right: 0;
      }
    }

    &:not(:first-child) {
      color: ${colors.lightGrey};
      font-weight: ${font.weight.bold};
      font-size: ${font.size.small};
      justify-content: center;
    }
  }
`

export const ProbabilityToggle = styled.button`
  cursor: not-allowed;
  border: none;
  background: transparent;
  height: 100%;
  font-size: inherit;
  font-weight: ${font.weight.semiBold};
  color: ${colors.titleGrey};
  border-left: ${spacers.xxsmall} solid ${colors.darkGrey2};
  padding-left: ${spacers.large};
`

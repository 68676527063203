import React,{useState,useRef,useEffect} from 'react'
import { UserProfile } from '../../components/Popup/Popup.elements'
import CustomInput from '../../components/CustomInput/CustomInput'
import { UserLogButton } from '../../components/Headers/Headers.elements'
import CustomToggle from '../../components/CustomToggle/CustomToggle'
import facebook from '../../assets/facebook.svg'
import insta from '../../assets/instagram.svg'
import linkedin from '../../assets/linkedin.svg'
import twitter from '../../assets/twitter.svg'
import Profile from '../../assets/simple-profile.svg'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import { useAuth0 } from '@auth0/auth0-react'

const HomePageModal = ({isModalOpen,setIsModalOpen, profile, setProfile,originProfile,fetchUserPhoto,
   origin,editError,setEditError,clearError}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imgError,setImgError] = useState(null)
  const {isAuthenticated , getAccessTokenSilently} = useAuth0()
  const file = useRef(null)
  const handleToogle = (e) =>{
    let chek = e.target.checked
    setProfile(prev => ({...prev,account:chek}))
  }
  const handleChange = (e) =>{
    let inputName = e.target.name;
    let value = e.target.value;
    setProfile(prev => ({...prev,[inputName]:value}))
    setEditError(prev=>({...prev,[inputName]:{message:null}}))
  }
  const handleChangeSocial = (e,type) =>{
    let value = e.target.value;
    //setProfile(prev => ({...prev,[inputName]:value}))
    let findSocial = profile?.social?.map(social =>{
      if(social?.type === type){
        social['link'] = value;
        let findSocial = editError.social?.map((err => {
         if(err?.type === type) {
           err.message = null;
           return err
         } else{
           return err
         }
        }))
        setEditError(prev=>({...prev,social:findSocial}))
        return social
      } else{
        return social
      }
    })
    setProfile(prev=>({...prev,social:findSocial}))
  }
  const handleUpload = (e) =>{
    const MAX_SIZE = 5_242_880;
    let allowedExtension = ['jpeg', 'jpg','png','svg'];
    const image = e.target.files[0];
    const getSizeImg = image?.size;
    const getTypeImg = image?.type?.split('/').pop();
    if(getSizeImg > MAX_SIZE ) {
      setImgError('Max size allow 5mb')
      return
    }
    if(!allowedExtension.includes(getTypeImg)){
      setImgError('Type allow JPEG')
      return
    }
    setImgError(null)
    let imgToBlog = URL.createObjectURL(image)
    setProfile(prev=>({...prev,avatar:imgToBlog}))
    setSelectedFile(image)
  }
  const postImg = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      //'Content-Type': 'application/json',
      //'Content-Type': "multipart/form-data",
      //'Content-Type':'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`
    };
    const formData = new FormData();
    formData.append('photo',selectedFile)
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/account/photo`,{
      method:'POST',
      headers:headers,
      body:formData
    })
    if(!response.ok){
      setSelectedFile(null)
      setProfile(prev=>({...prev,avatar:origin.avatar}))
      //setProfile(prev=>({...prev,avatar:originProfile.avatar}))
      if(response.status === 413) await setImgError(response.statusText)
      else await setImgError(response.statusText)
      return 'error'
    }

  }
  const isValid = () =>{
    let isError = false;
    const fbPrefix = 'https://facebook.com';
    const twPrefix = 'https://twitter.com';
    const liPrefix = 'https://linkedin.com';
    const inPrefix = 'https://instagram.com';
    const www_fbPrefix = 'https://www.facebook.com';
    const www_twPrefix = 'https://www.twitter.com';
    const www_liPrefix = 'https://www.linkedin.com';
    const www_inPrefix = 'https://www.instagram.com';
    if(profile.name.length ===0){
      setEditError(prev=>({...prev,name:{message:'Obligatoriskt fält'}}))
      isError = true;
    }
    if(profile.social[0].link?.length > 0){
      let field = profile.social[0].link;
      let checkValid = field.startsWith(fbPrefix) || field.startsWith(www_fbPrefix);
      if(checkValid){

      }else{
        let updateSocial = editError.social;
        updateSocial[0].message = 'Felaktig länk'
        setEditError(prev=>({...prev}))
      }
      isError = !checkValid
    }
    if(profile.social[1].link?.length > 0){
      let field = profile.social[1].link;
      let checkValid = field.startsWith(inPrefix) || field.startsWith(www_inPrefix);
      if(checkValid){

      }else{
        let updateSocial = editError.social;
        updateSocial[1].message = 'Felaktig länk'
        setEditError(prev=>({...prev}))
      }
      isError = !checkValid
    }
    if(profile.social[2].link?.length > 0){
      let field = profile.social[2].link;
      let checkValid = field.startsWith(liPrefix) || field.startsWith(www_liPrefix);
      if(checkValid){

      }else{
        let updateSocial = editError.social;
        updateSocial[2].message = 'Felaktig länk'
        setEditError(prev=>({...prev}))
      }
      isError = !checkValid
    }
    if(profile.social[3].link?.length > 0){
      let field = profile.social[3].link;
      let checkValid = field.startsWith(twPrefix) || field.startsWith(www_twPrefix);
      if(checkValid){

      }else{
        let updateSocial = editError.social;
        updateSocial[3].message = 'Felaktig länk'
        setEditError(prev=>({...prev}))
      }
      isError = !checkValid
    }
    return isError
  }
  const updateProfile = async (e) =>{
    if(!profile?.me) return
    if(isValid()){
      return
    }
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    let updateProfile = {
      name: profile?.name || '',
      publicProfile:profile?.account || false,
      bio: profile?.about || '',
      username: profile?.username
    }
    profile?.social.forEach(item =>{
      if(!item?.link) return
      else {
        updateProfile[item?.type] = item?.link
      }
    })
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization':`Bearer ${token}`
      };
    } else {
      headers = {}
    }
    let photo
    if(selectedFile){
      photo = await postImg()
    }
    let response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/account/settings`,{
      method:'PATCH',
      headers: headers,
      body:JSON.stringify(updateProfile)
    })
    let data = await  response.json()
    if(response?.ok){
      originProfile(prev =>({
        ...prev,
        name:data?.updated?.name,
        username:data?.updated?.username,
        about:data?.updated?.bio,
        social:[
          {link:data?.updated?.facebook,type:'facebook'}
          ,{link:data?.updated?.instagram,type:'instagram'}
          ,{link:data?.updated?.linkedin,type:'linkedin'}
          ,{link:data?.updated?.twitter,type:'twitter'}
        ],
        account:data?.updated?.publicProfile,
      }))
      if(imgError || (photo==='error')){
      }else{
        setIsModalOpen(false)
        fetchUserPhoto()
      }

    } else {
      if(response.status == 422){
        let errors = data?.errors?.map(error =>{
          if(error.param == 'facebook' || error.param == 'linkedin'
            || error.param == 'instagram' || error.param == 'twitter' )
          {
            let filter=editError.social;
            let searchFild = filter.map(field =>{
              if(field?.type === error.param){
                field.message = 'Felaktig länk'
                return field
              } else {
                return field
              }
            })
            setEditError(prev=>({...prev,
              social:searchFild}))
          } else if (error.param == 'bio'){
            setEditError(prev=>({...prev,about:{message:'Not valid'}}))
          } else if (error.param == 'name'){
            if(profile?.name){
              setEditError(prev=>({...prev,name:{message:'Valt Namn är för kort '}}))
            } else{
              setEditError(prev=>({...prev,name:{message:'Obligatoriskt fält'}}))
            }
          } else{
            setEditError(prev=>({...prev,[error.param]:{message:'Not valid'}}))
          }
        })
      }
      else {

      }
    }
  };
  const enter = (e) =>{
    e.stopPropagation();
    let eventTarget = e.target
    let area = document?.getElementById('Biography')
    if(e.key === "Enter" && (eventTarget !== area)){
      updateProfile()
    }
  };
  useEffect(()=>{
    if(isModalOpen) document.addEventListener('keydown',enter)
    return ()=>{
      document.removeEventListener('keydown',enter)
    }
  },[isModalOpen])
  return (
    <UserProfile
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false)
        setSelectedFile(null)
        clearError()
        setImgError(null)
        let copyObj = JSON.stringify({...origin})
        setProfile(JSON.parse(copyObj))
      }}
      position='center'
      closeOnDocumentClick={false}
    >
      <div>
        { <button className='modalClose' onClick={() =>{
          setIsModalOpen(false)
          setSelectedFile(null)
          clearError()
          setImgError(null)
          let copyObj = JSON.stringify({...origin})
          setProfile(JSON.parse(copyObj))
        } }>
          <CloseIcon alt='close' />
        </button>}
        <div className='profile-avatar'>
          <h4 className='profile-title'>Redigera profil</h4>
            <div className='avatar'>
              <img src={profile?.avatar || Profile}/>
            </div>
            {imgError && <p className='error'>
              {imgError}
            </p>}
            <input
              ref={file}
              className='file-upload'
              accept="image/*"
              type="file"
              onChange={handleUpload} />
            <button
              onClick={()=>file.current?.click()}
              className='cancel upload-btn'>
              Byt bild
            </button>
        </div>
        <div>
          <CustomInput
            label='Namn:'
            id = 'name'
            placeholder='Ange namn'
            inputType='input'
            value={profile?.name || ''}
            error={editError?.name}
            name = 'name'
            type='text'
            onChange={handleChange}
            counter={50}
            maxlength={50}
          />
        </div>
        <div>
          <CustomInput
            label='Användarnamn'
            id = 'name'
            placeholder='Ange Användarnamn'
            inputType='input'
            value={profile?.username || ''}
            error={editError?.username}
            name = 'username'
            type='text'
            onChange={handleChange}
            //counter={16}
            maxlength={16}
            className='disable'
            disabled
          />
        </div>
        <div>
          <CustomInput
            label='Presentation'
            id = 'Biography'
            inputType='textarea'
            placeholder='Presentation'
            className='area'
            name = 'about'
            counter= {1000}
            maxlength={1000}
            value={profile?.about || ''}
            onChange={handleChange}
            error={editError?.about}
          />
        </div>
        <div className='social'>
          <h5 className='media'>Sociala medier:</h5>
          <div className='social-item'>
            <CustomInput
              img={facebook}
              id = 'facebook'
              placeholder='Facebook'
              inputType='input'
              className='icon'
              maxlength={80}
              zero
              value={profile?.social[0]?.link || ''}
              error={editError?.social[0]}
              onChange={(e)=>handleChangeSocial(e,profile?.social[0]?.type)}
            />
            <CustomInput
              img={twitter}
              id = 'twitter'
              placeholder='Twitter'
              inputType='input'
              className='icon'
              maxlength={80}
              zero
              value={profile?.social[3]?.link || ''}
              error={editError?.social[3]}
              onChange={(e)=>handleChangeSocial(e,profile?.social[3]?.type)}
            />
            <CustomInput
              img={insta}
              id = 'instagram'
              placeholder='Instagram'
              inputType='input'
              className='icon'
              maxlength={80}
              zero
              value={profile?.social[1]?.link || ''}
              error={editError?.social[1]}
              onChange={(e)=>handleChangeSocial(e,profile?.social[1]?.type)}
            />
            <CustomInput
              img={linkedin}
              id = 'linkedin'
              placeholder='Linkedin'
              inputType='input'
              className='icon'
              maxlength={80}
              zero
              value={profile?.social[2]?.link || ''}
              error={editError?.social[2]}
              onChange={(e)=>handleChangeSocial(e,profile?.social[2]?.type)}
            //error={{message:'thi fff'}}
            />
          </div>
        </div>
        <div>
          <CustomToggle
            checked={profile?.account || false}
            onChange={handleToogle}
            label={!!profile?.account ? 'På' : 'Av' }
          />
        </div>
        <div className='btn-group '>
          <button
            onClick={() => {
              setIsModalOpen(false)
              setSelectedFile(null)
              clearError()
              setImgError(null)
              let copyObj = JSON.stringify({...origin})
              setProfile(JSON.parse(copyObj))
            }}
            className='cancel'>Avbryt</button>
          <button onClick={updateProfile} className='save'>Spara</button>
        </div>
      </div>
    </UserProfile>
  )
}

export default HomePageModal
import React, { useState } from 'react';
import icon from '../../../../assets/save-coupon.svg';

import ErrorPopupWithOptions from '../../../Popup/ErrorPopupWithOptions';

export default function SaveCouponBtn({
  saveCoupon,
  isReduced,
  setErrorPopup,
  setErrorPopupText,
  currentNickname,
  disabledBtn,
  isMine,
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(currentNickname || '');
  const [saveAsNew, setSaveAsNew] = useState(false);
  const handleChange = (e) => {
    setSaveAsNew(!saveAsNew);
  };
  /*useEffect(()=>{
    if(isMine){
      setSaveAsNew(true)
    }
  },[isMine])*/
  const content = (
    <>
      <form>
        <div className="input-group">
          <label>
            Spara som ny
            <input
              type="checkbox"
              //disabled={isMine}
              defaultChecked={saveAsNew}
              onChange={handleChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </form>
      <div>Namnge din kupong.</div>
      <input
        value={dialogContent}
        onChange={(ev) => {
          setDialogContent(ev.target.value);
        }}
        maxLength={20}
      ></input>
    </>
  );

  return (
    <>
      <ErrorPopupWithOptions
        open={isDialogOpen}
        setErrorPopup={setIsDialogOpen}
        errorPopupText={content}
        // OK={() => {saveCoupon()}}
        OK={() => {
          saveCoupon({
            nickname: dialogContent,
            saveAsCopy: saveAsNew,
            savestatus: saveAsNew ? 'draft' : null,
          });
        }}
        cancel={() => setIsDialogOpen(false)}
      />

      <button
        onClick={() => {
          if (!isReduced) {
            setErrorPopup(true);
            setErrorPopupText(
              <h3>Tryck på REDUCERA för att generera dina rader.</h3>,
            );
            return;
          }
          if (disabledBtn) {
            setErrorPopup(true);
            setErrorPopupText(
              <h3>
                Denna kupong är låst eftersom du deltar i Reducering-EM. Bocka
                ur tävlingen - eller radera kupongen från din profil - om du
                skapa en ny kupong för SM.
              </h3>,
            );
            return;
          }
          // saveCoupon()
          setIsDialogOpen(true);
        }}
      >
        <img src={icon} />
        Spara kupong
      </button>
    </>
  );
}

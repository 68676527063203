import React, { useState, useEffect, useContext } from "react";
import { WinTableWrapper } from "./WinTable.elements";
import svsPOwerplay from "../../../assets/svs_product-powerplay.svg";
import svsEuro from "../../../assets/svs_product_europatipset.svg";
import svsStryk from "../../../assets/svs_product_stryktipset.svg";
import svsTop from "../../../assets/svs_product_topptipset.svg";
import svsBomben from "../../../assets/Bomben-icon.svg";
import ReactPaginate from "react-paginate";
import { useAuth0 } from "@auth0/auth0-react";
import arrow from "../../../assets/arrow.png";
import Loader from "../../Loader/Loader";
import moment from "moment";
import useWindowDimensions from "../../../utils/hooks/useWindowDimensions";
import { useHistory } from "react-router-dom";
import Dropdown from "../../Dropdown/Dropdown";
import { ReactComponent as Icon } from "../../../assets/arrow-down.svg";
import { ActiveGamesContext } from "../../../context/activeGamesContext";
import { ContestContext } from "../../../context/contestContext";

const WinTable = ({
  win,
  group,
  setGroup,
  totalPage,
  setPages,
  loader,
  setOrder,
  setSearch,
  setOrderWin,
  filterWin,
  setFilterWin,
  statusWin,
  setStatusWin,
  search,
  totalPageTab,
  setPagesTab,
  setOrderTab,
  setOrderWinTab,
  loaderTab,
}) => {
  //State pagination
  const list = [
    { type: "Alla", value: "" },
    { type: "Öppen", value: "Open" },
    { type: "Stängd", value: "Closed" },
  ];
  const [page, setPage] = useState(0);
  const [tabPage, setTabPage] = useState(0);
  const [orders, setOrders] = useState(false);
  const { isSM } = useContext(ContestContext);
  const [tab, setTab] = useState(isSM ? "Str" : "All");
  const { stryktipset } = useContext(ActiveGamesContext);
  const [contestDraws, setContestDraws] = useState([]);
  const [upcomingContestDraw, setUpcomingContestDraw] = useState();

  const fetchContestDraws = async () => {
    if (contestDraws.length) {
      return;
    }

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/wins/contest-draws`
      );
      const data = await res.json();
      setContestDraws(data);
      if (data.length) {
        const upcomingDraw = data.find(
          (draw) => new Date(draw.date) > new Date()
        );

        if (!upcomingDraw?.gameType) return;
        const res = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/gameInfo`
        );
        const gameInfo = await res.json();

        const draw = gameInfo[upcomingDraw.gameType]?.draws.find(
          (draw) => draw.drawNumber === parseInt(upcomingDraw.id)
        );

        if (draw) {
          setUpcomingContestDraw({
            ...draw,
            gameType: upcomingDraw.gameType,
          });
        }
      }
    } catch (err) {
      return [];
    }
  };

  useEffect(() => {
    if (!isSM && contestDraws.length) {
      return;
    }
    fetchContestDraws();
  }, []);

  const history = useHistory();
  //Pagination
  const handlePageClick = (event) => {
    let page = event.selected + 1;
    setPage(event.selected);
    setPages(page);
  };
  const handlePageClickTab2 = (event) => {
    let page = event.selected + 1;
    setTabPage(event.selected);
    setPagesTab(page);
  };
  //
  const { winWidth } = useWindowDimensions();
  //Header for table
  const header = [
    {
      title: "NAMN",
    },
    {
      title: "SPEL",
    },
    {
      title: "DATUM",
      order: "date",
      sortBy: sortTableHead,
    },
    {
      title: "RÄTT",
    },
    {
      title: "VINST",
      order: "win",
      sortBy: sortTableHead,
    },
    {
      title: "INSATS",
    },
  ];
  const headerGroup = [
    {
      title: "ANVÄNDARE",
    },
    {
      title: "ANTAL RÄTT",
    },
    {
      title: "TOTALT INSPELAT",
      order: "win",
      sortBy: sortTableHeadTab,
    },
    {
      title: "ANTAL RADER",
      order: "rader",
      sortBy: sortTableHeadTab,
    },
  ];
  // Icon array
  const gameType = [
    {
      gameType: "europatipset",
      img: svsEuro,
    },
    {
      gameType: "powerplay",
      img: svsPOwerplay,
    },
    {
      gameType: "stryktipset",
      img: svsStryk,
    },
    {
      gameType: "topptipset",
      img: svsTop,
    },
    {
      gameType: "bomben",
      img: svsBomben,
    },
  ];
  // Handler for sort by game type
  const sortByType = (e) => {
    let trigger = e.target;
    let div = e.currentTarget;
    if (trigger.tagName === "IMG" || trigger.tagName === "BUTTON") {
      let sortBy = trigger.dataset.type;
      if (filterWin === sortBy) {
        setFilterWin(null);
        setPages(1);
        setPage(0);
      } else {
        setFilterWin(sortBy);
        setPages(1);
        setPage(0);
      }
      trigger.classList.toggle("active");
      div.querySelectorAll(".img")?.forEach((img) => {
        if (img.dataset.type === sortBy) return;
        else img.classList.remove("active");
      });
    } else {
      return;
    }
  };
  //Find Icon array
  const findType = (game) => {
    if (!game) return null;
    let find = gameType.find((games) => games.gameType === game);
    return find?.img;
  };

  //Link to
  const handleRedirect = (item) => {
    let id = item?.coupon?.id;
    let gameType = item?.coupon?.data?.gameType;
    let drawNumber = item?.coupon?.data?.drawNumber;
    history.push(`/liveresults/${gameType}/${drawNumber}/${id}`);
  };
  const handleRedirectInside = (item) => {
    let id = item?.id;
    let gameType = item?.data?.gameType;
    let drawNumber = item?.data?.drawNumber;
    if (!id) {
      return undefined;
    } else {
      history.push(`/liveresults/${gameType}/${drawNumber}/${id}`);
    }
  };

  const handleRedirectProfile = (item) => {
    let name = item?.userInfo[0]?.username;
    if (!name) return;
    history.push(`/profile/${name}`);
  };

  // sorting for fetch
  function sortTableHead(event, columName, type) {
    if (!columName) return;
    if (!type) return;
    setOrder(type);
    setOrderWin(columName);
  }
  function sortTableHeadTab(event, columName, type) {
    if (!columName) return;
    if (!type) return;
    setOrderTab(type);
    setOrderWinTab(columName);
  }
  const sortStatus = (e, item) => {
    //setStatus(item)
    setStatusWin(item);
    let storage = window.localStorage;
    if (storage.getItem("status")) {
      storage.removeItem("status");
      storage.setItem("status", JSON.stringify(item));
    } else {
      storage.setItem("status", JSON.stringify(item));
    }
    //window.localStorage.setItem('status',`${item?.value}`)
  };
  const handleOpenMore = (e, groupId) => {
    e.preventDefault();
    let updateGroup = group?.map((item) => {
      if (item._id === groupId) {
        return { ...item, open: !item.open };
      } else {
        return item;
      }
    });
    setGroup(updateGroup);
  };
  const groupRader = (item, fullItem) => {
    if (typeof item === "number") {
      return item?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " kr";
    } else {
      let time = moment(fullItem.closeTime);
      if (!fullItem.closeTime) {
        return "-";
      } else {
      }
      let currTime = moment(new Date());
      if (time > currTime) {
        return "-";
      } else {
        return item;
      }
    }
  };
  return (
    <WinTableWrapper>
      <h2>SENASTE KUPONGERNA</h2>
      <div className="tabs">
        {isSM && (
          <button
            className={`tabs__tab ${tab === "Str" ? "tabs__tab--active" : ""}`}
            onClick={() => setTab("Str")}
          >
            REDUCERING-EM
          </button>
        )}

        <button
          className={`tabs__tab ${tab === "All" ? "tabs__tab--active" : ""}`}
          onClick={() => setTab("All")}
        >
          SENASTE KUPONGERNA
        </button>
      </div>
      {tab === "All" ? (
        <div className="table">
          <div className="head">
            <div className="type" onClick={sortByType}>
              <button>
                <img className="img" data-type="stryktipset" src={svsStryk} />
              </button>
              <button>
                <img className="img" data-type="europatipset" src={svsEuro} />
              </button>
              <button>
                <img className="img" data-type="bomben" src={svsBomben} />
              </button>
              <button>
                <img className="img" data-type="topptipset" src={svsTop} />
              </button>
              <button>
                <img className="img" data-type="powerplay" src={svsPOwerplay} />
              </button>
            </div>
            <div className="right">
              <div className="params ">
                <Dropdown
                  list={list}
                  onChange={sortStatus}
                  value={statusWin?.type || "Alla"}
                  disabled
                  classFor="dark"
                />
              </div>
              <div className="search">
                <input
                  name="search"
                  type="text"
                  placeholder="Sök"
                  value={search}
                  onChange={(e) => setSearch(e?.target?.value)}
                />
              </div>
            </div>
          </div>
          <div className="table-container">
            <div className="table-head">
              {header.map((item) => (
                <div
                  className={`table-title  ${
                    item?.order ? "table-title-medium" : ""
                  }`}
                >
                  <p
                    className={``}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item?.order) {
                        if (orders) {
                          item?.sortBy(e, item?.order, "asc");
                          setOrders((prev) => !prev);
                        } else {
                          item?.sortBy(e, item?.order, "desc");
                          setOrders((prev) => !prev);
                        }
                      } else return;
                    }}
                  >
                    {item.title}
                  </p>
                  {item?.sortBy ? (
                    <div className="arrows">
                      <span onClick={(e) => item.sortBy(e, item?.order, "asc")}>
                        <img className="up" src={arrow} />
                      </span>
                      <span
                        onClick={(e) => item.sortBy(e, item?.order, "desc")}
                      >
                        <img className="down" src={arrow} />
                      </span>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
            <div className="table-body">
              {!loader ? (
                win?.length ? (
                  win.map((winItem) => (
                    <div
                      className="table-body__item"
                      onClick={() => handleRedirect(winItem)}
                    >
                      <div>
                        {winItem?.userInfo[0]?.username ||
                          winItem?.userInfo[0]?.user_id ||
                          "-"}
                      </div>
                      <div>
                        <img src={findType(winItem?.coupon?.data?.gameType)} />
                      </div>
                      <div>
                        {moment(winItem?.coupon?.data?.closeTime).format(
                          "DD MMM"
                        )}{" "}
                      </div>
                      <div> {winItem?.win?.ratt || "-"}</div>
                      <div>
                        {Math.trunc(winItem?.win?.win)
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0"}{" "}
                        kr
                      </div>
                      <div>
                        {(
                          winItem?.win?.rader *
                          winItem?.coupon?.data?.betRowAmount
                        )
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0"}{" "}
                        kr
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="without-data">Inga tillgängliga kuponger</p>
                )
              ) : (
                <Loader wintable={true} />
              )}
            </div>
          </div>
          <div className="pagination">
            <div className="info-win">
              Här visas endast system från offentliga användare. Uppdatera dina
              inställningar om du också vill synas!
            </div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClick}
              marginPagesDisplayed={2}
              activeClassName="active"
              pageRangeDisplayed={5}
              pageCount={totalPage}
              previousLabel="< "
              forcePage={page}
              disableInitialCallback={true}
            />
          </div>
        </div>
      ) : (
        <div className="table">
          <div className="head">
            <div className="type">
              <button>
                <img className="img" data-type="europatipset" src={svsEuro} />
              </button>
            </div>
            <div className="right">
              {isSM && (
                <div className="params ">
                  <Dropdown
                    list={list}
                    onChange={sortStatus}
                    value={statusWin?.type || "Alla"}
                    disabled
                    classFor="dark"
                  />
                </div>
              )}
              <div className="search">
                <input
                  name="search"
                  type="text"
                  placeholder="Sök"
                  value={search}
                  onChange={(e) => setSearch(e?.target?.value)}
                />
              </div>
            </div>
          </div>
          <div className="table-container">
            <div className="table-head table-head--min">
              {headerGroup.map((item) => (
                <div
                  className={`table-title  ${
                    item?.order ? "table-title-medium" : ""
                  }`}
                >
                  <p
                    className={``}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item?.order) {
                        if (orders) {
                          item?.sortBy(e, item?.order, "asc");
                          setOrders((prev) => !prev);
                        } else {
                          item?.sortBy(e, item?.order, "desc");
                          setOrders((prev) => !prev);
                        }
                      } else return;
                    }}
                  >
                    {item.title}
                  </p>
                  {item?.sortBy ? (
                    <div className="arrows">
                      <span onClick={(e) => item.sortBy(e, item?.order, "asc")}>
                        <img className="up" src={arrow} />
                      </span>
                      <span
                        onClick={(e) => item.sortBy(e, item?.order, "desc")}
                      >
                        <img className="down" src={arrow} />
                      </span>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
            <div className="table-body">
              {!loaderTab ? (
                group?.length ? (
                  group.map((winItem) => (
                    <>
                      <div
                        className="table-body__item table-body__item--min"
                        onClick={(e) => handleOpenMore(e, winItem._id)}
                        /*onClick={()=>handleRedirectProfile(winItem)}*/
                      >
                        <div className="table-flex">
                          <Icon
                            className={`table-icon${
                              winItem.open ? " table-icon--open" : ""
                            }`}
                          />
                          {winItem?.userInfo[0]?.username ||
                            winItem?.userInfo[0]?.user_id ||
                            "-"}
                        </div>
                        <div>{winItem?.totalRatt || "0"}</div>
                        <div>
                          {winItem?.totalWin
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0"}{" "}
                          kr
                        </div>
                        <div>
                          {winItem?.totalRader
                            ?.toString()
                            ?.replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0"}{" "}
                          kr
                        </div>
                      </div>
                      {winItem.open &&
                        winItem.allCoupons?.map((coupon, index) => {
                          const drawNumber =
                            coupon?.data?.drawNumber ||
                            coupon?.coupon?.data?.drawNumber;
                          const contestDraw = contestDraws?.find(
                            (draw) => draw.id === drawNumber
                          );
                          if (!contestDraw) return;
                          const closeTimePassed =
                            new Date() > new Date(contestDraw?.date);

                          const isNextUpcoming =
                            contestDraws?.find(
                              (draw) => new Date() < new Date(draw.date)
                            )?.id === drawNumber;

                          if (
                            contestDraws[0] !== contestDraw &&
                            !closeTimePassed &&
                            !isNextUpcoming
                          )
                            return;

                          let drawIndex =
                            contestDraws?.findIndex(
                              (draw) => draw.id === contestDraw.id
                            ) + 1;

                          return (
                            <div
                              className="table-body__item table-body__item--min table-body__item--color "
                              onClick={() => handleRedirectInside(coupon)}
                              /*onClick={()=>handleRedirectProfile(winItem)}*/
                            >
                              <div className="table-flex table-flex--padding">
                                {`Omgång ${drawIndex}` || "-"}
                                {isNextUpcoming ? (
                                  <span
                                    style={{
                                      color: "#b7bec9",
                                    }}
                                  >
                                    (kommande)
                                  </span>
                                ) : null}
                              </div>
                              <div>
                                {(!isNextUpcoming && coupon?.win?.ratt) || "-"}
                              </div>
                              <div>
                                {!isNextUpcoming &&
                                typeof coupon?.win?.win === "number"
                                  ? `${coupon?.win?.win
                                      ?.toString()
                                      ?.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        " "
                                      )} kr`
                                  : "-"}
                              </div>
                              <div>
                                {!isNextUpcoming &&
                                typeof coupon?.win?.rader === "number"
                                  ? `${groupRader(coupon?.win?.rader, coupon)}`
                                  : !isNextUpcoming
                                  ? coupon?.win?.rader
                                  : "-"}
                              </div>
                            </div>
                          );
                        })}
                    </>
                  ))
                ) : (
                  <p className="without-data">Inga tillgängliga kuponger</p>
                )
              ) : (
                <Loader wintable={true} />
              )}
            </div>
          </div>
          <div className="pagination">
            <div className="info-win">
              {tab === "Str" ? (
                <>
                  {isSM && (
                    <button
                      disabled={!upcomingContestDraw}
                      onClick={() => {
                        if (upcomingContestDraw) {
                          history.push(
                            `/reducera/${upcomingContestDraw.gameType}/${upcomingContestDraw.drawNumber}`
                          );
                        }
                      }}
                      className="btn-go"
                    >
                      delta i{" "}
                      {upcomingContestDraw?.gameType === "stryktipset"
                        ? "sm"
                        : "em"}
                    </button>
                  )}
                </>
              ) : (
                "Här visas endast system från offentliga användare.\n" +
                "Uppdatera dina inställningar om du också vill synas!"
              )}
            </div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={handlePageClickTab2}
              marginPagesDisplayed={2}
              activeClassName="active"
              pageRangeDisplayed={5}
              pageCount={totalPageTab}
              previousLabel="< "
              forcePage={tabPage}
              disableInitialCallback={true}
            />
          </div>
        </div>
      )}
    </WinTableWrapper>
  );
};

export default WinTable;

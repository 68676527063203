import { EventSign } from '../CouponSettings.elements'

const map = {
  home: '1',
  draw: 'X',
  away: '2'
}
function sortName (event){
    let sortParticipants = event?.sort((home,away)=>{
      if(home?.type !== 'home'){
        return 1
      }else {
        return -1
      }
    });
  return `${sortParticipants[0]?.name} - ${sortParticipants[1]?.name}`
}

export default function SignSummary({ viewState, isReduced, events, forecast }) {
  const eventSummery = viewState?.map((val, i) => {

    let output = [
      <div key={0} className='eventNumber'>
        {events[i].eventNumber}
      </div>,
      <div key={1} className='eventDescription'>
        {/* {events[i].description} */}
        {sortName(events[i].participants)}
        {/*events[i].participants[0].name}{' - '}{events[i].participants[1].name*/}
      </div>
    ]

    let signs = Object.keys(val).map((key, id) => {
      let correctBet =
        forecast?.events[i]?.sportEventStatus === 'Inte startat'
          ? false
          : map[key] === forecast?.events[i]?.outcome
      if (isReduced && val[key].signDistribution > 0) {
        let distributionPercentage = `${parseInt(val[key].signDistribution * 100)}%`
        return (
          <EventSign correctBet={correctBet} key={id + 2}>
            {distributionPercentage}
          </EventSign>
        )
      } else {
        return <div key={id + 2} />
      }
    })

    output.push(signs)
    return output
  })

  return (
    <div className='eventContainer'>
      {eventSummery?.map((item, idx) => (
        <div className='eventRow' key={`summaryRow-${idx}`}>
          {item}
        </div>
      ))}
    </div>
  )
}

import React from 'react'
import reset from '../../../assets/reset-coupon.svg'
import save from '../../../assets/save-coupon.svg'
import clone from '../../../assets/clone-coupon.svg'
import lama from '../../../assets/svenskaspel-logomark.svg'
import { ReactComponent as CloseIcon } from '../../../assets/close.svg'
import { BombenButtonContainer } from './BombenButton.styles'
let img = [
  {title:'lama',src: lama},
  {title:'reset',src: reset},
  {title:'save',src:save},
  {title:'clone',src:clone}];

const BombenButton = ({handleClick,types,text,icon,variant,handleRemove}) => {
  return (
    <BombenButtonContainer types={types} onClick={handleClick} variant={variant}>
      {variant && <CloseIcon
        onClick={handleRemove}
        className='close'/>}
      {icon && (<img
        alt='btn-action'
        className='img'
        src={img?.find(image=> image?.title === icon)?.src} />)
        }
      <span>{text}</span>
    </BombenButtonContainer>
  )
}

export default BombenButton
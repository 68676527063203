import React from 'react'
import { SimpleActiveGamesContainer } from '../../styles/globalStyle'

import simpleLogo from '../../assets/kommer-snart.png'
import svsBlueSymbol from '../../assets/svs-blue-symbol.svg'

export default function SimpleActiveGamesBox() {
  return (
    <SimpleActiveGamesContainer
    >
      <div className="svsBlueSymbolContainer">
        <img className='svsBlueSymbol' src={svsBlueSymbol} alt='Svenska Spel Symbol' />
      </div>
        <div>
          <div className='activeGameHeader'>
            <span style={{color:'#5F8397'}}></span>
          </div>
          <img
            className={`itemLogo powerPlay`}
            src={simpleLogo}
            alt='simple-type'
            style={{maxWidth:'255px',maxHeight:'30px'}}
          />
        </div>
    </SimpleActiveGamesContainer>
  )
}

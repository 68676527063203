import styled from 'styled-components'
import { breakPoints, spacers, colors,font } from '../../../styles/constants'
import arrow from '../../../assets/arrow.png'
export const BlogMain = styled.div`
  width: 100%;
  padding-top: 6.7rem;
  .no-data {
    font-size: 1.8rem;
    text-align: center;
  }
  .swiper-container{
    padding: 0 33px;
  }
  .item {
    margin-bottom:2.8rem ;
    @media screen and (max-width: 968px){
      text-align: center;
    }
    .swiper-button-prev{
      left:0;
      &:after{
        content: "";
        background-image: url(${arrow});
        background-repeat: no-repeat;
        background-position: center right;
        transform: rotate(180deg);
        width: 2rem;
        height: 2rem;
      }  
    }
    .swiper-button-next{
     right:0;
      &:after{
        content: "";
        background-image: url(${arrow});
        background-repeat: no-repeat;
        background-position: center right;
        width: 2rem;
        height: 2rem;
      }  
     }
    h3 {
      font-weight: ${font.weight.bold};
      font-size: 2.8rem;
      margin-bottom: 2.7rem;
    }
  }
`
import { useEffect, useState } from "react";
import logoMark from "../assets/reducering-v2.png";
// import wordMark from "../assets/reducering-wordmark.svg";

export const V2Popup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const dontShowV2PopupCountdown =
      localStorage.getItem("dontShowV2PopupCountdown") || "";
    const now = new Date().getTime();
    if (dontShowV2PopupCountdown && now < parseInt(dontShowV2PopupCountdown)) {
      return;
    }

    localStorage.removeItem("dontShowV2PopupCountdown");

    if (!localStorage.getItem("dontShowV2PopupCountdown")) {
      setIsOpen(true);
    }
  }, []);

  const handleClose = () => {
    const now = new Date();
    const inFuture = new Date(now);
    inFuture.setMinutes(now.getMinutes() + 60);
    localStorage.setItem(
      "dontShowV2PopupCountdown",
      inFuture.getTime().toString(),
    );

    setIsOpen(false);
  };

  return isOpen ? (
    <div
      style={{
        position: "fixed",
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
      }}
      onClick={handleClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          display: "flex",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "1px solid black",
          backgroundColor: "#18252F",
          flexDirection: "column",
          justifyItems: "center",
          padding: "20px",
          borderRadius: "5px",
          width: "90%",
          maxWidth: "400px",
        }}
      >
        <button
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            backgroundColor: "transparent",
            border: "none",
            color: "white",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          X
        </button>
        <a
          href="https://beta.reducering.se"
          alt="beta.reducering.se"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            // paddingBottom: "15px",
          }}
        >
          <img
            style={{
              width: "400px",
            }}
            src={logoMark}
            alt="Reducering Logomark"
          />
        </a>
        <p
          style={{
            fontSize: "20px",
            fontStyle: "bold",
            textAlign: "center",
          }}
        >
          Äntligen - nya versionen är här!
        </p>
        <p
          style={{
            fontSize: "14px",
            paddingTop: "8px",
            textAlign: "center",
          }}
        >
          Missa inte nya funktioner såsom odds- och utdelningsreducering,
          autogenerade system med Poolarn och underhållande liverättning.
        </p>
        <a
          href={`https://beta.reducering.se`}
          style={{
            textAlign: "center",
            backgroundColor: "#36C5AF",
            borderRadius: "5px",
            marginTop: "27px",
            fontSize: "20px",
            margin: "15px 10px auto",
            color: "white",
            padding: "10px",
          }}
        >
          Testa nya Reducering.se
        </a>
      </div>
    </div>
  ) : null;
};

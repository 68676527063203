import React, { useState } from 'react'
import icon from '../../../../assets/reset-coupon.svg'

import ErrorPopupWithOptions from '../../../Popup/ErrorPopupWithOptions'
import ResetPopup from '../../../Popup/ResetPopup'

export default function ResetCoupon({resetCoupon}) {

    const [resetPopup, setResetPopup] = useState(false);

    const text =
        <span>Vill du ta bort samtliga reduceringar?</span>

    return  <> 

    <ResetPopup 
        open={resetPopup} 
        setOpen={() => setResetPopup(false)} 
        text={text}
        reset={resetCoupon}
        cancel={() =>  { /* Noop */ } }
    />
    
    <button onClick={() => {
        setResetPopup(true)
    }
    }>


        <img src={icon} />
        <span>Nollställ reduceringar</span>
    </button>
    </>
}
import styled from 'styled-components'
import { breakPoints, spacers } from '../../../../styles/constants'
import { ToolsPanel } from '../../../../styles/globalStyle'

export const SignReductionContainer = styled(ToolsPanel)`
  flex-direction: column;

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    > div {
      flex-direction: row;
    }

    input {
      width: 4.5rem;
    }

    select {
      width: 4.5rem;
    }
  }
`
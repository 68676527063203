import React, { useState, useEffect, useContext } from 'react'
import useWindowDimensions from '../../../../utils/hooks/useWindowDimensions'
import Tooltip from 'rc-tooltip'
import { ToolsHeading, ToolsPanel } from '../../../../styles/globalStyle'
import {
  UserRowsContainer,
  UserRowsTool,
  UserRowsHeader,
  InnerContainer,
  UserRowsList,
  InnerColList,
  InnerUserSign,
  CorrectRows
} from '../../UserRows/UserRows.elements'
import { font } from '../../../../styles/constants'
import Dividend from '../../ResultWidgets/Dividend/Dividend'
import DividendForecast from '../../ResultWidgets/DividendForecastSystem/DividendForecast'
import DividendLastGame from '../../ResultWidgets/DividendLastGame/DividendLastGame'
import { SliderContainer, SignBG, SliderControls } from '../../ResultWidgets/DividendLastGame/DividendLastGame.elements'
import SwiperCore, { Navigation } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/swiper-bundle.css'

import { GameContext } from '../../../../context/gameContext'
// install Swiper modules
SwiperCore.use([Navigation])

export default function SwiperBox({ isModalOpen, userRows, forecast, dividendForecast, theLastGame, betRowAmount }) {
  const [swiperHeader, setSwiperHeader] = useState('')
  const [swiperSubheader, setSwiperSubheader] = useState()

  const gameInfo = useContext(GameContext)
  const [gameType, drawNumber] = [gameInfo.gameType, gameInfo.drawNumber]

  return (
    <ToolsPanel style={{ marginBottom: '.2rem' }}>
      <SliderContainer isModalOpen={isModalOpen}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SliderControls className='swiper-prev' />
          <div style={{ textAlign: 'center', fontSize: font.size.medium }}>
            {swiperHeader} <br />
            {theLastGame ? swiperSubheader : ''}
          </div>
          <SliderControls className='swiper-next' />
        </div>

        <Swiper
          slidesPerView={1}
          loop={true}
          speed={275}
          spaceBetween={30}
          simulateTouch={true}
          onRealIndexChange={sw => {}}
          onSlideChange={sw => {
            if (sw.realIndex) {
              setSwiperHeader('Utdelning Svenska folket')
              setSwiperSubheader(`${theLastGame?.participants[0].name} - ${theLastGame?.participants[1].name}`);
            } else {
              setSwiperHeader('Utdelningsprognos System')
              // setSwiperSubheader(theLastGame?.participants[1].name);
              setSwiperSubheader(`${theLastGame?.participants[0].name} - ${theLastGame?.participants[1].name}`);

            }
          }}
          navigation={{
            prevEl: '.swiper-prev',
            nextEl: '.swiper-next'
          }}
        >
          {theLastGame ? (
            <>
              <SwiperSlide>
                <DividendLastGame userRows={userRows} forecast={forecast} sign={'1'} betRowAmount={betRowAmount} />
                <DividendLastGame userRows={userRows} forecast={forecast} sign={'X'} betRowAmount={betRowAmount} />
                <DividendLastGame userRows={userRows} forecast={forecast} sign={'2'} betRowAmount={betRowAmount} />
              </SwiperSlide>
              <SwiperSlide>
                <Dividend
                  distribution={forecast?.distribution}
                  large={gameType === 'stryktipset' || gameType === 'europatipset'}
                />
              </SwiperSlide>
            </>
          ) : (
            <>
              <SwiperSlide>
                <DividendForecast dividendForecast={dividendForecast} betRowAmount={betRowAmount} />
              </SwiperSlide>
              <SwiperSlide>
                <Dividend
                  distribution={forecast?.distribution}
                  large={gameType === 'stryktipset' || gameType === 'europatipset'}
                />
              </SwiperSlide>
            </>
          )}
        </Swiper>
      </SliderContainer>
    </ToolsPanel>
  )
}

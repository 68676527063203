import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import HomeHeader from "../../components/Headers/HomeHeader";
import Video from "../../components/Video/Video";
import Card from "../../components/Card/Card";
import ActiveGamesBoxes from "../../components/ActiveGamesBoxes/ActiveGamesBoxes";
import HowItWorksPanel from "../../components/HowItWorksPanel/HowItWorksPanel";
import Footer from "../../components/Footer/Footer";
import {
  InnerContainer,
  OneHalf,
  PageContent,
  ArticlePanel,
  ArticlePanelWithLink,
  TeamWrapper,
} from "../../styles/globalStyle";
import VideoHeroMp4 from "../../assets/video/Hero-Fotboll.mp4";
import VideoHeroWebm from "../../assets/video/Hero-Fotboll.webm";
import VideoHeroImage from "../../assets/video/Hero-fallback-fotboll.jpg";
import contestPrize from "../../assets/contest_prize.svg";
import ActiveCreatePopup from "../../components/Popup/ActiveCreatePopup";
import { useAuth0 } from "@auth0/auth0-react";
import BlogMain from "../../components/Blog/BlogMain";

import Autorized from "../../components/Popup/Autorized";
import About from "../../components/HomePage/About";
import WinTable from "../../components/HomePage/WinTable/WinTable";
import HowIsWork from "../../components/HomePage/HowIsWork/HowIsWork";
import WinSection from "../../components/HomePage/WinSection/WinSection";
import { startAffiliate, removeAffiliate } from "../../utils/helperFunctions";
import moment from "moment";
import { BlogMainContainer } from "../../components/Blog/Blog.elements";
import { ContestContext } from "../../context/contestContext";
import { ContestPopup } from "../../components/ContestPopup";
import { V2Popup } from "../../components/V2Popup";

export default function HomePage() {
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  /*let token = isAuthenticated ?  getAccessTokenSilently() : {};
  const headers = {'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}` };*/
  const getItemFromStorage = () => {
    let item = window.localStorage.getItem("status");
    if (!item) {
      return "";
    } else {
      let fromJson = JSON.parse(item);
      return fromJson;
    }
  };

  const [team, setTeam] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [isModalLogin, setIsModalLogin] = useState(false);
  const [page, setPage] = useState(1);
  const [isCanLoad, setIsCanLoad] = useState(true);
  const [isCanLoadBlog, setIsCanLoadBlog] = useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [resetSort, setResetSort] = useState(false);
  const [pageBlog, setPageBlog] = useState(1);
  const [blog, setBlog] = useState(null);
  const [typeSort, setTypeSort] = useState(null);
  const location = useLocation();
  const scrollTo = useRef(null);
  // Win list
  const [win, setWin] = useState(null);
  const [groupWin, setGroupWin] = useState(null);
  const [winPage, setWinPage] = useState(1);
  const [winPageCount, setWinPageCount] = useState(null);
  const [loaderWin, setWinLoader] = useState(true);
  const [loaderWinGrop, setWinLoaderGrop] = useState(true);
  const [orderBy, setOrderBy] = useState("");
  const [orderByGroup, setOrderByGroup] = useState("");
  const [orderWin, setOrderWin] = useState("");
  const [orderWinGroup, setOrderWinGroup] = useState("");
  const [search, setSearch] = useState("");
  const [statusWin, setStatusWin] = useState(getItemFromStorage());
  const [filterWin, setFilterWin] = useState(null);
  const [winPageTotalGroup, setWinPageTotalGroup] = useState(1);
  const [winPageGroup, setWinPageGroup] = useState(1);
  const { isSM } = useContext(ContestContext);
  // Lottery State
  const [lottery, setLottery] = useState({
    title: null,
    photo: null,
    text: null,
    hidden: true,
  });
  const [loaderLottery, setLoaderLottery] = useState({
    img: false,
    main: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location?.hash == "#andelsspel" && scrollTo.current !== null) {
      setTimeout(() => {
        let height = scrollTo.current.offsetTop;
        window.scrollTo(0, height);
      }, 500);
    }
  }, [location, scrollTo]);
  useEffect(() => {
    if (!isLoading) {
      fetchBlog();
      fetchTeam();
      fetchWinCoupon();
      //fetchWinGroup()
      fetchLottery();
      fetchLotteryPhoto();
    }
  }, [isAuthenticated, isLoading]);
  useEffect(() => {
    if (!isLoading) {
      updateTeam();
    }
    return () => removeAffiliate();
  }, [typeSort]);
  const updateTeam = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers;
    setIsLoader(true);
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {};
    }
    setTeam([]);
    let page = 1;
    setPage(1);
    setIsCanLoad(true);
    let reset = await setTeam([]);
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/teams?page=${page}&perPage=12${
        typeSort ? `&gameType=${typeSort}` : ""
      }`,
      {
        headers: headers,
      },
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTimeout(() => {
          setIsLoader(false);
        }, 500);
        setTeam(data?.teams);
        startAffiliate();
      });
  };
  const uploadMore = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : null;
    let headers;
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {};
    }
    let current = page + 1;
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/teams?page=${current}&perPage=12${
        typeSort ? `&gameType=${typeSort}` : ""
      }`,
      {
        headers: headers,
      },
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPage(current);
        setIsLoader(false);
        if (!typeSort) setResetSort((prev) => !prev);
        /*if(data.teams.length <= 11 && current === 1){
      setIsCanLoad(false)
      return
    }*/
        if (data.teams.length <= 0) {
          setIsCanLoad(false);
          return;
        } else {
          setTeam((prev) => [...prev, ...data?.teams]);
          if (data.teams.length <= 11) setIsCanLoad(false);
        }
      });
  };
  const handleSubmit = async (data = {}) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    await setIsLoader(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/teams`,
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      },
    );

    let errorMessage = await response.json();
    return { response, error: errorMessage };
  };
  const handlDelete = async (e, id) => {
    e.preventDefault();
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/teams/${id}`,
      {
        method: "DELETE",
        headers: headers,
      },
    );
    if (response.ok) {
      setIsLoader(true);
      await updateTeam();
    }
    return response.json();
  };
  const fetchBlog = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers;
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {};
    }
    setIsCanLoad(true);
    let reset = await setTeam([]);
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/blog?page=${pageBlog}&perPage=3`,
      {
        headers: headers,
      },
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setIsLoader(false);
        setPageBlog((prev) => prev + 1);
        setBlog(data?.blogs);
      });
  };
  const uploadMoreBlog = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : null;
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/blog?page=${pageBlog}&perPage=3`,
      {
        headers: headers,
      },
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setPageBlog((prev) => prev + 1);
        //setIsLoader(false)
        /*if(data.teams.length <= 11 && current === 1){
        setIsCanLoad(false)
        return
      }*/
        if (data.blogs.length <= 0) {
          setIsCanLoadBlog(false);
          return;
        } else {
          setBlog((prev) => [...prev, ...data?.blogs]);
          if (data.blogs.length <= 2) setIsCanLoadBlog(false);
        }
      });
  };
  const fetchTeam = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers;
    setIsLoader(true);
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {};
    }
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/teams?page=${page}&perPage=12`,
      {
        headers: headers,
      },
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setTimeout(() => {
          setIsLoader(false);
        }, 500);
        setTeam(data?.teams);
        startAffiliate();
      });
  };
  const fetchWinCoupon = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers;
    setWinLoader(true);
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {};
    }
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/wins?page=${winPage}&perPage=8${
        orderBy ? `&orderDirection=${orderBy}` : ""
      }${orderWin ? `&order=${orderWin}` : ""}${
        filterWin ? `&gameType=${filterWin}` : ""
      }${search ? `&search=${search}` : ""}${
        statusWin?.value ? `&state=${statusWin?.value}` : ""
      }&outdate=1`,
      {
        headers: headers,
      },
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data?.list?.length > 0) {
          let updateRate = data?.list?.map((win) => {
            let time = moment(win?.coupon?.data?.closeTime);
            let currTime = moment(new Date());
            if (time > currTime) {
              win.win.ratt = 0;
              return win;
            } else {
              return win;
            }
          });
          setWin(updateRate);
          let count = data.totalCount;
          let totalPage = count / 8;
          setWinPageCount(Math.ceil(totalPage));
        } else {
          setWin(null);
        }
        setTimeout(() => {
          setWinLoader(false);
        }, 500);
      });
  };
  const fetchWinGroup = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers;
    setWinLoaderGrop(true);
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {};
    }
    /*params
    ${statusWin?.value ? `&state=${statusWin?.value}` : ''}*/
    fetch(
      `${
        process.env.REACT_APP_API_ENDPOINT
      }/wins/europatipset-grouped?page=${winPageGroup}&perPage=8${
        orderByGroup ? `&orderDirection=${orderByGroup}` : ""
      }${orderWinGroup ? `&order=${orderWinGroup}` : ""}${
        search ? `&search=${search}` : ""
      }&outdate=0&isOctoberFeature=${isSM ? "true" : "false"}`,
      {
        headers: headers,
      },
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data?.list?.length > 0) {
          let updateList = data?.list?.map((li) => {
            li.open = false;
            return li;
          });
          setGroupWin(updateList);
          let count = data.totalCount;
          let totalPage = count / 8;
          setWinPageTotalGroup(Math.ceil(totalPage));
        } else {
          setGroupWin(null);
        }
        setTimeout(() => {
          setWinLoaderGrop(false);
        }, 500);
      });
  };
  const fetchLottery = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers;
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {};
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/lottery`, {
      headers: headers,
    })
      .then((res) => {
        if (res.ok) return res.json();
        else return { reponse: res.json, error: true };
      })
      .then((data) => {
        if (data?.lottery) {
          let checkEmpy = JSON.stringify(data?.lottery);
          if (checkEmpy === "{}") {
            setLottery((prev) => ({
              ...prev,
              title: "",
              text: "",
              hidden: true,
            }));
            return;
          }
          setLottery((prev) => ({
            ...prev,
            title: data?.lottery?.title,
            text: data?.lottery?.text,
            hidden: data?.lottery?.hidden,
          }));
        } else {
          //setWin(null)
        }
        setTimeout(() => {
          //setWinLoader(false)
        }, 500);
      });
  };
  //Fetch Lottery
  const fetchLotteryPhoto = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers;
    setLoaderLottery((prev) => ({ ...prev, img: true }));
    //setWinLoader(true)
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {};
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/lotteryPhoto`, {
      headers: headers,
    })
      .then((res) => {
        if (res.ok) return res.blob();
        else return null;
      })
      .then((data) => {
        if (data == null) return;
        let url = URL.createObjectURL(data);
        setLottery((prev) => ({ ...prev, photo: url }));
      })
      .finally(() => {
        setTimeout(() => {
          setLoaderLottery((prev) => ({ ...prev, img: false }));
        }, 500);
      });
  };
  useEffect(() => {
    fetchWinCoupon(); // Always fetch

    // Conditionally fetch based on isSM
    if (isSM) {
      fetchWinGroup();
    }
  }, [
    winPage,
    orderBy,
    orderWin,
    filterWin,
    statusWin,
    winPageGroup,
    orderByGroup,
    orderWinGroup,
    statusWin,
    isSM,
  ]);

  useEffect(() => {
    let timer = setTimeout(() => {
      fetchWinCoupon(); // Always fetch

      // Conditionally fetch based on isSM
      if (isSM) {
        fetchWinGroup();
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [search, isSM]); // Added isSM to dependencies
  return (
    <>
      <ActiveCreatePopup
        isModalOpen={isModal}
        setIsModalOpen={setIsModal}
        Submit={handleSubmit}
        setIsLoader={setIsLoader}
        updateTeam={updateTeam}
      />
      <Autorized isModalOpen={isModalLogin} setIsModalOpen={setIsModalLogin} />
      <HomeHeader />
      <InnerContainer>
        <Video
          muted
          autoPlay // Autoplay in Chrome requires video to be muted.
          loop
          playsInline
          mp4={VideoHeroMp4}
          webm={VideoHeroWebm}
          img={VideoHeroImage}
          alt={`Sorry, your browser doesn't support embedded videos.`}
        />
      </InnerContainer>

      <InnerContainer>
        <h2 className="section-title">SPELA NU</h2>
        <ActiveGamesBoxes />
      </InnerContainer>

      {/*<PageContent>*/}
      {/*  <OneHalf>*/}
      {/*    <ArticlePanel>*/}
      {/*      <h3>Nytt högklassigt reduceringsverktyg</h3>*/}

      {/*      <p>*/}
      {/*        “Av poolspelare, för poolspelare” skulle man kunna säga. <em>Reducering.se</em> är ett helt nytt*/}
      {/*        reduceringsverktyg för Svenska Spel Sport och Casinos poolspelsprodukter, framtaget av ett team med lång erfarenhet av*/}
      {/*        poolspel och av att spela reducerat.*/}
      {/*      </p>*/}

      {/*      <p>*/}
      {/*        Verktyget är gratis att använda och innehåller många nya funktioner, som kommer vara till stor hjälp i*/}
      {/*        jakten på 8 respektive 13 rätt! Därtill erbjuds även en liverättning - också helt gratis - som ger en*/}
      {/*        trevlig och underhållande upplevelse efter att spelet lämnats in. Välkommen!*/}
      {/*      </p>*/}
      {/*    </ArticlePanel>*/}

      {/*    <div style={{marginTop: "5px"}}>*/}
      {/*      <HowItWorksPanel />*/}
      {/*    </div>*/}
      {/*  </OneHalf>*/}

      {/*  <OneHalf>*/}
      {/*      <ArticlePanelWithLink>*/}

      {/*      */}

      {/*</OneHalf>
      </PageContent>*/}
      <InnerContainer>
        <WinSection
          isEditing={false}
          lottery={lottery}
          loader={loaderLottery}
        />
      </InnerContainer>

      {/*        <h3>Tävla med Reducering.se och vinn fotbollsresa värd  <span style={{whiteSpace: 'nowrap'}}>10 000 kr!</span></h3>*/}

      {/*        <p style={{}}>*/}
      {/*        Reducering.se ligger äntligen live och det ska givetvis uppmärksammas! Hur? Jo, vi smäller av en*/}
      {/*        lanseringstävling under de första veckorna där vinnaren kammar hem en fotbollsresa för två till ett*/}
      {/*        värde av <span style={{whiteSpace: "nowrap"}}>10 000 kr!</span> Vi har (självklart) valt att kalla tävlingen för <b>#reduceringsresan</b> och den pågår*/}
      {/*        fr. o m. lördag 14/8 i samband med premiäromgången av Premier League till torsdag 30/9 - d.v.s i*/}
      {/*        nästan sju veckor. */}
      {/*        </p>*/}
      {/*        <p>*/}
      {/*        Hur gör man då för att tävla? Det är mycket enkelt. Kort sagt; skapa ett system med <a href="https://www.reducering.se/">Reducering.se </a>*/}
      {/*        och dela på Twitter, så har du chansen! För utförligare instruktioner och information om tävlingen;*/}
      {/*        klicka på <i>“Läs mer”</i> nere till höger och se själv hur enkelt det är. Varmt välkommen till <a href="https://www.reducering.se/">Reducering.se </a>*/}
      {/*        och lycka till!*/}
      {/*        </p>*/}

      {/*          <img src={contestPrize} alt="Tävla och vinn fina priser" className="contestImage"></img>*/}
      {/*        */}
      {/*          <Link to='/tavling' className='readMore'>*/}
      {/*            <span>Läs mer</span>*/}
      {/*          </Link>*/}
      {/*        */}
      {/*      </ArticlePanelWithLink>*/}
      {/*    */}
      {/*    */}
      {/*    /!* USUALLY ITS JUST THIS HERE, NOW ITS MOVED *!/*/}
      {/*    /!* <HowItWorksPanel /> *!/*/}

      {/*  </OneHalf>*/}
      {/*</PageContent>*/}
      {/*<InnerContainer>
        <WinSection/>
      </InnerContainer>*/}
      <InnerContainer>
        <h2 className="h2__title h2__title--paddingTop" ref={scrollTo}>
          AndeLslag
        </h2>
        <Card
          isLoader={isLoader}
          setIsLoader={setIsLoader}
          team={team}
          setTypeSort={setTypeSort}
          resetSort={resetSort}
          IsCanLoad={isCanLoad}
          uploadMore={uploadMore}
          handlDelete={handlDelete}
          setIsModal={() => setIsModal(true)}
          setIsModalLogin={setIsModalLogin}
        />
      </InnerContainer>

      <InnerContainer>
        <WinTable
          win={win}
          group={groupWin}
          setGroup={setGroupWin}
          setPages={setWinPage}
          setPagesTab={setWinPageGroup}
          totalPage={winPageCount}
          totalPageTab={winPageTotalGroup}
          loader={loaderWin}
          loaderTab={loaderWinGrop}
          setOrder={setOrderBy}
          setOrderTab={setOrderByGroup}
          search={search}
          setSearch={setSearch}
          setOrderWin={setOrderWin}
          setOrderWinTab={setOrderWinGroup}
          setFilterWin={setFilterWin}
          filterWin={filterWin}
          statusWin={statusWin}
          setStatusWin={setStatusWin}
        />
      </InnerContainer>

      <InnerContainer>
        <BlogMain
          Blog={blog}
          isCanLoadBlog={isCanLoadBlog}
          uploadMoreBlog={uploadMoreBlog}
        ></BlogMain>
      </InnerContainer>

      <InnerContainer>
        <About />
      </InnerContainer>
      <InnerContainer>
        <HowIsWork />
      </InnerContainer>
      <InnerContainer>
        <Footer />
      </InnerContainer>
      <ContestPopup />
      <V2Popup />
    </>
  );
}

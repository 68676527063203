import React from 'react'
import Slider, { Handle, Range } from 'rc-slider'
import * as utils from 'rc-slider/lib/utils.js'
import 'rc-slider/assets/index.css'
import {
  SliderContainer,
  handleStyle,
  secondHandleStyle,
  railStyle,
  trackStyle
} from './Trends.elements'

class CustomHandle extends Handle {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      prefixCls,
      vertical,
      reverse,
      offset,
      style,
      disabled,
      min,
      max,
      value,
      tabIndex,
      ariaLabel,
      ariaLabelledBy,
      ariaValueTextFormatter,
      index,
      ...restProps
    } = this.props

    const className =
      this.props.className +
      (this.state.clickFocused ? ` ${prefixCls}-handle-click-focused` : '')
    const positionStyle = vertical
      ? {
          [reverse ? 'top' : 'bottom']: `${offset}%`,
          [reverse ? 'bottom' : 'top']: 'auto'
        }
      : {
          [reverse ? 'right' : 'left']: `${offset}%`,
          [reverse ? 'left' : 'right']: 'auto',
          transform: index === 0 ? `translateX(-100%)` : ''
        }

    const elStyle = {
      ...style,
      ...positionStyle
    }

    let mergedTabIndex = tabIndex || 0
    if (disabled || tabIndex === null) {
      mergedTabIndex = null
    }

    let ariaValueText
    if (ariaValueTextFormatter) {
      ariaValueText = ariaValueTextFormatter(value)
    }

    return (
      <div
        ref={this.setHandleRef}
        tabIndex={mergedTabIndex}
        {...restProps}
        className={className}
        style={elStyle}
        onBlur={this.handleBlur}
        onKeyDown={this.handleKeyDown}
        onMouseDown={this.handleMouseDown}
        index={index}
        dragging={"false"}
        // aria attribute
        role='slider'
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        aria-disabled={!!disabled}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-valuetext={ariaValueText}
      />
    )
  }
}

class CustomRange extends Range {
  constructor(props) {
    super(props)
  }

  onDown = (e, position) => {
    let p = position
    const { draggableTrack, vertical: isVertical } = this.props
    const { bounds } = this.state

    const value =
      draggableTrack && this.positionGetValue ? this.positionGetValue(p) || [] : []

    const inPoint = utils.isEventFromHandle(e, this.handlesRefs)
    this.dragTrack =
      draggableTrack &&
      bounds.length >= 2 &&
      !inPoint &&
      !value
        .map((n, i) => {
          const v = !i ? n >= bounds[i] : true
          return i === value.length - 1 ? n <= bounds[i] : v
        })
        .some(c => !c)

    if (this.dragTrack) {
      this.dragOffset = p
      this.startBounds = [...bounds]
    } else {
      if (!inPoint) {
        this.dragOffset = 0
      } else {
        const isRightOrLeft = e.target.getAttribute('index')
        const handlePosition =
          utils.getHandleCenterPosition(isVertical, e.target) +
          (isRightOrLeft === '0' ? +25 : -25)
        this.dragOffset = p - handlePosition //;
        p = handlePosition
      }
      this.onStart(p)
    }
  }
  onFocus = e => {
    return
  }
}

export default function HorizontalSlider({
  trendStart,
  toCurrentTime,
  onAfterChange,
  isActive,
  selectedTime
}) {
  //console.log('toCurrentTime', toCurrentTime)
  const sliderHandle = ev => {
    const { value, index, dragging } = ev
    let time = new Date(value).toTimeString().substring(0, 5)
    return (
      <CustomHandle dragging={dragging.toString()} {...ev} key={index}>
        {time}
      </CustomHandle>
    )
  }

  return (
    <SliderContainer>
      <CustomRange
        railStyle={railStyle}
        trackStyle={[trackStyle]}
        handleStyle={[handleStyle, secondHandleStyle]}
        handle={ev => sliderHandle(ev)}
        allowCross={false}
        pushable={30 * 60 * 1000}
        defaultValue={selectedTime}
        min={trendStart}
        max={toCurrentTime}
        step={5 * 60 * 1000}
        onAfterChange={ev => onAfterChange(ev)}
        disabled={!isActive}
      />
    </SliderContainer>
  )
}

import styled, { css } from 'styled-components'

export const BombenBigContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #3F5059;
  font-size: 1.4rem;
  color: #FFFFFF;
  padding: 1.2rem 1rem;
  border-radius: 0.375rem;
  justify-content: space-between;
  font-weight: 500;
  input{
   margin-left: auto;
  }
  ${props=>props.area && css`
    grid-area: game;
`}
`
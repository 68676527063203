import styled from 'styled-components'
import {
  breakPoints,
  colors,
  font,
  spacers,
  borderRadius,
  headerHeight
} from '../../../styles/constants'

export const HowContainer = styled.div`
   width: 100%;
   padding:5rem 0 ;
   @media screen and (max-width: 468px){
      padding: 3rem 0;
    }
  h2 {
    font-size:2.8rem;
    font-weight: ${font.weight.bold};
    @media screen and (max-width: 968px){
      text-align: center;
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }
  }
  p {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 968px){
      font-size: 1.4rem;
      padding: 0 1rem;
    }
  }
  .video{
    max-width:41.1rem;
    //margin: 0 auto;
    width: 100%;
    /*video {
      width: 100%;
      min-height: 23.1rem;
      object-fit: cover;
      border-radius: 1rem;
    }*/
    iframe {
    border-radius: 1rem;
    }
     @media screen and (max-width: 468px){
      margin: 0 auto;
      padding: 0 0.2rem;
      display: flex;
      justify-content: center;
      iframe {
        max-width: 320px;
      }
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
     @media screen and (max-width: 968px){
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .info {
    max-width: 58rem;
    width: 100%;
    //margin: 0 auto;
    margin-top:2rem;
    @media screen and (max-width: 968px){
      text-align: center;
      order: 2;
    }
  }
  a {
    text-decoration: underline;
    color: ${colors.anchor};
  }
  .link {
    display: flex;
    color: ${colors.white};
    align-items: center;
    justify-content: center;
    min-width: 15.2rem;
    text-decoration: none;
    max-width: 15.8rem;
    min-height: 4.2rem;
    border-radius: 0.5rem;
    border: none;
    box-shadow: 0px 3px 12px #0000000A;
    font-size: 1.8rem;
    margin-top: 1.4rem;
    background-color: ${colors.anchor};
    font-weight: ${font.weight.bold};
    @media screen and (max-width: 968px){
      margin: 0 auto;
    }
    @media screen and (max-width: 468px){
      margin: 0 auto;
      font-size: 1.2rem;
      min-height: 3rem;
      min-width: 9rem;
    }
  }
  
`
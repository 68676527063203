import styled from 'styled-components'
import { breakPoints, spacers, colors, font } from '../../styles/constants'
import { OneThird, TwoThirds } from '../../styles/globalStyle'

export const LiveResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`

import React from 'react'
import { ReductionPopup, PopupGamesList } from './Popup.elements'
import 'reactjs-popup/dist/index.css'
import { Link } from 'react-router-dom'

import {getActiveDate, importGameTypeLogos, sortDates} from '../../utils/helperFunctions'

import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import { colors } from '../../styles/constants'
import Rull from '../../assets/rullpott-reducering-wordmark.png'

export default function ActiveGamesPopup({ gameTypeData, isModalOpen, setIsModalOpen }) {
  return (
    <ReductionPopup
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false)
      }}
      position='center'
    >
      <>
        <button className='modalClose' onClick={() => setIsModalOpen(false)}>
          <CloseIcon alt='close' />
        </button>
        <PopupGamesList gameType={colors[`${gameTypeData.gameType}`]}>
          {!gameTypeData?.draws?.length
              ? null
              : gameTypeData?.draws?.sort(sortDates).map((draw, idx) => {
                return (
                    <li className='nav-text' key={`popupGameItem-${idx}`} onClick={() => setIsModalOpen(false)}>
                      <Link
                          to={{
                            pathname: `/reducera/${gameTypeData?.gameType}/${gameTypeData?.draws[idx]?.drawNumber}`
                          }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                          <span>
                            <p>
                              Spelstopp <span>{getActiveDate(gameTypeData, idx, true)}</span>
                              {gameTypeData.gameType === 'bomben' && <span>{gameTypeData?.draws[idx]?.drawComment}</span>}
                            </p>
                          </span>
                          <div style={{flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            {renderImagesForDraw(draw).map((image, imageIdx) => (
                                <img key={imageIdx} style={{minWidth: '60px', paddingLeft: '10px', maxHeight: '25px'}} {...image.props} />
                            ))}
                          </div>
                        </div>
                      </Link>
                    </li>

                )
              })}
        </PopupGamesList>
      </>
    </ReductionPopup>
  )
}

function renderImagesForDraw(draw) {
  const imagesToRender = [];
  const images = importGameTypeLogos(require.context('../../assets/', false, /.*\.svg$/));

  if (draw.jackpotItems) {
    imagesToRender.push(
        <img
            src={images['jackpot-reducering-wordmark.svg'].default}
            alt='Jackpot'
            className='jackpot'
        />
    );
  }

  if (draw.fund && parseFloat(draw.fund.extraMoney) > 0) {
    imagesToRender.push(
        <img
            src={images['extrapengar-reducering-wordmark.svg'].default}
            alt='Extra pengar'
            className='extra-pengar'
        />
    );
  }

  if (draw.fund && parseFloat(draw.fund.rolloverIn) > 0) {
    imagesToRender.push(
        <img
            src={Rull}
            alt='rullpott'
            className='extra-pengar'
        />
    );
  }

  return imagesToRender;
}


import React from 'react'
import { Link } from 'react-router-dom'
import {BlogItem} from './Blog.elements'
import svsPOwerplay from '../../assets/Powerplay-blog.png'
import svsTop from '../../assets/Topptipset-blog.png'
import svsStryk from '../../assets/Stryktipset-blog.png'
import svsEuro from '../../assets/Europtipset.png'
import moment from 'moment'
import { gameTurnoverWithSpace, getActiveDates } from '../../utils/helperFunctions'

const BlogCard = ({blog}) => {

  const game = [{
    game:'powerplay',
    img:svsPOwerplay,
    color:'#a00814'
  },
    {
      game:'topptipset',
      img:svsTop,
      color:'#f29400'
    },
    {
      game:'stryktipset',
      img:svsStryk,
      color:'#043266'
    },
    {
      game: 'europatipset',
      img:svsEuro,
      color:'#008733'
    }]
  const whatGame = (gameType) => {
    const picture = game.find(game => game.game === gameType)
    return picture
  }

  const { day, time } = getActiveDates(blog?.draw?.closeTime);

  return (
    <>
      <BlogItem>
      <div className='head' style={{background:whatGame(blog.gameType).color }}>
        <Link className='link'  to={{ pathname: `blog/${blog.gameType}/${blog._id}` }}>
          <img className='img' src={whatGame(blog.gameType).img}/>
        </Link>
        <div>
          <p>Omsättning {gameTurnoverWithSpace(blog?.draw?.turnover)} kr </p>
          <p>Spelstopp {day} {time}</p>
        </div>
      </div>
      <div className='body'>
        <h4>{blog.title}</h4>
        <p>{moment(blog.date).format('D MMM HH:mm')}</p>
        <Link className='link-go' to={{pathname: `blog/${blog.gameType}/${blog._id}`}}>
          LÄS MER
          <span className='icon'/>
        </Link>
      </div>
    </BlogItem>
    </>
  )
}

export default BlogCard
import React, { useEffect, useState } from 'react'
import {
  ColorGrid,
  SelectButton,
  SelectButtonInner,
  Circle,
  SignDistribution,
  Distribution,
  Odds,
  DistributionOdds
} from './SelectBet.elements'
import { colors } from '../../../../styles/constants'

export default function SelectBet({
  loadedCouponData,
  fcEvent,
  event,
  odds,
  distribution,
  sign
}) {
  const [bg, setBg] = useState('')

  const currentColorArr = loadedCouponData?.colors.map(val => {
    switch (val) {
      case 'red':
        return colors.reduceRed
      case 'blue':
        return colors.reduceBlue
      case 'green':
        return colors.reduceGreen
      case 'yellow':
        return colors.reduceYellow
      default:
        return '#465a65'
    }
  })

  const ccLength = loadedCouponData?.colors.length

  useEffect(() => {
    let width = 100
    if (ccLength === 1) {
      //setBgWidth(100 / ccLength)
      setBg(`background: ${currentColorArr[0]};`)
    } else if (ccLength === 2) {
      setBg(`background: linear-gradient(
        90deg, ${currentColorArr[0]} 0% ${width / ccLength}%,
        ${currentColorArr[1]} ${width / ccLength}% 100%
      );`)
    } else if (ccLength === 3) {
      setBg(`background: linear-gradient(
        90deg, ${currentColorArr[0]} 0% ${width / ccLength}%,
        ${currentColorArr[1]} ${width / ccLength}% ${(width / ccLength) * 2}%, ${
        currentColorArr[2]
      } ${(width / ccLength) * 2}% 100%
      );`)
    } else if (ccLength === 4) {
      setBg(`background: linear-gradient(
        90deg, ${currentColorArr[0]} 0% ${width / ccLength}%,
        ${currentColorArr[1]} ${width / ccLength}% ${(width / ccLength) * 2}%, ${
        currentColorArr[2]
      } ${(width / ccLength) * 2}% ${(width / ccLength) * 3}%, ${currentColorArr[3]} ${
        (width / ccLength) * 3
      }% 100%
      );`)
    } else {
      setBg(`background: ${colors.vividCyan};`)
    }
  }, [colors])

  return (
    <ColorGrid
      isSelected={loadedCouponData?.isSelected}
      bg={loadedCouponData?.isSelected ? bg : `background: ${colors.darkGrey};`}
      isOutcome={sign === fcEvent.outcome}
    >
      <SelectButton isSelected={loadedCouponData?.isSelected}>
        {/* <SignDistribution isSelected={loadedCouponData?.isSelected}>
          {loadedCouponData?.isSelected
            ? loadedCouponData?.signDistribution.toFixed(2)
            : '-'}
        </SignDistribution> */}

        <SelectButtonInner>
          <Circle
            isSelected={loadedCouponData?.isSelected}
            points={loadedCouponData?.points}
          >
            {loadedCouponData?.points || 'P'}
          </Circle>

          <DistributionOdds>
            <Distribution>{distribution}%</Distribution>
            <Odds>{odds}</Odds>
          </DistributionOdds>
        </SelectButtonInner>
      </SelectButton>
    </ColorGrid>
  )
}

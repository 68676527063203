import styled, { css } from 'styled-components'
import { font } from '../../../styles/constants'

export const LoaderCouponContainer = styled.div`
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.375rem;
    display: flex;
    grid-gap: 7px;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    color: rgb(255, 255, 255);
    background: linear-gradient(-45deg, rgb(54, 71, 79) 0%, rgb(54, 71, 79) 35%, rgb(89, 110, 121) 65%);
    ${props => props.area && css`
    grid-area: load;
  `}
    .info{
      font-weight: ${font.weight.bold};
      font-size: 1.4rem;
      line-height: 1.5rem;
      font-weight: 500;
      flex-shrink: 0;
    }
    .input{
      width: 100%;
      background: rgb(38, 50, 56);
      color: rgb(255, 255, 255);
      font-weight: 600;
      border: none;
      padding: 0.5rem;
      border-radius: 0.3rem;
      height: 29px;
      &::placeholder{
        font-size: 1rem;
      }
    }
    .button{
      border-radius: 50%;
      background-color: rgb(102, 124, 137);
      color: rgb(69, 90, 100);
      width: 3.4rem;
      height: 3.4rem;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      svg{
        width: 2.5rem;
        height: 2.5rem;
      }
    }
`
import React from 'react'
import { Bar } from '@reactchartjs/react-chart.js'
import { colors } from '../../../../styles/constants'
import { DividendContainer } from '../CouponSettings.elements'

export default function DividendForecast({forecastData}) {

  let labs = forecastData.map(val => val.label)
  let dat = forecastData.map(val => val.percent)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      enabled: false
    },
    legend: {
      display: false
    },

    scales: {
      yAxes: [{
        ticks: {
          fontColor: colors.white,
          max: !forecastData.length ? 100 :  undefined,
          min: 0,
          stepSize: 5,
          padding: 10,

          callback: (val) => {
            return  `${val}`
          }
        },
        gridLines: {
          display: true,
          drawTicks: false,
          drawBorder: false,
          lineWidth: 2,
          offsetGridLines: false,
          color: colors.grey,
          zeroLineWidth: 2,
          zeroLineColor: colors.grey,
        }
      }],
      xAxes: [{
        ticks: {
          stepSize: 1,
          autoSkip: false,
          fontColor: colors.white
        },
        gridLines: {
          display: false
        }
      }]
    }
  }

  const data = {
    labels: labs,
    datasets: [{
      data: dat,
      backgroundColor: colors.vividCyan,
      barThickness: 5
    }]
  };

  return (
    <>
      <DividendContainer>
        <Bar data={data} options={options} />
      </DividendContainer>
    </>
  )
}
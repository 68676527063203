import { ReductionPopup } from './Popup.elements'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'


export default function DeleteGamePopup({open, toggleOpen, OK, deleteCouponId, nickname}) {
  return (
    <ReductionPopup
      open={open}
      onClose={
          () => toggleOpen(false)
        }
    >
      <button className='modalClose' onClick={() => toggleOpen(false)}>
        <CloseIcon alt='close' />
      </button>

      <h3>
        {`Vänligen bekräfta att du vill ta bort kupong: ${nickname.length ? nickname : "(namnlös)"}`}
      </h3>

      <button onClick={() =>  {
        OK(deleteCouponId)
        toggleOpen(false)
      }}>
        OK
      </button>
      <button onClick={() => {
          toggleOpen(false)
      }}>
        Avbryt
      </button>

    </ReductionPopup>
  )
}


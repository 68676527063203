import { useState } from 'react'
import { ReductionPopup } from './Popup.elements'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import { ReactComponent as SendIcon } from '../../assets/send.svg'
import { ReactComponent as ClipboardIcon } from '../../assets/clipboard.svg'

export default function ShareCouponPopup({
  shareModal,
  setShareModal,
  couponData: { id, url }
}) {
  const [sendToEmail, setSendToEmail] = useState('')
  const [copied, setCopied] = useState(false)

  const handleCopied = () => {
    let copyAnimation
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopied(true)
        copyAnimation = setInterval(() => {
          setCopied(false)
          clearInterval(copyAnimation)
        }, 600)
      })
      .catch(err => console.log('Could not copy text:', err))
  }

  return (
    <ReductionPopup
      open={shareModal}
      onClose={() => setShareModal(false)}
      copied={copied}
    >
      <button className='modalClose' onClick={() => setShareModal(false)}>
        <CloseIcon alt='close' />
      </button>

      <label>Kopiera kupong-ID </label>
      <div className='handinSave' style={{ display: 'flex' }}>

        <button className='icon' onClick={() => handleCopied()}>
          <ClipboardIcon alt='Copy to clipboard' />
        </button>

        <div>
          {/* {id} */}
          Klicka på ikonen för att kopiera kupongen.
          <span className='copied' aria-hidden='true'>
            Kupongen är kopierad till "​urklipp"
          </span>
        </div>
      </div>

      <label>Dela kupong-ID via e-post </label>
      <div className='handinEmail' style={{ display: 'flex' }}>
        <a
          className='icon sendMailBtn'
          href={`mailto:${sendToEmail}?subject=Kupong ID&body=Kupong ID:%0A ${url}`}
        >
          <SendIcon alt='Send e-mail' />
        </a>
        <div>
          <input
            id='mailinput'
            type='email'
            placeholder='Skriv din e-postadress här'
            onChange={ev => setSendToEmail(ev.target.value)}
          />
        </div>
      </div>
    </ReductionPopup>
  )
}

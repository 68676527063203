import styled, { css } from 'styled-components'

export const WinTeamContainer = styled.div`
  background: linear-gradient(90deg,#596E79 0.7%,#596E79 31.88%,#36474F 63.06%,#36474F 100%);
  max-height: 156px;
  height: 100%;
  padding: 16px 10px;
  border-radius: 0.375rem;
  width: 100%;
  grid-area: ${props=>props.area};
  ${props => props.active && css`
     cursor: not-allowed;
     select{
      cursor: not-allowed!important;
     }
  `}
  .head{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .info{
    font-size: 1.4rem;
    font-weight: 500;
  }
  .body{
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .color-wrapper{
    display: flex;
    grid-gap: 12px;
    align-items: center;
  }
  .color{
    display: flex;
    grid-gap: 6px;
    align-items: center;
    span{
      color: #8DA2AB;
    }
  }
  select{
    cursor: pointer;
    width: 4.5rem;
    height: 2rem;
    padding: 0;
    text-indent: 0.25rem;
  }
  .winner{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 0.4rem;
    margin-bottom: 16px;
    &:nth-child(odd){
      border-right: 1px solid #667C89;
    }
    &:nth-child(even){
      justify-content: flex-end;
    }
    &__no_result{
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      text-transform: uppercase;
      color: #263238;
      justify-content: center!important;
    }
  
  }
`
export const WinnerTeamItem = styled.div`
  width: 6rem;
  height: 3.6rem;
  line-height: 3.6rem;
  text-align: center;
  border-radius: 0.4rem;
  background-color: #263238;
  cursor: pointer;
  font-family: 'Fugaz One', cursive;
  color: #FFFFFF;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 600;
  ${props => props.active && css`
    background: #40C7A8;
  `}
  ${props => props.disabled && css`
    cursor: not-allowed;
    opacity: 0.7
  `}
 
`
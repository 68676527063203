import styled from 'styled-components'
import {
  breakPoints,
  font,
  spacers,
  colors,
  borderRadius,
  transition
} from '../../styles/constants'

export const NavBar = styled.div`
  display: flex;
  //margin-left: auto;
  margin-right: 0;
  display: block;

  height: 7rem;
  width: 7rem;

  .menu-bars {
    justify-content: center;
    align-content: center;
    display: flex;
    cursor: pointer;

    &:link,
    &:visited {
      text-decoration: none;
      color: #273238;
    }
  }

  @media screen and (max-width: 959px) {
    height: 6rem;
  }

  img {
    @media screen and (max-width: 959px) {
      height: 4rem;
      width: 4rem;
    }
  }

  .menu-btn {
    display: block;
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }
`

export const NavBarMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 360px;
  max-height: calc(100vh - 4rem);
  z-index: 6;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  margin-top: -2rem;
  transition: 350ms;
  background: rgb(38 50 56 / 95%);
  border: 1px solid ${colors.vividCyan};
  border-radius: ${borderRadius.medium};
  padding: ${spacers.xlarge};
  box-shadow: 0px 5px 20px -5px black;

  opacity: 0;
  transition: ${transition.menu};

  pointer-events: none;

  @media screen and (min-width: ${breakPoints.medium}) {
    top: 2rem;
    right: 2rem;
    margin-bottom: -2rem;
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    max-height: 100%;
  }

  &.active {
    margin-top: 0;
    pointer-events: auto;
    opacity: 1;
  }

  .navbar-close {
    cursor: pointer;
    width: fit-content;
    margin-left: auto;
    margin-top: -1rem;
    margin-right: -1rem;

    filter: invert(47%) sepia(18%) saturate(540%) hue-rotate(155deg) brightness(95%)
      contrast(88%);

    &:hover {
      filter: invert(94%) sepia(14%) saturate(114%) hue-rotate(154deg) brightness(93%)
        contrast(86%);
    }
  }

  .nav-menu-items {
    position: relative;
    overflow: auto;
    font-size: ${font.size.small};
    text-transform: uppercase;
    color: ${colors.white};

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      &:before,
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        pointer-events: none;
        left: ${spacers.xlarge};
        right: ${spacers.xlarge};
      }
    }

    .nav-text {
      img {
        height: 3rem;
        width: auto;
        margin-top: -0.5rem;
        margin-bottom: -0.5rem;
        align-self: flex-start;

        + span {
          margin-left: auto;
        }
      }

      &.powerplay {
        img {
          height: 2rem;
          margin-top: -0.25rem;
          margin-bottom: -0.25rem;
        }
      }

      &.lrPowerplay {
        img {
          height: 2rem;
          margin-top: -0.25rem;
          margin-bottom: -0.25rem;
        }
      }

      &.auth {
        border: 1px solid ${colors.vividCyan};
      }

      .nav-button {
        flex: 1;
        cursor: pointer;
        height: 100%;
        border: none;
        background-color: transparent;
        color: inherit;
        text-transform: inherit;
        font-size: inherit;
        font-weight: ${font.weight.semiBold};
        text-align: left;
        padding: ${spacers.large} ${spacers.xlarge};
      }
    }
    .show-more{
      display: flex;
      padding: 0 20px;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      cursor: pointer;
      font-weight: 500;
      .arrow{
        width: 15px;
        fill: white;
        transition: transform ease-in 250ms;
      }
      .up{
        transform: rotate(180deg);
      }
    }
    > ul {
      border-radius: ${borderRadius.small};
      overflow: auto;
      height: 100%;

      > li {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: ${colors.grey};
      margin-bottom: ${spacers.xxsmall};
      min-height: 4rem;

      > a {
        font-weight: ${font.weight.semiBold};
        color: ${colors.white};
        display: block;
        display: flex;
        align-items: center;
        padding: ${spacers.large} ${spacers.xlarge};

        span {
          margin-left: ${spacers.xlarge};
          text-transform: capitalize;
        }
      }

      &.stryktipset {
        background-color: ${colors.stryktipset};

        + *:not(.stryktipset) {
          margin-top: ${spacers.small};
        }
      }

      &.europatipset {
        background-color: ${colors.europatipset};

        + *:not(.europatipset) {
          margin-top: ${spacers.small};
        }
      }

      &.topptipset {
        background-color: ${colors.topptipset};

        + *:not(.topptipset) {
          margin-top: ${spacers.small};
        }
      }

      &.powerplay {
        background-color: ${colors.powerplay};

        + *:not(.powerplay) {
          margin-top: ${spacers.small};
        }
      }

      &.liverattning {
        background-color: transparent;

        button {
          height: 4rem;
          display: flex;
          justify-content: space-between;
          background-color: ${colors.svenskaSpelRed};
          border: none;
          cursor: pointer;
          align-items: center;
          color: ${colors.white};
          text-transform: uppercase;
          font-weight: ${font.weight.semiBold};
          font-size: ${font.size.small};
          padding: ${spacers.large} ${spacers.xlarge};
          padding-right: ${spacers.small};

          svg {
            fill: ${colors.white};
            width: 2.8rem;
            height: 2.8rem;
            margin-right: 0.25rem;

            transform: ${({ showliveresult }) =>
              showliveresult ? 'rotate(0deg)' : 'rotate(-225deg)'};
            transition: transform 250ms cubic-bezier(0.02, 1.02, 0.8, 1);
          }

          &[disabled] {
            cursor: initial;
          }
        }

        li {
          background-color: ${colors.svenskaSpelRed};

          &:first-child {
            margin-top: ${spacers.xxsmall};
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          text-transform: initial;
          font-weight: ${font.weight.normal};
          justify-content: space-between;
          padding: 0;
          flex: auto;

          span {
            margin-left: initial;
          }

          > *:first-child {
            padding: ${spacers.large} ${spacers.xlarge};
            margin-left: 0;
          }

          .gameType {
            display: flex;
            align-self: stretch;
            flex-direction: column;
            justify-content: center;
            margin: ${spacers.xsmall};
            width: 48%;

            img {
              margin: initial;
              align-self: center;
            }

            &.topptipset {
              background-color: ${colors.topptipset};
            }

            &.stryktipset {
              background-color: ${colors.stryktipset};
            }

            &.europatipset {
              background-color: ${colors.europatipset};
            }

            &.powerplay {
              background-color: ${colors.powerplay};

              img {
                height: 2rem;
              }
            }
          }
        }

        + *:not(.liverattning) {
          margin-top: ${spacers.small};
        }

        ul {
          max-height: ${({ showliveresult }) => (showliveresult ? '25rem' : '0')};
          opacity: ${({ showliveresult }) => (showliveresult ? '1' : '0')};
          overflow: hidden;
          transition: max-height
              ${({ showliveresult }) => (showliveresult ? '1150ms' : '100ms')}
              cubic-bezier(0.02, 1.02, 0.8, 1),
            opacity ${({ showliveresult }) => (showliveresult ? '250ms' : '100ms')}
              cubic-bezier(0.02, 1.02, 0.8, 1);
        }
      }
    }
  }
`

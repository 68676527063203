import styled, { css } from 'styled-components'
import { colors } from '../../../styles/constants'
import svn from '../../../assets/svs-blue-symbol.svg'
export const BombenButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #506974;
  font-size: 1.2rem;
  color: #CFD8D9;
  padding: 1rem;
  border-radius: 0.375rem;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
  border: none;
  cursor: pointer;
  grid-gap: 1rem;
  font-weight: 500;
  .img{
    width: 2rem;
    height: 2rem;
    filter: invert(99%) sepia(1%) saturate(5473%) hue-rotate(170deg) brightness(95%) contrast(80%);
  }
   ${props=>(props.types =='lama') && css`
      background-color: ${colors.svenskaSpelRed};
      color: ${colors.white};
      position: relative;
      overflow: hidden;
      .img{
        align-self: flex-start;
        width: 2.5rem;
        height: 2.5rem;
        margin-top: -0.4rem;
        margin-bottom: 0.4rem;
        filter: initial;
      }
      &:before{
        position: absolute;
        content: '';
        background-image: url(${svn});
        background-size: cover;
        background-position: right;
        width: 32px;
        height: 28px;
        top: 0;
        right: 0;
      }
    `}
   ${props=>(props.types =='live') && css`
      background-color: ${colors.reduceBlue};
      color: ${colors.white};
      position: relative;
      overflow: hidden;
      display: none;
      @media screen and (max-width: 768px){
      display: flex;
      justify-content: flex-end;
      }
    `}
   ${props =>props.variant && css`
    position: relative;
  `}
   .close{
      position: absolute;
      fill: #cfd8dc;
      width: 2rem;
      height: 2rem;
      right: 5px;
      top: 5px;
   }
`
import React from 'react'
import stryk1 from '../../assets/stryk1.png'
import stryk2 from '../../assets/stryk2.png'
import stryk3 from '../../assets/stryk3.png'
import power1 from '../../assets/power1.png'
import power2 from '../../assets/power2.png'
import power3 from '../../assets/power3.png'
import topp1 from '../../assets/topp1.png'
import topp2 from '../../assets/topp2.png'
import topp3 from '../../assets/topp3.png'
import euro1 from '../../assets/euro1.png'
import euro2 from '../../assets/euro2.png'
import euro3 from '../../assets/euro3.png'
import moment from 'moment'
import {BlogElement} from './Blog.elements'
import {useHistory} from 'react-router-dom'
const game = [{
  game:'powerplay',
  img:{'power-1':power1,'power-2':power2,'power-3':power3},
  },
  {
    game:'topptipset',
    img:{'topp-1':topp1,'topp-2':topp2,'topp-3':topp3},
  },
  {
    game:'stryktipset',
    img:{'stryk-1':stryk1,'stryk-2':stryk2,'stryk-3':stryk3},
  },
  {
    game: 'europatipset',
    img:{'euro-1':euro1,'euro-2':euro2,'euro-3':euro3},
  }]

const BlogSmall = ({blog}) => {
  const history = useHistory()
  const whatGame = (gameType) => {
    const picture = game.find(game => game.game === gameType)
    //picture.find()
    let img = picture.img[`${blog?.imageId}`]
    return img
  }
  const historyPush = ()=>{
    history.push(`/blog/${blog.gameType}/${blog._id}`)
  }
  return (
    <BlogElement onClick = {historyPush}>
      <img src={whatGame(blog?.gameType)}/>
      <div>
        <h3>{blog?.title}</h3>
        <span>{moment(blog?.date).format('MM/DD (HH:mm)')}</span>
      </div>
    </BlogElement>
  )
}

export default BlogSmall
import React from 'react';
import svsBlueSymbol from '../../assets/svs-blue-symbol.svg';
import remove from '../../assets/delete.png';
import Check from '../../assets/chevron-down-white.svg';
import OL from '../../assets/svs_product-powerplay.svg';
import EUR from '../../assets/svs_product_europatipset.svg';
import STR from '../../assets/svs_product_stryktipset.svg';
import TOP from '../../assets/svs_product_topptipset.svg';
import BOM from '../../assets/Bomben-icon.svg';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';

const CardElement = ({ item, handlDelete }) => {
  const formatDate = (date) => {
    let currentDate = moment(date).format('DD MMM HH:mm');
    return currentDate;
  };
  const images = [EUR, OL, TOP, STR];
  const whatType = () => {
    if (item.gameType === 'europatipset') {
      return EUR;
    } else if (item.gameType === 'stryktipset') {
      return STR;
    } else if (item.gameType === 'topptipset') {
      return TOP;
    } else if (item.gameType === 'bomben') {
      return BOM;
    } else {
      return OL;
    }
  };
  return (
    <a
      target="_blank"
      href={`${item.link}`}
      class={`team-card ${item?.priviledged ? 'team-card--active' : ''}`}
    >
      <div className="team-card__info">
        <p className="username">{item?.userInfo[0]?.username}</p>
        <a className="team-card__title">{item.title}</a>
        <span className="team-time">{formatDate(item?.date)}</span>
      </div>
      <div>
        <span className="price">{item.price} kr</span>
        <img src={whatType()} className="img" />
        {item?.deleteAllowed && (
          <div onClick={(e) => handlDelete(e, item._id)} className="remove">
            <img className="img-delete" src={remove} />
            <span className="delete">Delete</span>
          </div>
        )}
      </div>
    </a>
  );
};

export default CardElement;

import styled from 'styled-components'
import {
  breakPoints,
  colors,
  font,
  spacers,
  borderRadius,
  headerHeight
} from '../../../styles/constants'
import { GameTypeHeader,GameTypeHeaderContent } from '../../../components/GameTypeHeader/GameTypeHeader.elements'
export const BlogMain = styled(GameTypeHeader)`

`


export const BlogWrapper = styled.div`
  display:flex;
  flex-wrap: wrap-reverse;
  width:100%;
  justify-content: space-between;
  padding:0rem 0 0.2rem 0;
  @media screen and (max-width: 1200px){
    justify-content: center;
    padding-top: 19rem;
    flex-direction: column-reverse;
  }
  @media screen and (max-width: 959px){
   padding-top: 24.5rem;
  }
  @media screen and (max-width: 468px){
    padding-top: 20.5rem;
  }
  
  .main {
    max-width:556px;
    width:100%;
    @media screen and (max-width: 1200px){
      max-width: 460px;
      margin: 0 auto;
    }
    &-aside {
     max-width:460px;
     width:100%;
     margin: 0 auto;
    }
    &-who {
      margin-top:2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      opacity:0.5;
      grid-gap: 1rem;
    }
    &-img {
      width:100%;
      border-radius:0.5rem;
      max-height:243px;
      overflow: hidden;
      img {
        width:100%;
        border-radius:0.5rem;
        min-height:243px;
        max-height:244px;
        object-fit:cover;
      }
      @media screen and (max-width: 1200px){
        position:absolute;
        top: 4rem;
        display: flex;
        justify-content: center;
        width: fit-content;
        max-width:46rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
       img {
          width: 100%;
          object-fit: fill;
          max-height: 19.1rem;
          min-height: 19rem;
        }
      }
      @media screen and (max-width: 968px){
        top: 10rem;
      }
      @media screen and (max-width: 968px){
        img {
          //object-fit: cover;
         // padding: 0 0.5rem;
          max-height: 19.1rem;
          min-height: 19rem;
        }
      }
      @media screen and (max-width: 468px){
        img {
          padding: 0 0.4rem;
          max-height: 15.1rem;
          min-height: 15rem;
        }
      }
    }
    &-info {
      margin-top:0.2rem;
      padding:3.6rem 1.6rem 2.3rem 1.6rem;
      font-size:1.6rem;
      background: #37474F 0% 0% no-repeat padding-box;
      border-radius: 0.5rem;
      @media screen and (max-width: 468px){
          p {
            font-size: 1.4rem;
          }
        }
      h2 {
        font-size:2.6rem;
        font-weight:${font.weight.bold};
        margin-bottom:2.1rem;
        text-transform:uppercase;
        overflow-wrap: break-word;
        @media screen and (max-width: 468px){
          font-size:1.8rem;
          p {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
`
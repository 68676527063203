import React, { useState, useEffect, useContext, useRef } from 'react';
import { Redirect, useLocation, useParams,useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import BigTableBlog from '../../../components/Blog/BigTableBlog';
import { InnerContainer, InnerContainerLarge } from '../../../styles/globalStyle';
import {BlogWrapper,BlogMain} from './Blog.elements';
import Blogs from '../../../assets/Blog.png';
import BlogAside from '../../../components/Blog/BlogAside';
import moment from 'moment';
import GameTypeHeader from '../../../components/GameTypeHeader/GameTypeHeader';
import BlogSinglePopup from '../../../components/Popup/BlogSinglePopup';
import { ReactComponent as LoadingLogo } from '../../../assets/reducering-logomark.svg'
import Footer from '../../../components/Footer/Footer'
import stryktipsetLogo from '../../../assets/stryktipset-wordmark.svg'
import topptipsetLogo from '../../../assets/topptipset-wordmark.svg'
import stryk1 from '../../../assets/stryk1.png'
import stryk2 from '../../../assets/stryk2.png'
import stryk3 from '../../../assets/stryk3.png'
import power1 from '../../../assets/power1.png'
import power2 from '../../../assets/power2.png'
import power3 from '../../../assets/power3.png'
import topp1 from '../../../assets/topp1.png'
import topp2 from '../../../assets/topp2.png'
import topp3 from '../../../assets/topp3.png'
import euro1 from '../../../assets/euro1.png'
import euro2 from '../../../assets/euro2.png'
import euro3 from '../../../assets/euro3.png'
import { Helmet } from "react-helmet-async"

const gameImage = [
 {src:power1,id:'power-1'},{src:power2,id:'power-2'},{src:power3,id:'power-3'},
 {src:euro1,id:'euro-1'},{src:euro2,id:'euro-2'},{src:euro3,id:'euro-3'},
 {src:topp1,id:'topp-1'},{src:topp2,id:'topp-2'},{src:topp3,id:'topp-3'},
 {src:stryk1,id:'stryk-1'},{src:stryk2,id:'stryk-2'},{src:stryk3,id:'stryk-3'}]


const Blog = () => {
  const params = useParams();
  const history = useHistory()
  //const location = useLocation();
  // State for modal
  const [isModalOpen,setIsModalOpen] = useState(false);
  //State for image
  const [image, setImage] = useState(null)
  // State for disable when gameOver
  const [canCheck, setCanCheck] = useState(true);
  // State for request id
  const [infoAboutBlog,setInfoAboutBlog] = useState(null);
  // function for find img id
  const findImage = (id)=>{
    return  gameImage.find(game => game.id === id)
  }
  // Fetch single blog
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const fetchBlog = async ()=>{
    if(!isLoading){
      let token = isAuthenticated ? await getAccessTokenSilently() : {};
      let headers
      if(isAuthenticated){
        headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        };
      } else{
        headers = {}
      }

      setLoader(true)
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/blog/${infoAboutBlog.id}`,{
        headers: headers
      }).then(res =>{
        setTimeout(()=>{
          setLoader(false)
        },500)
        if(res.ok) {
          return res.json()
        } else {
          if(res.status == 404) {
            history.push('/')
            return
          }
          return {error:res.json()}
        }
        //return res.json()
      }).then(data=>{
        if(data?.error){
          console.log('Problems')
        } else {
          if(data?.result?.length > 0) {setCanCheck(false)}
          setBlog(data)
          let img = findImage(data.imageId)
          setImage(img.src)
          let copyObj = JSON.stringify(data)
          setHistoryBlog(JSON.parse(copyObj))
        }
        //setIsLoaderTeam(false)
        //if(data.teams.length > 0){
          //let teams = data.teams.map(item =>
            //({
             // first:item.title,
             // second:item.email,
             // last: { info:formatDate(item.date) },
             // infoItem:{...item}
            //})
          //)
          //setTeam(teams)
          //let count = data.total
          //let totalPage = count/6
          //console.log(Math.ceil(totalPage))
         // setAllPage(Math.ceil(totalPage))
        //} else{
         // setTeam([])
       // }
      }).catch(err =>{
          debugger
          console.log(err)
        })
    }
  }
  useEffect(()=>{
    if(!infoAboutBlog){
      setInfoAboutBlog(params)
    }
  },[params])
  useEffect(()=>{
    if(infoAboutBlog && !isLoading ){
      fetchBlog()
    }
  },[isLoading,infoAboutBlog,isAuthenticated])
  // State for blog
  const [blog,setBlog] = useState({})
    //State for blog not muted
  const [historyBlog,setHistoryBlog] = useState(null)
    //State for loader
  const [loader,setLoader] = useState(true);
  // function for change pre-admin checked
  const handleChange = (e,eventId,forecastId) =>{
    const getEvent = blog.events;
    const findEvent = getEvent.map((item) =>{
        if(item.id === eventId){
          item.forecast[forecastId] = !item.forecast[forecastId]
          return item
        } else {
          return item
        }
    })
    setBlog(prev => ({
      ...prev,
      events:findEvent
    }))
  }
  //Copy from SelectedCoupon to header
  let extraMoney =
    blog?.draw?.fund &&
    parseFloat(blog?.draw?.fund?.extraMoney) > 0 &&
    blog?.draw?.fund?.extraMoney?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')?.slice(0, -3) + ' kr';

  const [errorPopup, setErrorPopup] = useState(false);
  const [errorPopupText, setErrorPopupText] = useState('');
  const [totalRader,setTotalRader] = useState(0)
  const changeRed = () =>{
    setTotalRader(
      blog.events.reduce((accum, curr) => {
        const total =
          curr.forecast[0] + curr.forecast[1] + curr.forecast[2]
        return accum * total
      }, 1)
    )
  }
  useEffect(()=>{
    if(blog?.draw){
      changeRed()
    }
  },[blog])
  return (<>
    <Helmet>
      <meta property="og:locale" content="en_GB" data-react-helmet="true" />
      <title>Blogs</title>
      <meta name="title" content="Blogs" data-react-helmet="true"/>
      <meta name="description" content="Blogs" data-react-helmet="true"/>
      <meta property="og:type" content="website" data-react-helmet="true"/>
      <meta property="og:url" content="https://metatags.io/" data-react-helmet="true"/>
      <meta property="og:title" content="Blogs" data-react-helmet="true"/>
      <meta property="og:description" content="Blogs" data-react-helmet="true"/>
      <meta property="og:image" content="https://c1.sfdcstatic.com/content/dam/sfdc-docs/www/logos/logo-salesforce.svg" data-react-helmet="true"/>
      <meta property="twitter:card" content="summary_large_image" data-react-helmet="true"/>
      <meta property="twitter:url" content="https://metatags.io/" data-react-helmet="true"/>
      <meta property="twitter:title" content="Blogs" data-react-helmet="true"/>
      <meta property="twitter:description" content="Blogs" data-react-helmet="true"/>
      <meta name="twitter:image" content="https://www.reducering.se/logo512.png" />
    </Helmet>
    <BlogSinglePopup
    setIsModalOpen={setIsModalOpen}
    isModalOpen={isModalOpen}
    />

    <BlogMain>
      {infoAboutBlog?.gameType && <GameTypeHeader
        gameType={infoAboutBlog?.gameType}
        gameTurnover={blog?.draw?.turnover}
        gameCloseTime={blog?.draw?.closeTime}
        jackpot={blog?.jackpotItems?.[0]?.amount}
        extraMoney={extraMoney}
        currentId={null}
        errorPopup={errorPopup}
        setErrorPopup={setErrorPopup}
        errorPopupText={errorPopupText}
        setErrorPopupText={setErrorPopupText}
        drawNumber={blog?.draw?.drawNumber}
        size={true}
      />}
    </BlogMain>
    <InnerContainer>
      {loader ?
        <div className='loader'>
          <div className='loader-animation' />
          <LoadingLogo />

          <p>
            Loading...
          </p>
        </div>
        :<BlogWrapper>
        <div className='main' >
          <div className='main-img'>
            <img src={image} alt='blog-image'/>
          </div>
          <div className='main-info'>
            <h2>{blog?.title}  {/*moment(blog?.date).format('DD/MM/YYYY')*/}</h2>
            <p style={{wordBreak:'break-word'}}>{blog?.comment}
            </p>
            <div className='main-who'>
              <span>Publicerad: {moment(blog?.date).format('DD/MM/YYYY')}</span>
              <span>{blog?.namedAuthor}</span>
            </div>
          </div>
          <BigTableBlog
            blog={blog}
            handleChange={ canCheck ? handleChange : ()=>{}}
            adminPrepeare = {historyBlog}
          />
        </div>
        <div className='main-aside'>
          <BlogAside
            blog={blog}
            canCheck={canCheck}
            handleChange={ canCheck ? handleChange : ()=>{}}
            totalRader={totalRader}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      </BlogWrapper>}

    </InnerContainer>
    { !loader &&
    <InnerContainer>
      <Footer />
    </InnerContainer>
    }
  </>)
}

export default Blog
export function createBombenChart (reduceRow) {
    let part = 0.70;
    let labels = ["1 - 250", "250 - 499", "500 - 999", "1k - 2k", "2k - 5k", "5k - 10k", "10k - 50k", "50k - 100k", "100k+"];
    let limits = [
      [1, 249],
      [250, 499],
      [500, 999],
      [1000, 1999],
      [2000, 4999],
      [5000, 9999],
      [10000, 49999],
      [50000, 99999],
      [100000, Number.MAX_VALUE]
    ];
    let out = Array(9).fill().map((x,i)=> {
      return {
        min: limits[i][0],
        max: limits[i][1],
        count: 0,
        percent: 0,
        label: labels[i]
      }
    })
    reduceRow.forEach(row => {
      let payout = row.reduce((acc, val) => {
        return acc*(val.distribution/100);
      }, 1)
      for (let obj of out) {
        if (obj.min < part/payout && part/payout <= obj.max) {
          obj.count++
        }
      }
    })

    for (let obj of out) {
      obj.percent = (obj.count/reduceRow?.length)*100;
    }
    return out;
}
import React from 'react'
import io from 'socket.io-client'


export const socket = io(`${process.env.REACT_APP_SOCKET_ENDPOINT}`, {
    transports: [ "websocket" ], 
    path: `${process.env.REACT_APP_SOCKET_PATH}`}
)

export const SocketContext = React.createContext()

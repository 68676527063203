import styled from 'styled-components'
import { breakPoints, colors, font, spacers } from '../../../../styles/constants'

export const GameDetailContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    @media screen and (max-width: ${breakPoints.mediumMax}) {
      font-size: 1rem;
    }
  }
`

export const GameInfoContainer = styled.div`
  background-color: #465a65;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  font-weight: ${font.weight.light};
  padding: ${spacers.xsmall} ${spacers.large};
  font-size: ${font.size.xsmall};

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    font-size: ${font.size.small};
  }

  @media screen and (max-width: ${breakPoints.smallMax}) {
    padding: ${spacers.xxsmall} ${spacers.small};
  }

  > * + * {
    @media screen and (max-width: ${breakPoints.smallMax}) {
      margin-left: ${spacers.xsmall};
    }
  }

  .gameInfoEventNumber {
    font-size: ${font.size.small};
    font-weight: ${font.weight.semiBold};
    margin-right: ${spacers.large};

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      font-size: 1rem;
    }

    @media screen and (max-width: ${breakPoints.smallMax}) {
      margin-right: 0;
    }
  }

  .gameInfoDescription {
    color: inherit;
    margin-right: auto;
    font-weight: ${font.weight.normal};
    text-decoration: none;
    color: ${colors.white};

    &:hover {
      text-decoration: underline;
    }

    @media screen and (max-width: ${breakPoints.smallMax}) {
      margin-bottom: 0;
      // font-size: ${font.size.xxsmall};
      font-size: ${font.size.xsmall};
      line-height: 0.9;
      line-height: 1;
      width: 100%;
      // color: ${colors.lightGrey};
      color: ${colors.white};
    }
  }

  .gameInfoStartDate {
    @media screen and (max-width: ${breakPoints.smallMax}) {
      font-size: ${font.size.xxsmall};
      font-weight: ${font.weight.semiBold};
    }
  }
`

export const GameInfoLottad = styled.div`
  padding:0.3rem 1rem;
  background-color: ${colors.svenskaSpelRedActive};
  font-size: 1rem;
`

export const GameOddsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: ${colors.lighterGrey};
  color: #000;
  box-shadow: 0 -0.1rem 0.1rem rgba(0, 0, 0, 0.15);
  font-size: ${font.size.small};
  font-weight: ${font.weight.semiBold};
  height: 100%;
  overflow: hidden;

  .gameOddsItem {
    padding: ${spacers.small} ${spacers.large};
    position: relative;

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      padding: ${spacers.small} ${spacers.large};
    }

    &:nth-child(2) {
      background-color: ${colors.lightGrey};

      .gameBetChoice {
        color: ${colors.lighterGrey};
      }

      .gameBetOdds {
        box-shadow: inset 0 -3px 0 -1px ${colors.lighterGrey};
      }
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;

      @media screen and (max-width: ${breakPoints.smallMax}) and (min-width: 400px) {
        width: 80%;
      }

      @media screen and (max-width: ${breakPoints.mediumMax}) and (min-width: ${breakPoints.small}) {
        width: 60%;
      }
    }

    @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
      padding: ${spacers.xxsmall} ${spacers.large};
      font-size: ${font.size.xsmall};

      > div {
        margin-right: 0;
        width: 6rem;
        justify-content: flex-end;
      }

      .gameBetChoice {
        position: absolute;
        top: 0.1rem;
        left: 25%;
        transform: translateX(-50%);
        font-size: 3rem;
      }

      .gameBetOdds {
        padding-top: 0;
        padding-bottom: 0.25rem;
        margin-bottom: 0.125rem;
        margin-right: 0.75rem;
      }

      .gameBetReduction {
        font-size: inherit;
        margin-left: 0.75rem;

        &:after {
          font-size: 0.6rem;
        }
      }
    }

    @media screen and (max-width: 1130px) and (min-width: ${breakPoints.medium}) {
      .gameBetChoice {
        left: 20%;
      }
    }
  }

  .gameBetChoice {
    font-size: ${font.size.xxlarge};
    font-weight: ${font.weight.semiBold};
    color: ${colors.lightGrey};
    line-height: 1;
  }

  .gameBetOdds {
    padding-top: ${spacers.xsmall};
    margin-bottom: ${spacers.medium};
    box-shadow: inset 0 -3px 0 -1px ${colors.lightGrey};
  }

  .gameBetReduction {
    font-size: calc(${font.size.small} - 0.05rem);
    font-weight: ${font.weight.light};

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      margin-right: -0.3rem;
    }

    // Positive or negative percentage
    //color: ${colors.gamebetGreen};

    &:after {
      position: relative;
      content: '▲';
      font-size: 0.7rem;
      top: -3px;

      @media screen and (max-width: ${breakPoints.mediumMax}) {
        left: 0.2rem;
      }
    }
  }
`

export const TrendText = styled.p`
  font-size: calc(${font.size.small} - 0.05rem);
  font-weight: ${font.weight.light};

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    margin-right: -0.3rem;
  }

  // Positive or negative percentage
  color: ${({ trendColor }) =>
    trendColor ? colors.gamebetGreen : colors.svenskaSpelRed};

  &:after {
    position: relative;
    font-size: 0.7rem;
    top: -3px;
    ${({ trendColor }) => (trendColor ? `content: '▲'` : `content: '▼'`)};

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      left: 0.2rem;
    }
  }
`

import React from 'react'
import HomeHeader from '../../components/Headers/HomeHeader'
import ActiveGamesBoxes from '../../components/ActiveGamesBoxes/ActiveGamesBoxes'
import Footer from '../../components/Footer/Footer'
import HowItWorksPanel from '../../components/HowItWorksPanel/HowItWorksPanel'
import {
  InnerContainer,
  OneHalf,
  PageContent,
  ArticlePanel
} from '../../styles/globalStyle'

export default function RulesConditionsPage() {
  return (
    <>
      <HomeHeader />
      <PageContent>
        <OneHalf>
          <ArticlePanel>
            <h3>Regler & Villkor</h3>

            <p>
              <em>Reducering.se</em> är ett reduceringsverktyg för Svenska Spels större
              poolspelprodukter med ambition om att vara marknadsledande.
            </p>

            <p>
              <em>Reducering.se</em> är gratis att använda och uppfyller de riktlinjer som
              finns för inlämning av externa systemspel i Svenska Spels miljö.{' '}
              <em>Reducering.se</em> ansvarar ej för eventuella felaktigheter av de
              raderna som verktyget genererar.
            </p>

            <p>
              Spelinformationen kring matcherna inhämtas från Svenska Spel och{' '}
              <em>Reducering.se</em> ansvarar ej för eventuella faktafel eller felaktiga
              uppgifter hos Svenska Spel och dess samarbetspartners.
            </p>

            <p>
              Liverättningen som <em>Reducering.se</em> tillhandahåller - också helt
              gratis - bygger på information levererad av Svenska Spel.{' '}
              <em>Reducering.se</em> ansvarar ej för eventuella felaktigheter i
              rättningsfunktionaliteten eller i den målservice-funktion som presenteras.
            </p>

            <p>
              För mer information, vänligen kontakta <em>Reducering.se</em> på{' '}
              <a href='mailto:kundtjanst@reducering.se'>kundtjanst@reducering.se</a>.
            </p>
          </ArticlePanel>
        </OneHalf>

        <OneHalf>
          <HowItWorksPanel />
        </OneHalf>
      </PageContent>

      <InnerContainer>
        <ActiveGamesBoxes />
      </InnerContainer>

      <InnerContainer>
        <Footer />
      </InnerContainer>
    </>
  )
}

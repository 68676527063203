import styled, { css } from 'styled-components'
import { spacers, font, colors, borderRadius } from '../../../../styles/constants'

export const RecentEventsTool = styled.div`
  font-size: 1.4rem;
  font-weight: ${font.weight.semiBold};

  > * {
    display: flex;
    flex-direction: row;
    padding: ${spacers.small} ${spacers.xlarge};

    + * {
      border-top: ${spacers.xxsmall} solid ${colors.darkGrey2};
    }
  }
  ${props => props.area && css`
    grid-area: ${props.area};
  `}
`

export const RecentEventsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.toolGrey};
  border-top-left-radius: ${borderRadius.xsmall};
  border-top-right-radius: ${borderRadius.xsmall};
  overflow: hidden;
  padding: 0;
`

export const RecentEventsHeading = styled.div`
  padding: ${spacers.small} ${spacers.xlarge};
`

export const MuteBtn = styled.button`
  border: none;
  height: inherit;
  color: inherit;
  cursor: pointer;
  color: ${colors.white};
  box-shadow: inset 0 0 0 2px ${colors.vividCyan};

  text-transform: uppercase;
  font-weight: ${font.weight.semiBold};
  background-color: ${colors.grey};
  font-size: ${font.size.xxsmall};
  border-top-right-radius: ${borderRadius.xsmall};
  padding: ${spacers.small};
`

export const ScoreCounter = styled.span`
  color: ${({ scored }) => (scored ? colors.vividCyan : colors.white)};
`

export const RecentEventsItem = styled.div`
  background: ${colors.toolsGradient};
  height: 4.5rem;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;

  @keyframes ex {
    0% {
      opacity: 0;
    }
    25% {
      background: ${colors.svenskaSpelRed};
    }
    75% {
      opacity: 1;
    }
    100% {
      background: ${colors.toolsGradient};
    }
  }
  animation: ${({ isNewEvent }) => (isNewEvent ? 'ex 3s;' : null)};

  transition: ${({ isNewEvent }) =>
    isNewEvent
      ? 'box-shadow 1500ms cubic-bezier(.02,1.02,.81,1)'
      : 'background 150ms ease-in-out'};

  box-shadow: ${({ isNewEvent }) =>
    isNewEvent ? '0 0 0 2rem rgba(255, 0, 0, 0)' : '0 0 0  rgba(255, 0, 0, 0.8)'};

  &:last-child {
    border-bottom-left-radius: ${borderRadius.xsmall};
    border-bottom-right-radius: ${borderRadius.xsmall};
    overflow: hidden;
  }

  .goalTime,
  .score {
    width: 5rem;
    text-align: center;
    font-size: 1.4rem;
  }

  .goalTime {
    width: 5rem;
  }

  .score {
    width: 11rem;
    // color: ${({ inTheLead }) => (inTheLead ? colors.vividCyan : colors.white)};

    span {
      // color: ${({ inTheLead }) => (inTheLead ? colors.white : colors.vividCyan)};
    }
  }

  .goalTime {
    text-indent: -${spacers.xlarge};
    color: ${colors.lightGrey};
  }

  .teamAway {
    text-align: right;
  }

  .teamHome,
  .teamAway {
    font-size: 1.1rem;
    hyphens: auto;
  }

  .score,
  .teamHome,
  .teamAway {
    width: calc(100% * 1 / 3 - 2rem);
    flex: 0 0 auto;
  }
`

import React from 'react';
import {LiveDate,ProggresBar} from './CouponLiveInfo.elements';

const CouponLiveInfo = ({date,open}) => {
  return (
    <LiveDate open>
      {date?.length > 0 ? date?.map(item =>{
        return(
          <div key={item.type}>
             <div className='title'>
              <span>{item?.home}</span>
              <span>{item?.translate}</span>
              <span>{item?.away}</span>
            </div>
            <div className='progress'>
              <ProggresBar colors='#40C7A8'>
                <progress className='first'  value={parseFloat(item?.home)} max={parseFloat(item?.away) + parseFloat(item?.home)} />
              </ProggresBar>
              <ProggresBar colors='#CFD8DC'>
                <progress value={parseFloat(item?.away)} max={parseFloat(item?.away) + parseFloat(item?.home)}  />
              </ProggresBar>
            </div>
          </div>
        )
      }) :null}

    </LiveDate>
  )
}

export default CouponLiveInfo
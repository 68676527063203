import { ReductionPopup } from './Popup.elements'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'

export default function ErrorPopupWithOptions({open, setErrorPopup, errorPopupText, OK, cancel}) {
  return (
    <ReductionPopup
      open={open}
      onClose={
          () => setErrorPopup(false)
        }
      errorPopupText
    >
      <button className='modalClose' onClick={() => setErrorPopup(false)}>
        <CloseIcon alt='close' />
      </button>

      <h3>
        {errorPopupText}
      </h3>

      <button onClick={() =>  {
        OK()
        setErrorPopup(false)
      }}>
        OK
      </button>
      <button onClick={() => {
          if (cancel) {cancel()}
          setErrorPopup(false)
      }}>
        Avbryt
      </button>

    </ReductionPopup>
  )
}


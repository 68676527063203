import { ReductionPopup } from './Popup.elements'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'

import React, { useEffect, useState, useContext } from 'react'

export default function ResetPopup({open, setOpen, text, reset, cancel }) {


  const [shouldResetFrame, setShouldResetFrame] = useState(false)

  const handleChange = (e) => {
    setShouldResetFrame(!shouldResetFrame);
  }

  return (
    <ReductionPopup
      open={open}
      onClose={
          () => setOpen(false)
        }
        text={text}
    >
      <button className='modalClose' onClick={() => setOpen(false)}>
        <CloseIcon alt='close' />
      </button>

      <h3>
        {text}
      </h3>

      <form>
        <div className='input-group'>
          <label>
            Ta även bort valda tecken
            <input
              type="checkbox"
              defaultChecked={shouldResetFrame}
              onChange={handleChange} />
            <span className="checkmark"></span>
          </label>
        </div>
      </form>

      <button onClick={() => {
          reset(shouldResetFrame)
          setOpen(false)
      }}>
        OK
      </button>


      <button onClick={() => {
          if (cancel) {cancel()}
          setOpen(false)
      }}>
        Avbryt
      </button>




    </ReductionPopup>
  )
}


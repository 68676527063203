import React, { useContext } from 'react';
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions';
import {
  SliderContainer,
  SignBG,
  SliderControls,
} from './DividendLastGame/DividendLastGame.elements';
import { ToolsContainer, SignSummaryContainer } from './ResultWidgets.elements';
import { ToolsPanel } from '../../../styles/globalStyle';

import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperBox from './SwiperCast/SwiperBox';

// Import Swiper styles
import 'swiper/swiper-bundle.css';

import DividendForecast from './DividendForecastSystem/DividendForecast';
import ResultSummary from './ResultSummary/ResultSummary';
import WholeHalfGuard from './WholeHalfGuard/WholeHalfGuard';
import PointReduction from './PointReduction/PointReduction';
import ColorReduction from './ColorReduction/ColorReduction';
import SignReduction from './SignReduction/SignReduction';
import LoadCoupon from './LoadCoupon/LoadCoupon';
import Dividend from './Dividend/Dividend';
import RecentEvents from './RecentEvents/RecentEvents';
import DividendLastGame from './DividendLastGame/DividendLastGame';

import SignSummary from '../../Game/CouponSettings/SignSummary/SignSummary';

// install Swiper modules
SwiperCore.use([Navigation]);

export default function ResultWidgets({
  eventData: { forecast, events, latestFive },
  loadedCouponData,
  loadedId,
  dividendForecast,
  userRows,
  betRowAmount,
}) {
  const { winWidth } = useWindowDimensions();
  //console.log('winWidth', winWidth)

  let isOneLeft = forecast?.events.filter((el) => {
    return !el.isFinished;
  });

  let theLastGame = null;
  if (isOneLeft?.length === 1) {
    theLastGame = isOneLeft[0];

    // NOTE THAT PARTICIPANTS GETS GLUED ON HERE
    theLastGame.participants = events[theLastGame.eventNumber - 1].participants;
  }

  return (
    <ToolsContainer className="ReductionTools">
      <LoadCoupon currentId={loadedId} />

      {winWidth <= 1365 && (
        <ToolsPanel>
          <SliderContainer>
            <SwiperBox
              // isModalOpen={isModalOpen}
              userRows={userRows}
              forecast={forecast}
              dividendForecast={dividendForecast}
              theLastGame={theLastGame}
              betRowAmount={betRowAmount}
            />
          </SliderContainer>

          {/*<SliderContainer>
             <SliderControls className='swiper-prev' />
            <SliderControls className='swiper-next' />

            <Swiper
              slidesPerView={1}
              loop={true}
              speed={275}
              spaceBetween={30}
              simulateTouch={true}
              navigation={{
                prevEl: '.swiper-prev',
                nextEl: '.swiper-next'
              }}
            >
              {isOneLeft ? (
                <>
                  <SwiperSlide>
                    <DividendLastGame
                      userRows={userRows}
                      forecast={forecast}
                      sign={'1'}
                    />
                    <SignBG>1</SignBG>
                  </SwiperSlide>

                  <SwiperSlide>
                    <DividendLastGame
                      userRows={userRows}
                      forecast={forecast}
                      sign={'X'}
                    />
                    <SignBG>X</SignBG>
                  </SwiperSlide>

                  <SwiperSlide>
                    <DividendLastGame
                      userRows={userRows}
                      forecast={forecast}
                      sign={'2'}
                    />
                    <SignBG>2</SignBG>
                  </SwiperSlide>
                </>
              ) : (
                <SwiperSlide>
                  <DividendForecast dividendForecast={dividendForecast} />
                </SwiperSlide>
              )}

              <SwiperSlide>
                <Dividend distribution={forecast?.distribution} />
              </SwiperSlide>
            </Swiper>
          </SliderContainer> */}
        </ToolsPanel>
      )}
      {/* <WholeHalfGuard /> */}
      <RecentEvents
        forecast={forecast}
        events={events}
        latestFive={latestFive}
      />
      <ColorReduction colorState={loadedCouponData?.colorReduction} />
      <SignReduction signState={loadedCouponData?.signReduction} />
      <PointReduction pointState={loadedCouponData?.pointReduction} />
      {/* <SignSummaryContainer>
        <ToolsHeading>Teckensammanfattning</ToolsHeading>
        <SignSummary
          forecast={forecast}
          events={events}
          viewState={loadedCouponData?.viewState}
          isReduced={true}
        />
      </SignSummaryContainer> */}
    </ToolsContainer>
  );
}

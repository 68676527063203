import React, { useState } from 'react'
import icon from '../../../../assets/clone-coupon.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg'

import ErrorPopupWithOptions from '../../../Popup/ErrorPopupWithOptions'

export default function CloneCouponBtn({cloneCount, setCloneCount}) {

  const [clonePopup, setClonePopup] = useState(false)


  const errorText = "Din kupong kommer klonas och generera fler kuponger att lämna in."


  const resetCloneCount = {
    position: 'absolute',
    background: 'transparent',
    zIndex: 1,
    top: 0,
    right: 0,
    textTransform: 'uppercase',
    width: 'initial'
  };

  const resetCloneCountSvg = {
    fill: '#cfd8dc',
    width: '2rem',
    height: '2rem',
    marginTop: '-0.5rem',
    marginRight: '-0.5rem'
  };

  return (
    <>
    <ErrorPopupWithOptions errorPopupText={errorText} open={clonePopup} setErrorPopup={setClonePopup} OK={() =>{setCloneCount(cloneCount + 1)}}/>
    <button onClick={() => {
      setClonePopup(true);
    }
    }>
      <img src={icon} />
      {cloneCount ? `Klonad ${cloneCount}` : "Klona kupong"}
    </button>

    { cloneCount > 0 ?
      <button style={resetCloneCount} onClick={() => {
        setCloneCount(0)
      } }>
        <CloseIcon  style={resetCloneCountSvg} alt='Reset' />
      </button>
    : null }
    </>
  )
}

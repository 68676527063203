import styled, { css } from 'styled-components'
import { Link } from "react-router-dom";
import { breakPoints, spacers, colors, font, headerHeight, borderRadius } from '../../styles/constants'

export const UserButton = styled(Link)`
  font-size: ${font.size.xsmall};
  font-weight: ${font.weight.semiBold};
  color: ${colors.lightGrey};
  padding: ${spacers.medium} ${spacers.large};
  border: none;
  background-color: ${colors.toolGrey};
  cursor: pointer;
  box-shadow: ${colors.black} 0px 3px 13px -2px;
  transition: all 0.2s ease-out;
  align-self: center;
  border-radius: 0.25rem;
  min-width: 7rem;
  white-space: nowrap;
  text-transform: uppercase;

  &:hover {
    color: ${colors.white};
    background-color: ${colors.toolGreyActive};
  }

  @media screen and (max-width: ${breakPoints.smallMax}) {
    min-width: 6.5rem;
  }
`;

export const UserLogButton = styled(UserButton).attrs({ as: 'button' })`
  font-size: 1.8rem;
  background-color: ${props => props.color ? ' #37474F;' : colors.anchor};
  color: ${colors.white};
  padding: 1rem 4rem;
  max-width: 16rem;
  @media screen and (max-width: 767px){
    min-width: 15rem;
  }
  @media screen and (max-width: 468px){
    min-width: 8rem;
  }
 ${props => props.color && css`
  text-transform: initial;
`}
  @media screen and (max-width:707px){
    font-size: 1.2rem;
    padding: 0.5rem;
    max-width: 9rem;
  }
  @media screen and (max-width:450px){
    font-size: 1rem;
    padding:${props=> props.width ? '0.9rem 0 ' : "0.4rem 0"} ;
    max-width:${props=> props.width ? '9rem' : '7rem'};
    width: 100%;
  }
  
  ${props=>props?.dis && css`
    display: flex;
    justify-content: center;
  `}
`
export const UserLoggButton = styled(UserLogButton)`
  background-color: ${colors.panelDarkGrey};
  max-width: 19rem;
  @media screen and (max-width:450px){
    display: none;
    
  }
`
export const UserLogButtonPopup = styled(UserLogButton)`
  text-transform: uppercase;
  font-size: 1.8rem!important;
  background-color: ${colors.anchor}!important;
  padding: 2rem 4rem!important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 468px){
    font-size: 1.2rem!important;
  }
  ${props => props.black && css`
  background-color: #37474F!important;
`}
  ${props => props.width && css`
  @media screen and (max-width: 707px){
    max-width: 14rem;
  }
`}
`


export const Header = styled.div`
  background: #273238;
  border-bottom: ${spacers.xxsmall} solid ${colors.darkGrey2};
  box-sizing: content-box;
  position: sticky;
  z-index: 10;
  top: 0;
  box-shadow: 0px 3px 15px #0000000A;
  display: grid;
  width: 100%;
  grid-template-columns:
    minmax(250px, 1fr)
    minmax(auto, calc(${breakPoints.large} - ${spacers.xsmall} * 2) )
    minmax(130px, 1fr);
  gap: ${spacers.small} ${spacers.small};

  @media screen and (min-width: ${breakPoints.xlarge}) {
    height: ${headerHeight.large};
  }

  @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
    height: ${headerHeight.large};
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    gap: initial;
  }

  .headerItem {
    &:nth-child(1) {
      grid-column: 1 / 1;
      display: flex;
    }

    &:nth-child(3) {
      display: flex;
    }
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    grid-template-columns: auto min-content;

    .headerItem:nth-child(1) {
      grid-column: initial;
      flex-direction: row;
      display: flex;
      align-items: center;
      height: ${headerHeight.small};

      p {
        text-align: center;
        margin-left: ${spacers.medium};
        margin-right: auto;
        padding-left: 2.5rem;
        font-size: ${font.size.large}
      }
    }

    .headerItem:nth-child(3) {
      grid-row-start: 1;
      grid-column-start: 2;
    }

    .headerItem:nth-child(1),
    .headerItem:nth-child(3) {
      padding: 1rem;
      height: 6rem;
      width: auto;
    }
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    // Logo
    > *:nth-child(1) {
      height: ${headerHeight.small};
      background-color: ${props => props.typeColor};
      grid-column: 1 / 3 span;
      grid-row-start: 1;
    }
  }

  @media screen and (max-width: calc(${breakPoints.xlarge} + 250px) ) and (min-width: ${breakPoints.medium}) {
    .navbar, .menu-bars {
      width: 6.75rem;
    }

    .navbar {
      margin-left: 0;
      margin-right: auto;
    }
  }
`

export const LogoContainer = styled.div`
  .reducering-logo {
    align-items: center;
    align-self: center;
    display: flex;
    height: ${headerHeight.medium};

    @media screen and (min-width: ${breakPoints.medium}) {
      height: inherit;
      width: 100%;
    }

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      height: 100%;
    }

    > a {
      height: inherit;

      .reducering-logomark {
        height: inherit;
        margin-right: 1rem;
        @media screen and (max-width: ${breakPoints.mediumMax}) {
          padding: 0;
          margin: 0 ${spacers.xsmall} 0 0;
          height: 100%;
          max-width: 100%;
          max-height: 100%;
        }

        @media screen and (min-width: ${breakPoints.xlarge}) {
          height: ${headerHeight.medium}
        }

        @media screen and (min-width: ${breakPoints.large}) {
          padding: ${spacers.small};
          padding-left: ${spacers.large};
        }

        @media screen and (max-width: ${breakPoints.largeMax}) and (min-width: ${breakPoints.medium}) {
          padding: ${spacers.large};
        }
      }
    }

    .reducering-wordmark {
      margin-bottom: -${spacers.xsmall};

      @media screen and (max-width: ${breakPoints.mediumMax}) {
        width: 120px;
        height: 18px;
        margin-bottom: -${spacers.small};
      }
    }

    @media screen and (min-width: calc(${breakPoints.xlarge} + ${headerHeight.large}) ) {
      margin-left: ${spacers.small};
    }

    @media screen and (min-width: calc(${breakPoints.xxlarge} - ${headerHeight.large})) {
      margin-left: ${spacers.xlarge};
    }

    @media screen and (max-width: calc(${breakPoints.xlargeMax} + 250px) ) and (min-width: ${breakPoints.medium}) {
     // justify-content: flex-end;

      > a {
        width: 100%;
        max-width: ${headerHeight.large};
        height: 100%;

        .reducering-logomark {
          width: inherit;
          max-width: inherit;
          height: inherit;
        }
      }

      .reducering-logomark {
        display: flex;
        align-self: center;
      }

      .reducering-wordmark {
        display: none;
      }
    }
  }
`

export const GameTypeJackpot = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: ${breakPoints.xlargeMax}) {
    justify-content: center;
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    flex: auto;
    padding: ${spacers.large};
    padding-left: 0;
    justify-content: flex-end;
  }

  p {
    align-self: center;
    text-indent: -1.2rem;
    margin-top: 1.5rem;
    font-size: ${font.size.medium};

    @media screen and (max-width: ${breakPoints.largeMax}) {
      margin-top: 0;
      align-self: flex-end;
    }

    @media screen and (max-width: ${breakPoints.xlargeMax}) {
      align-self: flex-end;
      text-indent: -0.9rem;
      margin-top: 1.3rem;
    }
  }

  & > img {
    @media screen and (max-width: ${breakPoints.xlargeMax}) {
      align-self: center;
      width: 85px;
      height: 26px;
    }
  }

  &.extra-pengar {
    p {
      margin-top: 0;
      margin-left: 0;
      text-indent: 0;
      align-self: flex-start;
    }

    > img {
      @media screen and (max-width: ${breakPoints.xlargeMax}) {
        width: 73px;
        height: 35px;
      }

      @media screen and (max-width: ${breakPoints.smallMax}) {
        margin-top: -1px;
        width: 65px;
        height: 27px;
      }
    }
  }
`

export const HeaderStart = styled(Header)`
  //overflow: hidden;
  //position: fixed;
  grid-template-columns:minmax(275px,1fr) minmax(auto,calc(1024px - 0.3rem * 2) ) minmax(130px,1fr);
  max-width: 1280px;
  margin: 0 auto;
  @media screen and (max-width: ${breakPoints.mediumMax}) {
    grid-template-columns: 3fr 1.5fr  0.5fr;
    grid-gap: 0.5rem;
  }
  .headerItem.navbar:before{
  display: none;
  }
  .headerItem.navbar:after{
  display: none;
  }
  @media screen and (max-width: 460px) {
    grid-template-columns: 2.5fr 1.5fr 0.7fr;
  }
  .reducering-logo{
    a {
      display: flex;
      align-items: center;
    }
    img {
      display: block!important;
    }
  }
  .headerItem {
    justify-content: flex-end;
    grid-gap: 1.5rem;
    &:nth-child(1) {
      align-self: center;
      
      @media screen and (min-width: calc(${breakPoints.xlarge} + 249px)) {
        padding-top: 0.3rem;
      }

      @media screen and (max-width: ${breakPoints.mediumMax}) and (min-width: ${breakPoints.medium}) {
        padding-bottom: 0;
      }
    }

    &:nth-child(2) {
      /*background: linear-gradient(
        -45deg,
        ${colors.darkGrey2} 0%,
        ${colors.darkGrey2} 15%,
        ${colors.headerGrey} 65%
      );*/

      @media screen and (max-width: ${breakPoints.mediumMax}) {
        display: none;
      }
    }
    &:nth-child(3){
      justify-content: flex-end;
      width: 100%;
      &:before {
        width: auto!important;
      }
      &:after {
        width: auto!important;
      }
    }

    &.navbar {
      @media screen and (max-width: ${breakPoints.mediumMax}) {
        display: flex;
        justify-content: flex-end;
      }

      &:before, &:after {
        position: absolute;
        content: '';
        z-index: -1;
        height: 100%;
        width: 100%;
        top: 0;
      }
    }
  }
`

export const HeaderStartContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    height: 6rem;

    grid-column: 1 / span 2;
    grid-row-start: 2;
    padding-left: ${spacers.xlarge};
    padding-right: ${spacers.xlarge};
  }

  .homeHeaderContent {
    width: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;

    > div {
      flex-wrap: nowrap;
      justify-content: flex-end;
      height: 4.5rem;
      padding: 0 1.5rem 0 0;
      background: none;
      max-width: 58rem;

      @media screen and (min-width: ${breakPoints.medium}) {
        padding: 0 2rem;
      }

      @media screen and (max-width: ${breakPoints.mediumMax}) {
        padding: 0 1rem 0 0;
      }

      > * {
        margin-top: initial;
        margin-right: ${spacers.xlarge}
      }

      p {
        margin-bottom: 0;
      }

      input {
        flex: 1;
        padding: 0.5rem 0.7rem;
      }

      button {
        margin-right: 0;
      }

      @media screen and (max-width: ${breakPoints.mediumMax}) {
        p {
          margin-right: auto;
        }

        input {
          flex: 1 1 auto;
          margin-left: ${spacers.xlarge};
          margin-right: 0;
        }
      }

      @media screen and (max-width: ${breakPoints.smallMax}) {
        input {
          margin-left: ${spacers.large}
        }
      }
    }
  }
  
`

export const Profile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    position: relative;
    @media screen and (max-width: 968px){
      justify-content: flex-end;
    }
    .author-nav {
      position: absolute;
      width: 100%;
      z-index: 5;
      right: 0;
      bottom: -21rem;
      user-select: none;
      background: transparent linear-gradient(120deg, #5A6E79 0%, #37474F 100%);
      border-radius: 0.5rem;
      width: 150px;
      box-shadow: 0px 10px 15px #090808e3;
      border: 1px solid${colors.anchor};
      padding: 1rem;
      background-color: #263238;
      @media screen and (max-width: 468px){
        bottom: -20.5rem;
      }
      li {
        padding: 1.5rem 0.5rem;
        border-bottom: 1px solid ${colors.grey};
        color: black;
        font-size: 1.2rem;
        word-break: break-all;
        cursor: pointer;
        color: ${colors.white};
        font-weight: ${font.weight.semiBold};
        &:hover {
          background: #4b5e6a;
          &:first-child{
          background: transparent;
          cursor: initial;
          }
        }
        &:last-child {
        border-bottom: none;
        }
      }
  }
  .author-name {
    font-weight: ${font.weight.semiBold};
    overflow: hidden;
    width: auto;
    word-break: break-word;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 1.3rem;
  }
  .author-img {
    width: 4rem;
    cursor: pointer;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #3A4B53;
    img{
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    @media screen and (max-width: 468px){
      width: 4rem;
      height: 4rem;
    }
    
  }


`
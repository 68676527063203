import React,{useState,useEffect} from 'react'

import ResultDetail from '../ResultDetail/ResultDetail'
import SelectBet from '../SelectBet/SelectBet'
import { SelectableResultItems } from './ResultItem.elements'
import CouponLiveInfo from '../../../CouponLiveInfo/CouponLiveInfo'
import _ from 'lodash'

export default function ResultItem({ event, fcEvent, loadedCouponData,infoLive }) {
  const { sportEventId } = fcEvent
  const [open,setOpen] = useState(false)
  const [infoForLive, setInfoForLive] = useState(null)
  useEffect(()=>{
    if(infoLive?.statistic  || infoLive?.length){
      let arr =[]
      if(infoLive.statistic){
        let searchLabel = infoLive?.statistic?.map(live =>{
          if(live?.type === 'Ball Possession'){
            return arr.push({...live,sort:0,translate:'Bollinnehav'})
          }else if(live?.type === 'Shots Total'){
            return arr.push({...live,sort:1,translate:'Totala skott'})
          }else if(live?.type ==='On Target'){
            return arr.push({...live,sort:2,translate:'Skott på mål '})
          } else if(live?.type === 'Corners'){
            return arr.push({...live,sort:3,translate:'Hörnor'})
          } else {
            return
          }
         /* if(live?.type === 'Ball Possession' || live?.type === 'Shots Total'
            || live?.type ==='On Target' || live?.type === 'Corners') {
            return  arr.push(live)
          } else {
            return
          }*/
        })
      } else{
        let searchLabel= infoLive?.map(live =>{
          if(live?.type === 'Ball Possession'){
            return arr.push({...live,sort:0,translate:'Bollinnehav'})
          }else if(live?.type === 'Shots Total'){
            return arr.push({...live,sort:1,translate:'Totala skott'})
          }else if(live?.type ==='On Target'){
            return arr.push({...live,sort:2,translate:'Skott på mål '})
          } else if(live?.type === 'Corners'){
            return arr.push({...live,sort:3,translate:'Hörnor'})
          } else {
            return
          }
        })
      }

      if(arr.length >0){
        let getUniq = _.uniqBy(arr,'translate')
        let sortArr = getUniq.sort((one,two)=> one.sort - two.sort)
        setInfoForLive(sortArr)
      }
    }

  },[infoLive])
  return (
    <>
      <SelectableResultItems>
      <ResultDetail key={`detailID-${sportEventId}`}
        matchTime={infoLive?.status}
        fcEvent={fcEvent}
        infoForLive={infoForLive}
        event={event}
        setOpen={setOpen}
        open={open} />

      <SelectBet
        key={`${sportEventId}-${0}`}
        loadedCouponData={loadedCouponData?.home}
        fcEvent={fcEvent}
        odds={event?.odds?.home}
        distribution={event?.distribution?.home}
        event={event}
        sign={'1'}
      />

      <SelectBet
        key={`${sportEventId}-${1}`}
        loadedCouponData={loadedCouponData?.draw}
        fcEvent={fcEvent}
        odds={event?.odds?.draw}
        distribution={event?.distribution?.draw}
        event={event}
        sign={'X'}
      />

      <SelectBet
        key={`${sportEventId}-${2}`}
        loadedCouponData={loadedCouponData?.away}
        fcEvent={fcEvent}
        odds={event?.odds?.away}
        distribution={event?.distribution?.away}
        event={event}
        sign={'2'}
      />
    </SelectableResultItems>
      {(open && infoForLive) && <CouponLiveInfo date={infoForLive} open={open} />}
    </>
  )
}

import React, { useState, useContext } from 'react'
import { ActiveGamesContext } from '../../context/activeGamesContext'
import { Link } from 'react-router-dom'
import { FooterContent } from './Footer.elements'
import Logo from './Logo'
import { ReactComponent as TwitterLogo } from '../../assets/icon-twitter.svg'
import { ReactComponent as FacebookLogo } from '../../assets/icon-facebook.svg'
import { ReactComponent as SvenskaSpelLogo } from '../../assets/svenskaspel-logomark-hat.svg'
import ActiveGamesPopup from '../../components/Popup/ActiveGamesPopup'
import { colors } from '../../styles/constants'
import svsPOwerplay from '../../assets/svs_product-powerplay.svg';
import svsEuro from '../../assets/svs_product_europatipset.svg';
import svsStryk from '../../assets/svs_product_stryktipset.svg';
import svsTop from '../../assets/svs_product_topptipset.svg';
import svsBomben from '../../assets/Bomben-icon.svg';
import mail from '../../assets/mail.png'

import { ActiveGamesRouter, handlePopup } from '../../utils/helperFunctions'

export default function Footer({marginBottom}) {
  const activeGames = useContext(ActiveGamesContext)
  const { stryktipset, europatipset, topptipset, powerplay, bomben } = activeGames
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})

  const footerGamesNav = [
      {
        title: 'Stryktipset',
        gameType: 'stryktipset',
        pathname: !stryktipset?.draws.length
          ? '/stryktipset'
          : `/reducera/stryktipset/${stryktipset.draws[0]?.drawNumber}`,
        textColor: colors.vividCyan,
        image:svsStryk
      },
    {
      title: 'Europatipset',
      gameType: 'europatipset',
      pathname: !europatipset?.draws.length
        ? '/europatipset'
        : `/reducera/europatipset/${europatipset.draws[0]?.drawNumber}`,
      textColor: colors.vividCyan,
      image:svsEuro
    },
    {
      title: 'Bomben',
      gameType: 'bomben',
      pathname: !bomben?.draws.length
        ? '/bomben'
        : `/reducera/bomben/${stryktipset.draws[0]?.drawNumber}`,
      textColor: colors.bomben,
      image:svsBomben
    },
    {
      title: 'Topptipset',
      gameType: 'topptipset',
      pathname: !topptipset?.draws.length
        ? '/topptipset'
        : `/reducera/topptipset/${topptipset.draws[0]?.drawNumber}`,
      textColor: colors.vividCyan,
      image:svsTop
    },
    {
      title: 'Powerplay',
      gameType: 'powerplay',
      pathname: !powerplay?.draws.length
        ? '/powerplay'
        : `/reducera/powerplay/${powerplay.draws[0]?.drawNumber}`,
      textColor: colors.vividCyan,
      image:svsPOwerplay
    },
  ]

  const footerAboutGamesNav = [
    {
      title: 'Hur funkar det?',
      pathname: '/hur-funkar-det',
      textColor: colors.white
    },
    {
      title: 'Om Stryktipset',
      pathname: '/stryktipset',
      state: {
        gameName: 'stryktipset',
        drawNumber: stryktipset?.draws[0]
      },
      textColor: colors.white
    },
    {
      title: 'Om Europatipset',
      pathname: '/europatipset',
      state: {
        gameName: 'europatipset',
        drawNumber: europatipset?.draws[0]
      },
      textColor: colors.white
    },
    {
      title: 'Om Topptipset',
      pathname: '/topptipset',
      state: {
        gameName: 'topptipset',
        drawNumber: topptipset?.draws[0]
      },
      textColor: colors.white
    },
    {
      title: 'Om Powerplay',
      pathname: '/powerplay',
      state: {
        gameName: 'powerplay',
        drawNumber: powerplay?.draws[0]
      },
      textColor: colors.white
    }
  ]

  const footerPagesNav = [
    {
      title: 'Om Reducering.se',
      pathname: '/om-oss',
      textColor: colors.white
    },
    {
      title: 'Regler & Villkor',
      pathname: '/regler-villkor',
      textColor: colors.white
    }
  ]

  return (
    <>
      <ActiveGamesPopup
        gameTypeData={modalData}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <FooterContent marginBottom={marginBottom}>
        <div className='footer-content'>
          <div className='footer-icon'>
            <Logo />
            <ul className='footer-nav-games'>
              {footerGamesNav.map(({ gameType, pathname, title,image }, idx) => (
                <li
                  key={`footerGamesNav-${idx}`}
                  // className={gameType === 'bomben' ? 'disabled':''}
                  onClick={
                    activeGames[`${gameType}`]?.draws.length > 1
                      ? () =>
                        handlePopup(
                          gameType,
                          activeGames[`${gameType}`],
                          setModalData,
                          setIsModalOpen,
                          isModalOpen
                        )
                      : null
                  }
                >
                  <ActiveGamesRouter
                    condition={activeGames[`${gameType}`]?.draws.length > 1}
                    wrapper={children => <Link className='gameLink' to={pathname}>{children}</Link>}
                  >
                    <img src={image} style={{height:32,width:32}}/>
                  </ActiveGamesRouter>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <p>
              Reducering.se är det givna verktyget för dig som vill spela reducerat och
              smartare på Svenska Spel Sport & Casinos poolspelsprodukter.
              Bli en bättre spelare - redan idag!
            </p>
            <p>
              Copyright Reducering.se genom OBG Media AB. Spel från Svenska Spel Sport & Casino.
            </p>
            <p className='mail'>
              <a href='mailto:support@reducering.se'>
                <img src={mail} alt='email'/>
              support@reducering.se</a>
            </p>
          </div>
        </div>

        <div className='footer-links'>
          <div className='footer-nav'>
            <ul>
              {footerAboutGamesNav.map(({ pathname, state, title }, idx) => (
                <li key={`footerAboutGamesNav-${idx}`}>
                  <Link to={{ pathname, state }}>{title}</Link>
                </li>
              ))}
            </ul>

            <ul>

              <li>
                <Link to={'/tavling'}>Tävling</Link>
              </li>

              {footerPagesNav.map((el, idx) => (
                <li key={`footerPagesNav-${idx}`}>
                  <Link to={el.pathname}>{el.title}</Link>
                </li>
              ))}

              <li>
                <Link to={'/gdpr'}>GDPR</Link>
              </li>

              <li>
                <a
                  href='https://www.stodlinjen.se'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Till Stödlinjen
                </a>
              </li>
            </ul>
          </div>

          <div className='social'>
            <div className='some'>
              <a
                href='https://twitter.com/reducering'
                className='twitter'
                target='_blank'
                rel='noopener noreferrer'
              >
                <TwitterLogo />
              </a>

              <a
                href='https://www.facebook.com/Reduceringse-108188301488811/'
                className='facebook'
                target='_blank'
                rel='noopener noreferrer'
              >
                <FacebookLogo />
              </a>
            </div>

            <div className='svenskaSpel'>
              <a
                href='https://www.svenskaspel.se/'
                target='_blank'
                rel='noopener noreferrer'
                alt='Svenska Spel'
              >
                <SvenskaSpelLogo />
              </a>
            </div>
          </div>
        </div>
      </FooterContent>
    </>
  )
}

import React, { useContext } from 'react'

import {
  SliderContainer,
  SignBG,
  SliderControls
} from '../LiveResult/ResultWidgets/DividendLastGame/DividendLastGame.elements'

import { ToolsPanel } from '../../styles/globalStyle'

import SwiperCore, { Navigation } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/swiper-bundle.css'

import 'reactjs-popup/dist/index.css'
import { UserRowsPopup } from './Popup.elements'

import DividendLastGame from '../LiveResult/ResultWidgets/DividendLastGame/DividendLastGame'

import UserRows from '../LiveResult/UserRows/UserRows'
import Dividend from '../LiveResult/ResultWidgets/Dividend/Dividend'
import DividendForecast from '../LiveResult/ResultWidgets/DividendForecastSystem/DividendForecast'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'

import { GameContext } from '../../context/gameContext'

// install Swiper modules
SwiperCore.use([Navigation])

export default function ShowRowsPopup({
  isModalOpen,
  setIsModalOpen,
  eventData,
  dividendForecast,
  userRows,
  updateForecast,
  toggleUnplayed,
  setToggleUnplayed,
  betRowAmount
}) {
  let isOneLeft =
    eventData.forecast?.events.reduce((acc, el) => {
      return acc + !el.isFinished
    }, 0) === 1

  const { gameType } = useContext(GameContext)

  // isOneLeft = 1

  return (
    <UserRowsPopup
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false)
      }}
    >
      <>
        <button className='modalClose' onClick={() => setIsModalOpen(false)}>
          <CloseIcon alt='close' />
        </button>
        <UserRows
          key={`UserRows-Popup`}
          eventData={eventData}
          userRows={userRows}
          dividendForecast={dividendForecast}
          updateForecast={updateForecast}
          toggleUnplayed={toggleUnplayed}
          setToggleUnplayed={setToggleUnplayed}
          isModalOpen={isModalOpen}
          betRowAmount={betRowAmount}
        />
        {/* <ToolsPanel style={{ padding: '2rem 3rem 2.5rem' }}>
          <SliderContainer isModalOpen={isModalOpen}>
            <SliderControls className='swiper-prev' />
            <SliderControls className='swiper-next' />

            <Swiper
              slidesPerView={1}
              loop={true}
              speed={275}
              spaceBetween={30}
              simulateTouch={true}
              navigation={{
                prevEl: '.swiper-prev',
                nextEl: '.swiper-next'
              }}
            >
              {isOneLeft ? (
                <>
                  <SwiperSlide>
                    <DividendLastGame
                      userRows={userRows}
                      forecast={eventData.forecast}
                      sign={'1'}
                    />
                    <SignBG>1</SignBG>
                  </SwiperSlide>

                  <SwiperSlide>
                    <DividendLastGame
                      userRows={userRows}
                      forecast={eventData.forecast}
                      sign={'X'}
                    />
                    <SignBG>X</SignBG>
                  </SwiperSlide>

                  <SwiperSlide>
                    <DividendLastGame
                      userRows={userRows}
                      forecast={eventData.forecast}
                      sign={'2'}
                    />
                    <SignBG>2</SignBG>
                  </SwiperSlide>
                </>
              ) : (
                <SwiperSlide>
                  <DividendForecast dividendForecast={dividendForecast} />
                </SwiperSlide>
              )}

              <SwiperSlide>
                <Dividend distribution={eventData.forecast?.distribution} />
              </SwiperSlide>
            </Swiper>
          </SliderContainer>
        </ToolsPanel> */}
      </>
    </UserRowsPopup>
  )
}

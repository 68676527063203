import React, { useState, useEffect, useContext } from 'react'
import SwiperCore, { Navigation } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/swiper-bundle.css'
import { ToolsPanel } from '../../../styles/globalStyle'
import { BombenSliderContainer, SliderContainer } from '../../Slider/Slider.elements'
import { SliderControls } from '../../LiveResult/ResultWidgets/DividendLastGame/DividendLastGame.elements'
import { font } from '../../../styles/constants'
import DividendLastGame from '../../LiveResult/ResultWidgets/DividendLastGame/DividendLastGame'
import Dividend from '../../LiveResult/ResultWidgets/Dividend/Dividend'
import DividendForecast from '../../LiveResult/ResultWidgets/DividendForecastSystem/DividendForecast'
import BombenDividend from './BombenDividend'


// install Swiper modules
SwiperCore.use([Navigation])

export default function BombenSwiper ({
  isModalOpen,
  userRows,
  forecast,
  dividendForecast,
  theLastGame,
  betRowAmount,
  win,
  totalWin,
  myWin
})
  {
  const [swiperHeader, setSwiperHeader] = useState('')
  const [swiperSubheader, setSwiperSubheader] = useState()


  return (
    <ToolsPanel style={{ marginBottom: '.4rem' }}>
      <BombenSliderContainer isModalOpen={isModalOpen}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SliderControls className='swiper-prev' />
          <div style={{ textAlign: 'center', fontSize: font.size.medium }}>
            {swiperHeader} <br />
            {theLastGame ? swiperSubheader : ''}
          </div>
          <SliderControls className='swiper-next' />
        </div>

        <Swiper
          slidesPerView={1}
          loop={true}
          speed={275}
          spaceBetween={30}
          simulateTouch={true}
          onRealIndexChange={sw => {}}
          onSlideChange={sw => {
            if (sw.realIndex) {
              setSwiperHeader('Utdelning Svenska folket')
              setSwiperSubheader(`${theLastGame?.participants[0].name} - ${theLastGame?.participants[1].name}`);
            } else {
              setSwiperHeader('Utdelningsprognos System')
              // setSwiperSubheader(theLastGame?.participants[1].name);
              setSwiperSubheader(`${theLastGame?.participants[0].name} - ${theLastGame?.participants[1].name}`);

            }
          }}
          navigation={{
            prevEl: '.swiper-prev',
            nextEl: '.swiper-next'
          }}
        >
          {theLastGame ? (
            <>
              <SwiperSlide>
                <DividendLastGame userRows={userRows} forecast={forecast} sign={'1'} betRowAmount={betRowAmount} />
                <DividendLastGame userRows={userRows} forecast={forecast} sign={'X'} betRowAmount={betRowAmount} />
                <DividendLastGame userRows={userRows} forecast={forecast} sign={'2'} betRowAmount={betRowAmount} />
              </SwiperSlide>
              <SwiperSlide>
                <Dividend
                  distribution={forecast?.distribution}
                  //large={gameType === 'stryktipset' || gameType === 'europatipset'}
                />
              </SwiperSlide>
            </>
          ) : (
            <>
              <SwiperSlide>
                <BombenDividend
                  type='win'
                  win={myWin}
                  info={totalWin}
                />
              </SwiperSlide>
              <SwiperSlide>
                <BombenDividend
                  info={win}
                />
              </SwiperSlide>
            </>
          )}
        </Swiper>
      </BombenSliderContainer>
    </ToolsPanel>
  )
}

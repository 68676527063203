import React, { useState, useContext, useEffect,useRef } from 'react'

import Tooltip from 'rc-tooltip'

import {
  ReductionContainer,
  ReductionRow,
  ReductionColumn,
  SpelaTillsammansButton,
  ReducedPercentage,
  AfterReduction,
  ReductionBtn
} from '../../Game/ReducedRow/ReducedRow.elements'
import { useHistory, Link, useParams } from 'react-router-dom'
import { ReactComponent as GroupIcon } from '../../../assets/group.svg'
import arrowDown from '../../../assets/chevron-down.svg'
import { GameContext } from '../../../context/gameContext'

export default function BombenReduceraRow({
   isReduced,
   SpTsms,
   totalRader,
   reducedRowsCount,
   betRowAmount,
   reduced,
   type,
   currId,
   removeIsReduce
   }) {
  // const [reducedRowsCount, setReducedRowsCount] = useState(0)
  const [spelaTillsammansIndicator, setSpelaTillsammansIndicator] = useState(false)
  const { drawNumber, id } = useParams();
  const gameType = 'bomben'
  const history = useHistory();
  useEffect(()=>{
    if(isReduced){
      setSpelaTillsammansIndicator(true)
    }else{
      setSpelaTillsammansIndicator(false)
    }
  },[isReduced])

  return (
    <ReductionContainer>
      <ReductionRow>
        {type !== 'live' && <div
          style={{
            display: 'flex',
            marginLeft: '0',
            marginRight: 'auto',
            alignItems: 'center',
          }}
        >
          <SpelaTillsammansButton
            className={SpTsms?.isSpelaTillsammans ? 'isActive' : null}
            onClick={() => {
              SpTsms.setIsSpelaTillsammans(!SpTsms.isSpelaTillsammans)
              removeIsReduce(null)
            }}
          >
            <GroupIcon />
          </SpelaTillsammansButton>

          {type !== 'live' && <Tooltip
            prefixCls='rToolTip'
            placement='top'
            trigger={['hover']}
            animation='anim'
            overlay={
              <>
                <h3>Spela Tillsammans</h3>

                <p>
                  Dags för andelssystem på Spela Tillsammans?
                  <em>Reducering.se</em> underlättar för spelläggare som ska spela på
                  Bomben där max antal reducerade enkelrader är 2200 och
                  den maximala insatsen är 20 000 kr.
                </p>

                <p>
                  <strong>Grön pil upp</strong> = Yes! Systemet går att lämna in på Spela Tillsammans.
                </p>

                <p>
                  <strong>Röd pil ned</strong> = No! Systemet går EJ att lämna in på Spela Tillsammans. Ändra om
                  systemet lite och testa igen!
                </p>
              </>
            }
          >
            <span style={{ marginLeft: 0, marginRight: 5 }} className='rToolTip-icon'>
              i
            </span>
          </Tooltip>}

          <div>
            {SpTsms?.isSpelaTillsammans &&
            ((spelaTillsammansIndicator && isReduced) ? (
              (reducedRowsCount <= 2200 && (reducedRowsCount*betRowAmount <= 20000)) ?
                (<span style={{ color: '#00b708' }}>▲</span>)
                :
                (<span style={{ color: '#FF0000' }}>▼</span>)
            ) : (
              <span style={{ color: '#FFF' }}>-</span>
            ))}
          </div>
        </div>}

        <ReductionColumn>
          <p className='reduction-title'>Oreducerat</p>

          <p className='reduction-info'>
            <span>
              <strong>{totalRader}</strong> rader
            </span>

            <span>
              <strong>{totalRader}</strong> kr
            </span>
          </p>
        </ReductionColumn>

        <ReducedPercentage>
          <img src={arrowDown} />

          <div className='reducedAmount'>
            <span className='reducedPercentage'>
              {!isReduced ? '?' : (100 - (reducedRowsCount / totalRader) * 100).toFixed(0)}%
            </span>

            <span className='reducedNumeral'>{!isReduced ? null : reducedRowsCount - totalRader + ' rader'}</span>
          </div>
        </ReducedPercentage>

        <AfterReduction>
          <p className='reduction-title'>Reducerat</p>

          <p className='reduction-info'>
            <span>
              <strong>{!isReduced ? '?' : reducedRowsCount}</strong> rader
            </span>

            <span>
              <strong>{!isReduced ? '?' : `${betRowAmount * reducedRowsCount}`}</strong> kr
            </span>
          </p>
        </AfterReduction>
        <ReductionBtn
          hideMed
          showLive = {type === 'live'}
          onClick={(e) => {
            e.preventDefault()
            if(type === 'live'){
              history.push(`/reducera/${gameType}/${drawNumber}${(currId || id  ) ? `/${id || currId}` : ''}`)
            }else{
              history.push(`/liveresults/${gameType}/${drawNumber}${(currId || id) ? `/${currId || id}` : ''}`)
            }
          }}
        >
          {type === 'live' ? 'reducering' : 'liverättning'}
        </ReductionBtn>
        <ReductionBtn
          onClick={reduced}
          removeMargin = {type === 'live'}
        >
          {type === 'live' ? 'Visa rader' : 'Reducera'}
        </ReductionBtn>
      </ReductionRow>
    </ReductionContainer>
  )
}

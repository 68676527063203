import styled, { createGlobalStyle } from 'styled-components'
import {
  colors,
  font,
  breakPoints,
  spacers,
  headerHeight,
  borderRadius
} from './constants'

export const ReductionToolTip = createGlobalStyle`
  .rToolTip {
    position: absolute;
    z-index: 1070;
    display: block;
    visibility: visible;
    padding: 1px;
    font-size: 1.1rem;

    &-icon {
      width: 1.7rem;
      height: 1.7rem;
      display: inline-flex;
      background-color: ${colors.toolGrey};
      border-radius: 50%;
      border: 2px solid ${colors.vividCyan};
      font-size: 1rem;
      line-height: inherit;
      font-weight: ${font.weight.bold};
      justify-content: center;
      margin-left: ${spacers.medium};
      cursor: pointer;
      justify-content: center;
      align-items: center;
    }

    &-appear,
    &-enter {
      opacity: 0;
    }

    &-anim-enter,
    &-anim-leave {
      display: block;
    }

    &-anim-enter,
    &-anim-appear {
      opacity: 0;
      animation-duration: 0.25s;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(.02,1.02,.81,1);
      animation-play-state: paused;

      &-active {
        animation-name: rToolTipAnimIn;
        animation-play-state: running;
      }
    }

    &-anim-leave {
      animation-duration: 0.25s;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(.02,1.02,.81,1);
      animation-play-state: paused;

      &-active {
        animation-name: rToolTipAnimOut;
        animation-play-state: running;
      }
    }

    &-hidden {
      display: none;
    }

    &-inner {
      padding: ${spacers.xlarge};
      text-align: left;
      text-decoration: none;
      background-color: rgba(38, 50, 56, 0.9);
      border-radius: ${borderRadius.small};
      box-shadow: 0 0 2rem 1rem rgba(0 0 0 / 25%);
      min-height: 34px;
      max-width: 30rem;

      h2, h3, h4 {
        color: ${colors.white};
        font-weight: ${font.weight.semiBold};
      }

      p {
        color: ${colors.lightGrey};

        strong, em {
          color: ${colors.white};
        }
      }

      h2, h3, h4, p {
        margin-bottom: ${spacers.medium};
      }
    }

    // &-arrow,
    // &-arrow-inner {
    //   position: absolute;
    //   width: 0;
    //   height: 0;
    //   border-color: transparent;
    //   border-style: solid;
    // }

    // &-placement {
    //   &-top,
    //   &-topLeft,
    //   &-topRight {
    //     .rToolTip-arrow {
    //       bottom: -5px;
    //       margin-left: -6px;
    //       border-width: 6px 6px 0;
    //       border-top-color: #b1b1b1;

    //       &-inner {
    //         bottom: 1px;
    //         margin-left: -6px;
    //         border-width: 6px 6px 0;
    //         border-top-color: #ffffff;
    //       }
    //     }
    //   }

      // &-top {
      //   .rToolTip-arrow {
      //     left: 50%;
      //   }
      // }

      // &-topLeft {
      //   .rToolTip-arrow {
      //     left: 15%;
      //   }
      // }

      // &-topRight {
      //   .rToolTip-arrow {
      //     right: 15%;
      //   }
      // }

      // &-bottom,
      // &-bottomLeft,
      // &-bottomRight {
      //   .rToolTip-arrow {
      //     top: -5px;
      //     margin-left: -6px;
      //     border-width: 0 6px 6px;
      //     border-bottom-color: #b1b1b1;

      //     &-inner {
      //       top: 1px;
      //       margin-left: -6px;
      //       border-width: 0 6px 6px;
      //       border-bottom-color: #ffffff;
      //     }
      //   }
      // }

      // &-bottom {
      //   .rToolTip-arrow {
      //     left: 50%;
      //   }
      // }

      // &-bottomLeft {
      //   .rToolTip-arrow {
      //     left: 15%;
      //   }
      // }

      // &-bottomRight {
      //   .rToolTip-arrow {
      //     right: 15%;
      //   }
      // }

      // &-right,
      // &-rightTop,
      // &-rightBottom {
      //   .rToolTip-arrow {
      //     left: -5px;
      //     margin-top: -6px;
      //     border-width: 6px 6px 6px 0;
      //     border-right-color: #b1b1b1;
      //   }
      // }

      // &-right,
      // &-rightTop,
      // &-rightBottom {
      //   .rToolTip-arrow-inner {
      //     left: 1px;
      //     margin-top: -6px;
      //     border-width: 6px 6px 6px 0;
      //     border-right-color: #ffffff;
      //   }
      // }

      // &-right {
      //   .rToolTip-arrow {
      //     top: 50%;
      //   }
      // }

      // &-rightTop {
      //   .rToolTip-arrow {
      //     top: 15%;
      //     margin-top: 0;
      //   }
      // }

      // &-rightBottom {
      //   .rToolTip-arrow {
      //     bottom: 15%;
      //   }
      // }

      // &-left,
      // &-leftTop,
      // &-leftBottom {
      //   .rToolTip-arrow {
      //     right: -5px;
      //     margin-top: -6px;
      //     border-width: 6px 0 6px 6px;
      //     border-left-color: #b1b1b1;
      //   }
      // }

      // &-left,
      // &-leftTop,
      // &-leftBottom {
      //   .rToolTip-arrow-inner {
      //     right: 1px;
      //     margin-top: -6px;
      //     border-width: 6px 0 6px 6px;
      //     border-left-color: #ffffff;
      //   }
      // }

      // &-left {
      //   .rToolTip-arrow {
      //     top: 50%;
      //   }
      // }

      // &-leftTop {
      //   .rToolTip-arrow {
      //     top: 15%;
      //     margin-top: 0;
      //   }
      // }

      // &-leftBottom {
      //   .rToolTip-arrow {
      //     bottom: 15%;
      //   }
      // }
    // }
  }

  @keyframes rToolTipAnimIn {
    0% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: translateY(-0.5rem);
    }
    100% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: translateY(0);
    }
  }

  @keyframes rToolTipAnimOut {
    0% {
      opacity: 1;
      transform-origin: 50% 50%;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform-origin: 50% 50%;
      transform: translateY(-0.5rem);
    }
  }
`
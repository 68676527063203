import React, { useState, useEffect } from 'react'
import windowDimensions from '../../../utils/hooks/useWindowDimensions.js'

import { useLocation } from 'react-router-dom'
import { Link, animateScroll as scroll, scroller } from 'react-scroll'

import { GamePageAboutNav } from './GamePageAboutContent.elements'

export default function StryktipsetNavigation() {
  const { winHeight, winWidth } = windowDimensions()

  const { state } = useLocation()

  const [aboutGameContentState, setAboutGameContentState] = useState(null)
  const [aboutGameHeaderState, setAboutGameHeaderState] = useState(null)

  const inPageAnchors = [
    {
      title: 'Vad är Powerplay?',
      anchor: '#vad-ar-powerplay'
    },
    {
      title: 'Powerplay - så fungerar det',
      anchor: '#powerplay-sa-fungerar-det'
    },
    {
      title: 'Powerplay - resultat och jackpot',
      anchor: '#powerplay-resultat-och-jackpot'
    },
    {
      title: 'Powerplay - “tips från coachen”',
      anchor: '#powerplay-tips-fran-coachen',
      highlight: true
    },
    {
      title: 'Powerplay - vinstutdelning',
      anchor: '#powerplay-vinstutdelning'
    }
  ]

  useEffect(() => {
    const aboutGameContent = document.getElementById('aboutGameContent').offsetTop
    setAboutGameContentState(aboutGameContent + 1)

    const aboutGameHeader = document.getElementById('aboutGameHeader').clientHeight
    setAboutGameHeaderState(aboutGameHeader + 56)
  }, [winHeight, winWidth])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [activeSection, setActiveSection] = useState(false)

  const handleOnSetActive = e => {
    setActiveSection(e)
    document.getElementById(e).classList.add('highlight')
  }

  const handleOnSetInActive = e => {
    if (e === activeSection) {
      setActiveSection(false)
      document.getElementById(e).classList.remove('highlight')
    }
  }

  return (
    <GamePageAboutNav>
      <select
        className='hide-for-small'
        onChange={ev => {
          let url = ev.target.value

          if (url) {
            scroller.scrollTo(url, {
              duration: 350,
              smooth: true,
              offset: -aboutGameHeaderState
            })
          }
          return false
        }}
      >
        <option key={'go'} value=''>
          Navigera till…
        </option>
        {inPageAnchors.map((el, idx) => (
          <option key={`inPageAnchor-optn-${idx}`} value={el.anchor}>
            {el.title}
          </option>
        ))}
      </select>

      <ul className='show-for-small'>
        {inPageAnchors.map((el, idx) => (
          <li key={`inPageAnchor-li-${idx}`}>
            <Link
              activeClass='highlight'
              to={el.anchor}
              spy={true}
              smooth={true}
              offset={-aboutGameContentState}
              duration={350}
              onSetActive={handleOnSetActive}
              onSetInactive={handleOnSetInActive}
              ignoreCancelEvents={false}
            >
              {el.title}
            </Link>
          </li>
        ))}
      </ul>
    </GamePageAboutNav>
  )
}

import React, { useEffect, useState } from 'react'
import { RecentEventsItem, ScoreCounter } from './RecentEvents.elements'

export default function RecentEvent({ recentDetails, playNotificationSound }) {
  const [isNewEvent, setIsNewEvent] = useState(false)
  const [goals, setGoals] = useState(recentDetails.outcomeScore.split('-'))
  // const teams = recentDetails.description.split('-')
  const teams = [
    recentDetails.participants[0].name, 
    recentDetails.participants[1].name
  ]

  useEffect(() => {
    setIsNewEvent(true)
    setTimeout(() => setIsNewEvent(false), [3000])
    playNotificationSound()
  }, [goals])

  return (
    <RecentEventsItem inTheLead={null} isNewEvent={isNewEvent}>
      <div className='teamHome'>{teams[0]}</div>

      <div className='score'>
        <ScoreCounter scored={teams[0] === recentDetails.scoringTeam}>
          {goals[0]}
        </ScoreCounter>{' '}
        -
        <ScoreCounter scored={teams[1] === recentDetails.scoringTeam}>
          {' '}
          {goals[1]}
        </ScoreCounter>
      </div>

      <div className='teamAway'>{teams[1]}</div>
    </RecentEventsItem>
  )
}

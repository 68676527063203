import React, { useContext, useEffect, useRef, useState } from "react";
import HomeHeader from "../../components/Headers/HomeHeader";
import { useAuth0 } from "@auth0/auth0-react";
import { InnerContainer } from "../../styles/globalStyle";
import { CreateContainer } from "./CreateNews.element";
import { AccountContext } from "../../context/accountContext";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";

const CreateNews = () => {
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  let location = useLocation();
  const { username } = useContext(AccountContext);

  //State
  const [historyLoad, setHistoryLoad] = useState(null);
  //State for inputs
  const [createNews, setCreateNews] = useState({
    title: "",
    date: new Date(),
    namedAuthor: "",
    intro: "",
    text: "",
    image: null,
  });
  const [error, setError] = useState({
    title: null,
    date: null,
    author: null,
  });

  //Handle change for inputs
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateNews((prev) => ({
      ...prev,
      [name]: value,
    }));
    setError((prev) => ({
      ...prev,
      [name]: null,
    }));
  };
  const handleChangeDate = (e) => {
    setCreateNews((prev) => ({
      ...prev,
      date: e,
    }));
    setError((prev) => ({
      ...prev,
      date: null,
    }));
  };

  useEffect(() => {
    if (username && !historyLoad) {
      setCreateNews((prev) => ({ ...prev, namedAuthor: username }));
    }
  }, [username]);

  // Submit news
  const handleSubmit = () => {
    if (historyLoad) {
      if (validField()) {
        let patchObject = {};
        patchCreate(patchObject, historyLoad._id);
        return;
      }
    }
    if (validField()) {
      let sendObject = {
        title: createNews.title,
        date: createNews.date,
        namedAuthor: createNews.author,
        intro: createNews.intro,
        text: createNews.text,
      };
      fetchCreate(sendObject);
    } else {
      return;
    }
  };

  // Validate
  const validField = () => {
    let valid = true;
    if (!createNews.title) {
      setError((prev) => ({ ...prev, title: true }));
      valid = false;
    }
    if (!createNews.namedAuthor) {
      setError((prev) => ({ ...prev, author: true }));
      valid = false;
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
    return valid;
    /* if(){

    }*/
  };

  useEffect(async () => {
    if (location?.state?.id) {
      await fetchNews(location?.state?.id);
      setError((prev) => ({
        ...prev,
      }));
    }
  }, [location?.state?.id]);

  //Fetch send
  const fetchCreate = async (data = {}) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    const formData = new FormData();
    formData.append("image", createNews.image);
    formData.append("title", createNews.title);
    formData.append("date", new Date(createNews.date).toISOString());
    formData.append("namedAuthor", createNews.namedAuthor);
    formData.append("intro", createNews.intro);
    formData.append("text", createNews.text);

    //await setIsLoader(true)
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/news`, {
      method: "POST",
      headers: headers,
      body: formData,
    });

    let errorMessage = await response.json();
    if (response.ok) {
      setShowExitPrompt(false);
      // if create news then redirect to news page
      let getId = errorMessage.created[0]._id;
      history.push({
        pathname: `news/${getId}`,
        state: { id: getId },
      });
    }
    return { response, error: errorMessage };
  };
  // Fetch patch
  const patchCreate = async (data = {}, id) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    const formData = new FormData();
    formData.append("image", createNews.image);
    formData.append("title", createNews.title);
    formData.append("date", new Date(createNews.date).toISOString());
    formData.append("namedAuthor", createNews.namedAuthor);
    formData.append("intro", createNews.intro);
    formData.append("text", createNews.text);

    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/news/${id}`,
      {
        method: "PATCH",
        headers: headers,
        body: formData,
      }
    );

    let errorMessage = await response.json();
    if (response.ok) {
      setShowExitPrompt(false);
      // if create news then redirect to news page
      let getId = errorMessage.updated._id;
      history.push({
        pathname: `news/${getId}`,
        state: { id: getId },
      });
    }
    return { response, error: errorMessage };
  };
  // Use effect to attach leave page
  const [showExitPrompt, setShowExitPrompt] = useState(true);

  useEffect(() => {
    window.addEventListener("onbeforeunload", preventClose);
    return () => {
      window.removeEventListener("onbeforeunload", preventClose);
    };
  }, []);
  const preventClose = (event) => {
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
      e.returnValue = ""; // Legacy method for cross browser support
    }
    return ""; // Legacy method for cross browser support
  };
  // handle for button cansel
  const handleRedirect = () => {
    history.push("admin");
  };
  //handle for checkbox
  // state for checkbox
  const [checkbox, setCheckbox] = useState(null);

  const fetchNews = async (id) => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    //await setIsLoader(true)
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/news/${id}`,
      {
        method: "GET",
        headers: headers,
        // body:JSON.stringify(data)
      }
    );

    let errorMessage = await response.json();
    if (response.ok) {
      setHistoryLoad(errorMessage);
      setCreateNews({
        title: errorMessage.title,
        date: parseISO(errorMessage.date),
        namedAuthor: errorMessage.namedAuthor,
        intro: errorMessage.intro,
        text: errorMessage.text,
        // image: errorMessage.image,
      });
    }
    return { response, error: errorMessage };
  };

  useEffect(() => {
    if (username && !historyLoad) {
      setCreateNews((prev) => ({ ...prev, author: username }));
    }
  }, [username]);

  return (
    <div>
      <Prompt
        when={showExitPrompt}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <HomeHeader />
      <InnerContainer>
        <CreateContainer>
          <div className="head">
            <h2>Nytt inlägg</h2>
            <button onClick={handleSubmit} className="save button">
              SPARA & PUPLICERA
            </button>
          </div>

          <div className="row">
            <label htmlFor="title">Titel</label>
            <input
              name="title"
              id="title"
              className={`${error?.title ? "input-error" : ""}`}
              placeholder="Titel"
              value={createNews.title}
              onChange={handleChange}
              maxLength={255}
              minLength={1}
            />
            <span className="counter">{createNews.title.length} /80</span>
            {error?.title && (
              <span className="info-error-input">Obligatoriskt fält</span>
            )}
          </div>
          <div className="row">
            <label htmlFor="intro">Intro</label>
            <input
              name="intro"
              id="intro"
              className={`${error?.intro ? "input-error" : ""}`}
              placeholder="Titel"
              value={createNews.intro}
              onChange={handleChange}
              maxLength={1000}
              minLength={1}
            />
            <span className="counter">{createNews.intro.length} /80</span>
            {error?.intro && (
              <span className="info-error-input">Obligatoriskt fält</span>
            )}
          </div>
          <div className="row">
            <label htmlFor="text">Text</label>
            <textarea
              name="text"
              id="text"
              placeholder="Beskrivning"
              value={createNews.text}
              onChange={handleChange}
              maxLength={15000}
              minLength={1}
            />
            <span className="counter">{createNews.text.length} /15000</span>
          </div>

          {/* image */}
          <div className="row">
            <label htmlFor="text">Bild</label>
            <input
              type="file"
              id="file"
              onChange={(e) => {
                setCreateNews((prev) => ({
                  ...prev,
                  image: e.target.files[0],
                }));
              }}
              accept="image/*"
            />
          </div>
          <div className="row row--space">
            <div className="row-div">
              <label htmlFor="date">Datum</label>
              <DatePicker
                selected={createNews.date}
                placeholderText="Datum"
                onChange={handleChangeDate}
                dateFormat="dd/MM/yyyy "
              />
            </div>
            <div className="row-div">
              <label htmlFor="namedAuthor">Författare</label>
              <input
                placeholder="Författare"
                className={`${error?.namedAuthor ? "input-error" : ""}`}
                id="namedAuthor"
                name="namedAuthor"
                value={createNews.namedAuthor}
                onChange={handleChange}
                maxLength={255}
                minLength={1}
              />
              <span className="counter">
                {createNews.namedAuthor.length} /255
              </span>
              {error?.namedAuthor && (
                <span className="info-error-input">Obligatoriskt fält</span>
              )}
            </div>
          </div>

          <div className="row row--default ">
            <button onClick={handleSubmit} className="save button">
              SPARA & PUPLICERA
            </button>
            <button className="button" onClick={handleRedirect}>
              AVBRYT
            </button>
          </div>
        </CreateContainer>
      </InnerContainer>
    </div>
  );
};

export default CreateNews;

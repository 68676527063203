import styled from 'styled-components'
import {
  ToolsPanelSmall
} from '../../../../styles/globalStyle'
import {
  colors,
  font,
  breakPoints,
  spacers,
  borderRadius
} from '../../../../styles/constants'

export const GuardContainer = styled(ToolsPanelSmall)`
  flex: 0 0 auto;

  flex-direction: row;
  justify-content: space-between;

  > p {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: initial;
    width: 50%;
    display: flex;

    strong {
      margin-left: 1rem;
    }
  }
`

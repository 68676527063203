import React, {useContext,useState,useRef} from 'react'
import { TeamCreate,TeamCreateWrapper  } from './Popup.elements'
import 'reactjs-popup/dist/index.css'
import { getActiveDate, sortDates } from '../../utils/helperFunctions'
import svsBlueSymbol from '../../assets/svs-blue-symbol.svg'
import svenskaSpelLogo from '../../assets/svenskaspel-logomark.svg'
import stryktipsetLogo from '../../assets/stryktipset-wordmark.svg'
import bombenLogo from '../../assets/bomben-wordmark.svg'
import { ActiveGamesContext } from '../../context/activeGamesContext'
import europatipsetLogo from '../../assets/europatipset-wordmark.svg'
import topptipsetLogo from '../../assets/topptipset-wordmark.svg'
import powerplayLogo from '../../assets/powerplay-wordmark.svg'
import { useOnClickOutside } from '../../utils/hooks/useOnClickOutside'
import arrow from '../../assets/arrow.png'
import { PointInput } from '../Game/GameList/SelectBet/SelectBet.elements'

export default function ActiveCreatePopup({  isModalOpen,
  setIsModalOpen,Submit,setIsLoader,updateTeam }) {
  const ref = useRef(null)
  const { stryktipset, europatipset, topptipset, powerplay, bomben } =
    useContext(ActiveGamesContext);
  useOnClickOutside(ref, () => {
    setIsOPenSelect(false)
  })
  const [formError, setFormError] = useState({
    name:null,
    price:null,
    link:null,
  })
  const [responseError,setResponseError] = useState(false)
  const [isOpenSelect, setIsOPenSelect] = useState(false);
  const [selected, setSelected] = useState({img:null, info:null,type:''});
  const [selectedError,setSelectedError] = useState(false)
  const [Form,setForm] = useState({
    name:'',
    price:'',
    link:''
  })

  const modifyDrawComment = (comment) => {
    const pattern = /(\d{4}-\d)/;
    if (pattern.test(comment)) {
      // Replace the pattern with the last number
      return comment.replace(pattern, (match, p1) => p1.split('-')[1]);
    }
    return comment; // Return original comment if pattern not found
  };

  const handleChange = (e) =>{
    setResponseError(false)
    let getName = e?.currentTarget?.name
    let value = e.target.value
    if(getName === 'price'){

    }
    setForm(prev =>({...prev,
    [getName]:value
    }))
    setFormError(prev =>({...prev,
      [getName]:null
    }))
  }
  const blockInvalidChar = e =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const handleOpen = (e) =>{
    setIsOPenSelect(prevState => !prevState)
  }
  const handleColor = (type) =>{
    if(type === "stryktipset") {
      return "background-str"
    } else if (type === "europatipset"){
      return "background-euro"
    }else if(type === "topptipset"){
      return "background-top"
    }
    else if(type === "powerplay"){
      return "background-pow"
    } else if (type === 'bomben'){
      return "background-pow"
    }
    else{
      return ''
    }
  }
  const image = [stryktipsetLogo,europatipsetLogo,topptipsetLogo,powerplayLogo,bombenLogo]
  const handleSelect = (event,type,id,idx) =>{
    if(type === "stryktipset"){
      const findItem = stryktipset?.draws.find(item=>item.drawNumber === id);
      let date = getActiveDate(stryktipset, idx);
      if(findItem) setSelected({img:image[0],info:date,type:"stryktipset",gameType:findItem.productName ,closeTime:findItem.closeTime})
      setSelectedError(false)
      setIsOPenSelect(false)
      return
    }
    else if(type === "europatipset"){
      const findItem = europatipset?.draws.find(item=>item.drawNumber === id);
      let date = getActiveDate(europatipset, idx);
      if(findItem) setSelected({img:image[1],info:date,type:"europatipset",gameType:findItem.productName,closeTime:findItem.closeTime })
      setSelectedError(false)
      setIsOPenSelect(false)
      return
    }
    else if(type === "topptipset"){
      const findItem = topptipset?.draws.find(item=>item.drawNumber === id);
      let date = getActiveDate(topptipset, idx);
      if(findItem) setSelected({img:image[2],info:date,type:"topptipset",gameType:findItem.productName ,closeTime:findItem.closeTime})
      setSelectedError(false)
      setIsOPenSelect(false)
      return
    }
    else if(type === "powerplay"){
      const findItem = powerplay?.draws.find(item=>item.drawNumber === id);
      let date = getActiveDate(powerplay, idx);
      if(findItem) setSelected({img:image[3],info:date,type:"powerplay",gameType:findItem.productName,closeTime:findItem.closeTime })
      setSelectedError(false)
      setIsOPenSelect(false)
      return
    }
    else if(type === "bomben"){
      const findItem = bomben?.draws.find(item=>item.drawNumber === id);
      let date = getActiveDate(bomben, idx);
      if(findItem) setSelected({img:image[4],info:date,type:"bomben",gameType:findItem.productName,closeTime:findItem.closeTime })
      setSelectedError(false)
      setIsOPenSelect(false)
      return
    }
  }
  const handleValid = (e) =>{
    let isValid = true
    if(!Form.name){
      setFormError(prev => ({...prev,name:true}))
      isValid=false
    }
    if(!(Form.name.length > 9)){
      setFormError(prev => ({...prev,name:true}))
      isValid=false
    }
    if(!Form.price){
      setFormError(prev => ({...prev,price:true}))
      isValid=false
    }

    if( parseFloat(Form.price) > 5000){
      setFormError(prev => ({...prev,price:true}))
      isValid=false
    }
    if( parseInt(Form.price) < 1){
      setFormError(prev => ({...prev,price:true}))
      isValid=false
    }
    if(!Form.link ){
      setFormError(prev => ({...prev,link:true}))
      isValid=false
    }
    if(!(Form?.link?.includes('spela.svenskaspel.se')) ){
      setFormError(prev => ({...prev,link:true}))
      isValid=false
    }
    if(!selected.info){
      setSelectedError(true)
      isValid=false
    }
    let total = Object.keys(Form);
    total.map(key =>{
      if(Form[key] === null){
        isValid = false
        return
      }
    })
    return isValid
  }
  const handleSubmit = () =>{
    if(handleValid()){
      const objToSend = {
        title:Form.name,
        gameType:selected.type,
        price:Form.price,
        link:Form.link,
        date:selected.closeTime
      }
      Submit(objToSend).then(res=>{
        if(res.response.ok){
          setIsModalOpen(false)
          updateTeam()
        }else{
          //debugger
          //if(res.response.status === 422) setFormError(prev => ({...prev,link:true}))
          if(res?.error?.errors?.length > 0) {
            setFormError(prev => ({...prev,link:true}))
          }
          setResponseError(res)
          setIsLoader(false)
        }
      }).catch(ree =>{
      })

    }
  }
  const resetError = () =>{
    setFormError({
      name:null,
      price:null,
      link:null
    })
    setResponseError(null)
    setSelectedError(null)
    setSelected({img:null, info:null,type:''})
    setForm({
      name:'',
      price:'',
      link:''
    })
  }
  return (
    <TeamCreate
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false)
        resetError()
      }}
      position='center'
    >
      <>
        <TeamCreateWrapper >
          <div className="svsBlueSymbolContainer">
            <img className='svsBlueSymbol' src={svsBlueSymbol} alt='Svenska Spel Symbol' />
          </div>
          <div>
            <img className='img' src={svenskaSpelLogo} alt='Svenska Spel' />
            <h4>SKAPA ANDELSSPEL</h4>
            <p>Tillsammans-spel med Liverättning</p>
          </div>
          <div>
            <div ref={ref} className='custom-dropdow'>
              <div onClick={handleOpen}
                   className={`custom-selected ${handleColor(selected.type)} 
                   ${selectedError ? 'error' : ''}
                   ${selected.type === '' ? 'custom-selected-default' : ''}`}>
                <span className='open'>
                  <img style={{transform:isOpenSelect ? 'rotate(270deg)' : 'rotate(90deg)'}}
                       src={arrow}/>
                </span>
                <li className="custom-item">
                  <img  src={selected.img} className={`img-logos ${selected.type === 'powerplay' ? "img-logo-pow" : ""}`}/>
                  <span> {selected.type !== "" ?
                    <span className='custom-span'>SPELSTOPP </span>
                    : <span className='span-default'>Välj omgång</span>}{selected.info}</span>
                </li>
              </div>
              { isOpenSelect &&
              <div className='custom-list'>
                <li className='nav-text stryktipset'>
                    <img className="img-logos" src={stryktipsetLogo} alt='Stryktipset' />
                </li>
                {
                  !stryktipset?.draws.length ? null : (
                      stryktipset?.draws
                          .slice()
                          .sort((a, b) => new Date(a.closeTime) - new Date(b.closeTime))
                          .map((item, idx) => (
                              <li className='nav-text stryktipset'
                                  onClick={(e) => handleSelect(e, 'stryktipset', item?.drawNumber, idx)}>
                                <span className="custom-span">{getActiveDate({draws: [item]}, 0, true)}</span>
                                <span>{modifyDrawComment(item?.drawComment)}</span>
                              </li>
                          ))
                  )
                }
                <li className='nav-text europatipset'>
                <img className="img-logos" src={europatipsetLogo} alt='Europatipset' />
                </li>
                {
                  !europatipset?.draws.length ? null : (
                      europatipset.draws
                          .slice()
                          .sort((a, b) => new Date(a.closeTime) - new Date(b.closeTime))
                          .map((item, idx) => (
                              <li className='nav-text europatipset' onClick={(e) => handleSelect(e, 'europatipset', item?.drawNumber, idx)}>
                                <span className="custom-span">{getActiveDate({draws: [item]}, 0, true)}</span>
                                <span>{modifyDrawComment(item?.drawComment)}</span>
                              </li>
                          ))
                  )
                }
                <li className='nav-text powerplay'>
                  <img className="img-logos" src={bombenLogo} alt='PowerPlay' />
                </li>
                {
                  !bomben?.draws.length ? null : (
                      bomben.draws
                          .slice() // Create a shallow copy
                          .sort((a, b) => new Date(a.closeTime) - new Date(b.closeTime))
                          .map((item, idx) => ( // Map over the sorted array
                              <li className='nav-text powerplay' key={`nav-pp${idx}`}
                                  onClick={(e) => handleSelect(e, 'bomben', item?.drawNumber, idx)}>
                                <span className="custom-span">{getActiveDate({draws: [item]}, 0, true)}</span>
                                <span>{modifyDrawComment(item?.drawComment)}</span>
                              </li>
                          ))
                  )
                }
                <li className='nav-text topptipset'>
                  <img className="img-logos" src={topptipsetLogo} alt='Topptipset' />
                </li>
                {
                  !topptipset?.draws.length ? null : (
                      topptipset.draws
                          .slice()
                          .sort((a, b) => new Date(a.closeTime) - new Date(b.closeTime))
                          .map((item, idx) => (
                              <li className='nav-text topptipset' key={`nav-tt${idx}`}
                                  onClick={(e) => handleSelect(e, 'topptipset', item?.drawNumber, idx)}>
                                <span className="custom-span">{getActiveDate({draws: [item]}, 0, true)}</span>
                                <span>{modifyDrawComment(item?.drawComment)}</span>
                              </li>
                          ))
                  )
                }
                <li className='nav-text powerplay'>
                  <img className="img-logos" src={powerplayLogo} alt='PowerPlay' />
                </li>
                {
                  !powerplay?.draws.length ? null : (
                      powerplay.draws
                          .slice()
                          .sort((a, b) => new Date(a.closeTime) - new Date(b.closeTime))
                          .map((item, idx) => (
                              <li className='nav-text powerplay' key={`nav-pp${idx}`}
                                  onClick={(e) => handleSelect(e, 'powerplay', item?.drawNumber, idx)}>
                                <span className="custom-span">{getActiveDate({draws: [item]}, 0, true)}</span>
                                <span>{modifyDrawComment(item?.drawComment)}</span>
                              </li>
                          ))
                  )
                }
              </div>}
              {selectedError && (
                  <p className='info-error custom-error'>Obligatoriskt fält</p>
              )}
            </div>
          </div>
          <div className='d-flex'>
            <div>
              <label>Namn på laget</label>
              <input maxLength={40}
                     minLength={10}
                     name='name'
                     type='text'
                     value={Form.name}
                     onChange={handleChange}
                     className={`input ${formError.name ? 'input-error' : ''}`}
                     placeholder="Namn på laget"/>
              {formError.name && (
                <p className='info-error'>{Form.name?.length ? 'Valt namn är för kort' : 'Obligatoriskt fält'}</p>
              )}
            </div>
            <div>
              <label>Pris per andel</label>
              <input type='number' value={Form.price}
                     name='price'
                     onChange={handleChange}
                     onKeyDown={blockInvalidChar}
                     className={`input ${formError.price ? 'input-error' : ''}`}
                     placeholder="45                                       kr"/>
              {formError.price && (
                <p className='info-error'>{Form.price ? 'Godkända värden: från 1 till 5000 kr ' : 'Obligatoriskt fält'}</p>
              )}
            </div>
          </div>
          <div className='link'>
            <label>Länk till laget</label>
            <input type='text'
                   value={Form.link} name='link'
                   onChange={handleChange}
                   className={`input ${formError.link ? 'input-error' : ''}`}
                   placeholder="Länk till laget"  />
            {formError.link && (
              <p className='info-error'>{Form.link ? 'Endast Spela Tillsammans-länkar' :'Obligatoriskt fält'}</p>
            )}
          </div>
          {responseError && <p style={{color:'#ff0000'}} >{responseError?.error?.message}</p>}
          <div  className='btn-group'>
            <button onClick={handleSubmit} className='btn btn-submit'>SKAPA LAG</button>
            <button onClick={()=>{
              resetError()
              setIsModalOpen(false)}} className='btn'>AVBRYT</button>
          </div>
        </TeamCreateWrapper >
      </>
    </TeamCreate>
  )
}

import React, { useContext } from 'react'
import {NotFoundContainer} from './NotFoundUser.elements';
import { ReactComponent as NotFound } from '../../assets/userNotFound.svg'
import {useHistory} from 'react-router-dom'
import { AccountContext } from '../../context/accountContext'

const NotFoundUser = ({ setUserNotFound}) => {
  let history = useHistory();
  const { username } = useContext(AccountContext);
  return (
    <NotFoundContainer>
      <NotFound className = 'svg'/>
      <h3 className='title'>Användare hittas ej</h3>
      <p className='info'>Denna användare hittas ej. Det kan bero på att användaren är
        borttagen eller att användaren har bytt användarnamn. Dubbelkolla
        så att du är inne på rätt profil.
      </p>
      <div className='button-wrapper'>
        <button disabled={!username} onClick={()=>{
          if(username){
            setUserNotFound(false)
            history?.push(`/profile/${username}`)
          }
        }} className='btn '>Tillbaka</button>
        <button onClick={()=>{
            history?.push('/')
        }}
                className='btn btn--green'>Till startsidan</button>
      </div>
    </NotFoundContainer>
  )
}

export default NotFoundUser
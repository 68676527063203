import styled, { css } from 'styled-components'
import React from 'react'

export const BombenDistributionContainer = styled.div`
    width: 8.5rem;
    max-width: 11rem;
    height: 4.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #465761;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 4px 8px 4px 9px;
    cursor: pointer;
    flex-grow: 1;
    .info-rader{
      display: flex;
      flex-direction: column;
      font-size: 1.4rem;
    }
    .info-padding{
      padding-left: 4px;
    }
    .circle{
      flex-shrink: 0;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background: #506974;
      position: relative;
      &__inside{
        width: 100%;
        height: 100%;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        color: rgb(69, 90, 100);
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
    .info{
      &-type{
        display: flex;
        grid-gap: 0.8rem;
        align-items: center;
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: #FFFFFF;
      }
    }
    .total{
      font-weight: 700;
      position: relative;
      align-self: stretch;
      display: flex;
      align-items: center;
      font-size: 1.8rem;
      &:after{
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: white;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    @media screen and (max-width: 410px){
      width: 100%;
      max-width: 100%;
      height: 5.2rem;
      .info-type{
        font-size: 1.4rem;
      }
      .total:after{
        left: 16px;
      }
      .info-rader{
        flex-direction: row;
        grid-gap: 1rem;
        font-size: 1.8rem;
      }
      .circle{
        width: 3.5rem;
        height: 3.5rem;
        .circle__inside{
          font-size: 1.6rem;
        }
      }
      .info-padding{
        width: 40px;
      }
      .small{
        font-size: 1.4rem!important;
      }
    }
    ${props=>props.isActive && css`
      background-color: #40C7A8;
    `}
    ${props=>props.bgColor && css`
      background: ${props.bgColor};
      background-blend-mode: color;
    `}
    ${props=>props.live && css`
      width: 3.3rem;
      height: 3.3rem;
      padding: 0;
      justify-content: center;
      .info{
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #667C88;
        .total-live{
        font-size: 1.4rem;
        }
      }
      @media screen and (max-width: 969px){
        flex-grow: 1;
      }
      @media screen and (max-width: 490px){
          flex-grow: 1;
          flex-shrink: 1;
          flex-wrap: nowrap;
          width: 2.8rem;
          height: 2.8rem;
        .info{
          width: 1.8rem;
          height: 1.8rem;
          .total-live{
            font-size: 1rem;
          }
        }
      }
      @media screen and (max-width: 390px){
          width: 2.4rem;
          height: 2.4rem;
        .info{
          width: 1.8rem;
          height: 1.8rem;
        }
      }
    `}
    .total-live{
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #FFFFFF;
    }
    ${props=>props.win && css `
      box-shadow: rgb(255 0 0) 0px 0px 0px 1px inset, rgb(255 255 255) 0px 0px 0px 4px inset;
    `}
    ${props => props.head && css`
      height: 2rem;
      background: #667C88;
      border-radius: 0px;
    `}
`
import React, { useState, useEffect } from 'react'
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions'
import Tooltip from 'rc-tooltip'
import { ToolsHeading, ToolsPanel } from '../../../styles/globalStyle'
import {
  UserRowsContainer,
  UserRowsTool,
  UserRowsHeader,
  InnerContainer,
  UserRowsList,
  InnerColList,
  InnerUserSign,
  CorrectRows
} from './UserRows.elements'
import { font } from '../../../styles/constants'

import SwiperBox from '../ResultWidgets/SwiperCast/SwiperBox';

import Dividend from '../ResultWidgets/Dividend/Dividend'
import DividendForecast from '../ResultWidgets/DividendForecastSystem/DividendForecast'
import DividendLastGame from '../ResultWidgets/DividendLastGame/DividendLastGame'
import {
  SliderContainer,
  SignBG,
  SliderControls
} from '../ResultWidgets/DividendLastGame/DividendLastGame.elements'
import SwiperCore, { Navigation } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/swiper-bundle.css'
// install Swiper modules
SwiperCore.use([Navigation])

// Helper to count correct rows from eventStatus, forecastEvents, a row
const isCorrect = (sign, event, fcEvent, toggleUnplayed) => {
  return event.sportEventStatus === 'Inte startat' && sign === 'X' && !fcEvent.cancelled
    ? toggleUnplayed
    : sign === fcEvent.outcome
}
const countCorrect = (row, events, fcEvents, toggleUnplayed) => {
  return row.reduce((acc, sign, idx) => {
    return acc + isCorrect(sign, events[idx], fcEvents[idx], toggleUnplayed)
  }, 0)
}

export default function UserRows({
  eventData: { forecast, events },
  userRows,
  dividendForecast,
  updateForecast,
  toggleUnplayed,
  setToggleUnplayed,
  isModalOpen,
  betRowAmount
}) {
  const handleToggle = () => {
    setToggleUnplayed(!toggleUnplayed)
    updateForecast(!toggleUnplayed)
  }

  const [nrOneBestRow, setNrOneBestRow] = useState('')
  const [isNewBestRow, setIsNewBestRow] = useState(false)

  const { winWidth } = useWindowDimensions()
  //console.log('winWidth', winWidth)

  const bestRows = (fcEvents, coupon) => {
    const correctArr = coupon
      .sort(
        (a, b) =>
          countCorrect(b, events, fcEvents, toggleUnplayed) -
          countCorrect(a, events, fcEvents, toggleUnplayed)
      )
      .slice(0, isModalOpen ? 50 : 10)
      .map((row, i) => {
        const bestRowsAmount = countCorrect(row, events, fcEvents, toggleUnplayed)
        const ColumnList = (
          <InnerColList key={`bet-${i}`}>
            {row.map((sign, idx) => {
              return (
                <InnerUserSign
                  correctBet={isCorrect(sign, events[idx], fcEvents[idx], toggleUnplayed)}
                  key={`${i}-innerUserSign-${idx}`}
                >
                  {sign}
                </InnerUserSign>
              )
            })}
            <CorrectRows>{bestRowsAmount}</CorrectRows>
          </InnerColList>
        )
        return { component: ColumnList, value: bestRowsAmount }
      })

    return correctArr
  }
  let userBestRows = userRows
    ? bestRows(forecast?.events || Array(events.length).fill({ outcome: 'X' }), userRows)
    : []

  useEffect(() => {
    setNrOneBestRow(userBestRows?.[0]?.value)
  }, [userBestRows])

  useEffect(() => {
    setIsNewBestRow(true)
    let timer = setTimeout(() => setIsNewBestRow(false), [2000])
    return () => {
      clearTimeout(timer)
    }
  }, [nrOneBestRow])

  let isOneLeft =
  forecast?.events.filter((el) => {
    return !el.isFinished
  })

  let theLastGame = null;
  if (isOneLeft?.length === 1) {
    theLastGame = isOneLeft[0]

    // NOTE THAT PARTICIPANTS GETS GLUED ON HERE
    theLastGame.participants = events[theLastGame.eventNumber - 1].participants
  }

  return (
    <UserRowsContainer className='userRows'>
      {(winWidth > 1365 || isModalOpen) && (
        <SwiperBox 
          isModalOpen={isModalOpen} 
          userRows={userRows} 
          forecast={forecast} 
          dividendForecast={dividendForecast}
          theLastGame={theLastGame}
          betRowAmount={betRowAmount}
        />
        // <ToolsPanel style={{ padding: '2rem 3rem 2.5rem', marginBottom: '0.3rem' }}>
        //   <SliderContainer isModalOpen={isModalOpen}>
            

        //     <div style={{display: "flex", justifyContent:"space-between"}}>
        //       <SliderControls className='swiper-prev' />              
        //       {swiperHeader}              
        //       <SliderControls className='swiper-next' />                  
        //     </div>
              
        //     <Swiper
        //       slidesPerView={1}
        //       loop={true}
        //       speed={275}
        //       spaceBetween={30}
        //       simulateTouch={true}
        //       onRealIndexChange={(sw) => {  }}
        //       onSlideChange={(sw) =>{ 
        //         sw.realIndex ? 
        //           setSwiperHeader("Utdelning Svenska folket") : 
        //           setSwiperHeader("Utdelningsprognos System") 
        //       }}
        //       navigation={{
        //         prevEl: '.swiper-prev',
        //         nextEl: '.swiper-next'
        //       }}
        //     >
        //         {false ? (
        //         <>  
 
        //           <SwiperSlide>
        //             <div>
        //             <DividendLastGame
        //               userRows={userRows}
        //               forecast={forecast}
        //               sign={'1'}
        //             />
        //             <SignBG>1</SignBG>
        //             </div>
        //           {/* </SwiperSlide>

        //           <SwiperSlide> */}
        //           <div>
        //             <DividendLastGame
        //               userRows={userRows}
        //               forecast={forecast}
        //               sign={'X'}
        //             />
        //             <SignBG>X</SignBG>
        //           </div>
        //           {/* </SwiperSlide>

        //           <SwiperSlide> */}
        //           <div>
        //             <DividendLastGame
        //               userRows={userRows}
        //               forecast={forecast}
        //               sign={'2'}
        //             />
                    
        //             <SignBG>2</SignBG>
        //             </div>
        //           </SwiperSlide>
        //           <SwiperSlide>
        //             <Dividend distribution={forecast?.distribution} />
        //           </SwiperSlide>
        //         </>
        //       ) : (
        //         <>
        //           <SwiperSlide>
        //             <DividendForecast dividendForecast={dividendForecast} />
        //           </SwiperSlide>
        //           <SwiperSlide>
        //               <Dividend distribution={forecast?.distribution} />
        //           </SwiperSlide>
        //         </>
        //       )}
        //     </Swiper>
        //   </SliderContainer>
        // </ToolsPanel>
      )}
      <UserRowsHeader>
        <span className='item-center'>
          <p>Aktuell rad och dina rader med flest antal rätt</p>

          {!isModalOpen ? (
            <Tooltip
              prefixCls='rToolTip'
              placement='bottom'
              trigger={['hover']}
              animation='anim'
              overlay={
                <>
                  <h3>Bästa rader</h3>

                  <p>
                    Här ser du dina 10 bästa rader. Klicka på "Visa Rader" i nedre
                    högerhörn för att se dina 50 bästa rader tillsammans med ditt
                    Utdelningsprognos system.
                  </p>
                </>
              }
            >
              <ToolsHeading>
                <span className='rToolTip-icon'>i</span>
              </ToolsHeading>
            </Tooltip>
          ) : null}
        </span>

        <button className='userRowsToggle' onClick={handleToggle}>
          {toggleUnplayed ? 'Exkludera ostartade matcher' : 'Inkludera ostartade matcher'}
        </button>
      </UserRowsHeader>

      <UserRowsTool isNewBestRow={isNewBestRow}>
        <InnerContainer className='hasScrollBar'>
          <div className='userRowEventInfo'>
            <div className='userRowEventNumber'>
              {forecast
                ? forecast?.events?.map((userRow, idx) => (
                    <span key={`fc-evtnr-${idx}`}>{userRow.eventNumber}</span>
                  ))
                : events?.map((userRow, idx) => (
                    <span key={`e-evtnr-${idx}`}>{userRow.eventNumber}</span>
                  ))}
            </div>

            <div className='userRowEventOutcome'>
              {forecast
                ? forecast?.events?.map((userRow, idx) => (
                    <span key={`fc-outcome-${idx}`}>{userRow.outcome}</span>
                  ))
                : events?.map((userRow, idx) => <span key={`e-outcome-${idx}`}>X</span>)}
            </div>

            <div className='userRowEventOutcomeLabel'>
              <span>Bästa rad</span>
              <span style={{ fontWeight: font.weight.bold }}>{nrOneBestRow}</span>
            </div>
          </div>
          <UserRowsList isModalOpen={isModalOpen}>
            {userBestRows.map(colList => colList.component)}
          </UserRowsList>
        </InnerContainer>
      </UserRowsTool>
    </UserRowsContainer>
  )
}

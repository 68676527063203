import styled, { css } from 'styled-components'
import { colors } from '../../../styles/constants'

export const Score = styled.div`
  display: flex;
  background: #3F5059;
  padding: 1px 14px 14px 14px;
  grid-gap: 1px;
  ${props => props.hide && css`
    @media screen and (max-width:1361px){
    display: none;
    }
  `}
  @media screen and (max-width:1400px){
    width: min-content;
   }
`

export const ScoreRow = styled.div`
  height: 4rem;
  min-height: 2rem;
  min-width: 2rem;
  line-height: 4rem;
  width: 4rem;
  text-align: center;
  font-size: 14px;
  background: #263238;
  @keyframes ex {
      0% {
        opacity: 0;
      }
      25% {
        background: ${colors.svenskaSpelRed};
      }
      75% {
        opacity: 1;
      }
      100% {
        background: #263238;
      }
    }
    animation: ${({ isNewBestRow }) => (isNewBestRow ? 'ex 2s;' : null)};

    transition: ${({ isNewBestRow }) =>
  isNewBestRow
    ? 'box-shadow 1500ms cubic-bezier(.02,1.02,.81,1)'
    : 'background 150ms ease-in-out'};

    box-shadow: ${({ isNewBestRow }) =>
  isNewBestRow ? '0 0 0 2rem rgba(255, 0, 0, 0)' : '0 0 0  rgba(255, 0, 0, 0.8)'};
  ${props => props.isMain && css`
    width: 8.1rem;
    min-width: 4.1rem;
    line-height: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`

export const Rows = styled.div`
  width: 100%;
  background: #3F5059;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1px;
  padding: 14px 14px 0 14px;
  ${props => props.hide && css`
    @media screen and (max-width:1361px){
    display: none;
    }
  `}
  @media screen and (max-width:1400px){
    width: min-content;
   }
`

export const RowContent = styled.div`
    display: flex;
    grid-gap: 1px;
    width: 4rem;
    flex-direction: column;
    min-width: 2rem;
  `

export const RowKub = styled.div`
  width: 100%;
  height: 4rem;
  min-height: 2rem;
  line-height: 4rem;
  font-size: 14px;
  color: #FFFFFF;
  background: #40C7A8;
  text-align: center;
  ${props => (props.type ==='red') && css `
    background: #FF0000;
  `}
  ${props => (props.type ==='dark') && css `
    background: #263238;
  `}
`
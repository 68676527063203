import React, { useEffect, useState, useContext } from 'react'
import { BombenLivePopupWrapp } from './Popup.elements'
import 'reactjs-popup/dist/index.css'
import { ReactComponent as CloseIcon } from '../../assets/close.svg'

export default function BombenLivePopup({
    open,
    setIsModalOpen,
    children
  }) {
  // This file assumes that the rows that go in can always he handed in.

  return (
    <BombenLivePopupWrapp
      open={open}
      onClose={() => {
        setIsModalOpen(false)
      }}
      position='center'
    >
      <>
        <button className='modalClose' onClick={() => setIsModalOpen(false)}>
          <CloseIcon alt='close' />
        </button>
        {children}

      </>
    </BombenLivePopupWrapp>
  )
}

import { useEffect } from 'react';

const SDKInitializer = ({ children }) => {
    useEffect(() => {
        // Facebook SDK Initialization
        window.fbAsyncInit = function() {
            // eslint-disable-next-line no-undef
            FB.init({
                xfbml: true,
                version: 'v18.0'
            });
        };

        // Load the SDK script asynchronously
        const script = document.createElement('script');
        script.src = 'https://connect.facebook.net/sv_SE/sdk/xfbml.customerchat.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return <>{children}</>;
};

export default SDKInitializer;

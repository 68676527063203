import React from 'react'
import PointReduction from './PointReduction/PointReduction'
import SignReduction from './SignReduction/SignReduction'
import ColorReduction from './ColorReduction/ColorReduction'
import TotaloddsReduction from './TotaloddsReduction/TotaloddsReduction'
import Trends from './Trends/Trends'
import { ToolsContainer } from './ReductionTools.elements'
import LoadGame from './LoadGame/LoadGame'
export default function ReductionTools({
  pointReductionState,
  colorReductionState,
  signReductionState,
  totaloddsData,
  trendsData,
  setOddsRange,
  currentId,
  topptippsify
}) {
  // Basically only passes on the state
  return (
    <ToolsContainer className='ReductionTools'>
      {<LoadGame currentId={currentId} />}
      <Trends 
        trendsData={trendsData} 
        topptippsify={topptippsify}
      />
      <ColorReduction colorReductionState={colorReductionState} />
      <SignReduction signReductionState={signReductionState} />
      <PointReduction pointReductionState={pointReductionState} />
      <TotaloddsReduction
        totaloddsData={totaloddsData.data}
        setOddsRange={setOddsRange}
        extraInfo={totaloddsData.extraInfo}
      />
    </ToolsContainer>
  )
}

import React,{useState} from 'react'
import {UserNameContainer } from './Popup.elements'
import { UserLogButton } from '../Headers/Headers.elements'
import { useAuth0 } from '@auth0/auth0-react'

const UserNamePopup = ({isModalOpen,setIsModalOpen,setUser}) => {
  const { loginWithRedirect, logout,isAuthenticated,getAccessTokenSilently } = useAuth0()
  const [userName, setUserName] = useState('')
  const [error,setError] = useState(null)
  const patchUserName = async () =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    if(!userName ){
      setError({error:{error:'Ditt användarnamn måste innehålla minst 5 tecken'}})
      return
    }

    if(!(userName.length >= 5)){
      setError({error:{error:'Ditt användarnamn måste innehålla minst 5 tecken'}})
      return
    }
    let reg = /^[a-zA-Z0-9_-]+$/;
    if(!reg.test(userName)){
      setError({error:{error:'Användarnamn får endast innehålla "A-Z", "a-z" och "0-9"'}})
      return
    }
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/account`,{
      method:'PATCH',
      headers:headers,
      body:JSON.stringify({username:userName})
    });

    let errorMessage = await response.json()
    if(response.ok){
      setUser(prev => ({...prev,...errorMessage.updated}))
      setIsModalOpen(false)
      return
    }
    if(response.status === 403) return setError({error:{error:'Användarnamnet används redan. Försök med ett annat'}})
    return setError({response,error:errorMessage});

  }
  const handleUserName = (e) =>{
    setUserName(e.target.value)
    setError(null)
  }
  return (
    <div>
      <UserNameContainer
        closeOnDocumentClick={false}
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false)
        }}
        position='center'
        closeOnEscape={false}
      >
        <h1 style={{fontSize:24}}>
          Ange ditt användarnamn
        </h1>
        <label>Användarnamn</label>
        <input
          onChange={handleUserName}
          name='userName'
          value={userName}
          placeholder='Användarnamn'
          type='text'
          maxLength={16}
          className={`${error ? 'error-input' : ''}`}
        />
        {
          error && <p className='error'>
            {error.error.error}
          </p>
        }
        <button className='submit' onClick={patchUserName}> SPARA</button>
      </UserNameContainer>
    </div>
  )
}

export default UserNamePopup
import React, { useState, useContext, useRef, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { ActiveGamesContext } from '../../context/activeGamesContext'
import { LiveResultContext } from '../../context/liveResultContext'

import { NavBar, NavBarMenu } from './Navbar.elements'

import { getActiveDate, sortDates } from '../../utils/helperFunctions'
import { useOnClickOutside } from '../../utils/hooks/useOnClickOutside.js'

import menuIcon from '../../assets/menu.svg'
import closeIcon from '../../assets/close.svg'
import { ReactComponent as ToggleLiveIcon } from '../../assets/close.svg'
import stryktipsetLogo from '../../assets/stryktipset-wordmark.svg'
import europatipsetLogo from '../../assets/europatipset-wordmark.svg'
import topptipsetLogo from '../../assets/topptipset-wordmark.svg'
import powerplayLogo from '../../assets/powerplay-wordmark.svg'
import bombenLogo from  '../../assets/bomben-wordmark.svg'
import {ReactComponent as Arrow} from '../../assets/angle-double-down.svg'
import { AccountContext } from '../../context/accountContext'

export default function Navbar() {
  const { loginWithRedirect, logout, isLoading } = useAuth0()
  const { isAuthenticated,getAccessTokenSilently } = useAuth0()
  const location = useLocation()
  const user = useContext(AccountContext)
  const [sidebar, setSideBar] = useState(false);
  const [isActive,setIsActive] = useState(false);
  const [count,setCount] = useState(false);
  const [toogleMore, setToggleMore] = useState({
    stryktipset:false,
    europatipset: false,
    topptipset: false,
    powerplay:false,
    bomben:false,
  })
  const [liveMatch, setLiveMatch] = useState({
    stryktipset:null,
    europatipset: null,
    topptipset: null,
    powerplay:null,
    bomben:null
  })
  const showSidebar = () => setSideBar(!sidebar)
  const handleSidebar = () => {
    showSidebar()

    if(sidebar) {
      // When hide menu
      setCount(false)
      document.body.classList.remove('menu-active');
    } else {
      // When show menu
      setCount(true)
      document.body.classList.add('menu-active');
    }
  }
  const handleToogleMore = (updateKey) =>{
    setToggleMore(prev =>
      ({
        ...prev,
        [updateKey]:!toogleMore[updateKey]
      }))
  }

  const [showliveresult, setLiveResult] = useState(false)

  const { stryktipset, europatipset, topptipset, powerplay, bomben } =
    useContext(ActiveGamesContext)

  /*const {
    stryktipset: lrStryktipset,
    europatipset: lrEuropatipset,
    topptipset: lrTopptipset,
    powerplay: lrPowerplay
  } = useContext(LiveResultContext)*/

  const ref = useRef(null)

  useOnClickOutside(ref, () => {
    sidebar && handleSidebar();
  })


  useEffect(async()=>{
    if(!isLoading && count){
      let token = isAuthenticated ? await getAccessTokenSilently() : {};
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/liveratting`,{
        method:'GET',
        headers:headers,
      })
      if(response.ok){
        let result = await response.json();
        setLiveMatch(result)
        if (
          result?.europatipset?.draws?.length ||
          result?.powerplay?.draws?.length ||
          result?.stryktipset?.draws?.length ||
          result?.topptipset?.draws?.length ||
          result?.bomben?.draws?.length
        ) {
          setIsActive(true)
        }
      } else {
        console.log('Rejected i dont know why@#@@#')
      }
    }
  },[count])

  return (
    <>
      <NavBar className='navbar headerItem'>
        <div className='menu-bars'>
          <button className='menu-btn' onClick={() => handleSidebar()}>
            <img src={menuIcon} alt='menu icon' />
          </button>
        </div>
      </NavBar>

      <NavBarMenu
        className={sidebar ? 'nav-menu active' : 'nav-menu'}
        showliveresult={showliveresult}
        ref={ref}
      >
        <div className='navbar-close' onClick={() => handleSidebar()}>
          <img src={closeIcon} alt='close icon' />
        </div>

        <div className='nav-menu-items'>
          <ul className='hasScrollBar'>
            <li className='nav-text'>
              <Link
                to={'/'}
                onClick={() => handleSidebar()}
              >{'Startsida'}</Link>
            </li>

            <li className='nav-text auth'>
              {isAuthenticated ? (
                location.pathname === '/profile/' ? (
                  <button
                    className='nav-button'
                    onClick={() => {
                      logout({
                        returnTo: window.location.origin
                      })
                      handleSidebar()
                    }}
                  >
                    Logga ut
                  </button>
                ) : (
                  <Link
                    to={{
                      pathname: `/profile/${user.username}`
                    }}
                    onClick={() => handleSidebar()}
                  >
                    Mina spel
                  </Link>
                )
              ) : (
                <button
                  className='nav-button'
                  onClick={() => {
                    loginWithRedirect()
                  }}
                >
                  Logga in
                </button>
              )}
            </li>

            <li className='nav-text stryktipset'>
              <Link
                to={{
                  pathname: '/stryktipset'
                }}
                onClick={() => handleSidebar()}
              >
                <img src={stryktipsetLogo} alt='Stryktipset' />
                <span>Om spelet</span>
              </Link>
            </li>

            {!stryktipset?.draws.length ? null : (
              <li className='nav-text stryktipset'>
                <Link
                  to={`/reducera/stryktipset/${stryktipset?.draws[0]['drawNumber']}`}
                  onClick={() => handleSidebar()}
                >
                  Spelstopp
                  <span>{getActiveDate(stryktipset, 0)}</span>
                </Link>
              </li>
            )}
            <li className='nav-text europatipset'>
              <Link
                to={{
                  pathname: '/europatipset'
                }}
                onClick={() => handleSidebar()}
              >
                <img src={europatipsetLogo} alt='Europatipset' />
                <span>Om spelet</span>
              </Link>
            </li>
            {!europatipset?.draws.length ? null : (
              <li className='nav-text europatipset'>
                <Link
                  to={`/reducera/europatipset/${europatipset?.draws[0]['drawNumber']}`}
                  onClick={() => handleSidebar()}
                >
                  Spelstopp
                  <span>{getActiveDate(europatipset, 0)}</span>
                </Link>
              </li>
            )}
            <li className='nav-text powerplay'>
              <Link
                style={{pointerEvents:'none'}}
                to={{
                  pathname: '/bomben'
                }}
                onClick={() => handleSidebar()}
              >
                <img src={bombenLogo} alt='PowerPlay' />
                {/*<span>Om spelet</span>*/}
              </Link>
            </li>
            {!bomben?.draws.length
              ? null
              : bomben?.draws?.slice(0,3)?.map((draw, idx) => {
                return (
                  <li className='nav-text powerplay' key={`nav-pp${idx}`}>
                    <Link
                      to={{ pathname: `/reducera/bomben/${draw['drawNumber']}`}}
                      onClick={() => handleSidebar()}
                    >
                      Spelstopp
                      <span>{getActiveDate(bomben, idx)}</span>
                    </Link>
                  </li>
                )
              })}
            {bomben?.draws.length > 3 &&
            <li className='nav-text powerplay show-more'
                onClick={()=>handleToogleMore('bomben')}
            >
              <Arrow className={`${toogleMore.bomben ? 'arrow up' : 'arrow'}`}/>
              <div>{toogleMore.bomben ? 'Dölj' : 'Visa fler'}</div>
              <Arrow className={`${toogleMore.bomben ? 'arrow up' : 'arrow'}`}/>
            </li>
            }
            {toogleMore.bomben == true ?
              bomben?.draws?.sort(sortDates)?.slice(3).map((draw, idx) => {
                //console.log('draw', draw)
                return (
                  <li className='nav-text powerplay' key={`nav-tt${idx}`}>
                    <Link
                      to={`/reducera/bomben/${draw['drawNumber']}`}
                      onClick={() => handleSidebar()}
                    >
                      Spelstopp
                      <span>{getActiveDate(bomben, idx + 3)}</span>
                    </Link>
                  </li>
                )
              })
              : null }
            <li className='nav-text topptipset'>
              <Link
                to={{
                  pathname: '/topptipset'
                }}
                onClick={() => handleSidebar()}
              >
                <img src={topptipsetLogo} alt='Topptipset' />
                <span>Om spelet</span>
              </Link>
            </li>
            {!topptipset?.draws.length
              ? null
              : topptipset?.draws?.sort(sortDates)?.slice(0,3).map((draw, idx) => {
                  //console.log('draw', draw)
                  return (
                    <li className='nav-text topptipset' key={`nav-tt${idx}`}>
                      <Link
                        to={`/reducera/topptipset/${draw['drawNumber']}`}
                        onClick={() => handleSidebar()}
                      >
                        Spelstopp
                        <span>{getActiveDate(topptipset, idx)}</span>
                      </Link>
                    </li>
                  )
                })}
            {topptipset?.draws.length > 3 &&
              <li className='nav-text topptipset show-more'
                  onClick={()=>handleToogleMore('topptipset')}
              >
                <Arrow className={`${toogleMore.topptipset ? 'arrow up' : 'arrow'}`}/>
                <div>{toogleMore.topptipset ? 'Dölj' : 'Visa fler'}</div>
                <Arrow className={`${toogleMore.topptipset ? 'arrow up' : 'arrow'}`}/>
              </li>
            }
            {toogleMore.topptipset == true ?
              topptipset?.draws?.sort(sortDates)?.slice(3).map((draw, idx) => {
                //console.log('draw', draw)
                return (
                  <li className='nav-text topptipset' key={`nav-tt${idx}`}>
                    <Link
                      to={`/reducera/topptipset/${draw['drawNumber']}`}
                      onClick={() => handleSidebar()}
                    >
                      Spelstopp
                      <span>{getActiveDate(topptipset, idx + 3)}</span>
                    </Link>
                  </li>
                )
              })
              : null }
            <li className='nav-text powerplay'>
              <Link
                to={{
                  pathname: '/powerplay'
                }}
                onClick={() => handleSidebar()}
              >
                <img src={powerplayLogo} alt='PowerPlay' />
                <span>Om spelet</span>
              </Link>
            </li>
            {!powerplay?.draws.length
              ? null
              : powerplay?.draws?.slice(0,3)?.map((draw, idx) => {
                  return (
                    <li className='nav-text powerplay' key={`nav-pp${idx}`}>
                      <Link
                        to={{ pathname: `/reducera/powerplay/${draw['drawNumber']}`}}
                        onClick={() => handleSidebar()}
                      >
                        Spelstopp
                        <span>{getActiveDate(powerplay, idx)}</span>
                      </Link>
                    </li>
                  )
                })}
            {powerplay?.draws.length > 3 &&
            <li className='nav-text powerplay show-more'
                onClick={()=>handleToogleMore('powerplay')}
            >
              <Arrow className={`${toogleMore.powerplay ? 'arrow up' : 'arrow'}`}/>
              <div>{toogleMore.powerplay ? 'Dölj' : 'Visa fler'}</div>
              <Arrow className={`${toogleMore.powerplay ? 'arrow up' : 'arrow'}`}/>
            </li>
            }
            {toogleMore.powerplay == true ?
              powerplay?.draws?.sort(sortDates)?.slice(3).map((draw, idx) => {
                //console.log('draw', draw)
                return (
                  <li className='nav-text powerplay' key={`nav-tt${idx}`}>
                    <Link
                      to={`/reducera/powerplay/${draw['drawNumber']}`}
                      onClick={() => handleSidebar()}
                    >
                      Spelstopp
                      <span>{getActiveDate(powerplay, idx + 3)}</span>
                    </Link>
                  </li>
                )
              })
              : null }
            <li className='nav-text liverattning'>
              <button
                onClick={() => setLiveResult(!showliveresult)}
                disabled={isActive ? null : 'disabled'}
              >
                {isActive ? 'Liverättning' : 'Inga live-spel'}
                {isActive ? <ToggleLiveIcon /> : null}
              </button>

              <ul>
                {!liveMatch.stryktipset?.draws?.length ? null : (
                  <li className='nav-text liverattning'>
                    <Link
                      to={{
                        pathname: `/liveresults/stryktipset/${liveMatch.stryktipset?.draws[0]['drawNumber']}`
                      }}
                      onClick={() => handleSidebar()}
                    >
                      <span>LIVE {getActiveDate(liveMatch.stryktipset, 0)}</span>
                      <div className='gameType stryktipset'>
                        <img src={stryktipsetLogo} alt='Stryktipset' />
                      </div>
                    </Link>
                  </li>
                )}

                {!liveMatch.europatipset?.draws?.length ? null : (
                  <li className='nav-text liverattning'>
                    <Link
                      to={{
                        pathname: `/liveresults/europatipset/${liveMatch.europatipset?.draws[0]['drawNumber']}`
                      }}
                      onClick={() => handleSidebar()}
                    >
                      <span>LIVE {getActiveDate(liveMatch.europatipset, 0)}</span>
                      <div className='gameType europatipset'>
                        <img src={europatipsetLogo} alt='Europatipset' />
                      </div>
                    </Link>
                  </li>
                )}
                {!liveMatch.bomben?.draws?.length
                  ? null
                  : liveMatch.bomben?.draws?.map((draw, idx) => {
                    return (
                      <li className='nav-text liverattning' key={`nav-lr-pp${idx}`}>
                        <Link
                          to={{
                            pathname: `/liveresults/bomben/${draw['drawNumber']}`
                          }}
                          onClick={() => handleSidebar()}
                        >
                          <span>LIVE {getActiveDate(liveMatch.bomben, idx)}</span>
                          <div className='gameType powerplay'>
                            <img src={bombenLogo} alt='BombenPlay' />
                          </div>
                        </Link>
                      </li>
                    )
                  })}
                {!liveMatch.topptipset?.draws?.length
                  ? null
                  : liveMatch.topptipset?.draws?.map((draw, idx) => {
                      return (
                        <li className='nav-text liverattning' key={`nav-lr-tt${idx}`}>
                          <Link
                            to={{
                              pathname: `/liveresults/topptipset/${draw['drawNumber']}`
                            }}
                            onClick={() => handleSidebar()}
                          >
                            <span>LIVE {getActiveDate(liveMatch.topptipset, idx)}</span>
                            <div className='gameType topptipset'>
                              <img src={topptipsetLogo} alt='Topptipset' />
                            </div>
                          </Link>
                        </li>
                      )
                    })}

                {!liveMatch.powerplay?.draws?.length
                  ? null
                  : liveMatch.powerplay?.draws?.map((draw, idx) => {
                      return (
                        <li className='nav-text liverattning' key={`nav-lr-pp${idx}`}>
                          <Link
                            to={{
                              pathname: `/liveresults/powerplay/${draw['drawNumber']}`
                            }}
                            onClick={() => handleSidebar()}
                          >
                            <span>LIVE {getActiveDate(liveMatch.powerplay, idx)}</span>
                            <div className='gameType powerplay'>
                              <img src={powerplayLogo} alt='PowerPlay' />
                            </div>
                          </Link>
                        </li>
                      )
                    })}
              </ul>
            </li>

            <li className='nav-text'>
              <Link
                to={'/hur-funkar-det'}
                onClick={() => handleSidebar()}
              >
                {'Hur funkar det?'}
              </Link>
            </li>
            <li className='nav-text'>
              <Link
                to={'/om-oss'}
                onClick={() => handleSidebar()}
              >
                {'Om Reducering.se'}
              </Link>
            </li>
            <li className='nav-text'>
              <Link
                to={'/tavling'}
                onClick={() => handleSidebar()}
              >
                {'Tävling'}
              </Link>
            </li>
            <li className='nav-text'>
              <Link
                to={'/blogs'}
                onClick={() => handleSidebar()}
              >
                {'Blogs'}
              </Link>
            </li>
            {user.admin && <li className='nav-text'>
              <Link
                to={'/admin'}
                onClick={() => handleSidebar()}
              >
                {'Admin page'}
              </Link>
            </li>}
            {user.author && <li className='nav-text'>
              <Link
                to={'/author'}
                onClick={() => handleSidebar()}
              >
                {'Author page'}
              </Link>
            </li>}
          </ul>
        </div>
      </NavBarMenu>
    </>
  )
}

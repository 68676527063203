import React,{useContext, useState, useRef} from 'react'
import useWindowDimensions from '../../utils/hooks/useWindowDimensions'
import Logo from './Logo'
import userLogo from '../../assets/simple-profile.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation, useHistory } from 'react-router-dom'
import {AccountContext} from '../../context/accountContext'
import Navbar from '../Navbar/Navbar'
import {
  HeaderStart,
  LogoContainer,
  HeaderStartContent,
  UserButton,
  UserLogButton,
  UserLoggButton,
  Profile
} from './Headers.elements'
import LoadGame from '../Game/ReductionTools/LoadGame/LoadGame'
import { useOnClickOutside } from '../../utils/hooks/useOnClickOutside'
import { PhotoContext } from '../../context/photoContext'


export default function HomeHeader() {
  const { loginWithRedirect, logout } = useAuth0()
  const { isAuthenticated } = useAuth0()
  const location = useLocation()
  const history = useHistory()
  const nav = useRef(null);
  useOnClickOutside(nav, () => {
    if(open) setOpen(false)
  })
  const userPhoto = useContext(PhotoContext)
  const user = useContext(AccountContext)
  const { winWidth } = useWindowDimensions()
  const [open, setOpen] = useState(false)
  return (
    <HeaderStart>
      <LogoContainer className='headerItem'>
        <Logo />
      </LogoContainer>
      <HeaderStartContent className='headerItem'>
        <p className='show-for-medium'></p>

        {/*<div className='homeHeaderContent'>
          <LoadGame />
        </div>*/}

        {winWidth > 960 && (

        isAuthenticated ? (
          location.pathname.startsWith("/profile") ? (
            <UserLogButton
              color
              onClick={() => {
                logout({
                  returnTo: window.location.origin
                })
              }}
            >
              Logga ut
            </UserLogButton>
          ) : (
            <>
              <Profile ref={nav}>
                <div className='author-name'>{user?.username}</div>
                <div onClick={()=>setOpen(!open)} className='author-img' >
                  <img src={ userPhoto || userLogo } alt='userIcon'/>
                </div>
                {open && <ul className='author-nav'>
                  <li> {user?.username}</li>
                  <li onClick={()=>history.push(`/profile/${user.username}`)} className='' >MINA SPEL</li>
                  <li onClick={()=>history.push(`/profile/${user?.username}`,{isOpenProfile:true})} className='' >INSTÄLLNINGAR</li>
                  <li
                    onClick={() => {
                      logout({
                        returnTo: window.location.origin
                      })
                      }}
                    className='' >LOGGA UT</li>
                </ul>}
              </Profile>
              {/*<UserButton
              to={{
                pathname: `/profile/${user.username ? user.username : ''}`
              }}
            >
              Mina spel
            </UserButton>*/}
            </>
          )
        ) : (
          <>
            <UserLogButton
              dis
              onClick={() => {
                loginWithRedirect()
              }}
            >
              Logga in
            </UserLogButton>
          </>
        )
      )}

      </HeaderStartContent>

      {winWidth < 959 && (
        isAuthenticated ? (
          location.pathname.startsWith("/profile")  ? (
            <UserLogButton
              color
              dis
              onClick={() => {
                logout({
                  returnTo: window.location.origin
                })
              }}
            >
              Logga ut
            </UserLogButton>
          ) : (
            <>
              <Profile ref={nav}>
                {winWidth > 550 && <div className='author-name'>{user?.username}</div>}
                <div onClick={()=>setOpen(!open)} className='author-img' >
                  <img src={userPhoto || userLogo} alt='userIcon'/>
                </div>
                {open && <ul className='author-nav'>
                  <li> {user?.username}</li>
                  <li onClick={()=>history.push(`/profile/${user.username}`)} className='' >MINA SPEL</li>
                  <li onClick={()=>history.push(`/profile/${user?.username}`,{isOpenProfile:true})} className='' >INSTÄLLNINGAR</li>
                  <li
                    onClick={() => {
                      logout({
                        returnTo: window.location.origin
                      })
                    }}
                    className='' >LOGGA UT</li>
                </ul>}
              </Profile>
              {/*<UserButton
              to={{
                pathname: `/profile/${user.username ? user.username : ''}`
              }}
            >
              Mina spel
            </UserButton>*/}
            </>
          )
        ) : (
          <>
            <UserLogButton
              dis
              onClick={() => {
                loginWithRedirect()
              }}
            >
              Logga in
            </UserLogButton>
          </>
        )
      )}

      <Navbar />
    </HeaderStart>
  )
}

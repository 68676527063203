import React,{useEffect} from 'react';
import {TableContainer} from './Blog.elements';

const BigTableBlog = ({blog,handleChange, adminPrepeare}) => {

  const getHighlightedText = (text, highlight='##(.*?)@@',)=> {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gm'));
    let remove = false
    let partsFilter = []
    parts.forEach(text => {
      if(text.startsWith('##') && text.endsWith('@@')){
        partsFilter.push(text)
        remove = true
      } else {
        if(remove){
          remove = false
          return
        } else {
          partsFilter.push(text)
        }
      }
      })
    return <span> { partsFilter.map((part, i) =>
      <span key={i} style={(part.startsWith('##') && part.endsWith('@@')) ? { fontWeight: 'bold'} : {} }>
            {(part.startsWith('##') && part.endsWith('@@')) ? `${ part.replace('##',' ').replace('@@',' ')}` :  part }
        </span>)
    } </span>;
  }
  return (

    <TableContainer>
      {
        blog?.events &&  blog?.events?.map((event,index) => (
          <div key={event?.id} className='element'>
            {/*console.log(adminPrepeare?.events[index].forecast[0])*/}
            <h2 className='header'>{event?.event?.eventNumber}. {event?.event?.description}
              {adminPrepeare?.events && <p>
                <span> {adminPrepeare.events[index].forecast[0] ? '1' : null}</span>
                <span>{adminPrepeare.events[index].forecast[1] ? 'X' : null}</span>
                <span>{adminPrepeare.events[index].forecast[2] ? '2' : null}</span>
              </p>}
            </h2>
            {blog.result && <p className='result-game'>Resultat :
              { ` ${blog.result[index].outcomeScore}`}
            </p>}
            <div className='select'>
              <p>Din rad:</p>
              <div className='rating'>
                <div onClick={(e)=>handleChange(e,event.id,0)}
                  className={`${event.forecast[0] ? 'select-active' : ''}
                  ${blog.result ? (blog?.result[index]?.outcome == '1' ? 'win-precent' : '') : ''}`}>
                  <span>1</span>
                  <span className='precent'>{event.event.distribution.home}%</span>
                </div>
                <div onClick={(e)=>handleChange(e,event.id,1)}
                  className={`${event.forecast[1] ? 'select-active' : ''}
                  ${blog.result ? (blog?.result[index]?.outcome == 'X' ? 'win-precent' : '') : ''}`}>
                  <span>X</span>
                  <span className='precent'>{event.event.distribution.draw}%</span>
                </div>
                <div onClick={(e)=>handleChange(e,event.id,2)}
                  className={`${event.forecast[2] ? 'select-active' : ''}
                  ${blog.result ? (blog?.result[index]?.outcome == '2' ? 'win-precent' : '') : ''}`}>
                  <span>2</span>
                  <span className='precent'>{event.event.distribution.away}%</span>
                </div>
              </div>
            </div>
            <p style={{wordBreak:'break-word'}}>
              {getHighlightedText(event.comment)}
            </p>
          </div>
        ))
      }
    </TableContainer>
  )
}

export default BigTableBlog
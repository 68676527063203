import React, { useState, useEffect } from 'react'
import Tooltip from 'rc-tooltip'
import _ from 'lodash'

import { ToolsHeading } from '../../../../styles/globalStyle'
import { ActivateBtn, LabelMinMax, Input } from '../ReductionTools.elements'
import { PointReductionRow, PointReductionContainer } from './PointReduction.elements'

export default function PointReduction({ pointReductionState }) {


  const pmax = pointReductionState.pointReduction.max
  const pmaxL = pointReductionState.pointReduction.maxLimit

  useEffect(() => {
    if (
      pointReductionState.pointReduction.min === null && 
      pointReductionState.pointReduction.max === null
    ) {
      setMaxDisplayValue("")
      setMinDisplayValue("")
    }
  }, [pointReductionState])

  const [maxDisplayValue, setMaxDisplayValue] = useState((pmax === pmaxL ||  pmax === null) ? "" : pmax);
  const [minDisplayValue, setMinDisplayValue] = useState(pointReductionState.pointReduction.min || "")

  return (
    <PointReductionRow>
      <PointReductionContainer>
        <ActivateBtn
          onClick={
            !pointReductionState.pointReduction.isSelected
              ? pointReductionState.pointReduceFunctions.startPointMode
              : pointReductionState.pointReduceFunctions.endPointMode
          }
          isActive={pointReductionState.pointReduction.isSelected}
        >
          <span>P</span>
        </ActivateBtn>

        <Tooltip
          prefixCls='rToolTip'
          placement='bottom'
          trigger={['hover']}
          animation='anim'
          overlay={
            <>
              <h3>Poängreducering</h3>

              <p>
                Klicka på <strong>“P”</strong> för att aktivera poängreducering och
                fördela poäng (1-99) på valda tecken i “ramen”. Ställ sedan in villkor
                genom att välja ett Min/Max-värde och klicka på <strong>REDUCERA</strong> för att
                reducera bort de rader som EJ uppfyller villkoret - d.v.s. de rader där
                totala poängsumman på valda tecken antingen ligger under Min- över
                Max-värdet.
              </p>

              <p>
                Poängreducering fungerar utmärkt att kombinera tillsammans med färg- och
                1X2-reducering.
              </p>
            </>
          }
        >
          <ToolsHeading>
            Poängreducering <span className='rToolTip-icon'>i</span>
          </ToolsHeading>
        </Tooltip>

        <LabelMinMax>Min</LabelMinMax>
        <Input
          type='text'
          // value={pointReductionState.pointReduction.min || ''}
          value={minDisplayValue}
          onChange={ev => {

            if (!ev.target.value) {
              setMinDisplayValue("")
              //  Max starts off as 0 and has no effect if 0.
              pointReductionState.pointReduceFunctions.setMin(0)
              return;
            }

            const val = parseInt(ev.target.value)
            pointReductionState.pointReduceFunctions.setMin(
              !Number.isNaN(val) ? val : pointReductionState.pointReduction.minLimit
            )

            setMinDisplayValue(
              !Number.isNaN(val) ? val : pointReductionState.pointReduction.minLimit
            )
          }}
          onInput={ev => {
            if (typeof ev.target.prevValue === 'undefined') {
              ev.target.prevValue = ''
            }
            const test = value => /^\d*$/.test(value)
            if (!test(ev.target.value)) {
              ev.target.value = ev.target.prevValue
            } else {
              ev.target.prevValue = ev.target.value
            }
          }}
        />

        <LabelMinMax>Max</LabelMinMax>
        <Input
          type='text'
          // value={pointReductionState.pointReduction.max || ''}
          value={maxDisplayValue}
          onChange={ev => {


            if (!ev.target.value) {
              setMaxDisplayValue("")
              //  Max starts off as 0 and has no effect if 0.
              pointReductionState.pointReduceFunctions.setMax(0)
              return;
            }

            const val = parseInt(ev.target.value)

            // console.log("setting Max at", !Number.isNaN(val) ? val : pointReductionState.pointReduction.maxLimit)
            pointReductionState.pointReduceFunctions.setMax(
              !Number.isNaN(val) ? val : pointReductionState.pointReduction.maxLimit
            )

            setMaxDisplayValue(
              !Number.isNaN(val) ? val : pointReductionState.pointReduction.maxLimit
            )
          }}
          onInput={ev => {
            if (typeof ev.target.prevValue === 'undefined') {
              ev.target.prevValue = ''
            }
            const test = value => /^\d*$/.test(value)
            if (!test(ev.target.value)) {
              ev.target.value = ev.target.prevValue
            } else {
              ev.target.prevValue = ev.target.value
            }
          }}
        />
      </PointReductionContainer>
    </PointReductionRow>
  )
}

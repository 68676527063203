import React, { useEffect, useState, useRef } from 'react'
import '../index.css'
import {
  ColorGrid,
  SelectButton,
  Circle,
  PointInput,
  PointPercentage
} from './SelectBet.elements'
import { colors } from '../../../../styles/constants'
import { useOnClickOutside } from '../../../../utils/hooks/useOnClickOutside.js'

export default function SelectBet({
  betObj: { viewState },
  couponFunctions,
  mode,
  isReduced
}) {
  const { isSelected, points } = viewState
  const [bg, setBg] = useState('')
  const ref = useRef(null)

  const handleMode = () => {
    switch (mode.mode) {
      case 'default':
        couponFunctions.toggle()
        break
      case 'pointMode':
        if (!viewState.isEditable) {
          couponFunctions.toggle()
        }
        break
      case 'colorMode':
        if (viewState.isSelected) {
          couponFunctions.setColor(mode.params)
          //console.log('mode.params', mode)
        } else {
          couponFunctions.toggle()
        }
        break
    }
  }

  const handlePointMode = e => {
    if (mode.mode === 'pointMode') {
      e.stopPropagation()
      // viewState.isSelected = true  // changing a property of a react prop...
      couponFunctions.openEdit()
    }
  }

  const currentColorArr = viewState.colors.map(val => {
    switch (val) {
      case 'red':
        return colors.reduceRed
      case 'blue':
        return colors.reduceBlue
      case 'green':
        return colors.reduceGreen
      case 'yellow':
        return colors.reduceYellow
      default:
        return '#465a65'
    }
  })
  const ccLength = viewState.colors.length

  useEffect(() => {
    let width = 100
    if (ccLength === 1) {
      //setBgWidth(100 / ccLength)
      setBg(`background: ${currentColorArr[0]};`)
    } else if (ccLength === 2) {
      setBg(`background: linear-gradient(
        90deg, ${currentColorArr[0]} 0% ${width / ccLength}%,
        ${currentColorArr[1]} ${width / ccLength}% 100%
      );`)
    } else if (ccLength === 3) {
      setBg(`background: linear-gradient(
        90deg, ${currentColorArr[0]} 0% ${width / ccLength}%,
        ${currentColorArr[1]} ${width / ccLength}% ${(width / ccLength) * 2}%, ${
        currentColorArr[2]
      } ${(width / ccLength) * 2}% 100%
      );`)
    } else if (ccLength === 4) {
      setBg(`background: linear-gradient(
        90deg, ${currentColorArr[0]} 0% ${width / ccLength}%,
        ${currentColorArr[1]} ${width / ccLength}% ${(width / ccLength) * 2}%, ${
        currentColorArr[2]
      } ${(width / ccLength) * 2}% ${(width / ccLength) * 3}%, ${currentColorArr[3]} ${
        (width / ccLength) * 3
      }% 100%
      );`)
    } else {
      setBg(`background: ${colors.reducedTurquoise};`)
    }
  }, [ccLength, currentColorArr])

  useOnClickOutside(ref, () => {
    couponFunctions.closeEdit()
  })

  return (
    <ColorGrid
      isSelected={isSelected}
      bg={isSelected ? bg : `background: ${colors.darkGrey};`}
    >
      <SelectButton onClick={() => handleMode()}>
        {/* <PointPercentage isSelected={isSelected}>
          {`${
            isReduced && isSelected ? `${viewState.signDistribution.toFixed(2)}` : '-'
          }`}
        </PointPercentage> */}

        <Circle onClick={e => handlePointMode(e)} isSelected={isSelected} points={points}>
          {!viewState.isEditable ? (
            <>{!viewState.points ? 'P' : viewState.isSelected ? viewState.points : 'P'}</>
          ) : (
            <PointInput
              autoFocus
              ref={ref}
              type='text'
              min={0}
              max={99}
              maxLength={2}
              placeholder={viewState.points}
              onChange={ev => couponFunctions.setValue(parseInt(ev.target.value)) || 0}
              onInput={ev => {
                if (typeof ev.target.prevValue === 'undefined') {
                  ev.target.prevValue = 0
                }
                const test = value =>
                  /^((?!(0)))\d*$/.test(value) && (value === '' || parseInt(value) <= 99)
                if (!test(ev.target.value)) {
                  ev.target.value = ev.target.prevValue
                } else {
                  ev.target.prevValue = ev.target.value
                }
                // setInputFilter(
                //   ev.target,
                //   value => /^\d*$/.test(value) && (value === '' || parseInt(value) <= 99)
                // )
              }}
              onKeyPress={ev => {
                if (ev.key === 'Enter') {
                  couponFunctions.closeEdit()
                }
              }}
            />
          )}
        </Circle>
      </SelectButton>
    </ColorGrid>
  )
}

import React, { useState, useEffect } from 'react'
import '../index.css'
import { gameBetOdds, TrendText } from './GameDetail.elements'

export default function GameDetailOdds({
  bet: { betChoice, odds, distribution, trendData }
}) {
  const [trendColor, setTrendColor] = useState(true)
  const [trend, setTrend] = useState('')

  useEffect(() => {
    // console.log("trendData is null")
    if (trendData === "n/a") {
    console.log("trendData is null", trendData)

      setTrendColor(false)
      setTrend('n/a')
      return;
    }

    if (trendData * 100 > parseFloat(distribution)) {
      setTrendColor(true)
    } else if (trendData * 100 < parseFloat(distribution)) {
      setTrendColor(false)
    } else {
      setTrendColor(prevState => prevState)
    }
    //console.log('trendColor', trendColor)
    if (typeof trendData === 'undefined') {
      setTrend('- ')
      setTrendColor(true)
    } else {
      setTrend(`${(trendData * 100).toFixed(0)}% `)
    }
  }, [trendData])

  return (
    <div className='gameOddsItem'>
      <div>
        <p className='gameBetChoice'>{betChoice}</p>

        <p className='gameBetOdds'>{odds}</p>
      </div>

      <div>
        <p>{distribution}%</p>
        <TrendText trendColor={trendColor}>{trend}</TrendText>
      </div>
    </div>
  )
}

import React, { useRef, useState,useEffect } from 'react'
import {CustomDropdown} from '../../styles/globalStyle'
import { useOnClickOutside } from '../../utils/hooks/useOnClickOutside';
import arrow from '../../assets/arrow.png'
const CustomDropdow = ({list,setCreateItem,error,setError,setSearch}) => {
  const ref = useRef(null)
  const lastState = useRef(null)
  useOnClickOutside(ref, () => {
    setIsOpen(false)
  })
  useEffect(()=>{
    setElement(list)
    lastState.current = list
  },[list])
  const [element, setElement] = useState(list)
  const [value, setValue] = useState("")
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (e,item) =>{
    setSelected(item)
    debugger
    setValue(item.email || item.user_id)
    setError(prev =>({...prev,email:null}))
    setCreateItem(prev => ({...prev,email:item.email,id:item._id,name: item?.name || ''}))
  }
  const handleSeach = (e) =>{
    let value = e.target.value
    setValue(value)
    setSearch(value)
  }

  return (
    <CustomDropdown
      onClick={()=>setIsOpen(prev =>!prev)}
      ref={ref}
    >
      <input
        className={`${isOpen ? "open" : ""} ${error.email ? 'error' : ""}`}
        maxLength={255}
        value={value}
        type='text'
        autoComplete='off'
        name='email'
        placeholder='Välj mailadress'
        onChange={handleSeach}
      ></input>
      { <span className='input-arrow'><img style={{transform:isOpen ? 'rotate(270deg)' : 'rotate(90deg)'}} src={arrow}/></span>}
      {error.email && <p className='error-mes'>En giltig mailadress måste anges</p>}
      {isOpen && <div className="active" >
        {(element?.length > 0) ? element?.map( (li) =>
          <div onClick={(e)=>handleSelect(e,li)}>
            {li.email || li.user_id}{li.username ? ` / ${li.username}` : '' }</div>
        ) : <p className='no-data'>No Data</p>}
      </div>}
    </CustomDropdown>
  )
}

export default CustomDropdow
import styled from 'styled-components'
import { breakPoints, spacers } from '../../styles/constants'
import { OneThird, TwoThirds } from '../../styles/globalStyle'

export const GameListArea = styled(OneThird)`
  @media screen and (min-width: ${breakPoints.xlarge}) {
    min-width: 470px;
  }
`

export const SelectedCouponArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: ${breakPoints.medium}) {
    flex-direction: row;

    > * + * {
      margin: 0 0 0 ${spacers.xsmall};
    }
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {

    > * + * {
      margin: ${spacers.small} 0 0 0;
    }
  }
`

export const GameWidgetArea = styled(TwoThirds)`
  flex-direction: row;
  display: flex;

  > * + * {
    margin: 0 0 0 ${spacers.small};
  }

  @media screen and (max-width: ${breakPoints.xlargeMax}) {
    display: flex;
    flex-direction: column;

    > * + * {
      margin: ${spacers.small} 0 0 0;
    }
  }

  @media screen and (max-width: ${breakPoints.xlargeMax}) and (min-width: ${breakPoints.medium}) {
    > * + * {
      margin: ${spacers.xxsmall} 0 0 0;
    }
  }

  .ReductionTools,
  .CouponSettings {
    width: calc(100% * 1/2 - ${spacers.xsmall} * 1/2);

    @media screen and (max-width: ${breakPoints.xlargeMax}) {
      width: 100%;
    }

    @media screen and (min-width: ${breakPoints.xlarge}) {
      position: sticky;
      top: 74px;
      align-self: flex-start;
    }
  }
`

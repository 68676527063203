import styled, { css } from 'styled-components'
import { colors, spacers, borderRadius } from '../../../../styles/constants'
import { ToolsPanel } from '../../../../styles/globalStyle'
import { LabelMinMax, Input, Select } from '../ReductionTools.elements'

export const ColorReductionContainer = styled(ToolsPanel)`
  > div {
    flex-direction: row;
    padding: ${spacers.large};
  }
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const LabelColorReductionMinMax = styled(LabelMinMax)`
  display: flex;
  flex-direction: column;
  margin: 0;
  align-items: center;
  align-self: flex-end;

  > * {
    height: 2rem;
    line-height: 2rem;

    + * {
      margin-top: ${spacers.large};
    }
  }
`

export const ColorBox = styled.button`
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  border: none;
  border-radius: ${borderRadius.small};
  margin-bottom: ${spacers.xxlarge};
  transform: rotate(0deg);
  overflow: hidden;
  cursor: pointer;
`

export const ReduceRed = styled(ColorBox)`
  background-color: ${colors.reduceRed};

  transition: all 0.2s ease-out;
  ${({ isActive }) =>
    'red' === isActive ? (`
      transform: rotate(45deg);
      box-shadow:
        inset 0 0 0 2px ${colors.vividCyan},
        0 0 8px -4px black;
    `) : `transform: rotate(0deg)`};
    ${props=> props.small && css`
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 2.5rem;
    margin-right: 3rem;
    `}
    ${props=> (props.small && props.isActive) && css`
    transform: rotate(45deg);
    box-shadow:
        inset 0 0 0 2px ${colors.vividCyan},
        0 0 8px -4px black;
    `}
`

export const ReduceYellow = styled(ColorBox)`
  background-color: ${colors.reduceYellow};

  transition: all 0.2s ease-out;
  ${({ isActive }) =>
    'yellow' === isActive ? (`
      transform: rotate(45deg);
      box-shadow:
        inset 0 0 0 2px ${colors.vividCyan},
        0 0 8px -4px black;
    `) : `transform: rotate(0deg)`};
  ${props=> props.small && css`
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 2.5rem;
    margin-right: 3rem;
`}
  ${props=> (props.small && props.isActive) && css`
    transform: rotate(45deg);
    box-shadow:
        inset 0 0 0 2px ${colors.vividCyan},
        0 0 8px -4px black;
    `}
`

export const ReduceBlue = styled(ColorBox)`
  background-color: ${colors.reduceBlue};

  transition: all 0.2s ease-out;
  ${({ isActive }) =>
    'blue' === isActive ? (`
      transform: rotate(45deg);
      box-shadow:
        inset 0 0 0 2px ${colors.vividCyan},
        0 0 8px -4px black;
    `) : `transform: rotate(0deg)`};
    ${props=> props.small && css`
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 2.5rem;
    margin-right: 3rem;
`}
    ${props=> (props.small && props.isActive) && css`
    transform: rotate(45deg);
    box-shadow:
        inset 0 0 0 2px ${colors.vividCyan},
        0 0 8px -4px black;
    `}
`

export const ReduceGreen = styled(ColorBox)`
  background-color: ${colors.reduceGreen};

  transition: all 0.2s ease-out;
  ${({ isActive }) =>
    'green' === isActive ? (`
      transform: rotate(45deg);
      box-shadow:
        inset 0 0 0 2px ${colors.vividCyan},
        0 0 8px -4px black;
    `) : `transform: rotate(0deg)`};
    ${props=> props.small && css`
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 2.5rem;
`}
    ${props=> (props.small && props.isActive) && css`
    transform: rotate(45deg);
    box-shadow:
        inset 0 0 0 2px ${colors.vividCyan},
        0 0 8px -4px black;
    `}
`

export const InputColorReduction = styled(Input)`
  width: 5.5rem;
  max-width: 5rem;
  padding-left: 0;
  margin-bottom: ${spacers.large};

  &:last-child {
    margin-bottom: 0;
  }

  + input {
    margin-left: 0;
  }
`

export const SelectColorReduction = styled(Select)`
  width: 5.5rem;
  max-width: 5rem;
  padding-left: 0;
  margin-bottom: ${spacers.large};

  &:last-child {
    margin-bottom: 0;
  }

  + select {
    margin-left: 0;
  }
`

import React from 'react'
import {
  ResultItemContainer,
  ResultItemsHeader,
  ProbabilityToggle
} from './ResultsList.elements'

import ResultItem from './ResultItem/ResultItem'

export default function ResultsList({
  eventData: { forecast, events },
  loadedCouponData,
  infoLive
}) {
  return (
    <ResultItemContainer>
      <ResultItemsHeader>
        <div>
          <ProbabilityToggle disabled>{'Visa odds som %'}</ProbabilityToggle>
        </div>

        <div>1</div>
        <div>X</div>
        <div>2</div>
      </ResultItemsHeader>
      {forecast
        ? forecast?.events?.map((fcEvent, idx) => (
            <ResultItem
              key={`fc-${idx}-${fcEvent.sportEventId}`}
              fcEvent={fcEvent}
              event={events[idx]}
              index={idx}
              loadedCouponData={loadedCouponData?.[idx]}
              infoLive={infoLive?.[idx+1]}
            />
          ))
        : events?.map((fcEvent, idx) => (
            <ResultItem
              key={`e-${idx}-${fcEvent.sportEventId}`}
              fcEvent={fcEvent}
              event={events[idx]}
              index={idx}
              loadedCouponData={loadedCouponData?.[idx]}
              infoLive={infoLive?.[idx+1]}
            />
          ))}
    </ResultItemContainer>
  )
}

import React from 'react'
import Tooltip from 'rc-tooltip'
import { ReductionInner } from '../../Game/ReductionTools/ReductionTools.elements'
import {
  Inner, ReduceBlue, ReduceGreen, ReduceRed,
  ReduceYellow,
} from '../../Game/ReductionTools/ColorReduction/ColorReduction.elements'
import { ToolsSmallHeading } from '../../../styles/globalStyle'
import { BombenColorsContainer } from './BombenColors.styles'
import { Option, Select } from '../../Game/CouponSettings/RowStake/RowStake.elements'

const BombenColors = ({color,changeColor,colorMinMax,isActive,setColorMinMax,removeStatus,area}) => {

  const renderOption = (color,type) =>{
    //if(!isActive) return
    let options = [];
    let leng = colorMinMax[color][`${color}Leng`];
    for (let i = 0;leng+1 > i;i++){
      if(type === 'min' && (i > colorMinMax[color][`${color}Max`]) ){
        continue
      }else if(type === 'max' && (colorMinMax[color][`${color}Min`] > i)){
        continue
      }
      options.push(i)
    }
    return options
  }

  const updateState = (e,color,type) =>{
    let value = + e.target.value;
    setColorMinMax(prev=>({
      ...prev,
      [color] : {
        ...colorMinMax[color],
        isSelect:true,
        [`${color}${type}`] : value
      }
    }))
    removeStatus(null)
  }

  return (
    <BombenColorsContainer area={area} isActive={!isActive}>
      <div className='color'>
        <Tooltip
          prefixCls='rToolTip'
          placement='bottom'
          trigger={['hover']}
          animation='anim'
          overlay={
            <>
              <h3>Färgreducering</h3>

              <p>
                Klicka på den färg du vill jobba med - det spelar ingen roll vilken du
                väljer - och fördela färgen på valda tecken i “ramen”. Ställ sedan in
                villkor genom att välja ett Min/Max-värde och klicka på <strong>REDUCERA</strong> för att
                reducera bort de rader som EJ uppfyller villkoret - d.v.s. de rader där
                totala färgsumman på valda tecken ligger under Min- och över Max-värdet.
              </p>

              <p>
                Det går att använda flera färger på samma tecken och färgreducering
                fungerar utmärkt att kombinera tillsammans med poäng- och 1X2-reducering.
              </p>
            </>
          }
        >
          <ToolsSmallHeading>
            Färgreducering <span className='rToolTip-icon'>i</span>
          </ToolsSmallHeading>
        </Tooltip>
      </div>
      <div>
        <ReductionInner noColumn>
          <Inner>
            <ReduceYellow
              small
              onClick={(e) => {
               changeColor(e,'yellow')
              }}
              isActive={color?.yellow}
            />
            <div className='color-inputs'>
              <div className='color-inputs-wrap'>
                  <Select
                    onChange={(e)=>updateState(e,'yellow','Min')}
                    isActive={isActive}
                    disabled={!isActive}
                    value = {colorMinMax?.yellow?.yellowMin}
                  >
                    {renderOption('yellow')?.length > 0 ?
                      renderOption('yellow','min')?.map((color,index) =>{
                        return <Option value={color}>{color}</Option>
                      })
                      : <Option value='0'>0</Option>}
                  </Select>
                <span className='color-size'>Min</span>
              </div>
              <div className='color-inputs-wrap'>
                <Select
                  onChange={(e)=>updateState(e,'yellow','Max')}
                  isActive={isActive}
                  disabled={!isActive}
                  value = {colorMinMax?.yellow?.yellowMax}
                >
                  {renderOption('yellow')?.length > 0 ?
                    renderOption('yellow','max')?.map((color,index) =>{
                      return <Option value={color}>{color}</Option>
                    })
                    : <Option value='0'>0</Option>}
                </Select>
                <span className='color-size'>Max</span>
              </div>
            </div>
          </Inner>

          <Inner>
            <ReduceBlue
              small
              onClick={(e) => {
                changeColor(e,'blue')
              }}
              isActive={color?.blue}
            />
            <div className='color-inputs'>
              <div className='color-inputs-wrap'>
                <Select
                  onChange={(e)=>updateState(e,'blue','Min')}
                  isActive={isActive}
                  disabled={!isActive}
                  value = {colorMinMax?.blue?.blueMin}
                >
                  {renderOption('blue')?.length > 0 ?
                    renderOption('blue','min')?.map((color,index) =>{
                      return <Option value={color}>{color}</Option>
                    })
                    :  <Option value='0'>0</Option>}
                </Select>
                <span className='color-size'>Min</span>
              </div>
              <div className='color-inputs-wrap'>
                <Select
                  onChange={(e)=>updateState(e,'blue','Max')}
                  isActive={isActive}
                  disabled={!isActive}
                  value = {colorMinMax?.blue?.blueMax}
                >
                  {renderOption('blue')?.length > 0 ?
                    renderOption('blue','max')?.map((color,index) =>{
                      return <Option value={color}>{color}</Option>
                    })
                    : <Option value='0'>0</Option>}
                </Select>
                <span className='color-size'>Max</span>
              </div>
            </div>
          </Inner>

          <Inner>
            <ReduceRed
              small
              onClick={(e) => {
                changeColor(e,'red')
              }}
              isActive={color?.red}
            />
            <div className='color-inputs'>
              <div className='color-inputs-wrap'>
                <Select
                  onChange={(e)=>updateState(e,'red','Min')}
                  isActive={isActive}
                  disabled={!isActive}
                  value = {colorMinMax?.red?.redMin}
                >
                  {renderOption('red')?.length > 0 ?
                    renderOption('red','min')?.map((color,index) =>{
                      return <Option value={color}>{color}</Option>
                    })
                    : <Option value='0'>0</Option>}
                </Select>
                <span className='color-size'>Min</span>
              </div>
              <div className='color-inputs-wrap'>
                <Select
                  onChange={(e)=>updateState(e,'red','Max')}
                  isActive={isActive}
                  disabled={!isActive}
                  value = {colorMinMax?.red?.redMax}
                >
                  {renderOption('red')?.length > 0 ?
                    renderOption('red','max')?.map((color,index) =>{
                      return <Option value={color}>{color}</Option>
                    })
                    : <Option value='0'>0</Option>}
                </Select>
                <span className='color-size'>Max</span>
              </div>
            </div>
          </Inner>


          <Inner>
            <ReduceGreen
              small
              onClick={(e) => {
                changeColor(e,'green')
              }}
              isActive={color?.green}
            />
            <div className='color-inputs'>
              <div className='color-inputs-wrap'>
                <Select
                  onChange={(e)=>updateState(e,'green','Min')}
                  isActive={isActive}
                  disabled={!isActive}
                  value = {colorMinMax?.green?.greenMin}
                >
                  {renderOption('green')?.length > 0 ?
                    renderOption('green','min')?.map((color,index) =>{
                      return <Option value={color}>{color}</Option>
                    })
                    : <Option value='0'>0</Option>}
                </Select>
              </div>
              <div className='color-inputs-wrap'>
                <Select
                  onChange={(e)=>updateState(e,'green','Max')}
                  isActive={isActive}
                  disabled={!isActive}
                  value = {colorMinMax?.green?.greenMax}
                >
                  {renderOption('green')?.length > 0 ?
                    renderOption('green','max')?.map((color,index) =>{
                      return <Option value={color}>{color}</Option>
                    })
                    : <Option value='0'>0</Option>}
                </Select>
              </div>
            </div>
          </Inner>
        </ReductionInner>
      </div>
    </BombenColorsContainer>
  )
}

export default BombenColors
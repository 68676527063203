import styled, { css } from 'styled-components'

export const BombenGameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #3F5059;
  border-radius: 5px;
  overflow: hidden;
  .head{
    background: #465761;
    box-shadow: 0px 4px 15px #273238;
    border-radius: 10px;
    width: 90%;
    min-width: 27.9rem;
    margin: 0 auto 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--right{
      margin-right: -1rem;
    }
    &--left{
      margin-left: -1rem;
    }
    &-vs{
      color: #A9A9A9;
      font-family: 'Fugaz One', cursive;
      font-size: 2.2rem;
      line-height: 3.2rem;
      margin: 0 0.5rem;
    }
    &-team{
      display: flex;
      align-items: center;
      grid-gap: 1rem;
      &-count{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        background-color: #263238;
        font-family: 'Fugaz One', cursive;
        font-size: 1.8rem;
        line-height: 2.6rem;
        color: #A9A9A9;
        flex-shrink: 0;
      }
      &-name{
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1.4rem;
        text-transform: uppercase;
        color: #FFFFFF;
        word-break: break-word;
      }
    }
  }
  .body{
   padding: 0 1.4rem;
    &-footer{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
    &-size{
      display: flex;
      align-items: center;
      grid-gap: 0.4rem;
      margin-top: 0.7rem;
     &__select{
      display: flex;
      flex-direction: column;
      grid-gap: 0.2rem;
     }
     &__title{
      color: #8DA2AB;
     }
    }
  }
  .footer{
    background: #465761;
    box-shadow: 0px 4px 15px #273238;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 0.9rem 1rem 1.6rem;
    &-check{
      display: flex;
      justify-content: center;
      align-items: center;
      grid-gap: 2.5rem;
    }
    &-info{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
    }
    &-hero{
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 1rem;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
  }
  .distribution{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 2rem;
    border-bottom: 1px solid #37474F;
    padding-bottom: 1.5rem;
    &-team{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      grid-gap: 0.5rem;
    }
  }
  ${props=>props.live && css`
    .body-footer{
      flex-direction: row;
      justify-content: space-between;
      margin: 0;
      align-items: center;
      padding: 11px 0;
      .body-size{
        margin-top: 0;
        grid-gap: 2rem;
      }
      .body-size__select{
        flex-direction: row;
        align-items: center;
        grid-gap: 0.5rem;
      }
      input{
      cursor: not-allowed;
      }
    }
    .distribution{
      flex-direction: column;
      grid-gap: 1rem;
    }
    .main-head{
      padding:0.8rem 1.2rem 0 1.2rem ;
      margin-bottom: 1.2rem;
      display: flex;
      align-items: center;
    }
    .count{
      width: 43px;
      height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 1px;
      flex-shrink: 0;
      background: #263238;
      border-radius: 0.4rem;
      margin-left: 2rem;
    }
    .status{
      margin-left: 3rem;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      grid-gap: 0.4rem;
      &__type{
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: red;
        font-size: 1.4rem;
        &--win{
          background: #00C853;
        }
      }
    }
    .head{
      max-width: min-content;
      min-width: 290px;
      margin: 0;
      margin-left: 1rem;
    }
    .body{
      padding: 0 1.2rem 0 1.2rem;
   }
   .distribution-team{
     width: 100%;
     justify-content: flex-start;
     grid-gap: 0.3rem;
   }
   .distribution-team__name{
    align-self: center;
    width: 36px;
    font-size: 1.2rem;
    text-align: center;
    overflow: hidden;
    font-weight: 700;
   }
   @media screen and (max-width: 969px){
    align-items: center;
    .main-head{
      width: 100%;
      justify-content: space-between;
      grid-gap: 10px;
    }
    .footer{
      width: 100%;
    }
    .body{
      width: 100%;
    }
   }
    @media screen and (max-width: 480px){
    .distribution-team{
     width: 100%;
     align-items: center;
     flex-wrap: nowrap;
   }
   .main-head{
    flex-wrap: wrap;
   }
   .head{
    width: 100%;
    margin: 0 auto;
   }
   .status{
    margin-left: 0;
   }
   .distribution-team__name{
    align-self: center;
    width: 18px;
    font-size: 1.2rem;
    text-align: center;
    overflow: hidden;
    font-weight: 700;
    }
   }
  `}
  
`
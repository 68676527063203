import React from 'react'
import {AboutWrapper} from './About.elements'
import cheked from '../../assets/big_Cheked.png'
const About = () => {
  return (
    <AboutWrapper>
      <h2>NYTT HÖGKLASSIGT REDUCERINGSVERKTYG</h2>
      <p>“Av poolspelare, för poolspelare” skulle man kunna säga. Reducering.se är
        ett helt nytt reduceringsverktyg för Svenska Spel Sport och
        Casinos poolspelsprodukter, framtaget av ett team med lång erfarenhet
        av poolspel och av att spela reducerat.
      </p>
      <p>Verktyget är gratis att använda och innehåller många nya funktioner,
        som kommer vara till stor hjälp i jakten på 8 respektive 13 rätt!
        Därtill erbjuds även en liverättning - också helt gratis - som ger en
        trevlig och underhållande upplevelse efter att spelet lämnats in. Välkommen!
      </p>
      <div className='item'>
        <div className='item-circle'>
          <div className='circle'>
            <img src={cheked}/>
          </div>
          <p className='upp'>Andelsspel</p>
        </div>
        <div className='item-circle'>
          <div className='circle'>
            <img src={cheked }/>
          </div>
          <p className='upp'>Liverättning</p>
        </div>
        <div className='item-circle'>
          <div className='circle'>
            <img src={cheked }/>
          </div>
          <p className='upp'>Trender</p>
        </div>
        <div className='item-circle'>
          <div className='circle'>
            <img src={cheked }/>
          </div>
          <p className='upp'>Speltips</p>
        </div>
      </div>
    </AboutWrapper>
  )
}

export default About
import React from 'react'
import {HowContainer} from './HowIsWork.elements'
import VideoHeroMp4 from '../../../assets/video/Hero-Fotboll.mp4'
import VideoHeroWebm from '../../../assets/video/Hero-Fotboll.webm'
import VideoHeroImage from '../../../assets/video/Hero-fallback-fotboll.jpg'
import Video from '../../Video/Video'
import { Link } from 'react-router-dom'
const HowIsWork = () => {
  return (
    <HowContainer>
      <h2>HUR FUNGERAR DET?</h2>
      <div className='container'>
        <div className='info'>
          <p>Kom igång med <a href="https://www.reducering.se/">Reducering.se</a>  och spela smartare! Är du nyfiken, men känner
            dig osäker på hur det fungerar? Lugn, här följer en grundlig steg-för-steg-guide.
            Klicka på "Läs mer" och ta del av råden, från steg 1 hela vägen till nr. 12,
            för att få en så bra start som möjligt. Lycka till!
          </p>
          <p> <a href="https://www.reducering.se/">Reducering.se</a> är framtaget av ett team erfarna poolspelare, som mer än gärna delar med sig av sitt kunnande.
            Håll utkik efter en instruktionsvideo, som kommer att spelas in och laddas upp på den här sektionen.</p>
          <Link className='link' to={'hur-funkar-det'}>LÄS MER</Link>
        </div>
        <div className='video'>
          {/*<Video
            muted
            autoPlay // Autoplay in Chrome requires video to be muted.
            loop
            playsInline
            mp4={VideoHeroMp4}
            webm={VideoHeroWebm}
            img={VideoHeroImage}
            alt={`Sorry, your browser doesn't support embedded videos.`}
          />*/
            <iframe
              width="411"
              height="231"
              src="https://www.youtube.com/embed/mQoYv1aQKKA?controls=0&loop=1&playlist=mQoYv1aQKKA"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen>
            </iframe>
          }
        </div>
      </div>
    </HowContainer>
  )
}

export default HowIsWork
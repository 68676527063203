import React, { useEffect, useState, useContext } from 'react'

import { ReductionPopup } from './Popup.elements'
import 'reactjs-popup/dist/index.css'

import { ReactComponent as CloseIcon } from '../../assets/close.svg'
import { ReactComponent as SvenskaSpelLogo } from '../../assets/svenskaspel-logomark.svg'
import { ReactComponent as SaveHandin } from '../../assets/save-coupon.svg'
import { ReactComponent as SendHandin } from '../../assets/send.svg'


import { startAffiliate, removeAffiliate } from '../../utils/helperFunctions'

import { useAuth0 } from '@auth0/auth0-react'

const BombenSvenska =({
  open,
  setIsModalOpen,
  isReduced,
  cloneCount,
  betRowAmount,
  currentId,
  saveCoupon,
  copied,
  drawNumber,
  errorModal,
  isClose
  })=> {
  // This file assumes that the rows that go in can always he handed in.

  const { isLoading, isAuthenticated, user } = useAuth0()
  const [urls, setUrls] = useState([])

  const getUrl = data => {
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/bomben/${drawNumber}/handin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
  }

  const onOpen = () => {
    removeAffiliate()
    if (!isReduced) {
      return
    }
    if(cloneCount === 0){
      const data = {
        betRowAmount: betRowAmount,
        system: 'single',
        items: isReduced
      }
      let promiseData = getUrl(data)
        .then(res =>{
          if(!res.ok) throw new Error('Failed')
          return res.json()
        }).then(data=>{
          let getUrl = data?.response?.url
          setUrls([getUrl])
          startAffiliate()
        }).catch(err=>{
          errorModal.modal(true)
          errorModal.variant('error')
          errorModal.message('Du kan inte lämna in - Omgången har redan påbörjats')
        })
    }else{
      const data = {
        betRowAmount: betRowAmount,
        system: 'single',
        items: isReduced
      }
      let promiseData = [];
      for(let i = 0; cloneCount >= i; i++){
        promiseData.push(getUrl(data))
      }
      Promise.all(promiseData)
        .then(response =>{
        return Promise.all(response?.map(resp =>{
          if(!resp.ok) throw new Error('Failed')
          return resp.json()
        }))
      }).then(data =>{
        let urls = data.map(url =>url.response.url)
        setUrls(urls)
        startAffiliate()
      }).catch(err=>{
        console.dir(err)
        errorModal.modal(true)
        errorModal.variant('error')
        errorModal.message('Du kan inte lämna in - Omgången har redan påbörjats')
      })
    }

    saveCoupon()
  }

  const [sendToEmail, setSendToEmail] = useState('')

  return (
    <ReductionPopup
      open={open}
      onOpen={onOpen}
      onClose={() => {
        setIsModalOpen(false)
        setUrls([])
        removeAffiliate()
      }}
      position='center'
      copied={copied}
      closeOnDocumentClick={!isClose}
    >
      <>
        <button className='modalClose' onClick={() => setIsModalOpen(false)}>
          <CloseIcon alt='close' />
        </button>

        {!isReduced ? (
          <div> Reducera ditt system! </div>
        ) : (
          <>

            {isAuthenticated && user ?
              <p>
                Inloggad som: {user.email || user.name}
              </p> :
              <p style={{fontSize: '1.5em'}}>
                EJ INLOGGAD
              </p>
            }
            {cloneCount ? (
              <p className='modalAlert'> Din kupong är klonad {cloneCount} gång(er). </p>
            ) : null}

            <label>Kopiera kupong-ID </label>
            <div className='handinSave' style={{ display: 'flex' }}>
              <button className='icon' onClick={saveCoupon}>
                <SaveHandin alt='Save' />
              </button>
              <div>
                {currentId}
                <span className='copied' aria-hidden='true'>
                  Kupongen är kopierad till "​urklipp"
                </span>
              </div>
            </div>

            <label>Dela kupong-ID via e-post </label>
            <div className='handinEmail' style={{ display: 'flex' }}>
              <a
                className='icon sendMailBtn'
                href={`mailto:${sendToEmail}?subject=Kupong ID&body=Kupong ID: %0A ${currentId}`}
              >
                <SendHandin alt='Send e-mail' />
              </a>
              <div>
                <input
                  id='mailinput'
                  type='email'
                  placeholder='Skriv din e-postadress här'
                  onChange={ev => setSendToEmail(ev.target.value)}
                />
              </div>
            </div>

            {urls?.length > 1
              ? urls?.map((url, i) => {
                return (
                  <p key={i}>
                    <a className='handinCta' href={url} target='_blank' rel='noopener noreferrer'>
                        <span>
                          Lämna in hos <br /> Svenska Spel Sport & Casino
                        </span>
                      <span>
                          Kupong <br /> del {i + 1}
                        </span>

                      <SvenskaSpelLogo alt='Svenska Spel' />
                    </a>
                  </p>
                )
              })
              : urls?.map((url, i) => {
                return (
                  <p key={i}>
                    <a className='handinCta' href={url} target='_blank' rel='noopener noreferrer'>
                        <span>
                          Lämna in hos <br /> Svenska Spel Sport & Casino
                        </span>
                      <SvenskaSpelLogo alt='Svenska Spel' />
                    </a>
                  </p>
                )
              })}

            <p>
              Genom att klicka på <strong>LÄMNA IN HOS SVENSKA SPEL SPORT & CASINO</strong> skickas du vidare till Svenska Spel Sport & Casino för inlämning.
            </p>

            <p>
              * Går inte ditt spel att lämna in?
              <br />
              <strong>
                <a href='https://spela.svenskaspel.se/villkor-och-integritet/spelregler' target='_blank' rel='noopener noreferrer'>
                  Läs mer om Svenska Spel Sport & Casinos regler här.
                </a>
              </strong>
            </p>
          </>
        )}
      </>
    </ReductionPopup>
  )
}
export  { BombenSvenska }

import React from 'react'
import Tooltip from 'rc-tooltip'
import { ToolsSmallHeading } from '../../../styles/globalStyle'
import CustomChekbox from '../../CustomCheckbox/CustomChekbox'
import { BombenGameContainer } from './BombenGame.styles'
import {ReactComponent as Icon} from '../../../assets/question.svg'
import InputNumber from '../InputNumber/InputNumber'
import BombenDistribution from '../BombenDistribution/BombenDistribution'

const BombenGame = ({
  event,
  eventState,
  changeStatus,
  eventId,
  checkBoxChange,
  changeMinMaxForGame,
  forecast,
  isCoast,
  changeCoast,
  trendData
  }) => {
  let getName = (type) =>{
    let participants = event?.participants
    if(type ==='home') {
      let returnTeam = participants?.find(partTeam => partTeam?.type === 'home')
      return returnTeam?.name || ''
    }
    else {
      let returnTeam = participants?.find(partTeam => partTeam?.type === 'away')
      return returnTeam?.name || ''
    }
  }
  return (
    <BombenGameContainer>
      <div className='head'>
        <div className='head-team'>
          <span className='head-team-count head--left'>H{event?.eventNumber}</span>
          <span className='head-team-name'>{ getName('home') }</span>
        </div>
        <div className='head-vs'>
          VS
        </div>
        <div className='head-team'>
          <span className='head-team-name'>{ getName('away') }</span>
          <span className='head-team-count head--right'>B{event?.eventNumber}</span>
        </div>
      </div>
      <div className='body'>
        <div className='distribution'>
          <div className='distribution-team'>
            {event?.distribution?.map((type,index)=>(
              <BombenDistribution
                eventId={eventId}
                changeStatus={changeStatus}
                key={index}
                type={type}
                trendData={trendData?.length > 0 ? trendData[index]?.home : false}
                kind='home'
                eventArrId = {index}
                currentType={eventState?.firstTeam[index]}
                winner={forecast?.isFinished && forecast?.outcome}
                isCoast={isCoast}
                changeCoast={changeCoast}
              />
              ))}
          </div>
          <div className='distribution-team'>
            {event?.distribution?.map((type,index)=>(
              <BombenDistribution
                eventId={eventId}
                changeStatus={changeStatus}
                trendData={trendData?.length > 0 ? trendData[index]?.away : false}
                type={type}
                kind='away'
                key={index}
                eventArrId = {index}
                currentType={eventState?.secondTeam[index]}
                winner={forecast?.isFinished && forecast?.outcome}
                isCoast={isCoast}
                changeCoast={changeCoast}
              />))}
          </div>
        </div>
        <div className='body-footer'>
          <Tooltip
            prefixCls='rToolTip'
            placement='bottom'
            trigger={['hover']}
            animation='anim'
            overlay={
              <>
                <h3>Totalt antal mål i matchen</h3>
                <p>
                  Reducera på totalt antal mål i matchen genom att välja ett Min- och ett Max-värde. Klicka
                  sedan på <strong>REDUCERA</strong>-knappen för att reducera bort de rader som EJ uppfyller de valda
                  villkoren.
                </p>
              </>
            }
          >
            <ToolsSmallHeading>
              Totalt antal mål <span className='rToolTip-icon'>i</span>
            </ToolsSmallHeading>
          </Tooltip>
          <div className='body-size'>
            <div className='body-size__select'>
              <span className='body-size__title'>
                Min
              </span>
              <InputNumber
                option={[eventId,'min']}
                onChange={changeMinMaxForGame}
                value={eventState?.min}
              />
            </div>
            <div className='body-size__select'>
              <span className='body-size__title'>
                Max
              </span>
              <InputNumber
                option={[eventId,'max']}
                onChange={changeMinMaxForGame}
                value={eventState?.max}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='footer'>
        <div className='footer-info'>
          <Tooltip
            prefixCls='rToolTip'
            placement='bottom'
            trigger={['hover']}
            animation='anim'
            overlay={
              <>
                <h3>Förslag på ny rubrik: Reducera bort 1X2</h3>

                <p>
                  Reducera på 1X2-resultatet i matchen genom att klicka i rutorna för <em>Hemma, Oavgjort och
                  Borta</em>.
                </p>
                <p>
                  Klicka sedan på <strong>REDUCERA</strong>-knappen för att reducera bort det valda utfallet eller
                  utfallen, d.v.s. om exempelvis <em>Hemma klickas</em> i, så reduceras samtliga rader som innehåller
                  hemmaseger bort.
                </p>

              </>
            }
          >
            <p className='footer-hero'>
              Reducera bort
              <Icon/>
            </p>
          </Tooltip>
        </div>
        <div className='footer-check'>
          <CustomChekbox
            styles='greens'
            checked={eventState?.firstDoesntWin}
            disabled={eventState?.noDraw && eventState?.secondDoesntWin}
            title='Hemma'
            onChange={(e)=>checkBoxChange(e,eventId,'firstDoesntWin')}
          />
          <CustomChekbox
            styles='greens'
            checked={eventState?.noDraw}
            title='Oavgiort'
            disabled={eventState?.firstDoesntWin && eventState?.secondDoesntWin}
            onChange={(e)=>checkBoxChange(e,eventId,'noDraw')}
          />
          <CustomChekbox
            styles='greens'
            checked={eventState?.secondDoesntWin}
            disabled={eventState?.noDraw && eventState?.firstDoesntWin}
            onChange={(e)=>checkBoxChange(e,eventId,'secondDoesntWin')}
            title='Borta'

          />
        </div>
      </div>
    </BombenGameContainer>
  )
}

export default BombenGame

import React from 'react'
//import {LoaderElement} from './Loader.element'
import { ReactComponent as LoadingLogo } from '../../assets/reducering-logomark.svg'

const Loader = ({wintable}) => {
  return (
    <div className={`loader loader--position ${wintable ? 'loader--change' : ''}`}>
      <div className='loader-animation' />
        <LoadingLogo />
      <p>
        Loading ...
      </p>
    </div>
  )
}

export default Loader
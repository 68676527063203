import React, { useContext, useEffect, useState } from 'react'
import { gameTurnoverWithSpace } from '../../../../utils/helperFunctions'
import {
  DividendLastGameContainer,
  DividendSignWrapper,
  DividendSignBox,
  DividendAmountWrapper,
  DividendLastGameHeading,
  DividendLastGameData,
  SignBG
} from './DividendLastGame.elements'

import { GameContext } from '../../../../context/gameContext'

// Helper, but does not take this nonsense about X being valid into account,
// Compare to the one in LiveResults.js
const calculateUserRows = (forecast, userRows, sign, notIsFinishedIndex, gameType) => {
  let out = Array.from({ length: forecast.events.length + 1 })
    .fill(0)
    .map(ev => {
      return {
        winners: 0,
        dividend: 0
      }
    })

  userRows.forEach(row => {
    let numOfCorrects = row.reduce((acc, s, i) => {
      return i === notIsFinishedIndex ? acc + (s === sign) : acc + (forecast.events[i].outcome === s)
    }, 0)
    out[numOfCorrects].winners++
  })

  out.forEach((game, i) => {
    let numcorr = forecast?.distribution.filter(val => {
      return val.name == `${i} rätt` && val.sign === sign
    })

    let key = gameType === 'powerplay' ? 'odds' : 'amount'

    if (numcorr?.length) {
      game.dividend = parseInt(numcorr[0][key])
    } else {
      game.dividend = 0
    }
  })
  //console.log('for ', sign, out)
  return out
}

export default function DividendLastGame({ userRows, forecast, sign, betRowAmount }) {
  const gameinfo = useContext(GameContext)

  const [currentView, setCurrentView] = useState([])
  const [teamName, setTeamName] = useState(null)
  // Not used right now but excellent for testing

  useEffect(() => {
    // set forecast to fake below, for testing, 3 entries

    let NoOfGamesFinished = forecast?.events.filter(ev => {
      // <-- Here
      return !ev.isFinished
    }, 0)

    if (
      !forecast || // Comment out this line for testing
      !userRows ||
      // !(gameinfo.gameType === 'europatipset' || gameinfo.gameType === 'stryktipset') ||
      NoOfGamesFinished.length !== 1
    ) {
      return
    }

    let out = calculateUserRows(forecast, userRows, sign, parseInt(NoOfGamesFinished[0].eventNumber) - 1, gameinfo.gameType) // <-- Here
    setCurrentView(out)
    // setTeamName(NoOfGamesFinished[0].description.replace('-', ' - '))
    setTeamName(`${NoOfGamesFinished[0].participants[0].name} - ${NoOfGamesFinished[0].participants[1].name}`)

  }, [userRows, forecast, sign, betRowAmount])

  let cutoff = gameinfo.gameType === 'europatipset' || gameinfo.gameType === 'stryktipset' ? 4 : 1

  return (
    <>
      <DividendLastGameContainer>
        {/* <DividendLastGameHeading>
          Utdelning <span className='light'>{teamName}</span>
        </DividendLastGameHeading> */}
        <DividendSignWrapper>
          <DividendSignBox>
            <SignBG>{sign}</SignBG>
            <p className='total'>
              {currentView.length ? `${currentView.reduce((acc, el) => acc + el.winners * el.dividend, 0)} kr` : null}
            </p>
          </DividendSignBox>
          <DividendAmountWrapper>
            {currentView
              .map((el, i) => (
                <DividendLastGameData key={`dividendLastGameID-${i}`} hasWinners={el.winners}>
                  <p>{i} rätt</p>
                  <p>{el.winners} st</p>
                  <p>{gameTurnoverWithSpace((el.dividend * el.winners * betRowAmount || 0).toFixed(2))} kr</p>
                </DividendLastGameData>
              ))
              .slice(currentView.length - cutoff, currentView.length)
              .reverse()}
          </DividendAmountWrapper>
        </DividendSignWrapper>
      </DividendLastGameContainer>
    </>
  )
}

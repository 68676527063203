import React, { useState, useContext, useEffect } from "react";
import Tooltip from "rc-tooltip";
import { ToolsHeading, ToolsSmallHeading } from "../../../styles/globalStyle";
import {
  CouponContainer,
  CouponGrid,
  SignSummery,
  DividedForecast,
  Safeguard,
  RowInput,
  CloneCoupon,
  SaveCoupon,
  DeleteCoupon,
  HandInCoupon,
  GoToLive,
  IsLocked,
} from "./CouponSettings.elements";
import HandInCouponBtn from "./HandInCoupon/HandInCouponBtn";
import WholeHalfGuard from "./WholeHalfGuard/WholeHalfGuard";
import CloneCouponBtn from "./CloneCoupon/CloneCouponBtn";
import SaveCouponBtn from "./SaveCoupon/SaveCouponBtn";
import DividendForecast from "./DividendForecast/DividendForecast";
import SignSummary from "./SignSummary/SignSummary";
import ResetCoupon from "./ResetCoupon/ResetCoupon";
import RowStake from "./RowStake/RowStake";
import HandinModal from "./../../Popup/Popup";
import { Link, useHistory } from "react-router-dom";
import { GameContext } from "../../../context/gameContext";
import { ContestContext } from "../../../context/contestContext";

import svsBlueSymbol from "../../../assets/svs-blue-symbol.svg";
import CustomChekbox from "../../CustomCheckbox/CustomChekbox";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import { ReactComponent as Cup } from "../../../assets/cup.svg";
import reduceringSm from "../../../assets/reducering-sm.svg";
import reduceringEm from "../../../assets/reducering-em-big.svg";

export default function CouponInfo({
  handinRows,
  forecastData,
  viewState,
  isReduced,
  events,
  resetCoupon,
  id,
  bet,
  saveCoupon,
  setStatus,
  setErrorPopup,
  setErrorPopupText,
  currentNickname,
  topptippsify,
  totalRader,
  reducedRowsCount,
  actionType,
  isLocked,
  setIsLocked,
  closeTime,
  checkBox,
  setCheckBox,
  SpTsms,
  status,
  isMine,
  isErrorCoupon,
}) {
  const [cloneCount, setCloneCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { initialStatus, initialViewState } = useContext(GameContext);
  const { isAuthenticated } = useAuth0();
  const history = useHistory();
  const { isSM } = useContext(ContestContext);
  const [contestDraws, setContestDraws] = useState([]);

  const fetchContestDraws = async () => {
    if (contestDraws.length) {
      return;
    }

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/wins/contest-draws`
      );
      const data = await res.json();
      setContestDraws(data);
    } catch (err) {
      return [];
    }
  };

  useEffect(() => {
    if (!isSM && contestDraws.length) {
      return;
    }
    fetchContestDraws();
  }, []);

  useEffect(() => {
    // Changed to enable contest
    if (!isSM) return;
    if (reducedRowsCount > 500) {
      setIsLocked(false);
      setCheckBox(false);
      if (checkBox) {
        setErrorPopup(true);
        setErrorPopupText(
          <>
            <h3>
              Maxgränsen för att delta i Reducering-EM är 500 rader. Välj bort
              något tecken eller reducera ditt system, så du kommer ned till max
              500 rader. Du kan lämna in kupongen utan att delta!
            </h3>
          </>
        );
      }
    }
  }, [
    checkBox,
    reducedRowsCount,
    setCheckBox,
    setErrorPopup,
    setErrorPopupText,
    setIsLocked,
  ]);
  useEffect(() => {
    //If is main we change state of chekbox
    if (
      initialViewState !== null &&
      initialViewState.isLocked === true &&
      isSM
    ) {
      // Changed to enable contest
      setCheckBox(true);
    }
  }, [initialViewState, isLocked, setCheckBox]); // Changed to enable contest
  const isDisabled = () => {
    if (reducedRowsCount > 500) {
      return true;
    } else if (moment(new Date()).unix() > moment(closeTime).unix()) {
      return true;
    } else if (isLocked && !isMine) {
      return true;
    } else if (!isMine && initialViewState?.isLocked) {
      return true;
    } else if (isLocked && !initialViewState?.isLocked) {
      return true;
    } else if (!isAuthenticated) {
      return true;
    } else {
      return false;
    }
  };
  const isTitle = () => {
    let isActive = isDisabled();
    if (isActive && reducedRowsCount > 500) {
      return "Systemet är för stort - du kan ej delta ";
    } else if (isActive && isLocked) {
      return "Du deltar redan i omgången ";
    } else if (!isAuthenticated) {
      return "Du behöver vara inloggad för att delta ";
    } else {
      return "Du kan ej delta med denna kupong ";
    }
  };
  const getContent = () => {
    if (reducedRowsCount > 500) {
      return (
        <>
          {/*<h3>More than 10k</h3>*/}
          <p>
            Du kan EJ delta med denna kupong - antingen har spelstoppet
            passerats, eller så har du redan deltagit i tävlingen.
          </p>
        </>
      );
    } else if (moment(new Date()).unix() > moment(closeTime).unix()) {
      return (
        <>
          {/*<h3>Coupon date start</h3>*/}
          <p>Omgången har redan startat.</p>
        </>
      );
    } else if (isLocked && !isMine) {
      return (
        <>
          {/*<h3>You have a submited coupon with checkbox</h3>*/}
          <p>
            {" "}
            Du kan EJ delta med denna kupong - antingen har spelstoppet
            passerats, eller så har du redan deltagit i tävlingen.
          </p>
        </>
      );
    } else if (isLocked && !initialViewState?.isLocked) {
      return (
        <>
          {/*<h3>You have a submited coupon with checkbox</h3>*/}
          <p>
            Du kan EJ delta med denna kupong - antingen har spelstoppet
            passerats, eller så har du redan deltagit i tävlingen.
          </p>
        </>
      );
    } else if (!isMine && initialViewState?.isLocked) {
      return (
        <>
          {/*<h3>You have a submited coupon with checkbox</h3>*/}
          <p>
            Du kan EJ delta med denna kupong - antingen har spelstoppet
            passerats, eller så har du redan deltagit i tävlingen.
          </p>
        </>
      );
    } else if (!isAuthenticated) {
      return (
        <>
          {/*<h3>Please log in</h3>*/}
          <p>Du behöver vara inloggad för att delta.</p>
        </>
      );
    } else {
      return <></>;
    }
  };
  const { gameType, drawNumber } = useContext(GameContext);

  return (
    <>
      <HandinModal
        topptippsify={topptippsify}
        betRowAmount={bet.betRowAmount}
        open={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handinRows={handinRows}
        isReduced={isReduced}
        cloneCount={cloneCount}
        currentId={id.currentId}
        saveCoupon={saveCoupon}
        currentNickname={currentNickname}
        totalRader={totalRader}
        setStatus={setStatus}
        isLocked={checkBox}
        isErrorCoupon={isErrorCoupon}
      />
      <CouponContainer className="CouponSettings">
        <CouponGrid>
          <SignSummery>
            <ToolsHeading className="signsHeading">
              Teckenfördelning{" "}
              <span className="headerSigns">
                <span>1</span>
                <span>X</span>
                <span>2</span>
              </span>
            </ToolsHeading>

            <SignSummary
              viewState={viewState}
              isReduced={isReduced}
              events={events}
            />
            <div className="safeguard hide-for-medium">
              <WholeHalfGuard viewState={viewState} isReduced={isReduced} />
            </div>
          </SignSummery>

          <DividedForecast>
            <Tooltip
              prefixCls="rToolTip"
              placement="bottom"
              trigger={["hover"]}
              animation="anim"
              overlay={
                <>
                  <h3>Utdelningsprognos i kronor</h3>

                  <p>
                    Hur ser den uppskattade utdelningen ut för raderna i
                    systemet? Få en snabb överblick på vad utdelningen
                    uppskattas ligga på för alla rader i systemet - prognosen är
                    inte faktisk och baseras på streckfördelningen och
                    omsättningen. Ju närmare spelstopp, desto skarpare
                    uppskattning. Staplarna visar hur pass defensivt/offensivt
                    upplagt systemet är.
                  </p>

                  <p>
                    Den uppskattade utdelningsprognosen aktiveras när man byggt
                    ett system och klickar på <strong>REDUCERA</strong>-knappen.
                    Med hjälp av poäng- färg- och 1X2-reducering kan man sedan
                    “vikta om” systemets rader beroende på smak och tycke.
                  </p>

                  <p>
                    OBS! Prognosen gäller enbart för rader med alla rätt och
                    uppskattas på den totala streckfördelningen och
                    omsättningen. <em>Reducering.se</em>
                     tar inget ansvar för eventuellt felaktiga uppskattningar.
                  </p>
                </>
              }
            >
              <ToolsHeading style={{ marginBottom: "1.5rem" }}>
                Utdelningsprognos i kronor{" "}
                <span className="rToolTip-icon">i</span>
              </ToolsHeading>
            </Tooltip>

            <p style={{ width: "100%", textIndent: "1.1rem" }}>%</p>

            <DividendForecast forecastData={forecastData} />
          </DividedForecast>

          <Safeguard className="show-for-medium">
            <WholeHalfGuard viewState={viewState} isReduced={isReduced} />
          </Safeguard>

          <RowInput>
            <RowStake
              setBetRowAmount={bet.setBetRowAmount}
              betRowAmount={bet.betRowAmount}
            />
          </RowInput>
          <GoToLive
            onClick={(e) => {
              e.preventDefault();
              if (id?.currentId) {
                history.push(
                  `/liveresults/${gameType}/${drawNumber}${
                    id.currentId ? `/${id.currentId}` : ""
                  }`
                );
              } else {
                setErrorPopup(true);
                // Not the best practice to set state to jsx
                setErrorPopupText(
                  <>
                    <h3>Glöm inte att spara din kupong!</h3>
                    <Link to={`/liveresults/${gameType}/${drawNumber}`}>
                      Fortsätt ändå
                    </Link>
                  </>
                );
              }
            }}
          >
            Liverättning
          </GoToLive>
          <CloneCoupon>
            <CloneCouponBtn
              cloneCount={cloneCount}
              setCloneCount={setCloneCount}
            />
          </CloneCoupon>

          <SaveCoupon>
            <SaveCouponBtn
              saveCoupon={saveCoupon}
              isReduced={isReduced}
              setErrorPopup={setErrorPopup}
              setErrorPopupText={setErrorPopupText}
              currentNickname={currentNickname}
              //disabledBtn = {isMine && !!initialViewState?.isLocked && checkBox}
              isMine={!isMine && !!id?.currentId && isAuthenticated}
            />
          </SaveCoupon>

          <DeleteCoupon>
            <ResetCoupon resetCoupon={resetCoupon} />
          </DeleteCoupon>

          <HandInCoupon>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                position: "absolute",
                padding: "0",
              }}
            >
              <img
                src={svsBlueSymbol}
                style={{
                  margin: "0",
                  width: "40%",
                  height: "100%",
                }}
              />
            </div>
            <HandInCouponBtn
              reducedRowsCount={reducedRowsCount}
              totalRader={totalRader}
              handinRows={handinRows}
              betRowAmount={bet.betRowAmount}
              setIsModalOpen={setIsModalOpen}
              isReduced={isReduced}
              setErrorPopup={setErrorPopup}
              setErrorPopupText={setErrorPopupText}
              actionType={actionType}
              SpTsms={SpTsms}
              //disabledBtn = {isMine && !!initialViewState?.isLocked && checkBox}
              isMine={isMine}
              id={!!id?.currentId}
              closeTime={closeTime}
            />
          </HandInCoupon>
        </CouponGrid>
        {isSM &&
          contestDraws.some(
            (contest) => contest.id === drawNumber.toString()
          ) && ( // Changed to enable contest
            <IsLocked isDisabled={isDisabled()}>
              <div className="turnament-img">
                <img alt="reduceringEm" src={reduceringEm} />
              </div>
              <div className="checkbox-container">
                <div>
                  <Tooltip
                    prefixCls="rToolTip"
                    placement="bottom"
                    trigger={isDisabled() ? ["hover"] : []}
                    animation="anim"
                    overlay={<>{getContent()}</>}
                  >
                    <div>
                      <CustomChekbox
                        styles="grays"
                        checked={checkBox}
                        disabled={isDisabled()}
                        onChange={() => {
                          setCheckBox((prev) => !prev); // Changed to enable contest
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>
                <Tooltip
                  prefixCls="rToolTip"
                  placement="bottom"
                  trigger={["hover"]}
                  animation="anim"
                  overlay={
                    <>
                      <p>
                        Var med och tävla i Reducering-EM tillsammans med
                        Reducering.se! Under tre EM-tipsetomgångar - med start
                        fredagen den 14:e juni - pågår denna prestigfyllda
                        tävling, som förutom möjligheten att koras till
                        EM-mästare även bjuder på chans till grymma priser. HELT
                        GRATIS att delta!
                      </p>
                    </>
                  }
                >
                  <ToolsSmallHeading>
                    {isDisabled() ? isTitle() : "Delta i Reducering-EM "}
                    <span>&nbsp;</span>
                    <Link className="link" to="/tavling">
                      Läs mer!
                    </Link>
                    <span className="rToolTip-icon">i</span>
                  </ToolsSmallHeading>
                </Tooltip>
              </div>
              <h4>Vinn resa värd 10 000 kr i Reducering-EM</h4>
              <div className="turnamet-notes">
                <ol>
                  <li>1. Gör ett system på max 500 rader</li>
                  <li>2. Checka boxen "Delta i Reducering-EM"</li>
                  <li>3. Klicka på "Lämna in"-knappen</li>
                  <li>4. Vinnaren får en resa värd 10 000 kr!</li>
                </ol>
                <Cup className="svg" />
              </div>
            </IsLocked>
          )}
      </CouponContainer>
    </>
  );
}

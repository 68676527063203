import styled from 'styled-components'
import {
  breakPoints,
  colors,
  font,
  spacers,
  borderRadius,
  headerHeight
} from '../../styles/constants'

export const GameAlarm = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;

  & > button {
    border: none;
    background-color: ${colors.svenskaSpelRed};
    font-size: ${font.size.small};
    color: ${colors.white};
    padding: ${spacers.medium};
    border-radius: ${borderRadius.small};
    display: flex;
    align-items: center;
    // text-align: right;
    margin-left: auto;
    cursor: pointer;
    white-space: nowrap;

    @media screen and (min-width: ${breakPoints.medium}) {
      font-size: ${font.size.xsmall};
      margin-left: ${spacers.xlarge};
    }

    @media screen and (max-width: ${breakPoints.smallMax}) {
      font-size: ${font.size.xsmall};
    }

    @media screen and (min-width: ${breakPoints.xlarge}) {
      margin-left: 4rem;
    }

    & > path {
      fill: ${({ isActive }) => (isActive ? colors.vividCyan : colors.white)};
    }
  }

  img {
    margin-right: ${spacers.small};

    @media screen and (max-width: ${breakPoints.smallMax}) {
      width: 18px;
      height: 19px;
    }
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    margin-left: ${spacers.medium};
    height: ${headerHeight.small};

    & > button {
      height: 100%;
    }
  }
`

export const AlarmButton = styled.button`
  /* border: none;
    background-color: ${colors.svenskaSpelRed};
    font-size: ${font.size.small};
    color: ${colors.white};
    padding: ${spacers.medium};
    border-radius: ${borderRadius.small};
    display: flex;
    align-items: center;
    text-align: right;
    margin-left: auto;
    cursor: pointer;
    white-space: nowrap;

    @media screen and (min-width: ${breakPoints.medium}) {
      font-size: ${font.size.xsmall};
      margin-left: ${spacers.xlarge};
    }

    @media screen and (max-width: ${breakPoints.smallMax}) {
      font-size: ${font.size.xsmall};
    }

    @media screen and (min-width: ${breakPoints.xlarge}) {
      margin-left: 4rem;
    } */

  & > svg {
    fill: ${({ isActive }) => (isActive ? colors.vividCyan : colors.white)};
    animation: wiggle 400ms ease-in-out infinite;
    animation-play-state: ${({ playAnimation }) => playAnimation};

    @keyframes wiggle {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(-10deg);
      }
      50% {
        transform: rotate(20deg);
      }
      75% {
        transform: rotate(-5deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }
`

import styled from 'styled-components'
import {
  spacers
} from '../../../../styles/constants'

export const Inner = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
`

export const ChartContainer = styled.div`
  max-width: calc(100% - 8rem);
  width: 100%;
  flex-grow: 1;

  margin-top: ${spacers.large};
  width: 100%;
  min-height: 19rem;
`

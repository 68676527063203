import styled from 'styled-components'
import { font, colors } from '../../styles/constants'
import { ArticlePanel } from '../../styles/globalStyle'

export const HowItWorksPanel = styled(ArticlePanel)`
  position: relative;
  //border-bottom-right-radius: 10rem;
  height: fit-content;
 padding: 0 0 5rem 0;
  
  background: none;
  .content{
    max-width: 100%;
    padding: 1.8rem;
    border-radius: 1rem;
    background: linear-gradient(
    -45deg,
    ${colors.panelDarkGrey} 0%,
    ${colors.panelDarkGrey} 15%,
    ${colors.headerGrey} 85%
  );
    p {
      max-width: initial;
      margin-bottom: 2.5rem;
      font-size: 1.6rem;
      color: ${colors.white};
      @media screen and (max-width: 468px){
      font-size: 1.4rem;
      }
    }
  
  }
  h3{
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-transform: uppercase;
    margin-bottom: 3rem;
    max-width: max-content;
    @media screen and (max-width: 960px){
    padding-left: 1.8rem;
    }
    @media screen and (max-width: 468px){
        font-size: 1.8rem;
        margin: 0 auto 20px auto;
        padding-left: 0;
        text-align: center;
      }
  }
  .readMore {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    line-height: 0.75;
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    right: 0;
    color: ${colors.lightGrey};
    text-decoration: none;
    border-radius: 50%;
    background-color: ${colors.grey};
    box-shadow: rgb(0 0 0 / 35%) 0px 3px 13px -2px;

    span {
      width: min-content;
      height: min-content;
      margin-bottom: -0.25rem;
      text-align: left;
      font-size: 1.75rem;
      font-weight: ${font.weight.semiBold};

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

import styled, { css } from 'styled-components'

export const BombenSelectContainer = styled.div`
  background: linear-gradient(90deg, #596E79 0.7%, #596E79 31.88%, #36474F 63.06%, #36474F 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  height: fit-content;
  border-radius: 0.375rem;
  select{
    width: 3.5rem;
    height: 2rem;
    padding: initial;
    border-radius: 0.375rem;
    text-indent: 0.25rem;
    cursor: pointer;
  }
  .size{
    color: #8DA2AB;
  }
  .selected{
    display: flex;
    align-items: center;
    grid-gap: 10px;
  }
  ${props => (props.area =='first') && css`
    grid-area: select;
  `}
  ${props => (props.area =='second') && css`
    grid-area: reselect;
  `}
  ${props => (props.area =='total') && css`
    grid-area: total;
  `}
   ${props => props.isActive && css`
    cursor: not-allowed;
  `}
    ${props => props.isButton && css`
      padding: 1rem 1.2rem 1.1rem;
  `}
`

export const ButtonPicker = styled.button`
background: rgb(102, 124, 137);
    color: rgb(207, 216, 220);
    box-shadow: rgb(0 0 0 / 35%) 0px 3px 13px -2px;
    border-radius: 50%;
    width: 3.4rem;
    height: 3.4rem;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 600;
  ${props => props.isButtonActive && css`
      background: #40C7A8;
      color: rgb(69, 90, 100);
  `}
  ${props => props.isActive && css`
      cursor: not-allowed;
  `}
`
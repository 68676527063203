import React, {useContext,useState,useRef, useEffect} from 'react'
import { TeamCreate,TeamCreateWrapper  } from './Popup.elements'
import 'reactjs-popup/dist/index.css';
import {DeletePopup,CreateContent} from './Popup.elements'
import { useAuth0 } from '@auth0/auth0-react'
import CustomDropdow from '../CustomDropdow/CustomDropdow'


export default function CreateItemPopup({  isModalOpen, setIsModalOpen,createItem,setCreateItem,handleCreate }) {
  const [createError, setCreateError] = useState({
    email:null,

  });
  const [user, setUser] = useState([])
  const [search,setSearch] = useState(null)
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const handleChange = (e) =>{
    const name = e.target.name;
    const value = e.target.value;
    setCreateItem(prev =>({
      ...prev,
      [name]:value
    }))
      setCreateError(prev =>({
        ...prev,
        [name]:null
      }))
  }
  const fetchUsers = async () =>{
    if(!isLoading){
      let token = isAuthenticated ? await getAccessTokenSilently() : {};
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/users`,{
        headers: headers
      }).then(res =>{
        return res.json()
        //return res.json()
      }).then(data=>{
        let users = data.users.map(item =>item)
        setUser(users)
      })
        .catch(err =>{
          debugger
          console.log(err)
        })
    }
  }
  const fetchUsersSort = async () =>{
    if(!isLoading){
      if(!search){
        fetchUsers()
        return
      }
      let token = isAuthenticated ? await getAccessTokenSilently() : {};
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
      fetch(`${process.env.REACT_APP_API_ENDPOINT}/users?${search ? `search=email&searchValue=${search}` : ''}`,{
        headers: headers
      }).then(res =>{
        return res.json()
        //return res.json()
      }).then(data=>{
        let users = data.users.map(item =>item)
        setUser(users)
      })
        .catch(err =>{
          debugger
          console.log(err)
        })
    }
  }
  useEffect(()=>{
    fetchUsers()
  }, [])
  useEffect(()=>{
     let time =setTimeout(()=>{
      fetchUsersSort()
    },500)
     return ()=>{
       clearTimeout(time)
     }
  },[search])
  const handleSubmit = () =>{
    let isValid = true
    if(!createItem.email && !createItem.id) {
      setCreateError(prev=>({...prev,email: true}))
      isValid = false
    }
    if(!createItem.id){
      setCreateError(prev=>({...prev,email: true}))
      isValid = false
    }
    return isValid
  }

  return (
    <DeletePopup
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false)
      }}
      position='center'
    >
      <CreateContent>
        <h4>Lägg till användare</h4>
        <div>
          <div className='row'>
            <label>Email</label>
            { <CustomDropdow
              list={user}
              setSearch={setSearch}
              setCreateItem={setCreateItem}
              error={createError}
              setError = {setCreateError}
            />}
          </div>
          {/*<div  className='row'>
            <label>Namn</label>
            <input
              maxLength={255}
              value={ createItem.username}
              onChange={handleChange}
              name='username'
              type="text"
            />
          </div>*/}
          <div  className='row'>
            <label>Notera</label>
            <input
              value={createItem.notera}
              onChange={handleChange}
              name='note'
              maxLength={255}
              type="text"/>
          </div>
          <div className='btn-group'>
            <button onClick={()=>{
              if(handleSubmit()){
                handleCreate.handle(createItem.id,createItem)
              } else {
                return
              }

            }} className='active'>Skapa</button>
            <button onClick={() => {
              setIsModalOpen(false)
            }}>Tillbaka</button>
          </div>
        </div>
      </CreateContent>
    </DeletePopup>
  )
}

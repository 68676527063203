import styled from 'styled-components'
import { breakPoints, borderRadius, spacers, colors, font } from '../../../styles/constants'

import {
  ArticlePanel
} from '../../../styles/globalStyle';

export const GameArticlePanel = styled(ArticlePanel)`
  background: none;
  padding:5rem 0;
  border-top: 1px solid #48C6A8;
  border-radius: unset;
  @media screen and (max-width: 468px){
       padding:3rem 0;
  }
  .tooltip{
    background-color: #44565F!important;
    box-shadow: 0px 3px 6px #0000004D;
    border-radius: 5px;
    border: none!important;
  }
  h3 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-transform: uppercase;
    margin-bottom: 3rem;
    max-width: max-content;
    @media screen and (max-width: 960px){
      padding-left: 1.8rem;
    }
    @media screen and (max-width: 468px){
        font-size: 1.8rem;
        margin: 0 auto 20px auto;
        padding-left: 0;
      }
  }
  h4{
    font-size: 2.2rem;
    line-height: 3rem;
    text-transform: uppercase;
    margin: 0 0 1rem 0!important;
     @media screen and (max-width: 468px){
        font-size: 1.6rem;
      }
  }
  .article-container {
  border-radius: 1rem;
  padding: 1.8rem;
  margin-bottom: 1.5rem;
  @media screen and (max-width: 468px){
      margin-bottom: 0.5rem;
  }
  max-width: 100%;
    background: linear-gradient(
    -45deg,
    ${colors.panelDarkGrey} 0%,
    ${colors.panelDarkGrey} 15%,
    ${colors.headerGrey} 85%
  );
  &:last-child{
    margin-bottom: 0;
  }
    p {
      max-width: initial;
      margin-bottom: 2.5rem;
      font-size: 1.6rem;
      color: ${colors.white};
      @media screen and (max-width: 468px){
      font-size: 1.4rem;
      }
    }
    p:last-child{
      margin-bottom: 0;
    }
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      min-height:12rem;
      max-height: 18rem;
      height: auto;
      border-radius: 0.5rem;
    }
    .size{
      font-size: 4rem;
    }
  }
  @media screen and (min-width: ${breakPoints.small}) {
    &.highlight {
      //box-shadow: inset 0 0 0 1px ${colors.vividCyan};
    }
  }
`

export const GamePageAboutNavContainer = styled(GameArticlePanel).attrs({ as: 'div' })`
  padding: 0;
  border: none;
  @media screen and (max-width: ${breakPoints.smallMax}) {
    position: sticky;
    z-index: 1;
    top: 0rem !important;
    padding: 1rem;
    background-color: rgba(0,0,0,0.7);
    border-top: 3px solid ${colors.darkGrey2};
    border-bottom: 3px solid ${colors.darkGrey2};
    border-radius: 0;

    > * {
      max-width: initial;
    }
  }
`

export const GamePageAboutNav = styled.div`
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: ${font.weight.semiBold};
  padding: 2rem 1rem;
  background: linear-gradient(
    -45deg,
    ${colors.panelDarkGrey} 0%,
    ${colors.panelDarkGrey} 15%,
    ${colors.headerGrey} 85%
  );
  border-radius: 1rem;
  ul > li {
    margin-bottom: 1.2rem;
    list-style-type: none;
    position: relative;
    &:after {
      content: '';
      width: 0.6rem;
      position: absolute;
      height: 0.6rem;
      left: -1.5rem;
      top: 50%;
      border-radius: 50%;
      background-color: ${colors.anchor};
      transform: translateY(-50%);
    }
    a.highlight {
      opacity: 1;
    }
  }

  a {
    color: ${colors.white};
    opacity: 0.5;
    cursor: pointer;
    text-decoration: none;
    text-transform: capitalize;
  }

  @media screen and (min-width: ${breakPoints.small}) {
    position: sticky;
    top: 11rem;
  }

  @media screen and (max-width: ${breakPoints.mediumMax}) and (min-width: ${breakPoints.small}) {
    top: 0.8rem;
  }

  > select {
    appearance: none;
    font-size: 16px;
    padding: ${spacers.medium};
    width: 100%;
    color: ${colors.white};
    height: 3rem;
    text-indent: 0.25rem;
    border-radius: ${borderRadius.xsmall};
    border: none;
    color: ${colors.white};

    background: ${colors.darkGrey2} url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21' height='21' viewBox='0 0 21 21'%3E%3Cg fill='none' fill-rule='evenodd' transform='rotate(-90 10.5 10.5)'%3E%3Crect width='21' height='21'/%3E%3Cpath fill='%23FFFFFF' fill-rule='nonzero' d='M9.556,9.978 L13.702,5.831 C14.0993628,5.39686541 14.0993628,4.73113459 13.702,4.297 C13.2678654,3.89963723 12.6021346,3.89963723 12.168,4.297 L7.283,9.24 L7.283,9.24 C6.88563723,9.67413459 6.88563723,10.3398654 7.283,10.774 L12.168,15.659 C12.3625794,15.8632944 12.6282168,15.9850151 12.91,15.999 C13.1957299,16.0067039 13.4686777,15.8805857 13.648,15.658 C14.0453628,15.2238654 14.0453628,14.5581346 13.648,14.124 L9.556,9.978 Z'/%3E%3C/g%3E%3C/svg%3E%0A" ) no-repeat;
    background-position: right 5px center;
  }
`
export const TeamsWrapper = styled.div`
  width: 99.7%;
  padding:0 0 3.5rem 0;
  @media screen and (max-width: 468px){
    padding: 1rem 0;
  }
`

export const TooltipGame = styled.div`
  background-color:rgba(55, 71, 79, 0.85);
  text-transform: capitalize;
  border-radius: 1rem;
  padding: ${props => props.size ? '1rem' : '1rem'};
  max-width: 130px;
  .title{
  margin-bottom: 0.5rem!important;
  }
  .green{
  color: ${colors.anchor}!important;
  }
`
import React, { useEffect, useState } from 'react'
import Tooltip from 'rc-tooltip'
import { ToolsSmallHeading } from '../../../styles/globalStyle'
import CustomChekbox from '../../CustomCheckbox/CustomChekbox'
import { BombenGameContainer } from './BombenGame.styles'
import {ReactComponent as Icon} from '../../../assets/question.svg'
import InputNumber from '../InputNumber/InputNumber'
import BombenDistributionLive from '../BombenDistribution/BombenDisributionLive'
import BombenDisributionHead from '../BombenDistribution/BombenDisributionHead'


const BombenGame = ({
  event,
  eventState,
  changeStatus,
  eventId,
  forecast,
  DataForecast

  }) => {
  const [sportEventStatusAbbrev,setSportEventStatusAbbrev] = useState('')
  let getName = (type) =>{
    let participants = event?.participants
    if(type ==='home') {
      let returnTeam = participants?.find(partTeam => partTeam?.type === 'home')
      return returnTeam?.name || ''
    }
    else {
      let returnTeam = participants?.find(partTeam => partTeam?.type === 'away')
      return returnTeam?.name || ''
    }
  }
  useEffect(()=>{
    if(forecast){
      switch (forecast?.sportEventStatus) {
        case 'Första halvlek':
          setSportEventStatusAbbrev('')
          break
        case 'Andra halvlek':
          setSportEventStatusAbbrev('')
          break
        case 'Halvtid':
          setSportEventStatusAbbrev('HT')
          break
        case 'Avslutad':
          setSportEventStatusAbbrev('FT')
          break
        case 'Slut efter förlängning':
          setSportEventStatusAbbrev('FT')
          break
        case 'Övertid':
          setSportEventStatusAbbrev('FT')
          break
        case 'Slut efter straffläggning':
          setSportEventStatusAbbrev('FT')
          break
        case 'Slut' :
          setSportEventStatusAbbrev('FT')
          break
        case 'Fulltid' :
          setSportEventStatusAbbrev('FT')
          break
        case 'Inte startat':
          setSportEventStatusAbbrev('')
          break
        default:
          setSportEventStatusAbbrev('')
      }
    }
  },[forecast])

  const getStatus = () =>{
    let getScore = forecast?.outcome?.split('-');
    if(!getScore?.length > 0){
      return (<>
          <span className='status__type'>-</span>
          <span>Fulltid</span>
        </>
      )
    }
    let selectFirst = eventState?.[eventId]?.firstTeam;
    let selectSecond = eventState?.[eventId]?.secondTeam;
    let findForFirstScore = selectFirst?.find(team => team?.score == getScore[0])
    let findForSecondScore = selectSecond?.find(team => team?.score == getScore[1])
    if(findForFirstScore && findForSecondScore){
      return (<>
          <span className='status__type status__type--win'>&#10003;</span>
          <span>{sportEventStatusAbbrev}</span>
        </>
      )
    }else{
      return (<>
          <span className='status__type'>-</span>
          <span>{sportEventStatusAbbrev}</span>
        </>
      )
    }
  }

  return (
    <BombenGameContainer live>
      <div className='main-head'>
        <div className='head'>
          <div className='head-team'>
            <span className='head-team-count head--left'>
              H{eventId + 1}
            </span>
            <span className='head-team-name'>{ getName('home') }</span>
          </div>
          <div className='head-vs'>
            VS
          </div>
          <div className='head-team'>
            <span className='head-team-name'>{ getName('away') }</span>
            <span className='head-team-count head--right'>
              B{eventId + 1}
            </span>
          </div>
        </div>
        <div className='status'>
          {getStatus()}
        </div>
        <div className='count'>
          <span>{forecast?.outcome}</span>
        </div>
      </div>
      <div className='body'>
        <div className='distribution'>
          <div className='distribution-team'>
            <div className='distribution-team__name'></div>
            {event?.distribution?.map((type,index)=>(
              <BombenDisributionHead
                eventId={eventId}
                key={index}
                type={type}
                kind='home'
                eventArrId = {index}
                currentType={eventState?.[eventId]?.firstTeam}
                winner={forecast?.isFinished && forecast?.outcome}
              />
            ))}
          </div>
          <div className='distribution-team'>
            <div className='distribution-team__name'>H{eventId + 1}</div>
              {event?.distribution?.map((type,index)=>(
                <BombenDistributionLive
                  eventId={eventId}
                  key={index}
                  type={type}
                  kind='home'
                  eventArrId = {index}
                  currentType={eventState?.[eventId]?.firstTeam}
                  winner={forecast?.outcome}
                />
              ))}
          </div>
          <div className='distribution-team'>
            <div className='distribution-team__name'>B{eventId + 1}</div>
            {event?.distribution?.map((type,index)=>(
              <BombenDistributionLive
                eventId={eventId}
                changeStatus={changeStatus}
                type={type}
                kind='away'
                key={index}
                eventArrId = {index}
                currentType={eventState?.[eventId]?.secondTeam}
                winner={forecast?.outcome}
              />))}
          </div>
        </div>
        <div className='body-footer'>
          <Tooltip
            prefixCls='rToolTip'
            placement='bottom'
            trigger={['hover']}
            animation='anim'
            overlay={
              <>
                <h3>Totalt antal mål i matchen</h3>
                <p>
                  Reducera på totalt antal mål i matchen genom att välja ett Min- och ett Max-värde. Klicka
                  sedan på <strong>REDUCERA</strong>-knappen för att reducera bort de rader som EJ uppfyller de valda
                  villkoren.
                </p>
              </>
            }
          >
            <ToolsSmallHeading>
              Totalt antal mål <span className='rToolTip-icon'>i</span>
            </ToolsSmallHeading>
          </Tooltip>
          <div className='body-size'>
            <div className='body-size__select'>
              <span className='body-size__title'>
                Min
              </span>
              <InputNumber
                disable={true}
                onChange={()=>{}}
                value={eventState?.[eventId]?.min}
              />
            </div>
            <div className='body-size__select'>
              <span className='body-size__title'>
                Max
              </span>
              <InputNumber
                disable={true}
                onChange={()=>{}}
                value={eventState?.[eventId]?.max}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='footer'>
        <div className='footer-info'>
          <Tooltip
            prefixCls='rToolTip'
            placement='bottom'
            trigger={['hover']}
            animation='anim'
            overlay={
              <>
                <h3>Förslag på ny rubrik: Reducera bort 1X2</h3>

                <p>
                  Reducera på 1X2-resultatet i matchen genom att klicka i rutorna för <em>Hemma, Oavgjort och
                  Borta</em>.
                </p>
                <p>
                  Klicka sedan på <strong>REDUCERA</strong>-knappen för att reducera bort det valda utfallet eller
                  utfallen, d.v.s. om exempelvis <em>Hemma klickas</em> i, så reduceras samtliga rader som innehåller
                  hemmaseger bort.
                </p>
              </>
            }
          >
            <p className='footer-hero'>
              Reducera bort
              <Icon/>
            </p>
          </Tooltip>
        </div>
        <div className='footer-check'>
          <CustomChekbox
            disabled={true}
            styles='greens'
            checked={eventState?.[eventId]?.firstDoesntWin}
            title='Hemma'
            onChange={()=>{}}
          />
          <CustomChekbox
            disabled={true}
            styles='greens'
            checked={eventState?.[eventId]?.noDraw}
            title='Oavgiort'
            onChange={()=>{}}
          />
          <CustomChekbox
            disabled={true}
            styles='greens'
            checked={eventState?.[eventId]?.secondDoesntWin}
            title='Borta'
            onChange={()=>{}}
          />
        </div>
      </div>
    </BombenGameContainer>
  )
}

export default BombenGame
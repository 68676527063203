import styled from 'styled-components'
import { breakPoints, spacers, colors } from '../../styles/constants'
import { GamesGrid, OneHalf } from '../../styles/globalStyle'

export const GamesGridAboutGame = styled(GamesGrid)`
  > * {
    width: calc((100% - ${spacers.small} * 2) / 3);

    &:before {
      padding-bottom: calc(1/1 * 85%);
    }

    @media screen and (max-width: 799px) {
      width: calc((100% - ${spacers.small}) / 2);

      &:before {
        padding-bottom: calc(1/1 * 100%);
      }

      &:nth-child(2n + 1) {
        margin-left: 0;
      }

      &:nth-child(n + 3) {
        margin-top: ${spacers.small};
      }
    }

  }
`

export const GameAboutContent = styled(OneHalf)`
  article {
    > * {
      max-width: 42rem;
    }

    + article {
      margin-top: ${spacers.small};
    }

    p em, p strong {
      color: ${colors.white};
    }
  }

  @media screen and (min-width: ${breakPoints.small}) {
    width: calc(100% - 200px - ${spacers.xxsmall});
    margin-top: ${spacers.small};
    margin-right: ${spacers.xxsmall};
  }
`

export const GameAboutSidebar = styled(OneHalf)`
  @media screen and (min-width: ${breakPoints.small}) {
    width: 200px;
  }
`

import styled, {css} from 'styled-components'
import {
  borderRadius,
  breakPoints,
  colors,
  font,
  spacers
} from '../../../../styles/constants'

export const GameResultContainer = styled.div`
  background-color: ${colors.darkGrey};
  color: #bcc5c8;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  font-weight: ${font.weight.light};
  padding: ${spacers.small} ${spacers.large};
  font-size: ${font.size.xsmall};
  width: 100%;
  ${props => props.cursor && css`
  cursor: pointer;
`}
  @media screen and (max-width: ${breakPoints.mediumMax}) {
    font-size: ${font.size.small};
  }
  .info {
    margin-right: 0.4rem;
    .nav-img {
      cursor: pointer;
      width: 6px;
      height: 10px;
      transform: rotate(90deg);
    }
    .active {
      transform: rotate(270deg);
    }
  }
  .gameStatus {
    min-width: 2.5rem;
    font-weight: ${font.weight.semiBold};
    margin-right: ${spacers.medium};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .gameEventNumber {
      font-size: ${font.size.medium};
      color: ${({ sportEventStatus }) => (
        sportEventStatus === 'Första halvlek' || 
        sportEventStatus === 'Andra halvlek' || 
        sportEventStatus === 'Halvtid' || 
        sportEventStatus === 'Övertid'
      ) ?  colors.vividCyan : '#bcc5c8'}
    }

    .gameStatusAbbrev {
      color: ${colors.white};
      font-weight: ${font.weight.normal};

      font-size: ${({ sportEventStatus }) =>
        sportEventStatus === 'Inte startat' ? font.size.small : font.size.large};
    }
  }
  .font-size{
    font-size: 1.8rem!important;
    @media screen and (max-width: 767px){
      font-size: 1.6rem!important;
    }
  }
  .red {
    color:${colors.svenskaSpelRedActive};
  }
  .gameInfo {
    flex: auto;
    margin-right: ${spacers.large};
    position: relative;
  }

  .gameInfoLott {
    text-transform: uppercase;
    // font-size: ${font.size.small};
    font-weight: ${font.weight.normal};
    white-space: pre-line;
    color: ${colors.white};

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      margin-top: ${spacers.small};
      display: block;
      font-size: ${font.size.xsmall};
    }
  }

  .gameInfoDate {
    display: flex;
    justify-content: space-between;
    color: ${colors.white};
    font-weight: ${font.weight.semiBold};
    margin-bottom: ${spacers.xsmall};
    
  }
  .position-medium{
    /*position: absolute;
    top: -0.5rem;
    right: -4rem;
    @media screen and (max-width: 1365px){
      right: -1rem;
      top: -1rem;
    }*/
  }
  .outcomeScore {
    width: 5rem;
    font-size: ${font.size.large};
    padding: ${spacers.large};
    text-align: center;
    white-space: nowrap;
    font-weight: ${font.weight.semiBold};
    background-color: ${({ isScored }) => (isScored ? colors.svenskaSpelRed : 'initial')};
    border-radius: ${borderRadius.small};
    flex: 0 0 auto;

    color: ${({ sportEventStatus }) =>
      sportEventStatus === 'Inte startat' ? '#BCC5C8' : colors.white};

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      width: 4.5rem;
      font-size: ${font.size.medium};
      padding: ${spacers.large} ${spacers.medium};
    }

    transition: ${({ isScored }) =>
      isScored
        ? 'box-shadow 1500ms cubic-bezier(.02,1.02,.81,1)'
        : 'background-color 150ms ease-in-out'};

    box-shadow: ${({ isScored }) =>
      isScored ? '0 0 0 2rem rgba(255, 0, 0, 0)' : '0 0 0  rgba(255, 0, 0, 0.8)'};

    &:empty {
      display: none;
    }
  }

  .gameInfoEventNumber {
    font-size: ${font.size.small};
    font-weight: ${font.weight.semiBold};

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      font-size: 1.6rem;
    }
  }

  .gameInfoStatus {
    font-size: ${font.size.small};
    font-weight: ${font.weight.semiBold};
  }

  .gameInfoTeams {
    // font-size: 1rem;
    font-size: 1.1rem;
    line-height: 1.1;
    font-weight: ${font.weight.normal};
    text-decoration: none;
    // color: ${colors.lightGrey};
    color: ${colors.white};

    &:hover {
      text-decoration: underline;
    }

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      font-size: 0.9rem;
    }
  }

  /* LiveResult */
  background: linear-gradient(
    -45deg,
    ${colors.panelDarkGrey} 15%,
    ${colors.panelDarkGrey} 40%,
    ${colors.headerGrey} 85%
  );

  .gameInfoResult {
    font-size: ${font.size.xxlarge};
    font-weight: ${font.weight.semiBold};
    width: 4rem;
    text-align: center;
    flex: 0 0 auto;
    text-indent: 0.5rem;

    color: ${({ sportEventStatus }) =>
      sportEventStatus === 'Inte startat' ? '#BCC5C8' : colors.white};

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      width: 2.4rem;
      text-indent: 0.75rem;
    }

    &:empty {
      display: none;
    }
  }
`

import React, { useState, useEffect } from 'react'
import useWindowDimensions from '../../../utils/hooks/useWindowDimensions'
import Tooltip from 'rc-tooltip'
import SwiperCore, { Navigation } from 'swiper/core'
// Import Swiper styles
import 'swiper/swiper-bundle.css'
import {
  CorrectRows,
  InnerColList,
  InnerUserSign,
  UserRowsContainer, UserRowsHeader, UserRowsList, UserRowsTool
} from '../../LiveResult/UserRows/UserRows.elements'
import SwiperBox from '../../LiveResult/ResultWidgets/SwiperCast/SwiperBox'
import { InnerContainer, ToolsHeading } from '../../../styles/globalStyle'
import { RowContent, RowKub, Rows, Score, ScoreRow } from './styles'
import BombenSwiper from '../BombenSwiper/BombenSwiper'
// install Swiper modules
SwiperCore.use([Navigation])


export default function BombenLiveRows({
   toggleUnplayed,
   setToggleUnplayed,
   betRowAmount,
   isModalOpen,
   data,
   table,
   win,
   totalWin,
   myWin
  }) {
  const handleToggle = () => {
    setToggleUnplayed(!toggleUnplayed)
  }

  // My logic
  const [rowHead, setRowHead] = useState([])
  const [rowWin, setRowWin] = useState([])
  const [rowScore,setRowScore] = useState([0])
  const [isNewBestRow, setIsNewBestRow] = useState(false)

  useEffect(()=>{
    if(table && table?.length > 0){
      setIsNewBestRow(true)
      let score = table?.map(item =>item?.ratt);
      score.unshift(score[0])
      setRowScore(score)
    }
  },[table])

  useEffect(()=>{
      let getEvent = data?.events?.length;
      let odd = 1
      let even = 1
      let arrHome = Array.from({length: getEvent * 2 }, (v, i) => {
        let isOdd = (i + 2) % 2 === 0;
        let result = isOdd ? `H${odd}` : `B${even}`;
        isOdd ? odd++ : even++;
        return result
      })
      setRowHead(arrHome)
      let forEvent = data?.forecast?.events?.map(event =>event?.outcome)
      if(data?.forecast && forEvent){
        let spliter = forEvent?.join('-')?.split('-')
        setRowWin(spliter)
      }else{
        setRowWin(Array.from({length: getEvent * 2}, (v, i) => 0))
      }
  },[data])
  return (
    <UserRowsContainer className='userRows'>
      <BombenSwiper
        isModalOpen={isModalOpen}
        userRows={[]}
        forecast={[]}
        dividendForecast={[]}
        betRowAmount={betRowAmount}
        theLastGame={null}
        win={win}
        totalWin={totalWin}
        myWin={myWin}
      />
      <UserRowsHeader hide={!isModalOpen}>
        <span className='item-center'>
          <p>Aktuell rad och dina rader med flest antal rätt</p>

          {!isModalOpen ? (
            <Tooltip
              prefixCls='rToolTip'
              placement='bottom'
              trigger={['hover']}
              animation='anim'
              overlay={
                <>
                  <h3>Bästa rader</h3>

                  <p>
                    Här ser du dina 10 bästa rader. Klicka på "Visa Rader" i nedre
                    högerhörn för att se dina 50 bästa rader tillsammans med ditt
                    Utdelningsprognos system.
                  </p>
                </>
              }
            >
              <ToolsHeading>
                <span className='rToolTip-icon'>i</span>
              </ToolsHeading>
            </Tooltip>
          ) : null}
        </span>

        <button className='userRowsToggle' onClick={handleToggle}>
          {toggleUnplayed ? 'Exkludera ostartade matcher' : 'Inkludera ostartade matcher'}
        </button>
      </UserRowsHeader>
      <div className='scroll'>
        <Rows hide={!isModalOpen}>
          <RowContent>
            {rowHead?.length && rowHead?.map((row,ind)=>{
              return <RowKub
                type='dark'
                key={`${ind}Head`}
              >
                {row}
              </RowKub>
            })}
          </RowContent>
          <RowContent>
            {rowWin?.length && rowWin?.map((row,ind)=>{
              return <RowKub
                type='red'
                key={`${ind}Win`}
              >
                {row}
              </RowKub>
            })}
          </RowContent>
          {table?.length > 0 && table?.map(tab =>{
            return (
              <RowContent>
                {tab?.scores?.length && tab?.scores?.map((row,ind)=>{
                  return <RowKub
                    type={row?.isWin ? '' : 'dark'}
                    key={`${ind}Scr`}
                  >
                    {row?.score}
                  </RowKub>
                })}
              </RowContent>
            )
          })}
        </Rows>
        <Score hide={!isModalOpen}>
          {rowScore?.length > 0 && rowScore?.map((score,inx) =>{
            return <ScoreRow
              isNewBestRow={isNewBestRow}
              isMain={inx === 0}
            >
              {inx === 0 && <span style={{fontSize:'9px',fontWeight:'500'}}>Bästa rad</span>}
              {score}
            </ScoreRow>
          })}
        </Score>
      </div>
    </UserRowsContainer>
  )
}

import styled from 'styled-components'
import bg from '../../assets/404.png'
export const EmptyContent = styled.div`
   box-sizing: border-box;
  width: 100%;
  height: 100%;
  .head{
   background: linear-gradient(130.37deg, #5A6E79 0.38%, #273238 83.07%);
   box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.04);
   position: fixed;
   top: 0;
   height: 77px;
   width: 100%;
   display: flex;
   align-items: center;
   .content{
    max-width: 945px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    grid-gap: 10px;
    align-items: center;
    @media screen and (max-width: 960px){
      padding-left: 10px;
    }
   }
  }
  .body{
    height: 100vh;
    overflow: auto;
    box-sizing: border-box;
    background: url(${bg});
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 90px;
    @media screen and (max-width: 1010px){
      padding: 10px;
      flex-direction: column;
      grid-gap: 20px;
    }
    @media screen and (max-width: 440px){
      padding: 15px;
    }
  }
  .info{
    //max-width: 510px;
    //width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
     @media screen and (max-width: 769px){
      grid-gap: 10px;
      text-align: center;
     }
    .title{
      font-size: 60px;
      font-weight: 700;
      color: #FFFFFF;
      margin: 0;
      @media screen and (max-width: 769px){
        font-size: 40px;
       }
       @media screen and (max-width: 440px){
        font-size: 30px;
       }
    }
    .subtitle{
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      color: #D7D7D7;
      max-width: 410px;
      width: 100%;
      margin: 0;
    }
    
  }
  .image{
      max-width: 100%;
      object-fit: contain;
      object-position: center;
       @media screen and (max-width: 1100px){
        max-width: 280px;
       }
    }
`
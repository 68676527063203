import React from 'react'
import { Option, Select } from '../../Game/CouponSettings/RowStake/RowStake.elements'
import { BombenSelectContainer, ButtonPicker } from './BombenSelect.style'
import { ToolsHeading, ToolsSmallHeading } from '../../../styles/globalStyle'
import Tooltip from 'rc-tooltip'
import CustomInput from '../../CustomInput/CustomInput'
import InputNumber from '../InputNumber/InputNumber'

const BombenSelect = ({
  tooltip,
  area,
  onChange,
  title,
  valueMin,
  valueMax,
  optionMin,
  optionMax,
  isActive = true,
  isButton = false,
  handleButtonClick,
  isButtonActive,
  tooltipHtml
  }) => {
  return (
    <BombenSelectContainer area={area} isActive={!isActive} isButton={isButton}>
      <div style={{display:'flex',gridGap:'1rem'}}>
        {isButton &&
        <ButtonPicker
          isActive={!isActive}
          isButtonActive={isButtonActive}
          onClick={handleButtonClick}
        >
          P
        </ButtonPicker>}
        <Tooltip
          prefixCls='rToolTip'
          placement='bottom'
          trigger={['hover']}
          animation='anim'
          overlay={
            <>
              {tooltipHtml}
            </>
          }
        >
          <ToolsSmallHeading>
            {title} <span className='rToolTip-icon'>i</span>
          </ToolsSmallHeading>
        </Tooltip>
      </div>
      <div className='selected'>
        <div className='selected'>
          <span className='size'>Min</span>
          <InputNumber
            option={optionMin}
            onChange={onChange}
            value={valueMin}
            size='small'
            disable={!isActive}
          />
        </div>
        <div className='selected'>
          <span className='size'>Max</span>
          <InputNumber
            option={optionMax}
            onChange={onChange}
            value={valueMax}
            size='small'
            disable={!isActive}
          />
        </div>
      </div>
    </BombenSelectContainer>
  )
}

export default BombenSelect
import styled from 'styled-components'
import {
  breakPoints,
  colors,
  font,
  spacers,
  borderRadius,
  headerHeight
} from '../../styles/constants'

export const AboutWrapper = styled.div`
  padding:4.8rem 0;
  width:100%;
  text-align: center;
  //border-bottom: 0.05rem solid ${colors.anchor};
  @media screen and (max-width:768px ){
    padding: 4.8rem 1rem;
  }
  h2 {
    font-size:2.8rem;
    margin-bottom: 2rem;
  }
  .upp {
    text-transform: uppercase;
  }
  p {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    padding: 0 6rem;
     @media screen and (max-width: 768px){
      padding: 0;
      font-size: 1.4rem;
     }
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    
    @media screen and (max-width: 968px){
      justify-content: space-evenly;
    }
    @media screen and (max-width: 468px){
      justify-content: space-between;
    }
    &-circle {
      text-align: center;
      width: 17rem;
       @media screen and (max-width: 768px){
        margin: 1rem ;
        min-width: 11rem;
        width: 40%;
       }
      .circle {
        margin: 0 auto;
        width: 9.2rem;
        height: 9.2rem;
        background: transparent linear-gradient(140deg, #5A6E79 0%, #37474F 100%);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 768px){
           width: 6.6rem;
           height: 6.6rem;
        }
        img {
          width: 4rem;
          height: 4rem;
          @media screen and (max-width: 768px){
           width: 3rem;
           height: 3rem;
          }
        }
      }
      p {
        font-size: 2.4rem;
        font-weight: ${font.weight.semiBold};
        padding: 0;
        margin-top: 1.3rem;
        @media screen and (max-width: 768px){
        font-size: 1.6rem;
        }
      }
    }
  }
`
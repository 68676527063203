import React from 'react'
import './index.css'
import {
  GameItemContainer,
  GameItemsHeader,
  ProbabilityToggle
} from './GameList.elements'

import GameItem from './GameItem/GameItem'

export default function GamesList({
  events,
  viewState,
  couponFunctions,
  mode,
  trendData,
  setColumnActive,
  setRowActive,
  isReduced,
  isProbability,
  setIsProbability,
  currentCouponName,
  productId,
  eventForecast
}) {
  return (
    <GameItemContainer>
      <GameItemsHeader>
        <div>
          <span style={{wordBreak:'break-word'}}>{currentCouponName}</span>

          <ProbabilityToggle
            onClick={() => {
              setIsProbability(!isProbability)
            }}
          >
            {isProbability ? 'Visa odds' : 'Visa odds som %'}
          </ProbabilityToggle>
        </div>

        <div onClick={() => setColumnActive('home')}>1</div>
        <div onClick={() => setColumnActive('draw')}>X</div>
        <div onClick={() => setColumnActive('away')}>2</div>
      </GameItemsHeader>

      {events.map((eventData, idx) => (
        <GameItem
          key={`${idx}-${eventData.sportEventId}`}
          eventData={eventData}
          viewState={viewState[idx]}
          index={idx}
          eventForecast={eventForecast?.length && eventForecast[idx]}
          mode={mode}
          couponFunctions={couponFunctions}
          isProbability={isProbability}
          trendData={trendData.trends?.[idx]}
          setRowActive={setRowActive}
          isReduced={isReduced}
          productId={productId}
        />
      ))}
    </GameItemContainer>
  )
}

import React,{useState,useEffect,useContext} from 'react'
import HomeHeader from '../../components/Headers/HomeHeader'
import Footer from '../../components/Footer/Footer'
import { InnerContainer } from '../../styles/globalStyle'
import Table from '../../components/Table/Table'
import { useAuth0 } from '@auth0/auth0-react'
import {AuthorWrapper} from './AuthorPage.elements'
import DeleteItemPopup from '../../components/Popup/DeleteItemPopup'
import moment from 'moment'
import svsEuro from '../../assets/svs_product_europatipset.svg'
import svsPOwerplay from '../../assets/svs_product-powerplay.svg'
import svsStryk from '../../assets/svs_product_stryktipset.svg'
import svsTop from '../../assets/svs_product_topptipset.svg'
import {useHistory} from 'react-router-dom'
import { AccountContext } from '../../context/accountContext'
import { ReactComponent as LoadingLogo } from '../../assets/reducering-logomark.svg'

// Utils
const header1 = {
  first:"Titel",
  second:"Datum",
  last:"Kategori"
}
const CreateBlogSubtitle = {
  text:'CREATE BLOG',
  add:true
}
const gameType = [{
  gameType:"europatipset",
  img:svsEuro
},{
  gameType: 'powerplay',
  img:svsPOwerplay
},{
  gameType: 'stryktipset',
  img:svsStryk
},{
  gameType: 'topptipset',
  img:svsTop
}
]
const AuthorPage = () => {
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [pageCountBlog,setPageCountBlog] = useState(1);
  const [AllPageBlog, setAllPageBlog] = useState(null);
  const [blog, setBlog] = useState([]);
  const [isModalOpen,setIsModalOpen] = useState(false);
  const [typeDelete, setTypeDelete] = useState();
  const [canFetch,setCanFetch] = useState(false);
  const [loader,setLoader] = useState(false)
  const history = useHistory();
  const user = useContext(AccountContext);

  const formatDate = (date) =>{
    let currentDate = moment(date).format('DD/MM/YYYY')
    return currentDate
  }

  const findType = (game) =>{
    let find = gameType.find(games => games.gameType === game);
    return find.img
  }

  const handleRedirect = () =>{
    history.push('/create-blog')
  }

  const handleDelete = (e,id,type) => {
    setIsModalOpen(true)
     if (type === 'blog'){
      setTypeDelete({
        handle: handlDeleteBlog
        , id: id
      })
    }
  }
  // delete blog
  const handlDeleteBlog = async (id) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/blog/${id}`,{
      method:'DELETE',
      headers:headers,
    })
    if(response.ok){
      setIsModalOpen(false)
      fetchBlog()
    }
    return response.json();
  }
  const fetchBlog = async () =>{
    setLoader(true)
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    let reset = await setBlog([])
    //setLoader(true)
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/myBlogs?page=${pageCountBlog}&perPage=6`,{
      headers: headers
    }).then(res =>{
      setTimeout(()=>{
        setLoader(false)
      },500)
      return res.json()
    }).then(data=>{
      if(data.blogs.length > 0){
        let blogs= data.blogs.map(item =>
          ({
            first:item.title,
            second:formatDate(item.date),
            last:{
              img:findType(item.gameType)
            },
            infoItem:{...item,link:`/blog/${item.gameType}/${item._id}`}
          })
        )
        setBlog(blogs)
        let count = data.total
        let totalPage = count/6
        setAllPageBlog(Math.ceil(totalPage))
      } else{
        setBlog([])
      }
      //setBlog(data?.blogs)
    })
  }

  useEffect(()=>{
    const timer = setTimeout(()=>{
      if(user.author === false){
        history.push('/')
      }else{
        setCanFetch(true)
      }
    },500);

    return ()=> clearTimeout(timer)
  },[user])

  useEffect(()=>{
    if(canFetch){
      fetchBlog()
    }
  },[canFetch,pageCountBlog])

  return (
    <>
      {isModalOpen && <DeleteItemPopup
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        typeDelete ={typeDelete}
      />}
      <HomeHeader />
      <InnerContainer>
        <AuthorWrapper>
          <Table
            header={header1}
            row={blog}
            title={CreateBlogSubtitle}
            handleCreate={handleRedirect}
            deleteItem={handleDelete}
            pageCount={pageCountBlog}
            setPageCount={setPageCountBlog}
            AllPage={AllPageBlog}
            typeAccout='blog'
            typeIcon='redirect'
            isLoading={loader}
          >
          </Table>
        </AuthorWrapper>
      </InnerContainer>
      <InnerContainer>
        <Footer />
      </InnerContainer>
    </>
  )
}

export default AuthorPage
import styled from 'styled-components'
import {
  breakPoints,
  font,
  spacers,
  colors,
  borderRadius,
  transition
} from '../../styles/constants'

export const NotFoundContainer = styled.div`
  padding:3.3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(118.44deg, #4F616B 8.02%, rgba(79, 97, 107, 0) 117.5%);
  border-radius: 0.5rem;
  .title{
    color: ${colors.white};
    font-size: 1.8rem;
    font-weight: ${font.weight.bold};
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
  .info {
    max-width: 430px;
    width: 100%;
    color: #D7D7D7;
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 2rem;
  }
  .svg {
    width: 5rem;
    height: 5rem;
    margin-bottom: 3rem;
  }
  .button-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    grid-gap: 1rem;
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor:pointer;
    font-size: 1.6rem;
    font-weight: ${font.weight.semiBold};
    text-transform: uppercase;
    min-width: 18rem;
    color: ${colors.white};
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #2B373E;
    height: 44px;
    &:disabled{
      cursor: not-allowed;
      opacity: 0.5;
    }
    &--green{
      background-color: #30B29E;
    }
    @media screen and (max-width: 468px){
      min-width: 14rem;
      font-size: 1.4rem;
    }
  }
`
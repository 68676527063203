import React, { useContext, useEffect, useRef, useState } from 'react'
import HomeHeader from '../../components/Headers/HomeHeader'
import { useAuth0 } from '@auth0/auth0-react'
import {InnerContainer} from '../../styles/globalStyle'
import {CreateContainer} from './CreateBlog.element'
import stryktipsetLogo from '../../assets/stryktipset-wordmark.svg'
import { getActiveDate, sortDates } from '../../utils/helperFunctions'
import europatipsetLogo from '../../assets/europatipset-wordmark.svg'
import topptipsetLogo from '../../assets/topptipset-wordmark.svg'
import powerplayLogo from '../../assets/powerplay-wordmark.svg'
import { ActiveGamesContext } from '../../context/activeGamesContext'
import {AccountContext} from '../../context/accountContext'
import { useOnClickOutside } from '../../utils/hooks/useOnClickOutside'
import {Prompt, useHistory,useLocation} from 'react-router-dom'
import arrow from '../../assets/arrow.png'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import CustomChekbox from '../../components/CustomCheckbox/CustomChekbox'
import { parseISO } from 'date-fns'
import stryk1 from '../../assets/stryk1.png'
import stryk2 from '../../assets/stryk2.png'
import stryk3 from '../../assets/stryk3.png'
import power1 from '../../assets/power1.png'
import power2 from '../../assets/power2.png'
import power3 from '../../assets/power3.png'
import topp1 from '../../assets/topp1.png'
import topp2 from '../../assets/topp2.png'
import topp3 from '../../assets/topp3.png'
import euro1 from '../../assets/euro1.png'
import euro2 from '../../assets/euro2.png'
import euro3 from '../../assets/euro3.png'


const gameImage = [{
  gameType:'powerplay',
  images:[{src:power1,id:'power-1', checked:true},{src:power2,id:'power-2', checked:false,},{src:power3,id:'power-3', checked:false,}]
},{
  gameType:'europatipset',
  images:[{src:euro1,id:'euro-1', checked:true,},{src:euro2,id:'euro-2', checked:false,},{src:euro3,id:'euro-3', checked:false,}]
},{
  gameType:'topptipset',
  images:[{src:topp1,id:'topp-1', checked:true,},{src:topp2,id:'topp-2', checked:false,},{src:topp3,id:'topp-3', checked:false,}]
},{
  gameType:'stryktipset',
  images:[{src:stryk1,id:'stryk-1', checked:true,},{src:stryk2,id:'stryk-2', checked:false,},{src:stryk3,id:'stryk-3', checked:false,}]
}]

const CreateBlog = () => {
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const history = useHistory()
  let location = useLocation()

  const ref = useRef(null)
  // fetch blog

  const { stryktipset, europatipset, topptipset, powerplay } =
    useContext(ActiveGamesContext);
  const {username} =
    useContext(AccountContext);

  //State
  const [isOpenSelect, setIsOPenSelect] = useState(false);
  const [selected, setSelected] = useState({img:null, info:null,type:''});
  const [historyLoad,setHistoryLoad] = useState(null)
    //State for inputs
  const [createBlog, setCreateBlog] = useState({
    title:"",
    date:new Date(),
    comment:"",
    author:""
  })
  const [error, setError] = useState({
    title:null,
    date:null,
    author:null,
  })
    // State for event
  const [event,setEvent] = useState([])
  const [eventChange, setEventChange] = useState([{}])
  //Images
  const image = [stryktipsetLogo,europatipsetLogo,topptipsetLogo,powerplayLogo]
  // For dropdown
  useOnClickOutside(ref, () => {
    setIsOPenSelect(false)
  })
  const handleSelect = (event,type,id,idx) =>{
    if(type === "stryktipset"){
      const imagesForChoose = gameImage.find(game => game.gameType === type)
      setCheckbox(imagesForChoose)
      const findItem = stryktipset?.draws.find(item=>item.drawNumber === id);
      let date = getActiveDate(stryktipset, idx);
      if(findItem) setSelected({draw:findItem,img:image[0],info:date,type:"stryktipset",gameType:findItem.productName ,openTime:findItem.openTime})
      setError(prev=>({
        ...prev,
        selected:null
      }))
      setIsOPenSelect(false)
      return
    }
    else if(type === "europatipset"){

      const imagesForChoose = gameImage.find(game => game.gameType === type)
      setCheckbox(imagesForChoose)
      const findItem = europatipset?.draws.find(item=>item.drawNumber === id);
      let date = getActiveDate(europatipset, idx);
      if(findItem) setSelected({draw:findItem,img:image[1],info:date,type:"europatipset",gameType:findItem.productName,openTime:findItem.openTime })
      setError(prev=>({
        ...prev,
        selected:null
      }))
      setIsOPenSelect(false)
      return
    }
    else if(type === "topptipset"){
      const imagesForChoose = gameImage.find(game => game.gameType === type)
      setCheckbox(imagesForChoose)
      const findItem = topptipset?.draws.find(item=>item.drawNumber === id);
      let date = getActiveDate(topptipset, idx);
      if(findItem) setSelected({draw:findItem,img:image[2],info:date,type:"topptipset",gameType:findItem.productName ,openTime:findItem.openTime})
      setError(prev=>({
        ...prev,
        selected:null
      }))
      setIsOPenSelect(false)
      return
    }
    else if(type === "powerplay"){
      const imagesForChoose = gameImage.find(game => game.gameType === type)
      setCheckbox(imagesForChoose)
      const findItem = powerplay?.draws.find(item=>item.drawNumber === id);
      let date = getActiveDate(powerplay, idx);
      if(findItem) setSelected({draw:findItem,img:image[3],info:date,type:"powerplay",gameType:findItem.productName,openTime:findItem.openTime })
      setError(prev=>({
        ...prev,
        selected:null
      }))
      setIsOPenSelect(false)
      return
    }
  }
  const handleColor = (type) =>{
    if(type === "stryktipset") {
      return "background-str"
    } else if (type === "europatipset"){
      return "background-euro"
    }else if(type === "topptipset"){
      return "background-top"
    }
    else if(type === "powerplay"){
      return "background-pow"
    } else{
      return ''
    }
  }
  const handleOpen = (e) =>{
    setIsOPenSelect(prevState => !prevState)
  }

  //Handle change for inputs
  const handleChange = (e) =>{
    const name = e.target.name;
    const value = e.target.value;
    setCreateBlog(prev =>({
      ...prev,
      [name]:value
    }))
    setError((prev => ({
      ...prev,
      [name]:null
    })))
  }
  const handleChangeDate = (e) =>{
    setCreateBlog(prev =>({
      ...prev,
      date:e
    }))
    setError((prev => ({
      ...prev,
      date:null
    })))
  }
  // Handle for games
  const handleCheck = (e,id,type) =>{
    let updateState = eventChange.map((item)=>{
      if(item.eventNumber === id){
          item.forecast[type] = !item.forecast[type]
        return item
      } else {
        return item
      }
    })
    setEventChange(updateState)
  }
  const handleChangeEvent = (e,id) =>{
    const value = e.target.value;
    let updateState = eventChange.map((item)=>{
      if(item.eventNumber === id){
        item.comment = value
        return item
      } else {
        return item
      }
    })
    setEventChange(updateState)
  }

  useEffect(()=>{
    if(selected.type){
      if(historyLoad) return
      setEventChange([{}])
      setEvent(selected.draw)
      let eventState = selected?.draw?.events?.map(event =>({eventNumber:event.eventNumber,
        comment:'',forecast:[false,false,false]}))
      setEventChange(eventState)
    }
  },[selected])
  // Submit blog
  const handleSubmit = () =>{
    if(historyLoad){
      if(validField()){
        /*let events = historyLoad.events.map((event,index) =>{
          return {
            comment:eventChange[index].comment ,
            event:event.event,
            forecast: eventChange[index].forecast,
            id:eventChange[index].eventNumber
            }
        })*/
        let findImg = checkbox?.images?.find(img => img?.checked === true)?.id
        let patchObject = {
          events: eventChange,
          title: createBlog.title,
          comment: createBlog.comment,
          namedAuthor: createBlog.author,
          date: createBlog.date,
          imageId:findImg
        }
        patchCreate(patchObject,historyLoad._id)
        return
      }
    }
    if(validField()){
      const {drawNumber,checksum} = event;
      let findImg = checkbox?.images?.find(img => img?.checked === true)?.id
      let sendObject = {
        gameType: selected.type,
        drawNumber: drawNumber,
        events: eventChange,
        title: createBlog.title,
        comment: createBlog.comment,
        namedAuthor: createBlog.author,
        date: createBlog.date,
        drawChecksum:checksum,
        imageId:findImg
      }
      fetchCreate(sendObject)
    } else {
      return
    }
  };

  // Validate
  const validField = () =>{
    let valid = true;
    if(!createBlog.title){
      setError( prev =>({...prev,
        title:true,
      }))
      valid = false
    }
    if(!createBlog.author){
      setError( prev =>({...prev,
        author:true,
      }))
      valid = false
    }
    if(!selected.type){
      setError(prev =>({
        ...prev,
        selected:true
      }))
      valid = false
    }
    window.scrollTo({top: 0, behavior: 'smooth'})
    return valid
   /* if(){

    }*/
  }

  //Fetch send
  const fetchCreate = async (data={}) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    //await setIsLoader(true)
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/blog`,{
      method:'POST',
      headers:headers,
      body:JSON.stringify(data)
    })

    let errorMessage = await response.json()
    if(response.ok){
      setShowExitPrompt(false)
      // if create blog then redirect to blog page
      let getType = errorMessage.created[0].gameType
      let getId = errorMessage.created[0]._id
      history.push({
        pathname: `blog/${getType}/${getId}`,
        state:{id:getId}
      })
    }
    return {response,error:errorMessage};
  }
  // Fetch patch
  const patchCreate = async (data={},id) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    //await setIsLoader(true)
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/blog/${id}`,{
      method:'PATCH',
      headers:headers,
      body:JSON.stringify(data)
    })

    let errorMessage = await response.json()
    if(response.ok){
      setShowExitPrompt(false)
      // if create blog then redirect to blog page
      let getType = errorMessage.updated.gameType
      let getId = errorMessage.updated._id
      history.push({
        pathname: `blog/${getType}/${getId}`,
        state:{id:getId}
      })
    }
    return {response,error:errorMessage};
  }
  // Use effect to attach leave page
  const [showExitPrompt, setShowExitPrompt] = useState(true);

  useEffect(()=>{
      window.addEventListener('onbeforeunload',preventClose)
    return ()=>{
      window.removeEventListener('onbeforeunload',preventClose)
    }
  },[])
  const preventClose = (event) => {
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
      e.returnValue = ''; // Legacy method for cross browser support
    }
    return ''; // Legacy method for cross browser support
  };
  // handle for button cansel
  const handleRedirect = () =>{
    history.push('admin')
  }
  //handle for checkbox
    // state for checkbox
  const [checkbox, setCheckbox] = useState(null)

  const handleCheckBox = (e,imageId) =>{
    let updateCheckbox = checkbox?.images.map(item =>{
      if(item.id === imageId){
        item.checked = true
        return item
      } else{
        item.checked = false
        return item
      }
    })
    setCheckbox(prev =>({...prev,images:updateCheckbox}))
  }

  const fetchBlog = async (id) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };
    //await setIsLoader(true)
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/blog/${id}`,{
      method:'GET',
      headers:headers,
      // body:JSON.stringify(data)
    })

    let errorMessage = await response.json()
    if(response.ok){
      setHistoryLoad(errorMessage)
      setCreateBlog({
        title:errorMessage.title,
        date:parseISO(errorMessage.date),
        comment:errorMessage.comment,
        author:errorMessage.namedAuthor
      })
    }
    return {response,error:errorMessage};
  }

  useEffect(()=>{
    if(username && !historyLoad){
      setCreateBlog(prev=>({...prev,author: username}))
    }
  },[username])

  useEffect(async()=>{
    if(location?.state?.id){
     await fetchBlog(location?.state?.id);
      setError(prev=>({
        ...prev,
        selected:null
      }))
      setIsOPenSelect(false)
    }
  },[location?.state?.id])
  useEffect(()=>{
      if(historyLoad){
        const imagesForChoose = gameImage.find(game => game.gameType === historyLoad.gameType)
        imagesForChoose?.images?.map(img =>{
          if(img?.id === historyLoad.imageId)  img.checked=true
          else img.checked = false
        })
        setCheckbox(imagesForChoose)
        let getImg
        let copyObj = JSON.stringify(historyLoad)
        let changeObj = JSON.parse(copyObj)
        changeObj.draws = [{...changeObj.draw}]
        let date = getActiveDate(changeObj,0);
        if(historyLoad.gameType == 'powerplay' ) getImg = 3
        else if(historyLoad.gameType == 'topptipset') getImg = 2
        else if(historyLoad.gameType == 'europatipset') getImg = 1
        else if(historyLoad.gameType == 'stryktipset' ) getImg = 0
        setSelected({draw:historyLoad.draw,
          img:image[getImg],
          info:date,
          type:historyLoad.gameType,
          gameType:historyLoad.draw.productName,
          openTime:historyLoad.draw.openTime })
        let eventChange = historyLoad.events.map(event =>{
          return {
            comment:event.comment,
            forecast:event.forecast,
            eventNumber:event.id
          }
        })
        let nativeEvent = historyLoad.events.map(event =>{
          return event.event
        })
        setEventChange(eventChange)
        setEvent({events:nativeEvent})
      }
  },[historyLoad])
  return (
    <div>
      <Prompt
        when={showExitPrompt}
        message='You have unsaved changes, are you sure you want to leave?'
      />
      <HomeHeader />
      <InnerContainer>
        <CreateContainer>
          <div className='head'>
            <h2>Nytt inlägg</h2>
            <button onClick={handleSubmit} className='save button'>SPARA & PUPLICERA</button>
          </div>
          <div className='row row--size'>
            <div ref={ref} className='custom-dropdow'>
              <div onClick={ historyLoad ? ()=>{} : handleOpen}
                   className={`custom-selected ${handleColor(selected.type)} 
                   ${selected.type === '' ? 'custom-selected-default' : ''} ${error.selected ? 'input-error' : ''}`}>
                <span className='open'>
                  <img className='open-img' style={{transform:isOpenSelect ? 'rotate(270deg)' : 'rotate(90deg)'}}
                       src={arrow}/>
                </span>
                <li className="custom-item">
                  <img  src={selected.img} className={`img-logos ${selected.type === 'powerplay' ? "img-logo-pow" : ""}`}/>
                  <span> {selected.type !== "" ?
                    <span className='custom-span'>SPELSTOPP </span>
                    : <span style={{fontSize:'1.4rem'}} className='span-default'>Välj omgång</span>}{selected.info}</span>
                </li>
              </div>
              { isOpenSelect &&
              <div className='custom-list'>
                <li className='nav-text stryktipset'>
                  <img className="img-logos" src={stryktipsetLogo} alt='Stryktipset' />
                  <span> Om Spelet</span>
                </li>
                {!stryktipset?.draws.length ? null : (

                  stryktipset?.draws.map((item,idx)=><li className='nav-text stryktipset visible' onClick={(e)=>handleSelect(e,'stryktipset',item?.drawNumber,idx)}>
                    <span className='custom-span'>Spelstopp</span>
                    <span>{getActiveDate(stryktipset, 0)}</span>
                  </li>)
                )}
                <li className='nav-text europatipset' >
                  <img className="img-logos" src={europatipsetLogo} alt='Europatipset' />
                  <span> Om Spelet</span>
                </li>
                {!europatipset?.draws.length ? null : (
                  europatipset.draws.map((item,idx)=><li className='nav-text europatipset visible' onClick={(e)=>handleSelect(e,'europatipset',item?.drawNumber,idx)}>
                    <span className='custom-span'>Spelstopp</span>
                    <span>{getActiveDate(europatipset, 0)}</span>
                  </li>)
                )}
                <li className='nav-text topptipset'>
                  <img className="img-logos" src={topptipsetLogo} alt='Topptipset' />
                  <span> Om Spelet</span>
                </li>
                {!topptipset?.draws.length
                  ? null
                  : topptipset?.draws?.sort(sortDates).map((draw, idx) => {
                    //console.log('draw', draw)
                    return (
                      <li className='nav-text topptipset visible' key={`nav-tt${idx}`} onClick={(e)=>handleSelect(e,'topptipset',draw?.drawNumber,idx)}>
                        <span className='custom-span'>Spelstopp</span>
                        <span>{getActiveDate(topptipset, idx)}</span>
                      </li>
                    )
                  })}
                <li className='nav-text powerplay'>
                  <img className="img-logos" src={powerplayLogo} alt='PowerPlay' />
                  <span> Om Spelet</span>
                </li>
                {!powerplay?.draws.length
                  ? null
                  : powerplay?.draws?.map((draw, idx) => {
                    return (
                      <li className='nav-text powerplay visible' key={`nav-pp${idx}`} onClick={(e)=>handleSelect(e,'powerplay',draw?.drawNumber,idx)}>
                        <span className="custom-span">Spelstopp</span>
                        <span>{getActiveDate(powerplay, idx)}</span>
                      </li>
                    )
                  })}
              </div>}
              {error?.selected && (
                <p className='info-error custom-error'>Obligatoriskt fält</p>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='checked-container'>
              {checkbox?.images?.map(image =>(
                <div className='checked-item'>
                  <CustomChekbox
                    checked={image?.checked}
                    onChange={handleCheckBox}
                    title='Image1'
                    imageId={image?.id}
                  />
                  <img src={image.src}/>
                </div>
              ))}

            </div>

          </div>
          <div className='row'>
            <label htmlFor='title'>Titel</label>
            <input
              name='title'
              id='title'
              className={`${error?.title ? 'input-error' : ""}`}
              placeholder='Titel'
              value={createBlog.title}
              onChange={handleChange}
              maxLength={80}
            />
            <span className='counter'>{createBlog.title.length} /80</span>
            {error?.title && <span className='info-error-input'>Obligatoriskt fält</span>}
          </div>
          <div className='row'>
            <label htmlFor='comment'>Beskrivning</label>
            <textarea
              name='comment'
              id='comment'
              placeholder='Beskrivning'
              value={createBlog.comment}
              onChange={handleChange}
              maxLength={500}
            />
            <span className='counter'>{createBlog.comment.length} /500</span>
          </div>
          <div className='row row--space'>
            <div className='row-div'>
              <label htmlFor='date'>Datum</label>
              <DatePicker
                selected={createBlog.date}
                placeholderText='Datum'
                onChange = {handleChangeDate}
                dateFormat="dd/MM/yyyy "
              />
            </div>
            <div  className='row-div'>
              <label htmlFor = 'author'>Författare</label>
              <input
                placeholder='Författare'
                className={`${error?.author ? 'input-error' : ""}`}
                id='author'
                name ='author'
                value={createBlog.author}
                onChange={handleChange}
                maxLength={255}
              />
              <span className='counter'>{createBlog.author.length} /255</span>
              {error?.author && <span className='info-error-input'>Obligatoriskt fält</span>}
            </div>
          </div>
          <div className='category'>
            {
              (event?.events?.length) && (
                event?.events?.map((game,index) => (<div className='item'>
                  <div key={index} className='item-number'>
                    <span>{game.eventNumber}</span>
                  </div>
                  <div className='item-main'>
                    <p className='bold'>Title</p>
                    <div className='item-title'>
                      <p className='title'>{game.description}</p>
                      <div className='bold checked'>Din rad:
                        <div>
                          <span className='type'>1</span>
                          <span
                            onClick={(e)=>handleCheck(e,game.eventNumber,0)}
                            className={`percent ${eventChange[index].forecast[0] ? 'active' : ''}`}>
                            {game.distribution?.home}%
                          </span>
                        </div>
                        <div>
                          <span className='type'>X</span>
                          <span
                            onClick={(e)=>handleCheck(e,game.eventNumber,1)}
                            className={`percent ${eventChange[index].forecast[1] ? 'active' : ''}`}>
                            {game.distribution?.draw}%
                          </span>
                        </div>
                        <div>
                          <span className='type'>2</span>
                          <span
                            onClick={(e)=>handleCheck(e,game.eventNumber,2)}
                            className={`percent ${eventChange[index].forecast[2] ? 'active' : ''}`}>
                             {game.distribution?.away}%
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='row'>
                        <label htmlFor='Comment'>Comment</label>
                        <textarea
                          name='comment'
                          id='Comment'
                          placeholder='Comment'
                          value={eventChange[index].comment}
                          onChange={(e)=>handleChangeEvent(e,game.eventNumber)}
                          maxLength={500}
                        />
                        <span className='counter'>{eventChange[index].comment.length} /500</span>
                      </div>
                    </div>
                  </div>
                </div>))
              )
            }

          </div>
          <div className='row row--default '>
            <button onClick={handleSubmit} className='save button'>SPARA & PUPLICERA</button>
            <button className='button' onClick={handleRedirect}>AVBRYT</button>
          </div>
        </CreateContainer>

      </InnerContainer>
    </div>
  )
}

export default CreateBlog
import React from 'react'
import { WinnerTeamItem, WinTeamContainer } from './WinTeam.styles'
import { Option, Select } from '../Game/CouponSettings/RowStake/RowStake.elements'

const WinTeam = ({data,area,isActive,min,max,possible,selectTeam,selectMinMax}) => {

  const renderOption = (possible,type) =>{
    //if(!isActive) return
    let options = [];
    let start = type === 'max' ? max : min;
    for (let i = 0;possible+1 > i;i++){
      if(type === 'min' && (i > max) ){
        continue
      }else if(type === 'max' && (min > i)){
        continue
      }
      options.push(i);
    }
    return options
  }
  return (
    <WinTeamContainer area={area} active={!isActive}>
      <div className='head'>
        <p className='info'>Antal vinnande lag</p>
        <div className='color-wrapper'>
          <div className='color'>
            <span className='color-size'>Min</span>
            <Select
              onChange={(e)=>selectMinMax(e,'min')}
              isActive={isActive}
              disabled={!isActive}
              value = {min}
            >
              {possible > 0 ?
                renderOption(possible,'min')?.map((item,index) =>{
                  return <Option value={item}>{item}</Option>
                })
                :  <Option value='0'>0</Option>}
            </Select>
          </div>
          <div className='color'>
            <span className='color-size'>Max</span>
            <Select
              onChange={(e)=>selectMinMax(e,'max')}
              isActive={isActive}
              disabled={!isActive}
              value = {max}
            >
              {possible > 0 ?
                renderOption(possible,'max')?.map((item,index) =>{
                  return <Option value={item}>{item}</Option>
                })
                :<Option value='0'>0</Option>}
            </Select>
          </div>
        </div>
      </div>
      <div className='body'>
          {
            data?.length > 0
              ?
              <>
              {data.map((li,index)=>{
                return (
                  <div className='winner'>
                    <WinnerTeamItem
                      disabled={!isActive}
                      active ={li === 'first'}
                      onClick={()=>selectTeam(index,'first')}
                    >
                      <span>H</span>
                      <span>{index+1}</span>
                    </WinnerTeamItem>
                    <WinnerTeamItem
                      disabled={!isActive}
                      active ={li === 'x'}
                      onClick={()=>selectTeam(index,'x')}
                    >
                      <span>X</span>
                    </WinnerTeamItem>
                    <WinnerTeamItem
                      disabled={!isActive}
                      active ={li === 'second'}
                      onClick={()=>selectTeam(index,'second')}
                    >
                      <span>B</span>
                      <span>{index+1}</span>
                    </WinnerTeamItem>
                  </div>
                )
              })}
                {data.length === 3 && <div className='winner winner__no_result'>no Match</div>}
              </>
              :
              'No Data'
          }
      </div>
    </WinTeamContainer>
  )
}

export default WinTeam
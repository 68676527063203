import React, { useState, useEffect, useContext } from 'react'
import { useParams, useLocation, Redirect } from 'react-router-dom'
import GamePage from './GamePage'
import LiveResultPage from './LiveResultPage'

import { LiveResultContext } from '../context/liveResultContext'
import { ActiveGamesContext } from '../context/activeGamesContext'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { SocketContext, socket } from './../context/socket'
import { GameContext } from './../context/gameContext'
import Dividend from '../components/LiveResult/ResultWidgets/Dividend/Dividend'
import LiveResult from '../components/LiveResult/LiveResult'

export default function CouponLoader() {
  const { id } = useParams()

  const [whereToSend, setWhereToSend] = useState(null)
  const [loadedState, setLoadedState] = useState(null)

  let activeGames = useContext(ActiveGamesContext)
  let liveGames = useContext(LiveResultContext)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/coupon/${id}`)
      .then(response => {
        if (response.status === 404) {
          throw new Error('Not found')
        }
        return response.json()
      })
      .then(data => {
        setLoadedState({
          gameType: data.data.gameType,
          drawNumber: data.data.drawNumber,
          id: data.id,
          initialViewState: data.data
        })
        if (
          Object.keys(activeGames).some(key => {
            return activeGames[key].draws.some(draw => {
              return draw.drawNumber === data.data.drawNumber
            })
          })
        ) {
          setWhereToSend('reducera')
          //console.log('whereToSend', whereToSend)
        } else if (
          Object.keys(liveGames).some(key => {
            return liveGames[key].draws.some(draw => {
              return draw.drawNumber === data.data.drawNumber
            })
          })
        ) {
          setWhereToSend('liveresults')
          //console.log('whereToSend', whereToSend)
        }
      })
      .catch(error => {
        console.log('error', error)
        console.error(error)
        setWhereToSend('home')
      })
  }, [activeGames, liveGames])

  const renderSwitch = param => {
    switch (param) {
      case 'home':
        return <Redirect to='/' />
      case 'reducera':
        return (
          <SocketContext.Provider value={socket}>
            <GameContext.Provider value={loadedState}>
              {
                <GamePage
                  initialViewState={loadedState.initialViewState.viewState}
                  initialId={loadedState.id}
                />
              }
            </GameContext.Provider>
          </SocketContext.Provider>
        )
      case 'liveresults':
        return (
          <SocketContext.Provider value={socket}>
            <GameContext.Provider value={loadedState}>
              {
                <LiveResultPage
                  loadedData={loadedState.initialViewState}
                  id={loadedState.id}
                />
              }
            </GameContext.Provider>
          </SocketContext.Provider>
        )
      default:
        return <Redirect to='/' />
    }
  }

  return whereToSend ? renderSwitch(whereToSend) : null
}

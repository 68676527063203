import styled from 'styled-components'
import { colors, font, breakPoints, spacers } from '../../../../styles/constants'

import { ToolsHeading } from '../../../../styles/globalStyle'

export const SliderContainer = styled.div`
  position: relative;
  ${({ isModalOpen }) => (isModalOpen ? `width: 32rem;` : `width: 100%;`)}
  // padding: 1.5rem 2rem 0;

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    width: 100%;
    max-width: 32rem;
  }

  .swiper-slide {
    opacity: 1;
    transition: opacity 0.3s 0.15s ease-out;

    &:not([class*='-active']) {
      opacity: 0;
      transition: opacity 0.1s 0s ease-out;
    }
  }
`

export const DividendLastGameContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const DividendSignWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
`

export const DividendSignBox = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  margin-top: 0.5rem;

  .total {
    font-weight: ${font.weight.semiBold};
    font-size: ${font.size.medium};
    text-align: left;
    line-height: 1;
    color: ${colors.white};
  }
`

export const DividendAmountWrapper = styled.div`
  width: 100%;
  margin-left: 20px;
`

export const DividendLastGameHeading = styled(ToolsHeading)`
  font-size: 1.4rem;
  margin-right: 5rem;
  display: inline-block;
  line-height: 2;

  @media screen and (max-width: ${breakPoints.mediumMax}) {
    font-size: 1.2rem;
  }
`

export const DividendLastGameData = styled.div`
  display: flex;
  height: 1.5rem;
  text-align: right;

  p {
    font-size: ${font.size.medium};
    font-weight: ${font.weight.light};

    &:nth-child(1) {
      flex-basis: 70%;
    }

    &:nth-child(2) {
      flex-basis: 80%;
    }

    &:nth-child(3) {
      flex-basis: 100%;
    }

    @media screen and (max-width: ${breakPoints.mediumMax}) {
      font-size: 1.2rem;
      padding-left: 0.5rem;
    }

    ${({ hasWinners }) =>
      hasWinners
        ? `
      font-weight: ${font.weight.bold};
      color: ${colors.white};
    `
        : `color: ${colors.lightGrey};`};

    &.total {
      flex: 1;
      text-align: right;
      font-weight: ${font.weight.bold};
      font-size: ${font.size.large};
      line-height: 1;

      ${({ hasWinners }) =>
        hasWinners
          ? `
        color: ${colors.white};
      `
          : null};
    }
  }

  .popup-content & {
    height: 1.2rem;

    p {
      font-size: 1rem;
    }
  }
`

export const SignBG = styled.div`
  // position: absolute;
  // top: 0;
  // left: 0;
  font-size: 4rem;
  line-height: 1;
  font-weight: ${font.weight.bold};
  opacity: 0.5;
  margin-top: -0.75rem;
`

export const SliderControls = styled.button`
  // position: absolute;
  // top: 50%;
  // height: 100%;
  width: 2rem;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: ${colors.lightGrey};

  &.swiper-prev:after,
  &.swiper-next:after {
    font-family: Arial;

    font-size: 4rem;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }

  &.swiper-next {
    right: -2.5rem;
    left: auto;

    &:after {
      content: '›';
    }
  }

  &.swiper-prev {
    right: auto;
    left: -2.5rem;

    &:after {
      content: '‹';
    }
  }
`

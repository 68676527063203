import React from 'react'
import { EmptyContent } from './style'
import empty from '../../assets/empty.png'
import logoMark from '../../assets/reducering-logomark.svg'
import wordMark from '../../assets/reducering-wordmark.svg'
const EmptyPage = () => {
  return (
    <EmptyContent>
      <div className='head'>
        <div className='content'>
          <img className='reducering-logomark' src={logoMark} alt='Reducering Logomark' />
          <img className='reducering-wordmark' src={wordMark} alt='Reducering Wordmark' />
        </div>
      </div>
      <div className='body'>
        <img alt='empty' src={empty} className='image'/>
        <div className='info'>
          <h1 className='title'>Reducering.se
            <br></br>
            ligger (tillfälligt)
            <br></br>
            nere för räkning
          </h1>
          <p className='subtitle'>
            Sajten kanske bara är lite utmattad efter alla rätt som delats ut
            senaste tiden, men förmodligen är det något som byggs om eller uppdateras
            för att göra verktyget och övriga funktioner ännu bättre.
            Snart är Reducering.se uppe på benen igen och fungerar som den ska!
          </p>
        </div>
      </div>
    </EmptyContent>
  )
}

export default EmptyPage
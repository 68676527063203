import styled, { css } from 'styled-components'

export const BombenColorsContainer = styled.div`
  padding: 1.5rem 1.5rem 2.2rem;
  border-radius: 4px;
  background: linear-gradient(90deg, #596E79 0.7%, #596E79 31.88%, #36474F 63.06%, #36474F 100%);
  .color{
    margin-bottom: 10px;
  }
  .color-inputs{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    grid-gap: 1rem;
    &-wrap{
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 1rem;
    }
  }
  .color-size{
  color: #FFFFFF;
  opacity: 0.5;
  font-size: 1.2rem;
  line-height: 1.4rem;
  }
  select{
  cursor: pointer;
  width: 4.5rem;
  height: 2rem;
  padding: 0;
  text-indent: 0.25rem;
  }
  ${(props)=>props.area && css `
    grid-area: ${props.area};
  `}
  ${(props)=>props.isActive && css `
    * {
      cursor: not-allowed;
    }
    select{
    cursor: not-allowed;
    }
  `}
`
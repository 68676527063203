import React, { useEffect, useState, useContext } from 'react'
import Tooltip from 'rc-tooltip'
import { TrendsContainer, Button } from './Trends.elements'
import { ToolsHeading, ToolsText } from '../../../../styles/globalStyle'
import HorizontalSlider from './HorizontalSlider'

import { ReactComponent as ResetTrendIcon } from '../../../../assets/reset-coupon.svg'
import { ReactComponent as UpdateTrendIcon } from '../../../../assets/update-tool.svg'
import { ReactComponent as LoadingTrendIcon } from '../../../../assets/load-tool.svg'

import { GameContext } from '../../../../context/gameContext'

import ErrorPopup from '../../../Popup/ErrorPopup'

export default function Trends({ trendsData: { setTrendData, closingTime, trendData }, topptippsify ,isBomben,update }) {
  const { gameType, drawNumber } = useContext(GameContext)
  let trendStartS = new Date()
  let trendStart = trendStartS.setHours(0, 0, 0, 0).valueOf()
  // console.log('trendStartS ', trendStartS, '  trendStart', trendStart)

  let trendEndS = new Date(closingTime)
  let trendEnd = trendEndS.valueOf()
  //console.log('trendEndS ', trendEndS, '  trendEnd', trendEnd)

  let trendOpen = trendEndS.setHours(0, 0, 0, 0).valueOf()
  //console.log('trendOpen', trendOpen)

  const [timeFrom, setTimeFrom] = useState(trendStart)
  const [timeTo, setTimeTo] = useState(trendEnd)
  const [toCurrentTime, setToCurrentTime] = useState(new Date().valueOf())
  const [isActive, setIsActive] = useState(false)
  const [isFetchingTrends, setIsFetchingTrends] = useState(false)

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupContent, setPopupContent] = useState("")
  //console.log('toCurrentTime', toCurrentTime)
  useEffect(() => {
    setIsActive(false)
    const interval = setInterval(() => {
      if (toCurrentTime >= trendEnd) {
        clearInterval(interval)
        setIsActive(false)
        return
      }
      setToCurrentTime(prevState => (prevState = new Date().valueOf()))
    }, 60 * 1000)
    if (toCurrentTime > trendOpen && toCurrentTime < trendEnd) {
      setIsActive(true)
    }
    return () => clearInterval(interval)
  }, [toCurrentTime,update])

  const onAfterChange = ev => {
    // setState after change
    setTimeFrom(ev[0])
    setTimeTo(ev[1])
    //fetchTrends() // include for auto updated values
  }
  //console.log('isActive2', isActive)
  const fetchTrends = () => {
    //console.log('timeFron', timeFrom, 'timeTo', timeTo)

    setIsFetchingTrends(true)

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/${topptippsify(gameType)}/${drawNumber}/trend?trendFrom=${timeFrom}&trendTo=${timeTo}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => {
        return response.json()
      })
      .then(data => {
        //console.log('res data', data)

        // These errors only show up on later backends.
        if (data.error === "trendPercentLimitError") {
          setPopupContent("Omsättningen i vald intervall är för låg för att visa ett relevant resultat. Vänligen välj ett bredare intervall.")
          setIsPopupOpen(true)
        }

        if (data.error === "trendDataError") {
          setPopupContent("Ingen data i intervallet.")
          setIsPopupOpen(true)
        }

        if (data.trends) {
          setTrendData(data)
        }

        setIsFetchingTrends(false)
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  const mystyle = {
    flexDirection: 'column',
    display: 'flex'
  }

  const gameTurnoverWithSpace = gameTurnover =>
    gameTurnover?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return (
    <TrendsContainer isActive={isActive}>
      <ErrorPopup open={isPopupOpen} setErrorPopup= {setIsPopupOpen} errorPopupText={popupContent} />
      <div style={mystyle}>
        {!isActive ? (
          <ToolsHeading>Trender</ToolsHeading>
        ) : (
          <Tooltip
            prefixCls='rToolTip'
            placement='bottom'
            trigger={['hover']}
            animation='anim'
            overlay={
              <>
                <h3>Trender</h3>

                <p>
                  Hur ser trenderna ut för alla tecken? 
                  Se hur streck&shy;fördelningen fördelas på 1X2, från kl. 00:00 på kupongens startdag fram till spelstopp. 
                  Använd slidern för att ställa in tidsintervall och klicka på uppdaterings&shy;knappen. 
                  Se total omsättning för valt intervall och hur streck&shy;fördelningen för intervallet (i grönt och rött) 
                  ändras precis till höger om den totala streck&shy;fördelningen inne i kupongen. 
                  För att presentera ett så relevant värde som möjligt ligger intervallerna på minst 30 minuter, 
                  samt så måste omsättningen i intervallet uppgå till minst 3% av den totala omsättningen.
                </p>

                <p>
                  <strong>Grön pil upp</strong> = Streckprocenten för vald period är HÖGRE
                  än den totala streckfördelningen.
                </p>

                <p>
                  <strong>Röd pil ned</strong> = Streckprocenten för vald period är LÄGRE
                  än den totala streckfördelningen.
                </p>
              </>
            }
          >
            <ToolsHeading>
              Trender <span className='rToolTip-icon'>i</span>
            </ToolsHeading>
          </Tooltip>
        )}

        <ToolsText style={{ marginBottom: 0 }}>
          Omsättning vald period{'\u00A0'}
          {!isBomben &&
          <strong>
            {typeof trendData.turnover == 'undefined'
              ? ' ? '
              : gameTurnoverWithSpace(trendData?.turnover?.toString()) + ' '}
            kr
          </strong>}
        </ToolsText>
        {isBomben &&
        <ToolsText style={{ marginTop: '2px',marginBottom: 0 }}>
          <strong>
            {typeof trendData.turnover == 'undefined'
              ? ' ? '
              : gameTurnoverWithSpace(trendData?.turnover?.toString()) + ' '}
            kr
          </strong>
        </ToolsText>}
      </div>

      <Button
        className='resetTrend'
        disabled={trendData.trends === undefined}
        isActive={isActive}
        onClick={() => setTrendData({})}
      >
        <ResetTrendIcon />
      </Button>

      <Button onClick={fetchTrends} disabled={!isActive} isActive={isActive}>
        { isFetchingTrends ?
          <LoadingTrendIcon className='loading' />
        :
          <UpdateTrendIcon />
        }
      </Button>

      <HorizontalSlider
        timeFrom={timeFrom}
        toCurrentTime={Math.floor(toCurrentTime/(60 * 5 * 1000)) * (60 * 5 * 1000) }
        trendStart={trendStart}
        selectedTime={[timeFrom, timeTo]}
        onAfterChange={onAfterChange}
        key={toCurrentTime}
        isActive={isActive}
      />
    </TrendsContainer>
  )
}

import React from 'react'
import { InputNumberElem } from './InputNumber.styles'

const InputNumber = ({onChange, option, value, size,disable = false}) => {
  return (
    <InputNumberElem
      size={size}
      type='text'
      value={value}
      disabled={disable}
      onInput={ev => {
        if (typeof ev.target.prevValue === 'undefined') {
          ev.target.prevValue = ''
        }
        const test = value => /^\d*$/.test(value)
        if (!test(ev.target.value)) {
          ev.target.value = ev.target.prevValue
        } else {
          ev.target.prevValue = ev.target.value
        }
      }}
      onChange={(e)=>onChange(e,...option)}
    />

  )
}

export default InputNumber
import React, { useContext, useState, useEffect } from 'react'

import { LiveResultContext } from '../../context/liveResultContext'
import { ActiveGamesContext } from '../../context/activeGamesContext'

import { useAuth0 } from '@auth0/auth0-react'
import {useParams,useLocation} from 'react-router-dom'
import HomeHeader from '../../components/Headers/HomeHeader'
import { InnerContainer } from '../../styles/globalStyle'
import User from '../../components/User/User'
import Footer from '../../components/Footer/Footer'
import { startAffiliate } from '../../utils/helperFunctions'
import { AccountContext } from '../../context/accountContext'
import HomePageModal from './HomePageModal'
import Autorized from '../../components/Popup/Autorized'

export default function MyPage({setUserPhoto}) {
  const { isLoading, isAuthenticated, getAccessTokenSilently, user } = useAuth0()
  const {username} = useParams()
  const [team,setTeam] = useState(null)
  const [blog, setBlog] = useState(null)
  const [openEdit, setOpenEdit] = useState(false)
  const [publics,SetPublics] = useState(true)
  const [userNotFound, setUserNotFound] = useState(false);
  const [profile, setProfile] = useState({
    avatar:null,
    name:null,
    username:null,
    about:null,
    social:[],
    account:false,
    me:false
  })
  const [profile1, setProfile1] = useState({
    avatar:null,
    name:null,
    username:null,
    about:null,
    social:[],
    account:false,
    me:false
  })
  const [editError, setEditError] = useState({
    username:{message:null},
    name:{message:null},
    about:{message:null},
    social:[{message:null,type:'facebook'}
      ,{message:null,type:'instagram'}
      ,{message:null,type:'linkedin'}
      ,{message:null,type:'twitter'}],
  })
  const [userGames, setUserGames] = useState([])
  const [hideTeam,setHideTeam] = useState(true)
  const [updateCoupon, setUpdateCoupon] = useState(0)
  // State for loader
  const [teamLoad, setTeamLoad] = useState(false);
  const [blogLoad, setBlogLoad] = useState(false);
  const [infoLoad, setInfoLoad] = useState(false);
  const [couponLoad, setCouponLoad] = useState(false);
  const location = useLocation()
  let activeGames = useContext(ActiveGamesContext)
  let liveGames = useContext(LiveResultContext)
  let account = useContext(AccountContext)

  const getCoupons = async () => {
    await setCouponLoad(true)
    let token = isAuthenticated && !isLoading ? await getAccessTokenSilently() : null
    const headers =
      isAuthenticated && !isLoading ? { Authorization: `Bearer ${token}` } : {}

    await fetch(`${process.env.REACT_APP_API_ENDPOINT}/usercoupons/`, {
      headers: headers
    })
      .then(response => {
        if (response.status === 404) {
          throw new Error('Not found')
          setTimeout(()=>{
            setCouponLoad(false)
          },500)
        }
        if (response.status === 403) {
          throw new Error('Not Allowed')
          setTimeout(()=>{
            setCouponLoad(false)
          },500)
        }
        return response.json()
      })
      .then(data => {
        data.data.usercoupons.sort((a, b) => {
          if (new Date(a.closeTime) < new Date(b.closeTime)) {
            return -1;
          } else if (new Date(a.closeTime) > new Date(b.closeTime)) {
            return 1;
          }
          return 0;
        }).forEach(el => {
          let game = el.data.gameType

          el.ongoingStatus = 'inactive'

          let drawNumber = el.data.drawNumber

          let activeFound = activeGames[game].draws.find(
            val => val.drawNumber === parseInt(drawNumber)
          )
          let liveFound = liveGames[game].draws.find(
            val => val.drawNumber === parseInt(drawNumber)
          )

          el.closeTime = el.data.closeTime || "";

          if (activeFound) {
            el.ongoingStatus = 'upcoming'
            el.closeTime = activeFound.closeTime
          }
          if (liveFound) {
            el.ongoingStatus = 'ongoing'
            el.closeTime = liveFound.closeTime
          }
          el.data.status = el.data.status || "draft"
        })

        //console.log('User coupons:', data.data.usercoupons)

        setUserGames(data.data.usercoupons.sort((a, b) => {
          if (new Date(a.closeTime) < new Date(b.closeTime)) {
            return -1;
          } else if (new Date(a.closeTime) > new Date(b.closeTime)) {
            return 1;
          }
          return 0;
        }))

        setTimeout(()=>{
          setCouponLoad(false)
        },500)
      })
      .catch(error => {
        console.error(error)
      })
  }
  const getCouponsUser = async () => {
    let token = isAuthenticated && !isLoading ? await getAccessTokenSilently() : null
    await setCouponLoad(true)
    const headers =
      isAuthenticated && !isLoading ? { Authorization: `Bearer ${token}` } : {}

    await fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/${username}/coupons`, {
      headers: headers
    })
      .then(response => {
        if (response.status === 404) {
          throw new Error('Not found')
          setTimeout(()=>{
            setCouponLoad(false)
          },500)
        }
        if (response.status === 403) {
          setTimeout(()=>{
            setCouponLoad(false)
          },500)
          SetPublics(false)
          return 'err'
        }
        return response.json()
      })
      .then(data => {
        setUpdateCoupon(1)
        setTimeout(()=>{
          setCouponLoad(false)
        },500)
        if(data === 'err'){
          return
        }
        data?.data?.usercoupons?.sort((a, b) => {
          if (new Date(a.closeTime) < new Date(b.closeTime)) {
            return -1;
          } else if (new Date(a.closeTime) > new Date(b.closeTime)) {
            return 1;
          }
          return 0;
        }).forEach(el => {
          let game = el.data.gameType
          el.ongoingStatus = 'inactive'

          let drawNumber = el.data.drawNumber
          let activeFound = activeGames[game]?.draws?.find(
            val => val.drawNumber === parseInt(drawNumber)
          )
          let liveFound = liveGames[game]?.draws?.find(
            val => val.drawNumber === parseInt(drawNumber)
          )

          el.closeTime = el.data.closeTime || "";

          if (activeFound) {
            el.ongoingStatus = 'upcoming'
            el.closeTime = activeFound.closeTime
          }
          if (liveFound) {
            el.ongoingStatus = 'ongoing'
            el.closeTime = liveFound.closeTime
          }
          el.data.status = el.data.status || "draft"
        })

        //console.log('User coupons:', data.data.usercoupons)
        setUserGames(data?.data?.usercoupons?.sort((a, b) => {
          if (new Date(a.closeTime) < new Date(b.closeTime)) {
            return -1;
          } else if (new Date(a.closeTime) > new Date(b.closeTime)) {
            return 1;
          }
          return 0;
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(()=>{
    if(!isLoading){
      fetchBlog()
      if(username){
        //getCoupons()
        fetchUserProfile()
        fetchUserPhoto()
      }
    }

  },[username,isLoading])
  const handleDelete = async (id, isAuthenticated) => {
    //console.log('trying to delete id ', id)
    let headers = {
      'Content-Type': 'application/json'
    }
    if (isAuthenticated) {
      let token = await getAccessTokenSilently()
      headers['Authorization'] = `Bearer ${token}`
    }

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/coupon/${id}`, {
      method: 'DELETE',
      headers: headers
    })
      .then(response => {
        if (response.status === 403) {
          throw new Error('Not allowed')
        }
        if (response.status === 404) {
          throw new Error('Not found')
        }
        return response.json()
      })
      .then(data => {
        //console.log('response: ', data)
        getCoupons()
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }
  //Get team
  const fetchTeam = async (params) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    await setTeamLoad(true)
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers = {}
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/teams?page=1&perPage=3${params}`,{
      headers: headers
    }).then(res =>{
      return res.json()
    }).then(data=>{
      setTeam(data?.teams)
      setTimeout(()=>{
        startAffiliate()
      },1000)
      setTimeout(()=>{
        setTeamLoad(false)
      },500)
    })
  };
  // Get Blog
  const fetchBlog = async () =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    await setBlogLoad(true)
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers = {}
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/blog?page=1&perPage=3`,{
      headers: headers
    }).then(res =>{
      return res.json()
    }).then(data=>{
      setBlog(data?.blogs)
      setTimeout(()=>{
        setBlogLoad(false)
      },500)
    })
  }
  //Get userInfo
  const fetchUserProfile = async () =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    await setInfoLoad(true)
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers = {}
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/${username}/info`,{
      headers: headers
    }).then(res =>{
      if(!res.ok) {
        if(res.status === 404){
          setUserNotFound(true)
        }
        return
      }
      return res.json()
    }).then(data=>{
      setProfile({
        avatar:null,
        name:data?.info?.name,
        username:data?.info?.username,
        about:data?.info?.bio,
        social:[
          {link:data?.info?.facebook,type:'facebook'}
          ,{link:data?.info?.instagram,type:'instagram'}
          ,{link:data?.info?.linkedin,type:'linkedin'}
          ,{link:data?.info?.twitter,type:'twitter'}
          ],
        account:data?.info?.publicProfile,
        me:data?.me
      })
      setProfile1({
        avatar:null,
        name:data?.info?.name,
        username:data?.info?.username,
        about:data?.info?.bio,
        social:[
          {link:data?.info?.facebook,type:'facebook'}
          ,{link:data?.info?.instagram,type:'instagram'}
          ,{link:data?.info?.linkedin,type:'linkedin'}
          ,{link:data?.info?.twitter,type:'twitter'}
        ],
        account:data?.info?.publicProfile,
        me:data?.me
      })
      if(data?.me){
        getCoupons()
        fetchTeam('&me=1')
      } else {
        getCouponsUser()
        let params = `&userName=${username}`
        fetchTeam(params)
      }
      setTimeout(()=>{
        setInfoLoad(false)
      },500)
      //setTeam(data?.teams)
    })
  };
  const fetchUserPhoto = async () =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else {
      headers = {}
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/users/${username}/photo`,{
      headers: headers
    }).then(res => {
      if(res.ok) return res.blob()
      else return null
    }).then(data => {
      if(data == null) return
      let url = URL.createObjectURL(data)
      setProfile(prev => ({...prev,avatar:url}))
      setProfile1(prev => ({...prev,avatar:url}))
    })
  };
  useEffect(()=>{
    if(profile.avatar !== null && profile.me){
      setUserPhoto(profile.avatar)
    }
  },[profile.avatar,profile.me])
  const clearError = ()=>{
    setEditError({
      username:{message:null},
      name:{message:null},
      about:{message:null},
      social:[{message:null,type:'facebook'}
        ,{message:null,type:'instagram'}
        ,{message:null,type:'linkedin'}
        ,{message:null,type:'twitter'}],
    })
  }
  useEffect(()=>{
    if(userGames?.length >0){
      setTimeout(()=>{
        let update = userGames.sort((a, b) => {
          if (new Date(a.closeTime) < new Date(b.closeTime)) {
            return -1;
          } else if (new Date(a.closeTime) > new Date(b.closeTime)) {
            return 1;
          }
          return 0;
        }).map(el => {
          let game = el.data.gameType
          el.ongoingStatus = 'inactive'

          let drawNumber = el.data.drawNumber
          let activeFound = activeGames[game]?.draws?.find(
            val => val.drawNumber === parseInt(drawNumber)
          )
          let liveFound = liveGames[game]?.draws?.find(
            val => val.drawNumber === parseInt(drawNumber)
          )

          el.closeTime = el.data.closeTime || "";

          if (activeFound) {
            el.ongoingStatus = 'upcoming'
            el.closeTime = activeFound.closeTime
          }
          if (liveFound) {
            el.ongoingStatus = 'ongoing'
            el.closeTime = liveFound.closeTime
          }
          el.data.status = el.data.status || "draft"
          return el
        })
        setUserGames(update)
      },500)
    }
  },[updateCoupon,userGames?.length])
  useEffect(()=>{
    let state = location;
    let getState = state?.state?.isOpenProfile;
    if(getState){
      setOpenEdit(true)
    }
  },[location])
  return (
    <>
      <HomePageModal
        isModalOpen={openEdit}
        setIsModalOpen={setOpenEdit}
        profile={profile1}
        setProfile={setProfile1}
        origin = {profile}
        originProfile={setProfile}
        fetchUserPhoto={fetchUserPhoto}
        editError={editError}
        setEditError = {setEditError}
        clearError={clearError}
      />
      <HomeHeader />
      <User
        hideTeam={hideTeam}
        publics={publics}
        profile={profile}
        setIsModalOpen={setOpenEdit}
        userData={user}
        account={account}
        team = {team}
        blog = {blog}
        userGames={userGames}
        handleDelete={handleDelete}
        isAuthenticated={isAuthenticated}
        teamLoad={teamLoad}
        blogLoad={blogLoad}
        infoLoad={infoLoad}
        couponLoad={couponLoad}
        userNotFound={userNotFound}
        setUserNotFound={setUserNotFound}
      />
      <InnerContainer>
        <Footer />
      </InnerContainer>
    </>
  )
}

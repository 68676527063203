import { useContext, useState } from 'react'
import { ActiveGamesContext } from '../../context/activeGamesContext'
import { GamesGrid } from '../../styles/globalStyle'
import ActiveGamesPopup from '../../components/Popup/ActiveGamesPopup'
import ActiveGamesBox from './ActiveGamesBox'
import stryktipsetLogo from '../../assets/stryktipset-wordmark.svg'
import europatipsetLogo from '../../assets/europatipset-wordmark.svg'
import topptipsetLogo from '../../assets/topptipset-wordmark.svg'
import powerplayLogo from '../../assets/powerplay-wordmark.svg'
import bombenLogo from '../../assets/bomben-wordmark.svg'
import { colors } from '../../styles/constants'
import SimpleActiveGamesBox from './SimpleActiveGamesBox'

export default function ActiveGamesBoxes() {
  const { stryktipset, europatipset, topptipset, powerplay, bomben } =
    useContext(ActiveGamesContext)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState({})
  const filteredDraws = powerplay ? powerplay.draws.filter(draw => draw.drawNumber !== 3334) : [];
  const filteredDrawsLength = filteredDraws.length;

  return (
    <GamesGrid>
      <ActiveGamesPopup
        gameTypeData={modalData}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <ActiveGamesBox
        data={stryktipset}
        gameTypeAssets={{
          gameType: 'stryktipset',
          logo: stryktipsetLogo,
          color: colors.stryktipset,
          amountOfGames: stryktipset?.draws.length
        }}
        popupAssets={{ setModalData, setIsModalOpen, isModalOpen }}
      />
      <ActiveGamesBox
        data={europatipset}
        gameTypeAssets={{
          gameType: 'europatipset',
          logo: europatipsetLogo,
          color: colors.europatipset,
          amountOfGames: europatipset?.draws.length
        }}
        popupAssets={{ setModalData, setIsModalOpen, isModalOpen }}
      />
      <ActiveGamesBox
        data={bomben}
        gameTypeAssets={{
          gameType: 'bomben',
          logo: bombenLogo,
          color: colors.powerplay,
          amountOfGames: bomben?.draws.length
        }}
        popupAssets={{ setModalData, setIsModalOpen, isModalOpen }}
      />
      <ActiveGamesBox
        data={topptipset}
        gameTypeAssets={{
          gameType: 'topptipset',
          logo: topptipsetLogo,
          color: colors.topptipset,
          amountOfGames: topptipset?.draws.length
        }}
        popupAssets={{ setModalData, setIsModalOpen, isModalOpen }}
      />
        <ActiveGamesBox
            data={(() => {
                if (powerplay) {
                    return {
                        ...powerplay,
                        draws: filteredDraws,
                        amountOfGames: filteredDrawsLength
                    };
                }
                return powerplay;
            })()}
            gameTypeAssets={{
                gameType: 'powerplay',
                logo: powerplayLogo,
                color: colors.powerplay,
                amountOfGames: filteredDrawsLength
            }}
            popupAssets={{ setModalData, setIsModalOpen, isModalOpen }}
        />
      <SimpleActiveGamesBox/>
    </GamesGrid>
  )
}

import React from 'react'
import { ToolsText } from '../../../../styles/globalStyle'
import { GuardContainer } from './WholeHalfGuard.elements'

export default function WholeHalfGuard({viewState, isReduced}) {

  let helGuard = 0;
  let halvGuard = 0;
  if (isReduced) {
    helGuard = viewState.reduce((acc, val) => {
      return Object.keys(val).filter(sign => val[sign].signDistribution > 0).length === 3 ? acc + 1 : acc;
    }, 0)

    halvGuard = viewState.reduce((acc, val) => {
      return Object.keys(val).filter(sign => val[sign].signDistribution > 0).length === 2 ? acc + 1 : acc;
    }, 0)

  }

  return <>
    <GuardContainer>
      <ToolsText>
        Helgarderingar: <strong>{helGuard}</strong>
      </ToolsText>

      <ToolsText>
        Halvgarderingar: <strong>{halvGuard}</strong>
      </ToolsText>
    </GuardContainer>
  </>
}

import React, { useContext, useEffect, useState, useRef } from 'react'
import { InnerContainerLarge, ToolsHeading, ToolsSmallHeading } from '../../styles/globalStyle'
import { ReactComponent as LoadingLogo } from '../../assets/reducering-logomark.svg'
import { BombenContainer } from './Bomben.styles'
import Footer from '../../components/Footer/Footer'
import LoaderCoupon from '../../components/Bomben/LoaderCoupon/LoaderCoupon'
import BombenSelect from '../../components/Bomben/BombenSelect/BombenSelect'
import BoombenBig from '../../components/Bomben/BombenBig/BoombenBig'
import BombenButton from '../../components/Bomben/BombenButton/BombenButton'
import BombenGame from '../../components/Bomben/BombenGame/BombenGame'
import Tooltip from 'rc-tooltip'
import DividendForecast from '../../components/Game/CouponSettings/DividendForecast/DividendForecast'
import { DividedForecast } from '../../components/Game/CouponSettings/CouponSettings.elements'
import BombenColors from '../../components/Bomben/BombenColors/BombenColors'
import { socket } from '../../context/socket'
import { useParams, useLocation, Prompt } from 'react-router-dom'
import { ActiveGamesContext } from '../../context/activeGamesContext'
import BombenRow from '../../components/Bomben/BombenRow/BombenRow'
import BombenReduceraRow from '../../components/Bomben/BombenRow/BombenReduceraRow'
import { useAuth0 } from '@auth0/auth0-react'
import { LiveResultContext } from '../../context/liveResultContext'
import {BombenSvenska} from '../../components/Popup/BombenSvenska'
import { ReductionPopup } from '../../components/Popup/Popup.elements'
import ResetPopup from '../../components/Popup/ResetPopup'
import BombenModal from '../../components/Popup/BombenModal'
import GameTypeHeader from '../../components/GameTypeHeader/GameTypeHeader'
import { useHistory } from 'react-router'
import Trends from '../../components/Game/ReductionTools/Trends/Trends'
import { GameContext } from '../../context/gameContext'
import Autorized from '../../components/Popup/Autorized'
import WinTeam from '../../components/WinTeam/WinTeam'
import GameReducer from '../../utils/math'
import { createBombenChart } from '../../utils/bombenMath'

const Bomben = () => {
  const hist = useHistory()
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  let activeGames = useContext(ActiveGamesContext);
  let liveGames = useContext(LiveResultContext)
  const [isModalLogin, setIsModalLogin] = useState(false)
  const [svenskaModal, setSvenskaModal] = useState(false)
  const [data, setData] = useState(null);
  const [history, setHistory] = useState(false)
  const [modal,setModal] = useState(false);
  const [variant, setVariant] = useState('');
  const [messageForModal, setMessageForModal] = useState('')
  const [isCoast,setIsCoast] = useState(false)
  const historyRout = useHistory();
  const [color,setActiveColor] = useState({
    yellow:false,
    blue:false,
    red:false,
    green:false
  });
  const [reduceData, setReduceData] = useState(null)
  const [svenskaMatrix, setSvenskaMatrix] = useState(null)
  const [isCreateNew, setIsCreateNew] = useState(false)
  const [cancelUpdate, setCancelUpdate] = useState(false)
  const [isHardReset, setIsHardReset] = useState(false)
  const [trendData, setTrendData] = useState({})
  const [isRemoveState, setIsRemoveState] = useState(false)
  const [chartForecast, setChartForecast] = useState([])
  const [isSpelaTillsammans, setIsSpelaTillsammans] = useState(false)
  const isCanLoad = useRef(false)
  //State for winTeam component (winnerTotal)
  const [winnerTotal,setWinnerTotal] = useState({
    min:0,
    max:0,
    values:[],
    possibleValues:0,
    isTouch:{
      min:false,
      max:false
    },
    upload:false,
  })
  const [couponInfo, setCouponInfo] = useState({
    cloneCount:0,
    betRowAmount:1,
    currentId:'',
    currentNickname:'',
    saveStatus:undefined,
    isSave:false,
    totalMatches:false,
    totalMatchesLength:false,
    totalRader:0,
  })
  //Settings for colors
  const [colorMinMax, setColorMinMax] = useState(
    {
      yellow: {title:'yellow',yellowMin:0,yellowMax:0},
      blue:{ title:'blue',blueMin:0,blueMax:0},
      green:{title:'green',greenMin:0,greenMax:0},
      red:{title:'red',redMin:0,redMax:0},
    }
  )
  const [reduceState, setReduceState] = useState({
    matches:[],
    options: {
      total: {
        min: "",
        max: ""
      },
      totalCoast: {
        min: "",
        max: ""
      },
      flags: []
    }
  })
  const { drawNumber, id } = useParams();
  const gameType = 'bomben'
  // If not live another socket connect
  let loadedState = {
    gameType,
    drawNumber,
    couponId:couponInfo.currentId,
  }
  //Check if user not autorized
  useEffect(()=>{

    if(!isAuthenticated && !isLoading){
      setIsModalLogin(true)
    }
  },[isAuthenticated,isLoading])
  useEffect(()=>{
    if(!id){
      setHistory(false)
    }
  },[id])
  useEffect(() => {
    const isActive = activeGames[gameType]?.draws?.some((el) => {
      return el.drawNumber === parseInt(drawNumber);
    })
    const isLive = liveGames[gameType]?.draws?.some((el) => {
      return el.drawNumber === parseInt(drawNumber);
    })
    let historyCal = history;
    if (isActive || isLive) {
      setHistory(false);
      historyCal = false
    } else {
      historyCal = true
      setHistory(true);
    }
    if (process.env.REACT_APP_NO_BACKEND.toUpperCase() === 'TRUE') {
    } else if (!historyCal) {
      // As soon as component is mounted, do:
      // console.log("emitting JOIN_DATA_CHANNEL")
      socket.emit('JOIN_DATA_CHANNEL', { game: gameType, draw: drawNumber })
      socket.on('data', response => {
        setData(response)
      })
    } else {
      socket.emit('REQUEST_SPECIFIC', { game: gameType, draw: drawNumber })
      socket.on('data', response => {
        setData(response)
      })
    }
    return () => {
      // Before component is destroyed
      // Unbind all event handlers used in this component
      //console.log(`stop data from channel: ${gameType}/${drawNumber}`)
      socket.emit('LEAVE_DATA_CHANNEL', { game: gameType, draw: drawNumber })
      socket.off('data')
      socket.off('statistic')
    }
  }, [socket, gameType, drawNumber,activeGames,liveGames])
  //If we have draw number remove state(its mean when we navigate to another drow number bomben need refresh state page
  useEffect(()=>{
    if(drawNumber){
      setIsRemoveState(true)
    }
  },[drawNumber])
  useEffect(()=>{
    if(drawNumber != data?.drawNumber) setData(null)
  },[drawNumber,data])
  useEffect(async ()=>{
    if (!id) {
      return;
    }else if(reduceState?.matches?.length === 0){
      return
    }else if(cancelUpdate){
      return
    } else{
      await fetch(`${process.env.REACT_APP_API_ENDPOINT}/coupon/${id}`, {
        // headers: headers
      })
        .then(response => {
          if (response.status === 404) {
            throw new Error('Not found')
          }
          if (response.status === 403) {
            throw new Error('Not Allowed')
          }
          return response.json()
        })
        .then(data => {
          // check if the loaded coupon matches the game and dn of directed coupon
          if (!(data.data.gameType === gameType && data.data.drawNumber === drawNumber)) {
            //setRedirectHome(true)
            return;
          }
          updateWinnerTeam(data)
          updateState(data,reduceState)
          setCancelUpdate(true)
          isCanLoad.current = true
        })
        .catch(error => {
          console.error(error)
          //setRedirectHome(true)
        })
    }
  },[id,reduceState?.matches])
    //When we have totalRadec and id coupon
  useEffect(()=>{
      if(isCanLoad.current && (couponInfo.totalRader > 0) && id){
        // next tick the event loop
        setTimeout(()=>{
          isCanLoad.current = false;
          handleReduce()
        },100)
      }
  },[couponInfo?.totalRader])
  // If we here some data from socket or etc
  useEffect(()=>{
      if(data && data?.events?.length && (!reduceState?.matches?.length) ){
        let match = []
        let reduceEvent = data?.events?.forEach(event =>{
          let createTeams = [];
          event?.distribution?.forEach(dist =>{
            let toArr = dist?.score?.split('-')
            let getFirst = toArr[0]
            createTeams.push({
              score: getFirst,
              flags: [],
              coast: 0,
              isActive:false,
            })
          })
          match.push({
            firstTeam : createTeams,
            secondTeam : [...createTeams],
            firstDoesntWin:false,
            noDraw:false,
            secondDoesntWin:false,
            min:'',
            max:'',
          })
        })
        setReduceState(prev=>({...prev,matches: match}))
      }
  },[data])

  useEffect(()=>{
    if(isRemoveState){
      let match = []
      let reduceEvent = data?.events?.forEach(event =>{
        let createTeams = [];
        event?.distribution?.forEach(dist =>{
          let toArr = dist?.score?.split('-')
          let getFirst = toArr[0]
          createTeams.push({
            score: getFirst,
            flags: [],
            coast: 0,
            isActive:false,
          })
        })
        match.push({
          firstTeam : createTeams,
          secondTeam : [...createTeams],
          firstDoesntWin:false,
          noDraw:false,
          secondDoesntWin:false,
          min:'',
          max:'',
        })
      })
      setReduceState({matches: match,options: {
          total: {
            min: "",
            max: ""
          },
          totalCoast: {
            min: "",
            max: ""
          },
          flags: []
        }})
      setColorMinMax (
        {
          yellow: {title:'yellow',yellowMin:0,yellowMax:0},
          blue:{ title:'blue',blueMin:0,blueMax:0},
          green:{title:'green',greenMin:0,greenMax:0},
          red:{title:'red',redMin:0,redMax:0},
        })
      setIsCoast(false)
      setActiveColor({
        yellow:false,
        blue:false,
        red:false,
        green:false
      })
      setSvenskaMatrix(null)
      setTrendData({})
      setCouponInfo({
        cloneCount:0,
        betRowAmount:1,
        currentId:'',
        currentNickname:'',
        saveStatus:undefined,
        isSave:false,
        totalMatches:false,
        totalMatchesLength:false,
        totalRader:0,
      })
      setIsRemoveState(false)
    }
  },[isRemoveState])

  const changeBetRowAmount = (e,maxSize) =>{
    let value = e.target.value;
    if(value > +maxSize) return
    setCouponInfo(prev=>({...prev,betRowAmount:value}))
  }

  const updateState = (currentState,newState) =>{
    let getMatches = currentState.data.couponRawData;
    setCouponInfo(prev=>({
      ...prev,
      betRowAmount:currentState?.data?.betRowAmount,
      currentId:currentState.id,
      currentNickname:currentState.nickname,
      saveStatus:currentState?.data?.status,
    }))
    let option = getMatches?.options
    const getFromSocket = newState.matches?.map((match,index) =>{
      let load = getMatches?.matches[index];
      let firstTeam = match?.firstTeam?.map((first,index)=>{
        let isFind = load.firstTeam.find(loadTeam =>loadTeam.score == first.score.toString().toLowerCase());
        if(isFind){
          return  {
            coast: isFind?.coast || 0,
            flags: isFind?.flags || [],
            isActive: true,
            score: isFind.score.toString(),
          }
        }else{
          return first
        }
      })
      let secondTeam = match?.secondTeam?.map((first,index)=>{
        let isFind = load.secondTeam.find(loadTeam =>loadTeam.score == first.score.toString().toLowerCase());
        if(isFind){
          return  {
            coast: isFind?.coast || 0,
            flags: isFind?.flags || [],
            isActive: true,
            score: isFind.score.toString(),
          }
        }else{
          return first
        }
      })
      let game = {
        firstDoesntWin: load.firstDoesntWin,
        max: load?.max?.toString() || "",
        min: load?.min?.toString() || "",
        noDraw: load.noDraw,
        secondDoesntWin: load.secondDoesntWin,
        firstTeam,
        secondTeam
      }
     return game
    })
    setReduceState({
      matches:getFromSocket,
      options: {
        total: {
          min:(option?.total?.min?.toString()) ||  "",
          max:(option?.total?.max?.toString()) ||  ""
        },
        totalCoast: {
          min:(option?.totalCoast?.min?.toString()) || "",
          max:(option?.totalCoast?.max?.toString()) || ""
        },
        flags: []
      }
    })
    optionsForColor(getMatches?.options?.flags)

  }


  const getActiveColor = () =>{
    for (const flag in color){
      if(color[flag] === true){
        return flag
      }
    }
  }

  const existColor = (arratyColor,color,isActive) =>{
    if (!color) return false
    let find = arratyColor?.find(arrColl => arrColl === color);
    if(!find){
      let isAddNewColor = isActive ? [color] : []
      return [...arratyColor,...isAddNewColor]
    }else{
      let filter = isActive ? arratyColor?.filter(colors => colors !== color) : arratyColor
      return [...filter]
    }
  }
  // When change state i invoke this function for iterate color state
  // Need to refactor
  const optionsForColor = (flag) => {
    let prepareObj = {
      yellow:{
        title:'yellow',
        yellowMin:colorMinMax?.yellow?.yellowMin || 0,
        yellowMax:colorMinMax?.yellow?.yellowMax || 0,
        isSelect:colorMinMax?.yellow?.isSelect || false ,
        yellowLeng:0},
      blue:{
        title:'blue',
        blueMin:colorMinMax?.blue?.blueMin || 0,
        blueMax:colorMinMax?.blue?.blueMax || 0,
        isSelect:colorMinMax?.blue?.isSelect || false ,
        blueLeng:0},
      green:{
        title:'green',
        greenMin:colorMinMax?.green?.greenMin || 0,
        greenMax:colorMinMax?.green?.greenMax || 0,
        isSelect:colorMinMax?.green?.isSelect || false ,
        greenLeng:0},
      red:{
        title:'red',
        redMin:colorMinMax?.red?.redMin,
        redMax:colorMinMax?.red?.redMax,
        isSelect:colorMinMax?.red?.isSelect || false ,
        redLeng:0},
    }
    let teamWithFlags = [];
    let totalRader = []
    let match = reduceState?.matches.forEach((item)=>{
      let firstTeamWithFlags = item?.firstTeam.filter(team => team?.flags?.length && team.isActive);
      let secondTeamWithFlags = item?.secondTeam.filter(team => team?.flags?.length && team.isActive);
      let isActive = item?.firstTeam.filter(team=>team.isActive)
      let isActiveSecond = item?.secondTeam.filter(team=>team.isActive)
      totalRader.push(isActive.length)
      totalRader.push(isActiveSecond.length)
      let objColors = {}
      if(firstTeamWithFlags.length){
        let blue = firstTeamWithFlags.filter(team=>team.flags.includes('blue'))
        let yellow = firstTeamWithFlags.filter(team=>team.flags.includes('yellow'))
        let red = firstTeamWithFlags.filter(team=>team.flags.includes('red'))
        let green = firstTeamWithFlags.filter(team=>team.flags.includes('green'))
        objColors.first = {
          blue:blue.length,
          yellow:yellow.length,
          red:red.length,
          green:green.length
        }
      } else{
      }
      if(secondTeamWithFlags.length){
        let blue = secondTeamWithFlags.filter(team=>team.flags.includes('blue'))
        let yellow = secondTeamWithFlags.filter(team=>team.flags.includes('yellow'))
        let red = secondTeamWithFlags.filter(team=>team.flags.includes('red'))
        let green = secondTeamWithFlags.filter(team=>team.flags.includes('green'))
        objColors.second = {
          blue:blue.length,
          yellow:yellow.length,
          red:red.length,
          green:green.length
        }
      }else{
      }
      teamWithFlags.push(objColors)
    })
    let yellowSize = 0;
    let blueSize = 0;
    let greenSize = 0;
    let redSize = 0;
    teamWithFlags.forEach((withFlag)=>{
      if(withFlag?.first){
        yellowSize = !!withFlag?.first.yellow ? yellowSize + 1 : yellowSize;
        blueSize = !!withFlag?.first.blue ? blueSize + 1 : blueSize;
        greenSize = !!withFlag?.first.green ? greenSize + 1 : greenSize;
        redSize = !!withFlag?.first.red ? redSize + 1 : redSize;
      }else{}
      if(withFlag?.second){
        yellowSize = !!withFlag?.second.yellow ? yellowSize + 1 : yellowSize;
        blueSize = !!withFlag?.second.blue ? blueSize + 1 : blueSize;
        greenSize = !!withFlag?.second.green ? greenSize + 1 : greenSize;
        redSize = !!withFlag?.second.red ? redSize + 1 : redSize;
      }else{}
    })
    prepareObj.blue.blueLeng = blueSize;
    prepareObj.yellow.yellowLeng = yellowSize;
    prepareObj.red.redLeng = redSize;
    prepareObj.green.greenLeng = greenSize;
    prepareObj.blue.blueMin = (prepareObj.blue.blueMin > blueSize ? blueSize : prepareObj.blue.blueMin  )
    prepareObj.yellow.yellowMin =(prepareObj.yellow.yellowMin > yellowSize ? yellowSize :  prepareObj.yellow.yellowMin)
    prepareObj.red.redMin =  (prepareObj.red.redMin > redSize ? redSize : prepareObj.red.redMin )
    prepareObj.green.greenMin = (prepareObj.green.greenMin > greenSize ? greenSize : prepareObj.green.greenMin )
    prepareObj.blue.blueMax = (colorMinMax.blue.isSelect) ?
      (prepareObj.blue.blueMax > blueSize ? blueSize : prepareObj.blue.blueMax  )
      : blueSize;
    prepareObj.yellow.yellowMax = (colorMinMax.yellow.isSelect) ?
      (prepareObj.yellow.yellowMax > yellowSize ? yellowSize :  prepareObj.yellow.yellowMax)
      : yellowSize;
    prepareObj.red.redMax = (colorMinMax.red.isSelect) ?
      (prepareObj.red.redMax > redSize ? redSize : prepareObj.red.redMax )
      : redSize;
    prepareObj.green.greenMax = (colorMinMax.green.isSelect) ?
      (prepareObj.green.greenMax > greenSize ? greenSize : prepareObj.green.greenMax )
      : greenSize
    if(flag && flag?.length){
      flag.forEach(fl =>{
        prepareObj[`${fl.color}`][`${fl.color}Max`] = fl.max
        prepareObj[`${fl.color}`][`${fl.color}Min`] = fl.min
        prepareObj[`${fl.color}`]['isSelect'] = true
      })
    }
    setColorMinMax(prepareObj)
    let total = totalRader.reduce((acc, current) => current * acc,1);
    setCouponInfo(prev=>({...prev,totalRader: total}))
  }
  const changeCoastValue = (e,eventArrId,eventId,team) =>{
    let value = e.target.value
    let typeTeam = team === 'home' ? 'firstTeam' : 'secondTeam'
    let getEvent = reduceState.matches[eventId];
    let getDist = getEvent[typeTeam];
    let getCurrenDist = getDist[eventArrId];
    getCurrenDist.coast = value;
    setSvenskaMatrix(null)
    setReduceState(prev=>({...prev}))
  }
  //Change state all function
  const changeStatus = (e,eventArrId,eventId,team) =>{
    e.preventDefault()
    let typeTeam = team === 'home' ? 'firstTeam' : 'secondTeam'
    let getEvent = reduceState.matches[eventId];
    let getDist = getEvent[typeTeam];
    let colorActive = getActiveColor();
    let updateDist = getDist?.map((dist,index) =>{
      if(index === eventArrId){
        let getTotalActive = getDist.filter(dist=>dist.isActive == true);
        if(colorActive && dist.isActive){
          let flag = existColor(dist?.flags,colorActive,dist.isActive)
          return {...dist,isActive: true,flags:[...flag]}
        }else{
          //If has flags and no color remove flags
          return {...dist,isActive:getTotalActive?.length >= 10 ? false :!dist?.isActive}
        }
      } else{
        return {...dist}
      }
    });
    getEvent[typeTeam] = updateDist
    optionsForColor()
    setSvenskaMatrix(null)
    setCouponInfo(prev =>({...prev,
      totalMatches:false,
      totalMatchesLength:false}))
    setReduceState(prev=>({...prev}))
  }

  const checkBoxChange = (e,eventId,type) =>{
    let getMatch = reduceState.matches[eventId];
    getMatch[type] = e.target.checked;
    setReduceState(prev=>({...prev}))
    setSvenskaMatrix(null)
  }

  const changeMinMaxForGame = (e,eventId,type) =>{
    let value = e.target.value;
    if(value > 100) return
    let getMatch = reduceState.matches[eventId];
    getMatch[type] = value;
    setReduceState(prev=>({...prev}))
    setSvenskaMatrix(null)
  }

  const changeColor = (e,col) => {
    e.preventDefault()
    setIsCoast(false)
    for (const flag in color){
      if(flag === col ){
        color[flag] = !color[flag]
      }else{
        color[flag] = false
      }
    }
    setActiveColor({...color})
  }
  const changeCoast = () =>{
    setIsCoast(prev=>!prev)
    for(const flag in color){
      color[flag] = false
    }
    setActiveColor({...color})
  }
  const createOptionsColor = () =>{
    let arrayColor = [];
    for (const key in colorMinMax){
      let hasLeng = colorMinMax[key][`${key}Leng`]
      if(hasLeng){
        let min = colorMinMax[key][`${key}Min`]
        let max = colorMinMax[key][`${key}Max`]
        arrayColor.push({color:key,min,max})
      }else{

      }
    }
    return arrayColor
  }
  const createTotal = (type) =>{
    let optionTotal = reduceState?.options[type];
    let min = optionTotal?.min;
    let max = optionTotal?.max
    if(min !== "" || max !== ''){
      return {
        min: min === "" ? undefined : Number.parseInt(min),
        max: max === "" ? undefined : Number.parseInt(max)
      }
    }else{
      return
    }
  }
  const handleReduce = () => {
    if(couponInfo?.totalRader === 0){
      setModal(true)
      setMessageForModal('Din kupong är ofullständig!')
      setVariant('error')
    }else{
      let dataTo = reduceState?.matches?.map(state =>{
        let reduceFirstTeam = state?.firstTeam?.filter(team => team?.isActive === true)
          .map(item=> {
            let isFlags = false;
            let isCoast = false;
            if(item?.flags?.length) isFlags = true
            if((+item?.coast)) isCoast = true
            let resultTeam = {}
            if(isFlags){
              resultTeam.flags = item.flags
            }else{}
            if(isCoast){
              resultTeam.coast = +item.coast
            }else{}
            let score = (item.score === 'F' || item.score === 'f') ? 'f' : +item.score
            return {...resultTeam,score:score}
          });
        let reduceSecondTeam = state?.secondTeam?.filter(team => team?.isActive === true)
          .map(item=> {
            let isFlags = false;
            let isCoast = false;
            if(item?.flags?.length) isFlags = true
            if((+item?.coast)) isCoast = true
            let resultTeam = {}
            if(isFlags){
              resultTeam.flags = item.flags
            }else{}
            if(isCoast){
              resultTeam.coast = +item.coast
            }else{}
            let score = (item.score === 'F' || item.score === 'f') ? 'f' : +item.score
            return {...resultTeam,score:score}
          });
        let max;
        let min;
        if(state?.min === '') {
          min = undefined
        }else{
          min = +state.min
        }
        if(state?.max === '') {
          max = undefined
        }else{
          max = +state.max
        }
        return {
          ...state,
          max:max,
          min:min,
          firstTeam:reduceFirstTeam,
          secondTeam:reduceSecondTeam
        }
      })
      let flag = createOptionsColor()
      let total = createTotal('total');
      let totalCoast = createTotal('totalCoast');
      setReduceData({matches:dataTo,
        options:{flags:flag,total,totalCoast,
          winnersTotal:{min:winnerTotal.min,max:winnerTotal.max,values:winnerTotal.values}}})
      getResult({matches:dataTo,
        options:{flags:flag,total,totalCoast,
          winnersTotal:{min:winnerTotal.min,max:winnerTotal.max,values:winnerTotal.values}}})
    }
  }
  // fetch reducera
  const getResult = async (data) =>{
    let token = isAuthenticated ? await getAccessTokenSilently() : null;
    let headers
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else{
      headers = {
        'Content-Type': 'application/json'
      }
    }
    let result = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/bomben`,
      {
        method:'POST',
        headers:headers,
        body:JSON.stringify(data)
      }
    )
    if(result.ok){
      let response = await result.json();
      reduceDistribution(response.totalMatches)
      if(response?.totalMatchesLength > 0 ){
        let totalMatches = response.totalMatches
        setSvenskaMatrix(totalMatches)
        setCouponInfo(prev =>({...prev,
          totalMatches:response.totalMatches,
          totalMatchesLength:response.totalMatchesLength}))
      } else{
        let totalMatches = response.totalMatches
        setSvenskaMatrix(totalMatches)
        setCouponInfo(prev =>({...prev,
          totalMatches:false,
          totalMatchesLength:response.totalMatchesLength}))
      }
    }else{
      // need for error handle
      let response = await result.json();
      let error = response?.errors?.matches ||
        response?.errors['options.total.max'] ||
        response?.errors['options.totalCoast.max'] ||
        response?.errors['options.total.min'] ||
        response?.errors['options.totalCoast.min'] ||
        response?.errors['options.flags']
      setMessageForModal(error)
      setModal(true)
      setVariant('error')
    }
  }

  const handleSvenska = () =>{
    let isSpela = isSpelaTillsammans
      && (svenskaMatrix?.length  <= 2200
        && (svenskaMatrix?.length  * couponInfo.betRowAmount <= 20000));
    if(svenskaMatrix?.length > 0
      && (svenskaMatrix?.length < 10_000)
      && (svenskaMatrix?.length * couponInfo.betRowAmount < 20_000)
      && (isSpelaTillsammans ? isSpela : !isSpelaTillsammans)
    ) {
      setSvenskaModal(true)
    }else if(!isSpela && isSpelaTillsammans && svenskaMatrix?.length > 0){
      setMessageForModal('Ditt "Spela Tillsammans"-system är antingen ' +
        'större än 2200 rader eller kostar mer än 20 000 kr och går ' +
        'därför inte att lämna in. Ändra om ditt system och försök igen!')
      setModal(true)
      setVariant('error')
    }
    else if(svenskaMatrix?.length > 10_000){
      setMessageForModal('Kupongen får inte innehålla mer är 10 000 rader')
      setModal(true)
      setVariant('error')
    }
    else if(svenskaMatrix?.length * couponInfo.betRowAmount > 20_000){
      setMessageForModal('Kupongen får inte innehålla mer är 20 000 kr')
      setModal(true)
      setVariant('error')
    }
    else if (svenskaMatrix?.length === 0){
      setMessageForModal('Din kupong är tom!')
      setModal(true)
      setVariant('error')
    }else{
      setMessageForModal('Tryck på REDUCERA för att generera dina rader.')
      setModal(true)
      setVariant('error')
    }
  }
  const saveFinall = () =>{
    setCouponInfo(prev=>({...prev,isSave:true,saveStatus: 'final'}))
  }
  useEffect(()=>{
    if(couponInfo.isSave){
      saveCoupon()
      setCouponInfo(prev => ({...prev,isSave: false}))
    }
  },[couponInfo.isSave])

  const saveCoupon = async () => {
    const couponData = {
      id: !couponInfo.currentId ? null : couponInfo.currentId,
      nickname: couponInfo.currentNickname || '',
      data: {
        // status: saveAsCopy ? "draft" : (savestatus || status) || "draft",  // one of "draft" | "final
        status: couponInfo.saveStatus || "draft", // one of "draft" | "final"
        saveTime: Date.now(),
        closeTime: data.closeTime,
        gameType: gameType,
        drawNumber: drawNumber,
        fetchDataUrlName: gameType,
        betRowAmount: couponInfo.betRowAmount,
        couponRawData:reduceData
      }
    }
    // send token silently
    let token = isAuthenticated ? await getAccessTokenSilently() : null;
    let headers
    if(isAuthenticated){
      headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };
    } else{
      headers = {'Content-Type': 'application/json'}
    }
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/coupon/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(couponData)
    })
      .then(response => {
        if (response.status === 403) {
          throw new Error('Not allowed')
        }
        if (response.status === 404) {
          throw new Error('Not found')
        }
        return response.json()
      })
      .then(data => {
        let url = `${window.location.origin}/reducera/${gameType}/${drawNumber}/${data.id}`;
        window.history.replaceState("", "", url )
        setCouponInfo(prev=>({...prev,
          currentNickname:data.nickname,
          currentId:data.id
        }))
        navigator.clipboard
          .writeText(
            `${process.env.REACT_APP_SITE_URL}/reducera/${gameType}/${drawNumber}/${data.id}`
          )
          .then(() => console.log('Copying to clipboard was successful!'))
          .catch(err => console.log('Could not copy text:', err))
      })
      .catch(error => {
        setModal(true)
        setVariant('error')
        setMessageForModal('Not a your coupon')
        console.error('Error:', error)
      })
  }
  const resetCoupon = () =>{
      setModal(true)
      setVariant('reset')
  };

  const uploadCoupon = async(id) =>{
    if(!id) return
    else{
      await fetch(`${process.env.REACT_APP_API_ENDPOINT}/coupon/${id}`, {
        // headers: headers
      })
        .then(response => {
          if (response.status === 404) {
            throw new Error('Not found')
          }
          if (response.status === 403) {
            throw new Error('Not Allowed')
          }
          return response.json()
        })
        .then(data => {
          // check if the loaded coupon matches the game and dn of directed coupon
          let {drawNumber,gameType} = data.data;
          window.location.href = `${window.location.origin}/reducera/${gameType}/${drawNumber}/${data.id}`;
        })
        .catch(error => {
          setMessageForModal('Kupongen hittades inte. Kontrollera kupong-id:t eller skapa ett nytt')
          setModal(true)
          setVariant('error')
          console.error(error)
        })
    }
  }
  const openCloneCoupon = () =>{
    if(svenskaMatrix === null){
      setMessageForModal('Tryck på REDUCERA för att generera dina rader.')
      setModal(true)
      setVariant('error')
    }else{
      setModal(true)
      setVariant('save')
    }
    // проверить есть ли матрица
  }

  const openCloneModal = () =>{
    setModal(true)
    setVariant('clone')
  }

  const handleTotal = (e,type,size,maxValue) =>{
    let value = e.target.value;
    if(!type || !size) return
    if(maxValue && (+value > +maxValue)) return
    let option  = reduceState?.options;
    option[type][size] = value;
    setReduceState(prev=>({
      ...prev
    }))
    setSvenskaMatrix(null)
  }

  const saveNameOrClone = () =>{
    if(isCreateNew){
      setCouponInfo(prev=>({...prev,isSave:true,currentId: null,saveStatus:"draft"}))
    }else{
      setCouponInfo(prev=>({...prev,isSave:true}))
    }
    setModal(false)
  }
  let listener = (event) => {
    const e = event || window.event;
    // Cancel the event
    console.log(e)
    e.preventDefault();
    if (e) {
      e.returnValue = ''; // Legacy method for cross browser support
    }
    return ''; // Legacy method for cross browser support
  }
  useEffect(() => {

    if (!id) {
      window.addEventListener("beforeunload", listener)
    }
    return () => {
      window.removeEventListener("beforeunload", listener)
    };

  }, [id])
  useEffect(()=>{
    if(couponInfo?.currentId){
      window.removeEventListener("beforeunload", listener)
    }
  },[couponInfo?.currentId])
  const resetCouponDefault = () =>{
    let hard = isHardReset;
    let prevValue = winnerTotal.values?.map(win => null)
    setWinnerTotal({
      min:0,
      max:0,
      values:prevValue,
      possibleValues:0,
      isTouch:{
        min:false,
        max:false
      },
      upload:false,
    });
    let toDefault = {
      matches:[],
      options: {
        total: {
          min: "",
          max: ""
        },
        totalCoast: {
          min: "",
          max: ""
        },
        flags: []
      }
    }
    setColorMinMax(
      {
        yellow: {title:'yellow',yellowMin:0,yellowMax:0},
        blue:{ title:'blue',blueMin:0,blueMax:0},
        green:{title:'green',greenMin:0,greenMax:0},
        red:{title:'red',redMin:0,redMax:0},
      })
    if(hard){
      let dataTo = reduceState?.matches?.map(state =>{
        let reduceFirstTeam = state?.firstTeam?.map(item=> {
          item.coast = 0;
          item.isActive = false;
          item.flags = [];
          return {...item}
        });
        let reduceSecondTeam = state?.secondTeam?.map(item=> {
          item.coast = 0;
          item.flags = [];
          item.isActive = false;
          return {...item}
        });
        return {
          ...state,
          max:'',
          min:'',
          firstTeam:reduceFirstTeam,
          secondTeam:reduceSecondTeam,
          firstDoesntWin:false,
          noDraw:false,
          secondDoesntWin:false,
        }
      })
      toDefault.matches = dataTo;
      setCouponInfo(prev=>({...prev,totalRader: 0}))
    }else{
      let dataTo = reduceState?.matches?.map(state =>{
        let reduceFirstTeam = state?.firstTeam?.map(item=> {
          item.coast = 0;
          item.flags = [];
          return {...item}
        });
        let reduceSecondTeam = state?.secondTeam?.map(item=> {
          item.coast = 0;
          item.flags = [];
          return {...item}
        });
        return {
          ...state,
          max:'',
          min:'',
          firstTeam:reduceFirstTeam,
          secondTeam:reduceSecondTeam,
          firstDoesntWin:false,
          noDraw:false,
          secondDoesntWin:false,
        }
      })
      toDefault.matches = dataTo;
    }
    setReduceState(toDefault)
    setSvenskaMatrix(null)
    setChartForecast([])
  }
  //Logic with winner team
  //update team winner
  const updateWinnerTeam = (data) =>{
    let getData = data?.data?.couponRawData?.options?.winnersTotal;
    if(!getData) return
    let getMaxPossibleValue = getData?.values?.filter(win => typeof win === 'string');
    setWinnerTotal(prev=>({...prev,
      isTouch: {
        min:true,
        max:true,
      },
      upload: true,
      values: getData?.values,
      max:getData?.max,
      min:getData?.min,
      possibleValues: getMaxPossibleValue.length}))
  }
    //When we have data and not upload coupon set state
  useEffect(()=>{
    if(data?.events?.length > 0 && !winnerTotal.upload && (winnerTotal.values.length === 0)){
      //Initial state undefined( its mean no selected users)
      let stateWinner = data?.events?.map(item=>undefined)
      setWinnerTotal(prev=>({...prev,values:stateWinner}))
    }else if(data?.events?.length > 0 && winnerTotal.upload){
      // when we upload coupon
    }
  },[data?.events,winnerTotal.upload]);
  //check is selected value isValid
  const isValidWinner = (type,possibleValue) =>{
    let value = winnerTotal[type];
    if(value > possibleValue){
      winnerTotal[type] = possibleValue;
      setWinnerTotal(prev=>({...prev}))
    }
  }
  const isIncreaseMax = (value) =>{
    if(!winnerTotal.isTouch.max){
      setWinnerTotal(prev=>({...prev,max:value}))
    }
  }
  //change the active winner team result
  const changeWinnerTeamState = (position,state) =>{
    let value = winnerTotal.values[position];
    if(value === state){
      winnerTotal.values[position] = undefined
    }else{
      winnerTotal.values[position] = state
    }
    let getMaxPossibleValue = winnerTotal?.values?.filter(win => typeof win === 'string');
    setWinnerTotal(prev=>({...prev,possibleValues:getMaxPossibleValue.length }));
    isValidWinner('min',getMaxPossibleValue.length)
    isValidWinner('max',getMaxPossibleValue.length)
    isIncreaseMax(getMaxPossibleValue.length)
    setSvenskaMatrix(null)
  }
  const changeMinMaxTeamState = (e,type) =>{
    let value  = + e.target.value;
    if(type === 'max' && (winnerTotal.min > value)){
      return
    }else if(type === 'min' && (  value > winnerTotal.max)){
      return
    }else{}
    winnerTotal[type] = value;
    winnerTotal.isTouch[type] = true;
    setSvenskaMatrix(null)
    setWinnerTotal(prev=>({...prev}))
  }
  //distribution forecast
  const reduceDistribution = (arrayValues) =>{
    if(arrayValues?.length <= 0){
        setChartForecast([])
    }else{
      let changeFormatArr = arrayValues?.map((arr,index) =>{
        //split name (format 1-2,2-2, )
        let splitterCombination = arr.split(',');
        // array fro results
        let resultArr = [];
        // find in combination destination
        let findDestribution = splitterCombination.forEach((comb,ind)=>{
          let splitResult = comb.split('-');
          let currArray = data?.events[ind];
          let currDestribution = currArray?.distribution;
          let home = currDestribution.find(destr => {
            let name = destr.score.split('-');
            if((name[0] == 'F' ? 'f' : name[0]) === splitResult[0]){
              return destr
            }
          })
          let away = currDestribution.find(destr => {
            let name = destr.score.split('-');
            if((name[0] == 'F' ? 'f' : name[0]) === splitResult[1]){
              return destr
            }
          })
          resultArr.push({
            distribution:home.home
          })
          resultArr.push({
            distribution:away.away
          })
        });
        return resultArr
      })
      // function to represent data for chart (same logic like on olds)
      //All this manipulation need to represent data to format for old logic
      let result = createBombenChart(changeFormatArr);
      let isEmpty = result.every(res=>res?.count === 0)
      setChartForecast(isEmpty ? [] : result)
    }
  }
  const getContentForModal = () =>{
    let type = variant || 'save'
    switch (type){
      case 'reset' :
        return (<>
          <h3>
            Vill du ta bort samtliga reduceringar?
          </h3>
          <form>
            <div className='input-group'>
              <label>
                Ta även bort valda tecken
                <input
                  type="checkbox"
                  checked={isHardReset}
                  onChange={(e)=>{
                    let value = e.target.checked;
                    setIsHardReset(value)
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </form>
          <button onClick={() => {
            setModal(false)
            resetCouponDefault()
          }}>
            OK
          </button>
          <button onClick={() => {
            setModal(false)
            setVariant('')
          }}>
            Avbryt
          </button>

        </>)
      case 'clone' :
        return (<>
          <h3>
            Din kupong kommer klonas och generera fler kuponger att lämna in.
          </h3>
          <button onClick={() => {
            setModal(false)
            setCouponInfo(prev=>({...prev,cloneCount: couponInfo?.cloneCount + 1}))
          }}>
            OK
          </button>
          <button onClick={() => {
            setModal(false)
          }}>
            Avbryt
          </button>
        </>)
      case 'save' :
        return (<>
          <form>
            <div className='input-group'>
              <label>
                Spara som ny
                <input
                  type="checkbox"
                  defaultChecked={isCreateNew}
                  onChange={(e)=>{
                    let value = e.target.checked;
                    setIsCreateNew(value)
                  }}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </form>
          <h3>Namnge din kupong.</h3>
          <input
            value={couponInfo?.currentNickname}
            onChange={(ev) => {
              setCouponInfo(prev=>({...prev,currentNickname:ev.target.value }))
            }}
            maxLength={20}
          >
          </input>
          <div style={{marginTop:'10px'}}>
            <button onClick={saveNameOrClone}>
              OK
            </button>
            <button onClick={() => {
              setModal(false)
            }}>
              Avbryt
            </button>
          </div>
        </>)
      default :
        return (<>
        <h3>
          {messageForModal}
        </h3>
        </>)
    }
  }
  const tooltipPoängreducering = <p>Klicka på “P” för att aktivera Poängreducering och fördela poäng (1-99) på valda tecken i
    “ramen”. Ställ sedan in villkor genom att välja ett Min/Max-värde och klicka på <strong>REDUCERA</strong>
    &nbsp;för att reducera bort de rader som EJ uppfyller villkoret - d.v.s. de rader där totala
    poängsumman på valda tecken antingen ligger under Min- över Max-värdet.</p>
  const tooltipTotalt = <>
    <h3>Totalt antal mål på kupongen</h3>
    <p>Reducera på totalt antal mål i hela omgången (samtliga matcher) genom att välja ett Min-
      och ett Max-värde. Klicka sedan på <strong>REDUCERA</strong>-knappen för att reducera bort de rader som
      EJ uppfyller de valda villkoren.</p>
  </>;
  return (
    <GameContext.Provider value={loadedState}>
      {data ?
      <BombenContainer>
        <Autorized
          isModalOpen={isModalLogin}
          setIsModalOpen={setIsModalLogin}
        />
        <Prompt
          message={(location, action) => {
            if (action === 'POP') {
              console.log('Backing up...')
            }
            return (couponInfo?.currentId || id)
              ? true
              : 'Du är påväg att lämna sidan, glöm inte spara din kupong!'
            // return location.pathname.startsWith("/dasdsa")
            //   ? true
            //   :
          }}
        />
        <GameTypeHeader
          gameType={gameType}
          gameTurnover={data?.turnover}
          gameCloseTime={data?.closeTime}
          jackpot={data?.jackpotItems?.[0]?.amount}
          extraMoney={parseInt(data?.fund?.extraMoney) > 0 ? parseInt(data?.fund?.extraMoney) + ' kr' : null  }
          rolloverIn={parseInt(data?.fund?.rolloverIn) > 0 ? parseInt(data?.fund?.rolloverIn) + ' kr' : null  }
          drawNumber={drawNumber}
          loadedId={id}
          gridTemplate = 'minmax(70px,1fr) minmax(auto,calc(1366px - 0.3rem * 2)) minmax(70px,1fr)'
        />
        <BombenModal
          open={modal}
          setOnOpen={setModal}
          setVariant={setVariant}
          setMessage={setMessageForModal}
        >
          {getContentForModal()}
        </BombenModal>
        <BombenSvenska
          isReduced={svenskaMatrix}
          open={svenskaModal}
          setIsModalOpen={setSvenskaModal}
          currentId={couponInfo?.currentId}
          betRowAmount={couponInfo?.betRowAmount}
          cloneCount={couponInfo?.cloneCount}
          saveCoupon={saveFinall}
          drawNumber={drawNumber}
          copied={true}
          errorModal = {{modal:setModal,message:setMessageForModal,variant:setVariant}}
          isClose={modal}
        />
      <InnerContainerLarge >
        <div className='bomben-wrapper'>
          <div className='bomben-games'>
            {
              data?.events?.map((event,index) =>
                <BombenGame
                  trendData={trendData?.trends ? trendData?.trends[index] : []}
                  eventState={reduceState?.matches[index]}
                  event = {event}
                  eventId={index}
                  key={index}
                  changeStatus={changeStatus}
                  checkBoxChange={checkBoxChange}
                  changeMinMaxForGame={changeMinMaxForGame}
                  forecast={data?.forecast?.events[index]}
                  isCoast={isCoast}
                  changeCoast={changeCoastValue}
              /> )
            }

          </div>
          <LoaderCoupon
            area={true}
            id={couponInfo?.currentId  || id}
            uploadCoupon={uploadCoupon}
          />
          <div className='trend'>
            <Trends
              trendsData={{
                trendData,
                setTrendData,
                closingTime: data?.closeTime
              }}
              topptippsify={(el)=>el}
              isBomben={true}
              update={isRemoveState}
            />
          </div>
          <BombenColors
            changeColor={changeColor}
            color={color}
            colorMinMax={colorMinMax}
            setColorMinMax={setColorMinMax}
            isActive={true}
            removeStatus={setSvenskaMatrix}
            area='color'
          />
          <BombenSelect
            onChange={handleTotal}
            valueMin={reduceState?.options?.total?.min}
            valueMax={reduceState?.options?.total?.max}
            optionMin={['total','min','100']}
            optionMax={['total','max','100']}
            title='Totalt antal mål'
            area='first'
            tooltipHtml={tooltipTotalt}
          />
          <BombenSelect
            onChange={handleTotal}
            valueMin={reduceState?.options?.totalCoast?.min}
            valueMax={reduceState?.options?.totalCoast?.max}
            optionMin={['totalCoast','min','10000']}
            optionMax={['totalCoast','max','10000']}
            title='Poängreducering'
            tooltipHtml={tooltipPoängreducering}
            isButton={true}
            handleButtonClick={changeCoast}
            isButtonActive={isCoast}
            area='second'
          />
          <WinTeam
            data={winnerTotal?.values}
            area='win'
            isActive={true}
            possible={winnerTotal.possibleValues}
            min={winnerTotal.min}
            max={winnerTotal.max}
            selectTeam = {changeWinnerTeamState}
            selectMinMax={changeMinMaxTeamState}
          />
          <DividedForecast area={true}>
            <Tooltip
              prefixCls='rToolTip'
              placement='bottom'
              trigger={['hover']}
              animation='anim'
              visible={false}
              overlay={
                <>
                  <h3>Utdelningsprognos i kronor</h3>

                  <p>
                    Hur ser den uppskattade utdelningen ut för raderna i systemet? Få en
                    snabb överblick på vad utdelningen uppskattas ligga på för alla rader
                    i systemet - prognosen är inte faktisk och baseras på
                    streckfördelningen och omsättningen. Ju närmare spelstopp, desto
                    skarpare uppskattning. Staplarna visar hur pass defensivt/offensivt
                    upplagt systemet är.
                  </p>

                  <p>
                    Den uppskattade utdelningsprognosen aktiveras när man byggt ett system
                    och klickar på <strong>REDUCERA</strong>-knappen. Med hjälp av poäng- färg- och
                    1X2-reducering kan man sedan “vikta om” systemets rader beroende på
                    smak och tycke.
                  </p>

                  <p>
                    OBS! Prognosen gäller enbart för rader med alla rätt och uppskattas på
                    den totala streckfördelningen och omsättningen. <em>Reducering.se</em>
                    tar inget ansvar för eventuellt felaktiga uppskattningar.
                  </p>
                </>
              }
            >
              <ToolsSmallHeading style={{ marginBottom: '1rem' }}>
                Utdelningsprognos i kronor <span style={{cursor:'not-allowed'}} className='rToolTip-icon'>i</span>
              </ToolsSmallHeading>
            </Tooltip>

            <p style={{ width: '100%', textIndent: '1.1rem' }}>%</p>

            <DividendForecast
                forecastData= {chartForecast}
            />
          </DividedForecast>
          <div className='bomben-settings'>
            <BoombenBig
              area='game'
              value={couponInfo?.betRowAmount}
              onChange={changeBetRowAmount}
              option={['99']}
            />
            <BombenButton
              area='live'
              text='Liverättning'
              types='live'
              handleClick={()=>{
                historyRout.push(`/liveresults/${gameType}/${drawNumber}${(id || couponInfo.currentId) ? `/${id || couponInfo.currentId}` : ''}`)
              }}
            />
            <BombenButton
              icon='clone'
              text={couponInfo?.cloneCount > 0 ? `Klonad ${couponInfo?.cloneCount}` : 'Klona kupong'}
              area='clone'
              handleClick={openCloneModal}
              variant = {couponInfo?.cloneCount > 0}
              handleRemove={(e)=>{
                e.stopPropagation()
                setCouponInfo(prev=>({...prev,cloneCount: 0}))
              }}
            />
            <BombenButton
              icon='save'
              text='Spara kupong'
              area='spara'
              handleClick={openCloneCoupon}
            />
            <BombenButton
              icon='reset'
              text='Nollställ reduceringar'
              area='delet'
              handleClick={resetCoupon}
            />
            <BombenButton
              area='lama'
              text='Lämma in'
              icon='lama'
              types='lama'
              handleClick={handleSvenska}
            />
          </div>
        </div>
        <BombenReduceraRow
          reduced={handleReduce}
          isReduced={svenskaMatrix?.length === 0 || svenskaMatrix?.length > 0 || svenskaMatrix !==null }
          reducedRowsCount={couponInfo.totalMatchesLength}
          betRowAmount={couponInfo.betRowAmount}
          totalRader={couponInfo.totalRader || 0}
          currId={couponInfo.currentId}
          removeIsReduce = {setSvenskaMatrix}
          SpTsms={{isSpelaTillsammans, setIsSpelaTillsammans }}
        />
        <Footer marginBottom={true}/>
      </InnerContainerLarge>
    </BombenContainer> :
        (
          <div className='loader'>
            <div className='loader-animation' />
            <LoadingLogo />

            <p>Laddar sidan</p>
          </div>
        )}
    </GameContext.Provider>
  )
}

export default Bomben

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import HomeHeader from '../../components/Headers/HomeHeader'
import ActiveGamesBoxes from '../../components/ActiveGamesBoxes/ActiveGamesBoxes'
import HowItWorksPanel from '../../components/HowItWorksPanel/HowItWorksPanel'
import Footer from '../../components/Footer/Footer'
import {
  InnerContainer,
  OneHalf,
  PageContent,
  ArticlePanel
} from '../../styles/globalStyle'

export default function AboutPage() {
  const url = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <HomeHeader />
      <Helmet>
        <title>Om oss | Reducering.se</title>
        <meta property='og:title' content='Sveriges bästa poolspels-verktyg' />
        <meta property='og:url' content={`https://www.reducering.se${url.pathname}`} />
        <meta
          property='og:description'
          content='I teamet bakom Reducering.se hittar ni några av Sveriges bästa och skarpaste pool-spelare. Här delar vi med oss av tips och trix för att bli en bättre spelare - redan idag!'
        />
      </Helmet>

      <PageContent>
        <OneHalf>
          <ArticlePanel>
            <h3>Reducering.se</h3>

            <p>
              <em>Reducering.se</em> är ett reduceringsverktyg för Svenska Spels
              poolspelprodukter (Stryktipset, Europatipset, Topptipset och Powerplay),
              framtaget med ambition om att bli marknadsledande. Enkelt att använda, med
              en rad smarta funktioner för att tillfredsställa både små- och storspelarens
              behov. Teamet bakom <em>Reducering.se</em> har mångårig erfarenhet av
              poolspel och av att spela reducerat, något som varit till stor fördel i
              utvecklingen av verktyget.
            </p>

            <p>
              Den stora skillnaden med att spela reducerat kontra “vanligt” matematiskt är
              att du själv väljer insatsfördelningen för varje valt tecken. På så sätt kan
              du - med en rad olika reduceringar och beroende på tro - skräddarsy och göra
              ditt system mera slagkraftigt. Att bara betala för de raderna man själv vill
              är något som i långa loppet mycket väl kan vara skillnaden på vinst och
              förlust.
            </p>

            <p>
              <em>Reducering.se</em> har också en snabb och trevlig liverättning - en
              viktig del av hela upplevelsen. När du byggt ihop ditt system och matcherna
              startar, så börjar det roliga - jakten på 13 rätt! I liverättningsdelen kan
              du följa utvecklingen - alla mål som “plingar” in, hur många rätt du har och
              aktuell utdelning. Allt i snabbast möjliga uppdateringstakt!
            </p>

            <p>
            Supportfrågor: <br />
            <a href="mailto:support@reducering.se">support@reducering.se</a>
            </p>
            <p>  
            Kontakta oss: <br />
            <a href="mailto:kontakt@reducering.se">kontakt@reducering.se</a> 
            </p>
          </ArticlePanel>
        </OneHalf>

        <OneHalf>
          <HowItWorksPanel />
        </OneHalf>
      </PageContent>

      <InnerContainer>
        <ActiveGamesBoxes />
      </InnerContainer>

      <InnerContainer>
        <Footer />
      </InnerContainer>
    </>
  )
}

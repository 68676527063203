import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Headers/Logo'
import {
  sortDates,
  importGameTypeLogos,
  gameTurnoverWithSpace,
  getActiveDates
} from '../../utils/helperFunctions'

import LoadGame from '../../components/Game/ReductionTools/LoadGame/LoadGame'
import Navbar from '../Navbar/Navbar'

import { colors } from '../../styles/constants'
import {
  GameTypeLogo,
  GameTypeHeaderContent,
  GameTypeInfo,
  GameAlarm
} from '../GameTypeHeader/GameTypeHeader.elements'
import {
  AboutHeader,
  AboutHeaderContent,
  AboutHeaderRounds
} from './AboutGameHeader.elements'
import { LogoContainer, GameTypeJackpot } from '../Headers/Headers.elements'

export default function GameHeaderAbout({
  gameType,
  gameTurnover,
  gameCloseTime,
  activeGames,
  jackpot,
  extraMoney
}) {
  const headerSpelStopp = getActiveDates(gameCloseTime)
  const images = importGameTypeLogos(require.context('../../assets/', false, /.*\.svg$/))
  return (
    <>
      <AboutHeader id='aboutGameHeader'>
        <LogoContainer className='headerItem'>
          <Logo />
        </LogoContainer>

        <GameTypeHeaderContent className='headerItem' gameTypeColor={colors[gameType]}>
          {gameType && (
            <GameTypeLogo classList=''>
              <img
                className='svenskaspel-logomark'
                src={images['svenskaspel-logomark.svg'].default}
                alt='Svenska Spel'
              />
              <img
                className={'gametype-wordmark ' + gameType}
                src={images[gameType + '-wordmark.svg']?.default}
                alt={gameType}
              />
            </GameTypeLogo>
          )}

          <div className='show-for-medium'></div>

          <GameTypeJackpot className={extraMoney && 'extra-pengar'}>
            {jackpot && (
              <img
                src={images['jackpot-reducering-wordmark.svg'].default}
                alt='Jackpot'
              />
            )}
            {jackpot && (
              <p>
                <strong>{jackpot}</strong>
              </p>
            )}

            {extraMoney && (
              <img
                src={images['extrapengar-reducering-wordmark.svg'].default}
                alt='Extra pengar'
                width='73'
                height='35'
              />
            )}
            {extraMoney && (
              <p>
                <strong>{extraMoney}</strong>
              </p>
            )}
          </GameTypeJackpot>

          <GameTypeInfo>
            <p>
              Omsättning:{' '}
              <strong>
                {activeGames?.length ? gameTurnoverWithSpace(gameTurnover) + ' kr' : `--`}
              </strong>
            </p>
            <p>
              Spelstopp:{' '}
              <strong>
                {activeGames?.length
                  ? `${headerSpelStopp.day} ${headerSpelStopp.time}`
                  : `--`}
              </strong>
            </p>
          </GameTypeInfo>

          {/*<GameAlarm gameTypeColor={colors[gameType]}>
            <button>
              <img src={images['icon-notifications.svg'].default} alt='Notiser' />
              10 min innan <br />
              spelstopp.
            </button>
          </GameAlarm>*/}

          <AboutHeaderContent>
            <AboutHeaderRounds
              gameTypeColor={colors[gameType]}
              className={activeGames?.length == 1 && 'single-game'}
            >
              {activeGames?.length ? (
                activeGames?.sort(sortDates).map((game, idx) => {
                  const { date, time } = getActiveDates(game.closeTime)
                  if(idx > 1){
                    return
                  }
                  return (
                    <Link
                      to={{
                        pathname: `/reducera/${gameType}/${game['drawNumber']}`
                      }}
                      key={`aboutHeaderRounds-${idx}`}
                    >
                      {gameType === 'europatipset' || gameType === 'stryktipset' ? 
                        'SPELA NU' : 
                        <>Spel <span>{`${date} (${time})`}</span></>}
                    </Link>
                  )
                })
              ) : (
                <p>Inga aktiva {gameType}-spel</p>
              )}
            </AboutHeaderRounds>

            <LoadGame />
          </AboutHeaderContent>
        </GameTypeHeaderContent>

        <Navbar className='navbar headerItem' />
      </AboutHeader>
    </>
  )
}

import React, { useEffect, useState, useContext } from 'react'
import { LoadGameContainer, LoadGameInput, LoadGameButton } from './LoadCoupon.elements'
import { ToolsHeading } from '../../../../styles/globalStyle'
import { ReactComponent as LoadGameIcon } from '../../../../assets/load-tool.svg'

import { LiveResultContext } from "../../../../context/liveResultContext"
import { ActiveGamesContext } from "../../../../context/activeGamesContext"
import { GameContext } from "../../../../context/gameContext"

export default function LoadCoupon({ currentId }) {

  let activeGames = useContext(ActiveGamesContext)
  let liveGames = useContext(LiveResultContext)

  // This is ONLY here to make the input box selectable, otherwise we should keep it as a placeholder
  const [field, setField] = useState('')
  const [isLoadingCoupon, setIsLoadingCoupon] = useState(false)

  let gameCouponProxy = `${process.env.REACT_APP_SITE_URL}/reducera/`
  let liveCouponProxy = `${process.env.REACT_APP_SITE_URL}/liveresults/`

  const handleChange = (val) => {
    if (val.startsWith(gameCouponProxy) ||val.startsWith(liveCouponProxy) ) {
      setField(val.substring(val.lastIndexOf('/') + 1))
      return;
    }
    setField(val)
  }

  useEffect(() => {
    setField(currentId || '')
  }, [currentId])

  return (
    <LoadGameContainer isLoadingCoupon={isLoadingCoupon}>
      <ToolsHeading>
        {currentId ? (
          <span>
            KUPONG <br /> LADDAD
          </span>
        ) : (
          <span>
            LADDA <br /> KUPONG
          </span>
        )}
      </ToolsHeading>
      <LoadGameInput
        value={field}
        type='text'
        placeholder={currentId || 'Kopiera in länken till ditt spel här..'}
        onChange={(e) => (
          handleChange(e.target.value)
        )}
      />
      <LoadGameButton
        onClick={() => {

          if (field === "") {
            return;
          }

          fetch(`${process.env.REACT_APP_API_ENDPOINT}/coupon/${field}`)
          .then(response => {
            if (response.status === 404) {
              throw new Error('Not found')
            }
            return response.json()
          })
          .then(data => {
            setIsLoadingCoupon(true);
            const gameType =  data.data.gameType;
            const drawNumber = data.data.drawNumber;
            const id = data.id;
            const status = data.data.status || "draft"; // <--- remove later
            
            if (status === 'draft') {
              window.location.href = `${process.env.REACT_APP_SITE_URL}/reducera/${gameType}/${drawNumber}/${id}`;
            } else {
              window.location.href = `${process.env.REACT_APP_SITE_URL}/liveresults/${gameType}/${drawNumber}/${id}`;
            }
          })
          .catch(error => {
            console.log('error', error)
            console.error(error)
            setIsLoadingCoupon(false);
          })
        }}
      >
        <LoadGameIcon />
      </LoadGameButton>
    </LoadGameContainer>
  )
}

import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import HomeHeader from "../../components/Headers/HomeHeader";
import ActiveGamesBoxes from "../../components/ActiveGamesBoxes/ActiveGamesBoxes";
import Footer from "../../components/Footer/Footer";
import {
  InnerContainer,
  PageContent,
  ContestBody,
  TavlingSection,
} from "../../styles/globalStyle";

import Video from "../../components/Video/Video";

import VideoHeroWebm from "../../assets/video/OBG_Contest_3.webm";
import VideoHeroMp4 from "../../assets/video/OBG_Contest_3.mp4";
import VideoHeroImage from "../../assets/video/Hero-fallback-contest.jpg";
import VideoHeroTavling from "../../assets/tavling-em.png";
import { WinTableWrapper } from "../../components/HomePage/WinTable/WinTable.elements";
import arrow from "../../assets/arrow.png";
import moment from "moment";
import Loader from "../../components/Loader/Loader";
import ReactPaginate from "react-paginate";
import { ReactComponent as Icon } from "../../assets/arrow-down.svg";
import { ActiveGamesContext } from "../../context/activeGamesContext";
import { ReactComponent as Svenska } from "../../assets/svenskaspel-logomark.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { ContestContext } from "../../context/contestContext";

export default function ContestPage() {
  const [search, setSearch] = useState("");
  const [tabPage, setTabPage] = useState(0);
  const [totalPageTab, setTotalPageTab] = useState(0);
  const [pagesTab, setPagesTab] = useState(1);
  const [orderTab, setOrderTab] = useState("");
  const [orderWinTab, setOrderWinTab] = useState("");
  const [group, setGroup] = useState([]);
  const [orders, setOrders] = useState(false);
  const [loaderTab, setLoaderTab] = useState(false);
  const url = useLocation();
  const { loginWithRedirect } = useAuth0();
  const { isSM } = useContext(ContestContext);
  let { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [contestDraws, setContestDraws] = useState([]);
  const [upcomingContestDraw, setUpcomingContestDraw] = useState();

  const fetchContestDraws = async () => {
    if (contestDraws.length) {
      return;
    }

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/wins/contest-draws`,
      );
      const data = await res.json();
      setContestDraws(data);
      if (data.length) {
        const upcomingDraw = data.find(
          (draw) => new Date(draw.date) > new Date(),
        );

        if (!upcomingDraw?.gameType) return;
        const res = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/gameInfo`,
        );
        const gameInfo = await res.json();

        const draw = gameInfo[upcomingDraw.gameType]?.draws.find(
          (draw) => draw.drawNumber === parseInt(upcomingDraw.id),
        );

        if (draw) {
          setUpcomingContestDraw({
            ...draw,
            gameType: upcomingDraw.gameType,
          });
        }
      }
    } catch (err) {
      return [];
    }
  };

  const fetchWinGroup = async () => {
    let token = isAuthenticated ? await getAccessTokenSilently() : {};
    let headers;
    setLoaderTab(true);
    if (isAuthenticated) {
      headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      headers = {};
    }
    fetch(
      `${
        process.env.REACT_APP_API_ENDPOINT
      }/wins/stryktipset-grouped?page=${pagesTab}&perPage=8${
        orderTab ? `&orderDirection=${orderTab}` : ""
      }${orderWinTab ? `&order=${orderWinTab}` : ""}${
        search ? `&search=${search}` : ""
      }&outdate=0&includePrivateUsers=true&isOctoberFeature=${
        isSM ? "true" : "false"
      }`,
      {
        headers: headers,
      },
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data?.list?.length > 0) {
          let updateList = data?.list?.map((li) => {
            li.open = false;
            return li;
          });
          setGroup(updateList);
          let count = data.totalCount;
          let totalPage = count / 8;
          setTotalPageTab(Math.ceil(totalPage));
        } else {
          setGroup(null);
        }
        setTimeout(() => {
          setLoaderTab(false);
        }, 500);
      });
  };

  useEffect(() => {
    if (!isLoading) {
      fetchWinGroup();
    }
  }, [isAuthenticated, isLoading]);
  useEffect(() => {
    fetchWinGroup();
  }, [pagesTab, orderTab, orderWinTab]);
  useEffect(() => {
    let timer = setTimeout(() => {
      fetchWinGroup();
    }, 500);
    return () => clearTimeout(timer);
  }, [search]);

  const { stryktipset } = useContext(ActiveGamesContext);

  const history = useHistory();
  const handlePageClickTab2 = (event) => {
    let page = event.selected + 1;
    setTabPage(event.selected);
    setPagesTab(page);
  };
  const handleRedirectInside = (item) => {
    let id = item?.id;
    let gameType = item?.data?.gameType;
    let drawNumber = item?.data?.drawNumber;
    if (!id) {
      return undefined;
    } else {
      history.push(`/liveresults/${gameType}/${drawNumber}/${id}`);
    }
  };
  const handleOpenMore = (e, groupId) => {
    e.preventDefault();
    let updateGroup = group?.map((item) => {
      if (item._id === groupId) {
        item.open = !item.open;
        return item;
      } else {
        return item;
      }
    });
    setGroup(updateGroup);
  };
  function sortTableHeadTab(event, columName, type) {
    if (!columName) return;
    if (!type) return;
    setOrderTab(type);
    setOrderWinTab(columName);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!isSM && contestDraws.length) {
      return;
    }
    fetchContestDraws();
  }, []);
  const headerGroup = [
    {
      title: "ANVÄNDARE",
    },
    {
      title: "ANTAL RÄTT",
    },
    {
      title: "TOTALT INSPELAT",
      order: "win",
      sortBy: sortTableHeadTab,
    },
    {
      title: "ANTAL RADER",
      order: "rader",
      sortBy: sortTableHeadTab,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Tävla med Reducering.se! | Reducering.se</title>
        <meta property="og:title" content="Sveriges bästa poolspels-verktyg" />
        <meta
          property="og:url"
          content={`https://www.reducering.se${url.pathname}`}
        />
        <meta
          property="og:description"
          content="Tävla med Reducering.se och vinn fina priser! Bli en bättre spelare med Reducering.se."
        />
      </Helmet>
      <HomeHeader />

      <InnerContainer>
        <Video
          muted
          autoPlay // Autoplay in Chrome requires video to be muted.
          loop
          playsInline
          //mp4={VideoHeroMp4}
          //webm={VideoHeroWebm}
          img={VideoHeroTavling}
          alt={`Sorry, your browser doesn't support embedded videos.`}
        />
      </InnerContainer>

      <PageContent>
        <ContestBody flex={true}>
          <div className="right">
            <h3>Dags för Reducering-EM – vinn fotbollsresa värd 10 000 kr!</h3>
            <p>
              Reducering-EM pågår över tre EM-tipsomgångar under EM där just DU
              har chansen att bli Reducering-mästare! Förutom ära och prestige,
              så kammar vinnaren även hem en fotbollsresa för två till ett värde
              av 10 000 kr!
            </p>
            <p>
              <h4>Hur tävlar man?</h4>
              <ul className="dashed">
                <li>
                  Gör minst ett system (max 500 rader) på EM-tipset inne på
                  Reducering.se under tävlingsperioden (14-26 juni)
                </li>
                <li>
                  Checka boxen “Delta i Reducering-EM”, som ligger under Tävling
                  i Analys & Info, och klicka på “Lämna in”-knappen (Notera att
                  du inte måste lämna in systemet inne på Svenska Spel Sport &
                  Casino AB för att delta i tävlingen.)
                </li>
              </ul>
            </p>
            <p>
              <h4>När avgörs tävlingen?</h4>
              <ul className="dashed">
                <li>
                  14-26 juni över tre EM-tipsetomgångar, med start kl. 21:00
                  (spelstopp 20:59) den 14:e juni
                </li>
              </ul>
            </p>
            <p>
              <h4>Hur vinner man?</h4>
              <ul className="dashed">
                <li>
                  Den som spelat ihop mest (i kronor) under tävlingsperioden
                  vinner
                </li>
              </ul>
            </p>
          </div>
          <div className="left">
            <p>
              <h4 style={{ textTransform: "uppercase" }}>
                LEADERBOARD - SÅ FÖLJER DU TÄVLINGEN
              </h4>

              <p className="dashed" style={{ fontSize: "12px" }}>
                Halva nöjet med Reducering-EM kommer vara att följa tävlingen
                inne på Reducering.se! Där kommer det finnas en leaderboard med
                samtliga deltagare - håll koll på din egen placering, dina
                vänners resultat och vilka som slåss i toppen! Kika längre ned
                på sidan så hittar du leaderboarden!
              </p>
            </p>

            <h4>Höj temperaturen med Reducering-EM!</h4>
            <div>
              <p
                style={{
                  fontSize: "12px",
                }}
              >
                Fotbolls-EM i Tyskland är såklart en av sommarens stora
                höjdpunkter - även utan Sverige bland de 24 lagen. Det vill vi
                på Reducering.se understryka genom att anordna Reducering-EM,
                som pågår i tre omgångar då Svenska Spel Sport & Casino AB
                erbjuder det så populära EM-tipset - som ersätter Europatipset
                under ett par veckor. Det blir samma upplägg som i tidigare EM
                och SM, fast över tre omgångar. System med max 500 rader tävlar
                på EM-tipset och den som spelar ihop mest (i kronor) totalt
                vinner! Det är alltså inte antal rätt som räknas - utan summan.
                Pris? En fotbollsbollsresa för två till ett värde av 10 000 kr!
                Vad väntar du på? In och tävla direkt! Har du ingen
                Reducering-profil? Skapa här, gratis!
              </p>
            </div>

            <h4>Tävlingsbestämmelser & Regler</h4>

            <ul className="dashed">
              <li>
                Spelform som räknas i tävlingen: EM-tipset under fotbolls-EM
                (tre omgångar)
              </li>
              <li>
                En Reducering-profil krävs - gratis att skapa! Klicka{" "}
                <a style={{ cursor: "pointer" }} onClick={loginWithRedirect}>
                  här
                </a>{" "}
                om du saknar ett konto. )
              </li>
              <li>Det går bara att tävla med ett system per spelomgång</li>
              <li>
                Ett inlämnat tävlingsbidrag går ej att ändra efter spelstopp
              </li>
              <li>
                Den som spelar ihop mest (i kronor) under tävlingsperioden
                vinner
              </li>
              <li>
                Den som spelat ihop mest (i kronor) under tävlingsperioden
                vinner och blir svensk Reducering-mästare
              </li>
              <li>Ev. vinstskatt betalas av vinnaren</li>
            </ul>
          </div>

          <div className="center-flex">
            <div>
              {isSM && (
                <button
                  disabled={!upcomingContestDraw}
                  onClick={() => {
                    if (upcomingContestDraw) {
                      history.push(
                        `/reducera/${upcomingContestDraw.gameType}/${upcomingContestDraw.drawNumber}`,
                      );
                    }
                  }}
                  className="btn-global"
                >
                  delta i em
                </button>
              )}
            </div>
          </div>
        </ContestBody>

        {/* Stekt! för nu!! */}

        {/*<ContestBody>
          <h3>Tävla och vinn med Reducering.se</h3>
          <h4>Bli veckans “klonkare”!</h4>

          <p>
            Bli veckans “klonkare” (#veckansklonk) med Reducering.se och vinn matchbiljetter! Reducering.se anordnar i
            samarbete med Svenska Spel Sport & Casino en unik tävling hela år 2021. Enkelt att delta och fina
            matchbiljetter i prispotten!
          </p>

          <h4>Hur deltar man i tävlingen?</h4>

          <ul className='dashed'>
            <li>Lämna in ett system på Svenska Spel Sport & Casino med Reducering.se.</li>
            <li>Vinn minst 2 000 kr.</li>
            <li>Dela en bild på vinsten med hashtag #veckansklonk och tagga @reducering på Twitter.</li>

            <li>Du är nu kvalificerad till utlottningen om att bli veckans vinnare.</li>
          </ul>

          <h4>När avgörs tävlingen?</h4>

          <ul className='dashed'>
            <li>
              Veckovis: Måndag-söndag, med start måndag den 16:e augusti (vecka 33). En vinnare lottas fram varje vecka
              bland de som kvalificerats!
            </li>
          </ul>

          <h4>Priser?</h4>

          <ul className='dashed'>
            <li>
              2 st matchbiljetter till en vinnare varje vecka. Landskamper för svenska fotbolls- och ishockeylandslaget,
              SHL, Hockeyallsvenskan, presentkort m.m. finns i potten.
            </li>
          </ul>

          <h4>Tävlingsbestämmelser & Regler</h4>
          <ul className='dashed'>
            <li>
              Vinnaren presenteras på Reducering.se Twitterkonto varje måndag eller tisdag efter avslutad tävlingsvecka.
            </li>
            <li>Spelformer som räknas i tävlingen: Stryktipset, Europatipset, Topptipset och Powerplay</li>
            <li>Totala vinstsumman för systemet/spelet räknas, d.v.s. bruttovinsten.</li>
            <li>
              Bilden som delas måste vara en skärmdump/bild från liverättningsdelen inne på Reducering.se där
              vinstsumman framgår.
            </li>
            <li>Det går bara att delta i tävlingen en gång per system.</li>
            <li>
              Systemet måste ha lämnats in på Svenska Spel Sport & Casino för att vara giltigt. Kontroll kommer ske.
            </li>
          </ul>
        </ContestBody> */}
      </PageContent>

      {/*<InnerContainer>
        <TavlingSection>
          <Svenska className='svenska'/>
          <div className='tavling-info'>
            <h3>Är du redo att bli svensk mästare</h3>
            <p>Ta chansen att vinna Reducering-SM och en fotbollsresa värd 10 000 kr!</p>
            <button
              onClick={()=>{
                let first = stryktipset.draws[0]
                if(first?.drawNumber){
                  history.push(`/reducera/stryktipset/${first.drawNumber}`)
                }
              }}
              className='btn'>delta i sm</button>
          </div>
        </TavlingSection>
      </InnerContainer>*/}
      <InnerContainer>
        <ActiveGamesBoxes />
      </InnerContainer>

      <InnerContainer>
        <Footer />
      </InnerContainer>
    </>
  );
}

import styled from 'styled-components'
import {
  breakPoints,
  colors,
  font,
  spacers,
  borderRadius,
  headerHeight
} from '../../../styles/constants'

export const WinSectionContainer = styled.div`
  width: 100%;
  //padding-top: 5rem;
  padding-top: ${props => props.isEditing ? '1rem' : '5rem'};
  .lottery-error{
    font-size: 1.6rem;
    color: #fe0000;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 3px 15px #0000000A;
    background: transparent linear-gradient(118deg, #5A6E79 0%, #37474F 100%);
    border-radius: 1rem;
    grid-gap: 1rem;
     @media screen and (max-width: 768px){
      flex-direction: column-reverse;
    }
  }
  label {
    align-items: center;
    font-size: 1.6rem;
  }
  .counter{
  position: relative;
  top: -1.2rem;
  font-size: 1.2rem;
  font-weight: 600;
  }
  .info {
    max-width: 53.5rem;
    width: 100%;
    padding-left: 2.2rem;
     @media screen and (max-width: 768px){
      padding-bottom: 2rem;
      max-width: 41.5rem;
      padding-left: 0;
    }
    @media screen and (max-width: 468px){
      max-width: 31.5rem;
      //margin-right: auto;
     // padding-left: 1.8rem;
     padding: 0 2rem 2rem 2rem;
    }
  }
  h3 {
    font-size: 2.8rem;
    font-weight: ${font.weight.bold};
    margin-bottom: 1.6rem;
    word-break: break-word;
     @media screen and (max-width: 768px){
      font-size: 1.8rem;
    }
  }
  a {
    text-decoration: underline;
    color: ${colors.anchor};
  }
  .main {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
  .svg {
    margin-bottom: 3.58rem;
    @media screen and (max-width: 768px){
      margin-bottom: 2rem;
      max-width: 12rem;
    }
  }
  .overlay-img{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .upload {
      display: block;
      width: 10rem;
      height: 5rem;
      fill: #FFFFFF;
    }
  }
  .img {
    max-width: 35.6rem;
    width: 100%;
    border-radius: 0.5rem;
    min-height: 40.2rem;
    max-height: 40.3rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media screen and (max-width: 768px){
      margin-top: 2rem;
      //max-height:16.3rem ;
      min-height: auto;
      max-height: 100%;
    }
    @media screen and (max-width: 468px){
    margin-top: 0;
    }
    img {
      //object-fit: cover;
      object-position: center;
      width: 100%;
      min-height: 40.2rem;
      max-height: 40.3rem;
      @media screen and (max-width: 768px){
        max-width: 100%;
        width: auto;
       //max-height:16.3rem ;
       min-height: auto;
       margin-bottom: 2rem;
       border-radius: 1rem;
    }
    }
   
  }
  button {
    display: flex;
    cursor: pointer;
    color: ${colors.white};
    align-items: center;
    justify-content: center;
    min-width: 15.2rem;
    min-height: 4.2rem;
    border-radius: 0.5rem;
    border: none;
    box-shadow: 0px 3px 12px #0000000A;
    font-size: 1.8rem;
    margin-top: 2.6rem;
    background-color: ${colors.anchor};
    font-weight: ${font.weight.bold};
    &:hover{
    transform: scale(1.1);
    }
    @media screen and (max-width: 468px){
      margin: 0 auto;
      font-size: 1.2rem;
      min-height: 3rem;
      min-width: 9rem;
    }
  }
  .submit {
    background-color: ${colors.anchor}!important;
    max-width: 15.2rem!important;
    min-width: 10rem!important;
    font-size: 1.8rem!important;
  }
  textarea {
    display: block;
    width: 100%;
    margin-bottom: 1.6rem;
    max-height: 25.4rem;
    min-height: 25.3rem;
    resize: none;
    border: 1px solid #FFFFFF;
    border-radius: 1rem;
    padding: 1.6rem 1rem 1.6rem 2rem;
    outline: none;
    background: transparent;
    font-size: 1.8rem;
    color: #ffffff;
  }
  .loterry-title{
    min-height: 6.6rem;
  }
  .btn-group {
    display: flex;
    margin-top: 1rem;
  }
  .btn-winn{
    display: flex;
    align-items: center;
    grid-gap: 2rem;
  }
  .delta{
     background-color: #273238;
     text-transform: uppercase;
  }
  .cancel{
    font-size:1.8rem!important; 
    min-width: 10rem!important;
  }
  
`